import { useRef } from "react";

import { useTranslation } from "react-i18next";

import { CreateUpdateForm, IPhoneData, getFullPhoneNumber } from "@feature/phone";

import { EditField, IEditFieldRef } from "../../EditField/EditField";

interface IPhoneFieldProps {
  phoneData: IPhoneData;
  onSubmit: (value: IPhoneData, onSuccess: () => void) => void;
  isLoading: boolean;
}

export const PhoneField = (props: IPhoneFieldProps) => {
  const { t } = useTranslation();
  const editFieldRef = useRef<IEditFieldRef | null>(null);

  return (
    <EditField
      title={t("form.label.telephone")}
      displayText={getFullPhoneNumber(props.phoneData)}
      ref={editFieldRef}
      isRequired={false}
    >
      <CreateUpdateForm
        {...props}
        onSubmit={(formData) => {
          props.onSubmit(formData, () => editFieldRef.current?.closeEditMode());
        }}
      />
    </EditField>
  );
};
