import { gql } from "@apollo/client";

const GET_REQUESTS = gql`
  query joinRequests($sendbirdId: String!) {
    joinRequests(sendbirdId: $sendbirdId) {
      user {
        fullName
        email
        organization {
          sector {
            name
          }
          name
          userRole
        }
        interests {
          name
        }
      }
      community {
        name
        sendbirdId
      }
      created
      status
    }
  }
`;
export default GET_REQUESTS;
