import SettingSvg from "@images/setting.svg";
import { useTranslation } from "react-i18next";
import styles from "./BuildingProfile.module.scss";

const BuildingProfile = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.buidingContainer}>
        <img src={SettingSvg} alt="" />
        <h2 className={styles.heading}>{t("buildingProfile.heading")}</h2>
        <span className={styles.subheading}>{t("buildingProfile.subheading")}</span>
      </div>
    </div>
  );
};

export default BuildingProfile;
