const IS_ONBOARDED = "isOnboarded";

class TourModalService {
  public static setIsOnboarded() {
    return localStorage.setItem(IS_ONBOARDED, IS_ONBOARDED);
  }

  public static getIsOnboarded() {
    return localStorage.getItem(IS_ONBOARDED);
  }

  public static clearIsOnboarded() {
    localStorage.removeItem(IS_ONBOARDED);
  }
}

export default TourModalService;
