import { gql } from "@apollo/client";

const GET_COMMUNITY_RESOURCES = gql`
  query communities($size: UInt, $skip: UInt, $text: String, $interests: [String]) {
    communities(
      communitySearch: { size: $size, skip: $skip, text: $text, interests: $interests }
    ) {
      totalCount
      communities {
        name
        communityType
        description
        communityImageUrl
        communityBannerUrl
        sendbirdId
        members {
          email
        }
        admins {
          email
        }
        communityCreator {
          email
        }
        joinRequests {
          user {
            email
          }
          community {
            name
            sendbirdId
          }
          created
          status
        }
      }
    }
  }
`;

export default GET_COMMUNITY_RESOURCES;
