import BackButton from "@components/BackButton/BackButton";
import { useTranslation } from "react-i18next";

interface IEventPageBackButton {
  onClick?: () => void;
}

const EventPageBackButton = ({ onClick }: IEventPageBackButton) => {
  const { t } = useTranslation();
  let text = t("button.back");
  if (localStorage?.href?.includes("/portal/community/community-page")) {
    text = t("events.backButtonToCommunity");
  }

  return <BackButton path={localStorage?.href} label={text} onClick={onClick} />;
};

export default EventPageBackButton;
