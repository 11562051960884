import { gql } from "@apollo/client";

const GET_TIME_ZONES = gql`
  query timeZoneGroups {
    timeZoneGroups {
      zoneGroups {
        name
        timeZones {
          name
        }
      }
    }
  }
`;

export default GET_TIME_ZONES;
