import { isDocumentRtl } from "@utils/checkDocumentDir";
import { IEvent } from "types/event";

export const isNewEventOverlappingWithHostedEvents = (
  newEventStartDate: Date,
  newEventEndDate: Date,
  hostingEvents: IEvent[]
) => {
  const isNewEventOverlapping = ({ startDate, endDate }: IEvent) => {
    const hostedStartDate = new Date(startDate);
    const hostedEndDate = new Date(endDate);
    if (newEventStartDate >= hostedStartDate && newEventEndDate <= hostedEndDate) {
      return true;
    }

    if (newEventStartDate <= hostedStartDate && newEventEndDate > hostedStartDate) {
      return true;
    }

    if (newEventStartDate < hostedEndDate && hostedEndDate <= newEventEndDate) {
      return true;
    }

    return false;
  };

  return hostingEvents.find(isNewEventOverlapping);
};

export const getTimePickerDateFormat = () => {
  return isDocumentRtl() ? "ahh:mm" : "hh:mma";
};
