import { PlusOutlined } from "@ant-design/icons";
import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import SearchInput from "@components/SearchInput/SearchInput";
import { Tabs } from "@components/navigation/Tabs/Tabs";
import MagnifyGlassIcon from "@images/search_grey.svg";
import { ClaimsService } from "@services/ClaimsService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearSearchTerm, saveSearchTerm } from "../../store/events/actions";
import { RootState } from "../../store/store";
import styles from "./Events.module.scss";

import { EVENT_ROUTES } from "@utils/routes/event";
import { SearchedEvents } from "./SearchedEvents/SearchedEvents";
import { useGetTabs } from "./useGetTabs/useGetTabs";

const Events = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tabs } = useGetTabs();

  const hasCreateEventClaim: boolean = ClaimsService.hasCreateEventClaim();

  const [searchEventQuery, setSearchEventQuery] = useState("");
  const [renderKey, setRenderKey] = useState<number>(0);
  const dispatch = useDispatch();
  const searchTerm = useSelector<RootState, string>(
    (state) => state.searchTerm.searchTerm
  );

  const handleSubmitSearchEventQuery = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(saveSearchTerm(searchEventQuery));
    setRenderKey(renderKey + 1);
  };

  const handleBackClick = () => {
    setSearchEventQuery("");
    dispatch(clearSearchTerm());
  };

  const eventsSearchField = () => (
    <div className={styles.row}>
      <form onSubmit={handleSubmitSearchEventQuery}>
        <SearchInput
          name="searchEvent"
          placeholder={t("events.search")}
          e2eTestId="events-search-input"
          value={searchEventQuery}
          onChange={(event) => setSearchEventQuery(event.target.value)}
          searchIcon={
            <img
              src={MagnifyGlassIcon}
              onClick={handleSubmitSearchEventQuery}
              alt=""
              aria-label="Magnify Glass"
            />
          }
          className={styles.searchInput}
        />
      </form>
      {hasCreateEventClaim && (
        <Button
          title={t("createEvent.buttonText")}
          buttonIcon={<PlusOutlined />}
          buttonSize="large"
          buttonType="primary"
          onClick={() => history.push("/portal/event/new")}
        />
      )}
    </div>
  );

  const createEventButton = () => (
    <Button
      title={t("createEvent.buttonText")}
      wrapperClassNames={styles.createEventButton}
      buttonIcon={<PlusOutlined />}
      buttonType="primary"
      buttonSize="large"
      e2eTestId="create-event-button"
      onClick={() => history.push("/portal/event/new")}
    />
  );

  return (
    <div className={styles.container}>
      {searchTerm ? (
        <>
          <div className={styles.navigationTop}>
            <BackButton label={t("events.backButton")} onClick={handleBackClick} />
            {eventsSearchField()}
          </div>
          <SearchedEvents key={`articles_${renderKey}`} searchTerm={searchTerm} />
        </>
      ) : (
        <div className={styles.tabsAndButtonContainer} e2e-test-id="events-page-contents">
          <Tabs
            tabs={tabs}
            routePrefix={EVENT_ROUTES.EVENTS}
            size="large"
            extraContent={eventsSearchField()}
          />
          {window.location.pathname.includes(EVENT_ROUTES.EVENTS) &&
            hasCreateEventClaim &&
            createEventButton()}
        </div>
      )}
    </div>
  );
};

export default Events;
