import { gql } from "@apollo/client";

const GET_USER_ARTICLES = gql`
  query user {
    user {
      interestingArticles {
        articleId
      }
      inappropriateArticles {
        articleId
      }
      hiddenArticles {
        articleId
      }
    }
  }
`;

export default GET_USER_ARTICLES;
