import Button from "@components/Button/Button";
import EventCard from "@components/EventCard/EventCard";
import Loading from "@components/Loading/Loading";
import { formatDate } from "@utils/eventsHelper";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IEvent } from "types/event";
import styles from "./EventsGrid.module.scss";

interface IEventsGridProps {
  events: Array<IEvent>;
  loadMore: () => void;
  eventsLoading: boolean;
  eventsLoadingMore: boolean;
  eventsCount: number;
  searchValue?: string;
  firstItemId?: string;
  hostName?: string;
}

const EventsGrid = ({
  events,
  searchValue,
  eventsLoading,
  eventsCount,
  loadMore,
  eventsLoadingMore,
  firstItemId,
  hostName = "",
}: IEventsGridProps) => {
  const { t, i18n } = useTranslation();
  const firstItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!firstItemRef || !firstItemId) return;
    firstItemRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [firstItemId]);

  const renderEventWithRefConditionally = (event: IEvent, index: number) =>
    firstItemId === event.publicId ? (
      <div key="refPlaceholder" ref={firstItemRef}>
        {eventCartRender(event, index)}
      </div>
    ) : (
      eventCartRender(event, index)
    );

  const eventCartRender = (event: IEvent, index: number) => (
    <EventCard
      startDate={formatDate(event?.startDate, i18n.language)}
      key={`${index}_${event.publicId}`}
      endDate={formatDate(event?.endDate, i18n.language)}
      name={event?.name}
      userName={event?.eventHost?.fullName || hostName}
      event={event}
    />
  );

  return (
    <div className={styles.eventsGridContainer} role="grid">
      {searchValue && (
        <h3 className={styles.sectionTitle}>
          {t("events.relatedEvents", { searchValue: `${searchValue}` })}
        </h3>
      )}
      {eventsLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading borderless disableBoxShadow />
        </div>
      ) : (
        <div className={styles.eventsGridWrapper}>
          {events.map(renderEventWithRefConditionally)}
        </div>
      )}
      {eventsCount > events.length && (
        <Button
          aria-label="Load More Button"
          title={t("button.viewMore")}
          wrapperClassNames={styles.loadMoreButton}
          onClick={loadMore}
          loading={eventsLoadingMore}
          disabled={eventsLoading}
        />
      )}
    </div>
  );
};

export default EventsGrid;
