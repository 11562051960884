import { useState } from "react";

import RoundedX from "@images/rounded_x.svg";

import { useCreateChannel } from "../useCreateChannel/useCreateChannel";

import { GroupChannel } from "sendbird";

import styles from "./ChannelCreate.module.scss";

interface ICreateChannelProps {
  channels: Array<string>;
  setChannels: (value: Array<string>) => void;
  groupChannel?: GroupChannel;
  defaultChannel: string;
  onCancel: () => void;
}

export const ChannelCreate = ({ onCancel, ...props }: ICreateChannelProps) => {
  const { createChannel, loading } = useCreateChannel(props);
  const [name, setName] = useState("");

  const handleNameChange = (event: { target: { value: string } }) => {
    setName(event.target.value.replaceAll(" ", "_"));
  };

  const handleNameKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.code === "Enter" && !loading) {
      createChannel(name, resetChannelCreation);
    }
  };

  const resetChannelCreation = () => {
    onCancel();
    setName("");
  };

  return (
    <div className={styles.container}>
      <span className={styles.hashtag}>#</span>
      <input
        autoFocus
        type="text"
        className={styles.input}
        value={name}
        onChange={handleNameChange}
        onKeyPress={handleNameKeyPress}
        maxLength={50}
      />
      <img
        src={RoundedX}
        alt=""
        className={styles.closeIcon}
        onClick={resetChannelCreation}
      />
    </div>
  );
};
