import { useApolloClient } from "@apollo/client";
import { ADD_FOLDER_TO_COLLECTION } from "@graphql/collection/mutation";
import { GET_COLLECTION_CONTENT, GET_FOLDER_CONTENT } from "@graphql/collection/queries";
import { INewFolder } from "@graphql/collection/type";
import { ADD_SUBFOLDER } from "@graphql/folder/mutation";
import ArticleService from "@services/ArticleService";
import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { EventDataNode } from "antd/es/tree";
import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IArticleId } from "types/article";
import { IFile } from "types/file";
import { ILink } from "types/link";
import { CollectionsTreeNode } from "./types";

export const useCollectionsTree = (
  initialTreeData: CollectionsTreeNode[] | undefined,
  onSelect: (node: CollectionsTreeNode[] | undefined) => void,
  newFolderTitle: string,
  emptyTitle?: string,
  showFiles = true,
  showLinks = true,
  showArticles = false,
  folderCreationSuccessMessage = "",
  filterNode?: (node: CollectionsTreeNode, parent: CollectionsTreeNode) => boolean
) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [treeData, setTreeData] = useState<CollectionsTreeNode[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [parentNode, setParentNode] = useState<CollectionsTreeNode>();
  const [isParentNodeExpanded, setIsParentNodeExpanded] = useState(false);
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);

  const client = useApolloClient();
  const { t } = useTranslation();
  if (!newFolderTitle) {
    newFolderTitle = t("collection.newFolder");
  }
  if (!emptyTitle) {
    emptyTitle = t("collection.noFiles");
  }

  useEffect(() => {
    if (initialTreeData && !isInitialized) {
      setTreeData(initialTreeData);
      setExpandedKeys([]);
      setSelectedKeys([]);
      setIsInitialized(true);
    }
  }, [initialTreeData, isInitialized]);

  // handle creating new folder when parent node is not expanded
  useEffect(() => {
    if (isCreatingNewFolder && parentNode && !isParentNodeExpanded) {
      expandHandler([...expandedKeys, parentNode.key], {
        expanded: true,
        node: parentNode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewFolder, parentNode, isParentNodeExpanded]);

  const selectHandler = (
    selectedKeys: Array<Key>,
    { node }: { node: EventDataNode<CollectionsTreeNode> }
  ) => {
    setSelectedKeys(selectedKeys);
    // node.selected is previous state of the node
    if (node.selected || node.type === "new-folder") {
      onSelect(undefined);
    } else {
      onSelect(getPath(treeData, node.key));
    }
  };

  const expandHandler = (
    expandedKeys: Key[],
    { expanded, node }: { expanded: boolean; node: CollectionsTreeNode }
  ) => {
    setExpandedKeys(expandedKeys);

    if (expanded) {
      const loadingChild: CollectionsTreeNode = {
        title: "",
        key: Math.random(),
        type: "loading",
        isLeaf: true,
      };

      setTreeData((treeData) => updateTreeData(treeData, node.key, [loadingChild]));

      if (node.type === "collection" || node.type === "folder") {
        fetchData(node);
      }
    }
  };

  const newFolderSubmitHandler = async (name: string) => {
    if (!parentNode) {
      return;
    }
    const parentPath = getPath(treeData, parentNode.key);
    const parent = parentPath?.at(-1);

    if (!parent) {
      return;
    }

    const mutation =
      parent.type === "collection" ? ADD_FOLDER_TO_COLLECTION : ADD_SUBFOLDER;
    const variables =
      parent.type === "collection"
        ? {
            folder: {
              name,
              collectionPublicId: parent.key,
            },
          }
        : {
            subfolderToAdd: {
              name,
              parentFolderPublicId: parent.key,
            },
          };

    const responseDataKey =
      parent.type === "collection" ? "addFolderToCollection" : "addSubfolder";

    try {
      const response = await client.mutate({
        mutation,
        variables,
      });
      const { publicId } = response.data[responseDataKey];
      const children = parent.children?.filter((child) => child.type !== "new-folder");
      const newFolder: CollectionsTreeNode = {
        key: publicId,
        title: name,
        type: "folder",
      };
      children?.unshift(newFolder);
      setTreeData((treeData) => updateTreeData(treeData, parent.key, children));
      cancelCreatingNewFoler();
      setSelectedKeys([publicId]);

      onSelect([...(parentPath ?? []), newFolder]);

      displaySuccessNotification(folderCreationSuccessMessage);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      displayCustomNotSuccessNotification(errorCode);
    }
  };

  const fetchData = async (parent: CollectionsTreeNode) => {
    const type = parent.type;
    let folders: CollectionsTreeNode[] = [];
    let files: CollectionsTreeNode[] = [];
    let links: CollectionsTreeNode[] = [];
    let articles: CollectionsTreeNode[] = [];
    const query =
      parent.type === "collection" ? GET_COLLECTION_CONTENT : GET_FOLDER_CONTENT;
    try {
      const response = await client.query({
        query,
        variables: { publicId: parent.key },
        fetchPolicy: "no-cache",
      });
      const id = response.data[type].publicId;
      folders = response.data[type].folders.map((folder: INewFolder) => {
        return {
          key: folder.publicId,
          title: folder.name,
          type: "folder",
          isLeaf: false,
          updateTime: folder.updateTime,
        };
      });
      if (isCreatingNewFolder && parentNode?.key === parent.key) {
        folders = [getNewFolderNode(newFolderTitle!), ...folders];
      }
      if (showFiles) {
        files = response.data[type].files.map((file: IFile) => {
          return {
            key: file.publicId,
            title: file.name,
            type: "file",
            isLeaf: true,
          };
        });
      }
      if (showLinks) {
        links = response.data[type].links.map((link: ILink) => {
          return {
            key: link.publicId,
            title: link.name,
            type: "link",
            isLeaf: true,
          };
        });
      }
      if (showArticles) {
        const articleIds = response.data[type].articles.map(
          (article: IArticleId) => article.articleId
        );
        const articlesResponse = await ArticleService.getArticlesByIds(articleIds);
        if (articlesResponse?.status === 200) {
          articles = articlesResponse.data.map(
            (articleData: { title: string }, index: number) => ({
              key: parent.key + "###" + response.data[type].articles[index].publicId,
              title: articleData.title,
              type: "article",
              isLeaf: true,
            })
          );
        }
      }
      let children = [...folders, ...files, ...links, ...articles];
      if (filterNode) {
        children = children.filter((child) => filterNode(child, parent));
      }
      if (children.length === 0) {
        children = [
          {
            key: Math.random(),
            title: emptyTitle,
            type: "empty",
            isLeaf: true,
          },
        ];
      }
      setTreeData((treeData) => updateTreeData(treeData, id, children));
    } catch (error) {
      displayNotSuccessNotification(error);
      setTreeData((treeData) => updateTreeData(treeData, parent.key, undefined));
      setExpandedKeys((expandedKeys) => expandedKeys.filter((key) => key !== parent.key));
    }
  };

  const createNewFolder = (parent: CollectionsTreeNode) => {
    const newFolderNode = getNewFolderNode(newFolderTitle!);
    const children = [newFolderNode, ...(parent.children ?? [])].filter(
      (node) => node.type !== "empty"
    );
    const isExpanded = expandedKeys.some((key) => key === parent.key);
    setSelectedKeys([newFolderNode.key]);
    setIsCreatingNewFolder(true);
    setParentNode(parent);
    setIsParentNodeExpanded(isExpanded);
    if (isExpanded) {
      setTreeData((treeData) => updateTreeData(treeData, parent.key, children));
    }
    onSelect(undefined);
  };

  const cancelCreatingNewFoler = () => {
    setIsCreatingNewFolder(false);
    setParentNode(undefined);
    setIsParentNodeExpanded(false);
  };

  const getNodePath = (nodeKey: string | number, includeLeaf = false) => {
    return getPath(treeData, nodeKey, includeLeaf);
  };

  const setState = (
    treeData: CollectionsTreeNode[],
    expandedKeys: Key[] = [],
    selectedKeys: Key[] = []
  ) => {
    setTreeData(treeData);
    setExpandedKeys(expandedKeys);
    setSelectedKeys(selectedKeys);
    cancelCreatingNewFoler();
    setIsInitialized(true);
  };

  return {
    isInitialized,
    treeData,
    selectedKeys,
    expandedKeys,
    isCreatingNewFolder,
    newFolderTitle: newFolderTitle!,
    emptyTitle: emptyTitle!,
    setState,
    setSelectedKeys,
    getNodePath,
    selectHandler,
    expandHandler,
    createNewFolder,
    newFolderSubmitHandler,
    cancelCreatingNewFoler,
  };
};

const getNewFolderNode = (newFolderTitle: string): CollectionsTreeNode => ({
  key: "new-folder",
  title: newFolderTitle,
  type: "new-folder",
});

const getPath = (
  treeNodes: CollectionsTreeNode[],
  nodeKey: string | number,
  includeLeaf = false
): CollectionsTreeNode[] | undefined => {
  for (let ii = 0; ii < treeNodes.length; ii++) {
    const treeNode = treeNodes[ii];

    if (treeNode.key === nodeKey) {
      if (includeLeaf || !treeNode.isLeaf) {
        return [treeNode];
      } else {
        return [];
      }
    }
    if (treeNode.children?.length) {
      const childrenInPath = getPath(treeNode.children, nodeKey, includeLeaf);
      if (childrenInPath) {
        return [treeNode, ...childrenInPath];
      }
    }
  }
};

const updateTreeData = (
  list: CollectionsTreeNode[],
  key: React.Key,
  children: CollectionsTreeNode[] | undefined
): CollectionsTreeNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
