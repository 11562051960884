import axios from "axios";
import store from "../store/store";
import { setClaims } from "../store/user/actions";
import { CLAIM_TYPES } from "../store/user/constants";
import { displayServerError } from "./NotificationService/NotifacitonService";

export class ClaimsService {
  public static async getUserClaims() {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/backend/SignedInUser`
      );

      store.dispatch(setClaims(data.Claims));
    } catch (error) {
      displayServerError(error);
    }
  }

  public static hasClaim(claim: string) {
    const claims: Array<string> = store.getState().user.claims;
    return claims.find((item: string) => item.includes(claim)) ? true : false;
  }

  public static hasAllClaims(claims: Array<string>) {
    return claims.every((claim) => this.hasClaim(claim));
  }

  public static hasUserReadClaim() {
    return ClaimsService.hasClaim(CLAIM_TYPES.USER_READ);
  }

  public static hasFeedbackReadClaim() {
    return ClaimsService.hasClaim(CLAIM_TYPES.FEEDBACK_READ);
  }

  public static hasDeviceTokenCreate() {
    return ClaimsService.hasClaim(CLAIM_TYPES.DEVICE_TOKEN_CREATE);
  }

  public static hasCreateEventClaim() {
    return ClaimsService.hasClaim(CLAIM_TYPES.EVENT_CREATE);
  }

  public static hasCreateCommunityClaim() {
    return ClaimsService.hasClaim(CLAIM_TYPES.COMMUNITY_CREATE);
  }
}
