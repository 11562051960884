import { useTranslation } from "react-i18next";

import { ITab, Tabs } from "@components/navigation/Tabs";

import {
  ACCOUNT_SETTINGS,
  ACCOUNT_SETTINGS_ROUTES_PREFIX,
} from "@utils/routes/accountSettings";

import styles from "./TabBar.module.scss";

interface ITabBarProps {
  personalDetailsContent: React.ReactChild;
  profileDetailsContent: React.ReactChild;
  interestsContent: React.ReactChild;
}

const TabBar = ({
  personalDetailsContent,
  profileDetailsContent,
  interestsContent,
}: ITabBarProps) => {
  const { t } = useTranslation();
  const menuItems: Array<ITab> = [
    {
      id: "1",
      name: t("tabBar.personalDetails"),
      path: ACCOUNT_SETTINGS.PERSONAL_DETAILS,
      content: personalDetailsContent,
      isExtraContentDisplayed: false,
      isRoot: true,
    },
    {
      id: "2",
      name: t("tabBar.profileDetails"),
      path: ACCOUNT_SETTINGS.PROFILE_DETAILS,
      content: profileDetailsContent,
      isExtraContentDisplayed: false,
      isRoot: true,
    },
    {
      id: "3",
      name: t("tabBar.interests"),
      path: ACCOUNT_SETTINGS.CATEGORIES,
      content: interestsContent,
      isExtraContentDisplayed: false,
      isRoot: true,
    },
  ];

  return (
    <div className={styles.settingsContainer} e2e-test-id="account-settings-container">
      <h3 className={styles.title} e2e-test-id="account-settings-title">
        {t("accountSettings.title")}
      </h3>
      <Tabs
        tabs={menuItems}
        routePrefix={ACCOUNT_SETTINGS_ROUTES_PREFIX}
        size="large"
        fullWidthTabBar
        regularSizeTabs
      />
    </div>
  );
};

export default TabBar;
