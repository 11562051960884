import { gql } from "@apollo/client";

export const GET_PUBLIC_COMMUNITY_COLLECTIONS = gql`
  query GetPublicCommunityCollections($search: CommunityCollectionSearchInputType!) {
    publicCommunityCollections(communityCollectionSearch: $search) {
      totalCount
      collections {
        publicId
        name
        collectionCreator {
          email
        }
        collaborators {
          email
        }
      }
    }
  }
`;

export const GET_PRIVATE_COMMUNITY_COLLECTIONS = gql`
  query GetPrivateCommunityCollections($search: CommunityCollectionSearchInputType!) {
    privateCommunityCollections(communityCollectionSearch: $search) {
      totalCount
      collections {
        publicId
        name
        collectionCreator {
          email
        }
        collaborators {
          email
        }
      }
    }
  }
`;

export const GET_MANAGED_AND_JOINED_USER_COMMUNITIES = gql`
  query user {
    user {
      communitiesJoinedByMe {
        name
        sendbirdId
        communityType
        communityImageUrl
        communityBannerUrl
        communityCreator {
          email
        }
        admins {
          email
        }
        members {
          email
        }
        joinRequests {
          user {
            email
          }
        }
      }
      communitiesManagedByMe {
        name
        sendbirdId
        isPublic
        isHidden
        communityImageUrl
        communityBannerUrl
        communityCreator {
          email
        }
        admins {
          email
        }
        members {
          email
        }
      }
      interests {
        name
      }
    }
  }
`;

export const GET_RECOMMENDED_USER_COMMUNITIES = gql`
  query communities($communitySearch: CommunitySearchInputType!) {
    communities(communitySearch: $communitySearch) {
      totalCount
      communities {
        name
        sendbirdId
        communityType
        communityImageUrl
        communityBannerUrl
        communityCreator {
          email
        }
        admins {
          email
        }
        members {
          email
        }
        joinRequests {
          user {
            email
          }
        }
      }
    }
  }
`;

export const GET_USER_COMMUNITIES = gql`
  query user {
    user {
      communitiesManagedByMe {
        name
        sendbirdId
        communityImageUrl
      }
    }
  }
`;

export const GET_USER_COMMUNITIES_FOR_ADD_TO_COLLECTION = gql`
  query user {
    user {
      email
      communities {
        name
        sendbirdId
        communityImageUrl
        members {
          email
        }
      }
    }
  }
`;

export const COMMUNITIES_MANAGED_BY_USER = gql`
  query communitiesManagedByUser(
    $userCommunitySearchOptions: UserCommunitySearchInputType!
  ) {
    communitiesManagedByUser(userCommunitySearchOptions: $userCommunitySearchOptions) {
      totalCount
      communities {
        name
        sendbirdId
        communityType
        communityImageUrl
        communityBannerUrl
        communityCreator {
          fullName
          email
        }
        admins {
          email
        }
        members {
          email
        }
      }
    }
  }
`;

export const COMMUNITIES_FOR_WHO_ADMINS = gql`
  query communitiesForWhoAdmins($communitySearchOptions: CommunitySearchInputType!) {
    communitiesForWhoAdmins(communitySearchOptions: $communitySearchOptions) {
      totalCount
      communities {
        name
        communityType
        sendbirdId
        creationTime
        communityCreator {
          fullName
          email
          publicId
        }
      }
    }
  }
`;

export const COMMUNITIES_JOINED_BY_USER = gql`
  query communitiesJoinedByUser(
    $userCommunitySearchOptions: UserCommunitySearchInputType!
  ) {
    communitiesJoinedByUser(userCommunitySearchOptions: $userCommunitySearchOptions) {
      totalCount
      communities {
        name
        sendbirdId
        communityType
        communityImageUrl
        communityBannerUrl
        communityCreator {
          email
        }
        admins {
          email
        }
        members {
          email
        }
        joinRequests {
          user {
            email
          }
        }
      }
    }
  }
`;

export const GET_USER_COMMUNITIES_FOR_GO_TO_MODAL = gql`
  query user {
    user {
      email
      communities {
        name
        sendbirdId
        communityImageUrl
      }
    }
  }
`;

export const GET_DEFAULT_COMMUNITY_COLLECTION_FOR_GO_TO_MODAL = gql`
  query community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      defaultCollection {
        name
        publicId
      }
    }
  }
`;

export const GET_DEFAULT_COMMUNITY_COLLECTION_FOR_ADD_TO_COLLECTION_MODAL = gql`
  query community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      defaultCollection {
        name
        publicId
        collectionCreator {
          email
        }
        collaborators {
          email
        }
      }
    }
  }
`;
