import { useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useHandleError } from "@services/error";

import GET_USERS from "@graphql/queries/usersExtended";

import { CANCEL_USER_DEACTIVATION } from "./mutation";
import { ICancelDeactivateUserRequest, ICancelDeactivateUserResponse } from "./type";

interface IProps {
  onCompleted?: () => void;
  onError?: () => void;
}

export const useCancelDeactivateUser = ({ onCompleted, onError }: IProps) => {
  const { t } = useTranslation();
  const { handleError } = useHandleError();

  const [cancelDeactivateUser, { loading: isCancelingDeactivation }] = useMutation<
    ICancelDeactivateUserResponse,
    ICancelDeactivateUserRequest
  >(CANCEL_USER_DEACTIVATION, {
    onCompleted: () => {
      displaySuccessNotification(t("adminPanel.users.actionFinishedSuccessfully"));
      onCompleted?.();
    },
    onError: (error) => {
      handleError(error);
      onError?.();
    },
    refetchQueries: [GET_USERS],
  });

  return { cancelDeactivateUser, isCancelingDeactivation };
};
