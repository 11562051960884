import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";

import styles from "./EditField.module.scss";

export interface IEditFieldRef {
  openEditMode: () => void;
  closeEditMode: () => void;
}

interface IEditFieldProps {
  title: string;
  displayText: string | ReactNode;
  children: ReactNode;
  isRequired?: boolean;
}

export const EditField = forwardRef<IEditFieldRef, IEditFieldProps>(
  ({ title, displayText, isRequired = true, children }, ref) => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState(false);

    useImperativeHandle(ref, () => ({
      openEditMode: () => setIsEditMode(true),
      closeEditMode: () => setIsEditMode(false),
    }));

    const getEditButtonText = () => {
      return isEditMode
        ? t("accountSettings.personalDetails.button.cancel")
        : t("accountSettings.profileDetails.button.edit");
    };

    return (
      <div className={styles.editField}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <div>
              <span className={styles.title}>{`${title} ${isRequired ? "*" : ""}`}</span>
            </div>
            <div>
              <Button
                title={getEditButtonText()}
                onClick={() => setIsEditMode((prev) => !prev)}
                aria-label={`edit-field-${title}`}
                buttonType="link"
              />
            </div>
          </div>
          {isEditMode ? (
            <div className={styles.form}>
              <div>{children}</div>
            </div>
          ) : (
            <div className={styles.displayText}>{displayText}</div>
          )}
        </div>
      </div>
    );
  }
);
