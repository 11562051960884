import { gql } from "@apollo/client";

const ADD_SOURCE_SUGGESTION = gql`
  mutation AddSourceSuggestion($suggestion: SourceSuggestionInputType!) {
    addSourceSuggestion(sourceSuggestion: $suggestion) {
      url
      feedback
    }
  }
`;

export default ADD_SOURCE_SUGGESTION;
