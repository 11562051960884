import { gql } from "@apollo/client";
const GET_COMMUNITY = gql`
  query Community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      name
      description
      sendbirdId
      communityType
      communityImageUrl
      communityBannerUrl
      communityBannerName
      interests {
        name
      }
      members {
        publicId
        fullName
        bio
        canReceiveMessages
        pronouns
        interests {
          name
        }
        countries {
          name
        }
        country {
          name
        }
        email
        organization {
          name
          userRole
        }
      }
      admins {
        publicId
        fullName
        bio
        canReceiveMessages
        pronouns
        interests {
          name
        }
        countries {
          name
        }
        country {
          name
        }
        email
        organization {
          name
          userRole
        }
      }
      communityCreator {
        publicId
        canReceiveMessages
        pronouns
        email
        interests {
          name
        }
        countries {
          name
        }
        fullName
        email
        organization {
          name
          userRole
        }
      }
      communityLinks {
        title
        url
      }
      goals
      rules
      name
      channels
      coverImageName
      imageDirectory
      communityBannerDirectory
    }
  }
`;
export default GET_COMMUNITY;
