import { gql } from "@apollo/client";

const GET_USERS_APPLICATIONS_ROLE = gql`
  query user {
    user {
      fullName
      role
    }
  }
`;
export default GET_USERS_APPLICATIONS_ROLE;
