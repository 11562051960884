import { gql } from "@apollo/client";

const LINK_PREVIEW = gql`
  query linkPreview(
    $metaInformationFromLinkInputType: MetaInformationFromLinkInputType!
  ) {
    linkPreview(metaInformationFromLinkInputType: $metaInformationFromLinkInputType) {
      url
      title
      description
      imageUrl
    }
  }
`;

export default LINK_PREVIEW;
