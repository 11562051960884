import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const initOptions = {
  fallbackLng: "en",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath:
      process.env.NODE_ENV === "development"
        ? "/locales/en/translation.json"
        : `${process.env.REACT_APP_BASE_URL}/translations/{{lng}}.json?t=${Date.now()}`,
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(initOptions);

export default i18n;
