import { useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import LEAVE_COMMUNITY from "@graphql/mutations/leaveCommunity";
import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";
import { ButtonType } from "antd/lib/button";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

interface ILeaveGroupButtonProps {
  sendBirdId: string;
  onClick?: () => void;
  type?: ButtonType;
  title: string;
  danger?: boolean;
  wrapperClassNames?: string;
}

export const LeaveGroupButton = ({
  sendBirdId,
  onClick,
  type,
  title,
  danger,
  wrapperClassNames,
}: ILeaveGroupButtonProps) => {
  const history = useHistory();
  const params = useParams<{
    community: string;
    channel: string;
    message: string;
  }>();
  const { t } = useTranslation();
  const { removeCommunityNotificationsRelatedToCommunity } =
    useCommunityNotificationsHelper();
  const [leaveCommunity] = useMutation(LEAVE_COMMUNITY, {
    onCompleted: () => {
      removeCommunityNotificationsRelatedToCommunity(params.community);
      displaySuccessNotification(t("community.drawer.succesLeave"));
      setTimeout(() => {
        history.push("/portal/community");
      }, 500);
    },
  });
  const handleLeaveCommunity = () => {
    leaveCommunity({ variables: { sendbirdId: params.community } });
  };

  return (
    <Button
      aria-label="leave community"
      buttonType={type}
      wrapperClassNames={wrapperClassNames}
      title={title}
      onClick={handleLeaveCommunity}
      danger={danger}
    />
  );
};
