import { useState } from "react";

import { useTranslation } from "react-i18next";

import { checkText } from "smile2emoji";

import AttachmentCard from "@components/AttachmentCard/AttachmentCard";
import { MessageHighlighted } from "@components/CommunityMessage/MessageHighlighted/MessageHighlighted";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import LinkifyMessage from "@components/LinkifyMessage/LinkifyMessage";

import { MessageType } from "@customHooks/sendBird/useSendBirdMessage/type";

import { FileHelper } from "@utils/files/fileHelper";
import { convertTime, handleAcceptingRedirect } from "@utils/helpers";

import { ISearchMessage } from "types/SearchMessage";

import { IUser } from "types/user";

import removeFiles from "@images/removAttachment.svg";

import styles from "./CommunityReplyMessage.module.scss";

interface ICommunityReplyMessageProps {
  message: MessageType;
  searchMessage?: ISearchMessage;
  unsetRemoveIcon?: boolean;
  currentUser?: IUser;
  startMessage?: boolean;
  description?: string;
  removeSelectedParentMessage?: () => void;
  beforeInput?: boolean;
}

const CommunityReplyMessage = ({
  message,
  searchMessage,
  currentUser,
  startMessage,
  description,
  unsetRemoveIcon,
  removeSelectedParentMessage,
  beforeInput,
}: ICommunityReplyMessageProps) => {
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");
  const { t } = useTranslation();
  const email = message?.messageType !== "admin" ? message?.sender?.userId : "";
  const isCurrentUserMessage = currentUser !== undefined && currentUser.email === email;

  const renderMessage = () => {
    let text = "";
    if (message && message.messageType !== "file") {
      text = message.message || "";
    }

    if (!searchMessage) {
      return <span aria-label="message">{checkText(text)}</span>;
    }

    return (
      <MessageHighlighted
        mentionedUsers={message.mentionedUsers}
        message={checkText(text)}
        messageId={message.messageId}
        searchMessage={searchMessage}
      />
    );
  };

  const getName = () => {
    let text = message.messageType !== "admin" ? message.sender?.nickname : "";

    text = `${text} ${startMessage ? "" : "•"}`;

    return text;
  };

  return (
    <div
      aria-label="communityMessage"
      className={
        styles.communityReplyMessage +
        (isCurrentUserMessage ? " " + styles.ownMessage : "") +
        (beforeInput ? " " + styles.beforeInput : "")
      }
    >
      <div className={styles.messageDetails}>
        <div className={styles.rightSide}>
          <div className={styles.innerWrapper}>
            <div className={styles.name}>
              <span>{getName()}</span>
              <span className={styles.time}>{convertTime(message.createdAt)}</span>
            </div>
            <span className={styles.description}>{description}</span>
            <div className={startMessage ? styles.startMessage : styles.message}>
              {
                <LinkifyMessage
                  messageToRender={renderMessage()}
                  openModal={() => setIsConfirmationModalOpened(true)}
                  setClickedUrl={setClickedUrl}
                />
              }
              {message.messageType === "file" && (
                <div className={styles.fileContainer}>
                  <AttachmentCard
                    name={message.name}
                    size={FileHelper.getSizeInKB(message.size)}
                    attachmentEvent
                    downloadAttachment={() => {
                      window.location.assign(message.url);
                    }}
                  />
                </div>
              )}
              <ConfirmationModal
                isOpen={isConfirmationModalOpened}
                accept={() =>
                  handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)
                }
                url={clickedUrl}
                closeModal={() => setIsConfirmationModalOpened(false)}
                messageTitle={t("chat.group.urlModal.title")}
                messageContent={t("chat.group.urlModal.text")}
              />
            </div>
          </div>
        </div>
      </div>
      {unsetRemoveIcon && (
        <img
          className={styles.removeIcon}
          src={removeFiles}
          onClick={removeSelectedParentMessage}
          alt=""
          aria-label="img"
        />
      )}
    </div>
  );
};

export default CommunityReplyMessage;
