import { useTranslation } from "react-i18next";

import { Form, Formik } from "formik";

import { ProfileDetails } from "store/onboarding/types";

import { useGetTranslatedOptions } from "@customHooks/useGetTranslatedOptions/UseGetTranslatedOptions";

import { useGetCountries } from "@feature/country/service/useGetCountries/useGetCountries";

import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import { InputQuill } from "@components/formik/InputQuill/InputQuill";
import { InputSelect } from "@components/formik/InputSelect/InputSelect";
import { InputText } from "@components/formik/InputText/InputText";

import { removeTypeName } from "@utils/graphQL/helper";

import { Form as SectorForm } from "@feature/sector";

import {
  CHAT_PRIVACY_OPTIONS,
  ChatPrivacy,
  ChatPrivacyToTranslation,
  getCanReceiveMessage,
} from "@modules/AccountSettings/SettingsProfileDetails/constants";

import { getValidationSchema } from "./validation";
import { getInitialValues } from "./validation/helper";

import Lock from "@images/lock.svg";

import formStyles from "@components/formik/form.style.module.scss";
import styles from "../ProfileDetails.module.scss";

interface ICreateUpdateForm {
  profileDetails: ProfileDetails;
  canReceiveMessages: boolean;
  onSubmit: (formData: ProfileDetails, canReceiveMessage: boolean) => void;
}

export const CreateUpdateForm = ({
  profileDetails,
  canReceiveMessages,
  onSubmit,
}: ICreateUpdateForm) => {
  const { t } = useTranslation();
  const { countryOptions, countriesData } = useGetCountries();
  const chatPrivacyOptions = useGetTranslatedOptions({ options: CHAT_PRIVACY_OPTIONS });

  return (
    <Formik
      validationSchema={getValidationSchema(t)}
      initialValues={getInitialValues(profileDetails, canReceiveMessages)}
      validateOnMount
      onSubmit={({ chat, ...data }) => {
        const foundCountry = countriesData?.countries.find(
          ({ name }) => name === data.workLocation
        ) || { name: "", callingCode: "" };

        onSubmit(
          { ...data, workLocation: removeTypeName(foundCountry) },
          getCanReceiveMessage(chat as ChatPrivacy)
        );
      }}
    >
      {({ values, isValid, submitForm }) => {
        return (
          <Form>
            <InputSelect
              label={t("form.label.workLocation")}
              name={"workLocation"}
              placeholder={t("form.placeholder.workLocation")}
              options={countryOptions}
              isSearchable
              isRequired
            />
            <InputQuill
              label={t("form.label.bio")}
              name="bio"
              placeholder={t("form.placeholder.bio")}
            />
            <InputText
              label={t("form.label.organization")}
              name="organizationName"
              placeholder={t("form.placeholder.organization")}
              isRequired
            />
            <InputText
              label={t("form.label.roleWithinOrganization")}
              name={"organizationRole"}
              placeholder={t("form.placeholder.roleWithinOrganization")}
              isRequired
            />
            <SectorForm values={values.sector} formPrefix="sector." isLabelDisplayed />

            <div className={styles.privacySettings}>
              <span className={styles.title}>
                {t("accountSettings.profileDetails.label.privacySettings")}
              </span>
              <span className={styles.subTitle}>
                {t(ChatPrivacyToTranslation[values.chat])}
              </span>
            </div>
            <InputSelect
              label=""
              name="chat"
              placeholder="Public"
              options={chatPrivacyOptions}
              className="chatSelect"
              icon={Lock}
              iconSide="left"
            />
            <div className={formStyles.buttons}>
              <BackButton
                path="/onboarding/personal-details"
                label={t("personalDetails.heading")}
              />
              <Button
                title={t("button.next")}
                wrapperClassNames="reverseButtonWrapper"
                disabled={!isValid}
                onClick={submitForm}
                type="button"
                buttonType="primary"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
