import { AnyAction } from "redux";
import { CLEAR_DEFAULT_COLLECTION, SET_DEFAULT_COLLECTION } from "./constants";
import { DefaultCollectionState } from "./types";

const initialState: DefaultCollectionState = {
  name: "",
  publicId: "",
};

const defaultCollectionReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DEFAULT_COLLECTION:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_DEFAULT_COLLECTION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default defaultCollectionReducer;
