import { useQuery } from "@apollo/client";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { GET_PLATFORM_STATISTICS } from "@graphql/platform-statistics/queries";
import { IPlatformStatistics } from "@graphql/platform-statistics/type";

import HivePulseBg from "@images/hive-pulse-bg.png";

import styles from "./SectionFive.module.scss";

const SectionFive = forwardRef((_props, ref: ForwardedRef<HTMLDivElement>) => {
  const { data } = useQuery<IPlatformStatistics>(GET_PLATFORM_STATISTICS);

  const platformStatistics = data?.platformStatistics;

  const HIVE_PULSE_TEXTS = [
    `Members of communities from ${platformStatistics?.membersCountriesCount} countries`,
    `The Hive platform is available in ${platformStatistics?.availableLanguagesCount} languages and more to come`,
    `${platformStatistics?.viewsCount} platform interactions by members this year`,
  ];

  const [textToShow, setTextToShow] = useState<string>();

  useEffect(() => {
    setTextToShow(HIVE_PULSE_TEXTS[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.sectionsWrapper}>
        <div className={styles.hexagonsContainer}>
          <div className={styles.hexagonOrange}>
            <span
              onMouseEnter={() => {
                setTextToShow(HIVE_PULSE_TEXTS[0]);
              }}
              className={styles.number}
            >
              {platformStatistics?.membersCountriesCount}
            </span>
          </div>
          <div className={styles.hexagonLight}>
            <span
              onMouseEnter={() => {
                setTextToShow(HIVE_PULSE_TEXTS[1]);
              }}
              className={styles.number}
            >
              {platformStatistics?.availableLanguagesCount}
            </span>
          </div>
          <div className={styles.hexagonDark}>
            <span
              onMouseEnter={() => {
                setTextToShow(HIVE_PULSE_TEXTS[2]);
              }}
              className={styles.number}
            >
              {platformStatistics?.viewsCount}
            </span>
          </div>
        </div>
        <div className={styles.textContainer}>
          <p>{textToShow}</p>
        </div>
      </div>
      <img className={styles.hivePulseBg} src={HivePulseBg} />
    </div>
  );
});

export default SectionFive;
