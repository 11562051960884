/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import { NotificationCard } from "@feature/notification";

import { INotification, INotificationsResponse } from "@graphql/notification/types";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { GET_USER_NOTIFICATIONS } from "@graphql/notification/queries";

import { MARK_NOTIFICATIONS_AS_READ } from "@graphql/notification/mutations";

import { NOTIFICATIONS_PAGINATION_SIZE } from "@constants/constants";

import { PlusOutlined } from "@ant-design/icons";
import globalStyles from "../../styles/Loader.module.scss";
import styles from "./NotificationPage.module.scss";

const NotificationPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [userNotificationList, setUserNotificationList] = useState<INotification[]>([]);
  const [notificationsTotalCount, setNotificationsTotalCount] = useState<number>(0);
  // load more
  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (notificationsLoader) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !notificationsLoader) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreNotifications();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const loadMoreNotifications = () => {
    getNotifications({
      variables: {
        notificationSearch: {
          size: NOTIFICATIONS_PAGINATION_SIZE,
          skip: userNotificationList.length,
        },
      },
    });
  };

  const [getNotifications, { loading: notificationsLoader }] =
    useLazyQuery<INotificationsResponse>(GET_USER_NOTIFICATIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setNotificationsTotalCount(data.user.notifications.totalCount);
        setUserNotificationList([
          ...userNotificationList,
          ...data.user.notifications.notifications,
        ]);
        // markNotificationsAsRead
        const userNotifications = data.user.notifications.notifications.map(
          (notification) => notification.publicId
        );
        setLoading(false);
        markNotificationsAsRead({
          variables: {
            notificationsPublicId: userNotifications,
          },
        });
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);

  const renderNotifications = () => {
    if (!userNotificationList?.length)
      return <h4 className={styles.text}>{t("requests.noNotificationPresent")}</h4>;
    return userNotificationList.map((notification) => (
      <div
        aria-label="notification"
        key={notification.publicId}
        className={styles.notification}
      >
        <NotificationCard notificationPageWrapper notification={notification} />
      </div>
    ));
  };

  useEffect(() => {
    setUserNotificationList([]);
    getNotifications({
      variables: {
        notificationSearch: {
          size: NOTIFICATIONS_PAGINATION_SIZE,
          skip: 0,
        },
      },
    });
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <h1 className={styles.title}>{t("notifications.pageTitle")}</h1>

      {loading && (
        <div className={globalStyles.loader}>
          <Loading borderless disableBoxShadow />
        </div>
      )}

      {userNotificationList?.length !== 0 && !loading && (
        <div className={styles.notificationsContainer}>{renderNotifications()}</div>
      )}

      {notificationsTotalCount > userNotificationList?.length && (
        <Button
          title={t("collections.buttons.viewMore")}
          wrapperClassNames={styles.loadMoreButton}
          buttonIcon={<PlusOutlined />}
          onClick={loadMoreNotifications}
          loading={notificationsLoader}
          disabled={notificationsLoader}
          customRef={loadMoreElementRef}
        />
      )}

      {userNotificationList?.length === 0 && !loading && (
        <div className={styles.notificationsContainer}>
          <p className={styles.noNewNotifiactions}>
            {t("requests.noNotificationPresent")}
          </p>
        </div>
      )}
    </div>
  );
};

export default NotificationPage;
