/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import NoEventsToExplore from "@components/NoEventsToExplore/NoEventsToExplore";
import GET_EVENTS from "@graphql/queries/events";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EVENTS_PLACEHOLDER, IEvent } from "types/event";
import ResourcesEventSection from "../../ResourcesEventsSection/ResourcesEventSection";

const pageSize = 4;
const text = "";
const PATH = "/portal/popular/events";

const ExploreEvents = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [eventsInitLoaded, setEventsInitLoaded] = useState<boolean>(false);
  const [eventsCount, setEventsCount] = useState<number>(0);

  const [getEvents, { loading: loadingEvent }] = useLazyQuery(GET_EVENTS, {
    fetchPolicy: "network-only",

    onCompleted: (data) => {
      if (!data?.events || !data?.events?.events) return;

      data.events.events.length
        ? setEvents([
            ...events.filter((event) => !(event.name === "placeholder")),
            ...data.events.events,
          ])
        : setEvents([]);
      setEventsCount(data?.events?.totalCount || 0);
      !eventsInitLoaded && setEventsInitLoaded(true);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });
  const loadEvents = () => {
    events.length &&
      setEvents([
        ...events,
        ...addEventPlaceholders(
          eventsCount - events.length < pageSize ? eventsCount - events.length : pageSize
        ),
      ]);

    getEvents({
      variables: {
        skip: events.length,
        size: pageSize,
        text,
        from: getDateInUtc(),
      },
    });
  };

  const addEventPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push(EVENTS_PLACEHOLDER);
    }
    return placeholderArray;
  };

  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <ResourcesEventSection
      events={events}
      eventsCount={eventsCount}
      loading={!eventsInitLoaded || loadingEvent}
      loadEvents={loadEvents}
      pageSize={pageSize}
      customHeaderTitle={t("events.popularEventsTitle")}
      noEventsPage={<NoEventsToExplore />}
      path={PATH}
    />
  );
};

export default ExploreEvents;
