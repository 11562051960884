import { AnyAction } from "redux";
import { LEAVE_GROUP } from "./constants";
import { ChatPage } from "./types";

const initialState: ChatPage = {
  isLeave: false,
};

const chatPageReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case LEAVE_GROUP:
      return {
        ...state,
        isLeave: payload,
      };
    default:
      return state;
  }
};

export default chatPageReducer;
