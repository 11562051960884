import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "@components/Modal/Modal";

import CancelIcon from "@images/cancelIcon.png";
import SearchIcon from "@images/search_icon.svg";

import { useInterestHelper } from "@customHooks/useInterestHelper";

import Button from "@components/Button/Button";
import { SelectUpdated } from "@components/formik/SelectUpdated/SelectUpdated";
import styles from "./UpdateCategoriesModal.module.scss";

interface IUpdateCategoriesModal {
  isOpen: boolean;
  fileName: string;
  selectedCategories: string[];
  previouslySelectedFileCategories: string[];
  close: () => void;
  handleUpdateCategories: (interests: string[]) => void;
  handleSubmitFileUpdateCategories: () => void;
}

const UpdateCategoriesModal = ({
  isOpen,
  close,
  fileName,
  selectedCategories,
  previouslySelectedFileCategories,
  handleUpdateCategories,
  handleSubmitFileUpdateCategories,
}: IUpdateCategoriesModal) => {
  const { t } = useTranslation();

  const { options: interestOptions } = useInterestHelper();

  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true);

  const defaultInterests = selectedCategories?.map((name) => name);

  useEffect(() => {
    if (isEqual(selectedCategories.sort(), previouslySelectedFileCategories.sort()))
      setIsUpdateDisabled(true);
    else setIsUpdateDisabled(false);
  }, [selectedCategories, previouslySelectedFileCategories]);

  return (
    <Modal modalType="primary" isOpen={isOpen}>
      <img
        src={CancelIcon}
        className={styles.removeIcon}
        onClick={close}
        alt=""
        aria-label="close-img"
      />
      <span className={styles.title}>{t("collections.updateCategoriesModal.title")}</span>
      <span className={styles.fileName}>{fileName}</span>
      <SelectUpdated
        label={t("addFiles.interestsLabel")}
        name="tags"
        placeholder={t("event.newEvent.select.tags.placeholder")}
        options={interestOptions}
        onChangeMulti={(selectedInterests) => handleUpdateCategories!(selectedInterests)}
        value={defaultInterests}
        icon={SearchIcon}
        isMulti
        isSearchable
      />
      <div className={styles.buttonContainer}>
        <span className={styles.cancelButton} onClick={close} aria-label="cancel">
          {t("collections.updateCategoriesModal.cancel")}
        </span>
        <Button
          type="submit"
          buttonType="primary"
          disabled={isUpdateDisabled}
          title={t("collections.updateCategoriesModal.submit")}
          onClick={handleSubmitFileUpdateCategories}
          aria-label="button-submit"
        />
      </div>
    </Modal>
  );
};

export default UpdateCategoriesModal;
