import { Pronouns } from "./enum";

const prefix = "pronouns.";

export const PronounsToTranslationKey = {
  [Pronouns.HE]: `${prefix}he`,
  [Pronouns.SHE]: `${prefix}she`,
  [Pronouns.THEY]: `${prefix}they`,
  [Pronouns.NONE]: `${prefix}none`,
};
