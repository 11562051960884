/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

import UPDATE_USER from "@graphql/mutations/updateUser";
import GET_USERS from "@graphql/queries/users";

import Button from "@components/Button/Button";
import Pills from "@components/Pills/Pills";
import SelectComponent from "@components/Select/Select";

import { useInterestHelper } from "@customHooks/useInterestHelper";

import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { DefaultOption } from "types/select";

import SearchIcon from "@images/search_icon.svg";

import styles from "./SettingsInterests.module.scss";

interface ISettingInterestsProps {
  currentUserInterests: Array<string>;
}

const SettingsInterests = ({ currentUserInterests }: ISettingInterestsProps) => {
  const { t } = useTranslation();
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const { options: allInterestOptions, mapToOptions } = useInterestHelper();
  const [interestOptions, setInterestOptions] = useState<Array<DefaultOption>>([]);
  const [selectedInterests, setSelectedInterests] =
    useState<Array<string>>(currentUserInterests);
  const [originalInterests, setOriginalInterests] =
    useState<Array<string>>(currentUserInterests);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const isUserInterest = (interest: string) => selectedInterests.includes(interest);

  useEffect(() => {
    setSelectedInterests(currentUserInterests);
    setOriginalInterests(currentUserInterests);
  }, [currentUserInterests]);

  useEffect(() => {
    if (!allInterestOptions.length) return;

    setInterestOptions(allInterestOptions.filter(({ value }) => !isUserInterest(value)));
  }, [selectedInterests, allInterestOptions]);

  const isValid = () => {
    return (
      originalInterests.sort().join(",") !== selectedInterests.sort().join(",") &&
      selectedInterests.length !== 0
    );
  };

  const onSave = () => {
    updateUser({
      variables: {
        user: {
          interests: selectedInterests.map((interest) => ({ name: interest })),
        },
      },
      onCompleted: () => {
        displaySuccessNotification();
        setIsEditMode(false);
      },
      onError: () => {
        displayServerError();
      },
      refetchQueries: [GET_USERS],
    });
  };

  return (
    <div className={styles.container} e2e-test-id="categories-container">
      <div className={styles.wrapper}>
        <div className={styles.headingContainer}>
          <span className={styles.title} e2e-test-id="categories-title">
            {t("accountSettings.categories.heading")}
          </span>
        </div>
        <div className={styles.interestsHeadingContainer}>
          <span className={styles.interestHeading}>
            {t("accountSettings.categories.subheading")}
          </span>
          {!isEditMode ? (
            <Button
              buttonType="link"
              data-testid="edit-btn"
              e2eTestId="update-categories-link"
              title={t("accountSettings.categories.button.update")}
              onClick={() => setIsEditMode(!isEditMode)}
            />
          ) : (
            <></>
          )}
        </div>

        {selectedInterests.length === 0 && (
          <span className={styles.noInterestsHeading}>
            {t("accountSettings.categories.noInterestsSubheading")}
          </span>
        )}

        <div
          className={styles.interestsContainer}
          e2e-test-id="categories-pills-container"
        >
          <Pills
            pills={selectedInterests.map((interest) => ({
              value: interest,
              label: t(`categories.type.${interest}`),
            }))}
            onPillClick={(selectedInterestOption) =>
              setSelectedInterests(
                selectedInterests.filter(
                  (interest) => interest !== selectedInterestOption.value
                )
              )
            }
            showCloseButton={isEditMode}
            isPillClickDisabled={!isEditMode}
          />
        </div>

        {isEditMode && (
          <div>
            {!!selectedInterests.length && (
              <Button
                buttonType="link"
                title={t("accountSettings.categories.button.removeAll")}
                onClick={() => setSelectedInterests([])}
              />
            )}
            <div className={styles.headingContainer}>
              <h4>{t("accountSettings.categories.addHeading")}</h4>
            </div>
            <SelectComponent
              name="interests"
              options={interestOptions}
              value={mapToOptions(selectedInterests)}
              onChange={(options: Array<DefaultOption>) => {
                setSelectedInterests(options.map(({ value }) => value));
              }}
              className="searchCountry"
              controlShouldRenderValue={false}
              showDropdown
              isMulti
              isClearable={false}
              isSearchable
              virtualized
              selectWithIcon
              icon={SearchIcon}
            />
            <div className={styles.buttonsContainer}>
              <Button
                title={t("accountSettings.categories.button.save")}
                onClick={onSave}
                buttonType="primary"
                disabled={!isValid() || loading}
                aria-label="submitBtn"
              />
              <Button
                buttonType="link"
                data-testid="edit-btn"
                title={t("accountSettings.categories.button.cancel")}
                onClick={() => {
                  setSelectedInterests(currentUserInterests);
                  setIsEditMode(!isEditMode);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsInterests;
