import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Tabs as AntdTabs } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { ITab } from "./type";

import { buildPath } from "./helper";

import styles from "./Tabs.module.scss";

export interface ITabsProps {
  tabs: Array<ITab>;
  routePrefix: string;
  size?: SizeType;
  extraContent?: React.ReactNode;
  fullWidthTabBar?: boolean;
  regularSizeTabs?: boolean;
}

export const Tabs = ({
  routePrefix,
  size,
  fullWidthTabBar,
  regularSizeTabs,
  extraContent,
  tabs,
}: ITabsProps) => {
  const [activeTab, setActiveTab] = useState<ITab>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const defaultActiveTab = tabs.find(({ path }) => location.pathname.includes(path));
    if (defaultActiveTab) {
      updateActiveTab(defaultActiveTab.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, location.pathname]);

  useEffect(() => {
    if (activeTab && !location.pathname.includes(activeTab.path)) {
      history.push(`${routePrefix}/${buildPath(activeTab)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, routePrefix]);

  const updateActiveTab = (id: string) => {
    const foundActiveTab = tabs.find((tab) => tab.id === id);
    if (foundActiveTab) {
      setActiveTab(foundActiveTab);
    }
  };

  const renderCustomTabBar = <T,>(props: T, DefaultTabBar: React.ElementType) => (
    <DefaultTabBar
      {...props}
      className={`${fullWidthTabBar ? styles.customTabPane : ""} ${
        regularSizeTabs ? styles.customTabButton : ""
      }`}
    />
  );

  const getTabs = () => {
    return tabs.map((tab) => ({
      key: tab.id,
      label: tab.name,
      children: tab.content,
    }));
  };

  return (
    <>
      <AntdTabs
        items={getTabs()}
        activeKey={activeTab?.id}
        size={size}
        tabBarExtraContent={activeTab?.isExtraContentDisplayed && extraContent}
        renderTabBar={renderCustomTabBar}
        onChange={updateActiveTab}
        destroyInactiveTabPane
      />
      {activeTab?.subTab ? <Tabs {...activeTab.subTab} /> : <></>}
    </>
  );
};
