import { ICollection } from "@graphql/collection/type";
import { IEvent } from "./event";
import { IFile } from "./file";
import { IInterest } from "./interest";
import { IJoinRequest } from "./joinRequest";
import { ILinkListData } from "./links";
import { IUser } from "./user";

export enum CommunityType {
  open = "Open",
  closed = "Closed",
  private = "Private",
}

export interface ICommunity {
  name: string;
  description: string;
  sendbirdId: string;
  coverImageName?: string;
  communityImageUrl: string;
  imageDirectory?: string;
  communityBannerName?: string;
  communityBannerUrl?: string;
  communityBannerDirectory?: string;
  creationTime?: string;
  communityType?: CommunityType;
  members: Array<IUser>;
  admins: Array<IUser>;
  interests?: Array<IInterest>;
  communityCreator: IUser;
  joinRequests: Array<IJoinRequest>;
  goals?: string;
  rules?: string;
  channels?: Array<string>;
  allCommunityCollectionsCount?: number;
  defaultCollection?: ICollection;
  communityEvents?: Array<IEvent>;
  pinnedFiles?: Array<IFile>;
  communityLinks?: Array<ILinkListData>;
}

export interface ICommunityPreview {
  name: string;
  sendbirdId: string;
  description: string;
  coverImageName?: string;
  communityImageUrl: string;
  imageDirectory?: string;
  communityBannerName?: string;
  communityBannerUrl?: string;
  communityBannerDirectory?: string;
  communityType?: string;
  interests?: Array<IInterest>;
  joinRequests: Array<IJoinRequest>;
  goals?: string;
  rules?: string;
  channels?: Array<string>;
  allCommunityCollectionsCount?: number;
  defaultCollection?: ICollection;
  communityEvents?: Array<IEvent>;
  pinnedFiles?: Array<IFile>;
  communityLinks?: Array<ILinkListData>;
}

export interface ICommunityResponse {
  community: ICommunity;
}

export interface ICommunityPreviewResponse {
  communityPreview: ICommunityPreview;
}

export interface ICommunitiesResponse {
  communities: { communities: ICommunity[] };
}

export interface ISearchedCommunitiesResponse {
  communitiesForWhoAdmins: {
    totalCount: number;
    communities: ICommunity[];
  };
}

export type TableCommunity = {
  name: string;
  dateCreated?: string;
  communityType?: CommunityType;
  communityCreator: {
    name: string;
    email: string;
  };
};

export interface IUserCommunitiesResponse {
  user: { email: string; communities: ICommunity[] };
}

export interface ICommunityResourcesResponse {
  communities: {
    communities: ICommunity[];
    totalCount: number;
    __typename: string;
  };
}

export interface IBanUserRequest {
  banRequest: {
    sendbirdId: string;
    userToBanPublicId: string;
  };
}

export interface ICommunityManagedByUser {
  name: string;
  communityImageUrl: string;
  sendbirdId: string;
}

export interface ICommunityManagedByUserResponse {
  user: { communitiesManagedByMe: ICommunityManagedByUser[] };
}
