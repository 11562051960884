import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";

import TextAsideBanner from "@components/TextAsideBanner/TextAsideBanner";

import OpenEventCards from "@components/OpenEventCards/OpenEventCards";

import Banner from "../../images/events-banner.svg";
import "../../modules/LandingPage/styles/global.scss";

interface IOpenPublicEventsProps {
  signupRef: MutableRefObject<HTMLDivElement | null>;
}

const OpenPublicEvents = ({ signupRef }: IOpenPublicEventsProps) => {
  const { t } = useTranslation();

  return (
    <div className="main-app">
      <TextAsideBanner
        title={t("openPublicEventsPage.topBannerTitle")}
        imgAlt={t("openPublicEventsPage.topBannerImageAlt")}
        imgUrl={Banner}
      />
      <OpenEventCards />
      {/* Removed as a hotfix until it is updated */}
      {/* <SectionFour ref={signupRef} /> */}
    </div>
  );
};

export default OpenPublicEvents;
