import { useEffect, useState } from "react";

export const useCardTextMaxLength = () => {
  const [maxTitleLength, setMaxTitleLength] = useState<number>(30);

  useEffect(() => {
    if (!window.innerWidth) return;

    if (1400 <= window.innerWidth && window.innerWidth < 1440) setMaxTitleLength(35);
    if (window.innerWidth >= 1440) setMaxTitleLength(40);
  }, []);

  return { maxTitleLength };
};
