import SearchIcon from "@images/GlassesSearchIcon.svg";
import { useTranslation } from "react-i18next";
import styles from "./NoUpcomingEvents.module.scss";

const NoUpcomingEvents = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src={SearchIcon} alt="" />
      <span className={styles.title}>{t("noUpcomingEvents.title")}</span>
      <span className={styles.subtitle}>{t("noUpcomingEvents.subtitle")}</span>
    </div>
  );
};

export default NoUpcomingEvents;
