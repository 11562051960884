/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  COMMUNITY_GUIDLINES_CHECK_TEXT_ONE,
  COMMUNITY_GUIDLINES_CHECK_TEXT_TWO,
  COMMUNITY_GUIDLINES_ITEMS,
  LAST_SECTION_CHECK_TEXT,
  SECOND_SECTION_CHECK_TEXT,
  SECTION_THREE_CHECK_TEXT,
  TABLE_CHECK_TEXT,
  TC_ITEMS,
  TC_ITEMS_LAST,
  TC_SECOND_SECTION_ITEMS,
  tableContent,
} from "@constants/constants";
import CommunityGuidelinesImage from "@images/communityGuidelines.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Document.module.scss";

interface IDocumentProps {
  document: Array<DocumentRoot>;
}

interface DocumentRoot {
  title?: string | null;
  content?: any;
  mainTitle?: string;
}

const Document = ({ document }: IDocumentProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<string>("");

  useEffect(() => {
    if (
      window.location.pathname.includes("/code-of-conduct") ||
      window.location.pathname.includes("/portal/conduct") ||
      window.location.pathname.includes("/conduct")
    )
      setPage("communityGuidlines");
    if (window.location.pathname.includes("/terms-and-conditions"))
      setPage("termsAndCoditions");
  }, [window.location.pathname]);

  const handleContentData = (content: any) => {
    if (content === t(COMMUNITY_GUIDLINES_CHECK_TEXT_TWO)) {
      return (
        <>
          <ul>
            {COMMUNITY_GUIDLINES_ITEMS.map((listItem: string) => (
              <li className={styles.listItem} key={listItem}>
                {renderLinkifiedParagraph(t(`${page}.listItems.${listItem}`))}
              </li>
            ))}
          </ul>
          <span>{t(`${page}.contents.contentThree`)}</span>
        </>
      );
    }

    if (content === SECTION_THREE_CHECK_TEXT) {
      return (
        <>
          <ul>
            {TC_ITEMS_LAST.map((listItem: string) => (
              <li className={styles.listItem} key={listItem}>
                {renderLinkifiedParagraph(t(`${page}.listItemsLastSection.${listItem}`))}
              </li>
            ))}
          </ul>
        </>
      );
    }

    if (content === t(TABLE_CHECK_TEXT)) {
      return (
        <>
          <span>{t(`${page}.contents.tableContent`)}</span>;
          <table className={styles.documentTable}>
            <thead>
              <tr>
                {tableContent.header.map((th: string) => (
                  <th key={th}>{t(th)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableContent.body.map((bodyItem: any, index: number) => (
                <tr key={index}>
                  {bodyItem.map((dataItem: any) => (
                    <td key={dataItem}>{handleContentData(t(dataItem))}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    }
    if (content === LAST_SECTION_CHECK_TEXT) {
      return (
        <>
          <ul>
            {TC_SECOND_SECTION_ITEMS.map((listItem: string) => (
              <li className={styles.listItem} key={listItem}>
                {renderLinkifiedParagraph(
                  t(`${page}.listItemsSecondSection.${listItem}`)
                )}
              </li>
            ))}
          </ul>
        </>
      );
    }

    if (content === t(SECOND_SECTION_CHECK_TEXT)) {
      return (
        <>
          <span>{t(`${page}.contents.contentSix`)}</span>
          <ul>
            {TC_ITEMS.map((listItem: string) => (
              <li className={styles.listItem} key={listItem}>
                {renderLinkifiedParagraph(t(`${page}.listItems.${listItem}`))}
              </li>
            ))}
          </ul>
        </>
      );
    }

    if (content === t(COMMUNITY_GUIDLINES_CHECK_TEXT_ONE)) {
      return (
        <>
          <span>{t(`${page}.contents.sectionOneContentOne`)}</span>
          <img className={styles.bannerImage} src={CommunityGuidelinesImage} />
        </>
      );
    }

    if (content?.tableContent) return renderTable(content.tableContent);
    return typeof content === "string"
      ? renderLinkifiedParagraph(content)
      : content?.map((item: any) => handleContentData(item));
  };

  const renderTable = (tableData: any) => (
    <table className={styles.documentTable}>
      <thead>
        <tr>
          {tableData.header.map((th: string) => (
            <th key={th}>{th}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.body.map((bodyItem: any, index: number) => (
          <tr key={index}>
            {bodyItem.map((dataItem: any) => (
              <td key={dataItem}>{dataItem}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderLinkifiedParagraph = (text: string) => (
    <p
      aria-label="content"
      className={styles.paragraph}
      key={text}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );

  return (
    <div className={styles.documentContainer}>
      {document.map(({ title, content, mainTitle }, index) => (
        <div className={styles.textSection} key={index}>
          {mainTitle && <h1 aria-label="main-title">{t(`${page}.${mainTitle}`)}</h1>}
          {title && (
            <h2 aria-label="title" className={styles.sectionTitle}>
              {t(`${page}.titles.${title}`)}
            </h2>
          )}
          {content && handleContentData(t(`${page}.contents.${content}`))}
        </div>
      ))}
    </div>
  );
};

export default Document;
