import CloseToastBlack from "@images/close_toast_black.svg";
import CloseToastWhite from "@images/close_toast_white.svg";
import i18next from "i18next";
import { useEffect, useState } from "react";

import { CloseButtonProps, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./ToastNotification.module.scss";

const CloseButton = ({ closeToast, type }: CloseButtonProps) => (
  <div onClick={closeToast}>
    <img src={type === toast.TYPE.ERROR ? CloseToastBlack : CloseToastWhite} alt="" />
  </div>
);

const ToastNotification = () => {
  const [direction, setDirection] = useState<string>("ltr");

  useEffect(() => {
    const callback = (language: string) => {
      setDirection(language === "ar" ? "rtl" : "ltr");
    };

    i18next.on("languageChanged", callback);

    return () => {
      i18next.off("languageChanged", callback);
    };
  }, []);

  return (
    <ToastContainer
      autoClose={5000}
      draggable={true}
      hideProgressBar={true}
      closeButton={CloseButton}
      className={styles.notificationContainer}
      toastClassName={styles.notificationToast}
      bodyClassName={styles.notificationBody}
      theme="colored"
      icon={false}
      position={direction === "rtl" ? "top-left" : "top-right"}
    />
  );
};

export default ToastNotification;
