export const ACCOUNT_SETTINGS_ROUTES_PREFIX = "/portal/account-settings";

export enum ACCOUNT_SETTINGS {
  PERSONAL_DETAILS = "personal-details",
  PROFILE_DETAILS = "profile-details",
  CATEGORIES = "categories",
  LOCATIONS = "locations",
  INTERESTS_AND_SOCIAL_MEDIA = "interests-and-social-media",
}

export const ACCOUNT_SETTINGS_ROUTES = {
  PERSONAL_DETAILS: `${ACCOUNT_SETTINGS_ROUTES_PREFIX}/${ACCOUNT_SETTINGS.PERSONAL_DETAILS}`,
  PROFILE_DETAILS: `${ACCOUNT_SETTINGS_ROUTES_PREFIX}/${ACCOUNT_SETTINGS.PROFILE_DETAILS}`,
  CATEGORIES: `${ACCOUNT_SETTINGS_ROUTES_PREFIX}/${ACCOUNT_SETTINGS.CATEGORIES}`,
  LOCATIONS: `${ACCOUNT_SETTINGS_ROUTES_PREFIX}/${ACCOUNT_SETTINGS.LOCATIONS}`,
  INTERESTS_AND_SOCIAL_MEDIA: `${ACCOUNT_SETTINGS_ROUTES_PREFIX}/${ACCOUNT_SETTINGS.INTERESTS_AND_SOCIAL_MEDIA}`,
};
