/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styles from "./ExploreViewMore.module.scss";

import Loading from "@components/Loading/Loading";

import { SEARCH_COLLECTIONS } from "@graphql/collection/queries";

import { COLLECTIONS_GRID_SIZE, SORTING_MOST_RELEVANT } from "@constants/constants";
import GET_USERS from "@graphql/queries/users";
import { IUserResponse } from "types/user";

import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";

import BackButton from "@components/BackButton/BackButton";
import { ICollection } from "@graphql/collection/type";
import { displayServerError } from "@services/NotificationService/NotifacitonService";

const ExploreViewMore = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [collections, setCollections] = useState<Array<ICollection>>([]);
  const [collectionsCount, setCollectionsCount] = useState<number>(0);

  const { data: userData } = useQuery<IUserResponse>(GET_USERS);

  const [getCollections, { loading }] = useLazyQuery(SEARCH_COLLECTIONS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data?.collections?.collections) return;
      setCollections([...collections, ...data.collections.collections]);
      setCollectionsCount(data.collections.totalCount);
    },
    onError: (error) => displayServerError(error),
  });

  const loadCollections = () => {
    getCollections({
      variables: {
        collectionSearch: {
          skip: collections.length,
          size: COLLECTIONS_GRID_SIZE,
          interests: userData?.user?.interests.map((i) => i.name) || [],
          sortingCriteria: SORTING_MOST_RELEVANT,
        },
      },
    });
  };

  useEffect(() => {
    if (userData?.user) loadCollections();
  }, [userData]);

  return (
    <div className={styles.outer} data-testid="explore-viev-more-container">
      <div>
        {loading ? (
          <Loading disableBoxShadow />
        ) : (
          <>
            <BackButton
              label={t("collections.backButtonExplore")}
              onClick={() => history.push("/portal/collections/explore")}
            />
            <CollectionsGrid
              collections={collections}
              loadMore={loadCollections}
              collectionsLoading={loading}
              collectionsCount={collectionsCount}
              collectionsLoadingMore={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ExploreViewMore;
