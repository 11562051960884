import {
  ComposableMap,
  Geographies,
  Geography,
  ProjectionConfig,
} from "react-simple-maps";

// Disabled because users can't click on the map - Charlotte
// import ZoomIn from "../../images/zoomIn.svg";
// import ZoomOut from "../../images/zoomOut.svg";

import countries from "./countries.json";
import styles from "./MapChart.module.scss";

interface IMapCharProps {
  selectedCountry: Array<string>;
  className?: string;
  projectionConfig?: ProjectionConfig;
}

const MapChart = ({ selectedCountry, className, projectionConfig }: IMapCharProps) => {
  // const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

  // Disabled because users can't click on the map - Charlotte
  // const handleZoomIn = () => {
  //   if (position.zoom >= 4) return;
  //   setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  // };
  // const handleZoomOut = () => {
  //   if (position.zoom <= 1) return;
  //   setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  // };
  // const handleMoveEnd = (position: {
  //   coordinates: Array<number>;
  //   zoom: number;
  // }) => {
  //   setPosition(position);
  // };

  return (
    <>
      <ComposableMap
        projectionConfig={{
          scale: 160,
          ...projectionConfig,
        }}
        data-tip=""
        data-testid="map"
        className={`${className ? className : styles.mapStyle} ${styles.notClickable}`}
      >
        {/*
  			Disabled because users can't click on the map - Charlotte
				<ZoomableGroup
          zoom={position.zoom}
          onMoveEnd={handleMoveEnd}
          translateExtent={
            position.zoom === 1
              ? [
                  [0, 0],
                  [mapWidth, mapHeight],
                ]
              : [
                  [0, 0],
                  [mapWidth + 150, mapHeight + 20],
                ]
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          filterZoomEvent={(evt: any) => {
            return evt.type === "wheel" ? false : true;
          }}
        > */}
        <Geographies geography={countries}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: selectedCountry.some((x: string) => {
                      return x === geo.properties.NAME;
                    })
                      ? "rgba(0, 147, 213, 1)"
                      : "rgba(211, 223, 231, 1)",
                    stroke: "white",
                    strokeWidth: 0.35,
                    outline: "none",
                    transition: "all 250ms",
                  },
                  hover: {
                    fill: "rgba(211, 223, 231, 1)",
                    outline: "none",
                    stroke: "white",
                    strokeWidth: 0.35,
                  },
                }}
              />
            ))
          }
        </Geographies>
        {/* </ZoomableGroup> */}
      </ComposableMap>

      {/* Disabled because users can't click on the map - Charlotte */}
      {/* <div className={styles.zoomButtons}>
        <img src={ZoomIn} className={styles.zoomIn} onClick={handleZoomIn} />
        <img src={ZoomOut} className={styles.zoomOut} onClick={handleZoomOut} />
      </div> */}
    </>
  );
};

export default MapChart;
