import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import UserDrawer from "@modules/UserDrawer/UserDrawer";

import getStorageImage from "@utils/getStorageImage";
import { handleAcceptingRedirect } from "@utils/helpers";

import pin from "@images/location.svg";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import { IUserDashboard } from "types/user";
import withUser from "../../hoc/withUser";
import styles from "./UserCard.module.scss";

const UserCard = (
  user: Pick<
    IUserDashboard,
    | "fullName"
    | "email"
    | "bio"
    | "organization"
    | "country"
    | "userPhotoName"
    | "imageDirectory"
  >
) => {
  const { fullName, email, bio, country, userPhotoName, imageDirectory } = user;
  const { t } = useTranslation();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");

  const createMarkup = () => {
    if (bio) return { __html: bio };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBioLinkClick = (e: any) => {
    if (e.target.nodeName === "A") {
      e.preventDefault();
      setIsConfirmationModalOpened(true);
      setClickedUrl(e.target.href);
    }
  };

  const renderBioValue = () => {
    return (
      <div
        className={styles.bio}
        dangerouslySetInnerHTML={createMarkup()}
        onClick={handleBioLinkClick}
      />
    );
  };

  const userImage = getStorageImage({
    image: userPhotoName,
    directoryName: imageDirectory,
    type: STORAGE_IMAGE_TYPE_USER,
  });

  const handleUserSelected = () => {
    setSelectedUserEmail(email);
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  return (
    <>
      <div className={styles.outer}>
        <div className={styles.userHeader} onClick={handleUserSelected}>
          <div
            style={{ backgroundImage: `url(${userImage})` }}
            className={styles.avatarIcon}
          />
          <div className={styles.userInfo}>
            <span className={styles.name}>{fullName}</span>
            <br />
            <span className={styles.userRole}>
              {getRoleOrganizationText(user.organization)}
            </span>
          </div>
        </div>
        <div className={styles.locationRow}>
          <img src={pin} />
          {country ? country?.name : t("users.noLocation")}
        </div>
        {renderBioValue()}
      </div>
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)}
        url={clickedUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};

export default UserCard;
