import { IPartialUser } from "./user";

export enum NotificationType {
  COMMUNITY,
  EVENT,
  COLLECTION,
}

export enum CommunityNotificationType {
  GENERAL,
  REMOVE,
  ADDED,
  REQUEST,
}

export interface ILink {
  text: string;
  path?: string;
  onClick?: () => void;
}

export interface INotification {
  id: string;
  notificationType: NotificationType;
  type: CommunityNotificationType;
  timestamp: number;
  text: string;
  title: string;
  titlePath?: string;
  user?: IPartialUser;
  link: ILink;
  links?: Array<ILink>;
  onClick?: () => void;
  isRead: boolean;
  image?: string;
}

export interface ICommunityNotification extends INotification {
  notificationType: NotificationType.COMMUNITY;
  type: CommunityNotificationType;
  sendBirdId: string;
  user: IPartialUser;
  isInvite?: boolean;
}
