/* eslint-disable react-hooks/exhaustive-deps */
import EventCard from "@components/EventCard/EventCard";
import Loading from "@components/Loading/Loading";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";
import { NO_EVENTS_MESSAGES } from "@constants/constants";
import calender from "@images/CalendarIcon.svg";
import { formatDate } from "@utils/eventsHelper";
import { disableNext } from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SlickSlider from "react-slick";
import { IEvent } from "types/event";
import styles from "./ResourcesEventSection.module.scss";
import { isDocumentRtl } from "@utils/checkDocumentDir";

export interface IResourcesEventSectionProps {
  events: Array<IEvent>;
  loading: boolean;
  customHeaderTitle?: string;
  eventsCount: number;
  hideViewMoreButton?: boolean;
  loadEvents: () => void;
  pageSize: number;
  searchResourcesSubmitted?: boolean;
  noEventsPage?: JSX.Element;
  path?: string;
}

const PLACEHOLDER = "placeholder";
const { noEvents, noSearchedEvents } = NO_EVENTS_MESSAGES;

const ResourcesEventSection = ({
  events,
  loading,
  loadEvents,
  customHeaderTitle,
  eventsCount,
  hideViewMoreButton,
  pageSize,
  searchResourcesSubmitted,
  noEventsPage,
  path,
}: IResourcesEventSectionProps) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [sliderPage, setSliderPage] = useState<number>(0);
  const [noEventsMessage, setNoEventsMessage] = useState<string>("");

  const eventChildRef = useRef<SlickSlider>(null);

  const handleNextEvents = () => {
    isDocumentRtl()
      ? eventChildRef.current?.slickPrev()
      : eventChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
    if (events.length >= eventsCount) {
      return;
    } else {
      loadEvents();
    }
  };

  const handlePreviousEvents = () => {
    isDocumentRtl()
      ? eventChildRef.current?.slickNext()
      : eventChildRef.current?.slickPrev();
    setSliderPage(sliderPage - 1);
  };

  const openAllEvents = () => {
    if (path) return history.push(path);
    history.push("/portal/resources/events-page");
  };

  const disableSliderNext = () => {
    return loading || disableNext(sliderPage, eventsCount, pageSize);
  };

  const disableSliderPrevious = () => {
    return loading || sliderPage === 0;
  };

  const checkIfPlacehodler = (name: string) => {
    if (name === PLACEHOLDER) return true;
    return false;
  };

  useEffect(() => {
    const pathname = window.location.href;
    localStorage.setItem("pageUrl", JSON.stringify(pathname));
  }, []);

  useEffect(() => {
    if (!noEventsPage) {
      setNoEventsMessage(searchResourcesSubmitted ? noSearchedEvents : noEvents);
    }
  }, [searchResourcesSubmitted]);

  const renderNoEventsMessage = () => (noEventsPage ? noEventsPage : t(noEventsMessage));

  return (
    <div
      className={styles.eventsResourcesContainer}
      data-testid="rs-events-container"
      e2e-test-id="events-container"
    >
      <div className={styles.headingContainer}>
        <div className={styles.titleContainer}>
          {!customHeaderTitle && (
            <>
              <img className={styles.eventIcon} src={calender} alt="" />
              <span className={styles.title}>{t("resources.eventsTitle")}</span>
            </>
          )}
          {!!events.length && (
            <span className={styles.customHeaderTitle} e2e-test-id="section-header-title">
              {customHeaderTitle}
            </span>
          )}
        </div>

        {!!events.length && (
          <div
            className={styles.communitiesButtonContainer}
            data-testid="shift-buttons-container"
            e2e-test-id="slider-controls"
          >
            <ShiftButtons
              onForwardClick={handleNextEvents}
              onBackClick={handlePreviousEvents}
              viewAll={openAllEvents}
              hideSeeAllButton={hideViewMoreButton}
              disabledBack={disableSliderPrevious()}
              disabledForward={disableSliderNext()}
            />
          </div>
        )}
      </div>
      {!events.length && !loading ? (
        <span className={styles.textContainer} data-testid="no-results-container">
          {renderNoEventsMessage()}
        </span>
      ) : (
        <div
          className={styles.eventsResourcesContainer}
          data-testid="results-container"
          e2e-test-id="event-cards"
        >
          {loading && !events.length ? (
            <div className={styles.loaderWrapper}>
              <Loading borderless disableBoxShadow e2eTestId="events-loader" />
            </div>
          ) : (
            <Slider
              forwardedRef={eventChildRef}
              items={events?.map((event: IEvent, index: number) =>
                checkIfPlacehodler(event.name) ? (
                  <Loading />
                ) : (
                  <EventCard
                    startDate={formatDate(event?.startDate, i18n.language)}
                    key={index}
                    endDate={formatDate(event?.endDate, i18n.language)}
                    name={event?.name}
                    userName={event?.eventHost?.fullName}
                    event={event}
                  />
                )
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResourcesEventSection;
