/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { GroupChannel, SendBirdInstance, User } from "sendbird";

import { useSendBirdContext } from "../context/context";

import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { useGetMembers } from "../useGetMembers/useGetMembers";
import { MessageType } from "../useSendBirdMessage/type";

import { IUseSendBirdGroupChannel, LeaveChannel } from "./type";

interface IUseSendBirdGroupProps {
  groupChannel?: GroupChannel;
}

export const useSendBirdGroupChannel = ({
  groupChannel,
}: IUseSendBirdGroupProps): IUseSendBirdGroupChannel => {
  const { t } = useTranslation();
  const { sb } = useSendBirdContext();
  const [blockedUsers, setBlockedUsers] = useState<Array<User>>([]);
  const allMembers = useGetMembers(groupChannel);
  console.log(allMembers);
  console.log(blockedUsers);

  const initializeBlockedUsers = useCallback((sendBirdInstance: SendBirdInstance) => {
    const list = sendBirdInstance.createBlockedUserListQuery();
    list.next(function (users, error) {
      if (error) {
        displayNotSuccessNotification();
        return;
      }

      setBlockedUsers(users);
    });
  }, []);

  useEffect(() => {
    if (!groupChannel || !sb) return;

    initializeBlockedUsers(sb);
  }, [groupChannel, initializeBlockedUsers, sb]);

  const leaveChannel: LeaveChannel = useCallback(
    (onSuccess) => {
      if (!groupChannel) return;

      groupChannel.leave((__, error) => {
        if (error) {
          displayNotSuccessNotification(error);
          return;
        }

        onSuccess?.();
      });
    },
    [groupChannel]
  );

  const deleteChannel = useCallback(
    (onSuccess?: () => void) => {
      if (!groupChannel) return;

      groupChannel.delete(() => {
        onSuccess?.();
      });
    },
    [groupChannel]
  );

  const blockUser = useCallback(
    (id: string, onSuccess?: () => void) => {
      if (!sb) return;

      sb.blockUserWithUserId(id, function (user: User, error) {
        if (error) {
          displayNotSuccessNotification(error);
          return;
        }

        setBlockedUsers((prev) => [...prev, user]);
        onSuccess?.();
        displaySuccessNotification(t("blockUser.isBlocked", { name: user.nickname }));
      });
    },
    [sb, t]
  );

  const toggleReaction = useCallback(
    async (key: string, message?: MessageType, userId?: string) => {
      if (!message || !userId || !groupChannel) return;
      const previousReaction = message.reactions.find(({ userIds }) =>
        userIds.includes(userId)
      );

      if (!previousReaction) {
        await groupChannel.addReaction(message, key);
        return;
      }

      for (const reaction of message.reactions) {
        await groupChannel.deleteReaction(message, reaction.key);
      }
      if (previousReaction.key !== key) {
        await groupChannel.addReaction(message, key);
      }
    },
    [groupChannel]
  );

  const sendBirdGroupHelper = useMemo(
    () => ({
      state: {
        groupChannel,
        blockedUsers,
        allMembers,
      },
      actions: {
        blockUser,
        toggleReaction,
      },
      helpers: {
        leaveChannel,
        deleteChannel,
      },
    }),
    [
      groupChannel,
      blockedUsers,
      allMembers,
      blockUser,
      toggleReaction,
      leaveChannel,
      deleteChannel,
    ]
  );

  return sendBirdGroupHelper;
};
