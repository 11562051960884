import styles from "./Tag.module.scss";

interface ITagProps {
  value: string;
  color: string;
  backgroundColor: string;
  icon?: string;
}

const Tag = ({ value, color, backgroundColor, icon }: ITagProps) => {
  return (
    <div style={{ color, backgroundColor }} className={styles.tag}>
      {icon && <img src={icon} />}
      {value}
    </div>
  );
};

export default Tag;
