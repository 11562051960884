import { gql } from "@apollo/client";

const CHANGE_COMMUNITY_CREATOR = gql`
  mutation changeCommunityCreator(
    $changeCommunityCreator: ChangeCommunityCreatorInputType!
  ) {
    changeCommunityCreator(changeCommunityCreator: $changeCommunityCreator) {
      name
    }
  }
`;

export default CHANGE_COMMUNITY_CREATOR;
