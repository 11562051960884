/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useLazyQuery } from "@apollo/client";
import BackButton from "@components/BackButton/BackButton";
import { SEARCH_COLLECTIONS } from "@graphql/collection/queries";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";
import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";
import { COLLECTIONS_GRID_SIZE } from "@constants/constants";

import { ICollection } from "@graphql/collection/type";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import styles from "./CollectionsResources.module.scss";

interface ICollectionResources {
  searchValue: string;
  interests: Array<string>;
}

const CollectionsResources = ({ searchValue, interests }: ICollectionResources) => {
  const [gridCollectionsCount, setGridCollectionsCount] = useState<number>(0);
  const [gridCollectionsInitLoaded, setGridCollectionsInitLoaded] =
    useState<boolean>(false);
  const [gridCollectionsLoadingMore, setGridCollectionsLoadingMore] =
    useState<boolean>(false);
  const [gridCollections, setGridCollections] = useState<Array<ICollection>>([]);
  const { t } = useTranslation();

  const [getGridCollectionsResources] = useLazyQuery(SEARCH_COLLECTIONS, {
    fetchPolicy: "network-only",
    onCompleted: ({ collections: collectionData }) => {
      if (!collectionData) return;
      setGridCollections([...gridCollections, ...collectionData.collections]);
      collectionData.totalCount && setGridCollectionsCount(collectionData.totalCount);
      !gridCollectionsInitLoaded && setGridCollectionsInitLoaded(true);
      gridCollectionsLoadingMore && setGridCollectionsLoadingMore(false);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const loadGridCollections = () => {
    gridCollectionsInitLoaded && setGridCollectionsLoadingMore(true);
    getGridCollectionsResources({
      variables: {
        collectionSearch: {
          skip: gridCollections.length,
          text: searchValue,
          size: COLLECTIONS_GRID_SIZE,
          interests: searchValue.length ? [] : interests,
        },
      },
    });
  };

  useEffect(() => {
    if (!interests.length) return;
    loadGridCollections();
  }, [interests]);

  return (
    <div className={styles.collectionsContainer}>
      <BackButton path="/portal/resources" label={t("resources.backToResult")} />
      <ResourcesHeading
        resultsCount={gridCollectionsCount}
        searchValue={searchValue}
        sectionTitle={t("resources.collections")}
        isTitleDisplayed={false}
      />

      <div className={styles.marginWrapper}>
        <CollectionsGrid
          collections={gridCollections}
          loadMore={loadGridCollections}
          collectionsLoading={!gridCollectionsInitLoaded}
          collectionsLoadingMore={gridCollectionsLoadingMore}
          collectionsCount={gridCollectionsCount}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
};

export default CollectionsResources;
