import * as yup from "yup";
import { FormFields, SECTOR_NOT_LISTED } from "./constant";
import { FormField } from "./enum";

export const getValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    name: yup.string().required(t("form.error.required")),
    customName: yup
      .string()
      .min(1)
      .max(FormFields[FormField.CUSTOM_NAME].maxLength)
      .trim()
      .when("name", (name, schema) => {
        if (name === SECTOR_NOT_LISTED) return schema.required(t("form.error.required"));
        return schema;
      }),
  });
