/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/RootState";

import Button from "@components/Button/Button";
import CommunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import { COMMUNITIES_PAGINATION, INITIAL_PAGINATION } from "@constants/constants";
import { useSubscription } from "@customHooks/useSubscription";
import { COMMUNITIES_JOINED_BY_USER } from "@graphql/community/queries";
import { ICommunitiesJoinedByUser } from "@graphql/community/types";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { ICommunity } from "types/community";
import { Subscription } from "../../../customContext/subscriptions/enum";
import styles from "./JoinedByMe.module.scss";

interface JoinedByMeProps {
  showRecommended: (val: boolean) => void;
}

const JoinedByMe = ({ showRecommended }: JoinedByMeProps) => {
  const { t } = useTranslation();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [communitiesJoinedByMe, setCommunitiesJoinedByMe] = useState<ICommunity[]>([]);
  const [communitiesJoinedByMeTotalCount, setCommunitiesJoinedByMeTotalCount] =
    useState<number>(0);
  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (joinedByLoader) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !joinedByLoader) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreJoinedByMeCommunities();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const loadMoreJoinedByMeCommunities = () => {
    getCommunitiesJoinedByUser({
      variables: {
        userCommunitySearchOptions: {
          size: COMMUNITIES_PAGINATION,
          skip: communitiesJoinedByMe.length,
        },
      },
    });
  };

  const [
    getCommunitiesJoinedByUser,
    { loading: joinedByLoader, refetch: refetchJoinedCommunities },
  ] = useLazyQuery<ICommunitiesJoinedByUser>(COMMUNITIES_JOINED_BY_USER, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      if (
        res.communitiesJoinedByUser.communities.length === 0 &&
        res.communitiesJoinedByUser.totalCount === 0
      ) {
        showRecommended(true);
      } else {
        showRecommended(false);
      }
      const newCommunities = res.communitiesJoinedByUser.communities.filter(
        (community) =>
          !communitiesJoinedByMe.some(
            (joinedCommunity) => community.sendbirdId === joinedCommunity.sendbirdId
          )
      );
      setCommunitiesJoinedByMe([...communitiesJoinedByMe, ...newCommunities]);
      setCommunitiesJoinedByMeTotalCount(res.communitiesJoinedByUser.totalCount);

      setIsLoaded(true);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  useEffect(() => {
    setCommunitiesJoinedByMe([]);
    getCommunitiesJoinedByUser({
      variables: {
        userCommunitySearchOptions: {
          size: COMMUNITIES_PAGINATION,
          skip: INITIAL_PAGINATION,
        },
      },
    });
  }, []);

  const refetchJoinedHandler = () => {
    refetchJoinedCommunities();
  };

  useSubscription(Subscription.JOINED_COMMUNITY, refetchJoinedHandler);
  useSubscription(Subscription.JOIN_PRIVATE_COMMUNITY_ACCEPTED, refetchJoinedHandler);
  useSubscription(Subscription.REMOVED_FROM_COMMUNITY, refetchJoinedHandler);

  return (
    <>
      <span className={styles.sectionTitle}>{t("community.joinedByMe")}</span>
      {isLoaded ? (
        <div className={styles.scrollableContainer}>
          <div className={styles.scrollableDiv}>
            {!!communitiesJoinedByMe.length && (
              <>
                <div className={styles.gridContainer}>
                  {communitiesJoinedByMe.map((community: ICommunity) => (
                    <div
                      key={community.sendbirdId}
                      aria-label="community-card"
                      role="listitem"
                    >
                      <CommunityCard
                        community={community}
                        loggedUser={currentUserEmail}
                      />
                    </div>
                  ))}
                </div>
                {communitiesJoinedByMeTotalCount > communitiesJoinedByMe.length && (
                  <Button
                    title={t("collections.buttons.viewMore")}
                    wrapperClassNames={styles.loadMoreButton}
                    buttonIcon={<PlusOutlined />}
                    onClick={loadMoreJoinedByMeCommunities}
                    loading={joinedByLoader}
                    disabled={joinedByLoader}
                    customRef={loadMoreElementRef}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loading disableBoxShadow />
      )}
    </>
  );
};

export default JoinedByMe;
