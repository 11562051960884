import { isInAppRoute } from "@feature/routes";
import { MouseEvent, useState } from "react";

interface IUseHandleLinkProps {
  onExternalLinkClick?: () => void;
}

export const useHandleLinkClick = ({ onExternalLinkClick }: IUseHandleLinkProps) => {
  const [externalUrl, setExternalUrl] = useState("");

  const redirect = (url: string) => {
    if (isInAppRoute(url)) {
      window.location.href = url;
      return;
    }

    setExternalUrl(url);
    onExternalLinkClick?.();
  };

  const handleLinkTextClick = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLLinkElement;
    if (target.nodeName === "A") {
      event.preventDefault();
      redirect(target.href);
    }
  };

  const handleLinkClick = (url: string) => {
    redirect(url);
  };

  return { handleLinkTextClick, handleLinkClick, externalUrl };
};
