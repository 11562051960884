/* eslint-disable react-hooks/exhaustive-deps */
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./Stepper.module.scss";

interface IStepRoutes {
  [key: string]: string;
}

const VerticalLinearStepper = () => {
  const { t } = useTranslation();
  // #842 Set stepper from onboarding pages not to be clickable
  // const history = useHistory();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState<string>("");
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const getSteps = {
    [t("verticalLinearStepper.personalDetails")]: "personal-details",
    [t("verticalLinearStepper.profileDetails")]: "profile-details",
    [t("verticalLinearStepper.interestGoals")]: "interests",
    [t("verticalLinearStepper.locations")]: "locations",
    [t("verticalLinearStepper.interestsAndSocialMedia")]: "interests-and-social-media",
  };
  const steps: IStepRoutes = getSteps;

  // #842 Set stepper from onboarding pages not to be clickable
  // const handleStepClick = (label: string) => {
  //   history.push(`/onboarding/${steps[label]}`);
  //   setActiveStep(label);
  //   setActiveStepIndex(Object.keys(steps).indexOf(activeStep));
  // };

  useEffect(() => {
    const foundActiveStep = Object.keys(steps).find(
      (key) => steps[key] === location.pathname.replace("/onboarding/", "")
    );
    if (foundActiveStep) {
      setActiveStep(foundActiveStep);
      setActiveStepIndex(Object.keys(steps).indexOf(foundActiveStep));
    }
  }, [location]);

  return (
    <div className={styles.root}>
      <Stepper
        className={styles.wrapper}
        activeStep={activeStepIndex}
        orientation="vertical"
      >
        {Object.keys(steps).map((label, index) => (
          <Step
            key={index}
            className={`${styles.stepWrapper} ${
              activeStep === label ? styles.stepActive : styles.step
            }`}
            // #842 Set stepper from onboarding pages not to be clickable
            // onClick={() => handleStepClick(label)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default VerticalLinearStepper;
