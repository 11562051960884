import { gql } from "@apollo/client";

const PROMOTE_USER = gql`
  mutation promoteUser($user: PromoteUserToAdminInputType) {
    promoteUser(user: $user) {
      email
      publicId
      role
    }
  }
`;

export default PROMOTE_USER;
