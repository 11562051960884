/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
/* eslint-disable prettier/prettier */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import ImagePickerWithModal from "@components/ImagePickerWithModal/ImagePickerWithModal";
import Input from "@components/Input/Input";
import LinksManagement from "@components/LinksManagement/LinksManagement";
import Loading from "@components/Loading/Loading";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import ReactQuill from "react-quill";

import { STORAGE_IMAGE_TYPE_EVENT } from "@constants/constants";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import UPDATE_COMMUNITY from "@graphql/mutations/updateCommunity";
import GET_COMMUNITY from "@graphql/queries/community";
import GET_COMMUNITY_BY_NAME from "@graphql/queries/communityByName";
import SearchIcon from "@images/search_icon.svg";
import { defaultImages } from "@modules/NewCommunity/constants";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import FileCheckerService from "@utils/files/fileChecker";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage, { getStorageImageUrl } from "@utils/getStorageImage";
import { fileNameReplaceRegex } from "@utils/helpers";
import { mapToOption } from "@utils/selectHelper";
import { CommunityType, ICommunitiesResponse, ICommunityResponse } from "types/community";
import { IImage } from "types/image";
import { ILinkListData } from "types/links";
import { DefaultOption } from "types/select";
import { IUser } from "types/user";

import ClosedCommunityIcon from "@images/closedCommunityIcon.svg";
import PrivateCommunityIcon from "@images/privateCommunityIcon.svg";

import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import "react-quill/dist/quill.snow.css";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import formStyles from "@components/formik/form.style.module.scss";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import styles from "./CommunityEdit.module.scss";

const MAX_INPUT_LENGTH = 1000;

type roleType = {
  value: string;
  label: string;
};

const CommunityEdit = () => {
  const { t } = useTranslation();
  const communityTypes = [
    {
      id: 1,
      name: CommunityType.open,
      label: t("community.newCommunity.communityTypes.open.label"),
      subLabel: t("community.newCommunity.communityTypes.open.text"),
    },
    {
      id: 2,
      name: CommunityType.closed,
      label: (
        <span className={styles.communityTypeIconsContainer}>
          {t("community.newCommunity.communityTypes.closed.label")}
          <img src={ClosedCommunityIcon} alt="Locked" />
        </span>
      ),
      subLabel: t("community.newCommunity.communityTypes.closed.text"),
    },
    {
      id: 3,
      name: CommunityType.private,
      label: (
        <span className={styles.communityTypeIconsContainer}>
          {t("community.newCommunity.communityTypes.private.label")}
          <img src={PrivateCommunityIcon} alt="Hidden" />
        </span>
      ),
      subLabel: t("community.newCommunity.communityTypes.private.text"),
    },
  ];

  const roles: roleType[] = [
    {
      value: "1",
      label: t("community.newCommunity.select.members.memberSameForAllLang"),
    },
    { value: "2", label: t("community.newCommunity.select.members.adminSameForAllLang") },
  ];
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const { options: interestOptions } = useInterestHelper();
  const [communityId, setCommunityId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [oldTitle, setOldTitle] = useState<string>("");
  const [goals, setGoals] = useState<string>();
  const [rules, setRules] = useState<string>();
  const [selectedCommunityType, setSelectedCommunityType] = useState<string>(
    communityTypes[0].name
  );
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedInterests, setSelectedInterests] = useState<Array<DefaultOption>>([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);

  const [selectedMembers, setSelectedMembers] = useState<
    Array<{ value: string; label: string; position: string; role: roleType }>
  >([]);

  const ADMIN = currentUserEmail;
  const [admins, setAdmins] = useState<Array<string>>([ADMIN]);
  const [updatingCommunity, setUpdatingCommunity] = useState(false);
  // community profile image
  const [selectedImage, setSelectedImage] = useState<IImage>({} as IImage);
  const [imageDirectoryFromCommunity, setImageDirectoryFromCommunity] =
    useState<string>("");
  // community banner image
  const [selectedImageBanner, setSelectedImageBanner] = useState<IImage>({} as IImage);

  const [communityLinks, setCommunityLinks] = useState<ILinkListData[] | null>(null);
  const [isUsefulLinksValid, setIsUsefulLinksValid] = useState<boolean>(true);

  const [isCommunityNotFound, setIsCommunityNotFound] = useState<boolean>(false);

  const { uploadFiles } = useUploadFiles();

  const [updateCommunity] = useMutation(UPDATE_COMMUNITY, {
    onCompleted: () => {
      setUpdatingCommunity(false);
      displaySuccessNotification(t("requests.updateCommunity"));
      history.goBack();
    },
    onError: (err) => {
      setUpdatingCommunity(false);
      displayServerError(err);
    },
  });

  const [editCommunityIfExists] = useLazyQuery<ICommunitiesResponse>(
    GET_COMMUNITY_BY_NAME,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ communities: { communities } }) => {
        const isExistingCommunity = communities.some(
          (community) => community.name === title.trim()
        );
        if (isExistingCommunity && oldTitle !== title) {
          const message = t("community.newCommunity.errors.alreadyExists");
          displayNotSuccessNotification(message, message);
          setUpdatingCommunity(false);
          return;
        }
        editCommunity();
      },
      onError: (error) => {
        displayServerError(error);
      },
    }
  );

  const { loading: communityLoader } = useQuery<ICommunityResponse>(GET_COMMUNITY, {
    variables: {
      sendbirdId: params.id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ community }) => {
      if (!community) return;
      setCommunityId(community?.sendbirdId);
      const defaultInterests = community.interests?.map(({ name }) =>
        mapToOption(name, t(`categories.type.${name}`))
      );

      setImageDirectoryFromCommunity(community.imageDirectory as string);

      const loadedImage = defaultImages.find(
        (image) => image.image === community.communityImageUrl
      );

      const loadedImageHelper = loadedImage
        ? ({} as IImage)
        : {
            name: community.coverImageName || "",
            label: community.coverImageName || "",
            image: getStorageImage({
              image: community.coverImageName,
              directoryName: community.imageDirectory,
              type: STORAGE_IMAGE_TYPE_EVENT,
            }),
            removeIcon: true,
            checkButton: true,
          };

      setSelectedImage(loadedImageHelper);

      const loadedImageForBanner = defaultImages.find(
        (image) => image.image === community.communityBannerUrl
      );

      if (typeof loadedImageForBanner !== "undefined") {
        setSelectedImageBanner(loadedImageForBanner);
      } else if (community.communityBannerName) {
        setSelectedImageBanner({
          name: community.communityBannerName,
          label: community.communityBannerName,
          image: getStorageImage({
            image: community.communityBannerName,
            directoryName: community.communityBannerDirectory,
            type: STORAGE_IMAGE_TYPE_EVENT,
          }),
          removeIcon: true,
          checkButton: true,
        });
      } else {
        setSelectedImageBanner({} as IImage);
      }

      community.communityType === CommunityType.private &&
        setSelectedCommunityType(communityTypes[2].name);
      community.communityType === CommunityType.open &&
        setSelectedCommunityType(communityTypes[0].name);
      !(community.communityType === CommunityType.open) &&
        !(community.communityType === CommunityType.private) &&
        setSelectedCommunityType(communityTypes[1].name);

      const selectedMembersOptions = community.members.map((user: IUser) => ({
        value: `${user.email}`,
        label: `${user.fullName}`,
        position: getRoleOrganizationText(user.organization),
        role: roles[0],
      }));

      setSelectedMembers(selectedMembersOptions);

      const selectedAdmins = community?.admins?.map((admin: IUser) => ({
        value: admin?.email,
        label: `${admin.fullName}`,
        position: getRoleOrganizationText(admin.organization),
        role: roles[1],
      }));

      setSelectedMembers([...selectedMembersOptions, ...selectedAdmins]);
      setAdmins(selectedAdmins?.map((admin) => admin?.value));
      setTitle(community.name);
      setOldTitle(community.name);
      setGoals(community.goals as string);
      if (community.description) {
        setDescription(community.description);
        setDescriptionField(true);
      }
      setRules(community.rules as string);

      defaultInterests && setSelectedInterests(defaultInterests);

      const isLocalBanner = community?.communityImageUrl?.includes("community-banner-");

      const uploadedPhoto: IImage = {
        name: community.coverImageName || "",
        label: "Uploaded image",
        image: getStorageImage({
          image: community.coverImageName,
          directoryName: community.imageDirectory,
          type: STORAGE_IMAGE_TYPE_EVENT,
        }),
        removeIcon: true,
        checkButton: true,
      };

      !isLocalBanner && setSelectedImage(uploadedPhoto);

      if (community.communityLinks) {
        // remove __typename that we are getting from BE
        const restOfCommunityLinks = community.communityLinks.map(
          ({ __typename, ...keepAttrs }) => keepAttrs
        );

        // add isValid prop because we use it in a child component
        restOfCommunityLinks.forEach(function (link) {
          link.isValid = true;
        });

        setCommunityLinks(restOfCommunityLinks);
      }
    },
    onError: (error) => setIsCommunityNotFound(true),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeGoals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGoals(event.target.value);
  };

  const handleChangeRules = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRules(event.target.value);
  };

  const editCommunity = async () => {
    setUpdatingCommunity(true);
    // community profile image upload
    let imageDirectory = imageDirectoryFromCommunity;
    let imageUrl = selectedImage.image;
    const imageName = fileNameReplaceRegex(selectedImage.name);
    // community banner image upload
    let communityBannerDirectory = "";
    let bannerImageUrl = selectedImageBanner.image;
    const bannerImageName = fileNameReplaceRegex(selectedImageBanner.name);

    if (selectedImage.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImage.img
      );

      const fileSave = await FileCheckerService.isSafe(selectedImage.img);
      if (!fileSave || !fileSize) {
        setUpdatingCommunity(false);
        return;
      }

      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            imageDirectory = directory;
            imageUrl = getStorageImageUrl(file.name, directory);
          },
        });
      } catch (error) {
        setUpdatingCommunity(false);
        displayNotSuccessNotification();
      }
    }

    // this is how we check if it is one of the default images or an uploaded image
    if (selectedImageBanner.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImageBanner.img
      );

      const fileSave = await FileCheckerService.isSafe(selectedImageBanner.img);
      if (!fileSave || !fileSize) {
        setUpdatingCommunity(false);
        return;
      }

      try {
        await uploadFiles(selectedImageBanner.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            communityBannerDirectory = directory;
            bannerImageUrl = getStorageImageUrl(file.name, directory);
          },
        });
      } catch (error) {
        setUpdatingCommunity(false);
        displayNotSuccessNotification();
      }
    }

    const communityData = {
      name: title.trim(),
      communityImageUrl: imageUrl,
      coverImageName: imageName,
      imageDirectory,
      communityBannerUrl: bannerImageUrl,
      communityBannerName: bannerImageName,
      communityType: selectedCommunityType,
      description,
      goals,
      rules,
      tags: selectedInterests?.map((item) => `${item?.value}`),
      adminEmails: admins?.map((admin) => admin),
      memberEmails: selectedMembers
        ?.filter((member) => !admins?.includes(member?.value))
        ?.map((item) => item?.value),
      communityLinks: communityLinks?.map(({ isValid, ...link }) => link),
      sendbirdId: communityId,
    };

    const editCommunityVariable = {
      ...communityData,
      ...(communityBannerDirectory && { communityBannerDirectory }),
    };

    updateCommunity({
      variables: {
        communityUpdate: editCommunityVariable,
      },
    });
  };

  const reactQuillRef = useRef<ReactQuill>(null);

  const validateInput = (description: string) => {
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor;
    const editor = reactQuillRef.current?.editor;
    editor?.on("text-change", () => {
      if (unprivilegedEditor !== undefined && unprivilegedEditor?.getLength() > 1000) {
        editor?.deleteText(1000, unprivilegedEditor.getLength());
      }
    });
    setDescription(description);
  };

  useEffect(() => {
    if (communityLinks?.length === 0) return setIsUsefulLinksValid(true);

    communityLinks?.forEach((communityLink) => {
      if (
        communityLink.title === "" ||
        communityLink.url === "" ||
        !communityLink.isValid
      ) {
        setIsUsefulLinksValid(false);
      } else {
        setIsUsefulLinksValid(true);
      }
    });
  }, [communityLinks]);

  if (isCommunityNotFound) return <PageNotFound />;

  return (
    <div className={styles.pageWrapper}>
      {communityLoader ? (
        <Loading borderless />
      ) : (
        <>
          <h2 className={styles.pageTitle} e2e-test-id="edit-community-form-header">
            {t("community.editCommunity.title")}
          </h2>
          <span className={styles.pageSubTitle} e2e-test-id="community-form-subheader">
            {t("community.editCommunity.subTitle")}
          </span>
          <form className={styles.newCommunityForm}>
            <div className={formStyles.column}>
              <InputUpdated
                onChange={handleChange}
                name="communityName"
                value={title}
                maxLength={100}
                label={t("community.editCommunity.communityName.label")}
                aria-label="communityName"
                e2eTestId="community-name-input-field"
                placeholder={t("community.editCommunity.communityName.placeholder")}
                additionalElement={
                  !descriptionField && (
                    <ButtonUpdated
                      aria-label="addDescription"
                      e2eTestId="add-community-description-button"
                      title={t("community.editCommunity.buttons.addDescription")}
                      buttonIcon={<PlusOutlined />}
                      buttonSize="small"
                      onClick={() => setDescriptionField(true)}
                    />
                  )
                }
              />
              {descriptionField && (
                <ReactQuill
                  theme="snow"
                  onChange={(description: string) => validateInput(description)}
                  ref={reactQuillRef}
                  className="description"
                  placeholder={t("community.newCommunity.textarea.label")}
                  aria-label="description textarea"
                  // #842 Remove current Description text
                  value={description}
                  defaultValue={description}
                />
              )}
            </div>

            <div className={styles.selectWrapper}>
              <SelectComponent
                options={interestOptions}
                placeholder={t("community.newCommunity.select.tags.placeholder")}
                className={"tagsSearch"}
                label={t("community.newCommunity.select.tags.label")}
                name={"tags"}
                e2eTestId="categories-select-container"
                showDropdown
                selectWithIcon
                icon={SearchIcon}
                isMulti
                value={selectedInterests}
                onChange={(selectedInterestOptions: DefaultOption[]) =>
                  setSelectedInterests(selectedInterestOptions)
                }
                isSearchable
              />
            </div>
            <div className={styles.inputContainer}>
              <Input
                onChange={handleChangeGoals}
                name="communityGoals"
                maxLength={MAX_INPUT_LENGTH}
                label={t("community.newCommunity.communityGoals.label")}
                aria-label="communityGoals"
                e2eTestId="community-goals-input-field"
                placeholder={t("community.newCommunity.communityGoals.placeholder")}
                value={goals}
              />
            </div>
            <div className={styles.inputContainer}>
              <Input
                onChange={handleChangeRules}
                name="communityRules"
                maxLength={MAX_INPUT_LENGTH}
                label={t("community.newCommunity.communityRules.label")}
                aria-label="communityRules"
                e2eTestId="community-rules-input-field"
                placeholder={t("community.newCommunity.communityRules.placeholder")}
                value={rules}
              />
            </div>
            <div e2e-test-id="community-profile-image-section">
              <ImagePickerWithModal
                title={t("community.newCommunity.radio.image.profileImageLabel")}
                description={t(
                  "community.newCommunity.radio.image.profileImageDescription"
                )}
                images={[]}
                pickedImage={selectedImage}
                setPickedImage={setSelectedImage}
                isUpload
                isRequired
                uploadButtonId={"selectedImage"}
              />
            </div>
            <div e2e-test-id="community-banner-image-section">
              <ImagePickerWithModal
                title={t("community.newCommunity.radio.image.bannerLabel")}
                description={t("community.newCommunity.radio.image.bannerDescription")}
                images={defaultImages}
                pickedImage={selectedImageBanner}
                setPickedImage={setSelectedImageBanner}
                isUpload
                isRequired
                uploadButtonId={"selectedImageBanner"}
              />
            </div>
            <div
              className={styles.communityTypeWrapper}
              e2e-test-id="community-type-section"
            >
              <div className={styles.communityTypeHeading}>
                {t("community.newCommunity.communityTypes.title")}
              </div>
              {communityTypes.map((communityType) => (
                <RadioButton
                  aria-label="communityType"
                  id={communityType.id}
                  key={communityType.name}
                  name={communityType.name}
                  label={communityType.label}
                  subLabel={communityType.subLabel}
                  checked={communityType.name === selectedCommunityType}
                  onChange={(event) => {
                    setSelectedCommunityType(event.target.name);
                  }}
                />
              ))}
            </div>
            {communityLinks && (
              <LinksManagement
                linksData={communityLinks}
                updateLinks={setCommunityLinks}
              />
            )}
            <div className={styles.buttons}>
              <Button
                aria-label="createCommunityBtn"
                e2eTestId="edit-community-button"
                buttonType="primary"
                wrapperClassNames={styles.buttonWrapper}
                title={t("community.editCommunity.buttons.editCommunity")}
                onClick={() => setIsConfirmModalOpened(true)}
                loading={updatingCommunity}
                disabled={
                  updatingCommunity ||
                  !title?.trim()?.length ||
                  !selectedInterests.length ||
                  !selectedImage?.image ||
                  !selectedImageBanner?.image ||
                  !isUsefulLinksValid
                }
                fullWidth
              />
              <Button
                aria-label="cancelBtn"
                e2eTestId="edit-community-cancel-link"
                buttonType="link"
                title={t("community.newCommunity.buttons.cancel")}
                className={styles.cancelLink}
                onClick={() => history.goBack()}
              />
            </div>
          </form>
          <ConfirmationModal
            isOpen={isConfirmModalOpened}
            e2eTestId="edit-community-modal-container"
            accept={() => {
              setIsConfirmModalOpened(false);
              editCommunityIfExists({
                variables: {
                  communitySearch: {
                    text: title,
                  },
                },
              });
            }}
            messageTitle={t("community.editCommunity.confirmModal.title")}
            messageContent=""
            closeModal={() => setIsConfirmModalOpened(false)}
            customButtonsText={{
              accept: t("community.editCommunity.confirmModal.button.accept"),
              cancel: t("community.editCommunity.confirmModal.button.cancel"),
            }}
          />
        </>
      )}
    </div>
  );
};

export default CommunityEdit;
