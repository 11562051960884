/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import HeaderBar from "@components/HeaderBar/HeaderBar";
import Navbar from "@components/Navbar/Navbar";
import TabBar from "@components/TabBar/TabBar";
import GET_USERS from "@graphql/queries/users";
import SettingsInterests from "@modules/AccountSettings/SettingsInterests/SettingsInterests";
import SettingsLocations from "@modules/AccountSettings/SettingsLocations/SettingsLocations";
import SettingsPersonalDetails from "@modules/AccountSettings/SettingsPersonalDetails/SettingsPersonalDetails";
import SettingsProfileDetails from "@modules/AccountSettings/SettingsProfileDetails/SettingsProfileDetails";
import ChatPage from "@modules/ChatPage/ChatPage";
import CollectionCreateNew from "@modules/CollectionCreateNew/CollectionCreateNew";
import CollectionEdit from "@modules/CollectionEdit/CollectionEdit";
import Collections from "@modules/Collections/Collections";
import CollectionsResources from "@modules/CollectionsResources/CollectionsResources";
import Communities from "@modules/Communities/Communities";
import Community from "@modules/Community/Community";
import EventDetailsPage from "@modules/EventDetailsPage/EventDetailsPage";
import EventEdit from "@modules/Events/EditEvent/EditEvent";
import PopularEvents from "@modules/Events/PopularEvents/PopularEvents";
import PendingInvitations from "@modules/Invitations/PendingInvitations";
import Resources from "@modules/Resources/Resources";
import SettingsInterestsAndSocialMedia from "@modules/SettingsInterestsAndSocialMedia/SettingInterestsAndSocialMedia";
import TourModal from "@modules/TourModal/TourModal";
import UsersViewMore from "@modules/UsersViewMore/UsersViewMore";
import AuthService from "@services/AuthService";
import LocationService from "@services/LocationService";
import TourModalService from "@services/TourModalService";
import dayjs from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { ILocation } from "types/location";
import { IMenuItem } from "types/menu";
import { IUser, IUserResponse } from "types/user";
import { v4 as uuidv4 } from "uuid";

import styles from "./Portal.module.scss";

import Icon, { ExportOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import invitations from "@images/invitations.svg";

import {
  SEARCH,
  SEARCH_ID,
  SEARCH_PORTAL_ACTION,
  SEARCH_VALUE,
  TIMESTAMP,
  USER_ACTIVITIES_CATEGORY,
  USER_ID,
} from "@constants/googleAnalyticsConstants";
import AdminPanel from "@modules/AdminPanel/AdminPanel";
import CollectionSingle from "@modules/CollectionSingle/CollectionSingle";
import Events from "@modules/Events/Events";
import NewEvent from "@modules/Events/NewEvent/NewEvent";
import EventsViewMore from "@modules/EventsViewMore/EventsViewMore";
import SingleArticle from "@modules/SingleArticle/SingleArticle";
import { SubscriptionsProvider } from "../../customContext/subscriptions/context";
import { clearSearchTerm } from "../../store/events/actions";
import { setUserUnreadNotificationsCount } from "../../store/notifications/actions";
import {
  clearSearchEventId,
  setPreviousPortalUrl,
  setSearchEventId,
} from "../../store/search/actions";
import { setUser as setStoreUser } from "../../store/user/actions";
import { CLAIM_TYPES } from "../../store/user/constants";

import { Navigate } from "@components/utils/Navigate/Navigate";
import { GLOBAL_LOCATION, LANGUAGES } from "@constants/constants";
import { SendBirdProvider } from "@customHooks/sendBird/context/context";
import { useGetCountries } from "@feature/country/service/useGetCountries/useGetCountries";
import { IPhoneData } from "@feature/phone";
import ExploreViewMore from "@modules/Collections/ExploreViewMore/ExploreViewMore";
import CommunityChannelPage from "@modules/CommunityChannelPage/CommunityChannelPage";
import CommunityCollectionCreateNew from "@modules/CommunityCollectionCreateNew/CommunityCollectionCreateNew";
import CommunityCollectionSingle from "@modules/CommunityCollectionSingle/CommunityCollectionSingle";
import CommunityEdit from "@modules/CommunityEdit/CommunityEdit";
import CommunityGuidelines from "@modules/CommunityGuidelines/CommunityGuidelines";
import CommunityHomepage from "@modules/CommunityHomepage/CommunityHomepage";
import ExploreCommunities from "@modules/ExploreCommunities/ExploreCommunities";
import FolderSingle from "@modules/FolderSingle/FolderSingle";
import NewCommunity from "@modules/NewCommunity/NewCommunity";
import NotificationPage from "@modules/NotificationPage/NotificationPage";
import RecommendedCommunities from "@modules/RecommendedCommunities/RecommendedCommunities";
import RequestsPage from "@modules/RequestsPage/RequestsPage";
import SupportPage from "@modules/SupportPage/SupportPage";
import TermsAndConditionsPage from "@modules/TermsAndConditionsPage/TermsAndConditionsPage";
import { ACCOUNT_SETTINGS_ROUTES } from "@utils/routes/accountSettings";
import i18n from "../../i18n";

const MAX_SEARCH_CHARACTER_LENGTH = 200;

const AccountSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [submitValue, setSubmitValue] = useState("");
  const [user, setUser] = useState<IUser>();
  const [userPhoneData, setUserPhoneData] = useState<IPhoneData>();
  const [interests, setInterests] = useState<Array<string>>([]);
  const [userLocations, setUserLocations] = useState<Array<ILocation>>([]);
  const [allLocations, setAllLocations] = useState<Array<ILocation>>();
  const [locationNames, setLocationNames] = useState<Array<string>>([]);
  // const [hiddenArticles, setHiddenArticles] = useState<Array<string>>([]);
  const [shouldShowTourModal, setShouldShowTourModal] = useState<boolean>(
    () => !!TourModalService.getIsOnboarded()
  );
  const [searchResourcesSubmitted, setSearchResourcesSubmitted] =
    useState<boolean>(false);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const { countriesData, getPhoneData } = useGetCountries();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (!pathname.includes("/portal/event/")) localStorage.setItem("href", pathname);
    if (pathname.includes("/edit")) localStorage.setItem("editCollection", pathname);

    if (submitValue.length) {
      dispatch(setPreviousPortalUrl(window.location.pathname));
    } else {
      dispatch(setPreviousPortalUrl(""));
    }
    if (window.location.pathname.includes("/portal/event")) return;
    dispatch(clearSearchTerm());
  }, [window.location.pathname]);

  useEffect(() => {
    if (!countriesData) return;

    const generatedAllLocations = LocationService.generateAllLocationsArray([
      { name: GLOBAL_LOCATION, callingCode: "" },
      ...countriesData.countries,
    ]);

    setAllLocations(generatedAllLocations);
    refetch().then((response) => {
      mapUserLocations(response.data.user);
    });
  }, [countriesData]);

  const mapUserLocations = (user: IUser) => {
    if (countriesData?.countries) {
      const combineCountriesAndCities = LocationService.generateUserLocations(
        countriesData.countries,
        user
      );

      setUserLocations(
        LocationService.generateAllLocationsArray(combineCountriesAndCities, true)
      );
    }
  };

  useEffect(() => {
    if (!userLocations.length) return;

    if (countriesData?.countries) {
      setLocationNames(
        LocationService.findAllCitiesForCountries(
          userLocations,
          countriesData.countries
        ).map((location) => {
          return t(`select.language.${location.name}`);
        })
      );
    }
  }, [userLocations]);

  const { refetch } = useQuery<IUserResponse>(GET_USERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      if (!response || !response.user) return;

      // TODO BE Should add new property callingCode and combine these two states
      setUser(response.user);
      setUserPhoneData(getPhoneData(response.user.telephone));

      const languageCode = LANGUAGES.find(
        (lng) => lng.label === response.user.defaultLanguage
      );
      i18n.changeLanguage(languageCode?.key);

      setInterests(response.user.interests.map(({ name }) => name));

      dispatch(
        setStoreUser({
          userPublicId: response.user.publicId,
          email: response.user.email,
          fullName: response.user.fullName,
          defaultLanguage: response.user.defaultLanguage,
        })
      );

      dispatch(
        setUserUnreadNotificationsCount(response.user.unreadNotificationsCount || 0)
      );

      setUserLoaded(true);
    },
  });

  const path = useLocation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.substring(0, MAX_SEARCH_CHARACTER_LENGTH));
  };

  const handleSearchIconClick = () => {
    handleSubmit();
    setSearchResourcesSubmitted(true);
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (searchValue.length) {
      const searchId = uuidv4();
      dispatch(setSearchEventId(searchId));
      sendSearchedValueToGA(searchValue, searchId);
    } else {
      dispatch(clearSearchEventId());
    }
    setSubmitValue(searchValue);
    setSearchResourcesSubmitted(true);
    history.push("/portal/resources");
  };

  useEffect(() => {
    if (!submitValue.length) {
      dispatch(clearSearchEventId());
    }
  }, [submitValue]);

  const sendSearchedValueToGA = (searchValue: string, searchId: string) => {
    const eventId = uuidv4();
    ReactGA.event({
      action: SEARCH_PORTAL_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${SEARCH}*${SEARCH_ID}*${searchId}`,
    });
    ReactGA.event({
      action: SEARCH_PORTAL_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${SEARCH}*${TIMESTAMP}*${dayjs().unix().toString()}`,
    });
    ReactGA.event({
      action: SEARCH_PORTAL_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${SEARCH}*${USER_ID}*${user?.publicId}`,
    });
    ReactGA.event({
      action: SEARCH_PORTAL_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${SEARCH}*${SEARCH_VALUE}*${searchValue}`,
    });
  };

  const handleLogout = () => {
    localStorage.setItem("loggedInUser", "false");

    AuthService.clearSendBirdToken();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  // custom ant icon
  const InvitationsIcon = () => {
    return (
      <Icon
        component={() => (
          <img
            src={invitations}
            height={16}
            style={{ position: "relative", bottom: 3 }}
          />
        )}
      />
    );
  };

  const accountMenuItems: Array<IMenuItem> = [
    {
      name: t("accountMenuItems.invitations"),
      icon: <InvitationsIcon />,
      url: "/portal/invitations",
      e2eTestId: "invitations",
    },
    {
      name: t("accountMenuItems.profileSettings"),
      icon: <UserOutlined />,
      url: ACCOUNT_SETTINGS_ROUTES.PERSONAL_DETAILS,
      e2eTestId: "profile-settings",
    },
    {
      name: t("accountMenuItems.adminPanel"),
      icon: <SettingOutlined />,
      url: "/portal/admin-panel",
      e2eTestId: "admin-panel",
      claimsRequired: [CLAIM_TYPES.USER_READ, CLAIM_TYPES.FEEDBACK_READ],
    },
    {
      name: t("accountMenuItems.logout"),
      icon: <ExportOutlined />,
      e2eTestId: "logout",
      onClick: () => handleLogout(),
    },
  ];

  const setUserHandler = (updatedUserLocations: IUser) => {
    const overridenUserCountriesData = { ...user, ...updatedUserLocations };
    setUser(overridenUserCountriesData);
    mapUserLocations(overridenUserCountriesData);
  };

  useEffect(() => {
    refetch();

    if (!path.pathname.includes("portal/resources")) {
      setSearchResourcesSubmitted(false);
      setSearchValue("");
      setSubmitValue("");
    }
  }, [path.pathname]);

  const handleCloseTourModal = () => {
    TourModalService.clearIsOnboarded();
    setShouldShowTourModal(false);
  };

  const renderSettingsPersonalDetails = () => {
    if (user && userPhoneData) {
      return (
        <SettingsPersonalDetails
          user={user}
          phoneData={userPhoneData}
          refetchData={refetch}
        />
      );
    }

    return <></>;
  };

  const renderSettingsProfileDetails = () => {
    if (!user) return <></>;

    return (
      <SettingsProfileDetails user={user} refetchData={refetch}>
        <SettingsInterestsAndSocialMedia
          data={{
            userDefinedInterests: user?.userDefinedInterests || "",
            userSecretSuperPower: user?.userSecretSuperPower || "",
            problemUserWouldLikeToSolve: user?.problemUserWouldLikeToSolve || "",
            userLinkedInLink: user?.userLinkedInLink || "",
          }}
          refetchData={refetch}
        />
      </SettingsProfileDetails>
    );
  };

  const renderSettingsLocations = () => (
    <>
      <SettingsInterests currentUserInterests={interests} />
      <SettingsLocations
        globalInterestLocation={!!user?.globalInterestLocation}
        allLocations={allLocations}
        userLocations={userLocationsGlobalCheck()}
        countriesData={countriesData}
        setUserData={setUserHandler}
        originalUserData={user}
        refetchUserData={refetch}
      />
    </>
  );

  const userLocationsGlobalCheck = () => {
    const GLOBAL_WORLWIDE: ILocation = {
      name: GLOBAL_LOCATION,
      callingCode: "",
      city: false,
      parentCountry: undefined,
    };

    if (!user?.globalInterestLocation) {
      return userLocations;
    } else {
      return [GLOBAL_WORLWIDE, ...userLocations];
    }
  };

  return (
    <SubscriptionsProvider>
      <SendBirdProvider>
        <div className={styles.container}>
          <TourModal onClose={handleCloseTourModal} isOpen={shouldShowTourModal} />
          <Navbar userDefaultLanguage={user?.defaultLanguage} />
          <div className={styles.dashboard}>
            <form onSubmit={handleSubmit}>
              <HeaderBar
                menuItems={accountMenuItems}
                setOpenProfile={() => false}
                onChange={onChange}
                value={searchValue}
                handleSearchIconClick={handleSearchIconClick}
              />
            </form>
            <Switch>
              <Route path="/portal/admin-panel">
                <AdminPanel />
              </Route>

              {/* <Route path="/portal/dashboard" exact>
              {userLoaded && (
                <Dashboard
                  hiddenArticles={hiddenArticles}
                  userName={user?.fullName}
                  interests={interests}
                  locationNames={locationNames}
                  userLocations={userLocations?.map((location) => location.name)}
                  loading={userLoader}
                />
              )}
            </Route> */}
              <Route path="/portal/resources" exact>
                {userLoaded && (
                  <Resources
                    interests={interests}
                    userLoaded={!!(user && interests)}
                    // locationNames={locationNames}
                    submitValue={submitValue}
                    // hiddenArticles={hiddenArticles}
                    searchResourcesSubmitted={searchResourcesSubmitted}
                  />
                )}
              </Route>
              {/* <Route path="/portal/resources/articles" exact>
              {userLoaded && (
                <Articles
                  searchValue={submitValue}
                  interests={interests}
                  locations={locationNames}
                  hiddenArticles={hiddenArticles}
                  publicId={user?.publicId || ""}
                />
              )}
            </Route> */}
              <Route path="/portal/resources/communities" exact>
                {userLoaded && (
                  <Communities searchValue={submitValue} interests={interests} />
                )}
              </Route>
              <Route path="/portal/resources/collections" exact>
                {userLoaded && (
                  <CollectionsResources searchValue={submitValue} interests={interests} />
                )}
              </Route>
              <Route path="/portal/resources/events-page" exact>
                {userLoaded && (
                  <EventsViewMore searchValue={submitValue} interests={interests} />
                )}
              </Route>
              <Route path="/portal/resources/users" exact>
                {userLoaded && (
                  <UsersViewMore
                    searchValue={submitValue}
                    interests={interests}
                    locations={locationNames}
                  />
                )}
              </Route>
              <Route path="/portal/chat-page/:id?" exact>
                <ChatPage />
              </Route>
              <Route path="/portal/resources/article/:id" exact>
                <SingleArticle />
              </Route>
              <Route path="/portal/event/new">
                <NewEvent />
              </Route>
              <Route path="/portal/events">
                <Events />
              </Route>
              <Route path="/portal/event/:id" exact>
                <EventDetailsPage />
              </Route>
              <Route path="/portal/event/edit/:id" exact>
                <EventEdit />
              </Route>
              <Route path="/portal/popular/events" exact>
                <PopularEvents submitValue={submitValue} />
              </Route>
              <Route path="/portal/collection/new" exact>
                <CollectionCreateNew />
              </Route>
              <Route path="/portal/collection/:id" exact>
                <CollectionSingle />
              </Route>
              <Route path="/portal/collection/:id/edit" exact>
                <CollectionEdit />
              </Route>
              <Route path="/portal/edit/community/:id" exact>
                <CommunityEdit />
              </Route>
              <Route path="/portal/collections/explore/view-more" exact>
                <ExploreViewMore />
              </Route>
              <Route path="/portal/collections/:slug?">
                <Collections />
              </Route>
              <Route path="/portal/account-settings">
                <TabBar
                  personalDetailsContent={renderSettingsPersonalDetails()}
                  profileDetailsContent={renderSettingsProfileDetails()}
                  interestsContent={renderSettingsLocations()}
                />
              </Route>
              <Route path="/portal/invitations">
                <PendingInvitations />
              </Route>
              <Route path="/portal/community-collection/new">
                <CommunityCollectionCreateNew />
              </Route>
              <Route path="/portal/community-collection/:id">
                <CommunityCollectionSingle />
              </Route>
              <Route path="/portal/collection/:collectionId/:folderId">
                <FolderSingle />
              </Route>
              <Route path="/portal/community" exact>
                {userLoaded && <Community interests={interests} />}
              </Route>
              <Route path="/portal/community/new" exact>
                <NewCommunity />
              </Route>
              <Route path="/portal/community/explore" exact>
                <ExploreCommunities />
              </Route>
              <Route path="/portal/community/recommended" exact>
                <RecommendedCommunities />
              </Route>
              <Route path="/portal/community/community-page/:community" exact>
                <CommunityHomepage />
              </Route>
              <Route path="/portal/community/requests/:id" exact>
                <RequestsPage />
              </Route>
              <Route
                path="/portal/community/community-page/:community/:channel/:message?"
                exact
              >
                <CommunityChannelPage />
              </Route>
              <Route path="/portal/notifications-page" exact>
                <NotificationPage />
              </Route>
              <Route path="/portal/support" exact>
                <SupportPage />
              </Route>
              <Route path="/portal/conduct" exact>
                <CommunityGuidelines isBackButtonVisible />
              </Route>
              <Route path="/portal/terms-and-conditions" exact>
                <TermsAndConditionsPage isBackButtonVisible />
              </Route>
              <Route>
                <Navigate path="/portal/resources" />
              </Route>
            </Switch>
          </div>
        </div>
      </SendBirdProvider>
    </SubscriptionsProvider>
  );
};

export default AccountSettings;
