import { useEffect, useRef, useState } from "react";

import { Route, Switch, useLocation } from "react-router-dom";

import Footer from "@components/LandingPage/Home/Footer";
import Header from "@components/LandingPage/Layout/Header";
import { Navigate } from "@components/utils/Navigate/Navigate";

import CommunityGuidelines from "@modules/CommunityGuidelines/CommunityGuidelines";
import LandingPage from "@modules/LandingPage";
import OpenPublicEventDetailsPage from "@modules/OpenPublicEventDetailsPage/OpenPublicEventDetailsPage";
import OpenPublicEvents from "@modules/OpenPublicEvents/OpenPublicEvents";
import OpenPublicZoomEventPage from "@modules/OpenPublicZoomEventPage/OpenPublicZoomEventPage";
import Register from "@modules/Register/Register";
import TermsAndConditionsPage from "@modules/TermsAndConditionsPage/TermsAndConditionsPage";
import WelcomeScreen from "@modules/WelcomeScreen/WelcomeScreen";

import { PUBLIC_ROUTES, routesWithoutRegisterButton } from "./constant";

import "../../../../App.scss";
import styles from "../../../../layout/Layout.module.scss";

export const PublicRoutes = () => {
  const [isWelcomePage, setIsWelcomePage] = useState(false);
  const location = useLocation();
  const signupRef = useRef<HTMLDivElement | null>(null);
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const hiveFeaturesRef = useRef<HTMLDivElement | null>(null);
  const hivePulseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsWelcomePage(location.pathname === "/welcome");
  }, [location.pathname]);

  return (
    <>
      {!isWelcomePage && (
        <Header
          signupRef={signupRef}
          aboutRef={aboutRef}
          hiveFeaturesRef={hiveFeaturesRef}
          hivePulseRef={hivePulseRef}
          showEventsButton={true}
          showLoginButton={true}
          showRegisterButton={!routesWithoutRegisterButton.includes(location.pathname)}
          showNavigationButtons={location.pathname === "/"}
        />
      )}
      <div className={`${isWelcomePage ? "" : styles.main}`}>
        <Switch>
          <Route path="/" exact>
            <LandingPage
              aboutRef={aboutRef}
              hiveFeaturesRef={hiveFeaturesRef}
              hivePulseRef={hivePulseRef}
              signupRef={signupRef}
            />
          </Route>
          <Route path="/welcome" exact>
            <WelcomeScreen />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/events" exact>
            <OpenPublicEvents signupRef={signupRef} />
          </Route>
          <Route path="/event/:id" exact>
            <OpenPublicEventDetailsPage />
          </Route>
          <Route path="/zoomEvent/:hostingId/:iserId" exact>
            <OpenPublicZoomEventPage />
          </Route>
          <Route path={PUBLIC_ROUTES.CODE_OF_CONDUCT} exact>
            <CommunityGuidelines />
          </Route>
          <Route path={PUBLIC_ROUTES.TERMS_AND_CONDITIONS} exact>
            <TermsAndConditionsPage />
          </Route>
          <Navigate path="/" />
        </Switch>
      </div>
      {!isWelcomePage && <Footer />}
    </>
  );
};
