import { IUser } from "../../types/user";
import {
  CLEAR_COMMUNITY_SEARCH_TERM,
  LEFT_COMMUNITY,
  SET_ADMINS,
  SET_ALL_MEMBERS,
  SET_COMMUNITY_MEMBERS,
  SET_COMMUNITY_SEARCH_TERM,
  SET_MEMBERS,
} from "./constants";

export const setCommunityMembers = (members: {
  admins: Array<IUser>;
  members: Array<IUser>;
  creator?: IUser;
}) => ({
  type: SET_COMMUNITY_MEMBERS,
  payload: members,
});

export const setAllMembers = (members: Array<IUser>) => ({
  type: SET_ALL_MEMBERS,
  payload: members,
});

export const setAdmins = (admins: Array<IUser>) => ({
  type: SET_ADMINS,
  payload: admins,
});

export const setMembers = (members: Array<IUser>) => ({
  type: SET_MEMBERS,
  payload: members,
});

export const leaveCommunityAction = (community: string) => ({
  type: LEFT_COMMUNITY,
  payload: community,
});

export const setCommunitySearchTerm = (searchTerm: string) => ({
  type: SET_COMMUNITY_SEARCH_TERM,
  payload: searchTerm,
});

export const clearCommunitySearchTerm = () => ({
  type: CLEAR_COMMUNITY_SEARCH_TERM,
  payload: "",
});
