import Button from "@components/Button/Button";
import Calendar from "@images/Calendar.png";
import { ClaimsService } from "@services/ClaimsService";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styles from "./NoEventsToExplore.module.scss";

const NoEventsToExplore = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasCreateEventClaim: boolean = ClaimsService.hasCreateEventClaim();

  return (
    <div className={styles.container} data-testid="container">
      <img src={Calendar} width={50} height={50} />
      <span className={styles.title}>{t("noEventsToExplore.title")}</span>
      <div className={styles.textContainer}>
        <span>{t("noEventsToExplore.firstText")}</span>
        {hasCreateEventClaim && <span>{t("noEventsToExplore.secondText")}</span>}
      </div>
      {hasCreateEventClaim && (
        <Button
          title={t("noEventsToExplore.buttonText")}
          onClick={() => history.push("/portal/event/new")}
          buttonType="primary"
          data-testid="button"
        />
      )}
    </div>
  );
};

export default NoEventsToExplore;
