import { useEffect, useState } from "react";

import { Emoji, SendBirdInstance } from "sendbird";

export const useSendBirdEmojis = (sb: SendBirdInstance | null) => {
  const [emojiList, setEmojiList] = useState<Emoji[]>([]);

  useEffect(() => {
    if (!sb) return;

    const getEmojis = async () => {
      const list = await sb.getAllEmoji();
      setEmojiList(list.emojiCategories[0].emojis);
    };

    getEmojis();
  }, [sb]);

  return emojiList;
};
