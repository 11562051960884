import { ICity, ICountry, ILocation } from "types/location";
import { IUser } from "types/user";

class LocationService {
  public static generateAllLocationsArray(
    countries: Array<ILocation>,
    skipCountryIfOnlyCitySelected?: boolean
  ) {
    return this.mapLocationsForSelect(
      countries.reduce((acc: Array<ILocation>, country: ILocation) => {
        const { cities, ...countryData } = country;
        if (
          !skipCountryIfOnlyCitySelected ||
          (!cities?.length && skipCountryIfOnlyCitySelected)
        ) {
          acc.push(countryData);
        }
        if (cities) {
          cities.map((city: ICity) => {
            return acc.push({
              ...countryData,
              name: `${city.name}, ${country.name}`,
              parentCountry: country.name,
              city: true,
            });
          });
        }

        return acc;
      }, [])
    );
  }

  public static mapLocationsForSelect(countries: Array<ILocation>) {
    return countries.map((location: ILocation) => {
      return {
        value: this.mergeCallingCodeAndName(location),
        callingCode: location.callingCode,
        name: location.name,
        parentCountry: location.parentCountry,
        city: !!location.city,
      };
    });
  }

  public static filterSelectedLocationsFromAll(
    allLocations: Array<ILocation>,
    selectedLocations: Array<ILocation>
  ) {
    return allLocations.filter((location) => {
      const cityIsSelected = selectedLocations.find(
        (selectedLocation) => selectedLocation.name === location.name
      );
      const countrySelectedForThisCity = selectedLocations.find(
        (selectedLocation) => selectedLocation.name === location.parentCountry
      );
      return !cityIsSelected && !countrySelectedForThisCity;
    });
  }

  public static filterCitiesIfCountryIsSelected(locations: Array<ILocation>) {
    const countries = locations.filter((location) => !location.city);
    return locations.filter(
      (location) => !countries.find((country) => location.parentCountry === country.name)
    );
  }

  public static displayCountryIfAllCitiesSelected(
    selectedLocations: Array<ILocation>,
    allLocations: Array<ILocation>
  ) {
    const allParentCountriesNames = selectedLocations.reduce(
      (acc: Array<string>, location: ILocation) => {
        if (location.parentCountry) {
          if (acc.length && acc.includes(location.parentCountry)) return acc;
          acc.push(location.parentCountry);
        }
        return acc;
      },
      []
    );
    const allCitiesPerSelectedCountry =
      allParentCountriesNames.length &&
      allLocations.filter(
        (location) =>
          location.parentCountry &&
          allParentCountriesNames.includes(location.parentCountry)
      );
    const allCitiesLeftToSelectPerCountry = allParentCountriesNames.map(
      (countryName: string) => {
        return {
          name: countryName,
          cities:
            allCitiesPerSelectedCountry &&
            allCitiesPerSelectedCountry
              .filter((city) => city.parentCountry === countryName)
              .filter(
                (city) =>
                  !selectedLocations.find((location) => location.name === city.name)
              ),
        };
      }
    );

    return selectedLocations.reduce((acc: Array<ILocation>, location: ILocation) => {
      if (!location.parentCountry) {
        acc.push(location);
        return acc;
      }

      if (acc.find((loc) => loc.name === location.parentCountry)) {
        return acc;
      }

      const citiesNotSelected = allCitiesLeftToSelectPerCountry.find(
        (loc) => loc.name === location.parentCountry
      )?.cities;

      if (citiesNotSelected && !citiesNotSelected.length) {
        const parentCountry = allLocations.find(
          (loc) => loc.name === location.parentCountry
        );
        parentCountry && acc.push(parentCountry);
        return acc;
      }

      acc.push(location);
      return acc;
    }, []);
  }

  public static addIfNotExistent(array: Array<string>, item: string | undefined) {
    if (item && array.indexOf(item) < 0) {
      array.push(item);
    }
  }

  public static generateCountriesAndCitiesArray(
    countries: Array<ILocation>,
    selectedCountries: Array<string>
  ) {
    return countries
      .filter((country: ILocation) => {
        return selectedCountries.find(
          (selectedCountry: string) => selectedCountry === country.name
        );
      })
      .map((country: ILocation) => ({
        name: country.name,
        callingCode: country.callingCode,
      }));
  }

  public static generateUserLocations(countriesData: Array<ICountry>, userData: IUser) {
    return this.generateCountriesAndCitiesArray(
      countriesData,
      userData.countries.map(
        (country: { name: string; __typename: string }) => country.name
      )
    );
  }

  public static extractCitiesFromSelectedLocations(locations: Array<ILocation>) {
    return locations
      .filter((location: ILocation) => location.city)
      .map((location: ILocation) => {
        return {
          name: location.name.substring(0, location.name.indexOf(",")),
        };
      });
  }

  public static findAllCitiesForCountries(
    locations: Array<ILocation>,
    originalCountriesData?: Array<ICountry>
  ) {
    return locations.reduce((acc: Array<ICity>, location: ILocation) => {
      if (location.city) {
        acc.push({
          name: location.name,
        });
      } else {
        const foundCountry = originalCountriesData?.find(
          (country) => country.name === location.name
        );
        foundCountry && acc.push({ name: `${foundCountry.name}` });
      }
      return acc;
    }, []);
  }

  public static extractStatesFromSelectedLocations(
    locations: Array<ILocation>,
    originalCountriesData?: Array<ICountry>
  ) {
    return locations
      .reduce((acc: Array<string>, location: ILocation) => {
        LocationService.addIfNotExistent(
          acc,
          location.city ? location.parentCountry : location.name
        );
        return acc;
      }, [])
      .map((countryName: string) => {
        const country =
          originalCountriesData &&
          originalCountriesData.find((country: ICountry) => country.name === countryName);
        return (
          country && {
            name: country.name,
          }
        );
      });
  }

  public static mapLocationForSelect(locations: Array<ILocation>) {
    return locations.length
      ? locations.map((location) => {
          return {
            value: location.name,
            label: location.name,
            city: location.city,
            name: location.name,
            parentCountry: location.parentCountry,
          };
        })
      : [];
  }

  public static mapCountryDataForMap(selectedLocations: Array<ILocation>) {
    const countryNames = selectedLocations.map((loc: ILocation) => {
      return loc.parentCountry || loc.name;
    });
    return countryNames;
  }

  public static mergeCallingCodeAndName(location: ILocation) {
    return `+${location.callingCode}, ${location.name}`;
  }
}

export default LocationService;
