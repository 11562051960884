import Modal from "@components/Modal/Modal";
import { useTranslation } from "react-i18next";

import styles from "./TourModal.module.scss";

import communityScreenshotEN from "@images/communityScreenshotEN.svg";
import dashboardScreenshotEN from "@images/dashboardScreenshotEN.svg";
import latestResourcesScreenshotEN from "@images/latestResourcesScreenshotEN.svg";
import profileScreenShotEN from "@images/profileScreenshotEN.svg";
import resourcesScreenshotEN from "@images/resourcesScreenshotEN.svg";

import communityScreenshotES from "@images/communityScreenshotES.svg";
import dashboardScreenshotES from "@images/dashboardScreenshotES.svg";
import latestResourcesScreenshotES from "@images/latestResourcesScreenshotES.svg";
import profileScreenShotES from "@images/profileScreenshotES.svg";
import resourcesScreenshotES from "@images/resourcesScreenshotES.svg";

import Button from "@components/Button/Button";
import communityScreenshotKO from "@images/communityScreenshotKO.svg";
import dashboardScreenshotKO from "@images/dashboardScreenshotKO.svg";
import latestResourcesScreenshotKO from "@images/latestResourcesScreenshotKO.svg";
import profileScreenShotKO from "@images/profileScreenshotKO.svg";
import resourcesScreenshotKO from "@images/resourcesScreenshotKO.svg";

interface ITourModal {
  onClose: () => void;
  isOpen: boolean;
}

const TourModal = ({ onClose, isOpen }: ITourModal) => {
  const { t, i18n } = useTranslation();

  const showTourPicture = () => {
    switch (i18n.language) {
      case "es":
        return [
          profileScreenShotES,
          dashboardScreenshotES,
          latestResourcesScreenshotES,
          resourcesScreenshotES,
          communityScreenshotES,
        ];
      case "ko":
        return [
          profileScreenShotKO,
          dashboardScreenshotKO,
          latestResourcesScreenshotKO,
          resourcesScreenshotKO,
          communityScreenshotKO,
        ];
      default:
        return [
          profileScreenShotEN,
          dashboardScreenshotEN,
          latestResourcesScreenshotEN,
          resourcesScreenshotEN,
          communityScreenshotEN,
        ];
    }
  };

  const PICTURES = showTourPicture();

  return (
    <Modal isOpen={isOpen} className={styles.container}>
      <div>
        <h2 className={styles.heading} aria-label="heading">
          {t("tour.heading")}
        </h2>
        <span className={styles.subheading}>{t("tour.subheading")}</span>
        <div className={styles.grid}>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.title} aria-label="title">
                {t("tour.profile.heading")}
              </h3>
              <p className={styles.about}>{t("tour.profile.about")}</p>
            </div>
            <div className={styles.col}>
              <img
                className={styles.img}
                src={PICTURES[0]}
                alt={""}
                aria-label="profileImg"
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.title}>{t("tour.dashboard.heading")}</h3>
              <p className={styles.about}>{t("tour.dashboard.about")}</p>
              <h3 className={styles.title}>{t("tour.latestUpdates.heading")}</h3>
              <p className={styles.about}>{t("tour.latestUpdates.about")}</p>
            </div>
            <div className={styles.col}>
              <img
                className={styles.img}
                src={PICTURES[1]}
                alt={t("tour.resources.imageDescription")}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.title}>{t("tour.latestResources.heading")}</h3>
              <p className={styles.about}>{t("tour.latestResources.about")}</p>
            </div>
            <div className={styles.col}>
              <img
                className={styles.img}
                src={PICTURES[2]}
                alt={t("tour.resources.imageDescription")}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.title}>{t("tour.resources.heading")}</h3>
              <p className={styles.about}>{t("tour.resources.about")}</p>
            </div>
            <div className={styles.col}>
              <img
                className={styles.img}
                src={PICTURES[3]}
                alt="tour.resources.imageDescription"
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <h3 className={styles.title}>{t("tour.community.heading")}</h3>
              <p className={styles.about}>{t("tour.community.about")}</p>
            </div>
            <div className={styles.col}>
              <img
                className={styles.img}
                src={PICTURES[4]}
                alt={t("tour.resources.imageDescription")}
              />
            </div>
          </div>
        </div>
        <div className={styles.closeContainer}>
          <Button
            title={t("tour.close")}
            aria-label="cancelButton"
            onClick={onClose}
            wrapperClassNames={styles.button}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TourModal;
