/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import CommunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import GET_USER_INFO_COMMUNITIES from "@graphql/queries/getUserInfoCommunities";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommunity } from "types/community";

import userDrawerStyles from "../UserDrawer.module.scss";

import styles from "./UserDrawerCommunities.module.scss";

interface UserDrawerCommunitiesProps {
  userEmail: string;
  currentUserEmail: string;
}

const UserDrawerCommunities = ({
  userEmail,
  currentUserEmail,
}: UserDrawerCommunitiesProps) => {
  const { t } = useTranslation();
  const [userCommunities, setUserCommunities] = useState<ICommunity[]>([]);
  const [userCommunitiesTotalCount, setUserCommunitiesTotalCount] = useState<number>(0);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  const [getUserCommunities, { loading: loadingCommunities }] = useLazyQuery(
    GET_USER_INFO_COMMUNITIES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserCommunities([...userCommunities, ...data.userInfo.communities]);
        setUserCommunitiesTotalCount(data.userInfo.communitiesTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (loadingCommunities) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loadingCommunities) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreUserCommunities();
      setShouldLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const loadMoreUserCommunities = () => {
    getUserCommunities({
      variables: {
        email: userEmail,
        communitySearch: { size: 6, skip: userCommunities?.length },
      },
    });
  };

  useEffect(() => {
    if (!userEmail) return;

    getUserCommunities({
      variables: { email: userEmail, communitySearch: { size: 6, skip: 0 } },
    });
  }, [userEmail]);

  return (
    <>
      {loadingCommunities && (
        <div className={userDrawerStyles.loader}>
          <Loading disableBoxShadow />
        </div>
      )}

      {!!userCommunities.length && (
        <>
          {userCommunities.map((community: ICommunity) => (
            <div
              key={community.sendbirdId}
              aria-label="community-card"
              role="listitem"
              className={styles.communityCardInUserDrawer}
            >
              <CommunityCard
                inUserDrawer={true}
                community={community}
                loggedUser={currentUserEmail}
              />
            </div>
          ))}

          {!!userCommunities.length &&
            userCommunitiesTotalCount > userCommunities.length && (
              <Button
                data-testid="view-more-communities"
                aria-label="view-more-communities"
                title={t("collections.buttons.viewMore")}
                wrapperClassNames={styles.loadMoreButton}
                buttonIcon={<span className={styles.plusIcon}>+</span>}
                onClick={loadMoreUserCommunities}
                loading={loadingCommunities}
                disabled={loadingCommunities}
                customRef={loadMoreElementRef}
              />
            )}
        </>
      )}
    </>
  );
};

export default UserDrawerCommunities;
