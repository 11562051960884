export enum MeetingType {
  Zoom = "zoom",
  External = "external",
}

export enum SubMeetingType {
  Webinar = "Webinar",
  Meeting = "Meeting",
  External = "External",
}
