import { gql } from "@apollo/client";

const GET_SECTORS = gql`
  query sectors {
    sectors {
      name
    }
  }
`;

export default GET_SECTORS;
