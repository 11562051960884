import { MenuTypeToClassName } from "./constant";
import { IMenuItem } from "./type";

export const getMenuItemClassName = (
  item: IMenuItem,
  isExpanded: boolean,
  styles: { [key: string]: string }
) => {
  let className = `${styles.menuItem} ${styles[MenuTypeToClassName[item.type]]}`;

  if (item.isActive) {
    className = `${className} ${styles.active}`;
  }

  return `${className} ${!isExpanded ? styles.collapsed : ""}`;
};
