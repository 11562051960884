import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { Locale } from "./type";
import { LOCALE } from "./constant";
interface IAntDLocaleProivderProps {
  children: React.ReactElement;
}

export const AntDLocaleProvider = ({ children }: IAntDLocaleProivderProps) => {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider locale={LOCALE[i18n.language as Locale]}>{children}</ConfigProvider>
  );
};
