import { useMemo, useState } from "react";

import { Emoji } from "sendbird";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import Popover from "@components/Popover/Popover";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";

import { ReactComponent as EmojiAddIcon } from "@images/EmojiAdd.svg";

import styles from "./EmojiContainer.module.scss";

interface IEmojiContainerProps {
  toggleReaction: (value: Emoji) => Promise<void>;
  className?: string;
}

export const EmojiContainer = ({ toggleReaction, className }: IEmojiContainerProps) => {
  const { emojiList } = useSendBirdContext();
  const [isOpen, setIsOpen] = useState(false);

  const menu = useMemo(
    () => (
      <div className={styles.options}>
        {emojiList.map((emoji) => {
          return (
            <div
              key={emoji.key}
              className={styles.option}
              onClick={() => {
                toggleReaction(emoji);
                setIsOpen(false);
              }}
            >
              <img src={emoji.url} width={20} height={20} />
            </div>
          );
        })}
      </div>
    ),
    [emojiList, toggleReaction]
  );

  return (
    <div className={styles.container}>
      <Popover
        overlayStyle={{ padding: 0 }}
        align={{ offset: [0, 2] }}
        showArrow={false}
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
        trigger="hover"
        childToWrap={
          <ButtonUpdated
            aria-label=""
            title=""
            buttonShape="circle"
            buttonType="link"
            wrapperClassNames={className}
            buttonIcon={<EmojiAddIcon />}
          />
        }
        placement="top"
        menu={menu}
        menuState={isOpen}
        setMenuState={setIsOpen}
      />
    </div>
  );
};
