export const ADMIN_PANEL_ROUTES_PREFIX = "/portal/admin-panel";

export enum ADMIN_PANEL {
  USERS = "users",
  COMMUNITY = "community",
  FEEDBACKS = "feedbacks",
  GENERATE_ACCESS_TOKEN = "generateAccessToken",
  PLATFORM_STATISTICS = "platformStatistics",
}

export const ADMIN_PANEL_ROUTES = {
  ADMIN_PANEL: ADMIN_PANEL_ROUTES_PREFIX,
  USERS: `${ADMIN_PANEL_ROUTES_PREFIX}/${ADMIN_PANEL.USERS}`,
  COMMUNITY: `${ADMIN_PANEL_ROUTES_PREFIX}/${ADMIN_PANEL.COMMUNITY}`,
  FEEDBACKS: `${ADMIN_PANEL_ROUTES_PREFIX}/${ADMIN_PANEL.FEEDBACKS}`,
  GENERATE_ACCESS_TOKEN: `${ADMIN_PANEL_ROUTES_PREFIX}/${ADMIN_PANEL.GENERATE_ACCESS_TOKEN}`,
  PLATFORM_STATISTICS: `${ADMIN_PANEL_ROUTES_PREFIX}/${ADMIN_PANEL.PLATFORM_STATISTICS}`,
};
