import styles from "./ButtonUpdated.module.scss";

import { ButtonType } from "antd/lib/button/button";

import { CustomButtonType, textSize } from "./type";

export const TypeToClassName: Record<ButtonType, string> = {
  default: styles.hiveButtonDefault,
  primary: styles.hiveButtonPrimary,
  ghost: styles.hiveButtonGhost,
  dashed: styles.hiveButtonDashed,
  link: styles.hiveButtonLink,
  text: styles.hiveButtonText,
};

export const CustomTypeToClassName: Record<CustomButtonType, string> = {
  danger: styles.hiveButtonDanger,
  ternary: styles.hiveButtonTernary,
};

export const TextSizeToClassName: Record<textSize, string> = {
  sm: styles.textSm,
  md: styles.textMd,
  lg: styles.textLg,
};
