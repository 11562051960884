import { gql } from "@apollo/client";

const GENERATE_SAS_TOKEN = gql`
  mutation generateSasToken($blobStorage: BlobStorageInputType!) {
    generateSasToken(blobStorage: $blobStorage) {
      values
      directoryName
    }
  }
`;

export default GENERATE_SAS_TOKEN;
