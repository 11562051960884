import {
  removeCommunityNotificationAction,
  removeCommunityNotificationsRelatedToCommunityAction,
} from "./../store/communityNotification/actions";
import { useDispatch } from "react-redux";

export const useCommunityNotificationsHelper = () => {
  const dispatch = useDispatch();

  const removeNotification = (id: string) =>
    dispatch(removeCommunityNotificationAction(id));

  const removeCommunityNotificationsRelatedToCommunity = (sendBirdId: string) => {
    dispatch(removeCommunityNotificationsRelatedToCommunityAction(sendBirdId));
  };

  return {
    removeNotification,
    removeCommunityNotificationsRelatedToCommunity,
  };
};
