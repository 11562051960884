import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import { RootState } from "store/store";

import { SendBirdHelper } from "@customHooks/sendBird/SendBirdHelper";

import GET_USERS_INFO from "@graphql/queries/getUserInfo";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { MentionOption } from "types/SearchMessage";
import { IUser } from "types/user";

import { DIRECT } from "@customHooks/sendBird/constant";
import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";

import { GroupChannel } from "sendbird";
import { useGetUserImages } from "../ChatSideMenu/useGetUserImages/useGetUserImages";

interface IUseHandleRouteChangeProps {
  sendBirdGroupChannel: IUseSendBirdGroupChannel;
  groupChannel?: GroupChannel;
}

export const useHandleRouteChange = ({
  sendBirdGroupChannel: {
    state: { blockedUsers },
  },
  groupChannel,
}: IUseHandleRouteChangeProps) => {
  const { id: groupId } = useParams<{ id: string }>();
  const isDirectGroup = groupChannel?.customType === DIRECT;
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const [otherUser, setOtherUser] = useState<IUser>();
  const [selectedUserToBlockEmail, setSelectedUserToBlockEmail] = useState<string>("");
  const [chatMembers, setChatMembers] = useState<MentionOption[]>([]);

  const isInputDisplayed = useMemo(() => {
    return !blockedUsers.some(({ userId }) => userId === otherUser?.email);
  }, [blockedUsers, otherUser]);

  const { getUserImages, userImages } = useGetUserImages();
  const [getUser] = useLazyQuery(GET_USERS_INFO, {
    onCompleted: ({ userInfo }) => {
      setOtherUser(userInfo);
      setSelectedUserToBlockEmail(userInfo.email);
    },
    onError: displayNotSuccessNotification,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!groupChannel) return;

    const otherMember = SendBirdHelper.getDirectGroupOtherMember(
      groupChannel,
      currentUserEmail
    );

    if (isDirectGroup && otherMember) {
      getUser({
        variables: { email: otherMember.userId },
      });
    }

    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: groupChannel.members.map((member) => member.userId),
        },
      },
    });

    const prepareChatMembers = groupChannel.members
      .filter((user) => user.userId !== currentUserEmail)
      .map((user) => ({
        value: user.nickname,
        label: user.userId,
      }));

    setChatMembers(prepareChatMembers);

    const currentMember = SendBirdHelper.getGroupCurrentMember(
      groupChannel,
      currentUserEmail
    );
    if (currentMember?.state === "invited") {
      groupChannel?.acceptInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChannel, currentUserEmail]);

  return {
    isInputDisplayed,
    selectedUserToBlockEmail,
    userImages,
    chatMembers,
    otherUser,
    groupId,
    isDirectGroup,
    chatName:
      isDirectGroup && groupChannel
        ? SendBirdHelper.getDirectGroupOtherMemberNickName(groupChannel, currentUserEmail)
        : groupChannel?.name,
  };
};
