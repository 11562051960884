import styles from "./PendingInvitationsCardEmpty.module.scss";

interface IPendingInvitationsCardEmpty {
  imageType: string;
  text: string;
}

const PendingInvitationsCardEmpty = ({
  imageType,
  text,
}: IPendingInvitationsCardEmpty) => {
  return (
    <div className={styles.noPendingInvitationsContainer}>
      <img className={styles.noPendingInvitationsImage} src={imageType} alt="" />
      <span className={styles.noPendingInvitationsSubtitle}>{text}</span>
    </div>
  );
};

export default PendingInvitationsCardEmpty;
