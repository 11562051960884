/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BackButton from "@components/BackButton/BackButton";
import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";

import { GET_SEARCHED_USERS } from "@graphql/queries/usersSearch";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { IUserDashboard } from "types/user";

import UsersGrid from "../UsersGrid/UsersGrid";
import styles from "./UsersViewMore.module.scss";

interface IUsersViewMore {
  searchValue: string;
  interests: string[];
  locations: string[];
}

const UsersViewMore = ({ searchValue, interests, locations }: IUsersViewMore) => {
  const [gridUsersCount, setGridUsersCount] = useState<number>(0);
  const [gridUsersInitLoaded, setGridUsersInitLoaded] = useState<boolean>(false);
  const [gridUsersLoadingMore, setGridUsersLoadingMore] = useState<boolean>(false);
  const [gridUsers, setGridUsers] = useState<IUserDashboard[]>([]);
  const { t } = useTranslation();

  const [getGridCommunityResources] = useLazyQuery(GET_SEARCHED_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data: {
      users: {
        users: IUserDashboard[];
        totalCount: number;
      };
    }) => {
      if (!data.users || !data.users.users) return;
      setGridUsers([...gridUsers, ...data.users.users]);
      data.users.totalCount && setGridUsersCount(data.users.totalCount);
      !gridUsersInitLoaded && setGridUsersInitLoaded(true);
      gridUsersLoadingMore && setGridUsersLoadingMore(false);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const loadGridUsers = () => {
    gridUsersInitLoaded && setGridUsersLoadingMore(true);
    getGridCommunityResources({
      variables: {
        skip: gridUsers.length,
        size: 16,
        text: searchValue,
      },
    });
  };

  useEffect(() => {
    loadGridUsers();
  }, [interests, locations]);

  return (
    <div className={styles.usersContainer}>
      <BackButton path="/portal/resources" label={t("resources.backToResult")} />
      <ResourcesHeading
        resultsCount={gridUsersCount}
        searchValue={searchValue}
        sectionTitle={t("resources.users")}
        isTitleDisplayed={false}
      />
      <UsersGrid
        users={gridUsers}
        loadMore={loadGridUsers}
        usersLoading={!gridUsersInitLoaded}
        usersLoadingMore={gridUsersLoadingMore}
        usersCount={gridUsersCount}
        searchValue={searchValue}
      />
    </div>
  );
};

export default UsersViewMore;
