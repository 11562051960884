import React, { InputHTMLAttributes, forwardRef } from "react";

import Loading from "@components/Loading/Loading";
import { Label } from "@components/formik/Label/Label";

import SearchIcon from "@images/search_icon.svg";

import { getClassName } from "./helper";
import { IClassName } from "./type";

import styles from "./InputUpdated.module.scss";

export interface IInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Omit<IClassName, "styles"> {
  label?: string;
  isRequired?: boolean;
  additionalElement?: React.ReactNode;
  customValidation?: boolean;
  e2eTestId?: string;
}

export const InputUpdated = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      additionalElement,
      e2eTestId,
      pattern,
      customValidation,
      isRequired,
      loading,
      isRounded,
      hasBorder,
      wrapperClassName,
      area,
      icon,
      ...props
    },
    ref
  ) => {
    const isInputValid = (value: string) => {
      if (!pattern) return true;
      return !!value.match(pattern);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (customValidation && !isInputValid(e.target.value)) {
        return;
      }
      props.onChange?.(e);
    };

    const renderIcon = () => {
      if (props.type === "search") {
        return <img className={styles.icon} src={SearchIcon} alt={props.label} />;
      }

      if (!icon) return <></>;

      return <img className={styles.icon} src={icon} alt={props.label} />;
    };

    return (
      <div className={styles.inputContainer}>
        {props.label && (
          <Label
            name={props.name || ""}
            text={props.label || ""}
            isRequired={isRequired}
          />
        )}
        <div
          className={getClassName({
            styles,
            wrapperClassName,
            hasBorder,
            isRounded,
            loading,
            type: props.type,
            icon,
            area,
          })}
        >
          {renderIcon()}
          <input
            {...props}
            autoComplete="off"
            e2e-test-id={e2eTestId}
            onChange={onChange}
            ref={ref}
            className={[props.className, styles.input].join(" ")}
            disabled={props.disabled}
          />
          {loading !== undefined && (
            <div className={styles.loaderContainer}>
              <Loading borderless size="Small" />
            </div>
          )}
          {additionalElement}
        </div>
      </div>
    );
  }
);
