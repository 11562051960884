import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Button from "@components/Button/Button";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Input from "@components/Input/Input";
import Select from "@components/Select/Select";

import { handleAcceptingRedirect } from "@utils/helpers";

import { DefaultOption } from "types/select";

import "../../styles/_textEditor.scss";

import styles from "./DetailField.module.scss";

interface IDetailField<T> {
  label: string;
  value?: string;
  name?: string;
  e2eTestId?: string;
  buttonName?: string;
  inputType?: string;
  options?: Array<DefaultOption>;
  selectWithIcon?: boolean;
  icon?: string;
  maxLength?: number;
  placeholder?: string;
  defaultValue?: string | DefaultOption;
  resetValue?: T;
  isSearchable?: boolean;
  onSave?: (name: string, value: string, onSuccess?: (value: T) => void) => void;
  onCancel?: (value?: T) => void;
  onChange?: (value: string) => void;
  children?: ReactElement;
  editButtonElement?: ReactNode;
}

const DetailField = <T,>({
  inputType,
  e2eTestId,
  options = [{ value: "", label: "" }],
  name,
  defaultValue,
  placeholder,
  isSearchable,
  ...props
}: IDetailField<T>) => {
  const { t } = useTranslation();
  const [editField, setEditField] = useState(false);
  const [value, setValue] = useState(props.value);
  const resetValueRef = useRef(props.resetValue);
  const reactQuillRef = useRef<ReactQuill>(null);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");

  const richText = "rich-text";

  const handleCancelEdit = () => {
    props.onCancel?.(resetValueRef.current);
    setEditField(!editField);
    setValue(props.value);
  };

  const handleSave = () => {
    setEditField(false);
    if (inputType === "telephone") {
      return props.onSave?.(
        name || "",
        value || "",
        (value) => (resetValueRef.current = value)
      );
    }

    if (value && value !== props.value) {
      props.onSave && props.onSave(name || "", value);
    }
  };

  const validateInput = (description: string) => {
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor;
    const editor = reactQuillRef.current?.editor;
    editor?.on("text-change", () => {
      if (unprivilegedEditor !== undefined && unprivilegedEditor?.getLength() > 1000) {
        editor?.deleteText(1000, unprivilegedEditor.getLength());
      }
    });
    setValue(description);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBioLinkClick = (e: any) => {
    if (e.target.nodeName === "A") {
      e.preventDefault();
      setIsConfirmationModalOpened(true);
      setClickedUrl(e.target.href);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const renderInput = () => {
    if (inputType === "select") {
      return (
        <Select
          label=""
          placeholder={placeholder || ""}
          name={name || ""}
          options={options}
          className={styles.inputField}
          showDropdown
          onChange={(option: DefaultOption) => {
            setValue(option.label);
          }}
          isSearchable={isSearchable}
        />
      );
    }
    if (inputType === richText) {
      return (
        <ReactQuill
          theme="snow"
          value={value}
          onChange={(descriptionValue: string) => validateInput(descriptionValue)}
          ref={reactQuillRef}
        />
      );
    }

    if (inputType === "telephone") {
      return <></>;
    }

    return (
      <Input
        aria-label={name}
        name="detailField"
        label=""
        type={inputType}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        inputClassNames={styles.inputField}
        wrapperClassNames={inputType === "number" ? "numberInput" : ""}
        maxLength={props.maxLength}
      />
    );
  };

  const createMarkup = () => {
    if (value) return { __html: value };
  };

  const renderValue = () => {
    if (inputType === richText) {
      return (
        <div dangerouslySetInnerHTML={createMarkup()} onClick={handleBioLinkClick} />
      );
    }
    return value;
  };

  return (
    <>
      <div className={styles.fieldContainer} e2e-test-id={e2eTestId}>
        <div className={styles.rowContainer}>
          <span className={styles.label}>{props.label}</span>
          {props.editButtonElement ? (
            props.editButtonElement
          ) : (
            <Button
              aria-label={`edit-field-${name}`}
              title={
                editField
                  ? t("accountSettings.personalDetails.button.cancel")
                  : props.buttonName || ""
              }
              onClick={handleCancelEdit}
              buttonType="link"
              data-testid="edit-field"
            />
          )}
        </div>
        {editField ? (
          <div>
            {renderInput()}
            {props.children}
            <Button
              aria-label={`save-${name}`}
              title={t("accountSettings.personalDetails.button.save")}
              buttonType="primary"
              onClick={handleSave}
              disabled={!value}
            ></Button>
          </div>
        ) : (
          <div>
            <span className={styles.value}>
              {name === "password"
                ? t("accountSettings.personalDetails.label.updatedPassword")
                : renderValue()}
            </span>
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)}
        url={clickedUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};

export default DetailField;
