/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client/react";

import { KeyboardEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_USER from "@graphql/queries/users";

import { ClaimsService } from "@services/ClaimsService";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { NotificationPopover, useChatNotification } from "@feature/notification";

import getStorageImage from "@utils/getStorageImage";

import { IMenuItem } from "types/menu";
import { IUserResponse } from "types/user";

import { updateCommunityNotificationsAsReadAction } from "../../store/communityNotification/actions";
import { numberOfUnreadCommunityNotificationsSelector } from "../../store/communityNotification/reducer";
import { RootState } from "../../store/store";
import { setUserImage } from "../../store/user/actions";

import { Messages } from "@components/Messages/Messages";
import Popover from "@components/Popover/Popover";
import SearchInput from "@components/SearchInput/SearchInput";

import { isDocumentRtl } from "@utils/checkDocumentDir";

import MessageIcon from "@images/message_icon.svg";
import SearchGrey from "@images/search_grey.svg";

import styles from "./HeaderBar.module.scss";

interface IHeaderBar {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onMessageClick?: () => void;
  setOpenProfile: () => void;
  menuItems: Array<IMenuItem>;
  value: string;
  handleSearchIconClick: () => void;
}

const HeaderBar = (props: IHeaderBar) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [menuState, setMenuState] = useState(false);

  const [getCurrentUser] = useLazyQuery<IUserResponse>(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ user }) => {
      dispatch(
        setUserImage(
          getStorageImage({
            image: user.userPhotoName,
            directoryName: user.imageDirectory,
            type: STORAGE_IMAGE_TYPE_USER,
          })
        )
      );
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const userPhoto = useSelector<RootState, string>((state) => state.user.userImage);

  const [isMessagesPopUpVisible, setIsMessagesPopUpVisible] = useState<boolean>(false);
  useChatNotification();
  const numberOfUnreadCommunityNotifications = useSelector(
    numberOfUnreadCommunityNotificationsSelector
  );

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (!isMessagesPopUpVisible) return;
    if (numberOfUnreadCommunityNotifications)
      dispatch(updateCommunityNotificationsAsReadAction());
  }, [isMessagesPopUpVisible]);

  const handleClick = (menuItem: IMenuItem) => {
    setMenuState(false);
    if (menuItem.url) {
      history.push(menuItem.url);
    } else {
      menuItem.onClick && menuItem.onClick();
    }
  };

  const renderMenuItem = (item: IMenuItem) => (
    <div
      role="listitem"
      aria-label="menuItem"
      e2e-test-id={item.e2eTestId}
      key={item.name}
      className={styles.menuItem}
      onClick={() => handleClick(item)}
    >
      <span className={styles.menuItemIcon}>{item.icon}</span>
      {item.name}
    </div>
  );

  // messages alert
  const getNumberOfUnreadMessages = () => {
    const totalUnreadNotifications = numberOfUnreadCommunityNotifications;
    if (totalUnreadNotifications > 10) return "10+";
    if (totalUnreadNotifications <= 0) return "";
    return totalUnreadNotifications;
  };

  const renderMessagesAlert = getNumberOfUnreadMessages() ? (
    <div role="alert" aria-label="notification" className={styles.alert}>
      <span className={styles.notificationNumber}>{getNumberOfUnreadMessages()}</span>
    </div>
  ) : (
    <></>
  );

  const menu = (
    <div className={styles.menuItemWrapper} e2e-test-id="avatar-menu-container">
      {props.menuItems.map((menuItem) =>
        menuItem.claimsRequired
          ? ClaimsService.hasAllClaims(menuItem.claimsRequired) &&
            renderMenuItem(menuItem)
          : renderMenuItem(menuItem)
      )}
    </div>
  );

  const handleOnKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      props.handleSearchIconClick();
    }
  };

  return (
    <div
      className={styles.headerContainer}
      role="navigation"
      aria-label="headBar"
      e2e-test-id="header-container"
    >
      <SearchInput
        name="header"
        placeholder={t("headerBar.placeholder")}
        e2eTestId="header-search-input"
        value={props.value}
        onChange={props.onChange}
        onKeyDown={handleOnKeyDown}
        searchIcon={<img src={SearchGrey} onClick={props.handleSearchIconClick} alt="" />}
        isRounded={false}
      />
      <div className={styles.headerIcons}>
        <Popover
          className={styles.communityNotificationPopUp}
          menu={<Messages closePopover={() => setIsMessagesPopUpVisible(false)} />}
          placement={isDocumentRtl() ? "bottomLeft" : "bottomRight"}
          menuState={isMessagesPopUpVisible}
          setMenuState={setIsMessagesPopUpVisible}
          childToWrap={
            <div className={styles.item}>
              {renderMessagesAlert}
              <img
                src={MessageIcon}
                className={styles.iconStyle}
                alt=""
                e2e-test-id="messages-icon"
              />
            </div>
          }
        />
        <NotificationPopover />
        <Popover
          menu={menu}
          placement={isDocumentRtl() ? "bottomLeft" : "bottomRight"}
          menuState={menuState}
          setMenuState={(state) => setMenuState(state)}
          childToWrap={
            <div
              style={{ backgroundImage: `url(${userPhoto})` }}
              className={styles.avatar}
              aria-label="AvatarIcon"
              e2e-test-id="avatar-icon"
            ></div>
          }
        />
      </div>
    </div>
  );
};

export default HeaderBar;
