/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useLazyQuery, useMutation } from "@apollo/client";

import { DELETE_EVENT } from "@graphql/mutations/deleteEvent";
import GET_EVENT from "@graphql/queries/event";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";

import PageNotFound from "@modules/PageNotFound/PageNotFound";

import DangerModal from "@components/DangerModal/DangerModal";
import EventConfirmJoinModal from "@components/EventConfirmJoinModal/EventConfirmJoinModal";
import EventDetailTabs from "@components/EventDetailTabs/EventDetailTabs";
import EventHostCard from "@components/EventHostCard/EventHostCard";
import EventImageCard from "@components/EventImageCard/EventImageCard";
import EventPageBackButton from "@components/EventPageBackButton/EventPageBackButton";
import EventTimeCard from "@components/EventTimeCard/EventTimeCard";
import Loading from "@components/Loading/Loading";
import ResourcesCategories from "@components/ResourcesCategories/ResourcesCategories";

import { IEvent } from "types/event";
import { IInterest } from "types/interest";
import { IUserImageResponse } from "types/user";

import { EVENT_ROUTES } from "@utils/routes/event";

import { STORAGE_IMAGE_TYPE_EVENT, STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import getStorageImage from "@utils/getStorageImage";

import {
  displayErrorNotification,
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import DangerIcon from "@images/DangerIcon.png";
import Avatar from "@images/avatar.svg";

import styles from "./EventDetailsPage.module.scss";

const EventDetailsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState<IEvent>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedRecordingUrl, setSelectedRecordingUrl] = useState<string>("");
  const [categories, setCategories] = useState<Array<string>>([]);
  const [isOpenDeleteEventModal, setOpenDeleteEventModal] = useState<boolean>(false);
  const [isEventDeleted, setIsEventDeleted] = useState<boolean>(false);

  const isZoomEvent = !event?.externalUrl;

  const [DeleteEvent] = useMutation(DELETE_EVENT);
  const [getEvent, { loading }] = useLazyQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.event === null) {
        displayErrorNotification(
          t("event.deleteEvent.deletedEventByOwner", {
            name: event?.name,
          })
        );
        history.push("/portal/events");
      }
      setEvent(data.event);
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: Array.of(data.event.eventHost.email),
          },
        },
      });
      setIsLoading(false);
      setCategories(data.event.interests.map((cat: IInterest) => cat.name));
    },
    onError: (error) => {
      setIsLoading(false);
      setIsEventDeleted(true);
    },
  });

  useEffect(() => {
    getEvent({ variables: { publicId: params.id } });
  }, [params.id]);

  // useEffect(() => {
  //   // if last received notification is linked to current event, refetch it's data
  //   const lastNotification = eventNotifications[eventNotifications.length - 1];
  //   if (lastNotification && lastNotification.publicId === params.id)
  //     getEvent({ variables: { publicId: params.id } });
  // }, [eventNotifications]);
  const handleRedirect = (url: string) => {
    setConfirmModalOpen(true);
    setSelectedRecordingUrl(url);
  };

  const [userImage, setUserImage] = useState<string>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo.userPhotoName,
          directoryName: userPhotosInfo.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  // Delete Event
  const handleDeleteEvent = (event: IEvent) => {
    if (typeof event === "undefined") return;

    DeleteEvent({
      variables: {
        eventPublicId: event.publicId,
      },
    })
      .then((response) => {
        if (!response.data) return;
        displaySuccessNotification(
          t("event.deleteEvent.successDeleteEvent", {
            name: event?.name,
          })
        );
        history.push(EVENT_ROUTES.UPCOMING);
      })
      .catch((error) => {
        displayNotSuccessNotification(error);
      });
  };

  if (isEventDeleted) return <PageNotFound />;

  return loading ? (
    <div className={styles.loadingContainer}>
      <Loading disableBoxShadow />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.img}>
          <EventPageBackButton />
        </div>
        <div className={styles.categoriesContainer}>
          <ResourcesCategories categories={categories} />
        </div>
        <div className={styles.row}>
          <EventImageCard
            image={getStorageImage({
              image: event?.eventImageName,
              directoryName: event?.imageDirectory,
              type: STORAGE_IMAGE_TYPE_EVENT,
            })}
          />
          {event && (
            <EventTimeCard
              hostingId={event.hostingId}
              publicId={params.id}
              externalUrl={event.externalUrl}
              name={event.name}
              startDate={event.startDate.toString()}
              endDate={event.endDate.toString()}
              isLoading={isLoading}
              startTime={event.startDate}
              endTime={event.endDate}
              eventType={event.eventType}
              host={event.eventHost.fullName}
              hostEmail={event.eventHost.email}
              setOpenDeleteEventModal={setOpenDeleteEventModal}
            />
          )}
        </div>
      </div>
      <div className={styles.content}>
        {event && (
          <EventDetailTabs
            attachmentsDirectory={event.attachmentsDirectory}
            attachments={event.attachments.map((attachment) => ({
              name: attachment.name,
              size: attachment.size,
            }))}
            description={event.description}
            externalUrl={event.externalUrl}
          />
        )}
        <div className={styles.eventCardsContainer}>
          {event && (
            <EventHostCard
              username={event.eventHost.fullName}
              userEmail={event.eventHost.email}
              avatar={userImage ? userImage : Avatar}
            />
          )}
          {isZoomEvent && (
            <>
              <div className={styles.eventCard}>
                <div className={styles.cardTitle}>{t("events.recordings")}</div>
                {event?.eventRecordingFileLinks &&
                  event.eventRecordingFileLinks.map((url) => (
                    <a
                      onClick={() => handleRedirect(url)}
                      key={url}
                      className={styles.recording}
                    >
                      {url}
                    </a>
                  ))}
              </div>
              <div className={styles.eventCard}>
                <div className={styles.cardTitle}>{t("events.password")}</div>
                <div className={styles.eventRecordingPassword}>
                  {event?.eventRecordingsPassword}
                </div>
              </div>
            </>
          )}
        </div>
        {confirmModalOpen && (
          <EventConfirmJoinModal
            text={t("events.recordingLinkText")}
            isOpen={confirmModalOpen}
            joinUrl={selectedRecordingUrl}
            closeModal={() => setConfirmModalOpen(false)}
          />
        )}
        {isOpenDeleteEventModal && (
          <DangerModal
            title={t("event.deleteEvent.deleteEventTitle", {
              title: event?.name,
            })}
            subTitle={t("event.deleteEvent.deleteEventSubTitle")}
            isOpen={isOpenDeleteEventModal}
            onClose={() => setOpenDeleteEventModal(false)}
            onSubmit={() => handleDeleteEvent(event as IEvent)}
            image={<img src={DangerIcon} />}
            closeButton={t("event.deleteEvent.keepEvent")}
            ctaButton={t("event.deleteEvent.deleteEvent")}
          />
        )}
      </div>
    </div>
  );
};

export default EventDetailsPage;
