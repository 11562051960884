import { useTranslation } from "react-i18next";

import { Form, Formik } from "formik";

import Button from "@components/Button/Button";
import { InputSelect } from "@components/formik/InputSelect/InputSelect";
import { InputText } from "@components/formik/InputText/InputText";

import { LanguageToKey, useGetLanguages } from "@feature/language";

import { Form as PhoneForm } from "@feature/phone";
import { Form as PronounForm } from "@feature/pronouns";

import { PersonalDetails } from "store/onboarding/types";

import { getInitialValues, getValidationSchema } from "./validation";

import { setDocumentDir } from "@utils/setDocumentDir";

import formStyles from "@components/formik/form.style.module.scss";

interface ICreateUpdateForm {
  personalDetails?: Partial<PersonalDetails>;
  onSubmit: (formData: PersonalDetails) => void;
}

export const CreateUpdateForm = ({ personalDetails, onSubmit }: ICreateUpdateForm) => {
  const { t, i18n } = useTranslation();
  const { languageOptions } = useGetLanguages();

  return (
    <Formik
      validationSchema={getValidationSchema(t)}
      initialValues={getInitialValues(personalDetails)}
      validateOnMount
      onSubmit={({ phone, ...data }) => {
        onSubmit({ ...data, countryCode: phone.countryCode, telephone: phone.telephone });
      }}
    >
      {({ isValid, submitForm, values }) => {
        return (
          <Form>
            <PronounForm isLabelDisplayed />
            <InputText
              label={t("form.label.fullName")}
              name="fullName"
              placeholder={t("form.placeholder.fullName")}
              isRequired
            />
            <InputText
              label={t("form.label.emailAddress")}
              name={"email"}
              placeholder={t("form.placeholder.emailAddress")}
              isRequired
              disabled
            />
            <InputSelect
              label={t("form.label.defaultLanguage")}
              subLabel={t("form.subLabel.defaultLanguage")}
              name={"defaultLanguage"}
              placeholder={t("form.placeholder.defaultLanguage")}
              options={languageOptions}
              onChange={(value) => {
                i18n.changeLanguage(LanguageToKey[value]);
                setDocumentDir(value);
              }}
              isSearchable
              className={"dropdown"}
            />
            <PhoneForm formPrefix="phone." isLabelDisplayed />
            <div className={formStyles.buttons}>
              <div></div>
              <Button
                title={t("button.next")}
                wrapperClassNames="reverseButtonWrapper"
                disabled={!isValid}
                onClick={submitForm}
                type="button"
                buttonType="primary"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
