import { useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import("dayjs/locale/en");
import("dayjs/locale/ko");
import("dayjs/locale/es");

export const useDateLocale = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!i18n.language) return;

    dayjs.locale(i18n.language);
  }, [i18n.language]);
};
