import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import Loading from "@components/Loading/Loading";

import EmptyComponent from "@components/EmptyComponent/EmptyComponent";
import styles from "./CollectionsGrid.module.scss";

import { IOption } from "@components/Menu/types";
import {
  ALL_COLLECTIONS,
  COLLECTION_CONTAINER,
  CREATED_BY_ME,
  PRIVATE,
  SHARED_WITH_ME,
} from "@constants/constants";
import { ICollection } from "@graphql/collection/type";
import EmptyFollowedCollections from "@images/collectionsResourcesicon.svg";
import EmptyMyCollections from "@images/emptyMyCollection.svg";
import { getUserRole } from "@utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export interface CollectionsGridProps {
  searchValue?: string;
  collections: Array<ICollection>;
  loadMore: () => void;
  e2eTestId?: string;
  collectionsLoading: boolean;
  collectionsLoadingMore: boolean;
  collectionsCount: number;
  menuDots?: (collection: ICollection) => void;
  type?: string;
  createNew?: () => void;
  from?: string;
  isEndlessScroll?: boolean;
  defaultCollection?: ICollection;
  collectionType?: string;
  linkedCollectionsIds?: string[];
}

const CollectionsGrid = ({
  searchValue,
  collections,
  loadMore,
  e2eTestId,
  collectionsCount,
  collectionsLoading,
  collectionsLoadingMore,
  menuDots,
  type,
  createNew,
  from,
  isEndlessScroll,
  defaultCollection,
  collectionType,
  linkedCollectionsIds = [],
}: CollectionsGridProps) => {
  const { t } = useTranslation();

  const userPublicId = useSelector<RootState, string>((state) => state.user.userPublicId);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  const translatedRoles: string[] = [
    t("collections.roles.owner"),
    t("collections.roles.follower"),
    t("collections.roles.collaborator"),
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (collectionsLoading || collectionsLoadingMore) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !collectionsLoading && !collectionsLoadingMore) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMore();
      setShouldLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  return (
    <div
      data-testid="collection-grid-outer"
      className={styles.outer}
      e2e-test-id={e2eTestId}
    >
      {collectionsLoading && (
        <div className={styles.loadingWrapper}>
          <Loading borderless disableBoxShadow e2eTestId="collections-loader" />
        </div>
      )}

      {searchValue && (
        <h3 className={styles.sectionTitle}>{`${t("resources.collectionsTitle")} ${t(
          "resources.relatedTo"
        )} ${searchValue}`}</h3>
      )}

      <div
        className={
          from === COLLECTION_CONTAINER
            ? styles.innerCollectionContainer
            : styles.innerOthers
        }
      >
        {/* OTHER COLLECTIONS */}
        {!!collections.length && (
          <>
            {collections.map((collection) => {
              const userRole = getUserRole(
                userPublicId,
                collection.collectionCreator,
                collection.followers!,
                collection.collaborators!,
                translatedRoles
              );

              const menu = menuDots && menuDots(collection);
              return (
                <CollectionsCard
                  key={collection.publicId}
                  title={collection.name}
                  description={collection.description}
                  updateTime={collection.updateTime}
                  publicId={collection.publicId}
                  resourcesCount={collection.articlesCount}
                  collaboratorsCount={collection.collaboratorsCount}
                  collectionCreator={collection.collectionCreator}
                  imageName={collection.imageName}
                  imageDirectory={collection.imageDirectory}
                  menuOptions={menu || undefined}
                  isPublic={collection.isPublic}
                  isGlobalCurated={collection?.isGlobalCurated}
                  collectionType={collection?.collectionType}
                  userRole={userRole}
                  isLinked={linkedCollectionsIds.includes(collection.publicId)}
                />
              );
            })}
          </>
        )}

        {/* DEFAULT COLLECTION (ALL COLLECTIONS AND PUBLIC) */}
        {defaultCollection && collectionType !== PRIVATE && (
          <CollectionsCard
            key={defaultCollection.publicId}
            title={defaultCollection.name}
            description={defaultCollection.description}
            updateTime={defaultCollection.updateTime}
            publicId={defaultCollection.publicId}
            resourcesCount={defaultCollection.articlesCount}
            collaboratorsCount={defaultCollection.collaboratorsCount}
            collectionCreator={defaultCollection.collectionCreator}
            imageName={defaultCollection.imageName}
            imageDirectory={defaultCollection.imageDirectory}
            isPublic={defaultCollection.isPublic}
            menuOptions={
              menuDots && (menuDots(defaultCollection) as unknown as IOption[])
            }
            isDefault={true}
          />
        )}
      </div>

      {!!collections.length && collectionsCount > collections.length && (
        <Button
          data-testid="view-more-collections"
          aria-label="view-more-collections"
          title={t("collections.buttons.viewMore")}
          wrapperClassNames={styles.loadMoreButton}
          buttonIcon={<span className={styles.plusIcon}>+</span>}
          onClick={loadMore}
          loading={collectionsLoadingMore}
          disabled={collectionsLoadingMore}
          customRef={isEndlessScroll ? loadMoreElementRef : null}
        />
      )}

      {/* NO COLLECTIONS */}
      {!collections.length && (
        <>
          {type && type === ALL_COLLECTIONS && (
            <EmptyComponent
              title={t("collections.emptyAllCollectionsTab.title")}
              subTitle=""
              image={EmptyMyCollections}
            />
          )}
          {type && type === CREATED_BY_ME && (
            <EmptyComponent
              title={t("collections.emptyMyCollectionsTab.title")}
              subTitle={t("collections.emptyMyCollectionsTab.subtitle")}
              image={EmptyMyCollections}
              customSubTitle={
                <>
                  <span className={styles.newCollection} onClick={createNew}>
                    {t("collections.emptyMyCollectionsTab.link")}
                  </span>
                  <span>{t("collections.emptyMyCollectionsTab.subtitle")}</span>
                </>
              }
            />
          )}
          {type && type === SHARED_WITH_ME && (
            <EmptyComponent
              title={t("collections.emptyFollowedCollectionsTab.title")}
              subTitle={t("collections.emptyFollowedCollectionsTab.subtitle")}
              image={EmptyFollowedCollections}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CollectionsGrid;
