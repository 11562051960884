import { Col, Menu, Row } from "antd";
import styled from "styled-components";
import palette from "./../../../scss/exports.module.scss";

import WhoBlack from "@images/hero-who-black.png";
import Logo from "@images/hive_logo.png";

import { configuration } from "../../../modules/LandingPage/Data";

const StyledMenu = styled(Menu)`
  justify-content: center;
  border: none;
  background-color: transparent;
  color: ${palette.darkBlue};

  @media screen and (max-width: 768px) {
    justify-content: left;
    li:first-child {
      padding-left: 0 !important;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    color: ${palette.darkBlue} !important;
    &:after {
      border-bottom-width: 1px !important;
      border-color: ${palette.darkBlue} !important;
    }
  }
  &.ant-menu-item-selected {
    color: ${palette.darkBlue} !important;
    &:after {
      border-bottom-width: 1px !important;
      border-color: ${palette.darkBlue} !important;
    }
  }
`;

const Footer = () => {
  const { footer } = configuration;

  return (
    <Row
      style={{
        padding: "1.5rem 2.625rem",
        width: "100%",
      }}
      justify="space-between"
      align="middle"
    >
      <Col xs={24} md={8} span={8}>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <img
            height="25"
            style={{ transform: "translateY(3px)" }}
            src={WhoBlack}
            alt={footer?.logoSrc}
          />
          <img height="25" src={Logo} alt={footer?.logoSrc} />
        </div>
      </Col>

      {footer?.menu.length > 0 && (
        <Col xs={24} md={8} span={8}>
          <Col span={24}>
            <StyledMenu mode="horizontal">
              {footer?.menu.map((item) => (
                <StyledMenuItem key={item.key}>{item.text}</StyledMenuItem>
              ))}
            </StyledMenu>
          </Col>
        </Col>
      )}

      <Col xs={24} md={8} span={8}>
        {footer?.socialLinks.length > 0 && (
          <Col span={24}>
            <Row justify={{ xs: "start", sm: "end", md: "end", lg: "end" }}>
              {footer?.socialLinks.map((item) => {
                const lastItem = footer?.socialLinks[footer?.socialLinks.length - 1];

                return (
                  <Col key={item.key} span={lastItem.key === item.key ? "" : 3}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img width="24" height="24" src={item.src} alt={item.key} />
                    </a>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default Footer;
