export const COLLECTION_ROUTES_PREFIX = "/portal/collections";

export enum COLLECTION {
  MY_COLLECTIONS = "my-collections",
  EXPLORE = "explore",
}

export enum COLLECTION_SUB_SECTION {
  VIEW_MORE = "view-more",
}

export const COLLECTION_ROUTES = {
  COLLECTIONS: COLLECTION_ROUTES_PREFIX,
  MY_COLLECTIONS: `${COLLECTION_ROUTES_PREFIX}/${COLLECTION.MY_COLLECTIONS}`,
  EXPLORE: `${COLLECTION_ROUTES_PREFIX}/${COLLECTION.EXPLORE}`,
};
