import { useEffect, useRef, useState } from "react";

import { MIN_DESCRIPTION_HEIGHT } from "./constant";

export const useReadMore = () => {
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const [shouldRenderSeeMore, setShouldRenderSeeMore] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (textRef.current && textRef.current.clientHeight < MIN_DESCRIPTION_HEIGHT) {
      setShouldRenderSeeMore(false);
    } else {
      setShouldRenderSeeMore(true);
    }
  }, []);

  return { shouldRenderSeeMore, toggleReadMore, textRef, isReadMore };
};
