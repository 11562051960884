import { QuestionCircleOutlined } from "@ant-design/icons";
import Button from "@components/Button/Button";
import Linkify from "react-linkify";
import styles from "./FeedbackCard.module.scss";

interface IFeedbackCardProps {
  title: string;
  content: string;
  buttonText: string;
  onButtonClick: (x: boolean) => void;
}

const FeedbackCard = ({
  title,
  content,
  buttonText,
  onButtonClick,
}: IFeedbackCardProps) => {
  return (
    <div
      className={styles.container}
      aria-label="feedback-card"
      e2e-test-id="feedback-container"
    >
      <span
        aria-label="title"
        className={styles.title}
        e2e-test-id="feedback-section-title"
      >
        {title}
      </span>
      <span
        aria-label="content"
        className={styles.content}
        e2e-test-id="feedback-section-text"
      >
        <Linkify>{content}</Linkify>
      </span>
      <Button
        aria-label="feedback-btn"
        title={buttonText}
        e2eTestId="provide-feedback-button"
        onClick={() => onButtonClick(true)}
        buttonIcon={<QuestionCircleOutlined />}
      />
    </div>
  );
};

export default FeedbackCard;
