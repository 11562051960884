import { components, DropdownIndicatorProps } from "react-select";

export const DropdownIndicator = ({ children, ...props }: DropdownIndicatorProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = (props.selectProps as any)["data-icon"];

  return (
    <components.DropdownIndicator {...props}>
      {icon ? <></> : children}
    </components.DropdownIndicator>
  );
};
