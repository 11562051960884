import { useRef } from "react";

import { useTranslation } from "react-i18next";

import { CreateUpdateForm, Pronouns, PronounsToTranslationKey } from "@feature/pronouns";

import { EditField, IEditFieldRef } from "../../EditField/EditField";

interface IPronounsFieldProps {
  pronouns: Pronouns;
  onSubmit: (value: string, onSuccess: () => void) => void;
  isLoading: boolean;
}

export const PronounsField = (props: IPronounsFieldProps) => {
  const { t } = useTranslation();
  const editFieldRef = useRef<IEditFieldRef | null>(null);

  return (
    <EditField
      title={t("form.label.preferredPronouns")}
      displayText={t(PronounsToTranslationKey[props.pronouns])}
      ref={editFieldRef}
      isRequired={false}
    >
      <CreateUpdateForm
        {...props}
        onSubmit={(name) => {
          props.onSubmit(name, () => editFieldRef.current?.closeEditMode());
        }}
      />
    </EditField>
  );
};
