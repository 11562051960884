import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { GroupChannel, SendBirdError } from "sendbird";

import { NO_ACCESS_CODE } from "@constants/constants";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { ERROR_CODE } from "../constant";
import { useSendBirdContext } from "../context/context";

export const useGetGroupChannel = () => {
  const { t } = useTranslation();
  const { sb } = useSendBirdContext();
  const [groupChannel, setGroupChannel] = useState<GroupChannel>();
  const [sendBirdId, setSendBirdId] = useState<string>("");

  useEffect(() => {
    if (!sendBirdId || !sb) return;

    const getChannel = async () => {
      try {
        const channel = await sb.GroupChannel.getChannel(sendBirdId);
        setGroupChannel(channel);
      } catch (error) {
        const { code } = error as SendBirdError;
        if (code === ERROR_CODE.CHANNEL_NOT_FOUND.code) {
          return;
        }

        if (code === NO_ACCESS_CODE) return;

        displayNotSuccessNotification(error);
      }
    };

    getChannel();
  }, [sendBirdId, sb, t]);

  return { groupChannel, setSendBirdId };
};
