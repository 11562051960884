import { useState } from "react";

import { useTranslation } from "react-i18next";

import { displayErrorNotification } from "@services/NotificationService/NotifacitonService";

import {
  FileChecker,
  FileErrorKey,
  FileErrorKeyToTranslation,
  defaultSupportedExtensions,
} from "@utils/files";

interface IUseUploadFileProps {
  multiple?: boolean;
  supportedExtensions?: Array<string>;
}

export const useUploadFile = ({
  multiple,
  supportedExtensions = defaultSupportedExtensions,
}: IUseUploadFileProps) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);

  const validateAndUpdateSelectedFiles = async (filesList: FileList | null) => {
    if (!filesList?.length) return;

    const files = Array.from(filesList);
    if (!(await FileChecker.areUploadable(files, supportedExtensions))) {
      return;
    }

    if (!multiple && files.length > 1) {
      return displayErrorNotification(
        t(FileErrorKeyToTranslation.get(FileErrorKey.MULTIPLE))
      );
    }

    if (!multiple) {
      setSelectedFiles([files[0]]);
      return;
    }

    if (FileChecker.hasDuplicateFileName(files, selectedFiles)) {
      return;
    }

    setSelectedFiles([...selectedFiles, ...files]);
  };
  const clearFiles = () => {
    setSelectedFiles([]);
  };

  const removeFile = (index: number) => {
    const filesRemoved = [...selectedFiles];
    filesRemoved.splice(index, 1);

    setSelectedFiles(filesRemoved);
  };

  return {
    multiple,
    supportedExtensions,
    selectedFiles,
    validateAndUpdateSelectedFiles,
    clearFiles,
    removeFile,
  };
};
