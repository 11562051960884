import { useTranslation } from "react-i18next";

import { InputSelect } from "@components/formik/InputSelect/InputSelect";
import { InputText } from "@components/formik/InputText/InputText";
import { Label } from "@components/formik/Label/Label";

import { useGetCountries } from "@feature/country/service/useGetCountries/useGetCountries";

import Plus from "@images/plus.svg";

import formStyles from "@components/formik/form.style.module.scss";
import styles from "./Form.module.scss";

interface IPhoneFormProps {
  isLabelDisplayed?: boolean;
  formPrefix?: string;
}

export const Form = ({ isLabelDisplayed, formPrefix = "" }: IPhoneFormProps) => {
  const { t } = useTranslation();
  const { callingCodeOptions } = useGetCountries();

  return (
    <div className={formStyles.column}>
      {isLabelDisplayed && (
        <Label
          text={t("form.label.telephone")}
          name=""
          subText={t("input.telephone.textBottom")}
        />
      )}
      <div className={styles.row}>
        <div>
          <InputSelect
            placeholder=""
            name={`${formPrefix}countryCode`}
            options={callingCodeOptions}
            filterOption={{ matchFrom: "start" }}
            isSearchable
            icon={Plus}
            iconSide="left"
            className="phoneNumber"
            isClearable={false}
            inputType="number"
          />
        </div>
        <div>
          <InputText
            label=""
            placeholder={t("form.placeholder.telephone")}
            name={`${formPrefix}telephone`}
            pattern="^[0-9]*$"
            customValidation={true}
            aria-label="telephone"
            type="text"
            maxLength={25}
            wrapperClassNames="numberInput"
          />
        </div>
      </div>
    </div>
  );
};
