import { ActionTypes, IAddCrumb, IRemoveCrumb } from "./actions";
import { ADD, CLEAR, REMOVE, SET_BREADCRUMB } from "./constants";
import { BreadcrumbsState } from "./types";

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
};

const addCrumb = ({ breadcrumbs }: BreadcrumbsState, { payload }: IAddCrumb) => {
  if (breadcrumbs.some((crumb) => crumb.id === payload.id)) {
    if (payload.id !== breadcrumbs[breadcrumbs.length - 1].id) {
      const crumbIndex = breadcrumbs.findIndex((crumb) => crumb.id === payload.id);
      const slicedBreadcrumbs = breadcrumbs.slice(0, crumbIndex + 1);

      return [...slicedBreadcrumbs];
    }

    return [...breadcrumbs];
  } else {
    return [...breadcrumbs, payload];
  }
};

const removeCrumb = (
  { breadcrumbs }: BreadcrumbsState,
  { payload: crumb }: IRemoveCrumb
) => {
  return breadcrumbs.filter((breadcrumb) => breadcrumb.id !== crumb.id);
};

const breadcrumbsReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case ADD:
      return {
        ...state,
        breadcrumbs: addCrumb(state, action),
      };

    case REMOVE:
      return {
        ...state,
        breadcrumbs: removeCrumb(state, action),
      };

    case CLEAR:
      return {
        ...state,
        breadcrumbs: [],
      };
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    default:
      return state;
  }
};

export default breadcrumbsReducer;
