import { gql } from "@apollo/client";

const CONVERT_TO_PDF_REQUEST = gql`
  mutation convertToPdfRequest($convertToPdfRequest: ConvertToPdfInputType!) {
    convertToPDF(convertToPdfRequest: $convertToPdfRequest) {
      fileName
      directoryName
    }
  }
`;

export default CONVERT_TO_PDF_REQUEST;
