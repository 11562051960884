import { COLLECTION_ROUTES } from "@utils/routes/collection";
import { EVENT_ROUTES } from "@utils/routes/event";
import { Menu, MenuType } from "./enum";
import { IMenuItem } from "./type";

import { ReactComponent as Calendar } from "@images/calendar.svg";
import { ReactComponent as Community } from "@images/community.svg";
import { ReactComponent as HiveNoText } from "@images/epicenter_no_text.svg";
import { ReactComponent as Resources } from "@images/resources.svg";
import { ReactComponent as Support } from "@images/support.svg";

export const MENU_ITEMS: Array<IMenuItem> = [
  {
    id: Menu.DASHBOARD,
    Icon: HiveNoText,
    translationKey: "navbar.navigationItems.resources",
    path: "/portal/resources",
    includedPaths: ["/portal/resources"],
    e2eTestId: "dashboard",
    isActive: false,
    type: MenuType.PRIMARY,
  },
  {
    id: Menu.COMMUNITY,
    Icon: Community,
    translationKey: "navbar.navigationItems.community",
    path: "/portal/community",
    includedPaths: ["/portal/community"],
    e2eTestId: "community",
    isActive: false,
    type: MenuType.PRIMARY,
  },
  {
    id: Menu.EVENT,
    Icon: Calendar,
    translationKey: "navbar.navigationItems.events",
    path: EVENT_ROUTES.UPCOMING,
    includedPaths: ["/portal/events", "/portal/event"],
    e2eTestId: "events",
    isActive: false,
    type: MenuType.PRIMARY,
  },
  {
    id: Menu.DRIVE,
    Icon: Resources,
    translationKey: "navbar.navigationItems.collections",
    path: COLLECTION_ROUTES.MY_COLLECTIONS,
    includedPaths: ["/portal/collections", "/portal/collection"],
    e2eTestId: "hive-drive",
    isActive: false,
    type: MenuType.PRIMARY,
  },
  {
    id: Menu.SUPPORT,
    Icon: Support,
    translationKey: "navbar.subMenu.first",
    path: "/portal/support",
    includedPaths: ["/portal/support"],
    e2eTestId: "support",
    isActive: false,
    isBottom: true,
    type: MenuType.PRIMARY,
  },
];

export const MenuTypeToClassName: Record<MenuType, string> = {
  [MenuType.PRIMARY]: "primary",
  [MenuType.SECONDARY]: "secondary",
};
