import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import GET_SECTORS from "./graphQL";

import { DefaultOption } from "types/select";

import { IGetSectorsResponse } from "./type";

export const useGetSectors = () => {
  const { t } = useTranslation();
  const [sectorOptions, setSectorOptions] = useState<Array<DefaultOption>>([]);

  useQuery<IGetSectorsResponse>(GET_SECTORS, {
    onCompleted: (response) => {
      const options = response.sectors.map(({ name }) => ({
        value: name,
        label: t(`sector.${name}`),
      }));

      setSectorOptions(options);
    },
  });

  return { sectorOptions };
};
