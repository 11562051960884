import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Menu from "@components/Menu/Menu";

import LinkIcon from "@images/link-icon-small.svg";

import { IOption } from "@components/Menu/types";
import { ILink } from "types/link";

import { useCardTextMaxLength } from "@customHooks/useCardTextMaxLength";
import { handleAcceptingRedirect } from "@utils/helpers";

import TextTruncated from "@components/TextTruncated/TextTruncated";
import styles from "./LinkCard.module.scss";

interface ILinkCard {
  link: ILink;
  menuOptions?: IOption[];
}

const LinkCard = ({ link, menuOptions }: ILinkCard) => {
  const { t } = useTranslation();

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);

  const baseUrl = process.env.REACT_APP_BASE_URL as string;
  const isOnHiveWebsite = link.url.includes(baseUrl);

  const { maxTitleLength } = useCardTextMaxLength();

  const handleRedirect = () => {
    if (isOnHiveWebsite) {
      window.location.href = link.url;
    } else {
      setIsConfirmationModalOpened(true);
    }
  };

  return (
    <>
      <div className={styles.card} onClick={handleRedirect}>
        {menuOptions?.length ? (
          <div className={styles.menuWrapper}>
            <Menu options={menuOptions} />
          </div>
        ) : (
          <div className={styles.noOptionsDiv} />
        )}
        <img src={LinkIcon} className={styles.icon} />
        <TextTruncated
          textToTruncate={link.name}
          length={maxTitleLength}
          placement="topLeft"
        />
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(link.url, setIsConfirmationModalOpened)}
        url={link.url}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};

export default LinkCard;
