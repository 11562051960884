import dayjs from "dayjs";
import englishDate from "dayjs/locale/en";
import spanishDate from "dayjs/locale/es";
import FrenchDate from "dayjs/locale/fr";
import koreanDate from "dayjs/locale/ko";
import RussianDate from "dayjs/locale/ru";
import ChineseDate from "dayjs/locale/zh";
import ArabicDate from "dayjs/locale/ar";

import { EVENTS_GRID_SIZE } from "../constants/constants";

import { IEvent } from "../types/event";

export const filterOutPassedEvents = (events: Array<IEvent>) => {
  const actualEvents = events.filter(({ endDate }) => new Date(endDate) > new Date());

  return actualEvents;
};

export const isPastEvent = (endDate: string) => new Date() > new Date(endDate);

export const formatDate = (eventDate: Date, language: string, format?: string) => {
  switch (language) {
    case "ar":
      dayjs.locale(ArabicDate);
      break;
    case "es":
      dayjs.locale(spanishDate);
      break;
    case "ko":
      dayjs.locale(koreanDate);
      break;
    case "fr":
      dayjs.locale(FrenchDate);
      break;
    case "ru":
      dayjs.locale(RussianDate);
      break;
    case "zh":
      dayjs.locale(ChineseDate);
      break;
    default:
      dayjs.locale(englishDate);
  }

  return dayjs(eventDate).format(format || "DD MMM YYYY, h:mmA");
};

export const simulatePagination = (events: Array<IEvent>, limiter: number) => {
  return events ? events.slice(0, limiter) : [];
};

export const findFirstItemFromNextPage = (events: Array<IEvent>, limiter: number) =>
  simulatePagination(events, limiter)[limiter - EVENTS_GRID_SIZE].publicId;
