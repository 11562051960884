import { useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import UPDATE_COMMUNITY_PRIVATE_JOIN from "@graphql/mutations/updateCommunityPrivateJoin";
import Avatar from "@images/big_avatar.svg";
import CheckMark from "@images/checkMark.svg";
import CloseMark from "@images/closeMark.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SendBird from "sendbird";
import { IUser } from "types/user";
import { v4 as uuidv4 } from "uuid";

import UserDrawer from "@modules/UserDrawer/UserDrawer";
import withUser from "../../hoc/withUser";

import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import styles from "./RequestCard.module.scss";

interface IRequestCard {
  user: IUser;
  community?: SendBird.GroupChannel;
  onClick: (id: string) => void;
}

const USER_INTERESTS_LIMIT = 10;

const RequestCard = ({ user, community, onClick }: IRequestCard) => {
  const { t } = useTranslation();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const [viewAllInterests, setViewAllInterests] = useState<boolean>(false);
  const [isAnswerInProgress, setIsAnswerInProgress] = useState<boolean>(false);
  const [updateCommunityPrivateJoin] = useMutation(UPDATE_COMMUNITY_PRIVATE_JOIN);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();

  const resolveStatusRequest = () => {
    if (!community) return;
    setIsAnswerInProgress(true);
    updateCommunityPrivateJoin({
      variables: {
        joinRequest: {
          sendbirdId: community.url,
          userEmail: user.email,
          isAccepted: true,
        },
      },
    })
      .then(() => {
        community?.inviteWithUserIds([user.email], function (response, error) {
          if (error) {
            displayServerError(error);
            return;
          }
          onClick(user.email);
        });
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        if (errorCode === "2007") {
          return false;
        }
        displayServerError();
      });
  };

  const resolveStatusRequestDecline = () => {
    if (!community) return;
    setIsAnswerInProgress(true);
    updateCommunityPrivateJoin({
      variables: {
        joinRequest: {
          sendbirdId: community.url,
          userEmail: user.email,
          isAccepted: false,
        },
      },
    })
      .then(() => {
        onClick(user.email);
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        if (errorCode === "2007") {
          return false;
        }
        displayServerError();
      });
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img
          className={styles.avatarImage}
          src={Avatar}
          onClick={() => setSelectedUserEmail(user.email)}
        />
        <div
          className={styles.userWrapper}
          onClick={() => setSelectedUserEmail(user.email)}
        >
          <span className={styles.fullName}>{user.fullName}</span>
          <span className={styles.role}>
            {getRoleOrganizationText(user.organization)}
          </span>
        </div>
        <div className={styles.interestsRow}>
          <span className={styles.interestTitle}>
            {t("requests.requestCard.interests")}
          </span>
          {user.interests.length <= USER_INTERESTS_LIMIT ? (
            <div className={styles.interestsContainer}>
              {user.interests.map((interest) => (
                <span className={styles.interest} key={uuidv4()}>
                  {interest.name}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.interestsContainer}>
              {user.interests.slice(0, USER_INTERESTS_LIMIT).map((interest) => (
                <span className={styles.interest} key={uuidv4()}>
                  {interest.name}
                </span>
              ))}
              {viewAllInterests ? (
                user.interests.slice(USER_INTERESTS_LIMIT).map((interest) => (
                  <span className={styles.interest} key={uuidv4()}>
                    {interest.name}
                  </span>
                ))
              ) : (
                <span
                  onClick={() => setViewAllInterests(true)}
                  className={styles.interest}
                >
                  {`+ ${user.interests.length - USER_INTERESTS_LIMIT} ${t(
                    "requests.requestCard.more"
                  )}`}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            resolveStatusRequest();
          }}
          disabled={isAnswerInProgress}
          title={t("requests.requestCard.accept")}
          icon={<img src={CheckMark} />}
          iconAfter={true}
          className={styles.buttonAccept}
        />
        <Button
          onClick={() => {
            resolveStatusRequestDecline();
          }}
          disabled={isAnswerInProgress}
          icon={<img src={CloseMark} />}
          className={styles.buttonDecline}
          iconAfter={true}
          title={t("requests.requestCard.decline")}
        />
        <UserDrawerWithUser
          email={selectedUserEmail ?? ""}
          closeInfoDrawer={() => setSelectedUserEmail("")}
          communityOptions={<></>}
        />
      </div>
    </div>
  );
};

export default RequestCard;
