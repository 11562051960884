import { gql } from "@apollo/client";

const REMOVE_REACTION_FROM_ARTICLE_COMMENT = gql`
  mutation removeReactionFromArticleComment($articleCommentPublicId: String!) {
    removeReactionFromArticleComment(
      removeArticleCommentReaction: { articleCommentPublicId: $articleCommentPublicId }
    ) {
      publicId
      reactionCount
      isLoggedUserReactedOnArticleComment
    }
  }
`;

export default REMOVE_REACTION_FROM_ARTICLE_COMMENT;
