import { IClassName } from "./type";

export const getClassName = ({
  hasBorder = true,
  isRounded = true,
  loading,
  type,
  icon,
  wrapperClassName,
  area = "md",
  styles,
}: IClassName) => {
  let newClassName = styles.inputWrapper;
  if (hasBorder) {
    newClassName = `${newClassName} ${styles.border}`;
  }

  if (isRounded) {
    newClassName = `${newClassName} ${styles.rounded}`;
  }

  if (icon) {
    newClassName = `${newClassName} ${styles.icon}`;
  }

  if (loading) {
    newClassName = `${newClassName} ${styles.loading}`;
  }

  if (wrapperClassName) {
    newClassName = `${newClassName} ${wrapperClassName}`;
  }

  newClassName = `${newClassName} ${styles[area]}`;

  return newClassName;
};
