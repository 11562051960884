import { gql } from "@apollo/client";

const UPDATE_FILE_CATEGORIES = gql`
  mutation updateFile($fileUpdate: UpdateFileInputType!) {
    updateFile(fileUpdate: $fileUpdate) {
      name
    }
  }
`;

export default UPDATE_FILE_CATEGORIES;
