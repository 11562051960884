import styles from "./DangerModal.module.scss";

import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Exit from "@images/exit.svg";

interface IDangerModal {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  closeButton?: string;
  ctaButton: string;
  image?: React.ReactNode;
  isLoading?: boolean;
  e2eTestId?: string;
}

const DangerModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  subTitle,
  closeButton,
  ctaButton,
  image,
  isLoading,
  e2eTestId,
}: IDangerModal) => {
  return (
    <Modal onRequestClose={onClose} isOpen={isOpen} modalType="narrow">
      <div
        className={styles.titleContainer}
        data-testid="modal-outer"
        e2e-test-id={e2eTestId}
      >
        {image && <div className={styles.mainImage}>{image}</div>}
        <h4 className={styles.title} e2e-test-id="confirmation-modal-title">
          {title}
        </h4>
        {subTitle && (
          <p className={styles.subTitle} e2e-test-id="confirmation-modal-text">
            {subTitle}
          </p>
        )}
        <img
          role="button"
          aria-label="close-modal"
          e2e-test-id="close-modal-icon"
          className={styles.closeImage}
          onClick={onClose}
          src={Exit}
          alt=""
        />
      </div>

      <div className={styles.buttons}>
        {closeButton && (
          <Button
            title={closeButton}
            buttonType="ghost"
            onClick={onClose}
            e2eTestId="cancel-action-button"
          />
        )}
        <Button
          title={ctaButton}
          disabled={isLoading}
          loading={isLoading}
          danger
          onClick={onSubmit}
          e2eTestId="confirm-action-button"
        />
      </div>
    </Modal>
  );
};

export default DangerModal;
