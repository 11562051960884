/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import CommunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import { DEFAULT_COMMUNITIES_GRID_SIZE } from "@constants/constants";
import GET_COMMUNITY_RESOURCES from "@graphql/queries/communityResources";
import SearchMoreIcon from "@images/searchMore.svg";
import { ICommunity } from "types/community";

import { displayServerError } from "@services/NotificationService/NotifacitonService";
import styles from "./CommunitySearch.module.scss";

interface ICommunitySearchProps {
  searchTerm: string;
}

export const CommunitySearch = ({ searchTerm }: ICommunitySearchProps) => {
  const { t } = useTranslation();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [totalCommunitiesCount, setTotalCommunitiesCount] = useState<number>(0);
  const [loadingCommunities, setLoadingCommunities] = useState(true);
  const [searchResultsMessage, setSearchResultMessage] = useState<string>("");

  const [getCommunities] = useLazyQuery(GET_COMMUNITY_RESOURCES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCommunities(data.communities.communities);
      setTotalCommunitiesCount(data.communities.totalCount);
      setLoadingCommunities(false);
    },
    onError: (error) => displayServerError(error),
  });

  const [loadMoreCommunities] = useLazyQuery(GET_COMMUNITY_RESOURCES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setCommunities([...communities, ...data.communities.communities]);
    },
    onError: (error) => displayServerError(error),
  });

  // initial load of 16 items
  useEffect(() => {
    setLoadingCommunities(true);
    getCommunities({
      variables: {
        skip: 0,
        size: DEFAULT_COMMUNITIES_GRID_SIZE,
        text: searchTerm,
      },
    });
  }, [searchTerm]);

  // search results message
  useEffect(() => {
    setSearchResultMessage(
      communities.length
        ? `${t("community.searchResults.found")} "${searchTerm}"`
        : t("community.searchResults.none")
    );
  }, [communities]);

  const handleViewMore = () => {
    loadMoreCommunities({
      variables: {
        skip: communities?.length,
        size: DEFAULT_COMMUNITIES_GRID_SIZE,
        text: searchTerm,
      },
    });
  };

  if (loadingCommunities) return <Loading />;

  if (!totalCommunitiesCount) {
    return (
      <div aria-label="No Results Found" className={styles.noResultsContainer}>
        <div className={styles.imgWrapper}>
          <img alt="" src={SearchMoreIcon} className={styles.img} />
        </div>
        <span className={styles.noResultsText}>
          {t("exploreCommunities.noSearchResult")}
        </span>
        <span className={styles.info}>{t("exploreCommunities.tryAgainSearch")}</span>
      </div>
    );
  }
  return (
    <div className={styles.communitySearch}>
      <h2 className={styles.searchResult} role="header" aria-label="search-header">
        {searchResultsMessage}
      </h2>
      <div className={styles.container}>
        <div className={styles.rowContainer} role="grid">
          {communities?.map((community: ICommunity, index) => (
            <div
              className={styles.communityCard}
              key={index}
              aria-label="community-card"
              role="listitem"
            >
              <CommunityCard community={community} loggedUser={currentUserEmail} />
            </div>
          ))}
        </div>

        {communities?.length < totalCommunitiesCount && (
          <div className={styles.viewMoreContainer}>
            <Button
              onClick={handleViewMore}
              title={t("button.loadMoreCommunities")}
              aria-label="View more communities"
            />
          </div>
        )}
      </div>
    </div>
  );
};
