import { useState } from "react";

import { useTranslation } from "react-i18next";

import { GroupChannel } from "sendbird";

import { DIRECT } from "@customHooks/sendBird/constant";
import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";

import AddGroupMemberModal from "@components/AddGroupMemberModal/AddGroupMemberModal";
import Button from "@components/Button/Button";

import { IUser } from "types/user";

import { ChatActions } from "../ChatActions/ChatActions";
import { IProfileImage } from "../ChatSideMenu/useGetUserImages/type";

import AddUser from "@images/add_user.svg";
import Avatar from "@images/avatar.svg";

import styles from "./Header.module.scss";

interface IHeaderProps {
  selectedGroupChannel?: GroupChannel;
  isDirectGroup: boolean;
  otherUser?: IUser;
  userImages?: IProfileImage[];
  chatName?: string;
  sendBirdGroupChannel: IUseSendBirdGroupChannel;
  selectedUserToBlockEmail: string;
}

export const Header = ({
  selectedGroupChannel,
  isDirectGroup,
  otherUser,
  userImages,
  chatName,
  sendBirdGroupChannel,
  selectedUserToBlockEmail,
}: IHeaderProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className={styles.header}>
      <div className={styles.subContainer}>
        {isDirectGroup && (
          <img
            className={styles.imageAvatar}
            src={
              userImages?.find((image) => image.email === otherUser?.email)?.image ||
              Avatar
            }
            alt="avatar"
          />
        )}
        <span className={styles.name}>{chatName}</span>
      </div>
      <div className={styles.subContainer}>
        {selectedGroupChannel && selectedGroupChannel.customType !== DIRECT && (
          <>
            <AddGroupMemberModal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              sendBirdGroupGroupChannel={sendBirdGroupChannel}
            />
            <Button
              title={t("chat.buttonAddMember")}
              icon={<img src={AddUser} alt="add user" />}
              onClick={() => setOpenModal(true)}
            />
          </>
        )}

        {isDirectGroup && otherUser && (
          <ChatActions
            sendBirdGroupChannel={sendBirdGroupChannel}
            selectedUser={otherUser}
            sendBirdId={selectedUserToBlockEmail}
          />
        )}
      </div>
    </div>
  );
};
