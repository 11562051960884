import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import removeIcon from "@images/cancelIcon.png";

import Modal from "@components/Modal/Modal";
import { FEEDBACK_MAX_LENGTH, FEEDBACK_SUBJECT_MAX_LENGTH } from "@constants/constants";
import ADD_FEEDBACK from "@graphql/mutations/addFeedback";
import SentFeedbackModal from "./SentFeedbackModal/SentFeedbackModal";

import { displayServerError } from "@services/NotificationService/NotifacitonService";
import styles from "./ProvideFeedbackModal.module.scss";

interface IProvideFeedbackModalProps {
  open: boolean;
  close: () => void;
}
const ProvideFeedbackModal = ({ open, close }: IProvideFeedbackModalProps) => {
  const [openSentFeedbackModal, setOpenSentFeedbackModal] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const { t } = useTranslation();
  const [addFeedback] = useMutation(ADD_FEEDBACK);

  const handleSubmit = () => {
    if (
      content.length > FEEDBACK_MAX_LENGTH ||
      subject.length > FEEDBACK_SUBJECT_MAX_LENGTH
    )
      return;

    addFeedback({
      variables: {
        feedback: {
          subject,
          content,
        },
        email: currentUserEmail,
      },
    })
      .then(() => setOpenSentFeedbackModal(true))
      .catch((error) => displayServerError(error));
    setContent("");
    setSubject("");
  };

  const disableSubmit: boolean = !subject.trim() || !content.trim();

  return (
    <Modal isOpen={open} modalType="primary">
      <img src={removeIcon} className={styles.removeIcon} onClick={close} alt="" />
      <div className={styles.titleContainer}>
        <span>{t("feedback.provideFeedbackModal.title")}</span>
      </div>
      <div className={styles.selectContainer}>
        <Input
          label={t("feedback.provideFeedbackModal.subjectInput.label")}
          placeholder={t("feedback.provideFeedbackModal.subjectInput.placeholder")}
          name="subject"
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <Input
          label={t("feedback.provideFeedbackModal.feedbackInput.label")}
          placeholder={t("feedback.provideFeedbackModal.feedbackInput.placeholder")}
          name="content"
          onChange={(e) => setContent(e.target.value)}
          required
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          title={t("feedback.provideFeedbackModal.cancel")}
          buttonType="link"
          onClick={close}
          buttonSize="large"
        />
        <Button
          title={t("feedback.provideFeedbackModal.buttonTitle")}
          buttonType="primary"
          onClick={handleSubmit}
          disabled={disableSubmit}
          buttonSize="middle"
        />
      </div>

      <SentFeedbackModal
        open={openSentFeedbackModal}
        close={() => setOpenSentFeedbackModal(false)}
        closeProvideModal={() => close()}
      />
    </Modal>
  );
};

export default ProvideFeedbackModal;
