/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Progress } from "antd";
import styled from "styled-components";

const StyledUl = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLi = styled.li`
  list-style: none;
  margin-right: 1rem;
  cursor: pointer;
`;

const StyledCol = styled(Col)<{ $padding: string }>`
  padding: ${(props) => props.$padding};

  @media screen and (max-width: 576px) {
    padding-bottom: 0;
  }
`;

interface Props {
  data: any;
  padding: string;
  currentSlideIndex: number;
  sliderPercentage: number;
  handleClick: (slideIdx: number) => void;
  trailColor: string;
  strokeColor: string;
}

const SliderDots = ({
  data,
  padding = "3rem 1rem 0 1rem",
  currentSlideIndex,
  sliderPercentage,
  trailColor,
  strokeColor,
  handleClick,
}: Props) => {
  return (
    <StyledCol $padding={padding}>
      <StyledUl>
        {data.map((_: any, slideIdx: number) => {
          return currentSlideIndex === slideIdx ? (
            <StyledLi key={slideIdx}>
              <Progress
                type="circle"
                percent={sliderPercentage}
                trailColor={trailColor}
                strokeColor={strokeColor}
                showInfo={false}
                width={40}
                strokeWidth={12}
              />
            </StyledLi>
          ) : (
            <StyledLi
              key={slideIdx}
              className="dot"
              onClick={() => handleClick(slideIdx)}
            ></StyledLi>
          );
        })}
      </StyledUl>
    </StyledCol>
  );
};

export default SliderDots;
