import { gql } from "@apollo/client";

const REACT_ON_ARTICLE_COMMENT = gql`
  mutation reactOnArticleComment(
    $articleCommentPublicId: String!
    $reactionType: String!
  ) {
    reactOnArticleComment(
      articleCommentReaction: {
        articleCommentPublicId: $articleCommentPublicId
        reactionType: $reactionType
      }
    ) {
      publicId
      reactionCount
      isLoggedUserReactedOnArticleComment
    }
  }
`;

export default REACT_ON_ARTICLE_COMMENT;
