/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ALL_COLLECTIONS,
  GLOBAL_CURATED,
  SORTING_A_Z,
  SORTING_MOST_RECENT,
  SORTING_Z_A,
} from "@constants/constants";
import {
  EXPLORE_COLLECTIONS,
  GET_USER_INTERESTS_FOR_SLIDER,
  SEARCH_GLOBAL_CURATED_COLLECTIONS,
} from "@graphql/collection/queries";
import {
  ICollection,
  ICollectionsResponse,
  IGlobalCuratedCollectionsResponse,
} from "@graphql/collection/type";
import { DefaultOption } from "types/select";
import { RootState } from "../../../store/store";

import styles from "./Explore.module.scss";

import Button from "@components/Button/Button";
import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";
import Select from "@components/Select/Select";
import SelectInterestsModal from "@components/SelectInterestsModal/SelectInterestsModal";
import LinkCollectionToCommunityModal from "../LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";
import RecommendedGlobalCuratedCollectionsSection from "./RecommendedGlobalCuratedCollectionsSection/RecommendedGlobalCuratedCollectionsSection";

import ShareModal from "@components/ShareModal/ShareModal";
import { FOLLOW_COLLECTION, UNFOLLOW_COLLECTION } from "@graphql/collection/mutation";
import CatergoryIcon from "@images/category.svg";
import SearchMoreIcon from "@images/searchMore.svg";
import SortIcon from "@images/sortIcon.svg";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getMenuOptions } from "@utils/collectionsHelper";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import { IUser } from "types/user";
import { UserState } from "../../../store/user/types";

const pageSize = 8;

interface IUserInterest {
  __typename: string;
  name: string;
}

interface IGetUserInterestsResponse {
  user: {
    interests: IUserInterest[];
  };
}

const Explore = () => {
  const { t } = useTranslation();

  const [interests, setInterests] = useState<string[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [collectionsCount, setCollectionsCount] = useState<number>(0);
  const [collections, setCollections] = useState<Array<ICollection>>([]);
  const [collectionToLinkId, setCollectionToLinkId] = useState<string>();
  const [targetCommunityId, setTargetCommunityId] = useState<string>();
  const [collectionName, setCollectionName] = useState<string>();

  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState<boolean>(false);

  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);

  const [activeButton, setActiveButton] = useState<
    typeof ALL_COLLECTIONS | typeof GLOBAL_CURATED
  >(ALL_COLLECTIONS);
  const [selectedInterests, setSelectedInterests] = useState<Array<DefaultOption>>([]);
  const [openCategoriesModal, setOpenCategoriesModal] = useState<boolean>(false);

  const sortOptions = [
    {
      value: SORTING_MOST_RECENT,
      label: t("collections.sorting.mostRecent"),
    },
    { value: SORTING_A_Z, label: t("collections.sorting.alphabeticalAZ") },
    { value: SORTING_Z_A, label: t("collections.sorting.alphabeticalZA") },
  ];

  const [sortValue, setSortValue] = useState(sortOptions[0]);

  const user = useSelector<RootState, UserState>((state) => state.user);

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareCollectionUrl, setShareCollectionUrl] = useState<string>();

  useQuery<IGetUserInterestsResponse>(GET_USER_INTERESTS_FOR_SLIDER, {
    onCompleted: (data) => {
      const mappedInterests = data.user.interests.map(
        (interest: IUserInterest) => interest.name
      );
      setInterests(mappedInterests);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const [getCollections, { loading: loadingCollections }] =
    useLazyQuery<ICollectionsResponse>(EXPLORE_COLLECTIONS, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      onCompleted: ({ collections: collectionData }) => {
        if (!collectionData || !collectionData.collections) return;

        setCollections([...collections, ...collectionData.collections]);

        setCollectionsCount(collectionData?.totalCount || 0);
        setIsLoadingMore(false);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [getGlobalCuratedCollections, { loading: loadingGlobalCuratedCollections }] =
    useLazyQuery<IGlobalCuratedCollectionsResponse>(SEARCH_GLOBAL_CURATED_COLLECTIONS, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      onCompleted: ({ globalCuratedCollections: globalCuratedCollectionsData }) => {
        if (!globalCuratedCollectionsData || !globalCuratedCollectionsData.collections)
          return;

        setCollections([...collections, ...globalCuratedCollectionsData.collections]);

        setCollectionsCount(globalCuratedCollectionsData.totalCount || 0);
        setIsLoadingMore(false);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  const [followCollection] = useMutation(FOLLOW_COLLECTION, {
    onCompleted: (data) => {
      if (!data?.followCollection?.name) {
        const message = t("collections.singleCollection.followCollection");
        displayNotSuccessNotification(message, message);
      }
      displaySuccessNotification(t("collections.singleCollection.successFollow"));
    },
    onError: (error) => displayServerError(error),
  });

  const [unfollowCollection] = useMutation(UNFOLLOW_COLLECTION, {
    onCompleted: (data) => {
      if (!data?.unfollowCollection?.name) {
        const message = t("collections.singleCollection.unfollowCollection");
        displayNotSuccessNotification(message, message);
      }
      displaySuccessNotification(t("collections.singleCollection.successUnfollow"));
    },
    onError: (error) => displayServerError(error),
  });

  const handleGetCollections = (
    activeButtonType: typeof ALL_COLLECTIONS | typeof GLOBAL_CURATED,
    skip: number,
    size: number,
    interests: string[],
    sortingCriteria: string
  ) => {
    if (activeButtonType === ALL_COLLECTIONS) {
      getCollections({
        variables: {
          collectionSearch: {
            skip,
            size,
            interests,
            sortingCriteria,
          },
        },
      });
    }
    if (activeButtonType === GLOBAL_CURATED) {
      getGlobalCuratedCollections({
        variables: {
          globalCuratedCollectionSearch: {
            skip,
            size,
            interests,
            sortingCriteria,
          },
        },
      });
    }
  };

  const handleActiveButtonClick = (e: React.MouseEvent) => {
    const activeButton = e.currentTarget.id as
      | typeof ALL_COLLECTIONS
      | typeof GLOBAL_CURATED;
    setActiveButton(activeButton);
    setCollections([]);

    handleGetCollections(
      activeButton,
      0,
      pageSize,
      selectedInterests.map((i) => i.value) || [],
      sortValue.value
    );
  };

  const loadMoreCollections = () => {
    collections.length && setCollections([...collections]);
    setIsLoadingMore(true);

    handleGetCollections(
      activeButton,
      collections.length,
      pageSize,
      selectedInterests.map((i) => i.value) || [],
      sortValue.value
    );
  };

  const handleSortChange = async (option: DefaultOption) => {
    setCollections([]);
    setSortValue(option);

    handleGetCollections(
      activeButton,
      0,
      pageSize,
      selectedInterests.map((i) => i.value) || [],
      option.value
    );
  };

  const handleCategoriesChange = async (options: DefaultOption[]) => {
    setCollections([]);
    setSelectedInterests(options);

    handleGetCollections(
      activeButton,
      0,
      pageSize,
      options.map((i) => i.value) || [],
      sortValue.value
    );
  };

  const handleCopyLink = () => {
    if (shareCollectionUrl) {
      navigator.clipboard.writeText(shareCollectionUrl);
      displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
    }
  };

  const hadleLinkCollection = (collection: ICollection) => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
    setCollectionToLinkId(collection.publicId);
    setCollectionName(collection.name);
  };

  const handleShareCollection = (collection: ICollection) => {
    setShareModalOpen(true);
    setShareCollectionUrl(
      `${window.location.origin}/portal/collection/${collection.publicId}`
    );
  };

  const handleFollowCollection = (collection: ICollection) => {
    followCollection({
      variables: {
        collectionPublicId: collection.publicId,
      },
    }).then(() => {
      setCollections((stateCollectins) =>
        stateCollectins.map((stateCol) => {
          if (stateCol.publicId === collection.publicId) {
            return {
              ...stateCol,
              followers: stateCol.followers?.concat({ email: user.email } as IUser),
            };
          } else {
            return stateCol;
          }
        })
      );
    });
  };

  const handleUnfollowCollection = (collection: ICollection) => {
    unfollowCollection({
      variables: {
        collectionPublicId: collection.publicId,
      },
    }).then(() => {
      setCollections((stateCollections) =>
        stateCollections.map((stateCol) => {
          if (stateCol.publicId === collection.publicId) {
            return {
              ...stateCol,
              followers: stateCol.followers?.filter(
                (follower) => follower.email !== user.email
              ),
            };
          } else {
            return stateCol;
          }
        })
      );
    });
  };

  const menuDots = (collection: ICollection) => {
    return getMenuOptions(collection, user.email, {
      link: (collection) => hadleLinkCollection(collection),
      share: (collection) => handleShareCollection(collection),
      follow: (collection) => handleFollowCollection(collection),
      unfollow: (collection) => handleUnfollowCollection(collection),
    });
  };

  return (
    <>
      {interests && (
        <RecommendedGlobalCuratedCollectionsSection
          interests={interests}
          menuDots={menuDots}
        />
      )}

      <div
        className={styles.outer}
        data-testid="collections-explore-outer"
        e2e-test-id="recommended-for-you-collections-container"
      >
        <div className={styles.innerText}>
          <h2 className={styles.title} e2e-test-id="recommended-for-you-title">
            {t("collections.exploreTab.title")}
          </h2>
        </div>
        <div className={styles.filtersSection}>
          <div className={styles.buttonsSection}>
            <Button
              idElement={ALL_COLLECTIONS}
              e2eTestId="all-collections-filter"
              buttonSize="large"
              className={
                activeButton === ALL_COLLECTIONS ? styles.activeButton : styles.notActive
              }
              title={t("collections.buttons.allCollections")}
              onClick={handleActiveButtonClick}
            />
            <Button
              idElement={GLOBAL_CURATED}
              e2eTestId="global-curated-collections-filter"
              buttonSize="large"
              className={
                activeButton === GLOBAL_CURATED ? styles.activeButton : styles.notActive
              }
              title={t("collections.buttons.globalCurated")}
              onClick={handleActiveButtonClick}
            />
          </div>

          <div className={styles.buttonsSection}>
            <Button
              onClick={() => setOpenCategoriesModal(true)}
              e2eTestId="categories-button"
              buttonIcon={<img className={styles.categoryIcon} src={CatergoryIcon} />}
              className={styles.categoriesButton}
              title={`(${
                selectedInterests.length ? selectedInterests.length : interests.length
              }) ${t("tour.dashboard.categories")}`}
            />
            <div
              className={styles.selectWrapper}
              e2e-test-id="collections-sorting-dropdown"
            >
              <Select
                label=""
                placeholder=""
                name={"Sort"}
                defaultSelect={sortOptions[0]}
                showDropdown
                options={sortOptions}
                onChange={(option: DefaultOption) => handleSortChange(option)}
                selectWithIcon
                icon={SortIcon}
              />
            </div>
          </div>
        </div>
        <CollectionsGrid
          collections={collections}
          e2eTestId="recommended-for-me-grid"
          menuDots={menuDots}
          loadMore={loadMoreCollections}
          collectionsLoading={
            (loadingCollections || loadingGlobalCuratedCollections) && !isLoadingMore
          }
          collectionsLoadingMore={loadingCollections || loadingGlobalCuratedCollections}
          collectionsCount={collectionsCount}
          isEndlessScroll
        />
        {!collections.length && !loadingCollections && !loadingGlobalCuratedCollections && (
          <div
            data-testid="no-results-found"
            aria-label="No Results Found"
            className={styles.noResultsContainer}
          >
            <div className={styles.imgWrapper}>
              <img src={SearchMoreIcon} className={styles.img} />
            </div>
            <span className={styles.noResultsText}>
              {t("collections.exploreTab.noCollections")}
            </span>
          </div>
        )}
        <SelectInterestsModal
          open={openCategoriesModal}
          closeModal={() => setOpenCategoriesModal(false)}
          setSelectedInterests={(options: DefaultOption[]) =>
            handleCategoriesChange(options)
          }
          selectedInterests={selectedInterests}
          currentUserInterests={interests}
        />
        {isLinkCollectionToCommuniyModalOpen && (
          <LinkCollectionToCommunityModal
            isOpen={isLinkCollectionToCommuniyModalOpen}
            collectionId={collectionToLinkId}
            collectionName={collectionName}
            targetCommunityId={targetCommunityId}
            onClose={handleCloseLinkCollectionToCommunityModal}
            onSetTargetCommunityId={handleSetTargetCommunityId}
          />
        )}
        <ShareModal
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          customUrl={shareCollectionUrl}
          copyLink={handleCopyLink}
          share={shareSuccessMessage}
        />
      </div>
    </>
  );
};

export default Explore;
