/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./EmojiPicker.module.scss";
import Picker, { IEmojiData } from "emoji-picker-react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

interface IEmojiPicker {
  addEmojiToMessage?: (emoji: string) => void;
  closeEmojiTable?: () => void;
  setIsEmojiPickerSubmitted: Dispatch<SetStateAction<boolean>>;
}

const EmojiPicker = ({
  addEmojiToMessage,
  closeEmojiTable,
  setIsEmojiPickerSubmitted,
}: IEmojiPicker) => {
  const [isEmojiSelected, setIsEmojiSelected] = useState<boolean>(false);
  const onEmojiClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    emojiObject: IEmojiData
  ) => {
    setIsEmojiSelected(true);
    addEmojiToMessage && addEmojiToMessage(emojiObject.emoji);
  };

  const outsideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const handleClickOutside = (event: any) => {
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        if (event.target.id !== "imgEmoji" && event.target.id !== "buttonEmoji")
          closeEmojiTable && closeEmojiTable();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideRef]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && isEmojiSelected) {
      setIsEmojiPickerSubmitted(true);
      closeEmojiTable && closeEmojiTable();
    }
  };

  return (
    <div ref={outsideRef} className={styles.emoji} onKeyDown={handleKeyDown}>
      <Picker
        onEmojiClick={onEmojiClick}
        groupVisibility={{ flags: false }}
        groupNames={{
          smileys_people: "",
          animals_nature: "",
          food_drink: "",
          travel_places: "",
          activities: "",
          objects: "",
          symbols: "",
          flags: "",
          recently_used: "",
        }}
      />
    </div>
  );
};

export default EmojiPicker;
