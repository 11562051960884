import { gql } from "@apollo/client";

export const COLLECTION_FRAGMENT = gql`
  fragment CollectionFields on CollectionType {
    publicId
    name
    description
    isPublic
    isGlobalCurated
    imageName
    imageDirectory
    updateTime
    creationTime
    collaboratorsCount
    # articlesCount
    collectionCreator {
      fullName
      publicId
      email
    }
    followers {
      publicId
    }
    collaborators {
      publicId
    }
  }
`;
