import { useLazyQuery } from "@apollo/client";
import Tag from "@components/Tag/Tag";
import TextTruncated from "@components/TextTruncated/TextTruncated";
import { COLLECTION, COMMUNITY, STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { useCommunityTilesTitleMaxLength } from "@customHooks/useCommunityTilesTitleMaxLength";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Avatar from "@images/avatar.svg";
import ClosedCommunityIcon from "@images/closedCommunityIcon.svg";
import CollectionIcon from "@images/collection-card-collections.svg";
import Banner from "@images/collections-baner-1.svg";
import ManagedByMeIcon from "@images/managedbymedefault.svg";
import OpenCommunityIcon from "@images/openCommunityIcon.svg";
import PrivateCommunityIcon from "@images/privateCommunityIcon.svg";
import getStorageImage from "@utils/getStorageImage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommunityType } from "types/community";
import { IUserImageResponse } from "types/user";
import TooltipComponent from "../../components/Tooltip/Tooltip";
import styles from "./CommunityCollectionCard.module.scss";

interface CommunityCollectionCardProps {
  from: string;
  image: string;
  name: string;
  role: string;
  numberOfCollections?: number;
  numberOfMembers?: number;
  communityType?: string;
  communityCreatorEmail?: string;
}

const CommunityCollectionCard = ({
  communityCreatorEmail,
  from,
  image,
  name,
  role,
  numberOfCollections,
  numberOfMembers,
  communityType,
}: CommunityCollectionCardProps) => {
  const { t } = useTranslation();
  const isCollection = from === COLLECTION;
  const isCommunity = from === COMMUNITY;

  const { maxTitleLength } = useCommunityTilesTitleMaxLength();

  const [communityCreatorImage, setCommunityCreatorImage] = useState<string>();

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages[0];
      setCommunityCreatorImage(
        getStorageImage({
          image: userPhotosInfo.userPhotoName,
          directoryName: userPhotosInfo.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  useEffect(() => {
    communityCreatorEmail &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: communityCreatorEmail,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityCreatorEmail]);

  const showCommunityType = () => {
    return communityType === CommunityType.private
      ? PrivateCommunityIcon
      : communityType === CommunityType.open
      ? OpenCommunityIcon
      : ClosedCommunityIcon;
  };

  const showCommunityTypeText = () => {
    return communityType === CommunityType.private
      ? "community.communityType.privateWithDescription"
      : communityType === CommunityType.open
      ? "community.communityType.openWithDescription"
      : "community.communityType.closedWithDescription";
  };

  return (
    <div className={styles.communityCollectionCard} e2e-test-id="community-card-small">
      {isCollection && (
        <div
          style={{
            backgroundImage: `url(${image ? image : Banner})`,
          }}
          className={styles.communityCollectionCardImage}
          e2e-test-id="community-profile-image-section"
        ></div>
      )}
      {isCommunity && (
        <div className={styles.communityImageWrapper}>
          <div
            style={{
              backgroundImage: `url(${image ? image : ManagedByMeIcon})`,
            }}
            className={styles.communityImage}
            e2e-test-id="community-profile-image-section"
          ></div>
        </div>
      )}
      <div className={styles.communityCollectionCardText}>
        <span className={styles.communityCollectionCardName} e2e-test-id="community-name">
          <TextTruncated textToTruncate={name} length={maxTitleLength} />
        </span>
        <div className={styles.communityCollectionCardValues}>
          <div className={styles.communityCollectionCardCollections}>
            {numberOfCollections && (
              <>
                <img src={CollectionIcon} />
                <span className={styles.communityCollectionCardCollectionsFont}>
                  {numberOfCollections}{" "}
                  {t(
                    numberOfCollections > 1
                      ? "collections.communityCollections.collections"
                      : "collections.communityCollections.collection"
                  )}
                </span>
              </>
            )}
            {(numberOfMembers || numberOfMembers! >= 0) && (
              <>
                <div
                  style={{ backgroundImage: `url(${communityCreatorImage || Avatar})` }}
                  className={styles.communityCreatorImage}
                  e2e-test-id="community-creator-image"
                />
                <span
                  className={styles.communityCollectionCardCollectionsFont}
                  e2e-test-id="number-of-members"
                >
                  {numberOfMembers}{" "}
                  {numberOfMembers === 1 ? (
                    <>{t("community.member")}</>
                  ) : (
                    <>{t("community.members")}</>
                  )}
                </span>
              </>
            )}
          </div>
          {/* ROLES */}
          {isCollection && (
            <span className={styles.communityCollectionCardRole}>
              {role && <Tag value={role} color="#697377" backgroundColor="#DDE3E6" />}
            </span>
          )}

          {isCommunity && (
            <div className={styles.communityTypeAndRole}>
              <TooltipComponent
                title={t(showCommunityTypeText())}
                tooltipType="secondary"
                placement="bottom"
                childToWrap={
                  <div
                    style={{ backgroundImage: `url(${showCommunityType()})` }}
                    className={styles.communityType}
                    e2e-test-id="community-type-section"
                  ></div>
                }
              />
              <span className={styles.communityRole} e2e-test-id="community-role-tag">
                {role && <Tag value={role} color="#A2ACB0" backgroundColor="#DDE3E6" />}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityCollectionCard;
