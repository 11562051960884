import { gql } from "@apollo/client";

const GET_REPORT_TYPES = gql`
  query {
    userReportTypes {
      publicId
      name
    }
  }
`;

export default GET_REPORT_TYPES;
