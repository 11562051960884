import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import React from "react";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  title?: string;
  childToWrap: React.ReactNode;
  tooltipType: "default" | "primary" | "secondary";
  placement?: TooltipPlacement;
}

const TooltipComponent = ({
  title,
  childToWrap,
  tooltipType,
  placement,
}: TooltipProps) => {
  return (
    <Tooltip
      zIndex={1103}
      title={title}
      overlayClassName={`${styles[tooltipType]}`}
      placement={placement || "top"}
    >
      {childToWrap}
    </Tooltip>
  );
};

export default TooltipComponent;
