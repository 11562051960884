import { useTranslation } from "react-i18next";

import { CreateUpdateForm } from "@feature/bio";

import { LinkedText } from "@components/DataDisplay/LinkedText/LinkedText";
import { useRef } from "react";
import { EditField, IEditFieldRef } from "../../EditField/EditField";

interface IBioFieldProps {
  bio?: string;
  onSubmit: (values: string, onSuccess: () => void) => void;
  isLoading: boolean;
}

export const BioField = (props: IBioFieldProps) => {
  const { t } = useTranslation();
  const editFieldRef = useRef<IEditFieldRef | null>(null);

  return (
    <EditField
      title={t("form.label.bio")}
      displayText={<LinkedText text={props.bio || ""} />}
      ref={editFieldRef}
    >
      <CreateUpdateForm
        {...props}
        onSubmit={(bio) => {
          props.onSubmit(bio, () => editFieldRef.current?.closeEditMode());
        }}
      />
    </EditField>
  );
};
