import { useLazyQuery } from "@apollo/client";
import BackButton from "@components/BackButton/BackButton";
import Loading from "@components/Loading/Loading";
import Avatar from "@images/avatar.svg";
import getStorageImage from "@utils/getStorageImage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import withUser from "../../../hoc/withUser";
import UserDrawer from "../../UserDrawer/UserDrawer";
import styles from "./SingleFeedback.module.scss";
// constants
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
// types
import { EMPTY_FEEDBACK, IFeedback } from "types/Feedback";
import { IUserImageResponse } from "types/user";
// queries
import GET_FEEDBACK from "@graphql/queries/feedback";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { ADMIN_PANEL_ROUTES } from "@utils/routes/adminPanel";

const SingleFeedback = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const [feedback, setFeedback] = useState<IFeedback>(EMPTY_FEEDBACK);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [userImage, setUserImage] = useState<{ image: string; email: string }[]>();

  const [getEvent, isLoading] = useLazyQuery(GET_FEEDBACK, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFeedback(data.feedback);
    },
    onError: (error) => displayServerError(error),
  });

  // feedback.reporter.email
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    fetchPolicy: "no-cache",
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo?.userPhotoName,
          directoryName: userPhotosInfo?.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  useEffect(() => {
    getEvent({ variables: { publicId: params.id } });
  }, [getEvent, params]);

  useEffect(() => {
    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: feedback.reporter.email,
        },
      },
    });
  }, [feedback, getUserImages]);

  const handleUserSelected = (email: string) => {
    setSelectedUserEmail(email);
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  const feedbackInfo = () => (
    <div>
      <BackButton
        label={t("adminPanel.feedbacks.backLink")}
        path={ADMIN_PANEL_ROUTES.FEEDBACKS}
      />
      <div className={styles.headRow}>
        <span role="publicId" className={styles.idText}>
          {feedback.autoIncrementedId}
        </span>
        <div
          onClick={() => handleUserSelected(feedback.reporter.email)}
          className={styles.userInfoContainer}
        >
          <div
            style={{ backgroundImage: `url(${userImage || Avatar})` }}
            className={styles.avatar}
          ></div>
          <span role="fullName" className={styles.headRowText}>
            {feedback.reporter.fullName}
          </span>
        </div>
      </div>
      <div className={styles.feedbackContainer}>
        <h4 className={styles.reportTitle}>{t("adminPanel.feedbacks.reportTitle")}</h4>
        <span className={styles.reportSubtitle}>{t("adminPanel.feedbacks.name")}</span>
        <span role="subject" className={styles.reportText}>
          {feedback.subject}
        </span>
        <span className={styles.reportSubtitle}>{t("adminPanel.feedbacks.details")}</span>
        <span role="content" className={styles.reportText}>
          {feedback.content}
        </span>
      </div>
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </div>
  );

  return <div>{isLoading.loading ? <Loading /> : feedbackInfo()}</div>;
};

export default SingleFeedback;
