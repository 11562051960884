import { gql } from "@apollo/client";

export const UPDATE_LINK = gql`
  mutation updateLink($linkUpdate: UpdateLinkInputType!) {
    updateLink(linkUpdate: $linkUpdate) {
      name
      publicId
    }
  }
`;
