/* eslint-disable react-hooks/exhaustive-deps */
import SelectComponent from "@components/Select/Select";
import { mapToOption } from "@utils/selectHelper";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultOption } from "types/select";

import { ITimeZone } from "types/timeZone";

interface ITimeZoneSelectProps {
  selectedTimeZone: string;
  onChange: (value: DefaultOption) => void;
  disabled?: boolean;
  timeZones: ITimeZone[];
}

export const TimeZoneSelect = ({
  selectedTimeZone,
  onChange,
  disabled,
  timeZones,
}: ITimeZoneSelectProps) => {
  const { t } = useTranslation();

  const [defaultSelectOption, setDefaultSelectOption] = useState<DefaultOption>();

  const timeZoneOptions = timeZones.map((zone) => {
    return mapToOption(zone.timeZones[0].name, zone.name);
  });

  const userLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const userLocalTimeZoneOption = useMemo(() => {
    if (!timeZones.length) return;

    const userLocalTimezoneValue = timeZones.find((zone) =>
      zone.timeZones.map((timeZone) => timeZone.name).includes(userLocalTimezone)
    );

    return mapToOption(
      userLocalTimezoneValue!.timeZones[0].name,
      userLocalTimezoneValue!.name
    );
  }, []);

  useEffect(() => {
    if (!selectedTimeZone) {
      setDefaultSelectOption(userLocalTimeZoneOption);
    }

    if (timeZones.length) {
      const selectedUserTimeZoneVale = timeZones.find((zone) =>
        zone.timeZones.map((timeZone) => timeZone.name).includes(selectedTimeZone)
      );

      if (selectedUserTimeZoneVale) {
        setDefaultSelectOption(
          mapToOption(
            selectedUserTimeZoneVale!.timeZones[0].name,
            selectedUserTimeZoneVale!.name
          )
        );
      }
    }
  }, [selectedTimeZone, timeZones]);

  return (
    <SelectComponent
      isSearchable
      className="timeZones"
      options={timeZoneOptions}
      value={defaultSelectOption}
      onChange={onChange}
      label=""
      placeholder={t("timeZone.timeZone")}
      name="timeZone"
      showDropdown
      isDisabled={disabled}
    />
  );
};
