/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useLazyQuery, useMutation } from "@apollo/client";

import Switch from "react-switch";

import Loader from "react-loader-spinner";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { toast } from "react-toastify";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import moment, { Moment } from "moment";

import * as Yup from "yup";

import { PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { TimePicker } from "antd";

import Button from "@components/Button/Button";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import DatePicker from "@components/DatePicker/DatePicker";
import ImagePickerWithModal from "@components/ImagePickerWithModal/ImagePickerWithModal";
import Input from "@components/Input/Input";
import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import Loading from "@components/Loading/Loading";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import { TimeZoneSelect } from "@components/SelectCustom/TimeZone/TimeZoneSelect";
import TooltipComponent from "@components/Tooltip/Tooltip";
import UploadModal from "@components/UploadModal/UploadModal";
import { UploadModalType } from "@components/UploadModal/type";

import { COMMUNITIES_MANAGED_BY_USER } from "@graphql/community/queries";
import { ICommunitiesManagedByUser } from "@graphql/community/types";
import UPDATE_EVENT from "@graphql/mutations/updateEvent";
import GET_EVENT from "@graphql/queries/event";
import GET_TIME_ZONES from "@graphql/queries/getTimeZones";
import GET_HOSTED_EVENTS from "@graphql/queries/hostedEvents";

import {
  STORAGE_IMAGE_TYPE_EVENT,
  UTC_OFFSET_INT_DATE_LINE_WEST,
} from "@constants/constants";
import { useInterestHelper } from "@customHooks/useInterestHelper";

import PageNotFound from "@modules/PageNotFound/PageNotFound";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getCustomNotificationByCode } from "@services/NotificationService/NotificationHelper";

import { filterOutPassedEvents } from "@utils/eventsHelper";
import { supportedEventExtensions } from "@utils/files/constants";
import { FileHelper } from "@utils/files/fileHelper";
import {
  BlobStorageContainer,
  GenerateSasTokenError,
  UploadError,
} from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage, {
  DEFAULT_COLLECTION_IMAGE_NAME_1,
  DEFAULT_EVENT_IMAGE_NAME_2,
  DEFAULT_EVENT_IMAGE_NAME_3,
} from "@utils/getStorageImage";
import { fileNameReplaceRegex, urlRegex, validURL } from "@utils/helpers";
import { EVENT_ROUTES } from "@utils/routes/event";
import { mapToOption } from "@utils/selectHelper";

import { IHostEventsResponse } from "types/HostEvents";
import { IEvent } from "types/event";
import { IImage } from "types/image";
import { IInterest } from "types/interest";
import { DefaultOption } from "types/select";
import { IGetTimeZonesResponse, ITimeZone } from "types/timeZone";

import { defaultImages } from "../NewEvent/constants";
import { ISelectedCommunity } from "../NewEvent/type";

import {
  END_DATE_TYPE,
  END_TIME_TYPE,
  EVENT_MIN_INTERVAL,
  MAX_MINUTES,
  MAX_URL_LENGTH,
  MIN_MINUTES,
  START_DATE_TYPE,
  START_TIME_TYPE,
  eventTypes,
  meetingTypes,
} from "./constant";
import { MeetingType, SubMeetingType } from "./enum";

import arrow from "@images/blueArrow.svg";
import ErrorCircle from "@images/circleError.svg";
import CloseMark from "@images/close_toast_black.svg";
import remove from "@images/iconRemove.png";
import SearchIcon from "@images/search_icon.svg";

import palette from "@scss/exports.module.scss";

import { getTimePickerDateFormat } from "../NewEvent/helper";

import formStyles from "@components/formik/form.style.module.scss";
import styles from "./EditEvent.module.scss";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import { getEventTypeFromResponse } from "./helper";

let DIRECTORY_NAME = "";

const externalUrlValidation = Yup.object({
  url: Yup.string().matches(urlRegex),
});

const EditEvent = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [title, setTitle] = useState<string>("");
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] = useState<string>();
  const [selectedMeetingType, setSelectedMeetingType] = useState<string>(
    MeetingType.Zoom
  );
  const [selectedSubMeetingType, setSelectedSubMeetingType] = useState<string>(
    SubMeetingType.Meeting
  );

  const [clickedStartDate, setClickedStartDate] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [clickedEndDate, setClickedEndDate] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Moment>(
    moment().add("m", 15 - (moment().minute() % 15))
  );
  const [endTime, setEndTime] = useState<Moment>(
    moment().add("m", 30 - (moment().minute() % 15))
  );

  const [externalUrl, setExternalUrl] = useState<string>("");
  const [urlLengthMax, setUrlLengthMax] = useState<boolean>(false);
  const [isExternalEvent, setIsExternalEvent] = useState(false);
  const [urlInvalid, setUrlInvalid] = useState<boolean>(false);
  const [isEventPast, setIsEventPast] = useState<boolean>(false);
  const [previousAttachments, setPreviousAttachments] =
    useState<Array<{ name: string; size: number }>>();

  const [timeZone, setTimeZone] = useState<string>("");
  const [timeZones, setTimeZones] = useState<ITimeZone[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>(
    t("event.newEvent.error.setTime")
  );
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<File>>([]);
  const [error, setError] = useState(true);
  const [errorDate, setErrorDate] = useState(false);
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [isEditingEvent, setIsEditingEvent] = useState<boolean>(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);

  const { uploadFiles, isUploadInProgress } = useUploadFiles();

  const [, setHostedEvents] = useState<Array<IEvent>>([]);
  const [selectedImage, setSelectedImage] = useState<IImage>(defaultImages[0]);

  // interests
  const { options: interestOptions } = useInterestHelper();
  const [selectedInterests, setSelectedInterests] = useState<Array<DefaultOption>>([]);
  const [savedInterests, setSavedInterests] = useState<Array<IInterest>>([]);
  // communities
  const [communitiesManagedByMe, setCommunitiesManagedByMe] = useState<DefaultOption[]>(
    []
  );
  const [selectedCommunities, setSelectedCommunities] = useState<
    Array<ISelectedCommunity>
  >([]);

  const [defaultCommunitiesSelectValue, setDefaultCommunitiesSelectValue] =
    useState<DefaultOption>();

  const [isEventNotFound, setIsEventNotFound] = useState<boolean>(false);

  // get communities managed by user
  const [getCommunitiesManagedByUser, { loading: managedByLoader }] =
    useLazyQuery<ICommunitiesManagedByUser>(COMMUNITIES_MANAGED_BY_USER, {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        const userCommunities = res.communitiesManagedByUser.communities.map(
          (community) => ({
            value: community.sendbirdId,
            label: community.name,
          })
        );

        setCommunitiesManagedByMe(userCommunities);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [getHostedEvents] = useLazyQuery<IHostEventsResponse>(GET_HOSTED_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const hostingEvents = data?.user?.hostingEvents;
      if (!hostingEvents?.length) return;
      setHostedEvents(filterOutPassedEvents(hostingEvents));
    },
    onError: () => {
      displayServerError();
    },
  });

  const [getTimeZones, { loading: loadingTimeZones }] =
    useLazyQuery<IGetTimeZonesResponse>(GET_TIME_ZONES, {
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        setTimeZones(data.timeZoneGroups.zoneGroups);
        localStorage.setItem("timeZones", JSON.stringify(data.timeZoneGroups.zoneGroups));
      },
      onError: () => {
        displayServerError();
      },
    });

  useEffect(() => {
    const zonesFromLocalStorage = JSON.parse(
      localStorage.getItem("timeZones") as string
    ) as ITimeZone[];
    if (zonesFromLocalStorage) {
      setTimeZones(zonesFromLocalStorage);
    } else {
      getTimeZones();
    }
  }, []);

  useEffect(() => {
    isEventPast && setDescriptionField(true);
  }, [isEventPast]);

  useEffect(() => {
    checkCurrentTime(startDate, startTime.format("HH:mm"));
  }, [timeZone]);

  useEffect(() => {
    if (externalUrl?.length === MAX_URL_LENGTH) {
      setUrlLengthMax(true);
    } else {
      setUrlLengthMax(false);
    }
  }, [externalUrl]);

  const getDefaultTimeZoneOption = () => {
    if (!timeZones.length) return;

    const userLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const userLocalTimezoneValue = timeZones.find((zone) =>
      zone.timeZones.map((timeZone) => timeZone.name).includes(userLocalTimezone)
    );

    return mapToOption(
      userLocalTimezoneValue!.timeZones[0].name,
      userLocalTimezoneValue!.name
    );
  };

  const getTimezoneOffset = (timeZone: string) => {
    // This is the case for -12:00 time zone because we do not have standard value for it
    if (timeZone === UTC_OFFSET_INT_DATE_LINE_WEST.name)
      return UTC_OFFSET_INT_DATE_LINE_WEST.offsetValue;

    //  eslint-disable-next-line
    const FormatIntl = Intl as any;

    const timeZoneName = FormatIntl.DateTimeFormat("ia", {
      timeZoneName: "shortOffset",
      timeZone,
    })
      .formatToParts()
      //  eslint-disable-next-line
      .find((i: any) => i.type === "timeZoneName").value;

    const offset = timeZoneName.slice(3);
    if (!offset) return 0;

    const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);

    const [, sign, hour, minute] = matchData;
    let result = parseInt(hour) * 60;
    if (sign === "+") result *= -1;
    if (minute) result += parseInt(minute);

    return result;
  };

  const getOffset = () => {
    if (!timeZone) return;
    return getTimezoneOffset(timeZone);
  };

  const [getEvent, { loading }] = useLazyQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ event }) => {
      const linkedCommunitiesIds = event.linkedCommunities.map(
        (linkedCommunity: { sendbirdId: string }) => linkedCommunity.sendbirdId
      );

      const defaultCommunityOptions = communitiesManagedByMe.filter((community) => {
        return !linkedCommunitiesIds.includes(community.value);
      });

      // saving interest for case interest options are loaded later
      dayjs(event.endDate).toDate() < dayjs().toDate() && setIsEventPast(true);
      setPreviousAttachments(event.attachments);
      setSavedInterests(event?.interests);
      setTitle(event?.name || "");
      setStartDate(new Date(event?.startDate));
      setEndDate(new Date(event?.endDate));
      setSelectedInterests(
        event?.interests?.map((item: IInterest) =>
          interestOptions?.find((interest) => interest?.value === item?.name)
        )
      );
      setCommunitiesManagedByMe(defaultCommunityOptions);
      setSelectedCommunities(
        event?.linkedCommunities?.map(
          (community: {
            name: string;
            sendbirdId: string;
            shouldNotifyMembers: boolean;
          }) => ({
            label: community.name,
            value: community.sendbirdId,
            shouldSendNotification: community.shouldNotifyMembers,
          })
        )
      );

      setStartTime(moment(event?.startDate));
      setEndTime(moment(event?.endDate));
      setTimeZone(event?.timeZone || getDefaultTimeZoneOption()?.value);
      setSelectedImage(
        defaultImages?.find((item) => item?.name === event?.eventImageName) ||
          defaultImages[0]
      );
      setExternalUrl(event?.externalUrl);
      setIsExternalEvent(!!event?.externalUrl);
      setDescriptionValue(event?.description);
      setSelectedEventType(getEventTypeFromResponse(event?.eventType));
      setSelectedMeetingType(event?.externalUrl ? "external" : "zoom");
      setSelectedSubMeetingType(event?.eventFormat);

      if (event?.description.length > 0) setDescriptionField(true);

      DIRECTORY_NAME = event?.attachmentsDirectory;

      let defaultImage: IImage | null | undefined;
      switch (event.eventImageName) {
        case DEFAULT_COLLECTION_IMAGE_NAME_1:
          defaultImage = defaultImages[0];
          break;
        case DEFAULT_EVENT_IMAGE_NAME_2:
          defaultImage = defaultImages[1];
          break;
        case DEFAULT_EVENT_IMAGE_NAME_3:
          defaultImage = defaultImages[2];
          break;
      }
      if (!defaultImage) {
        const userImage: IImage = {
          name: event.eventImageName,
          label: "Uploaded image",
          image: getStorageImage({
            image: event.eventImageName,
            directoryName: event.imageDirectory,
            type: STORAGE_IMAGE_TYPE_EVENT,
          }),
          removeIcon: true,
          checkButton: true,
        };
        setSelectedImage(userImage);
      }
    },
    onError: () => setIsEventNotFound(true),
  });

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    onCompleted: () => {
      setIsEditingEvent(false);
      displaySuccessNotification(t("requests.updateEvent"));
      history.push(EVENT_ROUTES.UPCOMING);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      const message = getCustomNotificationByCode(errorCode);
      if (message) {
        setIsEditingEvent(false);
        return toast.error(message);
      } else {
        displayServerError(error);
      }
    },
  });

  useEffect(() => {
    getCommunitiesManagedByUser({
      variables: {
        userCommunitySearchOptions: {
          size: 100,
          skip: 0,
        },
      },
    });
    getHostedEvents();
  }, []);

  // sometimes interest options are loaded later than event interests
  // for that case we are saving event interests in seperate state and when loaded we are setting them up
  useEffect(() => {
    if (interestOptions?.length) {
      setSelectedInterests(
        savedInterests.map(
          (item: IInterest) =>
            interestOptions?.find((interest) => interest?.value === item?.name) || {
              value: "",
              label: "",
            }
        )
      );
    }
  }, [interestOptions]);

  useEffect(() => {
    getEvent({ variables: { publicId: params?.id } });
  }, [params.id]);

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    setMaxDate(date);
    onChangeStartTime(startTime);
  }, []);

  const resolveRange = (type: string, date: Date, time: string) => {
    const dateTimeStart = convertDateAndTime(
      type === START_DATE_TYPE ? date : startDate,
      type === START_TIME_TYPE ? time : startTime.format("HH:mm")
    );
    const dateTimeEnd = convertDateAndTime(
      type === END_DATE_TYPE ? date : endDate,
      type === END_TIME_TYPE ? time : endTime.format("HH:mm")
    );
    const selectedDateAndTimeStart = dayjs(new Date(dateTimeStart));
    const selectedDateAndTimeEnd = dayjs(new Date(dateTimeEnd));

    const currentDate = dayjs(new Date());

    const selectedTimezoneOffset = getOffset();
    const localTimeZone = getDefaultTimeZoneOption()?.value;
    const localTimeZoneOffset = localTimeZone && getTimezoneOffset(localTimeZone);

    if (localTimeZoneOffset && selectedTimezoneOffset)
      if (
        selectedDateAndTimeStart.diff(currentDate, "minutes") -
          localTimeZoneOffset +
          selectedTimezoneOffset <=
        0
      ) {
        setError(true);
        setErrorMessage(t("event.newEvent.error.startTime"));
        return;
      }

    if (
      selectedDateAndTimeStart.diff(selectedDateAndTimeEnd, "minutes") > -MIN_MINUTES ||
      selectedDateAndTimeStart.diff(selectedDateAndTimeEnd, "minutes") < -MAX_MINUTES
    ) {
      setErrorDate(true);
      setErrorMessage(t("event.newEvent.error.range"));
      return;
    }
    setError(false);
    setErrorDate(false);
    return;
  };

  const checkCurrentTime = (date: Date, time: string) => {
    const dateTimeStart = convertDateAndTime(date, time);
    const selectedDateAndTimeStart = dayjs(new Date(dateTimeStart));
    const currentDate = dayjs(new Date());

    const selectedTimezoneOffset = getOffset();
    const localTimeZone = getDefaultTimeZoneOption()?.value;
    const localTimeZoneOffset = localTimeZone && getTimezoneOffset(localTimeZone);

    if (localTimeZoneOffset && selectedTimezoneOffset)
      if (
        selectedDateAndTimeStart.diff(currentDate, "minutes") -
          localTimeZoneOffset +
          selectedTimezoneOffset <=
        0
      ) {
        setError(true);
        setErrorMessage(t("event.newEvent.error.startTime"));
        return true;
      }
    setError(false);
    setErrorDate(false);
  };

  const onChange = (value: Date) => {
    setStartDate(value);
    setEndDate(value);
    setClickedStartDate(false);

    if (!startTime || !endTime) {
      setErrorMessage(t("event.newEvent.error.range"));
      return;
    }

    checkCurrentTime(value, startTime.format("HH:mm"));
  };

  const onChangeEndDate = (value: Date) => {
    setEndDate(value);
    setClickedEndDate(false);
    if (!endTime || !startTime) {
      setErrorMessage(t("event.newEvent.error.range"));
      return;
    }

    resolveRange(END_DATE_TYPE, value, endTime.format("HH:mm"));
  };

  const onChangeStartTime = (time: Moment) => {
    setStartTime(time);
    if (!endTime) {
      setErrorMessage(t("event.newEvent.error.range"));
      return;
    }
    if (checkCurrentTime(startDate, time.format("HH:mm"))) {
      return;
    }
    resolveRange(START_TIME_TYPE, startDate, time.format("HH:mm"));
  };

  const onChangeEndTime = (time: Moment) => {
    setEndTime(time);

    if (!startTime) {
      setErrorMessage(t("event.newEvent.error.range"));
      return;
    }
    if (checkCurrentTime(startDate, startTime.format("HH:mm"))) {
      return;
    }
    resolveRange(END_TIME_TYPE, endDate, time.format("HH:mm"));
  };

  const editEvent = async () => {
    let eventUrl;
    if (isExternalEvent) {
      eventUrl = externalUrl.trim();
      if (!eventUrl.startsWith("http")) {
        eventUrl = "https://" + eventUrl;
      }
      if (!validURL(eventUrl)) {
        setUrlInvalid(true);
        return;
      }
    }

    const attachments = files.map(({ name, size }) => ({
      name: fileNameReplaceRegex(name),
      size,
    }));

    if (!isEventPast && checkCurrentTime(startDate, startTime.format("HH:mm"))) return;

    setIsEditingEvent(true);

    let imageDirectory = null;

    if (selectedImage.img) {
      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (_, directory) => (imageDirectory = directory),
        });
      } catch (error) {
        displayNotSuccessNotification(error);
      }
    }

    const communitiesToLink = selectedCommunities.map((community) => ({
      sendbirdId: community.value,
      shouldNotifyMembers: community.shouldSendNotification,
    }));

    updateEvent({
      variables: {
        eventUpdate: {
          name: title,
          publicId: params.id,
          description: descriptionValue,
          eventType: selectedEventType,
          eventImageName: fileNameReplaceRegex(selectedImage.name),
          tags: selectedInterests.map((interest) => interest.value),
          communitiesToLink,
          startDate: formatDateAndTimeForVariable(startDate, startTime),
          endDate: formatDateAndTimeForVariable(endDate, endTime),
          timeZone,
          attachmentsDirectory: DIRECTORY_NAME,
          imageDirectory,
          ...(isExternalEvent ? { externalUrl: eventUrl } : {}),
          eventFormat: selectedSubMeetingType,
          attachments: previousAttachments
            ? [
                ...previousAttachments?.map((att) => ({
                  name: att.name,
                  size: att.size,
                })),
                ...attachments,
              ]
            : attachments,
        },
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleLinkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const externalUrl = event.target.value;
    const isValid = await externalUrlValidation.isValid({ url: externalUrl });
    setUrlInvalid(!isValid);
    setExternalUrl(externalUrl);
  };

  const convertDateAndTime = (date: Date, time: string) => {
    const formatingDate = dayjs(date).format("YYYY-MM-DD");
    const dateAndTime = `${formatingDate}T${time}:00`;
    return dateAndTime;
  };

  const formatDateAndTimeForVariable = (date: Date, time: moment.Moment) => {
    if (timeZone === UTC_OFFSET_INT_DATE_LINE_WEST.name) {
      return `${convertDateAndTime(date, time.format("HH:mm"))}-12:00`;
    }

    return dayjs.tz(convertDateAndTime(date, time.format("HH:mm")), timeZone).format();
  };

  const removeFile = (file: File) => {
    const filteredFiles = files.filter((currentFile) => currentFile !== file);
    setFiles(filteredFiles);
  };

  const removePrevFile = (file: { name: string; size: number }) => {
    const filteredFiles = previousAttachments?.filter(
      (currentFile) => currentFile !== file
    );
    setPreviousAttachments(filteredFiles);
  };

  const handleFiles = async (newFiles: Array<File>) => {
    const uploadedFiles: Array<File> = [];
    const fileNames = files.map((file) => fileNameReplaceRegex(file.name));
    //  eslint-disable-next-line
    const filteredFiles = newFiles.filter((file) => {
      if (!fileNames.includes(fileNameReplaceRegex(file.name))) return file;
    });

    try {
      uploadFiles(filteredFiles, BlobStorageContainer.ATTACHMENTS, {
        useSameDirectory: true,
        directory: DIRECTORY_NAME,
        onFileUpload: (file, directory) => {
          DIRECTORY_NAME = directory;
          uploadedFiles.push(file);
          setFiles((prevFiles) => [...prevFiles, file]);
        },
      });
    } catch (error) {
      if (error instanceof UploadError) {
        const cause = error.cause;
        if (cause instanceof GenerateSasTokenError) {
          displayNotSuccessNotification(cause.cause);
        }
        displayNotSuccessNotification();
      }
    }
  };

  const isCreateEventButtonDisabled = () => {
    if (isEventPast)
      return (
        !title ||
        isUploadInProgress ||
        isEditingEvent ||
        urlInvalid ||
        !selectedInterests.length
      );
    return (
      error ||
      errorDate ||
      !title ||
      isUploadInProgress ||
      isEditingEvent ||
      urlInvalid ||
      !selectedInterests.length ||
      !selectedImage ||
      Object.keys(selectedImage).length === 0
    );
  };

  const reactQuillRef = useRef<ReactQuill>(null);

  const validateInput = (description: string) => {
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor;
    const editor = reactQuillRef.current?.editor;
    editor?.on("text-change", () => {
      if (unprivilegedEditor !== undefined && unprivilegedEditor?.getLength() > 1000) {
        editor?.deleteText(1000, unprivilegedEditor.getLength());
      }
    });
    setDescriptionValue(description);
  };

  const handleAddCommunity = (communityToAdd: DefaultOption) => {
    setSelectedCommunities([
      ...selectedCommunities,
      {
        label: communityToAdd.label,
        value: communityToAdd.value,
        shouldSendNotification: true,
      },
    ]);
    setCommunitiesManagedByMe((prevState) =>
      prevState.filter((community) => communityToAdd.value !== community.value)
    );
    setDefaultCommunitiesSelectValue({
      label: t("event.newEvent.select.communities.placeholder"),
      value: "",
    });
  };

  const handleRemoveCommunity = (communityId: string, communityName: string) => {
    setSelectedCommunities(
      selectedCommunities.filter((community) => community.value !== communityId)
    );
    setCommunitiesManagedByMe([
      { value: communityId, label: communityName },
      ...communitiesManagedByMe,
    ]);
  };

  const handleToggleSendNotifications = (community: ISelectedCommunity) => {
    const copySelectedCommunities = [...selectedCommunities];
    const selectedCommunity = copySelectedCommunities.find(
      (com) => com.value === community.value
    );
    selectedCommunity!.shouldSendNotification =
      !selectedCommunity!.shouldSendNotification;

    setSelectedCommunities(copySelectedCommunities);
  };

  if (loading || !interestOptions?.length)
    return (
      <div className={styles.loading}>
        <Loading borderless />
      </div>
    );

  if (isEventNotFound) return <PageNotFound />;

  return (
    <div className={styles.wrapper}>
      <UploadModal
        type={UploadModalType.ATTACMENTS}
        addFiles={handleFiles}
        open={openFileModal}
        multiple
        supportedExtensions={supportedEventExtensions}
        close={() => setOpenFileModal(false)}
      />
      <div className={styles.pageWrapper}>
        <h2 className={styles.pageTitle}>{t("event.editEvent.title")}</h2>
        <span className={styles.pageSubTitle}>{t("event.editEvent.subTitle")}</span>
        <form className={styles.newEventForm} onSubmit={editEvent}>
          <div className={formStyles.column}>
            <InputUpdated
              onChange={handleChange}
              value={title}
              name="eventName"
              maxLength={100}
              label={t("event.newEvent.eventName.label")}
              placeholder={t("event.newEvent.eventName.placeholder")}
              required
              disabled={isEventPast}
              additionalElement={
                !descriptionField && (
                  <ButtonUpdated
                    title={t("event.newEvent.buttons.addDescription")}
                    buttonIcon={<PlusOutlined />}
                    buttonSize="small"
                    type="button"
                    onClick={() => setDescriptionField(true)}
                  />
                )
              }
            />
            {descriptionField && (
              <ReactQuill
                theme="snow"
                onChange={(description: string) => validateInput(description)}
                ref={reactQuillRef}
                className="description"
                placeholder={t("event.newEvent.textarea.label")}
                aria-label="description textarea"
                // #842 Remove current Description text
                value={descriptionValue}
                defaultValue={descriptionValue}
                readOnly={isEventPast}
              />
            )}
          </div>

          {previousAttachments &&
            previousAttachments.map((file: { name: string; size: number }) => (
              <div key={file.name} className={styles.fileContainer}>
                <span className={styles.fileName}>{file.name}</span>
                <div className={styles.sizeContainer}>
                  <span className={styles.size}>
                    {FileHelper.getSizeInKB(file.size)} KB
                  </span>
                  <img
                    src={remove}
                    className={styles.iconCancel}
                    onClick={() => removePrevFile(file)}
                    alt=""
                  />
                </div>
              </div>
            ))}
          {files &&
            files.map((file: File) => (
              <div key={file.name} className={styles.fileContainer}>
                <span className={styles.fileName}>{file.name}</span>
                <div className={styles.sizeContainer}>
                  <span className={styles.size}>
                    {FileHelper.getSizeInKB(file.size)} KB
                  </span>
                  <img
                    src={remove}
                    className={styles.iconCancel}
                    onClick={() => removeFile(file)}
                    alt=""
                  />
                </div>
              </div>
            ))}
          <Button
            title={t("event.newEvent.buttons.addAttachments")}
            wrapperClassNames={styles.attachFile}
            buttonIcon={<PaperClipOutlined className={styles.paperClip} />}
            onClick={() => setOpenFileModal(true)}
          />
          <div className={styles.txtContainer}>
            <span>{t("event.newEvent.start")}</span>
            <span className={styles.endContainer}>{t("event.newEvent.end")}</span>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.datePicker}>
              <DatePicker
                value={startDate}
                isOpen={clickedStartDate}
                className={styles.datePicker}
                open={() => setClickedStartDate(true)}
                close={() => setClickedStartDate(false)}
                onChange={(value: Date) => onChange(value)}
                maxDate={maxDate}
                locale={i18n.language}
                disabled={isEventPast}
              />
              <TimePicker
                onChange={(time: Moment | null) => onChangeStartTime(time as Moment)}
                value={startTime as Moment}
                className={error ? styles.timePickerError : styles.timePicker}
                placeholder={startTime.format("HH-MM")}
                format={getTimePickerDateFormat()}
                minuteStep={EVENT_MIN_INTERVAL}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                disabled={isEventPast}
                showNow={false}
              />
            </div>
            <img src={arrow} alt="" className={styles.blueArrow} />
            <div className={styles.datePicker}>
              <DatePicker
                value={endDate}
                isOpen={clickedEndDate}
                className={errorDate ? styles.errorPicker2 : styles.datePicker}
                open={() => setClickedEndDate(true)}
                close={() => setClickedEndDate(false)}
                onChange={(value: Date) => onChangeEndDate(value)}
                maxDate={maxDate}
                locale={i18n.language}
                disabled={isEventPast}
              />
              <TimePicker
                onChange={(time: Moment | null) => onChangeEndTime(time as Moment)}
                value={endTime}
                className={error ? styles.timePickerError : styles.timePicker}
                placeholder={endTime.format("HH-MM")}
                format={getTimePickerDateFormat()}
                minuteStep={EVENT_MIN_INTERVAL}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                disabled={isEventPast}
                showNow={false}
              />
            </div>
          </div>
          {!isEventPast && (error || errorDate) && (
            <div className={styles.errorContainer}>
              <img alt="" src={ErrorCircle} />
              <span className={styles.errorMessage}>{errorMessage}</span>
            </div>
          )}
          {loadingTimeZones ? (
            <Loader type="Rings" color={palette.primaryBlue} width={34} height={34} />
          ) : (
            <TimeZoneSelect
              selectedTimeZone={timeZone}
              timeZones={timeZones}
              onChange={({ value }) => setTimeZone(value)}
            />
          )}
          <div className={styles.selectWrapper}>
            <SelectComponent
              options={interestOptions}
              placeholder={t("event.newEvent.select.tags.placeholder")}
              className={"tagsSearch"}
              label={t("event.newEvent.select.tags.label")}
              name={"tags"}
              value={selectedInterests}
              showDropdown
              selectWithIcon
              onChange={(selectedInterestOptions: DefaultOption[]) => {
                setSelectedInterests(selectedInterestOptions);
              }}
              icon={SearchIcon}
              isMulti
              isSearchable
              isDisabled={isEventPast}
            />
          </div>
          <div className={styles.imagePickerForNewEvent}>
            <ImagePickerWithModal
              title={`${t("event.newEvent.radio.image.label")} *`}
              images={defaultImages}
              pickedImage={selectedImage}
              setPickedImage={setSelectedImage}
              disabled={isEventPast}
              isUpload
              uploadButtonId={"selectedImage"}
            />
          </div>
          {/* meeting type */}
          <div className={styles.radioTypeWrapper}>
            <div className={styles.radioTypeHeading}>
              {t("event.newEvent.externalUrl.title")}
            </div>
            {meetingTypes.map((meetingType, index) => (
              <>
                <RadioButton
                  disabled
                  id={index}
                  key={meetingType.name}
                  name={meetingType.name}
                  label={t(meetingType.label)}
                  subLabel={t(meetingType.subLabel)}
                  checked={meetingType.name === selectedMeetingType}
                />
                {meetingType.name === MeetingType.Zoom && (
                  <div className={styles.buttonWrapper}>
                    <TooltipComponent
                      title={t("event.newEvent.zoomMeetingTypes.meetingDescription")}
                      tooltipType="secondary"
                      placement="bottom"
                      childToWrap={
                        <p>
                          <Button
                            title={t("event.newEvent.zoomMeetingTypes.meeting")}
                            wrapperClassNames={styles.meetingWrapper}
                            disabled={selectedSubMeetingType !== SubMeetingType.Meeting}
                            buttonType={
                              selectedSubMeetingType === SubMeetingType.Meeting
                                ? "primary"
                                : "dashed"
                            }
                          />
                        </p>
                      }
                    />
                    <TooltipComponent
                      title={t("event.newEvent.zoomMeetingTypes.webinarDescription")}
                      tooltipType="secondary"
                      placement="bottom"
                      childToWrap={
                        <p>
                          <Button
                            title={t("event.newEvent.zoomMeetingTypes.webinar")}
                            wrapperClassNames={styles.meetingWrapper}
                            disabled={selectedSubMeetingType !== SubMeetingType.Webinar}
                            buttonType={
                              selectedSubMeetingType === SubMeetingType.Webinar
                                ? "primary"
                                : "dashed"
                            }
                          />
                        </p>
                      }
                    />
                  </div>
                )}
                {meetingType.name === MeetingType.External && (
                  <>
                    <div className={styles.inputContainer}>
                      <Input
                        value={externalUrl}
                        name="externalUrl"
                        maxLength={MAX_URL_LENGTH}
                        label={""}
                        placeholder={t(
                          "event.newEvent.externalUrl.externalMeetingPlaceholder"
                        )}
                        type="url"
                        onChange={handleLinkChange}
                        readOnly={
                          selectedMeetingType !== meetingTypes[1].name || isEventPast
                        }
                      />
                      {urlLengthMax && (
                        <div className={styles.errorContainer}>
                          <img alt="" src={ErrorCircle} />
                          <span className={styles.errorMessage}>
                            {t("event.newEvent.error.externalUrlMax")}
                          </span>
                        </div>
                      )}
                      {urlInvalid && (
                        <div className={styles.errorContainer}>
                          <img alt="" src={ErrorCircle} />
                          <span className={styles.errorMessage}>
                            {t("event.newEvent.error.externalUrlNotValid")}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={styles.externalUrlNote}>
                      <span>{t("event.newEvent.externalUrl.note")}</span>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          {managedByLoader ? (
            <Loading borderless disableBoxShadow />
          ) : (
            <div className={styles.selectWrapper}>
              <SelectComponent
                options={communitiesManagedByMe}
                placeholder={t("event.newEvent.select.communities.placeholder")}
                value={defaultCommunitiesSelectValue}
                className={"tagsSearch"}
                label={t("event.newEvent.select.communities.label")}
                name={"communities"}
                showDropdown
                selectWithIcon
                onChange={(selectedCommunitiesOption: DefaultOption) =>
                  handleAddCommunity(selectedCommunitiesOption)
                }
                icon={SearchIcon}
                isSearchable
                isDisabled={false}
              />
            </div>
          )}
          {selectedCommunities.length > 0 && (
            <>
              <div className={styles.communitiesHeader}>
                <span>Communities</span>
                <span>Send Notifications</span>
              </div>
              <div>
                {selectedCommunities.map((community) => (
                  <div className={styles.selectedCommunityRow} key={community.value}>
                    <span>{community.label}</span>
                    <div className={styles.communityActions}>
                      <TooltipComponent
                        title={t("event.newEvent.tooltip.notifications")}
                        tooltipType="secondary"
                        placement="bottom"
                        childToWrap={
                          <div className={styles.switchWrapper}>
                            <Switch
                              onChange={() => handleToggleSendNotifications(community)}
                              checked={community.shouldSendNotification!}
                              onColor={palette.primaryBlue}
                              aria-label="chat-option"
                            />
                          </div>
                        }
                      />
                      <TooltipComponent
                        title={t("event.newEvent.tooltip.removeCommunity")}
                        tooltipType="secondary"
                        placement="bottom"
                        childToWrap={
                          <img
                            className={styles.removeCommunity}
                            src={CloseMark}
                            onClick={() => {
                              handleRemoveCommunity(community.value, community.label);
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {/* event type */}
          <div className={styles.radioTypeWrapper}>
            <div className={styles.radioTypeHeading}>
              {t("event.newEvent.eventTypes.title")}
            </div>
            {isEventPast &&
              eventTypes.map((eventType, index) => (
                <RadioButton
                  id={index}
                  key={eventType.name}
                  name={eventType.name}
                  label={t(eventType.label)}
                  subLabel={t(eventType.subLabel)}
                  checked={eventType.name === selectedEventType}
                  disabled={isEventPast}
                />
              ))}
            {!isEventPast &&
              eventTypes.map((eventType, index) => (
                <RadioButton
                  id={index}
                  key={eventType.name}
                  name={eventType.name}
                  label={t(eventType.label)}
                  subLabel={t(eventType.subLabel)}
                  checked={eventType.name === selectedEventType}
                  onChange={(event) => {
                    setSelectedEventType(event.target.name);
                  }}
                  disabled={selectedEventType === "public" || eventType.name === "public"}
                />
              ))}
          </div>
          <div className={styles.buttons}>
            <Button
              title={t("event.editEvent.buttons.editEvent")}
              onClick={() => setIsConfirmModalOpened(true)}
              disabled={isCreateEventButtonDisabled()}
              loading={isEditingEvent}
              buttonType="primary"
              fullWidth
            />
            <Button
              title={t("community.newCommunity.buttons.cancel")}
              buttonType="link"
              fullWidth
              disabled={isEditingEvent}
              onClick={() => history.push(EVENT_ROUTES.HOSTING)}
            />
            <ConfirmationModal
              isOpen={isConfirmModalOpened}
              accept={() => {
                setIsConfirmModalOpened(false);
                editEvent();
              }}
              messageTitle={t("event.editEvent.confirmModal.title")}
              messageContent={""}
              closeModal={() => setIsConfirmModalOpened(false)}
              customButtonsText={{
                accept: t("event.editEvent.confirmModal.button.accept"),
                cancel: t("event.editEvent.confirmModal.button.cancel"),
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEvent;
