import { gql } from "@apollo/client";

const CREATE_COMMUNITY = gql`
  mutation createCommunity($createCommunity: CreateCommunityInputType!) {
    createCommunity(createCommunity: $createCommunity) {
      name
      coverImageName
      communityImageUrl
      imageDirectory
      communityBannerUrl
      communityBannerName
      communityBannerDirectory
      sendbirdId
      communityType
      description
      goals
      rules
      members
      admins
      joinRequests
      channels
      interests
      communityCreator
    }
  }
`;

export default CREATE_COMMUNITY;
