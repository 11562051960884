/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@components/Button/Button";
import SearchInput from "@components/SearchInput/SearchInput";
import Tabs from "@components/Tabs/Tabs";

import { ClaimsService } from "@services/ClaimsService";

import JoinedByMe from "./JoinedByMe/JoinedByMe";
import ManagedByMe from "./ManagedByMe/ManagedByMe";
import Recommended from "./Recommended/Recommended";

import { setCommunitySearchTerm } from "../../store/community/actions";

import MagnifyGlassIcon from "@images/search_grey.svg";

import styles from "./Community.module.scss";

dayjs.extend(relativeTime);

interface ICommunityProps {
  interests: string[];
}

const Community = ({ interests }: ICommunityProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasCreateCommunityClaim: boolean = ClaimsService.hasCreateCommunityClaim();

  const [searchCommunity, setSearchCommunity] = useState<string>("");

  const [showRecommended, setShowRecommended] = useState<boolean>(false);

  const COMMUNITY_TABS = [
    {
      name: t("community.communitityContainerTitle"),
      selectedByRoutes: [""],
    },
    {
      name: t("collections.explore"),
      selectedByRoutes: ["/explore"],
    },
  ];

  const handleSearchCommunity = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setCommunitySearchTerm(searchCommunity.trim()));
    history.push("/portal/community/explore");
  };

  const handleShowRecommended = (showRecommended: boolean) => {
    setShowRecommended(showRecommended);
  };

  return (
    <div className={styles.communityContainer}>
      <div className={styles.communityHeader} e2e-test-id="community-top-bar">
        <Tabs tabs={COMMUNITY_TABS} size="large" routePrefix="/portal/community" />
        <div className={styles.communityActions}>
          <form onSubmit={handleSearchCommunity}>
            <SearchInput
              name="searchCollection"
              placeholder={t("community.exploreButton")}
              e2eTestId="communities-search-input"
              value={searchCommunity}
              onChange={(event) => setSearchCommunity(event.target.value)}
              searchIcon={
                <img
                  src={MagnifyGlassIcon}
                  onClick={handleSearchCommunity}
                  alt=""
                  aria-label="Magnify Glass"
                />
              }
              className={styles.searchInput}
            />
          </form>
          {hasCreateCommunityClaim && (
            <Button
              title={t("community.buttonText")}
              buttonIcon={<PlusOutlined />}
              wrapperClassNames={styles.createCommunityButtonWrapper}
              buttonSize="large"
              buttonType="primary"
              e2eTestId="create-community-button"
              onClick={() => history.push("/portal/community/new")}
            />
          )}
        </div>
      </div>

      <Row
        className={styles.communityContent}
        e2e-test-id="my-communities-container"
        gutter={[16, 0]}
      >
        <Col xs={24} sm={24} lg={8} xl={8} xxl={8}>
          {/* Managed by me communities */}
          <ManagedByMe />
        </Col>
        <Col xs={24} sm={24} lg={16} xl={16} xxl={16}>
          <>
            {showRecommended ? (
              <Recommended interests={interests} />
            ) : (
              <JoinedByMe showRecommended={handleShowRecommended} />
            )}
          </>
        </Col>
      </Row>
    </div>
  );
};

export default Community;
