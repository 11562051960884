/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import Loading from "@components/Loading/Loading";
import TextTruncated from "@components/TextTruncated/TextTruncated";

import EventImageCard from "@components/EventImageCard/EventImageCard";
import Header from "@components/LandingPage/Layout/Header";
import OpenPublicEventTimeCard from "@components/OpenPublicEventTimeCard/OpenPublicEventTimeCard";

import { STORAGE_IMAGE_TYPE_EVENT, STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { GET_EVENT } from "@graphql/open-events/queries";
import { IOpenEvent } from "@graphql/open-events/type";
import Avatar from "@images/avatar.svg";
import getStorageImage from "@utils/getStorageImage";

import {
  displayErrorNotification,
  displayServerError,
} from "@services/NotificationService/NotifacitonService";

import styles from "./OpenPublicEventDetailsPage.module.scss";

const MAX_NAME_LENGTH = 24;

const OpenPublicEventDetailsPage = () => {
  const SignupRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const [userImage, setUserImage] = useState<string>();

  const [event, setEvent] = useState<IOpenEvent>();

  const [getEvent, { loading }] = useLazyQuery(GET_EVENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.event === null) {
        displayErrorNotification(
          t("event.deleteEvent.deletedEventByOwner", {
            name: event?.name,
          })
        );
        history.push("/portal/events");
      }
      setEvent(data.event);
      setUserImage(
        getStorageImage({
          image: data.event.eventHost.userPhotoName,
          directoryName: data.event.eventHost.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
      setIsLoading(false);
    },
    onError: (error) => {
      displayServerError(error);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    getEvent({ variables: { publicId: params.id } });
  }, [params.id]);

  return loading ? (
    <div className={styles.loadingContainer}>
      <Loading disableBoxShadow />
    </div>
  ) : (
    <div className="main-app">
      <Header
        signupRef={SignupRef}
        showEventsButton={true}
        showLoginButton={true}
        showRegisterButton={false}
      />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.row}>
            <EventImageCard
              showCopyButton
              image={getStorageImage({
                image: event?.eventImageName,
                directoryName: event?.imageDirectory,
                type: STORAGE_IMAGE_TYPE_EVENT,
              })}
            />
            {event && (
              <OpenPublicEventTimeCard
                eventPublicId={event.publicId}
                hostingId={event.hostingId}
                name={event.name}
                startDate={event.startDate.toString()}
                endDate={event.endDate.toString()}
                isLoading={isLoading}
                startTime={event.startDate}
                endTime={event.endDate}
                host={event.eventHost.fullName}
                hostEmail={event.eventHost.email}
                event={event}
              />
            )}
          </div>
        </div>
        <div className={styles.content}>
          {event?.description ? (
            <div className={styles.descriptionContent}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </div>
          ) : (
            <div className={styles.descriptionContent}>
              <div className={styles.description}>
                {t("openPublicEventsDetailsPage.noDescription")}
              </div>
            </div>
          )}
          <div className={styles.eventCardsContainer}>
            {event && (
              <div className={styles.hostContainer}>
                <div className={styles.row}>
                  <div
                    style={{ backgroundImage: `url(${userImage ? userImage : Avatar})` }}
                    className={styles.image}
                  ></div>
                  <div className={styles.column}>
                    <span className={styles.hostTitle}>{t("eventDetail.hostedBy")}</span>
                    <span className={styles.hostName}>
                      <TextTruncated
                        textToTruncate={event.eventHost.fullName}
                        length={MAX_NAME_LENGTH}
                        placement="topRight"
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPublicEventDetailsPage;
