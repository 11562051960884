import { gql } from "@apollo/client";

export const GET_COMMUNITY_FOR_CHANGE_CREATOR_MODAL = gql`
  query Community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      name
      members {
        publicId
        fullName
        email
      }
      admins {
        publicId
        fullName
        email
      }
      communityCreator {
        publicId
        email
        fullName
      }
    }
  }
`;
