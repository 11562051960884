import { useLazyQuery } from "@apollo/client";
import { MouseEvent, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { IUser } from "types/user";

import GET_USER_INFO from "@graphql/queries/getUserInfo";

import UserDrawer from "@modules/UserDrawer/UserDrawer";

import Button from "@components/Button/Button";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

import { setIsNotificationsPopUpVisible } from "../../../../../store/notifications/actions";

import styles from "../NotificationCard.module.scss";

interface ILinks {
  text: string;
  path: string;
}

interface transComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

interface IGeneralNotificationsPart {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notificationData: any;
  links: ILinks[];
  text: string;
  config?: {
    userFullName?: {
      position: "left" | "right";
    };
  };
}

export const GeneralNotificationPart = ({
  notificationData,
  links,
  text,
  config = { userFullName: { position: "left" } },
}: IGeneralNotificationsPart) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState<IUser>();

  // getUser
  const [getUser] = useLazyQuery(GET_USER_INFO, {
    onCompleted: (response) => {
      setSelectedUser(response.userInfo);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  useEffect(() => {
    if (selectedUserEmail) {
      getUser({ variables: { email: selectedUserEmail } });
    } else {
      setSelectedUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserEmail]);

  const handleUserSelected = (event: MouseEvent) => {
    event.stopPropagation();
    setSelectedUserEmail(notificationData?.UserEmail ?? "");
    dispatch(setIsNotificationsPopUpVisible(false));
  };

  const renderTransComponent = (links: ILinks[]) => {
    const transComponentsHelper: transComponent | null = {};

    if (typeof links !== "undefined" && links.length > 0) {
      links.forEach(function (linkItem, index) {
        if (index === 0) {
          transComponentsHelper["link"] = (
            <Button
              wrapperClassNames={styles.btn}
              className={styles.linkText}
              title={linkItem.text}
              onClick={() => {
                dispatch(setIsNotificationsPopUpVisible(false));
                history.push(`${linkItem.path}`);
              }}
              aria-label="notificationButton"
            />
          );
        } else {
          transComponentsHelper["link" + index] = (
            <Button
              wrapperClassNames={styles.btn}
              className={styles.linkText}
              title={linkItem.text}
              onClick={() => {
                dispatch(setIsNotificationsPopUpVisible(false));
                history.push(`${linkItem.path}`);
              }}
              aria-label="notificationButton"
            />
          );
        }
      });

      return transComponentsHelper;
    }
  };

  const userFullName = notificationData.UserFullName && (
    <span className={styles.name} onClick={handleUserSelected}>
      {` ${notificationData.UserFullName} `}
    </span>
  );

  return (
    <bdi className={styles.generalNotificationPart}>
      {config?.userFullName?.position === "left" && userFullName}
      {notificationData.NotOnboardedUserFullName && (
        <span>{`${notificationData.NotOnboardedUserFullName} `}</span>
      )}
      <Trans i18nKey={text} components={renderTransComponent(links)} />
      {config?.userFullName?.position === "right" && userFullName}
      {selectedUser && (
        <UserDrawer
          user={selectedUser}
          closeInfoDrawer={() => setSelectedUserEmail("")}
          communityOptions={<></>}
        />
      )}
    </bdi>
  );
};
