/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";

import UPDATE_USER from "@graphql/mutations/updateUser";
import GET_USER from "@graphql/queries/users";

import Button from "@components/Button/Button";
import DetailField from "@components/DetailField/DetailField";
import UserPhotoModal from "@components/UserPhotoModal/UserPhotoModal";

import {
  displayCustomNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { useGetTranslatedOptions } from "@customHooks/useGetTranslatedOptions/UseGetTranslatedOptions";

import { useGetCountries } from "@feature/country";
import { ISectorUpdate } from "@feature/sector";

import { removeTypeName } from "@utils/graphQL/helper";

import { IOrganizationUpdate, IUpdateUserRequest, IUser } from "types/user";

import { useUpdateUser } from "./useUpdateUser/useUpdateUser";

import { SectorField } from "./SectorField/SectorField";

import {
  CHAT_PRIVACY_OPTIONS,
  ChatPrivacyToTranslation,
  getCanReceiveMessage,
  getChatPrivacy,
} from "./constants";
import { PersonalDetails } from "./enum";

import Avatar from "@images/avatar.svg";
import Lock from "@images/lock.svg";

import { SelectUpdated } from "@components/formik/SelectUpdated/SelectUpdated";
import { BioField } from "./BioField/BioField";
import styles from "./SettingsProfileDetails.module.scss";

interface ISettingsProfileDetailsProps {
  user: IUser;
  refetchData: () => void;
  children: React.ReactElement;
}

const SettingsProfileDetails = ({ user, children }: ISettingsProfileDetailsProps) => {
  const { t } = useTranslation();
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const [isUserPhotoModalOpen, setIsUserPhotoModalOpen] = useState<boolean>(false);
  const userPhoto = useSelector<RootState, string>((state) => state.user.userImage);
  const chatPrivacyOptions = useGetTranslatedOptions({ options: CHAT_PRIVACY_OPTIONS });
  const { countriesData, countryOptions } = useGetCountries();
  const { uploadedImage } = useUpdateUser();

  const patchUser = (
    userPatched: Partial<IUpdateUserRequest>,
    onSuccess?: (value: unknown) => void
  ) => {
    updateUser({
      variables: {
        user: userPatched,
      },
      refetchQueries: [GET_USER],
      onCompleted: () => {
        displaySuccessNotification();
        onSuccess?.("");
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;

        if (errorCode === "1502") {
          displayCustomNotSuccessNotification(errorCode);
        } else {
          displayServerError();
        }
      },
    });
  };

  const updateSector = (sector: ISectorUpdate, onSuccess?: () => void) => {
    patchUser(
      { organization: { ...removeTypeName(user.organization), sector } },
      onSuccess
    );
  };

  const getOrganizationUpdated = () => {
    return {
      ...removeTypeName(user.organization),
      sector: {
        name: user.organization.sector.name,
        customName: user.organization.sector.customSectorName,
      },
    };
  };

  const updateOrganization = <
    T extends IOrganizationUpdate,
    U extends keyof IOrganizationUpdate
  >(
    key: U,
    value: T[U],
    onSuccess?: () => void
  ) => {
    const organization = {
      name: key === "name" ? (value as string) : user.organization.name,
      sector: {
        name: user.organization.sector.name,
        customName: user.organization.sector.customSectorName,
      } as ISectorUpdate,
      userRole: key === "userRole" ? (value as string) : user.organization.userRole,
    };

    patchUser({ organization }, onSuccess);
  };

  return (
    <>
      <UserPhotoModal
        isOpen={isUserPhotoModalOpen}
        close={() => setIsUserPhotoModalOpen(false)}
        setUploadedImage={uploadedImage}
        currentUserPhoto={userPhoto || Avatar}
      />
      <div className={styles.container} e2e-test-id="profile-details-container">
        <div className={styles.column}>
          <h4 className={styles.tabTitle} e2e-test-id="profile-details-title">
            {t("accountSettings.profileDetails.heading")}
          </h4>
          <div>
            <div
              className={styles.profileImageContainer}
              e2e-test-id="profile-image-section"
            >
              <div>
                <span className={styles.profileImageLabel}>
                  {t("accountSettings.profileDetails.label.profileImage")}
                </span>
                <Button
                  title={t("accountSettings.profileDetails.button.profileImage")}
                  onClick={() => setIsUserPhotoModalOpen(true)}
                />
              </div>
              <div
                style={{ backgroundImage: `url(${userPhoto})` }}
                className={styles.image}
              ></div>
            </div>
          </div>
          <DetailField
            label={t("accountSettings.profileDetails.label.fullName")}
            e2eTestId="full-name-section"
            value={user.fullName}
          />
          <DetailField
            name={PersonalDetails.WORK_LOCATION}
            label={t("accountSettings.profileDetails.label.workLocation")}
            inputType="select"
            placeholder={user.country?.name}
            options={countryOptions}
            e2eTestId="work-location-section"
            value={user.country?.name}
            buttonName={t("accountSettings.profileDetails.button.edit")}
            isSearchable
            onSave={(__, value) => {
              const workLocation = countriesData?.countries.find(
                (country) => country.name === value
              );

              if (workLocation) {
                patchUser({
                  workLocation: removeTypeName(workLocation),
                  organization: getOrganizationUpdated(),
                });
              }
            }}
          />

          <BioField
            bio={user.bio}
            onSubmit={(bio, onSuccess) =>
              patchUser({ bio, organization: getOrganizationUpdated() }, onSuccess)
            }
            isLoading={loading}
          />

          <DetailField
            name={PersonalDetails.ORGANIZATION_NAME}
            label={t("accountSettings.profileDetails.label.organisation")}
            e2eTestId="organization-section"
            value={user.organization.name}
            buttonName={t("accountSettings.profileDetails.button.edit")}
            onSave={(__, value) => updateOrganization("name", value)}
            maxLength={60}
          />
          <DetailField
            name={PersonalDetails.ORGANIZATION_ROLE}
            label={t("accountSettings.profileDetails.label.roleWithinOrganisation")}
            e2eTestId="role-within-organization-section"
            value={user.organization.userRole}
            buttonName={t("accountSettings.profileDetails.button.edit")}
            onSave={(__, value) => updateOrganization("userRole", value)}
            maxLength={60}
          />

          <SectorField
            sector={user.organization.sector}
            onSubmit={updateSector}
            isLoading={loading}
          />

          <DetailField
            inputType="select"
            label={t("accountSettings.profileDetails.label.privacySettings")}
            e2eTestId="privacy-settings-section"
            value={t(ChatPrivacyToTranslation[getChatPrivacy(user.canReceiveMessages)])}
            buttonName={t("accountSettings.profileDetails.button.select")}
            editButtonElement={
              <SelectUpdated
                name="chat"
                placeholder="Public"
                value={getChatPrivacy(user.canReceiveMessages)}
                options={chatPrivacyOptions}
                className="chatSelect"
                icon={Lock}
                iconSide="left"
                onChange={(selectChatPrivacy) => {
                  patchUser({
                    canReceiveMessages: getCanReceiveMessage(selectChatPrivacy),
                    organization: getOrganizationUpdated(),
                  });
                }}
              />
            }
          />
        </div>
        <div className={styles.column}>{children}</div>
      </div>
    </>
  );
};

export default SettingsProfileDetails;
