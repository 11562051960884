import { useMutation } from "@apollo/client";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import GENERATE_SAS_TOKEN from "../../graphql/mutations/generateSasToken";
import { downloadFileFromBlob } from "../storageBlob";

export const useDownloadFile = () => {
  const [generateSasToken] = useMutation(GENERATE_SAS_TOKEN);

  const downloadFile = async (
    fileName: string,
    directory: string,
    downloadFile = true,
    container = process.env.REACT_APP_BLOB_FILE_CONTAINER_NAME
  ) => {
    try {
      const reponse = await generateSasToken({
        variables: {
          blobStorage: {
            fileNames: fileName,
            containerName: container,
            directoryName: directory,
          },
        },
      });
      const url = reponse.data.generateSasToken.values[0];
      if (downloadFile) {
        return await downloadFileFromBlob(fileName, url);
      } else {
        return await downloadFileFromBlob(fileName, url, false);
      }
    } catch (error) {
      displayNotSuccessNotification(error);
    }
  };

  return downloadFile;
};
