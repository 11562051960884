import { PronounsToTranslationKey } from "./constant";

import { Pronouns } from "./enum";

export const options = [
  { value: Pronouns.NONE, label: PronounsToTranslationKey[Pronouns.NONE] },
  { value: Pronouns.HE, label: PronounsToTranslationKey[Pronouns.HE] },
  { value: Pronouns.SHE, label: PronounsToTranslationKey[Pronouns.SHE] },
  { value: Pronouns.THEY, label: PronounsToTranslationKey[Pronouns.THEY] },
];
