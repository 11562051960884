import { IClassName } from "./type";

export const getClassName = ({
  hasBorder,
  isRounded,
  loading,
  searchIcon,
  className,
  styles,
}: IClassName) => {
  let newClassName = styles.input;
  if (hasBorder) {
    newClassName = `${newClassName} ${styles.border}`;
  }

  if (isRounded) {
    newClassName = `${newClassName} ${styles.rounded}`;
  }

  if (searchIcon) {
    newClassName = `${newClassName} ${styles.icon}`;
  }

  if (loading !== undefined) {
    newClassName = `${newClassName} ${styles.loader}`;
  }

  if (loading) {
    newClassName = `${newClassName} ${styles.loading}`;
  }

  if (className) {
    newClassName = `${newClassName} ${className}`;
  }

  return newClassName;
};
