import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import Pills from "@components/Pills/Pills";
import SearchInput from "@components/SearchInput/SearchInput";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import SearchIcon from "@images/search_icon.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DefaultOption } from "types/select";
import { addInterests } from "../../store/onboarding/actions";
import { Interest } from "../../store/onboarding/types";
import { RootState } from "../../store/store";
import styles from "./Interests.module.scss";

const Interests = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { options: allOptions } = useInterestHelper();
  const selectedOptions = useSelector<RootState, Array<Interest>>(
    (state) => state.onboarding.interests
  );
  const [chosenOptions, setChosenOptions] =
    useState<Array<DefaultOption>>(selectedOptions);
  const [options, setOptions] = useState<Array<DefaultOption>>([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (!allOptions.length) return;

    setOptions(allOptions);
  }, [allOptions]);

  const handleOptionSelection = (option: DefaultOption) => {
    const isOptionFound = allOptions.find(({ value }) => value === option.value);
    if (!isOptionFound) return;

    const isPreviouslySelected = chosenOptions.some(
      ({ value }) => value === option.value
    );

    if (isPreviouslySelected) {
      return setChosenOptions(
        chosenOptions.filter(({ value }) => value !== option.value)
      );
    }

    setChosenOptions((options) => [...options, option]);
  };

  const handleSearchChange = ({
    target: { value: searchQuery },
  }: ChangeEvent<HTMLInputElement>) => {
    setOptions(
      allOptions.filter(({ value }) =>
        value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setSearchText(searchQuery);
  };

  const handleSubmit = () => {
    dispatch(addInterests(chosenOptions));
    history.push("/onboarding/locations");
  };

  const isSubmitBtnDisabled = () => !chosenOptions.length;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.headingContainer}>
          <span className={styles.heading}>{t("categories.heading")}</span>
          <span className={styles.subheading}>{t("categories.subheading")}</span>
        </div>
        <div className={styles.content}>
          <div className={styles.searchContainer}>
            <div className={styles.searchLabel}>{t("categories.searchLabel")}</div>
            <SearchInput
              value={searchText}
              onChange={handleSearchChange}
              placeholder=""
              name="name"
              className={styles.searchInterests}
              searchIcon={<img src={SearchIcon} alt="" />}
            />
          </div>

          <div className={styles.interestsContainer}>
            <Pills
              pills={options}
              onPillClick={handleOptionSelection}
              selectedPills={chosenOptions}
              ariaLabel="add-interest"
            />
          </div>

          <div className={styles.buttonContainer}>
            <BackButton
              path="/onboarding/profile-details"
              label={t("profileDetails.heading")}
            />
            <div className={styles.nextButton}>
              <Button
                aria-label="next-step"
                onClick={handleSubmit}
                title={t("button.next")}
                disabled={isSubmitBtnDisabled()}
                buttonType="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interests;
