import { gql } from "@apollo/client";

const GET_EVENT = gql`
  query Event($publicId: ID!) {
    event(publicId: $publicId) {
      name
      description
      startDate
      endDate
      timeZone
      hostingId
      interests {
        name
      }
      linkedCommunities {
        name
        sendbirdId
        shouldNotifyMembers
      }
      publicId
      eventType
      eventImageName
      imageDirectory
      externalUrl
      eventRecordingsPassword
      eventRecordingFileLinks
      eventHost {
        fullName
        email
      }
      attachments {
        name
        size
      }
      attachmentsDirectory
      eventFormat
    }
  }
`;

export default GET_EVENT;
