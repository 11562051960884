import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import styles from "./Drawer.module.scss";

interface IDrawerProps {
  children?: ReactElement;
  closeInfoDrawer: () => void;
}

const Drawer = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IDrawerProps>>(
  ({ children, closeInfoDrawer }: IDrawerProps, ref) => {
    return ReactDOM.createPortal(
      <>
        <div className={styles.backdrop} onClick={closeInfoDrawer}></div>
        <div ref={ref} className={styles.drawer}>
          {children}
        </div>
      </>,
      document.body
    );
  }
);

Drawer.displayName = "Drawer";

export default Drawer;
