/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import * as Yup from "yup";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";

import { ILinkListData } from "types/links";

import { ArrowRightOutlined } from "@ant-design/icons";
import ErrorCircle from "@images/circleError.svg";
import Plus from "@images/plus.svg";
import TrashIcon from "@images/trashCanBlue.svg";
import { urlRegex } from "@utils/helpers";
import styles from "./LinksManagement.module.scss";

const MAX_INPUT_LENGTH = 1000;
const MAX_LINK_TITLE_LENGTH = 50;

interface IlinksList {
  linksData?: ILinkListData[] | null;
  updateLinks: (links: ILinkListData[]) => void;
}

const LinksManagement = ({ linksData, updateLinks }: IlinksList) => {
  const { t } = useTranslation();
  const [links, setLinks] = useState<ILinkListData[]>([]);

  // set data on load
  useEffect(() => {
    linksData && setLinks(linksData);
  }, []);

  // change parent
  useEffect(() => {
    links && updateLinks(links);
  }, [links]);

  const addLink = () => {
    setLinks((links) => [
      ...links,
      {
        title: "",
        url: "",
        isValid: false,
      },
    ]);
  };

  const deleteLink = (linkIndex: number) => {
    setLinks((oldValues) => {
      return oldValues.filter((_, i) => i !== linkIndex);
    });
  };

  const moveLinkUp = (linkIndex: number) => {
    const numberOfElementsToMove = 1;
    const linksCopy = links;
    const currentLinkObject = linksCopy[linkIndex];

    linksCopy.splice(linkIndex, numberOfElementsToMove);
    linksCopy.splice(linkIndex - 1, 0, currentLinkObject);
    setLinks([...linksCopy]);
  };

  const moveLinkDown = (linkIndex: number) => {
    const numberOfElementsToMove = 1;
    const linksCopy = links;
    const currentLinkObject = linksCopy[linkIndex];

    linksCopy.splice(linkIndex, numberOfElementsToMove);
    linksCopy.splice(linkIndex + 1, 0, currentLinkObject);
    setLinks([...linksCopy]);
  };

  const setLinkTitle = (value: string, linkIndex: number) => {
    setLinks((link) =>
      link.map((currentLinkObject, index) => {
        if (index === linkIndex) {
          return { ...currentLinkObject, title: value };
        }

        return currentLinkObject;
      })
    );
  };

  const setLinkUrl = async (value: string, linkIndex: number) => {
    const isValidUrl = value.match(urlRegex) ? true : false;

    setLinks((link) =>
      link.map((currentLinkObject, index) => {
        if (index === linkIndex) {
          return { ...currentLinkObject, url: value, isValid: isValidUrl };
        }

        return currentLinkObject;
      })
    );
  };

  return (
    <div className={styles.linksWrapper} e2e-test-id="links-section-container">
      <div className={styles.linksHead}>
        <div className={styles.title} e2e-test-id="links-section-title">
          {t("linksList.title")}
        </div>
        <Button
          aria-label="addLink"
          e2eTestId="add-link-button"
          title={""}
          wrapperClassNames={styles.actionButton}
          buttonIcon={<img src={Plus} alt="" />}
          buttonType="link"
          onClick={() => {
            addLink();
          }}
          disabled={links.length > 9}
        />
      </div>
      {links.length > 0 && (
        <div className={styles.linksBody}>
          {links.map((link, index) => (
            <div className={styles.linksRow} key={index}>
              {/* Title and URL inputs */}
              <div className={styles.linksInputsWrapper}>
                <div className={styles.inputOuterWrapper}>
                  <Input
                    value={link.title || ""}
                    onChange={(e) => setLinkTitle(e.target.value, index)}
                    name="rules"
                    maxLength={MAX_LINK_TITLE_LENGTH}
                    label={""}
                    aria-label="rules"
                    e2eTestId="link-title-input-field"
                    placeholder={t("linksList.titleText")}
                  />
                </div>
                <div className={styles.inputOuterWrapper}>
                  <Input
                    value={link.url || ""}
                    onChange={(e) => setLinkUrl(e.target.value, index)}
                    name="rules"
                    maxLength={MAX_INPUT_LENGTH}
                    label={""}
                    aria-label="rules"
                    e2eTestId="link-url-input-field"
                    placeholder={t("linksList.urlText")}
                  />
                  {!link.isValid && (
                    <div className={styles.errorContainer}>
                      <img alt="" src={ErrorCircle} />
                      <span
                        className={styles.errorMessage}
                        e2e-test-id="validation-error-message"
                      >
                        {t("event.newEvent.error.externalUrlNotValid")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/* Move btns and Delete btn */}
              <div className={styles.actions}>
                <Button
                  aria-label="moveLinkUp"
                  e2eTestId="move-link-up-button"
                  title={""}
                  wrapperClassNames={`${styles.actionButton} ${styles.buttonUp}`}
                  buttonIcon={<ArrowRightOutlined />}
                  buttonType="link"
                  onClick={() => {
                    moveLinkUp(index);
                  }}
                />
                <Button
                  aria-label="moveLinkDown"
                  e2eTestId="move-link-down-button"
                  title={""}
                  wrapperClassNames={`${styles.actionButton} ${styles.buttonDown}`}
                  buttonIcon={<ArrowRightOutlined />}
                  buttonType="link"
                  onClick={() => {
                    moveLinkDown(index);
                  }}
                />
                <Button
                  aria-label="deleteLink"
                  e2eTestId="delete-link-button"
                  title={""}
                  wrapperClassNames={styles.actionButton}
                  buttonIcon={<img src={TrashIcon} alt="" />}
                  buttonType="link"
                  onClick={() => {
                    deleteLink(index);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LinksManagement;
