import { TextareaHTMLAttributes } from "react";

import { MAX_INPUT_LENGTH } from "@constants/constants";
import { usePredefinedClassIfFound } from "@utils/classNamesHelpers";

import { getTextAreaStyle } from "./helper";

import { IClassName, ITextAreaStyle } from "./type";

import styles from "./Textarea.module.scss";
import { isDocumentRtl } from "@utils/checkDocumentDir";

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, IClassName {
  label: string;
  boldLabel?: boolean;
  textAreaStyle?: ITextAreaStyle;
}

const TextareaComponent = ({
  wrapperClassNames,
  labelClassNames,
  textareaClassNames,
  textAreaStyle,
  value,
  maxLength,
  boldLabel,
  ...props
}: ITextareaProps) => {
  return (
    <div
      aria-label="wrapper"
      className={`${styles.textareaWrapper} ${usePredefinedClassIfFound(
        wrapperClassNames,
        styles
      )}`}
    >
      <label
        aria-label="label"
        className={`${
          boldLabel ? styles.boldLabel : styles.label
        } ${usePredefinedClassIfFound(labelClassNames, styles)}`}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <textarea
        id="textarea"
        {...props}
        aria-label={props["aria-label"]}
        maxLength={maxLength || MAX_INPUT_LENGTH}
        className={`${getTextAreaStyle(
          styles,
          textAreaStyle
        )} ${usePredefinedClassIfFound(textareaClassNames, styles)} ${
          isDocumentRtl() && styles.textareaRtl
        }`}
      >
        {value}
      </textarea>
    </div>
  );
};

export default TextareaComponent;
