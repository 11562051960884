import { gql } from "@apollo/client";

const GET_GENERAL_USERS_INFORMATION = gql`
  query users($size: UInt, $text: String) {
    users(userSearch: { size: $size, text: $text }) {
      totalCount
      users {
        fullName
        email
        publicId
        organization {
          sector {
            name
          }
          name
          userRole
        }
      }
    }
  }
`;
export default GET_GENERAL_USERS_INFORMATION;
