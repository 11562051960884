import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import Button from "@components/Button/Button";

import { IPhoneData, getValidationSchema } from "./validation";

import { Form } from "./Form/Form";

interface ICreateUpdateForm {
  phoneData?: IPhoneData;
  onSubmit?: (formData: IPhoneData) => void;
  isLoading?: boolean;
}

export const CreateUpdateForm = ({
  phoneData,
  onSubmit,
  isLoading,
}: ICreateUpdateForm) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        countryCode: phoneData?.countryCode || "",
        telephone: phoneData?.telephone || "",
      }}
      enableReinitialize
      onSubmit={(data) => onSubmit?.(data)}
      validationSchema={getValidationSchema(t)}
    >
      {({ isValid, submitForm }) => {
        return (
          <>
            <Form />
            <div>
              <Button
                title={t("accountSettings.personalDetails.button.save")}
                buttonType="primary"
                disabled={!isValid || isLoading}
                type="button"
                onClick={submitForm}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};
