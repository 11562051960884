import { useTranslation } from "react-i18next";
import styles from "./NoPendingInvitationsCard.module.scss";

// icons
import invitations from "@images/invitations.svg";

const NoPendingInvitationsCard = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.noPendingInvitationsContainerOuter}>
      <img className={styles.noPendingInvitationsImage} src={invitations} />
      <h3 className={styles.noPendingInvitationsTitle}>
        {t("invitations.noPendingInvitations.title")}
      </h3>
      <span className={styles.noPendingInvitationsSubtitle}>
        {t("invitations.noPendingInvitations.subtitle")}
      </span>
    </div>
  );
};

export default NoPendingInvitationsCard;
