/* eslint-disable prettier/prettier */
import TextTruncated from "@components/TextTruncated/TextTruncated";
import { usePredefinedClassIfFound } from "@utils/classNamesHelpers";
import { Button as AntDButton } from "antd";
import { ButtonShape, ButtonType } from "antd/lib/button/button";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { CSSProperties, MouseEvent, ReactNode } from "react";
import styles from "./Button.module.scss";

const BUTTON_TYPE_CLASSES = {
  default: styles.hiveButtonDefault,
  primary: styles.hiveButtonPrimary,
  ghost: styles.hiveButtonGhost,
  dashed: styles.hiveButtonDashed,
  link: styles.hiveButtonLink,
  text: styles.hiveButtonText,
};

interface IButtonProps {
  type?: "button" | "submit" | "reset";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any;
  style?: CSSProperties;
  wrapperClassNames?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  uploadOnChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  uploadButton?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  iconAfter?: boolean;
  // AntD button props
  buttonType?: ButtonType;
  buttonShape?: ButtonShape;
  buttonSize?: SizeType;
  buttonIcon?: ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  danger?: boolean;
  ghost?: boolean;
  multiple?: boolean;
  buttonLengthLimit?: number;
  e2eTestId?: string;
  "data-testid"?: string;
  "aria-label"?: string;
  idElement?: string;
  customRef?: React.Ref<HTMLDivElement>;
}

function Button({
  wrapperClassNames,
  style,
  iconAfter,
  buttonType,
  buttonShape,
  buttonSize,
  buttonIcon,
  fullWidth,
  loading,
  danger,
  ghost,
  buttonLengthLimit,
  idElement,
  multiple = false,
  ...props
}: IButtonProps) {
  const handleFileClick = (event: MouseEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const renderTitle = () => {
    if (!props.title) return;

    return buttonLengthLimit ? (
      <TextTruncated
        textToTruncate={props.title}
        length={buttonLengthLimit}
        placement="bottom"
      />
    ) : (
      <span>{props.title}</span>
    );
  };

  return (
    <div
      className={`${styles.buttonWrapper} ${usePredefinedClassIfFound(
        wrapperClassNames,
        styles
      )}`}
      ref={props.customRef}
    >
      {props.uploadButton ? (
        <>
          <label
            htmlFor={idElement}
            className={`${styles.hiveButton} ${styles.uploadButton}`}
          >
            <span>{props.title}</span>
          </label>
          <input
            aria-label={props["aria-label"]}
            e2e-test-id={props.e2eTestId}
            className={styles.hiddenInput}
            type="file"
            accept="image/*"
            multiple={multiple}
            id={idElement ? idElement : "file-upload"}
            onClick={handleFileClick}
            onChange={props.uploadOnChange}
          />
        </>
      ) : (
        <AntDButton
          id={idElement}
          aria-label={props["aria-label"]}
          data-testid={props["data-testid"]}
          e2e-test-id={props.e2eTestId}
          className={`${
            buttonType ? `${BUTTON_TYPE_CLASSES[buttonType]}` : styles.hiveButton
          } ${danger && styles.danger} ${props.className}`}
          style={style}
          icon={iconAfter ? false : buttonIcon}
          onClick={props.onClick}
          type={buttonType}
          shape={buttonShape || "round"}
          size={buttonSize}
          loading={loading}
          block={fullWidth}
          ghost={ghost}
          danger={danger}
          disabled={props.disabled}
        >
          {renderTitle()}
          {iconAfter && <span className={styles.suffixIconWrapper}>{props.icon}</span>}
        </AntDButton>
      )}
    </div>
  );
}

export default Button;
