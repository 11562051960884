/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { GroupChannel } from "sendbird";

import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import Modal from "@components/Modal/Modal";
import Tabs from "@components/Tabs/Tabs";
import TextareaComponent from "@components/Textarea/Textarea";

import { MAX_TEXT_AREA_LENGTH } from "@constants/constants";

import { DIRECT, MAX_MESSAGES_LIMIT } from "@customHooks/sendBird/constant";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { ReactComponent as Copy } from "@images/copyIconSecond.svg";
import Exit from "@images/exit.svg";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import styles from "./ShareModal.module.scss";

interface IShareModal {
  customUrl?: string;
  open: boolean;
  close: () => void;
  copyLink: () => void;
  share: () => void;
}

const ShareModal = ({ customUrl, open, close, copyLink, share }: IShareModal) => {
  const { sb } = useSendBirdContext();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const { t } = useTranslation();
  const [groupChats, setGroupChats] = useState<GroupChannel[]>([]);
  const [directChats, setDirectChats] = useState<GroupChannel[]>([]);
  const [communityChats, setCommunityChats] = useState<GroupChannel[]>([]);
  const [checkedCommunityTabItems, setCheckedCommunityTabItems] = useState<
    GroupChannel[]
  >([]);
  const [checkedGroupChatTabItems, setCheckedGroupChatTabItems] = useState<
    GroupChannel[]
  >([]);
  const [checkedDirectChatTabItems, setCheckedDirectChatTabItems] = useState<
    GroupChannel[]
  >([]);
  const [message, setMessage] = useState<string>("");
  const url = window.location.href;

  const removeTabItemFromCheckedItems = (
    checkedItem: GroupChannel,
    setCheckedTabItems: React.Dispatch<React.SetStateAction<GroupChannel[]>>
  ) => {
    setCheckedTabItems((previousState) =>
      previousState.filter(({ url }) => url !== checkedItem.url)
    );
  };

  const addTabItemToCheckedItems = (
    checkedItem: GroupChannel,
    setCheckedTabItems: React.Dispatch<React.SetStateAction<GroupChannel[]>>
  ) => {
    setCheckedTabItems((previousState) => [...previousState, checkedItem]);
  };

  const isTabItemChecked = (
    checkedTabItem: GroupChannel,
    selectedTabItems: GroupChannel[]
  ) => selectedTabItems.some(({ url }) => url === checkedTabItem.url);

  const getDirectChatMemberName = (directChat: GroupChannel) =>
    directChat.members.find((member) => member.userId !== currentUserEmail)?.nickname ||
    "";

  const getChats = () => {
    if (!sb) return;
    const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    listQuery.superChannelFilter = "nonsuper";
    listQuery.includeEmpty = true;
    listQuery.order = "chronological";
    listQuery.limit = MAX_MESSAGES_LIMIT;
    if (listQuery?.hasNext) {
      listQuery?.next(function (groupChannels, error) {
        if (error) {
          displayNotSuccessNotification(error);
          return;
        }

        setGroupChats(
          groupChannels.filter((group) => group && group.customType !== DIRECT)
        );
        setDirectChats(
          groupChannels.filter((group) => group && group.customType === DIRECT)
        );
      });
    }
  };

  const getCommunities = () => {
    if (!sb) return;
    const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    listQuery.superChannelFilter = "super";
    listQuery.includeEmpty = true;
    listQuery.order = "chronological";
    listQuery.limit = MAX_MESSAGES_LIMIT;
    if (listQuery?.hasNext) {
      listQuery?.next(function (groupChannels, error) {
        if (error) {
          displayNotSuccessNotification(error);
          return;
        }

        setCommunityChats(
          groupChannels.filter((group) => group && group.customType !== DIRECT)
        );
      });
    }
  };

  useEffect(() => {
    if (!sb) return;
    getCommunities();
    getChats();
  }, [sb]);

  const renderTabItemName = (item: GroupChannel) => {
    if (item?.customType === DIRECT) return getDirectChatMemberName(item);
    return item?.name;
  };

  const generateModalTab = (
    tabItems: GroupChannel[],
    tabName: string,
    checkedTabItems: GroupChannel[],
    setCheckedTabs: React.Dispatch<React.SetStateAction<GroupChannel[]>>
  ) => ({
    name: t(tabName),
    content: (
      <div aria-label={"communityContainer"} className={styles.tabContainer}>
        {tabItems?.map(
          (tabItem) =>
            tabItem && (
              <div
                role="row"
                aria-label="tab-item"
                className={styles.tabItemRow}
                key={tabItem.url}
              >
                <Checkbox
                  wrapperClassName={styles.checkbox}
                  name=""
                  label=""
                  labelLeft={renderTabItemName(tabItem)}
                  aria-label="checkboxes"
                  checked={isTabItemChecked(tabItem, checkedTabItems)}
                  onChange={() => {
                    isTabItemChecked(tabItem, checkedTabItems)
                      ? removeTabItemFromCheckedItems(tabItem, setCheckedTabs)
                      : addTabItemToCheckedItems(tabItem, setCheckedTabs);
                  }}
                />
              </div>
            )
        )}
      </div>
    ),
  });

  const SHARE_MODAL_TABS = [
    generateModalTab(
      communityChats,
      "singleArticle.shareModal.tabs.community",
      checkedCommunityTabItems,
      setCheckedCommunityTabItems
    ),
    generateModalTab(
      groupChats,
      "singleArticle.shareModal.tabs.groupChat",
      checkedGroupChatTabItems,
      setCheckedGroupChatTabItems
    ),
    generateModalTab(
      directChats,
      "singleArticle.shareModal.tabs.directChat",
      checkedDirectChatTabItems,
      setCheckedDirectChatTabItems
    ),
  ];

  const handleTypeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onModalClose = () => {
    setMessage("");
    setCheckedCommunityTabItems([]);
    setCheckedGroupChatTabItems([]);
    setCheckedDirectChatTabItems([]);
    close();
  };

  const sendMessageWithLink = (groupChannel: GroupChannel) => {
    if (!sb) return;
    const params = new sb.UserMessageParams();
    params.message = `${customUrl || url} - ${message}`;
    params.customType = "General";

    groupChannel.sendUserMessage(params, (message, error) => {
      if (sb && message.errorCode === 800200) return;
      if (error) {
        displayNotSuccessNotification(error);
        return;
      }
    });
  };

  const shareMessageToCheckedItemsInAllTabs = () => {
    checkedCommunityTabItems.forEach(sendMessageWithLink);
    checkedGroupChatTabItems.forEach(sendMessageWithLink);
    checkedDirectChatTabItems.forEach(sendMessageWithLink);
    onModalClose();
    share();
  };

  return (
    <Modal
      modalType="primary"
      onRequestClose={onModalClose}
      isOpen={open}
      className={styles.modal}
    >
      <div className={styles.titleContainer}>
        <h4 aria-label={"title"} className={styles.title}>
          {t("singleArticle.shareModal.title")}
        </h4>
        <Button
          aria-label={"closeButton"}
          title=""
          buttonShape="circle"
          wrapperClassNames={styles.buttonBorderless}
          onClick={onModalClose}
          buttonIcon={<img className={styles.pointer} src={Exit} alt="" />}
        />
      </div>

      <div className={styles.copyLinkWrapper} aria-label="input">
        <div className={styles.copyLink}>{customUrl || url}</div>
        <Button
          aria-label={"copy"}
          buttonIcon={<Copy className={styles.copyImg} />}
          wrapperClassNames={styles.buttonCopy}
          title={t("singleArticle.shareModal.copy")}
          buttonSize="small"
          onClick={copyLink}
        />
      </div>

      <div className={styles.tabs}>
        <Tabs tabs={SHARE_MODAL_TABS} />
      </div>

      <div className={styles.line} />

      <TextareaComponent
        boldLabel
        aria-label="textArea"
        textareaClassNames={styles.textArea}
        name={"textShare"}
        label={t("singleArticle.shareModal.areaLabel")}
        placeholder={t("singleArticle.shareModal.areaPlaceholder")}
        maxLength={MAX_TEXT_AREA_LENGTH}
        onChange={handleTypeMessage}
      />
      <div className={styles.buttonContainer}>
        <Button
          aria-label={"closeModal"}
          onClick={onModalClose}
          buttonType="link"
          title={t("singleArticle.shareModal.cancelButton")}
        />
        <Button
          aria-label={"share"}
          wrapperClassNames={styles.shareButton}
          buttonSize="large"
          buttonType="primary"
          title={t("singleArticle.shareModal.shareButton")}
          disabled={
            !checkedCommunityTabItems.length &&
            !checkedGroupChatTabItems.length &&
            !checkedDirectChatTabItems.length
          }
          onClick={shareMessageToCheckedItemsInAllTabs}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
