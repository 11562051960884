/* eslint-disable react-hooks/exhaustive-deps */
import BackButton from "@components/BackButton/BackButton";
import SearchInput from "@components/SearchInput/SearchInput";
import MagnifyGlassIcon from "@images/search_grey.svg";
import { CommunitySearch } from "@modules/CommunitySearch/CommunitySearch";
import styles from "./ExploreCommunities.module.scss";

import { useLazyQuery } from "@apollo/client";
import { COMMUNITIES_SLIDES_PAGE_SIZE } from "@constants/constants";
import GET_COMMUNITY_RESOURCES from "@graphql/queries/communityResources";
import ResourcesCommunitySection from "@modules/ResourcesCommunitySection/ResourcesCommunitySection";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { isDocumentRtl } from "@utils/checkDocumentDir";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CommunityType, ICommunity } from "types/community";
import { EMPTY_USER } from "types/user";
import { clearCommunitySearchTerm } from "../../store/community/actions";
import { RootState } from "../../store/store";

const ExploreCommunities = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearchFieldSubmitted, setIsSearchFieldSubmitted] = useState<boolean>(false);
  // heading text for community search component
  const [searchTerm, setSearchTerm] = useState<string>("");

  const communitySearchTerm = useSelector<RootState, string>(
    (state) => state.community.searchTerm
  );

  const [communities, setCommunities] = useState<Array<ICommunity>>([]);
  const [communitiesInitLoaded, setCommunitiesInitLoaded] = useState<boolean>(false);
  const [communitiesCount, setCommunitiesCount] = useState<number>(0);

  const [getCommunities, { loading }] = useLazyQuery(GET_COMMUNITY_RESOURCES, {
    fetchPolicy: "network-only",
    onCompleted: (data: {
      communities: {
        communities: Array<ICommunity>;
        totalCount: number;
        __typename: string;
      };
    }) => {
      if (!data.communities || !data.communities.communities) return;
      data.communities.communities.length
        ? setCommunities([
            ...communities.filter((community) => !(community.name === "placeholder")),
            ...data.communities.communities,
          ])
        : setCommunities([]);
      data.communities.totalCount && setCommunitiesCount(data.communities.totalCount);
      !communitiesInitLoaded && setCommunitiesInitLoaded(true);
    },
    onError: (error) => displayServerError(error),
  });

  const loadcommunities = () => {
    communities.length &&
      setCommunities([
        ...communities,
        ...addCommunityPlaceholders(
          communitiesCount - communities.length < COMMUNITIES_SLIDES_PAGE_SIZE
            ? communitiesCount - communities.length
            : COMMUNITIES_SLIDES_PAGE_SIZE
        ),
      ]);
    getCommunities({
      variables: {
        skip: communities.length,
        size: COMMUNITIES_SLIDES_PAGE_SIZE,
      },
    });
  };

  useEffect(() => {
    if (communitySearchTerm) {
      setSearchValue(communitySearchTerm);
      setSearchTerm(communitySearchTerm.trim());
      setIsSearchFieldSubmitted(true);
    }
  }, [communitySearchTerm]);

  const addCommunityPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push({
        name: "placeholder",
        description: "",
        sendbirdId: "",
        communityImageUrl: "",
        communityType: CommunityType.closed,
        members: [],
        joinRequests: [],
        admins: [],
        communityCreator: EMPTY_USER,
      });
    }
    return placeholderArray;
  };

  const setSearchState = () => {
    setIsSearchFieldSubmitted(true);
    setSearchTerm(searchValue.trim());
    setSearchValue("");
  };

  const handleSearchInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchValue) setSearchState();
  };

  const handleSearchInputClick = () => {
    if (searchValue) setSearchState();
  };

  useEffect(() => {
    loadcommunities();
  }, []);

  const handleBackTo = () => {
    setIsSearchFieldSubmitted(false);
    setSearchValue("");
    dispatch(clearCommunitySearchTerm());
  };

  return (
    <div className={styles.container} aria-label="explore-communities">
      <div className={styles.header}>
        {isSearchFieldSubmitted ? (
          <BackButton
            path={"/portal/community/explore"}
            label={t("exploreCommunities.backLink")}
            onClick={handleBackTo}
          />
        ) : (
          <BackButton path={"/portal/community"} label={t("community.backButton")} />
        )}

        <div className={styles.searchWrapper}>
          <SearchInput
            name="search communities"
            placeholder={t("exploreCommunities.search")}
            value={searchValue}
            searchIcon={
              <img src={MagnifyGlassIcon} onClick={handleSearchInputClick} alt="" />
            }
            onChange={(event) => setSearchValue(event.target.value)}
            className={`${styles.search} ${isDocumentRtl() && styles.searchRtl}`}
            onKeyDown={(event) => handleSearchInputKeyPress(event)}
          />
        </div>
      </div>
      {isSearchFieldSubmitted ? (
        <CommunitySearch searchTerm={searchTerm} />
      ) : (
        <div>
          <h2 className={styles.title}>{t("exploreCommunities.title")}</h2>
          <h6 className={styles.subtitle}>{t("exploreCommunities.subtitle")}</h6>
          <ResourcesCommunitySection
            communities={communities}
            communitiesCount={communitiesCount}
            communitiesLoading={!communitiesInitLoaded || loading}
            loadCommunities={loadcommunities}
            // Added " " as title since "" will render default title with icon.
            customHeaderTitle=" "
            pageSize={COMMUNITIES_SLIDES_PAGE_SIZE}
            customSeeAll={() => history.push("/portal/community/recommended")}
          />
        </div>
      )}
    </div>
  );
};

export default ExploreCommunities;
