/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import { ChannelCreate } from "./ChannelCreate/ChannelCreate";

import { ALL_DISCUSSIONS_PARAM, ALL_DISCUSSIONS_URL } from "@constants/constants";

import { ICommunity } from "types/community";
import { Channel } from "./Channel/Channel";

import { PlusOutlined } from "@ant-design/icons";
import messageIcon from "@images/messageIcon.svg";
import activeMessageIcon from "@images/messageIconActive.svg";

import styles from "./CommunitySidemenu.module.scss";

interface ICommunitySideMenu {
  setChannels: (x: Array<string>) => void;
  channels: Array<string>;
  groupChannel?: SendBird.GroupChannel;
  defaultChannel: string;
  community?: ICommunity;
  currentUserEmail?: string;
}

const CommunitySideMenu = ({
  setChannels,
  channels,
  groupChannel,
  defaultChannel,
  community,
  currentUserEmail,
}: ICommunitySideMenu) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ channel: string }>();
  const [channelCreation, setChannelCreation] = useState<boolean>(false);

  const checkIfLoggedUserIsCreator = () => {
    return community?.communityCreator.email === currentUserEmail;
  };

  const checkIfLoggedUserIsAdmin = () => {
    return !!community?.admins.some((admin) => admin.email === currentUserEmail);
  };

  const handleAllDiscussionClick = () => {
    history.push(
      `/portal/community/community-page/${groupChannel?.url}/${ALL_DISCUSSIONS_URL}`
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.backButtonContainer}>
        <BackButton
          path={`/portal/community/community-page/${community?.sendbirdId}`}
          label={t("community.backToCommunity")}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{t("community.sidemenu.title")}</div>
      </div>

      <div className={styles.content}>
        <div className={styles.allDiscussions}>
          <img
            src={
              params.channel === ALL_DISCUSSIONS_PARAM ? activeMessageIcon : messageIcon
            }
            width={18}
            height={18}
          />
          <span
            className={
              params.channel === ALL_DISCUSSIONS_PARAM
                ? styles.activeAllDiscussionsSpan
                : styles.allDiscussionsSpan
            }
            onClick={handleAllDiscussionClick}
          >
            {t("community.sidemenu.allDiscussions")}
          </span>
        </div>
        <div className={styles.channels}>
          {channels.map((name) => (
            <Channel key={name} name={name} params={params} groupChannel={groupChannel} />
          ))}
        </div>

        {channelCreation && (
          <ChannelCreate
            channels={channels}
            setChannels={setChannels}
            groupChannel={groupChannel}
            defaultChannel={defaultChannel}
            onCancel={() => setChannelCreation(false)}
          />
        )}
      </div>

      <div className={styles.newDiscussionButton}>
        {(checkIfLoggedUserIsCreator() || checkIfLoggedUserIsAdmin()) && (
          <Button
            title={t("community.createChannelButton")}
            onClick={() => setChannelCreation(true)}
            buttonIcon={<PlusOutlined />}
          />
        )}
      </div>
    </div>
  );
};

export default CommunitySideMenu;
