import { useTranslation } from "react-i18next";

import { InputSelect } from "@components/formik/InputSelect/InputSelect";
import { Label } from "@components/formik/Label/Label";

import { useGetPronouns } from "../../../service/useGetPronouns/useGetPronouns";

import styles from "@components/formik/form.style.module.scss";

interface IFormProps {
  isLabelDisplayed?: boolean;
  isLoading?: boolean;
  formPrefix?: string;
}

export const Form = ({ isLoading, formPrefix = "", isLabelDisplayed }: IFormProps) => {
  const { t } = useTranslation();
  const { options } = useGetPronouns();

  return (
    <div className={styles.column}>
      {isLabelDisplayed && <Label text={t("form.label.preferredPronouns")} name="" />}
      <div className={styles.column}>
        <InputSelect
          label=""
          name={`${formPrefix}pronouns`}
          placeholder={t("form.placeholder.selectOne")}
          options={options}
          isDisabled={isLoading}
        />
      </div>
    </div>
  );
};
