import { MeetingType } from "./enum";

export const MIN_MINUTES = 15;
export const MAX_MINUTES = 120;
export const EVENT_MIN_INTERVAL = 15;
export const START_DATE_TYPE = "start date";
export const END_DATE_TYPE = "end date";
export const START_TIME_TYPE = "start time";
export const END_TIME_TYPE = "end time";
export const MAX_URL_LENGTH = 1000;

export const eventTypes = [
  {
    name: "private",
    label: "event.newEvent.eventTypes.private.label",
    subLabel: "event.newEvent.eventTypes.private.text",
  },
  {
    name: "hive",
    label: "event.newEvent.eventTypes.public.label",
    subLabel: "event.newEvent.eventTypes.public.text",
  },
  {
    name: "public",
    label: "event.newEvent.eventTypes.openToPublic.label",
    subLabel: "event.newEvent.eventTypes.openToPublic.text",
  },
];

export const meetingTypes = [
  {
    name: MeetingType.Zoom,
    label: "event.newEvent.externalUrl.zoomMeetingOption",
    subLabel: "event.newEvent.externalUrl.zoomMeetingPlaceholder",
  },
  {
    name: MeetingType.External,
    label: "event.newEvent.externalUrl.externalMeetingOption",
    subLabel: "",
  },
];
