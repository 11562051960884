import { gql } from "@apollo/client";

export const REGISTER_TO_EVENT = gql`
  mutation registerToEvent($eventRegistrationInput: ExternalEventRegistrationInputType!) {
    registerToEvent(eventRegistrationInput: $eventRegistrationInput) {
      name
      publicId
    }
  }
`;

export const GENERATE_REGISTRATION_REPORT = gql`
  mutation GenerateRegistrationReport($eventPublicId: ID!) {
    generateRegistrationReport(eventPublicId: $eventPublicId) {
      name
      __typename
    }
  }
`;

export const GENERATE_ATTENDANCE_REPORT = gql`
  mutation GenerateAttendanceReport($eventPublicId: ID!) {
    generateAttendanceReport(eventPublicId: $eventPublicId) {
      name
      __typename
    }
  }
`;

export const GET_ACCESS_EVENT_INFORMATION = gql`
  mutation getAccessEventInformation(
    $getAccessEventInformationInput: ExternalGetAccessEventInformationInputType!
  ) {
    getAccessEventInformation(
      getAccessEventInformationInput: $getAccessEventInformationInput
    ) {
      signature
      joinEmail
      sdkKey
      fullName
    }
  }
`;
