import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import Modal from "@components/Modal/Modal";
import { MAX_INPUT_LENGTH } from "@constants/constants";
import ADD_SOURCE_SUGGESTION from "@graphql/mutations/AddSuggestedResource";
import removeIcon from "@images/closeModal.svg";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import styles from "./SuggestResourceModal.module.scss";

import { useMutation } from "@apollo/client";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ISuggestResourceModal {
  open: boolean;
  close: () => void;
}
const SuggestResourceModal = ({ open, close }: ISuggestResourceModal) => {
  const { t } = useTranslation();
  const [sourceUrl, setSourceUrl] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const [addSourceSuggestion] = useMutation(ADD_SOURCE_SUGGESTION);

  const closeModal = () => {
    setSourceUrl("");
    setFeedback("");
    close();
  };

  const suggestResource = () => {
    addSourceSuggestion({
      variables: {
        suggestion: {
          feedback,
          url: sourceUrl,
        },
      },
    })
      .then(() => {
        closeModal();
        setOpenConfirmationModal(true);
      })
      .catch((error) => displayServerError(error));
  };

  const handleUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceUrl(event.target.value);
  };

  const handleFeedback = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  return (
    <div>
      <Modal modalType="primary" onRequestClose={closeModal} isOpen={open}>
        <Button
          title=""
          wrapperClassNames={styles.closeButtonWrapper}
          buttonShape="circle"
          onClick={closeModal}
          buttonIcon={<img alt="" className={styles.removeIcon} src={removeIcon} />}
        />
        <h4 aria-label="title">{t("suggestResourceModal.title")}</h4>
        <span aria-label="subtitle" className={styles.subtitle}>
          {t("suggestResourceModal.subtitle")}
        </span>
        <Input
          aria-label="inputUrl"
          maxLength={MAX_INPUT_LENGTH}
          labelClassNames={styles.label}
          name={t("suggestResourceModal.sourceInput.label")}
          label={t("suggestResourceModal.sourceInput.label")}
          placeholder={t("suggestResourceModal.sourceInput.placeholder")}
          onChange={handleUrl}
        />
        <Input
          aria-label="inputFeedback"
          maxLength={MAX_INPUT_LENGTH}
          labelClassNames={styles.label}
          name={t("suggestResourceModal.feedbackInput.label")}
          label={t("suggestResourceModal.feedbackInput.label")}
          placeholder={t("suggestResourceModal.feedbackInput.placeholder")}
          onChange={handleFeedback}
        />
        <div className={styles.buttonContainer}>
          <Button
            aria-label="cancelButton"
            onClick={closeModal}
            buttonType="link"
            title={t("suggestResourceModal.cancelButton")}
          />
          <Button
            aria-label="confirmButton"
            buttonType="primary"
            buttonSize="large"
            title={t("suggestResourceModal.suggestButton")}
            disabled={!sourceUrl.length}
            onClick={suggestResource}
          />
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={openConfirmationModal}
        messageTitle={t("suggestResourceModal.confirmation.title")}
        messageContent={t("suggestResourceModal.confirmation.content")}
        messageSubtitle={t("suggestResourceModal.confirmation.subtitle")}
        closeModal={() => setOpenConfirmationModal(false)}
        dangerModal={false}
      />
    </div>
  );
};

export default SuggestResourceModal;
