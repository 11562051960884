/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import { LiveHeader } from "@components/EventTimeCard/LiveHeader/LiveHeader";
import OpenEventsRegisterModal from "@components/OpenEventsRegisterModal/OpenEventsRegisterModal";

import { isBetween } from "@utils/date/helper";
import { formatDate, isPastEvent } from "@utils/eventsHelper";

import { IOpenEvent } from "@graphql/open-events/type";

import styles from "./OpenPublicEventTimeCard.module.scss";

interface IEventTimeCard {
  eventPublicId: string;
  startDate: string;
  endDate: string;
  name: string;
  eventDescription?: string;
  hostingId?: number;
  startTime: Date;
  endTime: Date;
  isLoading: boolean;
  host?: string;
  hostEmail?: string;
  event: IOpenEvent;
}

const EventTimeCard = ({
  eventPublicId,
  startDate,
  endDate,
  name,
  eventDescription,
  host,
  hostEmail,
  event,
}: IEventTimeCard) => {
  const { t, i18n } = useTranslation();
  const [registerToEventModalOpen, setRegisterToEventModalOpen] =
    useState<boolean>(false);

  const eventHasPassed = isPastEvent(endDate);

  const isLive = isBetween(event);

  return (
    <div className={styles.container} aria-label="container">
      <div>
        {isLive ? (
          <LiveHeader name={name} />
        ) : (
          <div>
            <h4 aria-label="event-name">{name}</h4>
          </div>
        )}
      </div>
      <div>
        <div className={styles.bottomContainer}>
          <div className={styles.rowSecond}>
            <div className={styles.column}>
              <span className={styles.timeTitle}>{t("eventDetail.date.start")}</span>
              <span aria-label="date-start" className={styles.time}>
                {formatDate(new Date(startDate), i18n.language)}
              </span>
            </div>
            <span className={styles.border} />
            <div className={styles.columnSecond}>
              <span className={styles.timeTitle}>{t("eventDetail.date.end")}</span>
              <span aria-label="date-end" className={styles.time}>
                {formatDate(new Date(endDate), i18n.language)}
              </span>
            </div>
          </div>
        </div>

        <Button
          buttonType="primary"
          aria-label="attend-event"
          onClick={() => setRegisterToEventModalOpen(true)}
          title={
            eventHasPassed
              ? t("eventDetail.buttons.eventPassed")
              : t("openPublicEventTimeCard.buttons.registerToEvent")
          }
          disabled={eventHasPassed}
          className={styles.button}
        />
      </div>

      <OpenEventsRegisterModal
        eventPublicId={eventPublicId}
        closeModal={() => setRegisterToEventModalOpen(false)}
        isOpen={registerToEventModalOpen}
      />
    </div>
  );
};

export default EventTimeCard;
