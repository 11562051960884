import { ApolloError } from "@apollo/client";

import { displayCustomNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

export const useHandleError = () => {
  const handleError = (error: ApolloError) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorCode = (error.networkError as unknown)?.result?.errors[0]?.extensions
      ?.code;
    displayCustomNotSuccessNotification(errorCode);
  };

  return { handleError };
};
