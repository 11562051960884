import { Col, Row } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";
import AnimatedSection from "../AnimatedSection";

const StyledBg = styled(Row)<{ $bgColor: ColorType }>`
  background: ${(props) => props.$bgColor};
  padding: 0px;
`;

const StyledRow = styled(Row)`
  position: relative;
`;

// const StyledImage = styled.img`
//   width: 100%;
//   height: 100%;
// `;

export enum ColorType {
  NewGreen = "#01B3C5",
  Green = "#159E98",
  Blue = "#567AE8",
  White = "#FFFFFF",
}

// const StyledCol = styled(Col)<{ $bgColor: ColorType }>`
//   position: absolute !important;
//   top: -15%;
//   left: ${(props) => (props.$bgColor === ColorType.Green ? "50%" : "25%")};
//   transform: ${(props) =>
//     props.$bgColor === ColorType.Green ? "translateX(-50%)" : "translateX(-25%)"};
// `;

interface Props {
  bgColor: ColorType;
  leftItem: ReactNode;
  rightItem: ReactNode;
}

const CollapseFeature = ({ bgColor = ColorType.White, leftItem, rightItem }: Props) => {
  return (
    <StyledBg $bgColor={bgColor}>
      <AnimatedSection>
        <StyledRow align="middle">
          {/* <StyledCol $bgColor={bgColor}>
            <StyledImage
              width="250"
              height="20"
              src={bgColor === ColorType.Green ? GreenLine : BlueLine}
              alt="Icon"
            />
          </StyledCol> */}
          <Col xs={24} lg={12} span={12}>
            {leftItem}
          </Col>
          <Col xs={24} lg={12} span={12}>
            {rightItem}
          </Col>
        </StyledRow>
      </AnimatedSection>
    </StyledBg>
  );
};

export default CollapseFeature;
