import styles from "./Label.module.scss";

interface ILabel {
  text: string | JSX.Element;
  subText?: string;
  name: string;
  isRequired?: boolean;
}

export const Label = ({ text, name, isRequired, subText }: ILabel) => {
  return (
    <label className={styles.label} htmlFor={name}>
      {text} {isRequired && "*"}
      {subText && <span className={styles.subText}>{subText}</span>}
    </label>
  );
};
