import { SET_CLAIMS, SET_USER, SET_USER_IMAGE, SET_USER_PUBLIC_ID } from "./constants";
import { UserState } from "./types";

export const setClaims = (claims: Array<string>) => ({
  type: SET_CLAIMS,
  payload: claims,
});

export const setUserPublicId = (userPublicId: string) => ({
  type: SET_USER_PUBLIC_ID,
  payload: userPublicId,
});

export const setUserImage = (userImage: string) => ({
  type: SET_USER_IMAGE,
  payload: userImage,
});

export const setUser = (user: Partial<UserState>) => ({
  type: SET_USER,
  payload: user,
});
