const MAX_FILE_SIZE_BYTES = 10485760; // 10MB
const MAX_FILE_SIZE_WHEN_CREATE_UPDATE = 1024000; // 1MB
const KB_TO_MB = 1024;

export class FileHelper {
  public static getExtension(name: string) {
    const fileExtension = name.split(".").pop() as string;
    return `.${fileExtension}`;
  }

  public static getFileName(name: string) {
    return name.split(".").slice(0, -1).join(".");
  }

  public static getExtensionForFileChecker({ name }: File) {
    return name.substring(name.lastIndexOf(".") + 1, name.length);
  }

  public static isValidSize(file: File) {
    return file.size < MAX_FILE_SIZE_BYTES;
  }

  public static isValidImageSizeWhenCreateUpdate(file: File) {
    return file.size < MAX_FILE_SIZE_WHEN_CREATE_UPDATE;
  }

  public static getSizeInKB(size: number) {
    return Math.round(size / KB_TO_MB);
  }
}
