import { useMemo } from "react";

import FileCard from "@components/FileCard/FileCard";
import LinkCard from "@components/LinkCard/LinkCard";
import { IOption } from "@components/Menu/types";

import { ISortValue, sortingFileLinks } from "@constants/constants";

import checkDefaultSortType from "@utils/checkDefaultSortType";
import { getSortedArray } from "@utils/helpers";

import { FileActionProps } from "types/article";
import { IFile } from "types/file";
import { ILink } from "types/link";

interface IFilesAndLinksProps {
  element?: { files: Array<IFile>; links: Array<ILink> };
  sortValue: ISortValue;
  files: IFile[];
  linkMenuDots: (link: ILink) => IOption[];
  fileMenuDots: (file: FileActionProps) => IOption[];
  getFileName?: (fileName: string) => void;
  renameFile?: (fileId: string, fileName: string, fileExtenstion: string) => void;
}

export const FilesAndLinks = ({
  element,
  files,
  sortValue,
  fileMenuDots,
  linkMenuDots,
  renameFile,
}: IFilesAndLinksProps) => {
  const filesAndLinksMemoized = useMemo(() => {
    if (!element) return [];

    const filesAndLinks = [...files, ...element.links];

    const sortedFilesAndLinks = getSortedArray(
      filesAndLinks,
      sortingFileLinks[sortValue.value]?.value,
      checkDefaultSortType(sortValue)
    );

    return sortedFilesAndLinks.map((element) => {
      if ("url" in element) {
        const link = element as ILink;
        return (
          <LinkCard menuOptions={linkMenuDots(link)} key={link.publicId} link={link} />
        );
      }

      const file = element as IFile;

      return (
        <FileCard
          key={file.publicId}
          file={file}
          isEdit={file.isEdit}
          menuOptions={fileMenuDots({
            publicId: file.publicId,
            fileName: file.name,
            fileDirectory: file.directory,
            filePublicId: file.publicId,
            isPinnable: file.isPinnable,
            canUserDeleteFile: file.canUserDeleteFile,
            originalFileName: file.originalName,
          })}
          renameFile={renameFile}
          id={file.publicId}
        />
      );
    });
  }, [element, fileMenuDots, linkMenuDots, sortValue, files, renameFile]);

  return <>{filesAndLinksMemoized}</>;
};
