import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";
import TooltipComponent from "@components/Tooltip/Tooltip";

import { useEffect, useState } from "react";
import { IFile } from "types/file";
import styles from "./PinnedFileCard.module.scss";

import {
  supportedArchiveExtensions,
  supportedDocumentExtensions,
  supportedImageExtensions,
  supportedSpreadSheetExtensions,
  supportedVideoExtensions,
} from "@utils/files/constants";

// icons
import DocumentIcon from "@images/Files/doc.svg";
import ImageIcon from "@images/Files/image.svg";
import PdfIcon from "@images/Files/pdf-large.svg";
import SpreadsheetIcon from "@images/Files/spreadsheet-large.svg";
import UniversalFileIcon from "@images/Files/universalFile.svg";
import VideoIcon from "@images/Files/video-large.svg";
import ZipIcon from "@images/Files/zip-large.svg";
import { isDocumentRtl } from "@utils/checkDocumentDir";
import { FileHelper } from "@utils/files/fileHelper";

interface IPinnedFileCardProps {
  file: IFile;
  menuOptions?: IOption[];
}

const PinnedFileCard = ({ file, menuOptions }: IPinnedFileCardProps) => {
  const fileName = file.name;
  const [fileIcon, setFileIcon] = useState("");

  useEffect(() => {
    const fileExtension = FileHelper.getExtension(fileName);

    if (supportedDocumentExtensions.includes(fileExtension) && fileExtension !== ".pdf") {
      return setFileIcon(DocumentIcon);
    }
    if (supportedImageExtensions.includes(fileExtension)) {
      return setFileIcon(ImageIcon);
    }
    if (fileExtension === ".pdf") {
      return setFileIcon(PdfIcon);
    }
    if (supportedSpreadSheetExtensions.includes(fileExtension)) {
      return setFileIcon(SpreadsheetIcon);
    }
    if (supportedVideoExtensions.includes(fileExtension)) {
      return setFileIcon(VideoIcon);
    }
    if (supportedArchiveExtensions.includes(fileExtension)) {
      return setFileIcon(ZipIcon);
    }
    return setFileIcon(UniversalFileIcon);
  }, [fileName]);

  return (
    <div className={styles.card}>
      <img
        src={fileIcon}
        className={isDocumentRtl() ? `${styles.icon} ${styles.rtlIcon}` : styles.icon}
      />
      <TooltipComponent
        tooltipType="secondary"
        title={fileName.replace(/_/g, " ")}
        childToWrap={
          <span className={styles.fileName}>{fileName.replace(/_/g, " ")}</span>
        }
      />
      {menuOptions ? (
        <div className={styles.menuWrapper}>
          <Menu options={menuOptions} />
        </div>
      ) : null}
    </div>
  );
};

export default PinnedFileCard;
