/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { debounce } from "lodash";
import { SELECT_SEARCH_TYPE } from "types/select";

import styles from "./DynamicSelect.module.scss";

interface ISelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateOptions?: any;
  options: Array<{ value: string; label: string | JSX.Element }>;
  placeholder: string;
  className?: string;
  label: string | JSX.Element;
  name: string;
  isMulti?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  textBottom?: string;
  isDisabled?: boolean;
  boldLabel?: boolean;
  selectedValues?: string[];
  maxSelectedOptions?: number;
}

const wait = 1000;

const DynamicSelect = ({
  updateOptions,
  options,
  placeholder,
  className,
  label,
  name,
  isMulti,
  onChange,
  textBottom,
  isDisabled,
  boldLabel,
  selectedValues,
  maxSelectedOptions,
}: ISelectProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [selectOptions, setSelectOptions] = useState<
    Array<{ value: string; label: string | JSX.Element }>
  >([]);
  const [closeDropdown, setCloseDropdown] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  useEffect(() => {
    if (!maxSelectedOptions || !selectedValues) return;

    if (selectedValues.length === 0) return;

    if (selectedValues?.length >= maxSelectedOptions) {
      setCloseDropdown(true);
    } else {
      setCloseDropdown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const handleInputChange = debounce((inputValue) => {
    setInputValue(inputValue);

    updateOptions(inputValue, SELECT_SEARCH_TYPE.INPUT_CHANGE);
  }, wait);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuScrollToBottom = (e: any) => {
    const { target } = e;

    if (target.scrollTop + target.clientHeight + 10 >= target.scrollHeight) {
      updateOptions(inputValue, SELECT_SEARCH_TYPE.SCROLL_TO_BOTTOM);
    }
  };

  return (
    <div className={className && styles[className]} data-testid="select">
      {label && (
        <label
          className={boldLabel ? styles.boldLabel : styles.inputLabel}
          htmlFor={name}
        >
          <div>{label}</div>
          <span className={styles.textBottom}>{textBottom}</span>
        </label>
      )}
      <Select
        key={`select-${name}`}
        aria-label={`select-${name}`}
        options={selectOptions}
        placeholder={placeholder}
        onChange={onChange}
        mode={isMulti ? "multiple" : "tags"}
        disabled={isDisabled}
        notFoundContent={<div className="noOptions">{t("select.noOptions")}</div>}
        onSearch={handleInputChange}
        onPopupScroll={handleMenuScrollToBottom}
        className={styles.select}
        dropdownStyle={{
          zIndex: 1135,
          maxHeight: closeDropdown ? 0 : "none",
          padding: closeDropdown ? 0 : "auto",
        }}
        filterOption={false}
        optionFilterProp="children"
      />
      {closeDropdown && (
        <span className={styles.errorMessage}>{t("select.LengthError")}</span>
      )}
    </div>
  );
};

export default DynamicSelect;
