/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Menu from "@components/Menu/Menu";
import Table from "@components/Table/Table";
import { Divider } from "antd";

import { useLazyQuery } from "@apollo/client";
import { COMMUNITIES_FOR_WHO_ADMINS } from "@graphql/community/queries";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { ISearchedCommunitiesResponse, TableCommunity } from "types/community";

import Avatar from "@images/avatar.svg";

import {
  initialState as PAGINATION_INITIAL_STATE,
  reducer as paginationReducer,
  setCurrentPage,
  setPageSize,
  setSearch,
  setTotalCount,
} from "../../../components/Pagination/paginationReducer";

import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { useDebounce } from "@customHooks/uesDebounce/useDebounce";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import getStorageImage from "@utils/getStorageImage";
import { formatDateForTableRow } from "@utils/helpers";
import { IUserImageResponse } from "types/user";
import withUser from "../../../hoc/withUser";
import ChangeCreatorModal from "./ChangeCreatorModal/ChangeCreatorModal";
import styles from "./Community.module.scss";

const Community = () => {
  const { t } = useTranslation();

  const [tableData, setTableData] = useState<TableCommunity[]>([]);
  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
  const [isChangeCommunityCreatorModalOpen, setIsChangeCommunityCreatorModalOpen] =
    useState<boolean>(false);

  const [dataForModal, setDataForModal] = useState<{
    communityId: string;
    creatorId: string;
    communityName: string;
  }>({
    communityId: "",
    creatorId: "",
    communityName: "",
  });
  const queryInputRef = useRef<HTMLInputElement | null>(null);
  const [
    { totalCount, currentPage, pageSize, totalPagesCount, skip, search },
    paginationDispatch,
  ] = useReducer(paginationReducer, PAGINATION_INITIAL_STATE);

  const handlePageChange = (newPage: number, newPageSize: number) => {
    if (newPage !== currentPage) {
      paginationDispatch(setCurrentPage(newPage));
    }
    if (newPageSize !== pageSize) {
      paginationDispatch(setPageSize(newPageSize));
    }
  };

  const [getCommunities, { loading: isGettingCommunities, refetch }] =
    useLazyQuery<ISearchedCommunitiesResponse>(COMMUNITIES_FOR_WHO_ADMINS, {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ communitiesForWhoAdmins }) => {
        const communitiesforTable: TableCommunity[] =
          communitiesForWhoAdmins.communities.map((community) => ({
            name: community.name,
            dateCreated: formatDateForTableRow(community.creationTime!),
            communityType: community.communityType,
            communityCreator: {
              name: community.communityCreator.fullName,
              email: community.communityCreator.email,
            },
            changeCommunityCreator: {
              communityCreatorId: community.communityCreator.publicId,
              sendbirdId: community.sendbirdId,
              communityName: community.name,
            },
          }));

        getUserImages({
          variables: {
            profileImagesInput: {
              userEmails: communitiesforTable.map(
                (community) => community.communityCreator.email
              ),
            },
          },
        });
        setTableData(communitiesforTable);
        paginationDispatch(setTotalCount(communitiesForWhoAdmins.totalCount));
      },

      onError: (error) => displayServerError(error),
    });

  const debouncer = useRef(
    _.debounce(() => paginationDispatch(setSearch(queryInputRef.current?.value)), 1000)
  );
  const variables = useMemo(
    () => ({
      communitySearchOptions: {
        size: pageSize,
        skip,
        text: search,
        interests: [],
      },
    }),
    [currentPage, pageSize, skip, search]
  );
  useDebounce({
    queryFn: getCommunities,
    variables,
  });

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;

      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  useEffect(() => {
    getCommunities({
      variables: {
        communitySearchOptions: {
          size: pageSize,
          skip,
          text: search,
          interests: [],
        },
      },
    });
  }, [currentPage, pageSize, skip, search]);

  const handleUserSelected = (email: string) => {
    setSelectedUserEmail(email);
  };

  const handleOpenModal = (
    communityId: string,
    creatorId: string,
    communityName: string
  ) => {
    setDataForModal({ communityId, creatorId, communityName });
    setIsChangeCommunityCreatorModalOpen(true);
  };

  const tableColumns = [
    {
      title: t("adminPanel.communities.table.name"),
      dataIndex: "name",
      key: "name",
      width: 450,
    },
    {
      title: t("adminPanel.communities.table.createdDate"),
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: t("adminPanel.communities.table.communityType"),
      dataIndex: "communityType",
      key: "communityType",
    },
    {
      title: t("adminPanel.communities.table.communityCreator"),
      dataIndex: "communityCreator",
      key: "communityCreator",
      render: (communityCreator: { name: string; email: string }) => {
        const profileImg = userImages
          ? userImages.find((img) => img.email === communityCreator.email)?.image
          : Avatar;

        return (
          <div data-testid="user-row" className={styles.community}>
            <div
              style={{
                backgroundImage: `url(${profileImg})`,
              }}
              onClick={() => handleUserSelected(communityCreator.email)}
              className={styles.avatar}
              aria-label="AvatarIcon"
            ></div>
            <div
              className={styles.communityInfoContainer}
              onClick={() => handleUserSelected(communityCreator.email)}
            >
              <span className={styles.nameValue}>{communityCreator.name}</span>
              <span className={styles.emailValue}>{communityCreator.email}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: t("adminPanel.communities.table.settings"),
      dataIndex: "changeCommunityCreator",
      key: "changeCommunityCreator",
      width: 90,
      render: (changeCreatorData: {
        communityCreatorId: string;
        sendbirdId: string;
        communityName: string;
      }) => {
        return (
          <div className={styles.menuWrapper}>
            <Menu
              options={[
                {
                  label: "changeCreator",
                  action: () =>
                    handleOpenModal(
                      changeCreatorData.sendbirdId,
                      changeCreatorData.communityCreatorId,
                      changeCreatorData.communityName
                    ),
                },
              ]}
              placement="bottomRight"
            />
          </div>
        );
      },
    },
  ];

  const UserDrawerWithUser = withUser(UserDrawer);

  return (
    <>
      <div>
        <div className={styles.headingContainer}>
          <div className={styles.statusHeader}>
            <Button
              className={styles.allCommunitiesButton}
              title={t("adminPanel.communities.allCommunitiesButton")}
            />
          </div>
        </div>
        <Divider className={styles.statusHeaderDivider} />
        <div className={styles.searchForm}>
          <InputUpdated
            type="search"
            name="userSearch"
            aria-label="searchUser"
            onChange={({ target: { value } }) => value.length < 51 && debouncer.current()}
            ref={queryInputRef}
            placeholder={t("adminPanel.communities.searchPlaceholder")}
            loading={isGettingCommunities}
            size={t("adminPanel.communities.searchPlaceholder").length}
          />
        </div>
        {isGettingCommunities ? (
          <Loading disableBoxShadow />
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            totalNumberOfRows={totalCount}
            loadingData={isGettingCommunities}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPagesCount={totalPagesCount}
            pageSize={pageSize}
          />
        )}
      </div>
      {isChangeCommunityCreatorModalOpen && (
        <ChangeCreatorModal
          modalData={dataForModal}
          refetchCommunities={refetch}
          open={isChangeCommunityCreatorModalOpen}
          close={() => {
            setIsChangeCommunityCreatorModalOpen(false);
          }}
        />
      )}
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </>
  );
};

export default Community;
