import BackButton from "@components/BackButton/BackButton";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type LocationProps = {
  state: {
    redirect?: string;
    prevPath?: string;
  };
  pathname: string;
};

interface ICollectionsBackButton {
  onClick?: () => void;
}

const CollectionsBackButton = ({ onClick }: ICollectionsBackButton) => {
  const { t } = useTranslation();
  const location = useLocation() as LocationProps;
  const redirect = location.state?.redirect;
  let text = t("collections.backButtonMyCollections");

  switch (redirect) {
    case "/portal/resources":
      text = t("collections.backButtonResources");
      break;
    case "/portal/collections/my-collections":
      text = t("collections.backButtonMyCollections");
      break;
    case "/portal/collections/explore":
      text = t("collections.backButtonExplore");
      break;
    case "/portal/collections/followed":
      text = t("collections.backButtonFollowed");
      break;
    case "/portal/resources/collections":
      text = t("collections.backButtonResourcesView");
      break;
    default:
      text = t("collections.backButtonDefault");
  }

  return (
    <BackButton
      path={location.state?.prevPath ? location.state.prevPath : redirect}
      label={text}
      onClick={onClick}
    />
  );
};

export default CollectionsBackButton;
