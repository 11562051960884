import { ForwardedRef, forwardRef } from "react";

import AboutBg from "@images/about-bg.png";

import styles from "./SectionTwo.module.scss";

const SectionTwo = forwardRef((_props, ref: ForwardedRef<HTMLDivElement>) => {
  const aboutListItems = [
    {
      title: "Community collaboration",
      desc: "Facilitating the sharing of local knowledge and expertise and decision-making by supporting communities to co-create, collaborate and crowd-source solutions together. ",
    },
    {
      title: "Access to trusted information",
      desc: "Improving access to trustworthy and best available scientific information adapted to the community needs.",
    },
    {
      title: "Preparedness for response",
      desc: "Connecting and activating communities for the exchange of experiences and action-oriented activities to prepare and respond to health emergencies.",
    },
    {
      title: "Data for community action",
      desc: "Monitoring platform metrics, sharing data and evidence to help communities make informed decisions.",
    },
    {
      title: "Knowledge and expertise amplifying froum",
      desc: "Engaging with WHO experts to obtain the best available scientific information and enable the decisions, policies, and actions to be evidence informed.",
    },
  ];

  return (
    <div ref={ref} className={styles.container}>
      <p className={styles.title}>
        Hive enables building community relationships for the preparedness and response to
        health emergencies
      </p>
      {aboutListItems.map((listItem) => (
        <>
          <p className={styles.listItemTitle}>{listItem.title}</p>
          <p className={styles.listItemDesc}>{listItem.desc}</p>
        </>
      ))}
      <img className={styles.aboutBg} src={AboutBg} />
    </div>
  );
});

export default SectionTwo;
