import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { CollectionsTreeNode } from "@components/CollectionsTree";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";
import DangerModal from "@components/DangerModal/DangerModal";
import { IOption } from "@components/Menu/types";
import PreviwDocument from "@components/PreviewDocument/PreviewDocument";

import { FileActionProps } from "types/article";
import { IFile } from "types/file";
import { ILink } from "types/link";

import {
  IMAGE_CONTENT_TYPE,
  ISortValue,
  PDF,
  PDF_CONTENT_TYPE,
  PDF_EXTENSION,
  PDF_FOLDER_NAME,
  SORTING_A_Z,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
} from "@constants/constants";
import {
  MOVE_FILE_TO_COLLECTION,
  MOVE_FILE_TO_FOLDER,
} from "@graphql/collection/mutation";
import { GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES } from "@graphql/collection/queries";
import {
  CollectionType,
  ICollection,
  IUserCollectionResponse,
} from "@graphql/collection/type";
import { COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS } from "@graphql/community-collection/queries";
import { PIN_FILE_TO_COMMUNITY } from "@graphql/community/mutations";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import { DELETE_FILE } from "@graphql/mutations/deleteFile";
import DangerIcon from "@images/DangerIcon.png";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import {
  notSupportedExtensionsForDocumentPreview,
  supportedOfficeExtensions,
} from "@utils/files/constants";
import { useDownloadFile } from "@utils/files/useDownloadFile";

import UpdateCategoriesModal from "@components/UpdateCategoriesModal/UpdateCategoriesModal";
import { FilesAndLinks } from "@feature/collection";
import { PreviewModal } from "@feature/file";
import { useGetLazyFileByPublicId } from "@feature/file/service/useGetLazyByPublicId/useGetLazyByPublicId";
import UPDATE_FILE_CATEGORIES from "@graphql/mutations/updateFileCategories";
import { RootState } from "store/RootState";
import { IBreadcrumb } from "store/breadcrumbs/types";

interface FolderFilesContainerProps {
  folder: { links: Array<ILink>; files: Array<IFile> };
  files: IFile[];
  sortValue: ISortValue;
  linkMenuDots: (link: ILink) => IOption[];
  refetchFolder: () => void;
  isUserCreator: boolean;
  isUserCollaborator: boolean;
  collectionType?: string;
  parentCollectionName: string;
  renameFile?: (fileId: string, fileName: string, fileExtension: string) => void;
  getFileIdEdit?: (fileId: string) => void;
}

interface IDeleteFile {
  fileName?: string;
  fileDirectory?: string;
}

type ParamsProps = {
  collectionId: string;
  folderId: string;
};

const FolderFilesAndLinksContainer = ({
  folder,
  files,
  sortValue,
  linkMenuDots,
  isUserCreator,
  isUserCollaborator,
  refetchFolder,
  collectionType,
  parentCollectionName,
  renameFile,
  getFileIdEdit,
}: FolderFilesContainerProps) => {
  const { t } = useTranslation();
  const { getFile, data: fileData } = useGetLazyFileByPublicId();

  const currentUserId = useSelector<RootState, string>(
    (state) => state.user.userPublicId
  );

  const params = useParams() as unknown as ParamsProps;
  const [isOpenFileDangerModal, setIsOpenFileDangerModal] = useState<boolean>(false);
  const [fileToBeRemoved, setFileToBeRemoved] = useState<IDeleteFile>();
  const [fileToMoveId, setFileToMoveId] = useState<string>("");
  const [file, setFile] = useState<Blob | MediaSource>();
  const [openPreviewDocumentModal, setOpenPreviewDocumentModal] =
    useState<boolean>(false);
  const [type, setType] = useState<string>();
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
  const [fileToMoveName, setFileToMoveName] = useState<string>("");
  const [destinationFolderName, setDestinationFolderName] = useState<string>("");
  const [destinationCollectionName, setDestinationCollectionName] = useState<string>("");
  const [userAndCollaboratingCollections, setUserAndCollaboratingCollections] =
    useState<Array<ICollection>>();
  const [communityCollections, setCommunityCollections] = useState<ICollection[]>([]);
  const [parentCollection, setParentCollection] = useState<string>("");
  const [modalActiveTab, setModalActiveTab] = useState<string>("");
  const [isPreviewFileModalOpen, setIsPreviewModalOpen] = useState(false);

  const [isUpdateCategoriesModalOpen, setIsUpdateCategoriesModalOpen] =
    useState<boolean>(false);
  const [fileToUpdateId, setFileToUpdateId] = useState<string>("");
  const [fileCategories, setFileCategories] = useState<string[]>([]);
  const [previouslySelectedFileCategories, setPreviouslySelectedFileCategories] =
    useState<string[]>([]);
  const [fileToUpdateName, setFileToUpdateName] = useState<string>("");

  const handleSetModalActiveTab = (activeTab: string) => setModalActiveTab(activeTab);

  const downloadFile = useDownloadFile();

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  const CURRENT_COLLECTION: Pick<ICollection, "publicId" | "name"> = {
    publicId: params.collectionId,
    name: parentCollectionName!,
  };

  // GET USER AND COLLABORATING COLLECTIONS
  useQuery<IUserCollectionResponse>(GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IUserCollectionResponse) => {
      setUserAndCollaboratingCollections(
        [...data.user.myCollections, ...data.user.collaboratingCollections].sort(
          sortingCollectionsObj[SORTING_A_Z].sort
        )
      );
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );

  // GET ALL COMMUNITY COLLECTIONS FOR MOVE TO MODAL
  const [getAllCommunityCollections] = useLazyQuery(
    COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        const allCommunityCollections: ICollection[] =
          res.allCommunityCollections.collections;

        // Filter only collections that were created by
        // the current user or the user is a collaborator of
        const communityCollectionsCreatedOrCollaborator = allCommunityCollections.filter(
          (collection) =>
            collection.collectionCreator.publicId === currentUserId ||
            collection.collaborators?.some(
              (collaborator) => collaborator.publicId === currentUserId
            )
        );

        setCommunityCollections(communityCollectionsCreatedOrCollaborator);
      },
      onError: (err) => displayServerError(err),
    }
  );

  useEffect(() => {
    const communityCrumb = breadcrumbs.filter((crumb) =>
      crumb.path.includes("/portal/community")
    );

    let communityId;

    if (communityCrumb.length && collectionType === CollectionType.COMMUNITY_COLLECTION) {
      communityId = communityCrumb[0].id;
    }

    if (communityId) {
      getAllCommunityCollections({
        variables: {
          communityCollectionSearch: {
            size: 1000,
            skip: 0,
            text: "",
            sendbirdId: communityId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionType]);

  const handleFileDelete = (
    fileName: string | undefined,
    fileDirectory: string | undefined
  ) => {
    setFileToBeRemoved({
      fileName,
      fileDirectory,
    });
    setIsOpenFileDangerModal(true);
  };

  const handleFilePreview = ({
    originalFileName,
    fileDirectory,
  }: Omit<FileActionProps, "publicId">) => {
    const file = originalFileName;
    const directory = fileDirectory;
    const fileExt = originalFileName!.split(".").pop();
    let convertFile = false;
    if (supportedOfficeExtensions.includes("." + fileExt!) && fileExt !== PDF) {
      fileDirectory = fileDirectory + PDF_FOLDER_NAME;
      originalFileName = originalFileName!.replace("." + fileExt, PDF_EXTENSION);
      convertFile = true;
      setType(PDF_CONTENT_TYPE);
    } else {
      convertFile = false;
      setType(IMAGE_CONTENT_TYPE);
    }
    downloadFile(originalFileName!, fileDirectory!, false).then((res) => {
      if (res !== null) {
        if (!notSupportedExtensionsForDocumentPreview.includes("." + fileExt!)) {
          setFile(res);
          setOpenPreviewDocumentModal(true);
        } else {
          const message = t(
            "collections.collectionsActions.formatNotSupportedForPreview"
          );
          displayNotSuccessNotification(message, message);
        }
      } else {
        const message = t("collections.collectionsActions.documentNotReady");
        displayNotSuccessNotification(message, message);
        const convertToPdfRequest = {
          fileName: file,
          directoryName: directory,
        };
        if (convertFile) {
          try {
            ConvertFileToPdf({
              variables: { convertToPdfRequest },
            });
          } catch (error) {
            displayNotSuccessNotification();
          }
        }
      }
    });
  };

  const handleCloseFileModal = () => {
    setIsOpenFileDangerModal(false);
    setFileToBeRemoved(undefined);
  };

  const [updateFileCategories] = useMutation(UPDATE_FILE_CATEGORIES, {
    onCompleted: () => {
      setIsUpdateCategoriesModalOpen(false);
      displaySuccessNotification(t("collections.collectionsActions.successUpdateFile"));
      refetchFolder();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const getFileCategories = (fileId: string, fileName: string) => {
    const fileCategories = files
      .filter((file) => file.publicId === fileId)[0]
      .interests?.map((category) => ({
        name: category,
        _typename: "Category",
      }))
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .map((category: any) => category.name.name);

    setFileToUpdateId(fileId);
    setFileToUpdateName(fileName);
    setFileCategories(fileCategories!);
    setPreviouslySelectedFileCategories(fileCategories!);
  };

  const handleUpdateFileCategories = () => {
    updateFileCategories({
      variables: {
        fileUpdate: {
          filePublicId: fileToUpdateId,
          interests: fileCategories,
        },
      },
    });
  };

  const deleteFileFromCollectionHandler = (file: IDeleteFile) => {
    const { fileName, fileDirectory } = file;
    deleteFileFromCollection({
      variables: {
        deleteFileRequest: {
          fileName,
          fileDirectory,
        },
      },
    });
  };

  const [pinFileToCommunity] = useMutation<string>(PIN_FILE_TO_COMMUNITY, {
    onCompleted: () => {
      refetchFolder();
      displaySuccessNotification(t("collections.collectionsActions.successPinFile"));
    },
    onError: () => {
      displayNotSuccessNotification();
    },
  });

  const [deleteFileFromCollection] = useMutation(DELETE_FILE, {
    onCompleted: () => {
      setIsOpenFileDangerModal(false);
      displaySuccessNotification(t("collections.collectionsActions.successDeleteFile"));
      refetchFolder();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const MAX_FILE_NAME_LENGTH = 30;

  const checkFileNameLength = (fileName: string) => {
    if (fileName.length <= MAX_FILE_NAME_LENGTH) {
      return fileName;
    } else {
      return `${fileName.slice(0, MAX_FILE_NAME_LENGTH)}...`;
    }
  };

  const [moveFileToCollection] = useMutation(MOVE_FILE_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveFileToCollection", {
          fileToMoveName: checkFileNameLength(fileToMoveName),
          destinationCollectionName,
        })
      );
      refetchFolder();
      setIsMoveModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            name: fileToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3010") {
        const message = t("collections.singleCollection.fileAlreadyInCollection", {
          name: fileToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          name: checkFileNameLength(fileToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveFileToFolder] = useMutation(MOVE_FILE_TO_FOLDER, {
    onCompleted: () => {
      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t("collections.collectionsActions.successMoveFileToFolderInOtherCollection", {
              fileToMoveName: checkFileNameLength(fileToMoveName),
              destinationFolderName,
              collectionName: parentCollection,
            })
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveFileToFolder", {
              fileToMoveName: checkFileNameLength(fileToMoveName),
              destinationFolderName,
            })
          );
      refetchFolder();
      setIsMoveModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            name: fileToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3009") {
        const message = t("collections.singleCollection.fileAlreadyInFolder", {
          name: fileToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          name: checkFileNameLength(fileToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const handleMoveToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveFileToCollection({
        variables: {
          moveFileToCollectionRequest: {
            collectionPublicId: lastChild.key,
            filePublicId: fileToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setDestinationFolderName(lastChild!.title as string);
      setParentCollection(firstChild!.title as string);
      moveFileToFolder({
        variables: {
          moveFileToFolderRequest: {
            folderPublicId: lastChild.key,
            filePublicId: fileToMoveId,
          },
        },
      });
    }
  };

  const fileMenuDots = useCallback(
    ({
      publicId,
      fileName,
      fileDirectory,
      filePublicId,
      isPinnable,
      canUserDeleteFile,
      originalFileName,
    }: FileActionProps) => {
      let options: IOption[] = [
        {
          label: "download",
          action: () => {
            downloadFile(originalFileName!, fileDirectory!);
          },
        },
        {
          label: "preview",
          action: () => handleFilePreview({ originalFileName, fileDirectory }),
        },
        {
          label: "moreInfo",
          action: () => {
            getFile({
              fetchPolicy: "no-cache",
              variables: { publicId },
              onCompleted: () => setIsPreviewModalOpen(true),
            });
          },
        },
      ];

      if (isUserCreator || isUserCollaborator) {
        options = [
          ...options,
          {
            label: "renameFile",
            action: () => {
              if (getFileIdEdit) getFileIdEdit(filePublicId!);
            },
          },
          {
            label: "move",
            action: () => {
              setIsMoveModalOpen(true);
              setFileToMoveId(filePublicId!);
              setFileToMoveName(fileName!);
            },
          },
        ];
      }

      if (canUserDeleteFile) {
        options = [
          ...options,
          {
            isDelete: true,
            label: "delete",
            action: () => handleFileDelete(fileName, fileDirectory),
          },
        ];
      }
      if (isPinnable && collectionType === CollectionType.COMMUNITY_COLLECTION) {
        options = [
          {
            label: "pin",
            action: () =>
              pinFileToCommunity({
                variables: {
                  filePublicId,
                },
              }),
          },
          ...options,
        ];
      }

      if (
        collectionType === CollectionType.COMMUNITY_COLLECTION &&
        (isUserCreator || isUserCollaborator)
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // This is here because Update Categories needs to be bellow the Rename file option
        options = options.toSpliced(-2, 0, {
          label: "updateCategories",
          action: () => {
            setIsUpdateCategoriesModalOpen(true);
            getFileCategories(filePublicId!, fileName!);
          },
        });
      }

      return options;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionType, isUserCollaborator, isUserCreator, getFileIdEdit]
  );

  const collectionsForMoveModal =
    collectionType === CollectionType.USER_COLLECTION
      ? userAndCollaboratingCollections
      : communityCollections;

  const collectionsTabTitle =
    collectionType === CollectionType.USER_COLLECTION
      ? "personalTabTitle"
      : "personalCommunityTabTitle";

  return (
    <>
      <FilesAndLinks
        element={folder}
        files={files}
        sortValue={sortValue}
        renameFile={renameFile}
        linkMenuDots={linkMenuDots}
        fileMenuDots={fileMenuDots}
      />
      {fileToBeRemoved && (
        <DangerModal
          title={t("collections.collectionsActions.deleteFileTitle", {
            fileName: fileToBeRemoved.fileName,
          })}
          subTitle={t("collections.collectionsActions.deleteFileSubTitle")}
          isOpen={isOpenFileDangerModal}
          onClose={() => handleCloseFileModal()}
          onSubmit={() => deleteFileFromCollectionHandler({ ...fileToBeRemoved })}
          image={<img src={DangerIcon} />}
          closeButton={t("collections.collectionsActions.keepFile")}
          ctaButton={t("collections.collectionsActions.deleteFile")}
        />
      )}
      {isMoveModalOpen && (
        <CollectionsTreeModal
          collectionsTabTitle={collectionsTabTitle}
          collections={collectionsForMoveModal}
          handleSetModalActiveTab={handleSetModalActiveTab}
          showCurrentAndMyCollections
          treeModalType={TREE_MODAL_TYPE.move}
          isOpen={isMoveModalOpen}
          currentCollection={CURRENT_COLLECTION}
          onCancel={() => setIsMoveModalOpen(false)}
          onSelect={handleMoveToSelect}
          modalType="primary"
        />
      )}
      {isUpdateCategoriesModalOpen && (
        <UpdateCategoriesModal
          fileName={fileToUpdateName}
          selectedCategories={fileCategories}
          previouslySelectedFileCategories={previouslySelectedFileCategories}
          isOpen={isUpdateCategoriesModalOpen}
          close={() => setIsUpdateCategoriesModalOpen(false)}
          handleUpdateCategories={setFileCategories}
          handleSubmitFileUpdateCategories={handleUpdateFileCategories}
        />
      )}
      <PreviwDocument
        open={openPreviewDocumentModal}
        close={() => setOpenPreviewDocumentModal(false)}
        file={file!}
        type={type!}
      />
      <PreviewModal
        isOpen={isPreviewFileModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        file={fileData?.fileInfo}
      />
    </>
  );
};

export default FolderFilesAndLinksContainer;
