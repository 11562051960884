import { Col, Menu, Row } from "antd";
import React from "react";
import styled from "styled-components";
import palette from "./../../../scss/exports.module.scss";

import { useHistory } from "react-router";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { configuration } from "../../../modules/LandingPage/Data";
import styles from "./Header.module.scss";

const StyledMenu = styled(Menu)`
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  color: ${palette.white};
  width: 100%;

  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(Menu.Item)<{ $secondaryHover: boolean }>`
  a,
  button {
    color: inherit !important;
    -webkit-appearance: none;
    border: 0;
    line-height: revert;
    background: none;
    cursor: pointer;
  }

  ${(props) =>
    props.$secondaryHover
      ? `
    border-radius: 100px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
    background: #333333 !important;
    color: #ffffff !important;
    transition: 0.2s box-shadow ease-out !important;
    &:hover {
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);

    }
    &:after {
      display: none !important;
    }
  `
      : `
    color: #333333 !important;
    &:after {
      border-bottom-width: 1px !important;
      border-color: #333333 !important;
      transition: 0.2s transform ease-out !important;
    }
    &:hover {
      color: #333333 !important;
    }
    &:hover:after {
      transform: translateY(-2px);
    }
  `}
`;

interface IHeaderProps {
  signupRef: React.MutableRefObject<HTMLDivElement | null>;
  aboutRef?: React.MutableRefObject<HTMLDivElement | null>;
  hiveFeaturesRef?: React.MutableRefObject<HTMLDivElement | null>;
  hivePulseRef?: React.MutableRefObject<HTMLDivElement | null>;
  showEventsButton?: boolean;
  showLoginButton?: boolean;
  showRegisterButton?: boolean;
  showNavigationButtons?: boolean;
}

const Header = ({
  signupRef,
  aboutRef,
  hiveFeaturesRef,
  hivePulseRef,
  showEventsButton,
  showLoginButton,
  showRegisterButton,
  showNavigationButtons,
}: IHeaderProps) => {
  const { header } = configuration;
  const history = useHistory();

  const executeScroll = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current)
      window.scrollTo({
        top: ref.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
  };

  const { instance } = useMsal();

  function handleLoginRedirect() {
    instance.loginRedirect(loginRequest);
  }

  function handleEventsRedirect() {
    history.push("/events");
  }

  function handleHomePageRedirect() {
    history.push("/");
  }

  return (
    <div className={styles.rowContainer}>
      <Row className={styles.headerContainer} align="middle" justify="space-between">
        <Col span={12}>
          <Row align="middle" justify="start">
            <Col xs={0} sm={12} span={12}>
              <div className={styles.leftSection}>
                <img
                  onClick={handleHomePageRedirect}
                  className={styles.logo}
                  src={header?.logoSrc}
                  alt={header?.logoAlt}
                />
                {showNavigationButtons && (
                  <>
                    <span
                      onClick={() => {
                        aboutRef && executeScroll(aboutRef);
                      }}
                    >
                      {header?.menu[3].text}
                    </span>
                    <span
                      onClick={() => {
                        hiveFeaturesRef && executeScroll(hiveFeaturesRef);
                      }}
                    >
                      {header?.menu[4].text}
                    </span>
                    <span
                      onClick={() => {
                        hivePulseRef && executeScroll(hivePulseRef);
                      }}
                    >
                      {header?.menu[5].text}
                    </span>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} sm={0} span={12}>
              <img
                width="124"
                height="38"
                src={header?.logoMobileSrc}
                alt={header?.logoMobileAlt}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align="middle" justify="end">
            <StyledMenu mode="horizontal">
              {showEventsButton && (
                <StyledMenuItem key={1} $secondaryHover={header?.menu[0]?.secondaryHover}>
                  <button onClick={handleEventsRedirect}>{header?.menu[0]?.text}</button>
                </StyledMenuItem>
              )}
              {showLoginButton && (
                <StyledMenuItem key={2} $secondaryHover={header?.menu[1]?.secondaryHover}>
                  <button onClick={handleLoginRedirect}>{header?.menu[1]?.text}</button>
                </StyledMenuItem>
              )}
              {/* Removed as a hotfix until it is updated */}
              {/* {showRegisterButton && (
                <StyledMenuItem
                  key={3}
                  $secondaryHover={header?.menu[2]?.secondaryHover}
                  onClick={() => executeScroll(signupRef)}
                >
                  <button>{header?.menu[2]?.text}</button>
                </StyledMenuItem>
              )} */}
            </StyledMenu>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default React.forwardRef(Header);
