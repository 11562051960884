import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CollectionsTreeNode } from "@components/CollectionsTree";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";
import { IOption } from "@components/Menu/types";
import NewFolderCard from "@components/NewFolderCard/NewFolderCard";
import {
  SORTING_A_Z,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
} from "@constants/constants";
import {
  MOVE_FOLDER_TO_COLLECTION,
  MOVE_FOLDER_TO_FOLDER,
} from "@graphql/collection/mutation";
import { GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES } from "@graphql/collection/queries";
import {
  CollectionType,
  ICollection,
  INewFolder,
  ISingleCollection,
  IUserCollectionResponse,
} from "@graphql/collection/type";
import { COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS } from "@graphql/community-collection/queries";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IBreadcrumb } from "store/breadcrumbs/types";
import { IFolder } from "../../store/newFolders/types";
import { RootState } from "../../store/store";

interface FolderFoldersContainerProps {
  collectionId: string;
  folders: INewFolder[];
  getFolderName?: (folderName: string) => void;
  getFolderIdEdit?: (folderId: string) => void;
  renameFolder?: (folderId: string, folderName: string) => void;
  deleteFolder?: (folderId: string) => void;
  refetchFolder: () => void;
  currentCollection: ISingleCollection;
  isUserCreator: boolean;
  isUserCollaborator: boolean;
  collectionType?: string;
}

const FolderFoldersContainer = ({
  collectionId,
  folders,
  getFolderName,
  getFolderIdEdit,
  renameFolder,
  deleteFolder,
  isUserCreator,
  isUserCollaborator,
  collectionType,
  currentCollection,
  refetchFolder,
}: FolderFoldersContainerProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const currentUserId = useSelector<RootState, string>(
    (state) => state.user.userPublicId
  );

  const [isMoveFolderModalOpen, setIsMoveFolderModalOpen] = useState<boolean>(false);
  const [folderToMoveId, setFolderToMoveId] = useState<string>("");
  const [folderToMoveName, setFolderToMoveName] = useState<string>("");
  const [destinationFolderName, setDestinationFolderName] = useState<string>("");
  const [destinationCollectionName, setDestinationCollectionName] = useState<string>("");
  const [parentCollection, setParentCollection] = useState<string>("");

  const [modalActiveTab, setModalActiveTab] = useState<string>("");

  const handleSetModalActiveTab = (activeTab: string) => setModalActiveTab(activeTab);

  const [userAndCollaboratingCollections, setUserAndCollaboratingCollections] =
    useState<Array<ICollection>>();

  const [communityCollections, setCommunityCollections] = useState<ICollection[]>([]);

  const newFolders = useSelector<RootState, IFolder[]>(
    (state) => state.newFolders.folders
  );

  // GET USER AND COLLABORATING COLLECTIONS
  useQuery<IUserCollectionResponse>(GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IUserCollectionResponse) => {
      setUserAndCollaboratingCollections(
        [...data.user.myCollections, ...data.user.collaboratingCollections].sort(
          sortingCollectionsObj[SORTING_A_Z].sort
        )
      );
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );

  // GET ALL COMMUNITY COLLECTIONS FOR MOVE TO MODAL
  const [getAllCommunityCollections] = useLazyQuery(
    COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        const allCommunityCollections: ICollection[] =
          res.allCommunityCollections.collections;

        // Filter only collections that were created by
        // the current user or the user is a collaborator of
        const communityCollectionsCreatedOrCollaborator = allCommunityCollections.filter(
          (collection) =>
            collection.collectionCreator.publicId === currentUserId ||
            collection.collaborators?.some(
              (collaborator) => collaborator.publicId === currentUserId
            )
        );

        setCommunityCollections(communityCollectionsCreatedOrCollaborator);
      },
      onError: (err) => displayServerError(err),
    }
  );

  useEffect(() => {
    const communityCrumb = breadcrumbs.filter((crumb) =>
      crumb.path.includes("/portal/community")
    );

    let communityId;

    if (communityCrumb.length && collectionType === CollectionType.COMMUNITY_COLLECTION) {
      communityId = communityCrumb[0].id;
    }

    if (communityId) {
      getAllCommunityCollections({
        variables: {
          communityCollectionSearch: {
            size: 1000,
            skip: 0,
            text: "",
            sendbirdId: communityId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionType]);

  const [moveFolderToCollection] = useMutation(MOVE_FOLDER_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveFolderToCollection", {
          folderToMoveName,
          destinationCollectionName,
        })
      );
      refetchFolder();
      setIsMoveFolderModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "2500") {
        const message = t(
          "collections.singleCollection.folderExistsOrNotSufficientRights",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2509") {
        const message = t("collections.singleCollection.folderAlreadyInCollection", {
          name: folderToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2512") {
        const message = t(
          "collections.singleCollection.folderWithSameNameAlreadyExists",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveFolderToFolder] = useMutation(MOVE_FOLDER_TO_FOLDER, {
    onCompleted: () => {
      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t(
              "collections.collectionsActions.successMoveFolderToFolderInOtherCollection",
              {
                folderToMoveName,
                destinationFolderName,
                collectionName: parentCollection,
              }
            )
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveFolderToFolder", {
              folderToMoveName,
              destinationFolderName,
            })
          );
      refetchFolder();
      setIsMoveFolderModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "2500") {
        const message = t(
          "collections.singleCollection.folderExistsOrNotSufficientRights",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2508") {
        const message = t("collections.singleCollection.folderAlreadyInFolder", {
          name: folderToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2512") {
        const message = t(
          "collections.singleCollection.folderWithSameNameAlreadyExists",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2510") {
        const message = t("collections.singleCollection.destinationFolderIsSubfolder");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2511") {
        const message = t("collections.singleCollection.destinationFolderSameAsSource");
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const handleFolderNameSubmit = (folderName: string) => {
    if (folders?.find((folder) => folder.name === folderName)) {
      const message = t("collections.singleCollection.folderWithSameNameAlreadyExists", {
        name: folderName,
      });
      displayNotSuccessNotification(message, message);

      return;
    }

    if (getFolderName) getFolderName(folderName);
  };

  const handleFolderNameRename = (folderId: string, folderName: string) => {
    if (folders?.find((folder) => folder.name === folderName)) {
      const message = t("collections.singleCollection.folderWithSameNameAlreadyExists", {
        name: folderName,
      });
      displayNotSuccessNotification(message, message);

      return;
    }

    if (renameFolder) renameFolder(folderId, folderName);
  };

  const handleMoveFolderToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    setDestinationFolderName(lastChild!.title as string);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveFolderToCollection({
        variables: {
          moveFolderToCollectionRequest: {
            collectionPublicId: lastChild.key,
            folderPublicId: folderToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setParentCollection(firstChild!.title as string);
      moveFolderToFolder({
        variables: {
          moveFolderToFolderRequest: {
            destinationFolderPublicId: lastChild.key,
            sourceFolderPublicId: folderToMoveId,
          },
        },
      });
    }
  };

  const goToFolder = (folderId: string) => {
    history.push({
      pathname: `/portal/collection/${collectionId}/${folderId}`,
      state: {
        collectionId,
        folderId,
      },
    });
  };

  const folderMenuDots = (folder: INewFolder) => {
    const options: IOption[] = [];
    const { publicId, isEditable } = folder;

    if (!isEditable) {
      return options;
    }

    if (isUserCreator || isUserCollaborator) {
      options.push(
        {
          label: "rename",
          action: () => {
            if (getFolderIdEdit) getFolderIdEdit(publicId);
          },
        },
        {
          label: "move",
          action: () => {
            setIsMoveFolderModalOpen(true);
            setFolderToMoveId(folder.publicId);
            setFolderToMoveName(folder.name);
          },
        }
      );
    }

    if (folder.canUserDeleteFolder) {
      options.push({
        isDelete: true,
        label: "delete",
        action: () => {
          if (deleteFolder) deleteFolder(publicId);
        },
      });
    }

    return options;
  };

  const collectionsForMoveModal =
    collectionType === CollectionType.USER_COLLECTION
      ? userAndCollaboratingCollections
      : communityCollections;

  const collectionsTabTitle =
    collectionType === CollectionType.USER_COLLECTION
      ? "personalTabTitle"
      : "personalCommunityTabTitle";

  return (
    <>
      {newFolders &&
        newFolders.map((newFolder) => (
          <NewFolderCard
            key={newFolder.id}
            name={newFolder.name}
            isEdit
            folderNameSubmit={handleFolderNameSubmit}
          />
        ))}
      {folders &&
        folders.map((folder) => (
          <NewFolderCard
            key={folder.publicId}
            id={folder.publicId}
            name={folder.name}
            menuOptions={folderMenuDots(folder)}
            isEdit={folder.isEdit}
            renameFolder={handleFolderNameRename}
            onClick={goToFolder}
          />
        ))}
      {isMoveFolderModalOpen && (
        <CollectionsTreeModal
          showCurrentAndMyCollections
          collectionsTabTitle={collectionsTabTitle}
          handleSetModalActiveTab={handleSetModalActiveTab}
          collections={collectionsForMoveModal}
          treeModalType={TREE_MODAL_TYPE.move}
          isOpen={isMoveFolderModalOpen}
          currentCollection={currentCollection}
          onCancel={() => setIsMoveFolderModalOpen(false)}
          onSelect={handleMoveFolderToSelect}
          modalType="primary"
        />
      )}
    </>
  );
};

export default FolderFoldersContainer;
