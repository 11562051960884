import { useLazyQuery } from "@apollo/client";
import { Fragment, useState } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { defaultLanguageSelector } from "../../../../store/user/reducer";

import UserDrawer from "@modules/UserDrawer/UserDrawer";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import Modal from "@components/Modal/Modal";
import Pills from "@components/Pills/Pills";

import GET_USER_INFO from "@graphql/queries/getUserInfo";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { formatDate } from "@utils/dateHelper";
import { mapToOption } from "@utils/selectHelper";

import CloseIcon from "@images/closeModal.svg";

import { IFile } from "@feature/file/service/type";
import { DefaultOption } from "types/select";
import { IUser } from "types/user";

import styles from "./PreviewModal.module.scss";

interface IPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  file?: IFile;
}

interface IKeyToFormattedValue {
  displayName: string;
  originalFileName: string;
  uploadedBy: { name: string; email: string };
  uploadDate: string;
  categories: Array<DefaultOption>;
  lastUpdatedBy?: { name: string; email: string };
  lastUpdate: string;
}

export const PreviewModal = ({ isOpen, onClose, file }: IPreviewModalProps) => {
  const { t } = useTranslation();
  const defaultLanguage = useSelector(defaultLanguageSelector);

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUser && setUser(data.userInfo);
      setIsUserDrawerOpen(true);
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  if (!file) return <></>;

  const KeyToFormattedValue: IKeyToFormattedValue = {
    displayName: file.name,
    originalFileName: file.originalName,
    uploadedBy: { name: file.creator.fullName, email: file.creator.email },
    uploadDate: formatDate(new Date(file.creationTime), defaultLanguage),
    categories: file.interests.map(({ name }) =>
      mapToOption(name, t(`categories.type.${name}`))
    ),
    lastUpdatedBy: file.updatedBy && {
      name: file.updatedBy?.fullName,
      email: file.updatedBy?.email,
    },
    lastUpdate: formatDate(new Date(file.updateTime), defaultLanguage),
  };

  return (
    <>
      <Modal
        className={styles.previewDocumentModal}
        modalType="primary"
        isOpen={isOpen}
        onRequestClose={onClose}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <div></div>
            <ButtonUpdated
              title=""
              wrapperClassNames={styles.closeButtonWrapper}
              buttonShape="circle"
              onClick={onClose}
              buttonIcon={<img alt="" className={styles.removeIcon} src={CloseIcon} />}
              hasBorder={false}
            />
          </div>
          <div className={styles.grid}>
            {Object.keys(KeyToFormattedValue).map((key) => {
              const formatKey = key as keyof IKeyToFormattedValue;

              return (
                <Fragment key={key}>
                  <div>
                    <b>{t(`moreInfoModal.label.${formatKey}`)}:</b>
                  </div>
                  <div className={styles.valueContainer}>
                    {formatKey === "categories" ? (
                      <Pills pills={KeyToFormattedValue[formatKey]} />
                    ) : formatKey === "uploadedBy" || formatKey === "lastUpdatedBy" ? (
                      <span
                        className={styles.userLabel}
                        onClick={() => {
                          getUserInfo({
                            variables: {
                              email: KeyToFormattedValue[formatKey]!.email,
                            },
                          });
                          onClose();
                        }}
                      >
                        {KeyToFormattedValue[formatKey]!.name}
                      </span>
                    ) : (
                      <span className={styles.value}>
                        {KeyToFormattedValue[formatKey]}
                      </span>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className={styles.buttons}>
            <ButtonUpdated
              title={t("moreInfoModal.close")}
              wrapperClassNames={styles.closeButtonWrapper}
              onClick={onClose}
              customButtonType="danger"
            />
          </div>
        </div>
      </Modal>
      {user && isUserDrawerOpen && (
        <UserDrawer
          user={user}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={<></>}
        />
      )}
    </>
  );
};
