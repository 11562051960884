import Arrow from "@images/arrow.svg";
import styles from "./CommunityHeadbar.module.scss";

export interface ICommunityHeadbar {
  title?: string;
  selectedInterest?: string;
  searchInput: JSX.Element;
}

const CommunityHeadbar = ({
  title,
  selectedInterest,
  searchInput,
}: ICommunityHeadbar) => {
  return (
    <div className={styles.headbar} data-testid="container">
      <div className={styles.titleContainer}>
        {!selectedInterest ? (
          <span className={styles.title} aria-label="title">
            {title}
          </span>
        ) : (
          <span className={styles.title} aria-label="selected-interest">
            {title}
            <img src={Arrow} className={styles.arrowImg} aria-label="interest" />
            {selectedInterest}
          </span>
        )}
      </div>
      {searchInput}
    </div>
  );
};

export default CommunityHeadbar;
