import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import TooltipComponent from "@components/Tooltip/Tooltip";

import { ReactComponent as PrivateChip } from "@images/Chip.svg";
import EventPlaceholderImage from "@images/event_placeholder_image.svg";

import { formatDate } from "@utils/eventsHelper";
import { IEvent } from "types/event";

import styles from "./UpcomingCommunityEventCard.module.scss";

interface IUpcomingCommunityEventCard {
  isUserNotAllowed: boolean;
  event: IEvent;
  openEventPage?: (publicId: string) => void;
  renderImage?: (eventImageName?: string, imageDirectory?: string) => void;
  handleUserSelected?: (userMail: string) => void;
}

const UpcomingCommunityEventCard = ({
  isUserNotAllowed,
  event,
  openEventPage,
  renderImage,
  handleUserSelected,
}: IUpcomingCommunityEventCard) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleCardClick = () => {
    if (!event.publicId) {
      return history.push("/portal/events/explore");
    }

    return openEventPage!(event.publicId);
  };

  return (
    <div
      className={styles.cardWrapper}
      key={event.publicId}
      style={!isUserNotAllowed ? {} : { pointerEvents: "none" }}
      onClick={handleCardClick}
    >
      <div
        className={styles.leftWrapper}
        style={
          renderImage
            ? {
                backgroundImage: `url(${renderImage!(
                  event.eventImageName,
                  event.imageDirectory
                )})`,
              }
            : {
                backgroundImage: `url(${EventPlaceholderImage})`,
              }
        }
      >
        <div className={styles.dateWrapper}>
          <span className={styles.smallDate}>
            {formatDate(event?.startDate, i18n.language, "MMM").toUpperCase()}
          </span>
          <span className={styles.bigDate}>
            {formatDate(event?.startDate, i18n.language, "DD")}
          </span>
        </div>
      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.name}>{event.name}</div>
        <div className={styles.additionalInfo}>
          {event.publicId ? (
            <span className={styles.hostedBy}>
              {t("community.upcomingEvents.hostedBy")}
              <span
                style={!isUserNotAllowed ? {} : { pointerEvents: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserSelected!(event.eventHost.email);
                }}
              >
                {event.eventHost.fullName}
              </span>
            </span>
          ) : (
            <span className={styles.createEvent}>{event.eventHost.fullName}</span>
          )}
          {event.eventType === "private" && (
            <span className={styles.eventType}>
              <TooltipComponent
                tooltipType="secondary"
                title={t("community.upcomingEvents.privateEvent")}
                childToWrap={<PrivateChip />}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingCommunityEventCard;
