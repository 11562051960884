import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import AttachmentCard from "@components/AttachmentCard/AttachmentCard";
import ChatInput from "@components/ChatInput/ChatInput";
import ChatOptions from "@components/ChatOptions/ChatOptions";
import CommunityMessage from "@components/CommunityMessage/CommunityMessage";
import CommunityReplyMessage from "@components/CommunityReplyMessage/CommunityReplyMessage";
import EmojiPicker from "@components/EmojiPicker/EmojiPicker";
import UploadModal from "@components/UploadModal/UploadModal";
import { UploadModalType } from "@components/UploadModal/type";

import ChatSideMenu from "./ChatSideMenu/ChatSideMenu";

import sb from "@customHooks/sendBird/SendBird";
import {
  SendBirdHelper,
  SendBirdMessageHelper,
} from "@customHooks/sendBird/SendBirdHelper";
import { ADMIN } from "@customHooks/sendBird/constant";
import { useSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/useSendBirdGroupChannel";
import { useSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/useSendBirdGroupChannels";
import { useSendBirdMessage } from "@customHooks/sendBird/useSendBirdMessage/useSendBirdMessage";
import { useSendBirdMessageReply } from "@customHooks/sendBird/useSendBirdMessageReply/useSendBirdMessageReply";

import GET_USER from "@graphql/queries/users";

import { FileHelper } from "@utils/files/fileHelper";
import { CHAT_ROUTES } from "@utils/routes/chat";

import { MentionOption } from "types/SearchMessage";
import { IUserResponse } from "types/user";

import { SENDBIRD } from "@feature/reactGA/constant";

import { useHandleRouteChange } from "./useHandleRouteChange/useHandleRouteChange";

import { GroupDefaultMessage } from "./GroupDefaultMessage/GroupDefaultMessage";
import { Header } from "./Header/Header";

import Avatar from "@images/avatar.svg";

import styles from "./ChatPage.module.scss";

const CURSOR_INACTIVE = -1;

const ChatPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: sendBirdParamsId } = useParams<{ id: string }>();
  const { data: currentUser } = useQuery<IUserResponse>(GET_USER);

  const redirectToChatPage = useCallback(() => {
    history.replace(CHAT_ROUTES.CHAT);
  }, [history]);

  const onChannelDeleted = useCallback(
    (url: string) => {
      const isOnDeletedGroupChannelUrl =
        SendBirdHelper.getUrlWithoutPrefix(url) === sendBirdParamsId;
      if (isOnDeletedGroupChannelUrl) {
        history.replace(CHAT_ROUTES.CHAT);
      }
    },
    [history, sendBirdParamsId]
  );

  const sendBirdGroupChannels = useSendBirdGroupChannels({
    onChannelDeleted,
    onChannelNotFound: redirectToChatPage,
  });
  const {
    state: { selectedGroupChannel },
  } = sendBirdGroupChannels;

  const sendBirdGroupChannel = useSendBirdGroupChannel({
    groupChannel: selectedGroupChannel,
  });

  const sendBirdMessageHelper = useSendBirdMessage({
    groupChannel: selectedGroupChannel,
  });
  const {
    messageActions: { sendChannelMessage },
    messageState: { messages },
  } = sendBirdMessageHelper;

  const {
    chatMembers,
    isInputDisplayed,
    selectedUserToBlockEmail,
    userImages,
    otherUser,
    isDirectGroup,
    chatName,
    groupId,
  } = useHandleRouteChange({
    sendBirdGroupChannel,
    groupChannel: selectedGroupChannel,
  });

  const { parentMessageData, selectParentMessage, resetParentMessage } =
    useSendBirdMessageReply({
      groupChannel: selectedGroupChannel,
    });

  const [mentionedUserArray, setMentionedUserArray] = useState<MentionOption[]>([]);
  const [message, setMessage] = useState<string>("");
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [emoji, setEmoji] = useState(false);
  const [isEmojiPickerSubmitted, setIsEmojiPickerSubmitted] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(CURSOR_INACTIVE);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // TODO Refactor Emoji
  const addEmojiToMessage = (emoji: string) => {
    const newMessage = message.replace(
      new RegExp(`^(.{${cursorPosition}})(.*)`),
      `$1${emoji}$2`
    );
    inputRef.current?.focus();
    setMessage(newMessage);
    setCursorPosition(cursorPosition + emoji.length);
  };

  useEffect(() => {
    if (cursorPosition !== CURSOR_INACTIVE)
      inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (isEmojiPickerSubmitted) sendMessageOrFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmojiPickerSubmitted]);

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(CURSOR_INACTIVE);
    setEmoji(false);
    setMessage(event.target.value.trimStart());

    // if we delete part of mention option remove it from setMentionedUserArray
    if (mentionedUserArray.length > 0) {
      mentionedUserArray.forEach((mentionedUser, index) => {
        const mentionedOptionValue = `@${mentionedUser.value}`;
        const mentionedOptionValueIncluded = event.target.value
          .trimStart()
          .includes(mentionedOptionValue);

        !mentionedOptionValueIncluded &&
          setMentionedUserArray([...mentionedUserArray.slice(0, index)]);
      });
    }
  };

  const handleSubmitMessage = (event: FormEvent) => {
    event.preventDefault();
    if (!message.trim() || loading) return;
    sendMessageOrFile();
  };

  const sendMessageOrFile = () => {
    if (loading) return;
    if (selectedFile) sendFile();
    if (message) sendMessage();
    if (isEmojiPickerSubmitted) setIsEmojiPickerSubmitted(false);
  };

  const onError = () => setLoading(false);
  const sendMessage = () => {
    if (!sb || !message.trim()) return;
    setLoading(true);

    const params = new sb.UserMessageParams();
    params.message = message.trimStart();
    params.mentionType = "users";
    params.mentionedUserIds = mentionedUserArray.map(function (user) {
      return user.label;
    });
    if (parentMessageData) {
      params.parentMessageId = parentMessageData.messageId;
    }
    const onSuccess = () => {
      ReactGA.event(SENDBIRD.MESSAGE_SUCCESSFULLY_SENT);
      resetParentMessage();
      setMentionedUserArray([]);
      setMessage("");
      setLoading(false);
    };
    sendChannelMessage(params, onSuccess, onError);
  };

  const sendFile = () => {
    if (!selectedFile || !sb) return;
    setLoading(true);

    const fileMessageParams = SendBirdMessageHelper.getFileMessageParams(
      sb,
      selectedFile
    );

    const onSuccess = () => {
      ReactGA.event(SENDBIRD.MESSAGE_SUCCESSFULLY_SENT);
      setSelectedFile(undefined);
      setLoading(false);
    };
    sendChannelMessage(fileMessageParams, onSuccess, onError);
  };

  const removeSelectedParentMessage = () => {
    resetParentMessage();
    setMentionedUserArray([]);
  };

  const updateMentionedUserArray = (value: MentionOption) => {
    setMentionedUserArray([...mentionedUserArray, value]);
  };

  const lastMessageRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ block: "end", behavior: "auto" });
  }, [messages.length, selectedGroupChannel]);

  const messagesRendered = useMemo(() => {
    return messages.map((msg, index) => {
      const userAvatar =
        msg.messageType !== ADMIN
          ? userImages?.find((image) => image.email === msg?.sender?.userId)?.image
          : Avatar;

      return (
        <div
          key={msg.messageId}
          className={styles.message}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        >
          <CommunityMessage
            message={msg}
            sendBirdMessageHelper={sendBirdMessageHelper}
            sendBirdGroupChannel={sendBirdGroupChannel}
            groupChannel={selectedGroupChannel}
            selectParentMessage={selectParentMessage}
            image={userAvatar}
            currentUser={currentUser?.user}
            showReplyButton="showReplyButton"
          />
        </div>
      );
    });
  }, [
    messages,
    selectedGroupChannel,
    sendBirdGroupChannel,
    sendBirdMessageHelper,
    selectParentMessage,
    userImages,
    currentUser?.user,
  ]);

  return (
    <div className={styles.chatPage}>
      <UploadModal
        type={UploadModalType.ATTACMENTS}
        open={openFileModal}
        close={() => setOpenFileModal(false)}
        addFiles={(file: File[]) => setSelectedFile(file[0])}
      />
      <div className={styles.sideMenu}>
        <ChatSideMenu sendBirdGroupChannels={sendBirdGroupChannels} />
      </div>
      {groupId && (
        <div className={styles.chat}>
          <Header
            selectedGroupChannel={selectedGroupChannel}
            chatName={chatName}
            isDirectGroup={isDirectGroup}
            otherUser={otherUser}
            selectedUserToBlockEmail={selectedUserToBlockEmail}
            sendBirdGroupChannel={sendBirdGroupChannel}
            userImages={userImages}
          />

          <div className={!selectedFile ? styles.messenger : styles.chatInputWithFile}>
            <div className={styles.messages}>
              <GroupDefaultMessage
                selectedGroupChannel={selectedGroupChannel}
                sendBirdMessageHelper={sendBirdMessageHelper}
              />
              {messagesRendered}
            </div>

            <div className={styles.input}>
              {emoji && (
                <EmojiPicker
                  closeEmojiTable={() => {
                    setCursorPosition(CURSOR_INACTIVE);
                    setEmoji(false);
                  }}
                  addEmojiToMessage={addEmojiToMessage}
                  setIsEmojiPickerSubmitted={setIsEmojiPickerSubmitted}
                />
              )}
              {selectedFile && (
                <div className={styles.attachmentBox}>
                  <AttachmentCard
                    name={selectedFile.name}
                    size={FileHelper.getSizeInKB(selectedFile.size)}
                    removeFile={() => setSelectedFile(undefined)}
                  />
                </div>
              )}
              {parentMessageData && (
                <div className={styles.parentMessageBox}>
                  <CommunityReplyMessage
                    message={parentMessageData}
                    removeSelectedParentMessage={removeSelectedParentMessage}
                    unsetRemoveIcon={true}
                    currentUser={currentUser?.user}
                    beforeInput={true}
                  />
                </div>
              )}
              {isInputDisplayed && (
                <form onSubmit={handleSubmitMessage}>
                  <ChatInput
                    updateMessageOnMention={setMessage}
                    updateMentionedUserArray={updateMentionedUserArray}
                    optionsForMentions={chatMembers}
                    onChange={onInputChange}
                    onClick={sendMessageOrFile}
                    openEmojiPicker={() => {
                      if (inputRef.current && !emoji) {
                        setCursorPosition(inputRef.current?.selectionStart);
                      }
                      setEmoji(!emoji);
                    }}
                    value={message}
                    file={selectedFile}
                    name={selectedFile ? "inputWithAttachment" : "chat"}
                    placeholder={t("chat.chatInput.message", {
                      name: chatName ? chatName : "",
                    })}
                    inputName="inputChat"
                    openAttachments={() => setOpenFileModal(true)}
                    inputReference={inputRef}
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedGroupChannel && !isDirectGroup && (
        <ChatOptions groupChannel={selectedGroupChannel} />
      )}
    </div>
  );
};

export default ChatPage;
