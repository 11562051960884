import AddToCollectionModal from "@components/AddToCollectionModal/AddToCollectionModal";
import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";
import { CollectionType } from "@graphql/collection/type";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ArticleActionProps, IArticle } from "types/article";
import { ICommunity } from "types/community";

type LocationProps = {
  state: {
    newCollection?: string;
    newCollectionId?: string;
    collectionType: CollectionType;
    community?: ICommunity;
    currentLoadedPage?: number;
  };
};

export const ArticleOptions = ({ id, title }: IArticle) => {
  const location = useLocation() as LocationProps;
  const history = useHistory();
  const { t } = useTranslation();
  const [addedArticleToCollection, setAddedArticleToCollection] =
    useState<ArticleActionProps>();
  const [createdCollectionId, setCreatedCollectionId] = useState<string>();
  const [createdCollectionType, setCreatedCollectionType] = useState<string>();
  const [createdCollectionCommunity, setCreatedCollectionCommunity] =
    useState<ICommunity>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSelect = () => {
    setAddedArticleToCollection({ articleId: id, title });
    setIsModalOpen(true);
  };

  const options: IOption[] = [
    {
      label: t("collections.collectionsActions.addToCollectionMenuHelper"),
      action: handleSelect,
    },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAddedArticleToCollection(undefined);
    setCreatedCollectionId(undefined);
    history.replace({ state: undefined });
  };

  useEffect(() => {
    if (location.state?.newCollection && location.state.newCollection === id) {
      setAddedArticleToCollection({
        articleId: location.state.newCollection,
        title: "",
      });
      setCreatedCollectionId(location.state.newCollectionId);
      setCreatedCollectionType(location.state.collectionType);
      setCreatedCollectionCommunity(location.state.community);
      setTimeout(() => setIsModalOpen(true), 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Menu options={options} />
      <AddToCollectionModal
        articleId={addedArticleToCollection?.articleId || ""}
        newCollectionId={createdCollectionId}
        newCollectionType={createdCollectionType}
        newCollectionCommunity={createdCollectionCommunity}
        redirectToAfterAddNewCollection="/portal/dashboard"
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};
