export const NUMBER_OF_FILES_TO_DISPLAY = 4;
export const NUMBER_OF_MEMBERS_TO_DISPLAY = 3;
export const COMMUNITY_CREATED = "communityCreated";
export const ADDED_TO_COMMUNITY = "addedToCommunity";
export const ADDED_TO_EXISTING_COMMUNITY = "memberInvitedToCommunity";
export const REMOVED_FROM_COMMUNITY = "removedFromCommunity";
export const JOIN_PRIVATE_COMMUNITY_REQUESTED = "joinPrivateCommunityRequested";
export const JOIN_PRIVATE_COMMUNITY_ACCEPTED = "joinPrivateCommunityAccepted";
export const JOIN_PRIVATE_COMMUNITY_DECLINED = "joinPrivateCommunityDeclined";
export const CANCEL_JOIN_PRIVATE_COMMUNITY_REQUEST = "cancelJoinPrivateCommunityRequest";
export const BANNED_FROM_COMMUNITY = "bannedFromCommunity";
export const MEMBER_IN_COMMUNITY_PROMOTED = "memberInCommunityPromoted";
export const ADMIN_IN_COMMUNITY_DEMOTED = "adminInCommunityDemoted";
export const IS_HIDDEN = "is_hidden";
export const JOINED_TO_COMMUNITY = "joinedToCommunity";
export const UPDATED_COMMUNITY_CHANNELS = "updatedCommunityChannels";
export const DELETED_COMMUNITY_SUBSCRIPTION = "communityDeleted";

export const DELETED_COLLECTION_SUBSCRIPTION = "deletedCollection";
export const DELETED_ARTICLE_FROM_COLLECTION_SUBSCRIPTION =
  "deletedArticleFromCollection";
