export const getEventTypeFromResponse = (param: string) => {
  switch (param) {
    case "Hive":
      return "hive";
    case "Private":
      return "private";
    case "Public":
      return "public";
    default:
      return "private";
  }
};
