/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import BAN_USER_FROM_COMMUNITY from "@graphql/mutations/banUser";
import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBanUserRequest, ICommunityResponse } from "types/community";
import { IUseCommunityService } from "types/useCommunityServiceType";
import { useCommunityHelper } from "./useCommunityHelper";

export const useCommunityService = (
  publicId: string,
  email: string
): IUseCommunityService => {
  const { t } = useTranslation();
  const {
    communityActions: { setSendBirdId, removeMember },
    communityHelpers: { isAbleToManageUser, isMember },
    communityState: { admins, creator, regularMembers },
  } = useCommunityHelper();
  const [banUserRequest, { loading: isBanningUser }] = useMutation<
    ICommunityResponse,
    IBanUserRequest
  >(BAN_USER_FROM_COMMUNITY, {});
  const [isBannable, setIsBannable] = useState(false);

  const banUser = (sendBirdId: string) => {
    banUserRequest({
      variables: {
        banRequest: {
          sendbirdId: sendBirdId,
          userToBanPublicId: publicId,
        },
      },
    })
      .then(() => {
        removeMember(email);
        displaySuccessNotification(
          t("community.administration.messages.successfullyBlockedMember")
        );
      })
      .catch((error) => {
        displayNotSuccessNotification(error);
      });
  };

  useEffect(() => {
    setIsBannable(isAbleToManageUser(email) && isMember(email) && !isBanningUser);
  }, [admins, creator, regularMembers, isBanningUser]);

  return {
    banUserFromCommunity: {
      banUser,
      isBannable,
    },
    setSendBirdId,
  };
};
