import { gql } from "@apollo/client";

const GET_PLATFORM_STATISTICS = gql`
  query platformStatistics {
    platformStatistics {
      membersCountriesCount
      availableLanguagesCount
      viewsCount
    }
  }
`;

export { GET_PLATFORM_STATISTICS };
