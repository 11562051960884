/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSubscription } from "@customHooks/useSubscription";
import getStorageImage from "@utils/getStorageImage";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Subscription } from "../../customContext/subscriptions/enum";
import { leaveCommunityAction } from "../../store/community/actions";
import { leftCommunitySelector } from "../../store/community/reducer";
import TextTruncated from "../TextTruncated/TextTruncated";
// components
import Button from "@components/Button/Button";
import TooltipComponent from "@components/Tooltip/Tooltip";
// types
import { CommunityType, ICommunity } from "types/community";
import { IJoinRequest } from "types/joinRequest";
import { IUser, IUserImageResponse } from "types/user";
// constants
import { COMMUNITY_NAME_MAX_LENGTH, STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { defaultImages } from "@modules/NewCommunity/constants";
// queries
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
// mutations
import JOIN_CLOSED_COMMUNITY from "@graphql/mutations/joinClosedCommunity";
import JOIN_OPEN_COMMUNITY from "@graphql/mutations/joinOpenCommunity";
// images and icons
import AvatarIcon from "@images/avatar.svg";
import ClosedCommunityIcon from "@images/closedCommunityIcon.svg";
import OpenCommunityIcon from "@images/openCommunityIcon.svg";
import PrivateCommunityIcon from "@images/privateCommunityIcon.svg";
// scss
import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import styles from "./CommunityCard.module.scss";

export interface ICommunityCard {
  community: ICommunity;
  loggedUser: string;
  "aria-label"?: string;
  inUserDrawer?: boolean;
}

const CommunityCard = ({
  community,
  loggedUser,
  inUserDrawer,
  ...props
}: ICommunityCard) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sb } = useSendBirdContext();
  const communityUserHasLeft = useSelector(leftCommunitySelector);
  const [joinClosedCommunity] = useMutation(JOIN_CLOSED_COMMUNITY);
  const [joinOpenCommunity] = useMutation(JOIN_OPEN_COMMUNITY);
  const [isJoinClosedCommunityRequested, setIsJoinClosedCommunityRequested] =
    useState<boolean>(false);
  const [isCommunityMember, setIsCommunityMember] = useState<boolean>(false);
  const [joinOpenLoading, setJoinOpenLoading] = useState<boolean>(false);
  const [isRequestingToJoin, setIsRequestingToJoin] = useState<boolean>(false);
  const [numberOfCommunityMembers, setNumberOfCommunityMembers] = useState<number>(0);
  const [userImage, setUserImage] = useState<string>();

  const { removeCommunityNotificationsRelatedToCommunity } =
    useCommunityNotificationsHelper();

  const checkIfLoggedUserIsAlreadyMember = () => {
    return (
      community.members.some((member: IUser) => member.email === loggedUser) ||
      community.admins.some((member: IUser) => member.email === loggedUser) ||
      community.communityCreator.email === loggedUser
    );
  };

  useSubscription(
    Subscription.JOINED_COMMUNITY,
    (communityNotificationResponse: Array<string>) => {
      if (communityNotificationResponse[0] === community.sendbirdId) {
        setIsCommunityMember(true);
        setJoinOpenLoading(false);
      }
    }
  );

  useEffect(() => {
    community &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: community?.communityCreator?.email,
          },
        },
      });

    if (!community.communityCreator && community.sendbirdId) {
      displayServerError(
        t("exploreCommunities.errorCommunityId", {
          sendbirdId: community.sendbirdId,
        })
      );
    }
    setIsCommunityMember(checkIfLoggedUserIsAlreadyMember);
    setNumberOfCommunityMembers(community.admins.length + community.members.length + 1);
    setIsJoinClosedCommunityRequested(
      community.joinRequests.some((e: IJoinRequest) => e.user.email === loggedUser)
    );
  }, [community]);

  const handleJoinOpenCommunity = (event: React.MouseEvent) => {
    event.stopPropagation();
    setJoinOpenLoading(true);
    handleLeaveStore();

    joinOpenCommunity({
      variables: { sendbirdId: community.sendbirdId },
    })
      .then(() => {
        setNumberOfCommunityMembers(numberOfCommunityMembers + 1);
        setIsCommunityMember(true);
      })
      .catch(() => displayServerError())
      .finally(() => setJoinOpenLoading(false));
  };

  const handleLeaveStore = () => {
    if (community.name === communityUserHasLeft) dispatch(leaveCommunityAction(""));
  };

  const requestJoinClosedCommunity = () => {
    setIsRequestingToJoin(true);
    handleLeaveStore();
    joinClosedCommunity({
      variables: {
        sendbirdId: community.sendbirdId,
      },
    })
      .then(() => {
        setIsJoinClosedCommunityRequested(true);
      })
      .catch(() => displayServerError())
      .finally(() => setIsRequestingToJoin(false));
  };

  const handleJoinClosedCommunity = (event: React.MouseEvent) => {
    event.stopPropagation();

    requestJoinClosedCommunity();
  };

  const handleLeaveCommunity = (event: React.MouseEvent) => {
    if (!sb) return;
    event.stopPropagation();

    sb.GroupChannel.getChannel(community.sendbirdId, function (groupChannel, error) {
      if (error) {
        displayServerError(error);
        return;
      }

      groupChannel.leave(function (response, error) {
        if (error) {
          displayServerError(error);
          return;
        }
        setIsCommunityMember(false);
        setNumberOfCommunityMembers(numberOfCommunityMembers - 1);
        removeCommunityNotificationsRelatedToCommunity(community.sendbirdId);
      });
    });
  };

  const handleRedirectToCommunity = (communityType: CommunityType) => {
    if (communityType === CommunityType.private && !isCommunityMember) return;

    history.push(`/portal/community/community-page/${community.sendbirdId}`);
  };

  const handleStaticCommunityBannerUrl = () => {
    const localBanner = community.communityBannerUrl
      ?.split("/")
      .find((substring) => substring.includes("community-banner-"));
    const extractedLocalImageName =
      localBanner && localBanner.substring(0, localBanner.indexOf("."));
    const imageOrderNumber =
      extractedLocalImageName &&
      parseInt(extractedLocalImageName.substring(extractedLocalImageName.length - 1));
    if (imageOrderNumber) {
      return defaultImages[imageOrderNumber - 1].image;
    } else {
      return community.communityBannerUrl;
    }
  };

  const handleStaticImageUrl = () => {
    const localBanner = community.communityImageUrl
      ?.split("/")
      .find((substring) => substring.includes("community-banner-"));
    const extractedLocalImageName =
      localBanner && localBanner.substring(0, localBanner.indexOf("."));
    const imageOrderNumber =
      extractedLocalImageName &&
      parseInt(extractedLocalImageName.substring(extractedLocalImageName.length - 1));
    if (imageOrderNumber) {
      return defaultImages[imageOrderNumber - 1].image;
    } else {
      return community.communityImageUrl;
    }
  };

  const renderJoinOpenCommunityButton = () => {
    if (community.communityCreator.email === loggedUser) {
      return (
        <Button
          aria-label="Leave Community"
          e2eTestId="joined-community-button"
          disabled
          title={t("exploreCommunities.joined")}
          buttonType="primary"
        />
      );
    }

    if (isCommunityMember) {
      return (
        <Button
          aria-label="Leave Community"
          e2eTestId="joined-community-button"
          onClick={handleLeaveCommunity}
          title={t("exploreCommunities.joined")}
          buttonType="primary"
          disabled
        />
      );
    }

    return (
      <Button
        loading={joinOpenLoading}
        aria-label="Join Open Community"
        e2eTestId="join-community-button"
        onClick={handleJoinOpenCommunity}
        title={t("exploreCommunities.joinGroup")}
      />
    );
  };

  const renderJoinClosedCommunityButton = () => {
    if (isJoinClosedCommunityRequested) {
      return (
        <Button
          aria-label="Cancel Request Join Closed Community"
          e2eTestId="requested-to-join-community-button"
          disabled={isJoinClosedCommunityRequested}
          title={t("exploreCommunities.requested")}
        />
      );
    }

    return (
      <Button
        aria-label="Request Join Closed Community"
        e2eTestId="request-to-join-community-button"
        onClick={handleJoinClosedCommunity}
        loading={isRequestingToJoin}
        title={t("exploreCommunities.requestAccess")}
      />
    );
  };

  const renderJoinCommunityButton = () => {
    if (community.communityType === CommunityType.private && !isCommunityMember) {
      return <div className={styles.emptyButton} />;
    }
    if (!isCommunityMember && community.communityType === CommunityType.closed) {
      return renderJoinClosedCommunityButton();
    }

    return renderJoinOpenCommunityButton();
  };

  const showCommunityTypeText = () => {
    return community?.communityType === CommunityType.private
      ? "community.communityType.privateWithDescription"
      : community?.communityType === CommunityType.open
      ? "community.communityType.openWithDescription"
      : "community.communityType.closedWithDescription";
  };

  const showCommunityType = () => {
    return community?.communityType === CommunityType.private
      ? PrivateCommunityIcon
      : community?.communityType === CommunityType.open
      ? OpenCommunityIcon
      : ClosedCommunityIcon;
  };

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo.userPhotoName,
          directoryName: userPhotosInfo.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  return (
    <div
      onClick={() => handleRedirectToCommunity(community.communityType!)}
      style={
        community.communityType === CommunityType.private && !isCommunityMember
          ? { cursor: "default" }
          : { cursor: "pointer" }
      }
      className={`${styles.card} ${inUserDrawer ? styles.inUserDrawer : ""}`}
      aria-label={props["aria-label"]}
      e2e-test-id="community-card-large"
    >
      <div
        style={{ backgroundImage: `url(${handleStaticCommunityBannerUrl()})` }}
        className={styles.coverImg}
        e2e-test-id="community-card-banner-image-container"
      ></div>
      <div className={styles.cardBottom}>
        <div className={styles.topRow}>
          <div
            style={{ backgroundImage: `url(${handleStaticImageUrl()})` }}
            className={styles.bannerImg}
            e2e-test-id="community-card-profile-image-container"
          ></div>
          <div className={styles.subRow}>
            <div
              style={{ backgroundImage: `url(${userImage || AvatarIcon})` }}
              className={styles.img}
              e2e-test-id="community-creator-image-container"
            ></div>
            <span
              className={styles.memberCount}
              e2e-test-id="community-members-count"
            >{`${numberOfCommunityMembers} ${
              numberOfCommunityMembers > 1
                ? t("exploreCommunities.members")
                : t("exploreCommunities.member")
            } `}</span>
          </div>
        </div>
        <span className={styles.title} e2e-test-id="community-name">
          <TextTruncated
            textToTruncate={community.name}
            length={COMMUNITY_NAME_MAX_LENGTH}
          />
        </span>
        <div className={styles.bottomRow}>
          <TooltipComponent
            title={t(showCommunityTypeText())}
            tooltipType="secondary"
            placement="bottom"
            childToWrap={
              <div
                style={{ backgroundImage: `url(${showCommunityType()})` }}
                className={styles.communityType}
                e2e-test-id="community-type-icon"
              ></div>
            }
          />
          {renderJoinCommunityButton()}
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
