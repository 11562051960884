import { useTranslation } from "react-i18next";

import { ModeToClassName } from "./constant";
import { mode } from "./type";

import styles from "./Feedback.module.scss";

interface IFeedback {
  translationKey: string;
  mode?: mode;
}

export const Feedback = ({ translationKey, mode = "danger" }: IFeedback) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} ${ModeToClassName[mode]}`}>
      <span>{t(translationKey)}</span>
    </div>
  );
};
