/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Modal from "@components/Modal/Modal";
import { ReactComponent as CloseModalIcon } from "@images/exit.svg";

import { IOptions } from "@components/AddToCollectionModal/AddToCollectionModal";
import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import Loading from "@components/Loading/Loading";
import Select from "@components/Select/Select";
import CHANGE_COMMUNITY_CREATOR from "@graphql/mutations/changeCommunityCreator";
import { GET_COMMUNITY_FOR_CHANGE_CREATOR_MODAL } from "@graphql/queries/communityModalChangeCreator";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { ICommunityResponse } from "types/community";
import styles from "./ChangeCreatorModal.module.scss";

interface IChangeCreatorModal {
  modalData: {
    communityId: string;
    creatorId: string;
    communityName: string;
  };
  open: boolean;
  refetchCommunities: () => void;
  close: () => void;
}

const ChangeCreatorModal = ({
  modalData,
  open,
  refetchCommunities,
  close,
}: IChangeCreatorModal) => {
  const { t } = useTranslation();

  const [communityCreator, setCommunityCreator] = useState<{ name: string; id: string }>({
    name: "",
    id: "",
  });

  const [communityUsers, setCommunityUsers] = useState<IOptions[]>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const [getCommunity, { loading }] = useLazyQuery<ICommunityResponse>(
    GET_COMMUNITY_FOR_CHANGE_CREATOR_MODAL,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ community }) => {
        setCommunityCreator({
          name: community.communityCreator.fullName,
          id: community.communityCreator.publicId,
        });

        const allAdmins = community.admins.map((admin) => ({
          value: admin.publicId,
          label: admin.fullName,
        }));

        const allMembers = community.members.map((member) => ({
          value: member.publicId,
          label: member.fullName,
        }));

        setCommunityUsers([
          {
            value: community.communityCreator.publicId,
            label: community.communityCreator.fullName,
          },
          ...allAdmins,
          ...allMembers,
        ]);
      },
      onError: (error) => displayServerError(error),
    }
  );

  const [createCommunity, { loading: changingCreatorLoading }] = useMutation(
    CHANGE_COMMUNITY_CREATOR,
    {
      onCompleted: () => {
        displaySuccessNotification(
          t("adminPanel.communities.modal.successCreatorChange", {
            userName: communityCreator.name,
            communityName: modalData.communityName,
          })
        );
        refetchCommunities();
        close();
      },
      onError: () => {
        close();
        displayNotSuccessNotification();
      },
    }
  );

  const handleChangeCommunityCreator = () => {
    createCommunity({
      variables: {
        changeCommunityCreator: {
          sendbirdId: modalData.communityId,
          newCommunityCreatorPublicId: communityCreator.id,
        },
      },
    });
  };

  useEffect(() => {
    getCommunity({
      variables: {
        sendbirdId: modalData.communityId,
      },
    });
  }, []);

  useEffect(() => {
    if (communityCreator.id)
      setIsSaveDisabled(modalData.creatorId === communityCreator.id);
  }, [communityCreator]);

  return (
    <Modal
      className={styles.modal}
      onRequestClose={close}
      isOpen={open}
      modalType="primary"
    >
      <div className={styles.titleContainer}>
        <h4 aria-label="modal-title" className={styles.title}>
          {t("adminPanel.communities.modal.title")}
        </h4>
        <CloseModalIcon className={styles.exit} onClick={close} />
      </div>
      {loading ? (
        <Loading disableBoxShadow />
      ) : (
        <>
          <Input
            name="community-name"
            label="Community Name"
            value={modalData.communityName}
            disabled
          />
          <Select
            label="User"
            options={communityUsers}
            onChange={(option: IOptions) =>
              setCommunityCreator({ name: option.label, id: option.value })
            }
            placeholder="neki user"
            name="community-creator"
            value={{ value: communityCreator.id, label: communityCreator.name }}
            defaultSelect={{ value: communityCreator.id, label: communityCreator.name }}
            isSearchable
            showDropdown
          />
        </>
      )}

      <div className={styles.buttonContainer}>
        <Button
          title={t("adminPanel.communities.modal.cancel")}
          buttonType="link"
          onClick={close}
          aria-label="cancel-btn"
        />
        <Button
          title={t("adminPanel.communities.modal.save")}
          buttonType="primary"
          onClick={handleChangeCommunityCreator}
          disabled={isSaveDisabled}
          loading={changingCreatorLoading}
          aria-label="cancel-btn"
        />
      </div>
    </Modal>
  );
};

export default ChangeCreatorModal;
