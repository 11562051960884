import { ICollection, INewFolder } from "@graphql/collection/type";
import { sortByNumber, sortByString } from "@utils/helpers";
import { IArticle } from "types/article";
import { IFile } from "types/file";
import { ILink } from "types/link";

export const DEFAULT_SLIDER_PAGE_SIZE = 4;
export const SENDBIRD_ID_PREFIX = "sendbird_group_channel_";
export const MAX_INPUT_LENGTH = 1000;
export const MAX_INPUT_LENGTH_MID = 250;
export const MAX_INPUT_LENGTH_SHORT = 100;
export const EVENTS_GRID_SIZE = 16;
export const ARTICLES_GRID_SIZE = 12;
export const ARTICLES_DASHBOARD_SIZE = 12;
export const COLLECTIONS_GRID_SIZE = 16;
export const BUTTON_URL_LIMIT = 50;
export const DEFAULT_COMMUNITIES_GRID_SIZE = 16;
export const ADMIN = "Admin";
export const MAX_TEXT_AREA_LENGTH = 500;
export const ALL_DISCUSSIONS = "All Discussions";
export const ALL_DISCUSSIONS_URL = "all_discussions";
export const ALL_DISCUSSIONS_PARAM = "all_discussions";
export const TAB_NAME = "data-tab-name";
export const RECENT_TAB = "recent";
export const ONE_SECOND_IN_MS = 1000;
export const ACTIVITIES_GRID_SIZE = 8;
export const WHO_PAGE = "https://www.who.int";
export const MAX_RECENT_DISCUSSIONS = 5;

export const COMMUNITY_GUIDLINES_CHECK_TEXT_ONE =
  "communityGuidlines.contents.sectionOneContentOne";
export const COMMUNITY_GUIDLINES_CHECK_TEXT_TWO =
  "communityGuidlines.contents.contentThree";
export const COMMUNITY_GUIDLINES_ITEMS = [
  "itemsOne",
  "itemsTwo",
  "itemsThree",
  "itemsFour",
];

export const TC_ITEMS = ["itemOne", "itemTwo", "itemThree"];

export const TC_ITEMS_LAST = [
  "itemOne",
  "itemTwo",
  "itemThree",
  "itemFour",
  "itemFive",
  "itemSix",
  "itemSeven",
];

export const TC_SECOND_SECTION_ITEMS = ["itemOne", "itemTwo"];
export const NUMBER_OF_USERS_PER_PAGE = 100;
export const SEARCH_TRIGGER = 1;

export enum USER_INVITED_STATUS {
  MEMBER = "MemberInvited",
  ADMIN = "AdminInvited",
}

export const NO_ACCESS_CODE = 400108;
export const USER_NOT_MEMBER = "1002";
export const FILE_WITH_NAME_EXISTS_IN_COLLECTION_ERROR_CODE = "2500";

export const BYTES_IN_MB = 1048576;
export const MAX_FILE_SIZE_IN_MB = 10;

export const UTC_OFFSET_INT_DATE_LINE_WEST = {
  name: "UTC-12:00",
  offsetValue: 720,
};

export const COMMUNITIES_SLIDES_PAGE_SIZE = DEFAULT_SLIDER_PAGE_SIZE;

export const MY_COLLECTIONS_TYPE = "MYCOLLECTIONS";
export const EXPLORE_COLLECTIONS_TYPE = "EXPLORECOLLECTIONS";
export const EXPLORE_MORE_COLLECTIONS_TYPE = "EXPLOREMORECOLLECTIONS";
export const FOLLOWED_COLLECTIONS_TYPE = "FOLLOWEDCOLLECTIONS";
export const STORAGE_IMAGE_TYPE_COLLECTION = "COLLECTION";
export const STORAGE_IMAGE_TYPE_EVENT = "EVENT";
export const STORAGE_IMAGE_TYPE_COMMUNITY = "COMMUNITY";
export const STORAGE_IMAGE_TYPE_USER = "USER";

export const SORTING_MOST_ACTIVE = "MOST_ACTIVE";
export const SORTING_MOST_POPULAR = "MOST_POPULAR";
export const SORTING_MOST_RECENT = "MOST_RECENT";
export const SORTING_MOST_RELEVANT = "MOST_RELEVANT";
export const SORTING_A_Z = "ALPHABETICAL_ATO_Z";
export const SORTING_Z_A = "ALPHABETICAL_ZTO_A";
export const ADDED_ACTIVITY = "added";
export const REMOVED_ACTIVITY = "removed";

export const FLAG_ARTICLE_INTERESTING = "Interesting";
export const FLAG_ARTICLE_INAPPROPRIATE = "Inappropriate";
export const FLAG_ARTICLE_HIDDEN = "Hidden";

export enum TREE_MODAL_TYPE {
  goTo = "go",
  move = "move",
}

export const ARTICLE_COMMENT_MAX_LENGTH = 500;

export const FEEDBACK_MAX_LENGTH = 2000;
export const FEEDBACK_SUBJECT_MAX_LENGTH = 50;

export const INITIAL_START_PAGE = 1;
export const INITIAL_NUMBER_OF_ROWS = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_OPTIONS = ["10", "20"];

export const COLLECTION_TYPES = {
  PUBLIC_TYPE: "public",
  PRIVATE_TYPE: "private",
  GLOBAL_CURATED_TYPE: "globalCurated",
};

export const COLLECTION_TYPES_IDS = {
  PUBLIC_TYPE: 1,
  PRIVATE_TYPE: 2,
  GLOBAL_CURATED_TYPE: 3,
};

interface IArticlesSorting {
  [key: string]: {
    self: string;
    value: keyof IArticle;
  };
}

interface IFoldersSorting {
  [key: string]: {
    self: string;
    value: keyof INewFolder;
  };
}

interface IFileLinkSorting {
  [key: string]: {
    self: string;
    value: keyof ILink & keyof IFile;
  };
}

interface ICollectionSorting {
  sort: (collectionA: ICollection, collectionB: ICollection) => number;
}

export interface ISortValue {
  value: string;
  label: string;
}

export const sortingCollectionsObj: Record<string, ICollectionSorting> = {
  [SORTING_MOST_RECENT]: {
    sort: sortByNumber(({ updateTime }) => updateTime, false),
  },
  [SORTING_MOST_ACTIVE]: {
    sort: sortByNumber(({ activitiesCount }) => activitiesCount, false),
  },
  [SORTING_A_Z]: {
    sort: sortByString(({ name }) => name, true),
  },
  [SORTING_Z_A]: {
    sort: sortByString(({ name }) => name, false),
  },
};

export const sortingArticlesObj: IArticlesSorting = {
  [SORTING_MOST_RECENT]: {
    self: SORTING_MOST_RECENT,
    value: "published",
  },
  [SORTING_A_Z]: {
    self: SORTING_A_Z,
    value: "title",
  },
  [SORTING_Z_A]: {
    self: SORTING_Z_A,
    value: "title",
  },
};

export const sortingFoldersObj: IFoldersSorting = {
  [SORTING_MOST_RECENT]: {
    self: SORTING_MOST_RECENT,
    value: "updateTime",
  },
  [SORTING_A_Z]: {
    self: SORTING_A_Z,
    value: "name",
  },
  [SORTING_Z_A]: {
    self: SORTING_Z_A,
    value: "name",
  },
};

export const sortingFileLinks: IFileLinkSorting = {
  [SORTING_MOST_RECENT]: {
    self: SORTING_MOST_RECENT,
    value: "updateTime",
  },
  [SORTING_A_Z]: {
    self: SORTING_A_Z,
    value: "name",
  },
  [SORTING_Z_A]: {
    self: SORTING_Z_A,
    value: "name",
  },
};

export const REACTION_TYPE = "like";

export const LANGUAGES = [
  { value: "English", label: "English", key: "en" },
  { value: "Korean", label: "Korean", key: "ko" },
  { value: "Spanish", label: "Spanish", key: "es" },
  { value: "French", label: "French", key: "fr" },
  { value: "Russian", label: "Russian", key: "ru" },
  { value: "Chinese", label: "Chinese", key: "zh" },
  { value: "Arabic", label: "Arabic", key: "ar" },
];

export const GLOBAL_LOCATION = "Global/Worldwide";

export const NO_COMMUNITIES_MESSAGES = {
  noCommunities: "exploreCommunities.noCommunities",
  noSearchedCommunities: "exploreCommunities.noSearchResult",
};

export const NO_COLLECTIONS_MESSAGES = {
  noCollections: "collections.searchCollections.noCollections",
  noSearchedCollections: "collections.searchCollections.noSearchResult",
};

export const NO_EVENTS_MESSAGES = {
  noEvents: "events.noEvents",
  noSearchedEvents: "events.noSearchResult",
};

export const NO_USERS_MESSAGES = {
  noUsers: "users.noUsers",
  noSearchedUsers: "users.noSearchResult",
};

export const SECOND_SECTION_CHECK_TEXT = "termsAndCoditions.contents.contentSix";

export const TABLE_CHECK_TEXT = "termsAndCoditions.contents.tableContent";

export const SECTION_THREE_CHECK_TEXT = "lastContent";
export const LAST_SECTION_CHECK_TEXT = "lastSectionContent";

export const tableContent = {
  header: [
    "termsAndCoditions.tableContent.header.h1",
    "termsAndCoditions.tableContent.header.h2",
    "termsAndCoditions.tableContent.header.h3",
    "termsAndCoditions.tableContent.header.h4",
  ],
  body: [
    [
      "termsAndCoditions.tableContent.body.b1",
      "termsAndCoditions.tableContent.body.b2",
      "termsAndCoditions.tableContent.body.b3",
      [
        "termsAndCoditions.tableContent.body.b4",
        "termsAndCoditions.tableContent.body.b5",
        "termsAndCoditions.tableContent.body.b6",
      ],
    ],
  ],
};

export const ALL_COLLECTIONS = "allCollections";
export const CREATED_BY_ME = "createdByMe";
export const SHARED_WITH_ME = "sharedWithMe";
export const PUBLIC = "public";
export const PRIVATE = "private";
export const GLOBAL_CURATED = "globalCurated";

export const COLLECTION_CONTAINER = "collectionContainer";

export const DASHBOARD_URL = "/portal/dashboard";
export const COLLECTION_URL = "/portal/collection";
export const RESOURCES_URL = "/portal/resources";

export const COMMUNITY_PAGINATION_SIZE = 10;
export const COLLECTION_PAGINATION_SIZE = 9;
export const INITIAL_PAGINATION = 0;
export const MANAGED_BY_ME_PAGINATION = 4;

export const COLLECTION = "collection";
export const COMMUNITY = "community";

export const COMMUNITIES_PAGINATION = 12;
export const RECOMMENDED_COMMUNITIES_EMPTY_SEARCH = "";

export const COMMUNITY_NAME_MAX_LENGTH = 30;

export const COMMUNITY_DEFAULT_COLLECTION_NAME = "Community Resources";
export const COMMUNITY_DEFAULT_COLLECTION_DEFAULT_FOLDER_NAME =
  "Uploaded Discussion Media";

export const NOTIFICATIONS_PAGINATION_SIZE = 10;

export const PDF_CONTENT_TYPE = "application/pdf";
export const IMAGE_CONTENT_TYPE = "image/jpg";
export const PDF_FOLDER_NAME = "/PDF";
export const PDF_EXTENSION = ".pdf";
export const PDF = "pdf";
export const OPEN_PUBLIC_EVENTS_PAGINATION_SIZE = 9;
