import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/RootState";

import CommmunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";
import CommunitiesSectionIcon from "@images/ProfileIcon.svg";
import { disableNext } from "@utils/helpers";
import SlickSlider from "react-slick";
import { ICommunity } from "types/community";

import styles from "./ResourcesCommunitySection.module.scss";

import { NO_COMMUNITIES_MESSAGES } from "@constants/constants";
import { isDocumentRtl } from "@utils/checkDocumentDir";

const { noCommunities, noSearchedCommunities } = NO_COMMUNITIES_MESSAGES;

export interface IResourcesCommunitySection {
  communities: Array<ICommunity>;
  communitiesCount: number;
  communitiesLoading: boolean;
  loadCommunities: () => void;
  hideSeeAllButton?: boolean;
  customHeaderTitle?: string;
  pageSize: number;
  customSeeAll?: () => void;
  searchResourcesSubmitted?: boolean;
}

const ResourcesCommunitySection = ({
  communities,
  communitiesCount,
  communitiesLoading,
  loadCommunities,
  hideSeeAllButton,
  customHeaderTitle,
  pageSize,
  customSeeAll,
  searchResourcesSubmitted,
}: IResourcesCommunitySection) => {
  const communityChildRef = useRef<SlickSlider>(null);
  const { t } = useTranslation();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const history = useHistory();
  const [sliderPage, setSliderPage] = useState<number>(0);
  const [noCommunitiesMessage, setNoCommunitiesMessage] = useState<string>("");

  const openAllCommunities = () => {
    customSeeAll ? customSeeAll() : history.push(`/portal/resources/communities`);
  };

  const handlePreviousCommunities = () => {
    isDocumentRtl()
      ? communityChildRef.current?.slickNext()
      : communityChildRef.current?.slickPrev();
    setSliderPage(sliderPage - 1);
  };

  const handleNextCommunities = () => {
    isDocumentRtl()
      ? communityChildRef.current?.slickPrev()
      : communityChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
    if (communities.length >= communitiesCount) {
      return;
    } else {
      loadCommunities();
    }
  };

  const disableSliderNext = () => {
    return communitiesLoading || disableNext(sliderPage, communitiesCount, pageSize);
  };

  const disableSliderPrevious = () => {
    return communitiesLoading || sliderPage === 0;
  };

  useEffect(() => {
    setNoCommunitiesMessage(
      searchResourcesSubmitted ? noSearchedCommunities : noCommunities
    );
  }, [searchResourcesSubmitted]);

  return (
    <div
      className={styles.communitiesContainer}
      aria-label="communities-wrapper"
      e2e-test-id="communities-container"
    >
      <div className={styles.communitiesHeading}>
        {window.location.pathname.includes("/portal/community/explore") && (
          <h3 className={styles.communitiesRecommendationTitle}>
            {t("exploreCommunities.recommendedTitle")}
          </h3>
        )}
        <div className={styles.communitiesHeadingWrapper}>
          {!customHeaderTitle && (
            <img className={styles.communitiesIcon} src={CommunitiesSectionIcon} alt="" />
          )}
          <h3 className={styles.communitiesTitle}>
            {customHeaderTitle || t("resources.communitiesTitle")}
          </h3>
        </div>
        {communities.length !== 0 && (
          <div
            className={styles.communitiesButtonContainer}
            data-testid="shift-buttons-container"
          >
            <ShiftButtons
              onForwardClick={handleNextCommunities}
              onBackClick={handlePreviousCommunities}
              viewAll={openAllCommunities}
              disabledBack={disableSliderPrevious()}
              disabledForward={disableSliderNext()}
              hideSeeAllButton={hideSeeAllButton}
            />
          </div>
        )}
      </div>

      {!communities.length && !communitiesLoading ? (
        <span aria-label="no-communities-message" className={styles.textContainer}>
          {t(noCommunitiesMessage)}
        </span>
      ) : (
        <div className={styles.communitiesContent} data-testid="results-container">
          {communitiesLoading && !communities.length ? (
            <div className={styles.loaderWrapper}>
              <Loading borderless disableBoxShadow e2eTestId="communities-loader" />
            </div>
          ) : (
            <Slider
              aria-label="slider"
              forwardedRef={communityChildRef}
              items={communities?.map((community: ICommunity, index: number) =>
                community.name === "placeholder" ? (
                  <Loading />
                ) : (
                  <CommmunityCard
                    aria-label="community-card"
                    community={community}
                    key={index}
                    loggedUser={currentUserEmail}
                  />
                )
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResourcesCommunitySection;
