import { useLazyQuery } from "@apollo/client";
import GET_USER_INFO from "@graphql/queries/getUserInfo";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { FC, useEffect } from "react";
import { IUser } from "types/user";

export interface IWithUserProps {
  email: string;
}

export interface IWithUserInjectedProps {
  user: IUser;
}

const withUser =
  <P extends IWithUserInjectedProps>(Component: FC<P>) =>
  <T extends IWithUserProps & Omit<P, "user">>({ email, ...props }: T) => {
    const [getUser, { data, error }] = useLazyQuery(GET_USER_INFO);

    useEffect(() => {
      if (email) {
        getUser({ variables: { email } });
      }
    }, [email, getUser]);

    if (error) {
      displayServerError(error);
      return null;
    }

    const user: IUser = data?.userInfo;
    if (user) {
      return <Component {...(props as unknown as P)} user={user} />;
    }
    return null;
  };

export default withUser;
