import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getCustomNotificationByCode } from "@services/NotificationService/NotificationHelper";

import { REGISTER_TO_EVENT } from "@graphql/open-events/mutation";
import { GET_COUNTRIES } from "@modules/AccountSettings/SettingsProfileDetails/queries";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import Modal from "@components/Modal/Modal";
import SelectComponent from "@components/Select/Select";

import removeIcon from "@images/closeModal.svg";
import { useTranslation } from "react-i18next";
import { DefaultOption, Option } from "types/select";
import styles from "./OpenEventsRegisterModal.module.scss";

export interface IOpenEventsRegisterModalProps {
  eventPublicId: string;
  isOpen: boolean;
  closeModal: () => void;
  goBack?: () => void;
}

const MAX_INPUT_LENGTH = 1000;

const schema = yup.object().shape({
  email: yup.string().email(),
});

const OpenEventsRegisterModal = ({
  eventPublicId,
  closeModal,
  isOpen,
  goBack,
}: IOpenEventsRegisterModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [countries, setCountries] = useState<Array<DefaultOption>>([]);
  const [userTimeZone, setUserLocalTimezone] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<{
    name: string;
    callingCode: string;
  } | null>();
  const [isRegistrationInProgress, setIsRegistrationInProgress] =
    useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

  const { data: countriesData } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    setUserLocalTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    if (!countriesData) return;

    const mappedCountries = countriesData.countries.map(
      (country: { __typename: string; name: string; callingCode: string }) => ({
        label: country.name,
        value: country.callingCode,
      })
    );

    setCountries(mappedCountries);
  }, [countriesData]);

  const [registerToEvent] = useMutation(REGISTER_TO_EVENT, {
    onCompleted: () => {
      closeModal();
      resetForm();
      displaySuccessNotification(t("openPublicEventRegisterModal.userRegistered"));
      setIsRegistrationInProgress(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setIsRegistrationInProgress(false);
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      const message = getCustomNotificationByCode(errorCode);
      if (message) {
        return toast.error(message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const triggerClickWithoutPropagation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void
  ) => {
    e.stopPropagation();
    callback();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    schema.isValid({ email: event.target.value }).then((valid) => {
      setIsEmailValid(valid);
    });
  };

  const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganization(event.target.value);
  };

  const registerUserForEvent = () => {
    setIsRegistrationInProgress(true);

    const userData = {
      eventPublicId,
      fullName: `${name.trim()} ${lastName.trim()}`,
      email: email.trim(),
      organization: organization.trim(),
      country: selectedCountry?.name,
      timeZone: userTimeZone,
    };

    registerToEvent({ variables: { eventRegistrationInput: userData } });
  };

  const resetForm = () => {
    // reset fields
    setName("");
    setLastName("");
    setEmail("");
    setIsEmailValid(null);
    setOrganization("");
    setSelectedCountry(null);
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <div data-testid="container">
        <div>
          <div className={styles.introInnerWrapper}>
            <h4 aria-label="modal-title">{t("openPublicEventRegisterModal.title")}</h4>
            <Button
              title=""
              buttonType="link"
              wrapperClassNames={styles.closeButton}
              onClick={(e) =>
                triggerClickWithoutPropagation(e, () => {
                  closeModal();
                  goBack && goBack();
                })
              }
              buttonIcon={<img alt="" src={removeIcon} className={styles.closeIcon} />}
            />
          </div>
          <p className={styles.modalSubtitle} aria-label="modal-subtitle">
            {t("openPublicEventRegisterModal.subtitle")}
          </p>
          <form className={styles.eventsRegisterForm}>
            <div className={styles.inputContainer}>
              <Input
                onChange={handleNameChange}
                name="name"
                maxLength={MAX_INPUT_LENGTH}
                label={t("openPublicEventRegisterModal.nameLabel")}
                aria-label="userName"
                placeholder={t("openPublicEventRegisterModal.namePlaceholder")}
              />
            </div>
            <div className={styles.inputContainer}>
              <Input
                onChange={handleLastNameChange}
                name="lastName"
                maxLength={MAX_INPUT_LENGTH}
                label={t("openPublicEventRegisterModal.lastNameLabel")}
                aria-label="userLastName"
                placeholder={t("openPublicEventRegisterModal.lastNamePlaceholder")}
              />
            </div>
            <div className={styles.inputContainerFull}>
              <Input
                onChange={handleEmailChange}
                name="email"
                maxLength={MAX_INPUT_LENGTH}
                label={t("openPublicEventRegisterModal.emailLabel")}
                aria-label="userEmail"
                placeholder={t("openPublicEventRegisterModal.emailPlaceholder")}
              />
              {isEmailValid === false ? (
                <span className={styles.errorMessage}>
                  {t("openPublicEventRegisterModal.invalidEmail")}
                </span>
              ) : null}
            </div>
            <div className={styles.inputContainer}>
              <Input
                onChange={handleOrganizationChange}
                name="organization"
                maxLength={MAX_INPUT_LENGTH}
                label={t("openPublicEventRegisterModal.organizationLabel")}
                aria-label="userOrganization"
                placeholder={t("openPublicEventRegisterModal.organizationPlaceholder")}
              />
            </div>
            <div className={styles.selectContainer}>
              <SelectComponent
                options={countries}
                placeholder={t("openPublicEventRegisterModal.countryPlaceholder")}
                className={styles.selectRegister}
                label={t("openPublicEventRegisterModal.countryLabel")}
                isSearchable
                name="country"
                showDropdown={true}
                onChange={(option: Option) => {
                  setSelectedCountry({
                    name: option.label as string,
                    callingCode: option.value,
                  });
                }}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                wrapperClassNames={styles.buttonWrapper}
                aria-label="cancelBtn"
                buttonType="link"
                title={t("openPublicEventRegisterModal.buttons.cancel")}
                className={styles.cancelLink}
                onClick={(e) =>
                  triggerClickWithoutPropagation(e, () => {
                    resetForm();
                    closeModal();
                    goBack && goBack();
                  })
                }
              />
              <Button
                aria-label="registerUser"
                buttonType="primary"
                title={t("openPublicEventRegisterModal.buttons.submit")}
                onClick={() => registerUserForEvent()}
                loading={isRegistrationInProgress}
                disabled={
                  !name.trim().length ||
                  !lastName.trim().length ||
                  !email.trim().length ||
                  !organization.trim().length ||
                  !selectedCountry ||
                  !isEmailValid ||
                  isRegistrationInProgress
                }
                fullWidth
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default OpenEventsRegisterModal;
