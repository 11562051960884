import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { GroupChannel } from "sendbird";

import { Badge } from "@components/DataDisplay/Badge/Badge";

import { redirectToChatPage } from "@utils/redirectChat";

import { IUseSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/type";

import { useGetUserImages } from "../useGetUserImages/useGetUserImages";

import { RootState } from "store/store";

import SmallAvatar from "@images/avatar_small.svg";

import { SendBirdHelper } from "@customHooks/sendBird/SendBirdHelper";

import styles from "./MenuItems.module.scss";

interface IMenuItemsProps {
  sendBirdGroupChannel: IUseSendBirdGroupChannels;
}

export const MenuItems = ({
  sendBirdGroupChannel: {
    state: { directGroups, selectedGroupChannel },
  },
}: IMenuItemsProps) => {
  const history = useHistory();
  const { getUserImages, userImages } = useGetUserImages();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  useEffect(() => {
    if (!directGroups?.length) return;

    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: SendBirdHelper.getDirectGroupsUserEmails(
            currentUserEmail,
            directGroups
          ),
        },
      },
    });
  }, [currentUserEmail, directGroups, getUserImages]);

  const handleItemClick = (group: GroupChannel) => {
    redirectToChatPage(group, history);
  };

  return (
    <div className={styles.menuItems}>
      {directGroups?.map((group) => {
        const userIds = group.members.map((member) => member.userId);
        const profileImage = userImages?.find((userImage) =>
          userIds.includes(userImage.email)
        )?.image;

        return (
          <Badge count={group.unreadMessageCount} mode="secondary" key={group.url}>
            <div
              onClick={() => {
                handleItemClick(group);
              }}
              className={styles.messageContainer}
            >
              <div
                style={{ backgroundImage: `url(${profileImage || SmallAvatar})` }}
                className={styles.avatar}
              ></div>
              <span
                className={
                  selectedGroupChannel?.url === group.url
                    ? styles.activeUserName
                    : styles.userName
                }
              >
                {SendBirdHelper.getDirectGroupOtherMemberNickName(
                  group,
                  currentUserEmail
                )}
              </span>
            </div>
          </Badge>
        );
      })}
    </div>
  );
};
