export const codeContent = [
  {
    mainTitle: "mainTitle",
  },
  {
    content: "sectionOneContentOne",
  },
  {
    content: "sectionOneContentTwo",
  },
  {
    content: "sectionOneContentThree",
  },
  {
    content: "sectionOneContentFour",
  },

  {
    title: "TitleOne",
    content: "contentOne",
  },
  {
    title: "TitleTwo",
    content: "contentTwo",
  },
  {
    content: "contentTwoSectionTwo",
  },
  {
    content: "contentTwoSectionThree",
  },
  {
    title: "TitleThree",
    content: "contentThree",
  },
  {
    title: "TitleFour",
    content: "contentFour",
  },
  {
    title: "TitleFive",
    content: "contentFive",
  },
  {
    title: "TitleSix",
    content: "contentSix",
  },
  {
    title: "TitleSeven",
    content: "contentSeven",
  },
  {
    title: "TitleEight",
    content: "contentEight",
  },
];
