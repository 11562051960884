import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import FeedbackCard from "@components/FeedbackCard/FeedbackCard";

import ProvideFeedbackModal from "./ProvideFeedbackModal";

import TourModal from "@modules/TourModal/TourModal";
import styles from "./SupportPage.module.scss";

interface IUsefulLink {
  text: string;
  action: () => void;
  e2eTestId: string;
}

const SupportPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [shouldShowTourModal, setShouldShowTourModal] = useState<boolean>(false);

  const links: IUsefulLink[] = [
    {
      text: t("feedback.usefulLinks.codeOfConduct"),
      action: () => history.push(`/portal/conduct`),
      e2eTestId: "code-of-conduct-link",
    },
    {
      text: t("feedback.usefulLinks.terms"),
      action: () => history.push(`/portal/terms-and-conditions`),
      e2eTestId: "terms-and-conditions-link",
    },
    {
      text: t("feedback.usefulLinks.takeTour"),
      action: () => setShouldShowTourModal(true),
      e2eTestId: "take-a-tour-of-the-hive-link",
    },
  ];

  const feedbackInfo = {
    title: t("feedback.feedbackCard.title"),
    content: t("feedback.feedbackCard.content"),
    buttonText: t("feedback.feedbackCard.button"),
  };

  const renderUsefulLink = (text: string, action: () => void, e2eTestId: string) => {
    return (
      <p>
        <span className={styles.bullet}>•</span>
        <span className={styles.bulletText} onClick={action} e2e-test-id={e2eTestId}>
          {text}
        </span>
      </p>
    );
  };

  return (
    <>
      <TourModal
        onClose={() => setShouldShowTourModal(false)}
        isOpen={shouldShowTourModal}
      />
      <div className={styles.container} e2e-test-id="support-container">
        <div className={styles.titleContainer}>
          <span className={styles.firstTitle} e2e-test-id="support-title">
            {t("feedback.supportPage.firstTitle")}
          </span>
          <span className={styles.secondTitle} e2e-test-id="support-subtitle">
            {t("feedback.supportPage.secondTitle")}
          </span>
        </div>
        <div className={styles.feedbackCard}>
          <FeedbackCard
            title={feedbackInfo.title}
            content={feedbackInfo.content}
            buttonText={feedbackInfo.buttonText}
            onButtonClick={() => setOpenModal(true)}
          />
        </div>
        <div className={styles.feedbackCard}>
          <div className={styles.cardContainer} e2e-test-id="useful-links-container">
            <p
              aria-label="title"
              className={styles.cardTitle}
              e2e-test-id="useful-links-title"
            >
              {t("feedback.usefulLinks.title")}
            </p>
            <div className={styles.cardContent}>
              {links.map((link) =>
                renderUsefulLink(link.text, link.action, link.e2eTestId)
              )}
            </div>
          </div>
        </div>
        <ProvideFeedbackModal open={openModal} close={() => setOpenModal(false)} />
      </div>
    </>
  );
};

export default SupportPage;
