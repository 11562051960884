/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { IOption } from "@components/formik/SelectUpdated/SelectUpdated";
import GET_INTERESTS from "@graphql/queries/interests";
import { mapToOption } from "@utils/selectHelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInterestsResponse } from "types/interests";

export const useInterestHelper = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IInterestsResponse>(GET_INTERESTS);
  const [options, setOptions] = useState<IOption<string>[]>([]);

  useEffect(() => {
    if (!data?.interests) return;

    setOptions(
      data.interests.map(({ name }) => mapToOption(name, t(`categories.type.${name}`)))
    );
  }, [data]);

  const mapToOptions = (interests: Array<string>) => {
    return interests.map((interest) =>
      mapToOption(interest, t(`categories.type.${interest}`))
    );
  };

  return { options, mapToOptions };
};
