import { useMsal } from "@azure/msal-react";
import WelcomeSection from "@components/LandingPage/Home/WelcomeSection/WelcomeSection";
import Footer from "@components/LandingPage/Layout/Footer";
import Header from "@components/LandingPage/Layout/Header";
import { useRef } from "react";
import { loginRequest } from "../../authConfig";
import styles from "./WelcomeScreen.module.scss";

const WelcomeScreen = (): JSX.Element => {
  const SignupRef = useRef<HTMLDivElement | null>(null);

  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest);
  };
  const goToLandingPageHandler = () => {
    window.open("/", "_blank", "noopener, noreferrer");
  };

  return (
    <div className={styles.welcomeScreenContainer}>
      <Header
        signupRef={SignupRef}
        showEventsButton={false}
        showLoginButton={false}
        showRegisterButton={false}
        showNavigationButtons={false}
      />
      <WelcomeSection
        handleLoginReact={handleLoginRedirect}
        goToLandingPage={goToLandingPageHandler}
      />
      <Footer />
    </div>
  );
};

export default WelcomeScreen;
