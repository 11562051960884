import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Collapse } from "antd";

import Button from "@components/Button/Button";
import CommunityRemoveMemberModal from "@components/CommunityRemoveMemberModal/CommunityRemoveMemberModal";
import { CommunityOptions } from "@modules/ChatPage/CommunityOptions/CommunityOptions";
import UserDrawer from "../../UserDrawer/UserDrawer";

import avatar from "@images/avatar.svg";

import { IUser, IUserImageResponse, IUserResponse } from "types/user";
import { setAdmins, setMembers } from "../../../store/community/actions";
import { RootState } from "../../../store/store";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import DEMOTE_MEMBER from "@graphql/mutations/demoteMember";
import PROMOTE_MEMBER from "@graphql/mutations/promoteMember";
import REMOVE_MEMBER from "@graphql/mutations/removeMember";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import GET_USER from "@graphql/queries/users";
import getStorageImage from "@utils/getStorageImage";

import {
  currentlyLoggedInUserIsAdminOrCreator,
  isCurrentlyLoggedInUserAdmin,
  isMemberInCurrentRowAdmin,
  isMemberInCurrentRowCommunityCreator,
  isMemberInCurrentRowRegularMember,
} from "@modules/CommunityMembers/roleHelpers";
import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import styles from "./CommunityMembersSection.module.scss";

interface ICommunityMembersSection {
  isUserNotAllowed: boolean;
  isPublicCommunity: boolean | undefined;
  isCurrentUserCommunityCreator: boolean;
}

const CommunityMembersSection = ({
  isUserNotAllowed,
  isPublicCommunity,
  isCurrentUserCommunityCreator,
}: ICommunityMembersSection) => {
  const { Panel } = Collapse;

  const params = useParams<{
    community: string;
    channel: string;
    message: string;
  }>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const { community: sendbirdId } = useParams<{ community: string }>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [memberToBeRemoved, setMemberToBeRemoved] = useState<IUser>();

  const [handleChangeCommunityUserRoleButton, setHandleChangleCommunityUserRoleButton] =
    useState<boolean>(false);

  const [demoteMember] = useMutation(DEMOTE_MEMBER);
  const [promoteMember] = useMutation(PROMOTE_MEMBER);
  const [removeMember] = useMutation(REMOVE_MEMBER);

  const admins = useSelector<RootState, Array<IUser>>((state) => state.community.admins);

  const allMembers = useSelector<RootState, Array<IUser>>(
    (state) => state.community.allMembers
  );
  const regularMembers = useSelector<RootState, Array<IUser>>(
    (state) => state.community.members
  );
  const communityCreator = useSelector<RootState, IUser>(
    (state) => state.community.creator
  );
  const [memberInfoDrawer, setMemberInfoDrawer] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<IUser>();

  const openMemberInfoDrawer = (member: IUser) => {
    setMemberInfoDrawer(true);
    setSelectedMember(member);
  };

  const { data: currentUserData } = useQuery<IUserResponse>(GET_USER);

  const canUserSeeAdminsAndMembers = isUserNotAllowed && !isPublicCommunity;

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  useEffect(() => {
    allMembers &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: allMembers.map((member) => member.email),
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMembers]);

  const promoteRegularMemberToAdmin = (member: IUser, memberId: string) => {
    promoteMember({
      variables: {
        communitySendbirdId: sendbirdId,
        memberEmail: memberId,
      },
    })
      .then(() => {
        dispatch(setAdmins([...admins, member]));
        const filteredRegularMembers = regularMembers.filter(
          (regularMember) => regularMember !== member
        );
        dispatch(setMembers(filteredRegularMembers));
      })
      .catch((error) => displayNotSuccessNotification(error))
      .finally(() => setHandleChangleCommunityUserRoleButton(false));
  };

  const removeMemberFromCommunity = (member: IUser) => {
    removeMember({
      variables: {
        communitySendbirdId: sendbirdId,
        memberEmail: member.email,
      },
    })
      .then(() => {
        if (isMemberInCurrentRowRegularMember(regularMembers, member)) {
          const filteredRegularMembers = regularMembers.filter(
            (regularMember) => regularMember !== member
          );
          dispatch(setMembers(filteredRegularMembers));
        } else {
          const filteredAdminMembers = admins.filter((admin) => admin !== member);
          dispatch(setAdmins(filteredAdminMembers));
        }
        displaySuccessNotification(
          t("community.administration.messages.successfullyRemovedMember")
        );
      })
      .catch((error) => displayNotSuccessNotification(error));
  };

  const demoteAdminToRegularMember = (member: IUser, memberId: string) => {
    demoteMember({
      variables: { communitySendbirdId: sendbirdId, adminEmail: memberId },
    })
      .then(() => {
        dispatch(setMembers([...regularMembers, member]));
        const filteredAdmins = admins.filter((admin) => admin !== member);
        dispatch(setAdmins(filteredAdmins));
      })
      .catch((error) => displayNotSuccessNotification(error))
      .finally(() => setHandleChangleCommunityUserRoleButton(false));
  };

  const changeMemberRole = (member: IUser) => {
    const memberId = member.email;
    setHandleChangleCommunityUserRoleButton(true);
    if (isMemberInCurrentRowRegularMember(regularMembers, member)) {
      promoteRegularMemberToAdmin(member, memberId);
    } else {
      demoteAdminToRegularMember(member, memberId);
    }
  };

  const displayAdminBtnTitle = (member: IUser) =>
    isMemberInCurrentRowRegularMember(regularMembers, member)
      ? "community.administration.buttons.makeAdmin"
      : "community.administration.buttons.removeAdmin";

  const renderMemberRow = (member: IUser) => {
    const userAvatar = userImages
      ? userImages.find((image) => image.email === member.email)?.image
      : avatar;

    return (
      <div key={member.publicId} className={styles.grid}>
        <div className={styles.info}>
          <div
            style={{ backgroundImage: `url(${userAvatar})` }}
            className={styles.avatar}
            onClick={() => openMemberInfoDrawer(member)}
          ></div>
          <div className={styles.infoText}>
            <span
              className={styles.infoMemberName}
              title={member?.fullName}
              onClick={() => openMemberInfoDrawer(member)}
            >
              {member?.fullName}
              {isMemberInCurrentRowCommunityCreator(communityCreator, member) && (
                <span
                  className={styles.infoCommunityCreator}
                  title={t("community.administration.roles.groupCreator")}
                >
                  &nbsp;&#x2022;&nbsp;
                  {t("community.administration.roles.groupCreator")}
                </span>
              )}
            </span>
            <p className={styles.infoMemberOccupation}>
              {getRoleOrganizationText(member.organization)}
            </p>
            {!isMemberInCurrentRowCommunityCreator(communityCreator, member) && (
              <div className={styles.actions}>
                {!(
                  isCurrentlyLoggedInUserAdmin(currentUserData, admins) &&
                  isMemberInCurrentRowAdmin(admins, member)
                ) && (
                  <div className={styles.memberButtons}>
                    {currentlyLoggedInUserIsAdminOrCreator(
                      isCurrentUserCommunityCreator,
                      currentUserData,
                      admins
                    ) &&
                      !isMemberInCurrentRowCommunityCreator(communityCreator, member) && (
                        <>
                          <Button
                            wrapperClassNames={styles.adminBtn}
                            buttonType="link"
                            title={t(displayAdminBtnTitle(member))}
                            onClick={() => changeMemberRole(member)}
                            disabled={handleChangeCommunityUserRoleButton}
                          />
                          <span className={styles.line} />
                          <Button
                            aria-label="remove-btn"
                            wrapperClassNames={styles.memberBtn}
                            buttonType="link"
                            title={t("community.administration.buttons.removeMember")}
                            onClick={() => {
                              setIsModalOpen(true);
                              setMemberToBeRemoved(member);
                            }}
                            disabled={handleChangeCommunityUserRoleButton}
                          />
                        </>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Collapse
        expandIconPosition="start"
        className={styles.accordionHeader}
        ghost
        accordion
        style={canUserSeeAdminsAndMembers ? { pointerEvents: "none" } : {}}
      >
        <Panel key="1" header={t("community.accordionHeader")}>
          <div className={styles.panel}>
            <div className={styles.memberSection}>
              <h4 className={styles.sectionHeader}>{t("community.administrators")}</h4>
              <div className={styles.scrollBox}>
                {communityCreator && renderMemberRow(communityCreator)}
                {admins.map((member) => renderMemberRow(member))}
              </div>
            </div>
            <div className={styles.memberSection}>
              <h4 className={styles.sectionHeader}>{t("community.members")}</h4>
              <div className={styles.scrollBox}>
                {regularMembers.map((member) => renderMemberRow(member))}
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <CommunityRemoveMemberModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          // dangerModalOpen?.(false);
        }}
        handleRemoveMember={removeMemberFromCommunity}
        memberToBeRemoved={memberToBeRemoved}
      />

      {memberInfoDrawer && selectedMember && (
        <div className={styles.memberDrawerContainer}>
          <UserDrawer
            user={selectedMember}
            closeInfoDrawer={() => {
              setMemberInfoDrawer(false);
            }}
            communityOptions={
              <CommunityOptions
                sendBirdId={params.community}
                selectedUser={selectedMember}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default CommunityMembersSection;
