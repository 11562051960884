export const replaceWhiteSpacesWithWhiteSpace = (text: string) => {
  const whiteSpacesRegex = new RegExp(/\s\s+/, "g");

  return text.replace(whiteSpacesRegex, " ");
};

export const addEscapeToSpecialCharacters = (text: string) => {
  const specialCharacterRegex = new RegExp(
    // eslint-disable-next-line
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/,
    "gi"
  );

  return text.replace(specialCharacterRegex, (specialCharacter) => {
    return `\\${specialCharacter}`;
  });
};

export const getAllOccurrences = (text: string, regex: RegExp) => {
  return text.match(regex);
};

export const getRegexGroupWithEscapedSpecialCharacters = (text: string) => {
  const textSpecialCharactersEscaped = addEscapeToSpecialCharacters(text);
  const textRegex = new RegExp(`(${textSpecialCharactersEscaped})`, "gi");

  return textRegex;
};
