import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import styles from "./EventConfirmJoinModal.module.scss";
// Using .png icon since .svg is not rendered propertly in browser
import { CloseOutlined } from "@ant-design/icons";
import VideoIcon from "@images/video.png";
import { useTranslation } from "react-i18next";
interface IEventConfirmJoinModal {
  isOpen: boolean;
  joinUrl: string;
  text: string;
  closeModal: () => void;
}

const EventConfirmJoinModal = ({
  isOpen,
  joinUrl,
  text,
  closeModal,
}: IEventConfirmJoinModal) => {
  const { t } = useTranslation();

  /* To open link in a new tab the '//' (double forwardslash) has to be added infront of url. This works fine with links starting with www.
  With https:// or http:// links, adding '//' infront, will remove semicolon ":" for some reason (for example https//youtube.com) and link will be invalid. formatUrl function formats only links starting with www. */

  const formatUrl = (url: string) => {
    return url.includes(":") ? url : `//${url}`;
  };

  const openExternalLinkInNewTab = () => {
    const formattedUrl = formatUrl(joinUrl);
    window.open(formattedUrl);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} className={styles.modalContainer}>
      <div className={styles.modalWrapper}>
        <Button
          title=""
          buttonType="link"
          wrapperClassNames={styles.closeButton}
          onClick={closeModal}
          buttonIcon={<CloseOutlined className={styles.closeIcon} />}
        />
        <img src={VideoIcon} alt="" className={styles.videoImage} />
        <h4 className={styles.header}>{t("events.joinExternalLinkTitle")}</h4>
        <p className={styles.text}>{text}</p>
        <div className={styles.buttonContainer}>
          <Button
            wrapperClassNames={styles.confirmModalButtonWrapper}
            title={t("events.cancelExternalLinkButton")}
            onClick={closeModal}
          />
          <Button
            wrapperClassNames={styles.confirmModalButtonWrapper}
            title={t("events.proceedExternalLinkButton")}
            buttonType="primary"
            onClick={openExternalLinkInNewTab}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EventConfirmJoinModal;
