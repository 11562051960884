import { gql } from "@apollo/client";

const GET_SINGLE_FOLDER = gql`
  query folder($publicId: Guid!) {
    folder(publicId: $publicId) {
      name
      publicId
      updateTime
      isEditable
      parentCollection {
        publicId
        name
        description
        collectionType
        isPublic
        collectionCreator {
          fullName
          publicId
          email
          organization {
            name
            userRole
          }
        }
        collaborators {
          fullName
          publicId
          email
          organization {
            name
            userRole
          }
        }
        followers {
          fullName
          publicId
          email
          organization {
            name
            userRole
          }
        }
        interests {
          name
        }
      }
      creator
      folders {
        name
        publicId
        updateTime
        isEditable
        canUserDeleteFolder
      }
      files {
        name
        publicId
        directory
        updateTime
        isPinnable
        canUserDeleteFile
        originalName
        interests {
          name
        }
      }
      links {
        name
        publicId
        updateTime
        url
        canUserDeleteLink
      }
      # articles {
      #   articleId
      # }
    }
  }
`;

export default GET_SINGLE_FOLDER;
