import React from "react";

import { FixedSizeList as List } from "react-window";

import { MenuListProps, components } from "react-select";

export const MenuList = (props: MenuListProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const virtualized = (props.selectProps as any)["data-virtualized"];
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * 42;
  const childrenOptions = React.Children.toArray(children);

  if (virtualized)
    return (
      <List
        height={maxHeight}
        itemCount={childrenOptions.length || 0}
        itemSize={42}
        initialScrollOffset={initialOffset}
        width={"100%"}
      >
        {({ index, style }) => {
          return <div style={style}>{childrenOptions[index]}</div>;
        }}
      </List>
    );

  return <components.MenuList {...props}>{children}</components.MenuList>;
};
