import { useMsal } from "@azure/msal-react";
import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import Document from "@components/Document/Document";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { setAcceptTerms } from "../../store/onboarding/actions";
import styles from "./TermsAndConditions.module.scss";
import { termsContent } from "./content";

interface ITermsAndCoditionsProps {
  isOnboarded: boolean;
}

const TermsAndConditions = ({ isOnboarded }: ITermsAndCoditionsProps) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { instance } = useMsal();

  if (isOnboarded) return <Redirect to="/portal/resources" />;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.termsTitle}>{t("termsAndConditions.title")}</h2>
        <h2 className={styles.termsTitle}>{t("termsAndConditions.subtitle")}</h2>
        <div className={styles.termsContainer}>
          <Document document={termsContent} />
          <div className={styles.checkboxWrapper}>
            <Checkbox
              name="acceptTerms"
              label={t("termsAndConditions.agree")}
              checked={accepted}
              onChange={() => setAccepted(!accepted)}
            />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            title={t("termsAndConditions.buttons.back")}
            buttonType="link"
            onClick={() => {
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            }}
          />
          <Button
            title={t("termsAndConditions.buttons.agree")}
            wrapperClassNames="addIndentLeft"
            disabled={!accepted}
            buttonType="primary"
            onClick={() => {
              dispatch(setAcceptTerms(accepted));
              history.push("/code-of-conduct");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
