import Modal from "@components/Modal/Modal";
import styles from "./PreviwDocument.module.scss";
import removeIcon from "@images/closeModal.svg";
import Button from "@components/Button/Button";

interface PreviwDocumentProps {
  open: boolean;
  close: () => void;
  file: Blob | MediaSource;
  type: string;
}

const PreviwDocument = ({
  open,
  close,
  file,
  type,
}: PreviwDocumentProps): JSX.Element => {
  const closeModal = () => {
    close();
  };
  return (
    <>
      <Modal
        className={styles.previewDocumentModal}
        modalType="primary"
        isOpen={open}
        onRequestClose={closeModal}
      >
        <div className={styles.modalHeader}>
          <Button
            title=""
            wrapperClassNames={styles.closeButtonWrapper}
            buttonShape="circle"
            onClick={closeModal}
            buttonIcon={<img alt="" className={styles.removeIcon} src={removeIcon} />}
          />
        </div>
        <object
          data={file ? URL.createObjectURL(file!) : ""}
          type={type}
          className={styles.objectClass}
        />
      </Modal>
    </>
  );
};

export default PreviwDocument;
