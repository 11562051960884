import { useState } from "react";

import { useLazyQuery } from "@apollo/client";

import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";

import getStorageImage from "@utils/getStorageImage";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import { IUserImageRequest, IUserImageResponse } from "types/user";

import { IProfileImage } from "./type";

export const useGetUserImages = () => {
  const [userImages, setUserImages] = useState<IProfileImage[]>();

  const [getUserImages] = useLazyQuery<IUserImageResponse, IUserImageRequest>(
    GET_PROFILE_IMAGES,
    {
      onCompleted: ({ profileImages }) => {
        setUserImages(
          profileImages.map(({ imageDirectory, userPhotoName, email }) => ({
            image: getStorageImage({
              image: userPhotoName,
              directoryName: imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email,
          }))
        );
      },
    }
  );

  return { userImages, getUserImages };
};
