import { CLEAR_SEARCH_TERM, SAVE_SEARCH_TERM } from "./constants";

export const saveSearchTerm = (searchTerm: string) => ({
  type: SAVE_SEARCH_TERM,
  payload: searchTerm,
});

export const clearSearchTerm = () => ({
  type: CLEAR_SEARCH_TERM,
  payload: "",
});
