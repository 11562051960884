import TextTruncated from "@components/TextTruncated/TextTruncated";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import withUser from "../../hoc/withUser";
import styles from "./EventHostCard.module.scss";

const MAX_NAME_LENGTH = 24;

interface IEventHostCard {
  username: string;
  userEmail: string;
  avatar: string;
}

const EventHostCard = ({ username, userEmail, avatar }: IEventHostCard) => {
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const { t } = useTranslation();

  const handleUserSelected = () => {
    setSelectedUserEmail(userEmail);
  };

  const UserDrawerWithUser = withUser(UserDrawer);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <div
            style={{ backgroundImage: `url(${avatar})` }}
            className={styles.image}
            onClick={handleUserSelected}
          ></div>
          <div className={styles.column}>
            <span className={styles.hostTitle}>{t("eventDetail.hostedBy")}</span>
            <span className={styles.hostName} onClick={handleUserSelected}>
              <TextTruncated
                textToTruncate={username}
                length={MAX_NAME_LENGTH}
                placement="topRight"
              />
            </span>
          </div>
        </div>
      </div>
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </>
  );
};

export default EventHostCard;
