import { useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import ACCEPT_OR_DECLINE_INVITATION from "@graphql/mutations/acceptOrDeclineInvitation";
import UPDATE_COMMUNITY_PRIVATE_JOIN from "@graphql/mutations/updateCommunityPrivateJoin";
import CheckMark from "@images/checkMark.svg";
import CloseMark from "@images/closeMark.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IPartialUser } from "types/user";
import styles from "./RequestApproval.module.scss";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { getCustomNotificationByCode } from "@services/NotificationService/NotificationHelper";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";

interface IRequestApprovalProps {
  sendBirdId: string;
  user: IPartialUser;
  className?: string;
  onClick?: () => void;
  isInvite?: boolean;
}

export const RequestApproval = ({
  sendBirdId,
  user,
  className,
  onClick,
  isInvite,
}: IRequestApprovalProps) => {
  const { t } = useTranslation();
  const [isAnswerInProgress, setIsAnswerInProgress] = useState<boolean>(false);
  const [updateCommunityPrivateJoin] = useMutation(UPDATE_COMMUNITY_PRIVATE_JOIN);
  const [acceptOrDeclineInvitation] = useMutation(ACCEPT_OR_DECLINE_INVITATION);

  const [errorCode, setErrorCode] = useState<string>("");
  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);
  const { removeCommunityNotificationsRelatedToCommunity } =
    useCommunityNotificationsHelper();

  const handleJoinPrivateCommunityRequest =
    (isAcceptedInvitation: boolean) => (event: React.MouseEvent) => {
      setIsAnswerInProgress(true);
      updateCommunityPrivateJoin({
        variables: {
          joinRequest: {
            sendbirdId: sendBirdId,
            userEmail: user.email,
            isAccepted: isAcceptedInvitation,
          },
        },
      })
        .then(() => onClick?.())
        .catch(() => displayServerError());

      event.preventDefault();
    };

  const acceptOrDeclineInvitationAction =
    (isAcceptedInvitation: boolean) => (event: React.MouseEvent) => {
      setIsAnswerInProgress(true);
      acceptOrDeclineInvitation({
        variables: {
          invitationResponse: {
            sendbirdId: sendBirdId,
            inviterEmail: user.email,
            isAccepted: isAcceptedInvitation,
          },
        },
      })
        .then(() => {
          onClick?.();
        })
        .catch((error) => {
          const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
          const msg = getCustomNotificationByCode(errorCode);
          setErrorCode(msg);
          setIsUserNotAllowedModalOpened(true);
        });

      event.preventDefault();
    };

  const renderButtonTitle = (title: string, url: string) => {
    return (
      <div className={styles.buttonContainer}>
        <img src={url} className={styles.iconBeforeTitle} />
        <span>{title}</span>
      </div>
    );
  };

  const closeCommunityAccessModal = () => {
    removeCommunityNotificationsRelatedToCommunity(sendBirdId);
    setIsUserNotAllowedModalOpened(false);
  };

  return (
    <div className={`${styles.buttons} ${className}`}>
      <Button
        aria-label="accept request"
        title={renderButtonTitle(t("requests.requestCard.accept"), CheckMark)}
        buttonType="primary"
        wrapperClassNames={styles.button}
        disabled={isAnswerInProgress}
        onClick={
          isInvite
            ? acceptOrDeclineInvitationAction(true)
            : handleJoinPrivateCommunityRequest(true)
        }
      />
      <Button
        aria-label="decline request"
        title={renderButtonTitle(t("requests.requestCard.decline"), CloseMark)}
        wrapperClassNames={styles.button}
        disabled={isAnswerInProgress}
        onClick={
          isInvite
            ? acceptOrDeclineInvitationAction(false)
            : handleJoinPrivateCommunityRequest(false)
        }
      />
      <ConfirmationModal
        isOpen={isUserNotAllowedModalOpened}
        messageTitle={t("community.communityAccess.title")}
        messageContent={errorCode}
        closeModal={() => setIsUserNotAllowedModalOpened(false)}
        goBack={() => closeCommunityAccessModal()}
      />
    </div>
  );
};
