/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import BackButton from "@components/BackButton/BackButton";
import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";
import GET_COMMUNITY_RESOURCES from "@graphql/queries/communityResources";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommunity } from "types/community";
import CommunitiesGrid from "../CommunitiesGrid/CommunitiesGrid";
import styles from "./Communities.module.scss";

interface ICommunitiesProps {
  searchValue: string;
  interests: Array<string>;
}

const Communities = ({ searchValue, interests }: ICommunitiesProps) => {
  const [gridCommunitiesCount, setGridCommunitiesCount] = useState<number>(0);
  const [gridCommunitiesInitLoaded, setGridCommunitiesInitLoaded] =
    useState<boolean>(false);
  const [gridCommunitiesLoadingMore, setGridCommunitiesLoadingMore] =
    useState<boolean>(false);
  const [gridCommunities, setGridCommunities] = useState<Array<ICommunity>>([]);
  const { t } = useTranslation();

  const [getGridCommunityResources] = useLazyQuery(GET_COMMUNITY_RESOURCES, {
    fetchPolicy: "network-only",
    onCompleted: (data: {
      communities: {
        communities: Array<ICommunity>;
        totalCount: number;
        __typename: string;
      };
    }) => {
      if (!data.communities || !data.communities.communities) return;
      setGridCommunities([...gridCommunities, ...data.communities.communities]);
      data.communities.totalCount && setGridCommunitiesCount(data.communities.totalCount);
      !gridCommunitiesInitLoaded && setGridCommunitiesInitLoaded(true);
      gridCommunitiesLoadingMore && setGridCommunitiesLoadingMore(false);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const loadGridCommunities = () => {
    gridCommunitiesInitLoaded && setGridCommunitiesLoadingMore(true);
    getGridCommunityResources({
      variables: {
        skip: gridCommunities.length,
        size: 16,
        text: searchValue,
        interests: searchValue.length ? [] : interests,
      },
    });
  };

  useEffect(() => {
    if (!interests.length) return;
    loadGridCommunities();
  }, [interests]);

  return (
    <div className={styles.communitiesContainer}>
      <BackButton path="/portal/resources" label={t("resources.backToResult")} />
      <ResourcesHeading
        resultsCount={gridCommunitiesCount}
        searchValue={searchValue}
        sectionTitle={t("resources.communities")}
        isTitleDisplayed={false}
      />

      <CommunitiesGrid
        communities={gridCommunities}
        loadMore={loadGridCommunities}
        communitiesLoading={!gridCommunitiesInitLoaded}
        communitiesLoadingMore={gridCommunitiesLoadingMore}
        communitiesCount={gridCommunitiesCount}
        searchValue={searchValue}
      />
    </div>
  );
};

export default Communities;
