import bin from "@images/bin.png";
import tick from "@images/tick.svg";
import { useState } from "react";
import styles from "./RadioButton.module.scss";
export interface IRadioButtonProps {
  key: number | string;
  id: number;
  name: string;
  label: string;
  checked?: boolean;
  subLabel?: string;
  radioImage?: string;
  groupClassName?: string;
  removeIcon?: boolean;
  checkButton?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  remove?: (index: number) => void;
  "aria-label"?: string;
}

const RadioButton = (props: IRadioButtonProps) => {
  const [selectImage, setSelectImage] = useState<boolean>(false);

  const removeHandler = () => {
    if (props.remove) props.remove(props.id);
  };

  return (
    <div className={`${styles.radioWrapper} ${props.groupClassName}`}>
      <input
        aria-label={props["aria-label"]}
        id={props.name}
        name={props.name}
        type="radio"
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      {props.radioImage ? (
        <label htmlFor={props.name} className={styles.radioImageLabel}>
          <img
            className={styles.radioImage}
            src={props.radioImage}
            alt=""
            onClick={() => setSelectImage(!selectImage)}
            aria-label="img-1"
          />
          {props.checked && props.checkButton && (
            <img
              className={styles.checkIcon}
              src={tick}
              width={20}
              height={20}
              alt=""
              onClick={() => setSelectImage(false)}
              aria-label="img-2"
            />
          )}
          {props.checked && props.removeIcon && (
            <img
              className={styles.removeIcon}
              src={bin}
              width={35}
              height={35}
              onClick={removeHandler}
              alt=""
              aria-label="img-3"
            />
          )}
        </label>
      ) : (
        <label
          htmlFor={props.name}
          className={styles.radioLabel}
          aria-label="no-radio-image"
        >
          <div className={styles.labelWrapper}>
            <span className={styles.mainLabel} aria-label="label">
              {props.label}
            </span>
            {props.subLabel && (
              <span className={styles.subLabel} aria-label="sub-label">
                {props.subLabel}
              </span>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export default RadioButton;
