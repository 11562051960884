import { gql } from "@apollo/client";

const GET_USER_NOTIFICATIONS = gql`
  query user($notificationSearch: NotificationsSearchInputType!) {
    user {
      notifications(notificationSearch: $notificationSearch) {
        totalCount
        notifications {
          publicId
          notificationType
          data
          creationTime
          isRead
        }
      }
    }
  }
`;

const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query user {
    user {
      unreadNotificationsCount
    }
  }
`;

export { GET_USER_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS_COUNT };
