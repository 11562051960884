import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ArticleCard from "@components/ArticleCard/ArticleCard";
import { CollectionsTreeNode } from "@components/CollectionsTree";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";

import {
  MOVE_FILE_TO_COLLECTION,
  MOVE_FILE_TO_FOLDER,
  MOVE_FOLDER_TO_COLLECTION,
  MOVE_FOLDER_TO_FOLDER,
  MOVE_LINK_TO_COLLECTION,
  MOVE_LINK_TO_FOLDER,
  REMOVE_ARTICLE_FROM_COLLECTION,
} from "@graphql/collection/mutation";
import { PIN_FILE_TO_COMMUNITY } from "@graphql/community/mutations";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import { DELETE_FILE } from "@graphql/mutations/deleteFile";
import { DELETE_LINK } from "@graphql/mutations/deleteLink";
import { UPDATE_LINK } from "@graphql/mutations/updateLink";
import DangerIcon from "@images/DangerIcon.png";
import ArticleService from "@services/ArticleService";
import {
  notSupportedExtensionsForDocumentPreview,
  supportedOfficeExtensions,
} from "@utils/files/constants";
import { parseFileName } from "@utils/helpers";
import { useCallback, useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ArticleActionProps, FileActionProps, IArticle } from "types/article";
import styles from "./CollectionsElements.module.scss";

import DangerModal from "@components/DangerModal/DangerModal";
import { downloadFileFromBlob } from "@utils/storageBlob";

import FlagModal from "@components/FlagModal/FlagModal";
import { IOption } from "@components/Menu/types";
import NewFolderCard from "@components/NewFolderCard/NewFolderCard";
import PreviwDocument from "@components/PreviewDocument/PreviewDocument";
import ShareModal from "@components/ShareModal/ShareModal";
import UpdateCategoriesModal from "@components/UpdateCategoriesModal/UpdateCategoriesModal";

import {
  CollectionType,
  ICollection,
  INewFolder,
  ISingleCollection,
  IUserCollectionResponse,
} from "@graphql/collection/type";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { useDownloadFile } from "@utils/files/useDownloadFile";
import {
  SHARE_MODAL_TYPE_ARTICLE,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import { useSelector } from "react-redux";
import { IFolder } from "../../../store/newFolders/types";
import { RootState } from "../../../store/store";

import LinkModal from "@components/LinkModal/LinkModal";
import {
  COLLECTIONS_GRID_SIZE,
  IMAGE_CONTENT_TYPE,
  ISortValue,
  PDF,
  PDF_CONTENT_TYPE,
  PDF_EXTENSION,
  PDF_FOLDER_NAME,
  SORTING_A_Z,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
} from "@constants/constants";
import { FilesAndLinks } from "@feature/collection";
import { PreviewModal } from "@feature/file";
import { useGetLazyFileByPublicId } from "@feature/file/service/useGetLazyByPublicId/useGetLazyByPublicId";
import { GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES } from "@graphql/collection/queries";
import { COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS } from "@graphql/community-collection/queries";
import UPDATE_FILE_CATEGORIES from "@graphql/mutations/updateFileCategories";
import { IBreadcrumb } from "store/breadcrumbs/types";
import { IFile } from "types/file";
import { ILink } from "types/link";

interface ICollectionsElements {
  collection?: ISingleCollection;
  sortValue: ISortValue;
  articles: IArticle[];
  userIsCreator?: boolean;
  userIsCollaborator?: boolean;
  collectionName?: string;
  collectionType?: string;
  folders: INewFolder[];
  files: IFile[];
  getFolderName?: (folderName: string) => void;
  getFolderIdEdit?: (folderId: string) => void;
  getFileIdEdit?: (fileId: string) => void;
  renameFolder?: (folderId: string, folderName: string) => void;
  renameFile?: (fileId: string, fileName: string, fileExtenstion: string) => void;
  deleteFolder?: (folderId: string) => void;
  refetchCollection: () => void;
}

type ParamsProps = {
  id: string;
};

const CollectionsElements = ({
  collection,
  sortValue,
  folders,
  files,
  articles,
  userIsCreator,
  userIsCollaborator,
  collectionName,
  getFolderName,
  getFolderIdEdit,
  getFileIdEdit,
  renameFolder,
  renameFile,
  deleteFolder,
  refetchCollection,
  collectionType,
}: ICollectionsElements) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getFile, data: fileData } = useGetLazyFileByPublicId();

  const currentUserId = useSelector<RootState, string>(
    (state) => state.user.userPublicId
  );

  const params = useParams() as unknown as ParamsProps;
  const [articleToBeRemoved, setArticleToBeRemoved] = useState<ArticleActionProps>();
  const [fileToBeRemoved, setFileToBeRemoved] = useState<FileActionProps>();
  const [linkToBeRemoved, setLinkToBeRemoved] = useState<ILink>();
  const [linkToUpdate, setLinkToUpdate] = useState<ILink>();
  const [isOpenArticleDangerModal, setOpenArticleDangerModal] = useState<boolean>(false);
  const [isOpenFileDangerModal, setIsOpenFileDangerModal] = useState<boolean>(false);
  const [isOpenLinkDangerModal, setIsOpenLinkDangerModal] = useState<boolean>(false);
  const [isOpenUpdateLinkModal, setIsOpenUpdateLinkModal] = useState<boolean>(false);
  const [isOpenFlagModal, setOpenFlagModal] = useState<boolean>(false);
  const [articleId, setArticleId] = useState<string>("");
  const [articleShareUrl, setArticleShareUrl] = useState("");
  const [file, setFile] = useState<Blob | MediaSource>();
  const [fileToMoveId, setFileToMoveId] = useState<string>("");
  const [folderToMoveId, setFolderToMoveId] = useState<string>("");
  const [linkToMoveId, setLinkToMoveId] = useState<string>("");
  const [openPreviewDocumentModal, setOpenPreviewDocumentModal] =
    useState<boolean>(false);
  const [type, setType] = useState<string>();

  const [isMoveFileModalOpen, setIsMoveFileModalOpen] = useState<boolean>(false);
  const [isMoveFolderModalOpen, setIsMoveFolderModalOpen] = useState<boolean>(false);
  const [isMoveLinkModalOpen, setIsMoveLinkModalOpen] = useState<boolean>(false);
  const [fileToMoveName, setFileToMoveName] = useState<string>("");
  const [folderToMoveName, setFolderToMoveName] = useState<string>("");
  const [linkToMoveName, setLinkToMoveName] = useState<string>("");
  const [destinationFolderName, setDestinationFolderName] = useState<string>("");
  const [destinationCollectionName, setDestinationCollectionName] = useState<string>("");
  const [parentCollection, setParentCollection] = useState<string>("");

  const [isUpdateCategoriesModalOpen, setIsUpdateCategoriesModalOpen] =
    useState<boolean>(false);
  const [fileToUpdateId, setFileToUpdateId] = useState<string>("");
  const [previouslySelectedFileCategories, setPreviouslySelectedFileCategories] =
    useState<string[]>([]);
  const [fileCategories, setFileCategories] = useState<string[]>([]);
  const [fileToUpdateName, setFileToUpdateName] = useState<string>("");

  const [isPreviewFileModalOpen, setIsPreviewModalOpen] = useState(false);

  const [communityCollections, setCommunityCollections] = useState<ICollection[]>([]);

  const [modalActiveTab, setModalActiveTab] = useState<string>("");

  const handleSetModalActiveTab = (activeTab: string) => setModalActiveTab(activeTab);

  const [userAndCollaboratingCollections, setUserAndCollaboratingCollections] =
    useState<Array<ICollection>>();

  const CURRENT_COLLECTION: Pick<ICollection, "publicId" | "name"> = {
    publicId: params.id,
    name: collectionName!,
  };

  const newFolders = useSelector<RootState, IFolder[]>(
    (state) => state.newFolders.folders
  );
  const downloadFile = useDownloadFile();

  const [isShareModalOpen, setOpenShareModal] = useState(false);
  const [copyLink, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_ARTICLE,
  });

  const [deleteFileFromCollection, { loading: isDeleteInProgress }] = useMutation(
    DELETE_FILE,
    {
      onCompleted: () => {
        setIsOpenFileDangerModal(false);
        displaySuccessNotification(
          t("collections.collectionsActions.successDeleteFile", {
            collectionName,
          })
        );
        refetchCollection();
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  const [updateFileCategories] = useMutation(UPDATE_FILE_CATEGORIES, {
    onCompleted: () => {
      setIsUpdateCategoriesModalOpen(false);
      displaySuccessNotification(t("collections.collectionsActions.successUpdateFile"));
      refetchCollection();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const [updateLink] = useMutation(UPDATE_LINK, {
    onCompleted: () => {
      setIsOpenUpdateLinkModal(false);
      displaySuccessNotification(t("collections.collectionsActions.successUpdateLink"));
      setLinkToUpdate(undefined);
      refetchCollection();
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const linkThatFailedName = clientOptions.variables.linkUpdate.newName;

      if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: linkThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const [deleteLinkFromCollection, { loading: isDeleteLinkInProgress }] = useMutation(
    DELETE_LINK,
    {
      onCompleted: () => {
        setIsOpenLinkDangerModal(false);
        displaySuccessNotification(
          t("collections.collectionsActions.successDeleteLink", {
            collectionName,
          })
        );
        refetchCollection();
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  // GET USER AND COLLABORATING COLLECTIONS
  useQuery<IUserCollectionResponse>(GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IUserCollectionResponse) => {
      setUserAndCollaboratingCollections(
        [...data.user.myCollections, ...data.user.collaboratingCollections].sort(
          sortingCollectionsObj[SORTING_A_Z].sort
        )
      );
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  const [pinFileToCommunity] = useMutation<string>(PIN_FILE_TO_COMMUNITY, {
    onCompleted: () => {
      refetchCollection();
      displaySuccessNotification(t("collections.collectionsActions.successPinFile"));
    },
    onError: () => {
      displayNotSuccessNotification();
    },
  });

  const deleteFileFromCollectionHandler = (data: FileActionProps) => {
    const { fileName, fileDirectory } = data;

    deleteFileFromCollection({
      variables: {
        deleteFileRequest: {
          fileName,
          fileDirectory,
        },
      },
    });
  };

  const handleUpdateFileCategories = () => {
    updateFileCategories({
      variables: {
        fileUpdate: {
          filePublicId: fileToUpdateId,
          interests: fileCategories,
        },
      },
    });
  };

  const deleteLinkFromCollectionHandler = (link: ILink) => {
    deleteLinkFromCollection({
      variables: {
        deleteLinkRequest: {
          linkPublicId: link.publicId,
        },
      },
    });
  };

  const [removeAnArticleFromCollection] = useMutation(REMOVE_ARTICLE_FROM_COLLECTION, {
    onCompleted: (response) => {
      if (!response.removeAnArticleFromCollection) {
        const message = t("Remove an article from the collection was not successful");
        displayNotSuccessNotification(message, message);
        return;
      }

      displaySuccessNotification(
        t("collections.collectionsActions.successRemoveArticle", {
          collectionName,
        })
      );
      handleCloseModal();
      refetchCollection();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const MAX_FILE_NAME_LENGTH = 30;
  const MAX_LINK_NAME_LENGTH = 30;

  const checkFileNameLength = (fileName: string) => {
    if (fileName.length <= MAX_LINK_NAME_LENGTH) {
      return fileName;
    } else {
      return `${fileName.slice(0, MAX_LINK_NAME_LENGTH)}...`;
    }
  };

  const checkLinkNameLength = (linkName: string) => {
    if (linkName.length <= MAX_LINK_NAME_LENGTH) {
      return linkName;
    } else {
      return `${linkName.slice(0, MAX_LINK_NAME_LENGTH)}...`;
    }
  };

  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );

  // GET ALL COMMUNITY COLLECTIONS FOR MOVE TO MODAL
  const [getAllCommunityCollections] = useLazyQuery(
    COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        const allCommunityCollections: ICollection[] =
          res.allCommunityCollections.collections;

        // Filter only collections that were created by
        // the current user or the user is a collaborator of
        const communityCollectionsCreatedOrCollaborator = allCommunityCollections.filter(
          (collection) =>
            collection.collectionCreator.publicId === currentUserId ||
            collection.collaborators?.some(
              (collaborator) => collaborator.publicId === currentUserId
            )
        );

        setCommunityCollections(communityCollectionsCreatedOrCollaborator);
      },
      onError: (err) => displayServerError(err),
    }
  );

  useEffect(() => {
    const communityCrumb = breadcrumbs.filter((crumb) =>
      crumb.path.includes("/portal/community")
    );

    let communityId;

    if (communityCrumb.length && collectionType === CollectionType.COMMUNITY_COLLECTION) {
      communityId = communityCrumb[0].id;
    }

    if (communityId) {
      getAllCommunityCollections({
        variables: {
          communityCollectionSearch: {
            size: 1000,
            skip: 0,
            text: "",
            sendbirdId: communityId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionType]);

  const [moveFileToCollection] = useMutation(MOVE_FILE_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveFileToCollection", {
          fileToMoveName: checkFileNameLength(fileToMoveName),
          destinationCollectionName,
        })
      );
      refetchCollection();
      setIsMoveFileModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            name: fileToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3010") {
        const message = t("collections.singleCollection.fileAlreadyInCollection", {
          name: fileToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          name: checkFileNameLength(fileToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveLinkToCollection] = useMutation(MOVE_LINK_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveLinkToCollection", {
          linkToMoveName: checkLinkNameLength(linkToMoveName),
          destinationCollectionName,
        })
      );
      refetchCollection();
      setIsMoveLinkModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.linkExistsOrNotSufficientRights",
          {
            name: linkToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7004") {
        const message = t("collections.singleCollection.linkAlreadyInCollection");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: checkLinkNameLength(linkToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveLinkToFolder] = useMutation(MOVE_LINK_TO_FOLDER, {
    onCompleted: () => {
      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t("collections.collectionsActions.successMoveLinkToFolderInOtherCollection", {
              linkToMoveName: checkLinkNameLength(linkToMoveName),
              destinationFolderName,
              collectionName: parentCollection,
            })
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveLinkToFolder", {
              linkToMoveName: checkLinkNameLength(linkToMoveName),
              destinationFolderName,
            })
          );

      refetchCollection();
      setIsMoveLinkModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.linkExistsOrNotSufficientRights",
          {
            name: linkToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7004") {
        const message = t("collections.singleCollection.linkAlreadyInFolder");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: checkLinkNameLength(linkToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveFileToFolder] = useMutation(MOVE_FILE_TO_FOLDER, {
    onCompleted: () => {
      const checkFileNameLength = (fileName: string) => {
        if (fileName.length <= MAX_FILE_NAME_LENGTH) {
          return fileName;
        } else {
          return `${fileName.slice(0, MAX_FILE_NAME_LENGTH)}...`;
        }
      };

      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t("collections.collectionsActions.successMoveFileToFolderInOtherCollection", {
              fileToMoveName: checkFileNameLength(fileToMoveName),
              destinationFolderName,
              collectionName: parentCollection,
            })
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveFileToFolder", {
              fileToMoveName: checkFileNameLength(fileToMoveName),
              destinationFolderName,
            })
          );

      refetchCollection();
      setIsMoveFileModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            name: fileToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3009") {
        const message = t("collections.singleCollection.fileAlreadyInFolder", {
          name: fileToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          name: checkFileNameLength(fileToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveFolderToCollection] = useMutation(MOVE_FOLDER_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveFolderToCollection", {
          folderToMoveName,
          destinationCollectionName,
        })
      );
      refetchCollection();
      setIsMoveFolderModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "2500") {
        const message = t(
          "collections.singleCollection.folderExistsOrNotSufficientRights",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2509") {
        const message = t("collections.singleCollection.folderAlreadyInCollection", {
          name: folderToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2512") {
        const message = t(
          "collections.singleCollection.folderWithSameNameAlreadyExists",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveFolderToFolder] = useMutation(MOVE_FOLDER_TO_FOLDER, {
    onCompleted: () => {
      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t(
              "collections.collectionsActions.successMoveFolderToFolderInOtherCollection",
              {
                folderToMoveName,
                destinationFolderName,
                collectionName: parentCollection,
              }
            )
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveFolderToFolder", {
              folderToMoveName,
              destinationFolderName,
            })
          );

      refetchCollection();
      setIsMoveFolderModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "2500") {
        const message = t(
          "collections.singleCollection.folderExistsOrNotSufficientRights",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2508") {
        const message = t("collections.singleCollection.folderAlreadyInFolder", {
          name: folderToMoveName,
        });
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2512") {
        const message = t(
          "collections.singleCollection.folderWithSameNameAlreadyExists",
          {
            name: folderToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2510") {
        const message = t("collections.singleCollection.destinationFolderIsSubfolder");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "2511") {
        const message = t("collections.singleCollection.destinationFolderSameAsSource");
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const removeArticleFromCollectionHandler = (data: ArticleActionProps) => {
    const { articleId } = data;
    removeAnArticleFromCollection({
      variables: {
        removeArticleFromCollection: {
          articleId,
          collectionPublicId: params?.id,
        },
      },
    });
  };

  const handleRemoveArticle = ({ articleId, title }: ArticleActionProps) => {
    setArticleToBeRemoved({
      articleId,
      title,
    });
    setOpenArticleDangerModal(true);
  };

  const handlePinFileToCommunity = (filePublicId: string | undefined) => {
    pinFileToCommunity({
      variables: {
        filePublicId,
      },
    });
  };

  const handleFileDelete = ({ publicId, fileName, fileDirectory }: FileActionProps) => {
    setFileToBeRemoved({
      fileName,
      fileDirectory,
      publicId,
    });
    setIsOpenFileDangerModal(true);
  };

  const handleLinkUpdate = (link: ILink) => {
    setLinkToUpdate(link);
    setIsOpenUpdateLinkModal(true);
  };

  const handleLinkDelete = (link: ILink) => {
    setLinkToBeRemoved(link);
    setIsOpenLinkDangerModal(true);
  };

  const handleUpdateLink = (link: ILink) => {
    updateLink({
      variables: {
        linkUpdate: {
          newName: link.name,
          newUrl: link.url,
          linkPublicId: link.publicId,
        },
      },
    });
  };

  const handleMoveFileToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    setDestinationFolderName(lastChild!.title as string);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveFileToCollection({
        variables: {
          moveFileToCollectionRequest: {
            collectionPublicId: lastChild.key,
            filePublicId: fileToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setParentCollection(firstChild!.title as string);
      moveFileToFolder({
        variables: {
          moveFileToFolderRequest: {
            folderPublicId: lastChild.key,
            filePublicId: fileToMoveId,
          },
        },
      });
    }
  };

  const handleMoveLinkToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    setDestinationFolderName(lastChild!.title as string);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveLinkToCollection({
        variables: {
          moveLinkToCollectionRequest: {
            collectionPublicId: lastChild.key,
            linkPublicId: linkToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setParentCollection(firstChild!.title as string);
      moveLinkToFolder({
        variables: {
          moveLinkToFolderRequest: {
            folderPublicId: lastChild.key,
            linkPublicId: linkToMoveId,
          },
        },
      });
    }
  };

  const handleMoveFolderToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    setDestinationFolderName(lastChild!.title as string);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveFolderToCollection({
        variables: {
          moveFolderToCollectionRequest: {
            collectionPublicId: lastChild.key,
            folderPublicId: folderToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setParentCollection(firstChild!.title as string);
      moveFolderToFolder({
        variables: {
          moveFolderToFolderRequest: {
            destinationFolderPublicId: lastChild.key,
            sourceFolderPublicId: folderToMoveId,
          },
        },
      });
    }
  };

  const handleFilePreview = ({ originalFileName, fileDirectory }: FileActionProps) => {
    const file = originalFileName;
    const directory = fileDirectory;
    const fileExt = originalFileName!.split(".").pop();
    let convertFile = false;
    if (supportedOfficeExtensions.includes("." + fileExt!) && fileExt !== PDF) {
      fileDirectory = fileDirectory + PDF_FOLDER_NAME;
      originalFileName = originalFileName!.replace("." + fileExt, PDF_EXTENSION);
      convertFile = true;
      setType(PDF_CONTENT_TYPE);
    } else {
      convertFile = false;
      setType(IMAGE_CONTENT_TYPE);
    }
    downloadFile(originalFileName!, fileDirectory!, false).then((res) => {
      if (res !== null) {
        if (!notSupportedExtensionsForDocumentPreview.includes("." + fileExt!)) {
          setFile(res);
          setOpenPreviewDocumentModal(true);
        } else {
          const message = t(
            "collections.collectionsActions.formatNotSupportedForPreview"
          );
          displayNotSuccessNotification(message, message);
        }
      } else {
        const message = t("collections.collectionsActions.documentNotReady");
        displayNotSuccessNotification(message, message);
        const convertToPdfRequest = {
          fileName: file,
          directoryName: directory,
        };
        if (convertFile) {
          try {
            ConvertFileToPdf({
              variables: { convertToPdfRequest },
            });
          } catch (error) {
            displayNotSuccessNotification();
          }
        }
      }
    });
  };

  const handleFlagArticle = (articleId: string) => {
    setOpenFlagModal(true);
    setArticleId(articleId);
  };

  const handleCloseFileModal = () => {
    setIsOpenFileDangerModal(false);
    setFileToBeRemoved(undefined);
  };

  const handleCloseUpdateLinkModal = () => {
    setIsOpenUpdateLinkModal(false);
    setLinkToUpdate(undefined);
  };

  const handleCloseDeleteLinkModal = () => {
    setIsOpenLinkDangerModal(false);
    setLinkToBeRemoved(undefined);
  };

  const handleCloseModal = () => {
    setOpenArticleDangerModal(false);
    setArticleToBeRemoved(undefined);
  };

  const title = (title: string) => {
    return (
      <span aria-label={"title"} className={styles.title}>
        {title}
      </span>
    );
  };

  const summary = (summary: string | undefined) => {
    return (
      <>
        <div aria-label={"summary"} className={styles.summaryContainer}>
          <span className={styles.summaryTitle}>{t("singleArticle.summary")}</span>
          <span className={styles.summaryContent}>{summary}</span>
        </div>
      </>
    );
  };

  const content = (element: JSX.Element) => {
    return (
      <div aria-label={"content"} className={styles.contentContainer}>
        <span className={styles.content}>{element}</span>
      </div>
    );
  };

  const downloadArticle = (article: IArticle) => {
    if (!article) return;

    const parsedArticleTitle = parseFileName(article.title);
    const replacedArticleContent = article.content.replace(/<a[^>]*>|<\/a>/g, "");

    const parsedArticleContentElement: JSX.Element = (
      <div dangerouslySetInnerHTML={{ __html: replacedArticleContent }}></div>
    );

    const html = ReactDOMServer.renderToStaticMarkup(
      <div className={styles.container}>
        {title(article.title)}
        {summary(article?.summary)}
        {content(parsedArticleContentElement)}
      </div>
    );

    const downloadUrl = `${process.env.REACT_APP_BASE_URL}/public-download-articles/${article.id}/${parsedArticleTitle}.pdf`;

    const request = new XMLHttpRequest();
    request.open("GET", downloadUrl, true);
    request.send();

    request.onload = () => {
      request.status === 200
        ? downloadFileFromBlob(`${parsedArticleTitle}.pdf`, downloadUrl)
        : ArticleService.uploadArticle(article.id, parsedArticleTitle, html)
            .then(() => {
              downloadFileFromBlob(`${parsedArticleTitle}.pdf`, downloadUrl);
            })
            .catch((error) => {
              displayServerError(error);
            });
    };
  };

  const articleMenuDots = (article: IArticle) => {
    const options = [
      {
        label: "download",
        action: () => {
          downloadArticle(article);
        },
      },
      {
        label: "share",
        action: () => {
          setOpenShareModal(true);
          setArticleShareUrl(
            window.location.origin + `/portal/resources/article/${article.id}`
          );
        },
      },
      {
        label: t("flagAsInappropriate"),
        action: () => handleFlagArticle(article.id),
      },
    ];

    if (userIsCreator) {
      const articleOptions: ArticleActionProps = {
        articleId: article.id,
        title: article.title,
      };

      options.push({
        label: t("removeFromCollection"),
        action: () => handleRemoveArticle(articleOptions),
      });
    }

    return options;
  };

  const folderMenuDots = (folder: INewFolder) => {
    const options: IOption[] = [];
    const { publicId, isEditable } = folder;

    if (!isEditable) {
      return options;
    }
    if (userIsCreator || userIsCollaborator) {
      options.push(
        {
          label: "rename",
          action: () => {
            if (getFolderIdEdit) getFolderIdEdit(publicId);
          },
        },
        {
          label: "move",
          action: () => {
            setIsMoveFolderModalOpen(true);
            setFolderToMoveId(folder.publicId);
            setFolderToMoveName(folder.name);
          },
        }
      );
    }

    if (folder.canUserDeleteFolder) {
      options.push({
        isDelete: true,
        label: "delete",
        action: () => {
          if (deleteFolder) deleteFolder(publicId);
        },
      });
    }

    return options;
  };

  const handleFolderNameSubmit = (folderName: string) => {
    if (folders?.find((folder) => folder.name === folderName)) {
      const message = t("collections.singleCollection.folderWithSameNameAlreadyExists", {
        name: folderName,
      });
      displayNotSuccessNotification(message, message);

      return;
    }

    if (getFolderName) getFolderName(folderName);
  };

  const handleFolderNameRename = (folderId: string, folderName: string) => {
    if (folders?.find((folder) => folder.name === folderName)) {
      const message = t("collections.singleCollection.folderWithSameNameAlreadyExists", {
        name: folderName,
      });
      displayNotSuccessNotification(message, message);

      return;
    }

    if (renameFolder) renameFolder(folderId, folderName);
  };

  const goToFolder = (folderId: string) => {
    const collectionId = params.id;
    history.push({
      pathname: `/portal/collection/${collectionId}/${folderId}`,
      state: {
        collectionId: params.id,
        folderId,
      },
    });
  };

  const getFileCategories = (fileId: string, fileName: string) => {
    const fileCategories = files
      .filter((file) => file.publicId === fileId)[0]
      .interests?.map((category) => ({
        name: category,
        _typename: "Category",
      }))
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .map((category: any) => category.name.name);

    setFileToUpdateId(fileId);
    setFileToUpdateName(fileName);
    setFileCategories(fileCategories!);
    setPreviouslySelectedFileCategories(fileCategories!);
  };

  const fileMenuDots = useCallback(
    ({
      publicId,
      fileName,
      fileDirectory,
      filePublicId,
      isPinnable,
      canUserDeleteFile,
      originalFileName,
    }: FileActionProps) => {
      let options: IOption[] = [
        {
          label: "download",
          action: () => {
            downloadFile(originalFileName!, fileDirectory!);
          },
        },
        {
          label: "preview",
          action: () => handleFilePreview({ originalFileName, fileDirectory, publicId }),
        },
        {
          label: "moreInfo",
          action: () => {
            getFile({
              fetchPolicy: "no-cache",
              variables: { publicId },
              onCompleted: () => setIsPreviewModalOpen(true),
            });
          },
        },
      ];

      if (userIsCreator || userIsCollaborator) {
        options = [
          ...options,
          {
            label: "renameFile",
            action: () => {
              if (getFileIdEdit) getFileIdEdit(filePublicId!);
            },
          },
          {
            label: "move",
            action: () => {
              setIsMoveFileModalOpen(true);
              setFileToMoveId(filePublicId!);
              setFileToMoveName(fileName!);
            },
          },
        ];
      }

      if (canUserDeleteFile) {
        options = [
          ...options,
          {
            isDelete: true,
            label: "delete",
            action: () => handleFileDelete({ fileName, fileDirectory, publicId }),
          },
        ];
      }
      if (isPinnable && collectionType === CollectionType.COMMUNITY_COLLECTION) {
        options = [
          {
            label: "pin",
            action: () => handlePinFileToCommunity(filePublicId),
          },
          ...options,
        ];
      }

      if (
        collectionType === CollectionType.COMMUNITY_COLLECTION &&
        (userIsCreator || userIsCollaborator)
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // This is here because Update Categories needs to be bellow the Rename file option
        options = options.toSpliced(-2, 0, {
          label: "updateCategories",
          action: () => {
            setIsUpdateCategoriesModalOpen(true);
            getFileCategories(filePublicId!, fileName!);
          },
        });
      }

      return options;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionType, userIsCollaborator, userIsCreator, getFileIdEdit]
  );

  const linkMenuDots = useCallback(
    (link: ILink) => {
      let options: IOption[] = [];

      if (userIsCreator || userIsCollaborator) {
        options = [
          ...options,
          {
            label: "update",
            action: () => handleLinkUpdate(link),
          },
          {
            label: "move",
            action: () => {
              setIsMoveLinkModalOpen(true);
              setLinkToMoveId(link.publicId);
              setLinkToMoveName(link.name);
            },
          },
        ];
      }

      if (link.canUserDeleteLink) {
        options = [
          ...options,
          {
            isDelete: true,
            label: "delete",
            action: () => handleLinkDelete(link),
          },
        ];
      }

      return options;
    },
    [userIsCollaborator, userIsCreator]
  );

  const collectionsForMoveModal =
    collectionType === CollectionType.USER_COLLECTION
      ? userAndCollaboratingCollections
      : communityCollections;

  const collectionsTabTitle =
    collectionType === CollectionType.USER_COLLECTION
      ? "personalTabTitle"
      : "personalCommunityTabTitle";

  return (
    <div
      className={styles.outer}
      data-testid="collections-articles-container"
      e2e-test-id="collection-contents-container"
    >
      <div>
        <div data-test-id="collections-article-inner" className={styles.articlesGrid}>
          {/* Display folder from store that are in process of creation */}
          {newFolders &&
            newFolders.map((folder) => (
              <NewFolderCard
                key={folder.id}
                name={folder.name}
                isEdit
                folderNameSubmit={handleFolderNameSubmit}
              />
            ))}

          {folders.map((folder) => (
            <NewFolderCard
              key={folder.publicId}
              id={folder.publicId}
              name={folder.name}
              isEdit={folder.isEdit}
              menuOptions={folderMenuDots(folder)}
              renameFolder={handleFolderNameRename}
              onClick={goToFolder}
            />
          ))}
          <FilesAndLinks
            element={collection}
            files={files}
            sortValue={sortValue}
            renameFile={renameFile}
            linkMenuDots={linkMenuDots}
            fileMenuDots={fileMenuDots}
          />

          {/* Display articles */}
          {articles &&
            articles.map((article: IArticle) => {
              return (
                <ArticleCard
                  key={article.id}
                  article={article}
                  onClick={(id) => ArticleService.getArticleById(id)}
                  menuOptions={articleMenuDots(article)}
                  currentLoadedPage={Math.ceil(articles.length / COLLECTIONS_GRID_SIZE)}
                  initialPage={window.location.pathname}
                />
              );
            })}
        </div>
      </div>

      {articleToBeRemoved && (
        <DangerModal
          title={t("collections.collectionsActions.removeArticleTitle", {
            articleName: articleToBeRemoved.title,
          })}
          subTitle={t("collections.collectionsActions.removeArticleSubTitle")}
          isOpen={isOpenArticleDangerModal}
          onClose={() => handleCloseModal()}
          onSubmit={() => removeArticleFromCollectionHandler({ ...articleToBeRemoved })}
          image={<img src={DangerIcon} />}
          closeButton={t("collections.collectionsActions.keepArticle")}
          ctaButton={t("collections.collectionsActions.removeArticle")}
        />
      )}

      {fileToBeRemoved && (
        <DangerModal
          title={t("collections.collectionsActions.deleteFileTitle", {
            fileName: fileToBeRemoved.fileName,
          })}
          subTitle={t("collections.collectionsActions.deleteFileSubTitle")}
          isOpen={isOpenFileDangerModal}
          onClose={() => handleCloseFileModal()}
          onSubmit={() => deleteFileFromCollectionHandler({ ...fileToBeRemoved })}
          image={<img src={DangerIcon} />}
          closeButton={t("collections.collectionsActions.keepFile")}
          ctaButton={t("collections.collectionsActions.deleteFile")}
          isLoading={isDeleteInProgress}
        />
      )}

      {linkToBeRemoved && (
        <DangerModal
          title={t("collections.collectionsActions.deleteLinkTitle", {
            linkName: linkToBeRemoved.name,
          })}
          subTitle={t("collections.collectionsActions.deleteLinkSubTitle")}
          isOpen={isOpenLinkDangerModal}
          onClose={handleCloseDeleteLinkModal}
          onSubmit={() => deleteLinkFromCollectionHandler(linkToBeRemoved)}
          image={<img src={DangerIcon} />}
          closeButton={t("collections.collectionsActions.keepLink")}
          ctaButton={t("collections.collectionsActions.deleteLink")}
          isLoading={isDeleteLinkInProgress}
        />
      )}

      <FlagModal
        open={isOpenFlagModal}
        close={() => setOpenFlagModal(false)}
        articleId={articleId}
      />
      {isMoveFileModalOpen && (
        <CollectionsTreeModal
          showCurrentAndMyCollections
          collectionsTabTitle={collectionsTabTitle}
          handleSetModalActiveTab={handleSetModalActiveTab}
          collections={collectionsForMoveModal}
          treeModalType={TREE_MODAL_TYPE.move}
          isOpen={isMoveFileModalOpen}
          currentCollection={CURRENT_COLLECTION}
          onCancel={() => setIsMoveFileModalOpen(false)}
          onSelect={handleMoveFileToSelect}
          modalType="primary"
        />
      )}
      {linkToUpdate && (
        <LinkModal
          link={linkToUpdate}
          isOpen={isOpenUpdateLinkModal}
          onCloseModal={handleCloseUpdateLinkModal}
          onSubmitLink={handleUpdateLink}
        />
      )}
      {isMoveFolderModalOpen && (
        <CollectionsTreeModal
          showCurrentAndMyCollections
          collectionsTabTitle={collectionsTabTitle}
          handleSetModalActiveTab={handleSetModalActiveTab}
          collections={collectionsForMoveModal}
          treeModalType={TREE_MODAL_TYPE.move}
          isOpen={isMoveFolderModalOpen}
          currentCollection={CURRENT_COLLECTION}
          onCancel={() => setIsMoveFolderModalOpen(false)}
          onSelect={handleMoveFolderToSelect}
          modalType="primary"
        />
      )}
      {isMoveLinkModalOpen && (
        <CollectionsTreeModal
          showCurrentAndMyCollections
          collectionsTabTitle={collectionsTabTitle}
          handleSetModalActiveTab={handleSetModalActiveTab}
          collections={collectionsForMoveModal}
          treeModalType={TREE_MODAL_TYPE.move}
          isOpen={isMoveLinkModalOpen}
          currentCollection={CURRENT_COLLECTION}
          onCancel={() => setIsMoveLinkModalOpen(false)}
          onSelect={handleMoveLinkToSelect}
          modalType="primary"
        />
      )}
      {isUpdateCategoriesModalOpen && (
        <UpdateCategoriesModal
          fileName={fileToUpdateName}
          selectedCategories={fileCategories}
          previouslySelectedFileCategories={previouslySelectedFileCategories}
          isOpen={isUpdateCategoriesModalOpen}
          close={() => setIsUpdateCategoriesModalOpen(false)}
          handleUpdateCategories={setFileCategories}
          handleSubmitFileUpdateCategories={handleUpdateFileCategories}
        />
      )}
      <ShareModal
        customUrl={articleShareUrl}
        copyLink={copyLink}
        open={isShareModalOpen}
        close={() => setOpenShareModal(false)}
        share={shareSuccessMessage}
      />
      <PreviwDocument
        open={openPreviewDocumentModal}
        close={() => setOpenPreviewDocumentModal(false)}
        file={file!}
        type={type!}
      />
      <PreviewModal
        isOpen={isPreviewFileModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        file={fileData?.fileInfo}
      />
    </div>
  );
};

export default CollectionsElements;
