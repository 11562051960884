import { Collapse as AntdCollapse } from "antd";
import styled from "styled-components";
import palette from "./../../../scss/exports.module.scss";

import { ColorType } from "../Layout/CollapseFeature";

const StyledAntdCollapse = styled(AntdCollapse)<{ $bgColor: ColorType }>`
  > .ant-collapse-item-active.ant-collapse-item:before {
    background-color: ${(props) =>
      props.$bgColor === ColorType.Blue ? palette.primaryBlue : palette.secondaryBlue};
  }
`;

const { Panel } = AntdCollapse;

export interface ItemsProps {
  id: string;
  label: string;
  image: {
    src: string;
    width: string;
    height: string;
  };
  text: string;
}

interface Props {
  items: ItemsProps[];
  bgColor: ColorType;
  activeItem: string;
  callback?: (key: string | string[]) => void;
  collapseType?: string;
}

const Collapse = ({ items, callback, bgColor, activeItem, collapseType }: Props) => {
  return (
    <StyledAntdCollapse
      collapsible="header"
      $bgColor={bgColor}
      ghost={true}
      accordion={true}
      defaultActiveKey={[activeItem]}
      onChange={callback}
      activeKey={activeItem}
    >
      {items.map((item) => (
        <Panel
          showArrow={false}
          header={item?.label}
          key={item.id}
          style={collapseType === "sectionThree" ? { opacity: "0.85" } : {}}
        >
          <p style={{ lineHeight: 1.2, letterSpacing: 0 }}>{item?.text}</p>
        </Panel>
      ))}
    </StyledAntdCollapse>
  );
};

export { Collapse };
