/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import SearchInput from "@components/SearchInput/SearchInput";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";
import GET_GENERAL_USERS_INFORMATION from "@graphql/queries/getGeneralUsersInformation";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Avatar from "@images/avatar.svg";
import Checked from "@images/checked.svg";
import Exit from "@images/exit.svg";
import BlackIcon from "@images/search_icon.svg";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import getStorageImage from "@utils/getStorageImage";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SendBird from "sendbird";
import { IUser, IUserImageResponse } from "types/user";
import styles from "./AddGroupMemberModal.module.scss";

interface IAddGroupMemberModal {
  isOpen: boolean;
  onClose: () => void;
  sendBirdGroupGroupChannel: IUseSendBirdGroupChannel;
}

const AddGroupMemberModal = ({
  sendBirdGroupGroupChannel: {
    state: { groupChannel },
  },
  onClose,
  isOpen,
}: IAddGroupMemberModal) => {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [allUsers, setAllUsers] = useState<Array<IUser>>([]);
  const [addUsers, setAddUsers] = useState<Array<string>>([]);
  const [search, setSearch] = useState<string>("");

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImage] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages;

      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const { data } = useQuery(GET_GENERAL_USERS_INFORMATION);

  useEffect(() => {
    if (!data?.users || !groupChannel) return;

    const memberEmails = groupChannel.members.map(
      (member: SendBird.User) => member.userId
    );

    const usersExcludingMembers = data.users.users.filter(
      (user: IUser) => !memberEmails.includes(user.email)
    );

    setUsers(usersExcludingMembers);
    setAllUsers(usersExcludingMembers);
  }, [data, groupChannel]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredUsers = allUsers.filter((user) =>
      user.fullName.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setUsers(filteredUsers);
    setSearch(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const addToInvite = (email: string) => {
    setAddUsers([...addUsers, email]);
  };

  const removeFromInvite = (email: string) => {
    const newArray = addUsers.filter((existEmail) => {
      return existEmail !== email;
    });
    setAddUsers(newArray);
  };

  const inviteUsers = () => {
    const userIds = addUsers;

    groupChannel?.inviteWithUserIds(userIds, (__, error) => {
      if (error) {
        displayNotSuccessNotification(error);
        return;
      }
    });
  };

  const handleClose = () => {
    setSearch("");
    setAddUsers([]);
    onClose();
  };

  useEffect(() => {
    users.length > 0 &&
      getUserImage({
        variables: {
          profileImagesInput: {
            userEmails: users.map((user) => user.email),
          },
        },
      });
  }, [allUsers]);

  return (
    <Modal onRequestClose={handleClose} isOpen={isOpen}>
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>{t("chat.addMemberModal.title")}</h4>
        <img className={styles.closeImage} onClick={handleClose} src={Exit} alt="" />
      </div>
      <div className={styles.subtitle}>{t("chat.addMemberModal.subtitle")}</div>
      <form onSubmit={handleSubmit}>
        <SearchInput
          searchIcon={<img src={BlackIcon} alt="" />}
          name="invite"
          placeholder={t(`chat.group.search`)}
          value={search}
          onChange={(e) => handleSearch(e)}
          className={styles.searchBar}
        />
      </form>
      <div className={styles.memberList}>
        {users.map((member, index) => {
          const profileImage = userImages
            ? userImages?.find((image) => image.email === member.email)?.image
            : Avatar;
          return (
            <div
              key={index}
              className={styles.memberItem}
              onClick={() =>
                addUsers.includes(member.email)
                  ? removeFromInvite(member.email)
                  : addToInvite(member.email)
              }
            >
              <div
                style={{ backgroundImage: `url(${profileImage})` }}
                className={styles.memberImage}
              ></div>
              <div>
                <div className={styles.memberName}>{member.fullName}</div>
                <div className={styles.memberPosition}>
                  {i18n.exists(`sector.${member?.organization?.sector?.name}`)
                    ? t(`sector.${member?.organization?.sector?.name}`)
                    : member?.organization?.sector?.name}
                </div>
              </div>

              {addUsers.includes(member.email) ? (
                <img src={Checked} alt="" className={styles.checked} />
              ) : (
                <div className={styles.unChecked} />
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          title={t("chat.addMemberModal.cancel")}
          buttonType="link"
          onClick={handleClose}
        />
        <Button
          title={t("chat.addMemberModal.button")}
          buttonType="primary"
          disabled={!addUsers.length}
          onClick={() => {
            inviteUsers();
            handleClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default AddGroupMemberModal;
