/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import MapChart from "@components/Map/MapChart";
import SelectComponent from "@components/Select/Select";
import { GLOBAL_LOCATION } from "@constants/constants";
import GET_COUNTRIES from "@graphql/queries/countries";
import SearchIcon from "@images/search_icon.svg";
import LocationService from "@services/LocationService";
import { ILocation } from "types/location";
import { addLocations } from "../../store/onboarding/actions";
import { OnboardingState } from "../../store/onboarding/types";
import { RootState } from "../../store/store";
import styles from "./Locations.module.scss";

const Locations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userData = useSelector<RootState, OnboardingState>((state) => state.onboarding);
  const [selectedLocations, setSelectedLocations] = useState<Array<ILocation>>(
    userData.locations
  );
  const [locations, setLocations] = useState<Array<ILocation>>([]);
  const [allLocations, setAllLocations] = useState<Array<ILocation>>([]);

  const { data: countriesData, loading: countriesLoading } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    if (!countriesData) return;
    const generatedAllLocations = LocationService.generateAllLocationsArray([
      { name: t(GLOBAL_LOCATION), callingCode: "" },
      ...countriesData.countries,
    ]);
    !selectedLocations
      ? setLocations(generatedAllLocations)
      : setLocations(
          LocationService.filterSelectedLocationsFromAll(
            generatedAllLocations,
            selectedLocations
          )
        );
    setAllLocations(generatedAllLocations);
  }, [countriesData]);

  useEffect(() => {
    if (!locations.length) return;
    setLocations(
      LocationService.filterSelectedLocationsFromAll(allLocations, selectedLocations)
    );
  }, [selectedLocations]);

  const handleChangeCountry = (selectedLocationsInput: Array<ILocation>) => {
    const filterCitiesIfCountryIsSelected =
      LocationService.filterCitiesIfCountryIsSelected(selectedLocationsInput);

    const displayCountryIfAllCitiesSelected =
      LocationService.displayCountryIfAllCitiesSelected(
        filterCitiesIfCountryIsSelected,
        locations
      );
    setSelectedLocations([...displayCountryIfAllCitiesSelected]);
    dispatch(addLocations([...displayCountryIfAllCitiesSelected]));
  };

  const handleForm = () => {
    history.push("/onboarding/interests-and-social-media");
  };

  const disableNext = selectedLocations.length === 0;

  const mapForSelect = (locations: Array<ILocation>) => {
    const translatedMap = LocationService.mapLocationForSelect(locations)?.map(
      (location) => {
        return { ...location, label: t(`countries.${location.value}`) };
      }
    );

    return translatedMap;
  };

  const markCountryOnMap = () => {
    const isGlobalSelected = selectedLocations.some(
      (location) => location.name === GLOBAL_LOCATION
    );

    if (allLocations && isGlobalSelected) {
      return LocationService.mapCountryDataForMap(allLocations);
    } else {
      return LocationService.mapCountryDataForMap(selectedLocations);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mapContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.textContainer}>
            <span className={styles.heading}>{t("locations.heading")}</span>
            <span className={styles.subheading}>{t("locations.subheading")}</span>
          </div>
          {countriesLoading ? (
            <Loading borderless disableBoxShadow />
          ) : (
            <div className={styles.input}>
              <SelectComponent
                options={mapForSelect(locations)}
                placeholder={t("locations.placeholder")}
                name="country"
                onChange={handleChangeCountry}
                value={mapForSelect(selectedLocations)}
                icon={SearchIcon}
                selectWithIcon
                showDropdown
                isMulti
                isSearchable
                virtualized
              />
            </div>
          )}
        </div>
        <div className={styles.mapContainer}>
          <MapChart
            selectedCountry={markCountryOnMap()}
            className={styles.mapStyle}
            projectionConfig={{ center: [0, 10], scale: 180 }}
          />
        </div>
        <div className={styles.outsideWrap}>
          <div className={styles.insideWrap}>
            <BackButton path="/onboarding/interests" label={t("locations.button")} />
            <div className={styles.nextButton}>
              <Button
                aria-label="next-step"
                title={t("locations.nextButton")}
                onClick={handleForm}
                disabled={disableNext}
                buttonType="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
