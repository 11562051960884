import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";

import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import styles from "./PlatformStatistics.module.scss";

import { useMutation, useQuery } from "@apollo/client";
import UPDATE_PLATFORM_STATISTICS from "@graphql/mutations/updatePlatformStatistics";
import { GET_PLATFORM_STATISTICS } from "@graphql/platform-statistics/queries";
import { IPlatformStatistics } from "@graphql/platform-statistics/type";

const PlatformStatistics = () => {
  const { t } = useTranslation();
  const MAX_INPUT_LENGTH = 4;
  const [editInterestsField, setEditInterestsField] = useState(false);
  const [numberOfCountries, setNumberOfCountries] = useState<string | undefined>();
  const [numberOfLanguages, setNumberOfLanguages] = useState<string | undefined>();
  const [numberOfViews, setNumberOfViews] = useState<string | undefined>();
  const [updatePlatformStatistics] = useMutation(UPDATE_PLATFORM_STATISTICS);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>();

  const [originalNumberOfCountries, setOriginalNumberOfCountries] = useState<
    string | undefined
  >();
  const [originalNumberOfLanguages, setOriginalNumberOfLanguages] = useState<
    string | undefined
  >();
  const [originalNumberOfViews, setOriginalNumberOfViews] = useState<
    string | undefined
  >();

  useQuery<IPlatformStatistics>(GET_PLATFORM_STATISTICS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IPlatformStatistics) => {
      setOriginalNumberOfCountries(data.platformStatistics.membersCountriesCount);
      setNumberOfCountries(data.platformStatistics.membersCountriesCount);

      setOriginalNumberOfLanguages(data.platformStatistics.availableLanguagesCount);
      setNumberOfLanguages(data.platformStatistics.availableLanguagesCount);

      setOriginalNumberOfViews(data.platformStatistics.viewsCount);
      setNumberOfViews(data.platformStatistics.viewsCount);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const handleStatisticsSave = () => {
    updatePlatformStatistics({
      variables: {
        platformStatisticsUpdate: {
          membersCountriesCount: numberOfCountries,
          availableLanguagesCount: numberOfLanguages,
          viewsCount: numberOfViews,
        },
      },
      onCompleted: () => {
        setOriginalNumberOfCountries(numberOfCountries);
        setOriginalNumberOfLanguages(numberOfLanguages);
        setOriginalNumberOfViews(numberOfViews);

        displaySuccessNotification();
      },
      onError: (error) => {
        setNumberOfCountries(originalNumberOfCountries);
        setNumberOfLanguages(originalNumberOfLanguages);
        setNumberOfViews(originalNumberOfViews);

        displayNotSuccessNotification(error);
      },
    });
  };

  useEffect(() => {
    if (!numberOfCountries || !numberOfLanguages || !numberOfViews) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [numberOfCountries, numberOfViews, numberOfLanguages]);

  return (
    <>
      <div className={styles.container}>
        <span className={styles.title}>
          {t("adminPanel.sidemenu.platformStatistics")}
        </span>
        <div className={styles.fieldContainer}>
          <div className={styles.rowContainer}>
            <span className={styles.rowTitle}>
              {t("adminPanel.platformStatistics.microsite")}
            </span>
            <Button
              title={
                editInterestsField
                  ? t("accountSettings.personalDetails.button.cancel")
                  : t("accountSettings.personalDetails.button.edit")
              }
              onClick={() => {
                if (editInterestsField) {
                  setNumberOfCountries(originalNumberOfCountries);
                  setNumberOfLanguages(originalNumberOfLanguages);
                  setNumberOfViews(originalNumberOfViews);
                }

                setEditInterestsField(!editInterestsField);
              }}
              buttonType="link"
              data-testid="edit-field"
            />
          </div>
          {editInterestsField ? (
            <>
              <div className={styles.numberOfViews}>
                {t("adminPanel.platformStatistics.membersOfCommunities")}
                <div className={styles.numberOfViewsInput}>
                  <Input
                    maxLength={MAX_INPUT_LENGTH}
                    name=""
                    label=""
                    labelClassNames={styles.inputLabel}
                    value={numberOfCountries}
                    onChange={(e) => setNumberOfCountries(e.target.value)}
                  />
                </div>
                {t("adminPanel.platformStatistics.countries")}
              </div>
              <div className={styles.numberOfViews}>
                {t("adminPanel.platformStatistics.hivePlatform")}
                <div className={styles.numberOfViewsInput}>
                  <Input
                    maxLength={MAX_INPUT_LENGTH}
                    name=""
                    label=""
                    labelClassNames={styles.inputLabel}
                    value={numberOfLanguages}
                    onChange={(e) => setNumberOfLanguages(e.target.value)}
                  />
                </div>
                {t("adminPanel.platformStatistics.languages")}
              </div>
              <div className={styles.numberOfViews}>
                <div className={styles.numberOfViewsInput}>
                  <Input
                    maxLength={MAX_INPUT_LENGTH}
                    name=""
                    label=""
                    labelClassNames={styles.inputLabel}
                    value={numberOfViews}
                    onChange={(e) => setNumberOfViews(e.target.value)}
                  />
                </div>
                {t("adminPanel.platformStatistics.views")}
              </div>
              <Button
                title={t("accountSettings.interestsAndSocialMedia.button.save")}
                buttonType="primary"
                disabled={isSaveDisabled}
                onClick={() => {
                  handleStatisticsSave();
                  setEditInterestsField(false);
                }}
              />
            </>
          ) : (
            <>
              <div>
                <p>
                  {t("adminPanel.platformStatistics.membersOfCommunities")}&nbsp;
                  {numberOfCountries}&nbsp;
                  {t("adminPanel.platformStatistics.countries")}
                </p>
                <p>
                  {t("adminPanel.platformStatistics.hivePlatform")}&nbsp;
                  {numberOfLanguages}&nbsp;
                  {t("adminPanel.platformStatistics.languages")}
                </p>
                <p>
                  {numberOfViews}&nbsp;
                  {t("adminPanel.platformStatistics.views")}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PlatformStatistics;
