import { gql } from "@apollo/client";

const ADD_FILES_TO_FOLDER = gql`
  mutation addFilesToFolder($addFilesToFolder: AddFilesToFolderInputType!) {
    addFilesToFolder(addFilesToFolder: $addFilesToFolder) {
      files {
        name
      }
    }
  }
`;

export default ADD_FILES_TO_FOLDER;
