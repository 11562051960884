/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import {
  CollectionsTreeNode,
  NodeIcon,
  useCollectionsTree,
} from "@components/CollectionsTree";
import CommunitiesList from "@components/CommunitiesList/CommunitiesList";
import Loading from "@components/Loading/Loading";
import Modal, { IModalProps } from "@components/Modal/Modal";
import Tree from "@components/Tree/Tree";
import { TREE_MODAL_TYPE } from "@constants/constants";
import { ICollection } from "@graphql/collection/type";
import {
  GET_DEFAULT_COMMUNITY_COLLECTION_FOR_GO_TO_MODAL,
  GET_PRIVATE_COMMUNITY_COLLECTIONS,
  GET_PUBLIC_COMMUNITY_COLLECTIONS,
  GET_USER_COMMUNITIES_FOR_GO_TO_MODAL,
} from "@graphql/community/queries";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { sortByString } from "@utils/helpers";
import { Tabs } from "antd";
import { TabsProps } from "antd/es/tabs";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Key, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommunity, IUserCommunitiesResponse } from "types/community";
import styles from "./CollectionsTreeModal.module.scss";

const TABS = {
  current: "Current",
  personal: "Personal",
  community: "Community",
};

dayjs.extend(localizedFormat);
interface CollectionsTreeModalProps extends Omit<IModalProps, "children"> {
  showCurrentCollectionOnly?: boolean;
  showCurrentAndMyCollections?: boolean;
  collectionsTabTitle?: string;
  treeModalType: TREE_MODAL_TYPE;
  collections?: { publicId: string; name: string }[];
  currentCollection?: Pick<ICollection, "publicId" | "name">;
  onCancel: () => void;
  handleSetModalActiveTab?: (activeTab: string) => void;
  onSelect: (node: CollectionsTreeNode[] | undefined) => void;
}

const CollectionsTreeModal = ({
  showCurrentCollectionOnly = false,
  showCurrentAndMyCollections = false,
  collectionsTabTitle = "personalTabTitle",
  handleSetModalActiveTab,
  treeModalType,
  isOpen,
  collections,
  currentCollection,
  onCancel,
  onSelect,
}: CollectionsTreeModalProps) => {
  const [selectedCurrentCollectionPath, setSelectedCurrentCollectionPath] =
    useState<CollectionsTreeNode[]>();
  const [selectedUserCollectionPath, setSelectedUserCollectionPath] =
    useState<CollectionsTreeNode[]>();
  const [selectedCommunityCollectionPath, setSelectedCommunityCollectionPath] =
    useState<CollectionsTreeNode[]>();

  const [activeTabKey, setActiveTabKey] = useState(TABS.personal);
  const [selectedCommunity, setSelectedCommunity] = useState<ICommunity>();
  const [communityCollections, setCommunityCollections] = useState<ICollection[]>();
  const { t } = useTranslation();

  const currentCollectionTreeInitialData: CollectionsTreeNode[] | undefined =
    useMemo(() => {
      return currentCollection
        ? [
            {
              key: currentCollection.publicId,
              title: currentCollection!.name,
              type: "collection",
              isLeaf: false,
            },
          ]
        : undefined;
    }, [currentCollection, isOpen]);

  const pathSelectHandler = (path: CollectionsTreeNode[] | undefined) => {
    switch (true) {
      case activeTabKey === TABS.current:
        setSelectedCurrentCollectionPath(path);
        break;
      case activeTabKey === TABS.personal:
        setSelectedUserCollectionPath(path);
        break;
      case activeTabKey === TABS.community:
        setSelectedCommunityCollectionPath(path);
        break;
    }
  };

  const goToHandler = () => {
    let selectedPath;
    switch (true) {
      case activeTabKey === TABS.current:
        selectedPath = selectedCurrentCollectionPath;
        break;
      case activeTabKey === TABS.personal:
        selectedPath = selectedUserCollectionPath;
        break;
      case activeTabKey === TABS.community:
        selectedPath = selectedCommunityCollectionPath;
        break;
    }
    onSelect(selectedPath);
  };

  const getTitle = (node: CollectionsTreeNode) => {
    const collectionsTree =
      activeTabKey === TABS.personal
        ? userCollectionsTree
        : activeTabKey === TABS.community
        ? communityCollectionsTree
        : currentCollectionTree;
    const path = collectionsTree.getNodePath(node.key, true);
    const depth = path ? path.length - 1 : 0;
    const className = `collections-tree-node-title ${node.type}`;
    const indent = 24 * depth;
    let nameMaxWidth = Math.max(30, 425 - indent);
    let datePart = null;

    if (node.updateTime && node.type === "folder") {
      let selectedKeys: Key[] = [];
      switch (true) {
        case activeTabKey === TABS.current:
          selectedKeys = currentCollectionTree.selectedKeys;
          break;
        case activeTabKey === TABS.personal:
          selectedKeys = userCollectionsTree.selectedKeys;
          break;
        case activeTabKey === TABS.community:
          selectedKeys = communityCollectionsTree.selectedKeys;
          break;
      }

      const nodeSelected = selectedKeys.some((key) => key === node.key);

      if (nodeSelected) {
        if (depth < 13) {
          nameMaxWidth = Math.max(30, 310 - indent);
        }
        datePart = (
          <span className={styles.date}>{` ${t("collection.folderUpdated")} ${dayjs(
            node.updateTime
          ).format("L")}`}</span>
        );
      }
    }

    return (
      <span
        style={{
          display: depth < 13 ? "flex" : "block",
        }}
      >
        <span
          style={{
            maxWidth: `${nameMaxWidth}px`,
          }}
          className={className}
        >
          {node.title}
        </span>
        {datePart}
      </span>
    );
  };

  useEffect(() => {
    if (currentCollection) {
      setActiveTabKey(TABS.current);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === false) {
      setActiveTabKey(currentCollection ? TABS.current : TABS.personal);
      setSelectedCommunity(undefined);
      setCommunityCollections(undefined);
      setSelectedCurrentCollectionPath(undefined);
      currentCollectionTree.setSelectedKeys([]);
      setSelectedUserCollectionPath(undefined);
      userCollectionsTree.setSelectedKeys([]);
      setSelectedCommunityCollectionPath(undefined);
      communityCollectionsTree.setSelectedKeys([]);
    }
  }, [isOpen, currentCollection]);

  let tabs: TabsProps["items"] = [
    ...(currentCollection
      ? [{ key: TABS.current, label: t("collections.goToModal.currentTabTitle") }]
      : []),
  ];

  if (!showCurrentCollectionOnly) {
    tabs = [
      ...tabs,
      {
        key: TABS.personal,
        label: t(`collections.goToModal.${collectionsTabTitle}`),
      },
    ];
  }

  if (!showCurrentAndMyCollections) {
    tabs = [
      ...tabs,
      {
        key: TABS.community,
        label: t("collections.goToModal.communityTabTitle"),
      },
    ];
  }

  const activeTabChangeHandler = (activeTabKey: string) => {
    setActiveTabKey(activeTabKey);
    setSelectedCommunityCollectionPath(undefined);
    if (activeTabKey === TABS.personal) {
      setSelectedCommunity(undefined);
    }

    if (handleSetModalActiveTab) {
      handleSetModalActiveTab(activeTabKey);
    }
  };

  const handleCommunitySelected = (community: ICommunity) => {
    setSelectedCommunity(community);
  };

  const cancelHandler = () => {
    onCancel();
  };

  const { data: userCommunitiesData } = useQuery<IUserCommunitiesResponse>(
    GET_USER_COMMUNITIES_FOR_GO_TO_MODAL,
    {
      fetchPolicy: "no-cache",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => displayNotSuccessNotification(error),
    }
  );

  const [getDefaultCommunityCollection] = useLazyQuery<{
    community: {
      defaultCollection: ICollection;
    };
  }>(GET_DEFAULT_COMMUNITY_COLLECTION_FOR_GO_TO_MODAL, {
    fetchPolicy: "no-cache",
  });

  const [getPublicCommunityCollections] = useLazyQuery<{
    publicCommunityCollections: { totalCount: number; collections: ICollection[] };
  }>(GET_PUBLIC_COMMUNITY_COLLECTIONS, {
    fetchPolicy: "no-cache",
  });
  const [getPrivateCommunityCollections] = useLazyQuery<{
    privateCommunityCollections: { totalCount: number; collections: ICollection[] };
  }>(GET_PRIVATE_COMMUNITY_COLLECTIONS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (selectedCommunity) {
      getCommunityCollections(selectedCommunity.sendbirdId);
    }
  }, [selectedCommunity]);

  const getCommunityCollections = async (sendbirdId: string): Promise<ICollection[]> => {
    const options = {
      variables: {
        search: {
          sendbirdId,
        },
      },
    };

    try {
      const responses = await Promise.all([
        getDefaultCommunityCollection({ variables: { sendbirdId } }),
        getPublicCommunityCollections(options),
        getPrivateCommunityCollections(options),
      ]);
      const collections = [
        ...(responses[0].data?.community.defaultCollection
          ? [responses[0].data?.community.defaultCollection]
          : []),
        ...(responses[1].data?.publicCommunityCollections.collections ?? []),
        ...(responses[2].data?.privateCommunityCollections.collections ?? []),
      ];
      setCommunityCollections(collections);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayNotSuccessNotification(error);
    }
    return Promise.resolve([]);
  };

  const userCollectionsTreeInitialData: CollectionsTreeNode[] | undefined = useMemo(
    () =>
      collections?.map((collection) => ({
        key: collection.publicId,
        title: collection.name,
        type: "collection",
        isLeaf: false,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collections, isOpen]
  );

  const communityCollectionsTreeInitialData: CollectionsTreeNode[] | undefined =
    useMemo(() => {
      if (communityCollections) {
        return communityCollections.map((collection) => ({
          key: collection.publicId,
          title: collection.name,
          type: "collection",
          isLeaf: false,
        }));
      }
      return undefined;
    }, [communityCollections, isOpen]);

  const currentCollectionTree = useCollectionsTree(
    currentCollectionTreeInitialData,
    pathSelectHandler,
    t("collection.newFolder"),
    t("collection.noFiles")
  );

  useEffect(() => {
    if (currentCollectionTree.isInitialized) {
      const currentCollectionTreeNode = currentCollectionTree.treeData[0];
      currentCollectionTree.expandHandler([currentCollectionTreeNode.key], {
        expanded: true,
        node: currentCollectionTreeNode,
      });
    }
  }, [currentCollectionTree.isInitialized]);

  const userCollectionsTree = useCollectionsTree(
    userCollectionsTreeInitialData,
    pathSelectHandler,
    t("collection.newFolder"),
    t("collection.noFiles")
  );

  const communityCollectionsTree = useCollectionsTree(
    communityCollectionsTreeInitialData,
    pathSelectHandler,
    t("collection.newFolder"),
    t("collection.noFolders"),
    true,
    true,
    false,
    t("collections.singleCollection.folderCreated")
  );

  const loadingSpinner = (
    <div className={styles.loadingWrapper}>
      <Loading borderless disableBoxShadow />
    </div>
  );

  const currentCollectionTabContent = currentCollectionTreeInitialData ? (
    <Tree<CollectionsTreeNode>
      treeData={currentCollectionTree.treeData}
      showIcon
      blockNode
      titleRender={getTitle}
      icon={NodeIcon}
      selectedKeys={currentCollectionTree.selectedKeys}
      expandedKeys={currentCollectionTree.expandedKeys}
      onSelect={currentCollectionTree.selectHandler}
      onExpand={currentCollectionTree.expandHandler}
    />
  ) : (
    loadingSpinner
  );

  const personalTabContent = userCollectionsTreeInitialData ? (
    <Tree<CollectionsTreeNode>
      treeData={userCollectionsTree.treeData}
      showIcon
      blockNode
      titleRender={getTitle}
      icon={NodeIcon}
      selectedKeys={userCollectionsTree.selectedKeys}
      expandedKeys={userCollectionsTree.expandedKeys}
      onSelect={userCollectionsTree.selectHandler}
      onExpand={userCollectionsTree.expandHandler}
    />
  ) : (
    loadingSpinner
  );

  const communityTabContent = userCommunitiesData?.user.communities ? (
    selectedCommunity ? (
      communityCollectionsTreeInitialData ? (
        <Tree<CollectionsTreeNode>
          treeData={communityCollectionsTree.treeData.sort(
            sortByString(({ title }) => title as string, true)
          )}
          showIcon
          blockNode
          titleRender={getTitle}
          icon={NodeIcon}
          selectedKeys={communityCollectionsTree.selectedKeys}
          expandedKeys={communityCollectionsTree.expandedKeys}
          onSelect={communityCollectionsTree.selectHandler}
          onExpand={communityCollectionsTree.expandHandler}
        />
      ) : (
        loadingSpinner
      )
    ) : (
      <CommunitiesList
        communities={userCommunitiesData?.user.communities.sort(
          sortByString(({ name }) => name as string, true)
        )}
        onSelect={handleCommunitySelected}
      />
    )
  ) : (
    loadingSpinner
  );

  const isGoToDisabled = () => {
    let isDisabled = true;
    switch (true) {
      case activeTabKey === TABS.current:
        isDisabled = !selectedCurrentCollectionPath;
        break;
      case activeTabKey === TABS.personal:
        isDisabled = !selectedUserCollectionPath;
        break;
      case activeTabKey === TABS.community:
        isDisabled = !selectedCommunityCollectionPath;
        break;
    }
    return isDisabled;
  };

  return (
    <Modal isOpen={isOpen} modalType={"primary"} className="collections-tree-modal">
      <div className={styles.Wrapper}>
        <h4 className={styles.Title}>
          {t(`collection.collecionsTreeModalTitle.${treeModalType}`)}
        </h4>
        <div className={styles.tabsWrapper}>
          <Tabs items={tabs} activeKey={activeTabKey} onChange={activeTabChangeHandler} />
        </div>
        <div className={styles.TreeWrapper}>
          {activeTabKey === TABS.current && currentCollectionTabContent}
          {activeTabKey === TABS.personal && personalTabContent}
          {activeTabKey === TABS.community && communityTabContent}
        </div>
        <div className={styles.ButtonsContainer}>
          <Button
            title={t(`collection.treeModal.${treeModalType}`)}
            buttonType="primary"
            className={styles.Button}
            disabled={isGoToDisabled()}
            onClick={goToHandler}
          ></Button>
          <Button
            title={t("collection.cancelBtn")}
            className={styles.Button}
            onClick={cancelHandler}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

export default CollectionsTreeModal;
