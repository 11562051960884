import { gql } from "@apollo/client";

export const ALL_PENDING_COLLECTION_COLLABORATION_INVITATIONS = gql`
  query {
    pendingCollectionCollaborationInvitations {
      collection {
        name
        publicId
        collectionCreator {
          fullName
          email
        }
        isPublic
      }
      created
    }
  }
`;

export const PENDING_COLLECTION_COLLABORATION_INVITATIONS = gql`
  query usersWithPendingCollectionCollaborationInvitations($collectionPublicId: Guid!) {
    usersWithPendingCollectionCollaborationInvitations(
      collectionPublicId: $collectionPublicId
    ) {
      fullName
      email
      publicId
      invitationStatus
      organization {
        sector {
          name
        }
        name
        userRole
      }
    }
  }
`;
