/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ControlProps,
  IndicatorsContainerProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import { CSSObject } from "styled-components";
import palette from "./../../../scss/exports.module.scss";

export const getDefaultStyles = (selectWithIcon?: boolean) => ({
  indicatorSeparator: (styles: CSSObject) => ({
    ...styles,
    display: "none",
    color: `rgba(${palette.white}, 0.55)`,
  }),
  dropdownIndicator: (styles: CSSObject) => ({
    ...styles,
    color: palette.black,
    padding: selectWithIcon ? 0 : styles.padding,
  }),
  control: ({ paddingLeft, ...styles }: CSSObject, state: ControlProps<any, boolean>) => {
    return {
      ...styles,
      paddingInlineStart: 10,
      borderRadius: "30px",
      border: state.isFocused
        ? `2px solid ${palette.primaryBlue}`
        : "2px solid transparent",
      boxShadow: state.isFocused ? "0" : "0",
      minHeight: "46px",

      "&:hover": {
        cursor: "pointer",
        border: state.isFocused
          ? `2px solid ${palette.primaryBlue}`
          : "2px solid transparent",
      },
    };
  },
  placeholder: (styles: CSSObject) => ({
    ...styles,
    fontWeight: 400,
    top: "49%",
  }),
  multiValue: (styles: CSSObject) => ({
    ...styles,
    borderRadius: "100px",
    backgroundColor: palette.pillBackground,
    color: palette.black,
    margin: "5px",
    outline: "none",
    cursor: "default",
    border: "1px solid transparent",
    transition: "ease 100ms",

    "&:hover": {
      border: `1px solid ${palette.primaryBlue}`,
    },
  }),
  multiValueRemove: (styles: CSSObject): CSSObject => ({
    ...styles,
    marginLeft: 5,
    marginRight: 5,
    position: "relative",
    top: 4,
    backgroundColor: palette.hiveGreySelect,
    borderRadius: 100,
    padding: 2,
    height: 16,
    width: 16,
    color: palette.white,
    cursor: "pointer",
    transition: "100ms ease",

    "&:hover": {
      color: palette.white,
      backgroundColor: palette.primaryBlue,
    },
  }),
  menu: (styles: CSSObject) => ({
    ...styles,
    cursor: "pointer",
  }),
  indicatorsContainer: (prevStyle: CSSObject, state: IndicatorsContainerProps) => {
    return {
      ...prevStyle,
      svg: {
        backgroundColor: palette.hiveGreySelect,
        borderRadius: 100,
        color: palette.white,
        transition: "100ms ease",
        "&:hover": {
          color: palette.white,
          backgroundColor: palette.primaryBlue,
        },
      },
    };
  },
  option: (styles: CSSObject, state: OptionProps<any, boolean>) => {
    return {
      ...styles,
      fontWeight: 400,
      paddingLeft: "10px",
      cursor: "pointer",
      minHeight: 38,
    };
  },
  loadingIndicator: (styles: CSSObject) => ({
    ...styles,
    padding: "5px",
  }),
  valueContainer: (prevStyle: CSSObject, state: ValueContainerProps) => {
    const styles: CSSObject = state.selectProps.isMulti
      ? { ...prevStyle, maxHeight: 100, overflow: "auto", scrollbarWidth: "none" }
      : prevStyle;

    return { ...styles };
  },
});
