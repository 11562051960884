import { gql } from "@apollo/client";

const UPDATE_EVENT = gql`
  mutation ($eventUpdate: UpdateEventInputType!) {
    updateEvent(eventUpdate: $eventUpdate) {
      name
      startDate
      endDate
      attachments {
        name
      }
    }
  }
`;

export default UPDATE_EVENT;
