import { Tree as AntTree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
import styles from "./Tree.module.scss";

const Tree = <T extends DataNode>(props: TreeProps<T>) => {
  return (
    <div className={styles.Wrapper}>
      <AntTree<T> {...props} />
    </div>
  );
};

export default Tree;
