/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import { ReactComponent as Copy } from "@images/copyIconSecond.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";

// queries
import GENERATE_TOKEN from "@graphql/mutations/generateToken";
import GENERATE_USER_CODE from "@graphql/mutations/generateUserCode";

import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import styles from "./GenerateAccessToken.module.scss";

const GenerateAccessToken = () => {
  const { t } = useTranslation();
  const [isCodeBtnBlocked, setIsCodeBtnBlocked] = useState<boolean>(false);
  const [isTokenBtnBlocked, setIsTokenBtnBlocked] = useState<boolean>(true);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);

  const [verificationUri, setVerificationUri] = useState<string>("");
  const [userCode, setUserCode] = useState<string>("");

  const [generateUserCode] = useMutation(GENERATE_USER_CODE, {
    onCompleted: (data) => {
      setVerificationUri(data.generateUserCode.verificationUri);
      setUserCode(data.generateUserCode.userCode);
      setIsCodeBtnBlocked(true);
      setIsTokenBtnBlocked(false);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const [generateToken] = useMutation(GENERATE_TOKEN, {
    onCompleted: (data) => {
      displaySuccessNotification(
        t("adminPanel.generateAccessToken.tokenSuccessfullyGeneratedNotification")
      );
      setIsCodeBtnBlocked(false);
      setIsTokenBtnBlocked(true);
      setVerificationUri("");
      setUserCode("");
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const generateLinkAndCode = () => {
    generateUserCode();
  };

  const openLink = () => {
    window.open(verificationUri, "_blank", "noreferrer");
  };

  const copyCode = () => {
    navigator.clipboard.writeText(userCode);
    displaySuccessNotification(t("adminPanel.generateAccessToken.copyCodeNotification"));
  };

  return (
    <div className={styles.pageWrapper}>
      <h2 className={styles.title}>{t("adminPanel.sidemenu.generateToken")}</h2>
      <div className={styles.sectionWrapper}>
        <p>{t("adminPanel.generateAccessToken.firstStep")}</p>
        <div className={styles.copyLinkWrapper} aria-label="input">
          <div className={styles.copyLink}>
            {verificationUri.length > 0
              ? verificationUri
              : t("adminPanel.generateAccessToken.linkPlaceholder")}
          </div>
          <Button
            aria-label={"openLink"}
            wrapperClassNames={styles.buttonCopy}
            title={t("adminPanel.generateAccessToken.openLink")}
            buttonSize="small"
            onClick={openLink}
            disabled={verificationUri.length < 1}
          />
        </div>
        <div className={styles.copyLinkWrapper} aria-label="input">
          <div className={styles.copyLink}>
            {userCode.length > 0
              ? userCode
              : t("adminPanel.generateAccessToken.codePlaceholder")}
          </div>
          <Button
            aria-label={"copy"}
            buttonIcon={<Copy className={styles.copyImg} />}
            wrapperClassNames={styles.buttonCopy}
            title={t("adminPanel.generateAccessToken.copy")}
            buttonSize="small"
            onClick={copyCode}
            disabled={userCode.length < 1}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            aria-label="generateCode"
            buttonType="primary"
            wrapperClassNames={styles.buttonWrapper}
            title={t("adminPanel.generateAccessToken.buttons.generateCode")}
            onClick={() => generateLinkAndCode()}
            disabled={isCodeBtnBlocked}
          />
        </div>
        <p>{t("adminPanel.generateAccessToken.secondStep")}</p>
        <div className={styles.buttonTokenWrapper}>
          <Button
            aria-label="generateToken"
            buttonType="primary"
            wrapperClassNames={styles.buttonWrapper}
            title={t("adminPanel.generateAccessToken.buttons.generateToken")}
            onClick={() => setIsConfirmModalOpened(true)}
            disabled={isTokenBtnBlocked}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmModalOpened}
        accept={() => {
          setIsConfirmModalOpened(false);
          generateToken();
        }}
        messageTitle={t("adminPanel.generateAccessToken.confirmModal.title")}
        messageContent=""
        closeModal={() => setIsConfirmModalOpened(false)}
        customButtonsText={{
          accept: t("adminPanel.generateAccessToken.confirmModal.button.accept"),
          cancel: t("adminPanel.generateAccessToken.confirmModal.button.cancel"),
        }}
      />
    </div>
  );
};

export default GenerateAccessToken;
