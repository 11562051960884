import { ApolloError, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Modal from "@components/Modal/Modal";

import Tick from "@images/blueTick.svg";
import removeIcon from "@images/closeModal.svg";

import LINK_PREVIEW from "@graphql/queries/linkPreview";
import { ILinkPreview } from "types/link";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import styles from "./ConfirmationModal.module.scss";

export interface IConfirmationModalProps {
  isOpen: boolean;
  messageTitle: string;
  messageContent: string;
  messageSubtitle?: string;
  customButtonsText?: {
    accept: string;
    cancel: string;
  };
  url?: string;
  accept?: () => void;
  closeModal: () => void;
  dangerModal?: boolean;
  goBack?: () => void;
  e2eTestId?: string;
}

const ConfirmationModal = ({
  accept,
  url,
  closeModal,
  isOpen,
  messageTitle,
  messageContent,
  messageSubtitle,
  customButtonsText,
  dangerModal = true,
  goBack,
  e2eTestId,
}: IConfirmationModalProps) => {
  const { t } = useTranslation();

  const [linkPreview, setLinkPreview] = useState<ILinkPreview>();
  const [userAgent, setUserAgent] = useState<string>();

  const [getLinkPreview, { loading: previewLoading }] = useLazyQuery<{
    linkPreview: ILinkPreview;
  }>(LINK_PREVIEW, {
    onCompleted: (res) => {
      setLinkPreview({
        url: res.linkPreview.url,
        title: res.linkPreview.title,
        description: res.linkPreview.description,
        imageUrl: res.linkPreview.imageUrl,
      });
    },
    onError: (err: ApolloError) => {
      setLinkPreview(undefined);
      const codeMessage = err?.graphQLErrors[0]?.extensions.code;
      if (codeMessage === "7006") {
        setLinkPreview(undefined);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const decodeHtmlCharCodes = (str: string) => {
    const decodedString = document.createElement("textarea");
    decodedString.innerHTML = str;
    return decodedString.value;
  };

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, []);

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);

    if (url && isOpen) {
      getLinkPreview({
        variables: {
          metaInformationFromLinkInputType: { url, userAgent },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isOpen]);

  const triggerClickWithoutPropagation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void
  ) => {
    e.stopPropagation();
    callback();
  };

  return (
    <Modal isOpen={isOpen} className={styles.modalContainer}>
      <div
        className={styles.modalWrapper}
        data-testid="container"
        e2e-test-id={e2eTestId}
      >
        <Button
          title=""
          buttonType="link"
          e2eTestId="close-modal-icon"
          wrapperClassNames={styles.closeButton}
          onClick={(e) =>
            triggerClickWithoutPropagation(e, () => {
              closeModal();
              goBack && goBack();
            })
          }
          buttonIcon={<img alt="" src={removeIcon} className={styles.closeIcon} />}
        />
        {!dangerModal && <img src={Tick} alt="" className={styles.modalImage} />}
        <h4 className={styles.header} aria-label="message-title">
          {messageTitle}
        </h4>
        {url ? (
          previewLoading ? (
            <div className={styles.loadingContainer}>
              <Loading borderless disableBoxShadow />
            </div>
          ) : linkPreview ? (
            <div className={styles.linkPreviewContainer}>
              <h3 className={styles.linkPreviewTitle}>{linkPreview.title}</h3>
              <p>{decodeHtmlCharCodes(linkPreview.description)}</p>
              <img className={styles.linkPreviewImage} src={linkPreview.imageUrl} />
            </div>
          ) : (
            <p className={styles.noPreviewAvailable}>{url}</p>
          )
        ) : null}
        {messageSubtitle && (
          <p
            className={messageSubtitle ? styles.twoRowText : styles.text}
            aria-label="message-subtitle"
          >
            {messageSubtitle}
          </p>
        )}
        <p
          className={messageSubtitle ? styles.twoRowText : styles.text}
          aria-label="message-content"
        >
          {messageContent}
        </p>
        {dangerModal && (
          <div className={styles.buttonContainer} aria-label="button-container">
            {goBack ? (
              <Button
                wrapperClassNames={styles.confirmModalButtonWrapper}
                title={t("collections.singleCollection.userNotAllowedModal.button")}
                onClick={(e) =>
                  triggerClickWithoutPropagation(e, () => {
                    closeModal();
                    goBack();
                  })
                }
                buttonType="primary"
              />
            ) : (
              <>
                <Button
                  wrapperClassNames={styles.confirmModalButtonWrapper}
                  title={
                    customButtonsText
                      ? customButtonsText.cancel
                      : t("events.cancelExternalLinkButton")
                  }
                  onClick={(e) => accept && triggerClickWithoutPropagation(e, closeModal)}
                  data-testid="close-button"
                  e2eTestId="cancel-button"
                />
                <Button
                  wrapperClassNames={styles.confirmModalButtonWrapper}
                  title={
                    customButtonsText
                      ? customButtonsText.accept
                      : t("events.proceedExternalLinkButton")
                  }
                  buttonType="primary"
                  onClick={(e) => accept && triggerClickWithoutPropagation(e, accept)}
                  danger={!customButtonsText}
                  data-testid="accept-button"
                  e2eTestId="accept-button"
                />
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
