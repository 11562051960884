import { gql } from "@apollo/client";

export const ADD_ARTICLE_TO_FOLDER = gql`
  mutation AddArticleToFolder($article: AddArticleToFolderInputType!) {
    addArticleToFolder(addArticleToFolder: $article) {
      name
      publicId
    }
  }
`;

export const ADD_SUBFOLDER = gql`
  mutation addSubfolder($subfolderToAdd: AddSubfolderInputType!) {
    addSubfolder(subfolderToAdd: $subfolderToAdd) {
      name
      publicId
    }
  }
`;
