import { MutableRefObject } from "react";

import HivePulseSection from "../../components/LandingPage/Home/SectionFive";
import HeroSection from "../../components/LandingPage/Home/SectionOne";
import HiveFeaturesSection from "../../components/LandingPage/Home/SectionThree";
import AboutSection from "../../components/LandingPage/Home/SectionTwo";
import "../../modules/LandingPage/styles/global.scss";

interface IHomeProps {
  signupRef: MutableRefObject<HTMLDivElement | null>;
  aboutRef: MutableRefObject<HTMLDivElement | null>;
  hiveFeaturesRef: MutableRefObject<HTMLDivElement | null>;
  hivePulseRef: MutableRefObject<HTMLDivElement | null>;
}

const Home = ({ aboutRef, hiveFeaturesRef, hivePulseRef, signupRef }: IHomeProps) => {
  return (
    <div className="main-app">
      <HeroSection />
      <AboutSection ref={aboutRef} />
      <HivePulseSection ref={hivePulseRef} />
      <HiveFeaturesSection ref={hiveFeaturesRef} />
      {/* Removed as a hotfix until it is updated */}
      {/* <SignupSection ref={signupRef} /> */}
    </div>
  );
};

export default Home;
