import Button from "@components/Button/Button";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  INITIAL_START_PAGE,
} from "@constants/constants";
import { ReactComponent as DoubleCaretLeft } from "@images/double-caret-left.svg";
import { ReactComponent as DoubleCaretRight } from "@images/double-caret-right.svg";
import { Pagination as AntDPagination } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./Pagination.module.scss";

interface IPaginationProps {
  handlePageChange: (page: number, pageSize: number) => void;
  totalNumberOfRows: number;
  totalPagesCount: number;
  currentPage: number;
  pageSize: number;
  pageSizeOptions?: Array<string>;
  loadingData?: boolean;
}

export const Pagination = ({
  handlePageChange,
  totalNumberOfRows,
  totalPagesCount,
  currentPage,
  pageSize,
  pageSizeOptions,
  loadingData,
}: IPaginationProps) => {
  const { t } = useTranslation();

  const goToFirstPage = () => {
    handlePageChange(INITIAL_START_PAGE, pageSize);
  };

  const goToLastPage = () => {
    const lastPage = Math.ceil(totalNumberOfRows / pageSize);
    handlePageChange(lastPage, pageSize);
  };

  if (loadingData) return <></>;

  return (
    <div className={styles.pagination}>
      <div className={styles.innerPagination}>
        <Button
          buttonType="text"
          title=""
          buttonIcon={<DoubleCaretLeft />}
          className={styles.button}
          wrapperClassNames={`${styles.buttonWrapper} ${styles.left}`}
          disabled={currentPage === INITIAL_START_PAGE}
          onClick={goToFirstPage}
        />
        <AntDPagination
          current={currentPage}
          total={totalNumberOfRows}
          defaultPageSize={pageSize || DEFAULT_PAGE_SIZE}
          showSizeChanger={true}
          pageSizeOptions={pageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS}
          hideOnSinglePage={false}
          locale={t("adminPanel.table.rows")}
          onChange={(page, pageSize) => handlePageChange(page, pageSize)}
        />
        <Button
          buttonType="text"
          title=""
          buttonIcon={<DoubleCaretRight />}
          className={styles.button}
          wrapperClassNames={`${styles.buttonWrapper} ${styles.right}`}
          disabled={currentPage === totalPagesCount}
          onClick={goToLastPage}
        />
      </div>
    </div>
  );
};
