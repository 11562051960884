import { ITabsProps } from "./Tabs";
import { ITab } from "./type";

export const buildPath = (tab: ITab) => {
  if (!tab.isRoot) return tab.path;

  const getPath = (currentTab?: ITabsProps): string => {
    if (!currentTab) {
      return "";
    }

    const path = getPath(currentTab.tabs[0].subTab);

    return `${currentTab.tabs[0].path}/${path}`;
  };

  const path = getPath(tab.subTab);
  if (path) {
    return `${tab.path}/${getPath(tab.subTab)}`;
  }

  return tab.path;
};
