import { ReactComponent as Caret } from "@images/chevron_right.svg";

import { ICommunity } from "types/community";

import styles from "./CommunitiesList.module.scss";

interface CommunitiesListProps {
  communities: ICommunity[];
  onSelect: (community: ICommunity) => void;
}

const CommunitiesList = ({ communities, onSelect }: CommunitiesListProps) => {
  return (
    <div className={styles.Wrapper}>
      {communities.map((community) => {
        return (
          <div key={community.sendbirdId} className={styles.Item}>
            <div
              className={styles.Image}
              style={{ backgroundImage: `url(${community.communityImageUrl})` }}
            ></div>
            <span
              className={styles.Title}
              key={community.sendbirdId}
              title={community.name}
            >
              {community.name}
            </span>
            <Caret onClick={() => onSelect(community)} />
          </div>
        );
      })}
    </div>
  );
};

export default CommunitiesList;
