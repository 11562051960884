import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { GroupChannel } from "sendbird";

import { SENDBIRD_ID_PREFIX } from "@constants/constants";

import {
  SendBirdDirectGroupChannelHelper,
  SendBirdGroupChannelHelper,
  SendBirdGroupChannelsHelper,
  SendBirdHelper,
} from "../SendBirdHelper";
import { useSendBirdContext } from "../context/context";

import { displayErrorNotification } from "@services/NotificationService/NotifacitonService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { DIRECT, ERROR_CODE } from "../constant";
import { CHANNEL_HANDLER_ID } from "./constant";
import {
  IUseSendBirdGroupChannels,
  createDirectGroupChannelParams,
  createGroupChannelParams,
} from "./type";

interface IUseSendBirdGroupChannelsProps {
  onChannelDeleted: (url: string) => void;
  onChannelNotFound: () => void;
}

export const useSendBirdGroupChannels = ({
  onChannelDeleted,
  onChannelNotFound,
}: IUseSendBirdGroupChannelsProps): IUseSendBirdGroupChannels => {
  const { t } = useTranslation();
  const { id: groupId } = useParams<{ id: string }>();
  const history = useHistory();
  const { sb } = useSendBirdContext();
  const [groups, setGroups] = useState<GroupChannel[]>();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const newCreatedGroupChannelPathPrefix = useRef("");

  const getGroupChannels = useCallback(() => {
    if (!sb) return;

    const initializeChannels = async () => {
      const groupChannels = await SendBirdGroupChannelsHelper.getGroupChannels(sb);
      setGroups(groupChannels);
    };

    initializeChannels();
  }, [sb]);

  useEffect(() => {
    if (sb) {
      getGroupChannels();
    }
  }, [sb, getGroupChannels]);

  const isGroupPresent = useCallback(
    (url: string) => SendBirdHelper.isGroupPresent(url, groups),
    [groups]
  );

  const removeGroup = useCallback((url: string) => {
    setGroups((prev) => SendBirdHelper.removeGroup(url, prev));
  }, []);

  const updateGroup = useCallback(
    async (groupChannel: GroupChannel) => {
      const foundIndexGroupChannel = SendBirdHelper.findIndexGroupChannel(
        groupChannel.url,
        groups
      );

      if (foundIndexGroupChannel !== undefined && foundIndexGroupChannel !== -1) {
        const groupChannels = [...(groups || [])];
        groupChannels[foundIndexGroupChannel] = groupChannel;
        setGroups(groupChannels);
      }
    },
    [groups]
  );

  const addGroup = useCallback((groupChannel: GroupChannel) => {
    setGroups((prev) => (prev ? [...prev, groupChannel] : [groupChannel]));
  }, []);

  const selectedGroupChannel = useMemo(() => {
    if (!groups) return;

    const foundGroupChannel = SendBirdHelper.findGroupChannel(
      SENDBIRD_ID_PREFIX.concat(groupId),
      groups
    );

    if (!foundGroupChannel && groupId) {
      onChannelNotFound();
    }

    return foundGroupChannel;
  }, [groups, groupId, onChannelNotFound]);

  useEffect(() => {
    if (selectedGroupChannel && selectedGroupChannel.unreadMessageCount > 0) {
      selectedGroupChannel.markAsRead();
    }
  }, [selectedGroupChannel]);

  useEffect(() => {
    if (!sb) return;

    const channelHandler = new sb.ChannelHandler();
    sb.addChannelHandler(CHANNEL_HANDLER_ID, channelHandler);

    channelHandler.onChannelDeleted = (url) => {
      displayErrorNotification(t(ERROR_CODE.CHANNEL_NOT_FOUND.translationKey));
      onChannelDeleted(url);
      removeGroup(url);
    };

    channelHandler.onUserReceivedInvitation = (groupChannel) => {
      if (isGroupPresent(groupChannel.url)) {
        return;
      }
      addGroup(groupChannel);

      if (newCreatedGroupChannelPathPrefix.current) {
        history.replace(
          `${
            newCreatedGroupChannelPathPrefix.current
          }/${SendBirdHelper.getGroupChannelUrlWithoutPrefix(groupChannel)}`
        );
      }

      newCreatedGroupChannelPathPrefix.current = "";
    };

    channelHandler.onUserLeft = async (groupChannel, user) => {
      if (groupChannel?.customType !== DIRECT && currentUserEmail !== user.userId) {
        return;
      }

      removeGroup(groupChannel.url);
    };

    channelHandler.onMessageReceived = async (groupChannel: GroupChannel) => {
      if (groupId === SendBirdHelper.getUrlWithoutPrefix(groupChannel.url)) {
        groupChannel.markAsRead();
      }
    };

    channelHandler.onChannelChanged = (groupChannel: GroupChannel) => {
      updateGroup(groupChannel);
    };

    return () => {
      sb.removeChannelHandler(CHANNEL_HANDLER_ID);
    };
  }, [
    groups,
    isGroupPresent,
    onChannelDeleted,
    addGroup,
    removeGroup,
    sb,
    history,
    groupId,
    updateGroup,
    currentUserEmail,
    t,
  ]);

  const createDirectGroupChannel = useCallback(
    ({ pathPrefix, ...params }: createDirectGroupChannelParams) => {
      if (!sb) {
        newCreatedGroupChannelPathPrefix.current = "";
        return;
      }

      newCreatedGroupChannelPathPrefix.current = pathPrefix;
      SendBirdDirectGroupChannelHelper.createDirectGroupChannel({
        ...params,
        currentUserEmail,
        sb,
      });
    },
    [currentUserEmail, sb]
  );

  const createGroupChannel = useCallback(
    ({ pathPrefix, ...params }: createGroupChannelParams) => {
      if (!sb) {
        newCreatedGroupChannelPathPrefix.current = "";
        return;
      }

      newCreatedGroupChannelPathPrefix.current = pathPrefix;
      SendBirdGroupChannelHelper.createGroupChannel({ ...params, currentUserEmail, sb });
    },
    [currentUserEmail, sb]
  );

  const sendBirdGroupChannels = useMemo(
    () => ({
      state: {
        selectedGroupChannel,
        groups: SendBirdHelper.getNonDirectGroups(groups),
        directGroups: SendBirdHelper.getDirectGroups(groups),
      },
      actions: {
        getGroupChannels,
      },
      helpers: {
        createDirectGroupChannel,
        createGroupChannel,
      },
    }),
    [
      selectedGroupChannel,
      groups,
      getGroupChannels,
      createDirectGroupChannel,
      createGroupChannel,
    ]
  );

  return sendBirdGroupChannels;
};
