import { gql } from "@apollo/client";

const ADD_CHANNEL = gql`
  mutation AddChannel($channel: ChannelInputType!) {
    addChannel(channel: $channel) {
      channels
    }
  }
`;

export default ADD_CHANNEL;
