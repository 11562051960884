import { gql } from "@apollo/client";

export const ADD_FILES_TO_CHAT = gql`
  mutation addFileToChat($addFileToChatRequest: AddFileToChatInputType!) {
    addFileToChat(addFileToChatRequest: $addFileToChatRequest) {
      name
    }
  }
`;

export const PIN_FILE_TO_COMMUNITY = gql`
  mutation pinFileToCommunity($filePublicId: Guid!) {
    pinFileToCommunity(filePublicId: $filePublicId) {
      name
    }
  }
`;

export const UNPIN_FILE_FROM_COMMUNITY = gql`
  mutation unpinFileFromCommunity($filePublicId: Guid!) {
    unpinFileFromCommunity(filePublicId: $filePublicId) {
      name
    }
  }
`;
