import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import SendBird from "sendbird";

const sb = new SendBird({ appId: process.env.REACT_APP_SENDBIRD_APP_ID || "" });

if (!sb) {
  displayNotSuccessNotification("not initialize sendbird");
}

export default sb;
