import { gql } from "@apollo/client";

const GET_COMMUNITY_BY_NAME = gql`
  query communities($communitySearch: CommunitySearchInputType) {
    communities(communitySearch: $communitySearch) {
      communities {
        name
        sendbirdId
        communityImageUrl
        communityType
        description
        channels
      }
    }
  }
`;

export default GET_COMMUNITY_BY_NAME;
