import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import Document from "@components/Document/Document";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { setAcceptCode } from "../../store/onboarding/actions";
import styles from "./CodeOfConduct.module.scss";
import { codeContent } from "./content";

interface ICodeOfConduct {
  isOnboarded: boolean;
}

const CodeOfConduct = ({ isOnboarded }: ICodeOfConduct) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (isOnboarded) return <Redirect to="/portal/resources" />;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.codeTitle}>{t("codeOfConduct.title")}</h2>
        <div className={styles.codeContainer}>
          <Document document={codeContent} />
          <div className={styles.checkboxWrapper}>
            <Checkbox
              name="acceptCode"
              label={t("codeOfConduct.agree")}
              checked={accepted}
              onChange={() => setAccepted(!accepted)}
            />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            title={t("codeOfConduct.buttons.back")}
            buttonType="link"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          />
          <Button
            title={t("codeOfConduct.buttons.agree")}
            wrapperClassNames="addIndentLeft"
            buttonType="primary"
            disabled={!accepted}
            onClick={() => {
              dispatch(setAcceptCode(accepted));
              history.push("/onboarding");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CodeOfConduct;
