import { gql } from "@apollo/client";

const REMOVE_MEMBER = gql`
  mutation removeCommunityMember($communitySendbirdId: String!, $memberEmail: String!) {
    removeCommunityMember(
      removeMember: {
        communitySendbirdId: $communitySendbirdId
        memberEmail: $memberEmail
      }
    ) {
      name
      sendbirdId
    }
  }
`;

export default REMOVE_MEMBER;
