import { useState } from "react";

import { TooltipPlacement } from "antd/lib/tooltip";

import TooltipComponent from "@components/Tooltip/Tooltip";

import { isEllipsisActive } from "@utils/dom/helper";

import { DEFAULT_NUMBER_OF_LINES_TO_DISPLAY } from "./constant";

import { TagTypes } from "./type";

import styles from "./TextTruncated.module.scss";

interface ITextTruncatedProps {
  text: string;
  lines?: number;
  placement?: TooltipPlacement;
  Tag?: TagTypes;
  e2eTestId?: string;
  className?: string;
}

export const TextTruncated: React.FC<ITextTruncatedProps> = ({
  text,
  placement,
  lines = DEFAULT_NUMBER_OF_LINES_TO_DISPLAY,
  Tag = "span",
  e2eTestId,
  className,
}: ITextTruncatedProps) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  const textTruncatedElement = (
    <Tag
      ref={(element) => {
        setElement(element);
      }}
      className={`${styles.text} ${className}`}
      style={{ WebkitLineClamp: lines }}
      e2e-test-id={e2eTestId}
    >
      {text}
    </Tag>
  );

  if (element && !isEllipsisActive(element)) {
    return textTruncatedElement;
  }

  return (
    <TooltipComponent
      tooltipType="secondary"
      placement={placement || "top"}
      title={text}
      childToWrap={textTruncatedElement}
    />
  );
};
