import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import Button from "@components/Button/Button";

import { Pronouns } from "../../service/enum";

import { getValidationSchema } from "./validation";

import { Form } from "./Form/Form";

interface ICreateUpdateForm {
  pronouns?: string;
  onSubmit?: (name: string) => void;
  isLoading?: boolean;
}

export const CreateUpdateForm = ({
  pronouns,
  onSubmit,
  isLoading,
}: ICreateUpdateForm) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        pronouns: pronouns || Pronouns.NONE,
      }}
      enableReinitialize
      onSubmit={(data) => onSubmit?.(data.pronouns)}
      validationSchema={getValidationSchema(t)}
    >
      {({ isValid, submitForm }) => {
        return (
          <>
            <Form isLoading={isLoading} />
            <div>
              <Button
                title={t("accountSettings.personalDetails.button.save")}
                buttonType="primary"
                disabled={!isValid || isLoading}
                type="button"
                onClick={submitForm}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};
