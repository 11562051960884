import { ILink } from "types/link";
import { IArticleId } from "../../types/article";
import { ICommunity } from "../../types/community";
import { IFile } from "../../types/file";
import { IInterest } from "../../types/interest";

export enum CollectionType {
  COMMUNITY_COLLECTION = "CommunityCollection",
  USER_COLLECTION = "UserCollection",
}

export interface ICollectionUser {
  publicId: string;
  fullName: string;
  email?: string;
  organization: {
    name?: string;
    userRole?: string;
  };
}

export interface IPartialUser {
  publicId: string;
  fullName: string;
  email?: string;
}

export interface INewFolder {
  name: string;
  publicId: string;
  updateTime?: string;
  isEdit?: boolean;
  isEditable?: boolean;
  canUserDeleteFolder?: boolean;
}

export interface ICollection {
  __typename: string;
  publicId: string;
  name: string;
  description: string;
  canUserDeleteCollection?: boolean;
  isPublic: boolean;
  isGlobalCurated?: boolean;
  collectionType?: CollectionType;
  imageName: string;
  imageDirectory: string;
  updateTime: number;
  creationTime: number;
  activitiesCount: number;
  collaboratorsCount: number;
  articlesCount: number;
  collectionCreator: IPartialUser;
  collaborators?: ICollectionUser[];
  followers?: ICollectionUser[];
  folders?: INewFolder[];
}

export interface ICollectionPreview {
  publicId: string;
  name: string;
  description: string;
  isPublic: boolean;
  isGlobalCurated?: boolean;
  imageName: string;
  imageDirectory: string;
  collaboratorsCount: number;
  collectionCreator: IPartialUser;
}

export interface ISingleCollection extends ICollection {
  collectionCreator: ICollectionUser;
  collaborators: ICollectionUser[];
  collectionType?: CollectionType;
  followers: ICollectionUser[];
  files: IFile[];
  links: ILink[];
  articles: Array<IArticleId>;
  filteredArticles: Array<IArticleId>;
  filteredArticlesCount: number;
  interests: Array<IInterest>;
  isEditable: boolean;
}

export interface ICollectionsResponse {
  collections?: {
    totalCount: number;
    collections: Array<ICollection>;
  };
}

export interface IGlobalCuratedCollectionsResponse {
  globalCuratedCollections?: {
    totalCount: number;
    collections: Array<ICollection>;
  };
}

export interface IMyCollectionsResponse {
  user: {
    myCollections: Array<ICollection>;
  };
}

export interface IUserCollectionResponse {
  user: {
    allCollections: ICollection[];
    myCollections: ICollection[];
    collaboratingCollections: ICollection[];
    followedCollections: ICollection[];
  };
}

export interface IUserAllCollectionsReponse {
  allUserCollections: {
    totalCount: number;
    collections: ICollection[];
  };
}

export interface ISingleCollectionResponse {
  collection: ISingleCollection;
}

export interface IUserCommunitiesResponse {
  userCommunities: {
    totalCount: number;
    communities: ICommunity[];
  };
}

export interface IRecommendedGlobalCuratedCollectionsResponse {
  recommendedGlobalCuratedCollections: {
    totalCount: number;
    collections: ICollection[];
  };
}
