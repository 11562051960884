import { gql } from "@apollo/client";

const REPORT_USER = gql`
  mutation reportUser(
    $reportedPublicId: String!
    $feedback: String!
    $reportTypePublicId: String!
  ) {
    reportUser(
      userReport: {
        reportedPublicId: $reportedPublicId
        feedback: $feedback
        reportTypePublicId: $reportTypePublicId
      }
    ) {
      publicId
      feedback
    }
  }
`;
export default REPORT_USER;
