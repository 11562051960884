import { useEffect, useState } from "react";
import { useSignalR } from "../../customHooks/useSignalR";
import { Subscription } from "./enum";
import { subscriptionSubscribers } from "./constant";
import { addSubscriber, IValue, removeSubscriber } from "./type";

export const useSubscriptions = <T>() => {
  const connection = useSignalR();
  const [subscriptionToSubscribers, setSubscriptionToSubscribers] = useState(
    () => new Map<Subscription, Array<IValue<T>>>(subscriptionSubscribers)
  );

  useEffect(() => {
    if (!connection) return;

    subscriptionToSubscribers.forEach((subscribers, subscription) =>
      connection.on(subscription, (response) =>
        subscribers.forEach((subscriber) => subscriber.callback(response))
      )
    );

    return () =>
      subscriptionToSubscribers.forEach((__, subscription) =>
        connection.off(subscription)
      );
  }, [connection, subscriptionToSubscribers]);

  const addSubscriber: addSubscriber<T> = (subscription, subscriber) => {
    setSubscriptionToSubscribers((map) => {
      const mapCopy = new Map(map);
      const subscribers = mapCopy.get(subscription);
      if (!subscribers || subscribers.some(({ id }) => id === subscriber.id)) return map;

      mapCopy.set(subscription, [...subscribers, subscriber]);

      return mapCopy;
    });
  };

  const removeSubscriber: removeSubscriber = (subscription, id) => {
    setSubscriptionToSubscribers((map) => {
      const mapCopy = new Map(map);
      const subscribers = mapCopy.get(subscription);
      if (!subscribers || !subscribers.some((subscriber) => subscriber.id === id))
        return map;

      mapCopy.set(
        subscription,
        subscribers.filter((subscriber) => subscriber.id !== id)
      );

      return mapCopy;
    });
  };

  return { addSubscriber, removeSubscriber };
};
