import axios from "axios";
import { displayServerError } from "./NotificationService/NotifacitonService";

export class ArticleService {
  public static async getSearchedArticles(
    term: string,
    size: number,
    interests: Array<string>,
    skip: number,
    locations: Array<string>,
    orderBy: string,
    hiddenArticleIds: Array<string>
  ) {
    try {
      const articles = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/article-search/ArticleSearch`,
        {
          SearchText: term,
          Size: size,
          Skip: skip,
          Interests: interests,
          Locations: locations,
          OrderBy: orderBy,
          HiddenArticleIds: hiddenArticleIds,
        }
      );
      return articles;
    } catch (error) {
      displayServerError(error);
    }
  }

  public static async getSearchedDashboardArticles(
    size: number,
    interests: Array<string>,
    skip: number,
    locations: Array<string>,
    hiddenArticleIds: Array<string>
  ) {
    try {
      const articles = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/article-search/Dashboard`,
        {
          Size: size,
          Skip: skip,
          Interests: interests,
          Locations: locations,
          HiddenArticleIds: hiddenArticleIds,
        }
      );
      return articles;
    } catch (error) {
      displayServerError(error);
    }
  }

  public static async getArticleById(id: string) {
    const article = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/article-search/Article/${id}`
    );

    return article;
  }

  public static async getArticlesByIds(list: Array<string>) {
    try {
      const articlesList = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/article-search/Articles`,
        {
          ArticleIds: list,
        }
      );
      return articlesList;
    } catch (error) {
      displayServerError(error);
    }
  }

  public static async getSuggestedArticles(publicId: string) {
    try {
      const articleList = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/article-search/SuggestArticles/${publicId}`
      );
      return articleList;
    } catch (error) {
      displayServerError(error);
    }
  }

  public static async uploadArticle(
    articleId: string,
    articleTitle: string,
    articleContent: string
  ) {
    try {
      const article = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/article-extraction/ArticleUploader`,
        {
          articleId,
          articleTitle,
          articleContent,
        }
      );
      return article;
    } catch (error) {
      displayServerError(error);
    }
  }

  public static addPlaceholderArticle() {
    return {
      title: "",
      content: "",
      image: "",
      domain: "",
      published: "",
      id: "placeholder",
      url: "",
    };
  }
}

export default ArticleService;
