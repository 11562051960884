import { Pronouns } from "@feature/pronouns";

import { Language } from "@feature/language";

import { IForm } from "./type";

export const initialValues: IForm = {
  fullName: "",
  email: "",
  phone: { countryCode: "", telephone: "" },
  defaultLanguage: Language.ENGLISH,
  pronouns: Pronouns.NONE,
  canReceiveMessages: true,
};
