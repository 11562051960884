import { useHistory } from "react-router-dom";

import { GroupChannel } from "sendbird";

import TooltipComponent from "@components/Tooltip/Tooltip";

import { isEllipsisActive } from "@utils/dom/helper";
import { useState } from "react";
import styles from "./Channel.module.scss";

interface IChannelProps {
  name: string;
  groupChannel?: GroupChannel;
  params?: { channel: string };
  disabled?: boolean;
  isRecentDiscussion?: boolean;
}

export const Channel = ({
  name,
  params,
  groupChannel,
  disabled = false,
  isRecentDiscussion = false,
}: IChannelProps) => {
  const history = useHistory();
  const [spanElement, setSpanElement] = useState<HTMLSpanElement | null>(null);

  const isActive = name === params?.channel;

  const channelElement = (
    <div
      className={`${styles.channel} ${isActive ? styles.active : ""} ${
        disabled ? styles.disabled : ""
      } ${isRecentDiscussion ? styles.reccentDiscussion : ""}`}
      onClick={() =>
        history.push(`/portal/community/community-page/${groupChannel?.url}/${name}`)
      }
    >
      <span className={styles.hashtag}>#</span>
      <span
        ref={(element) => {
          setSpanElement(element);
        }}
        className={styles.name}
      >
        {name}
      </span>
    </div>
  );

  if (spanElement && !isEllipsisActive(spanElement)) {
    return channelElement;
  }

  return (
    <TooltipComponent
      tooltipType="secondary"
      placement="left"
      title={name}
      childToWrap={channelElement}
    />
  );
};
