/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { useLazyQuery } from "@apollo/client";

import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import GET_USERS from "@graphql/queries/usersNameEmail";

import { SEARCH_TRIGGER, STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

import getStorageImage from "@utils/getStorageImage";
import { scrolledToEnd } from "@utils/helpers";

import { IChatUsersResponse, IPartialUser, IUserImageResponse } from "types/user";

import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import SearchInput from "../SearchInput/SearchInput";

import Avatar from "@images/avatar.svg";
import Checked from "@images/checked.svg";
import Close from "@images/close.svg";
import Search from "@images/search_icon.svg";

import { IUseSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/type";
import { CHAT_ROUTES } from "@utils/routes/chat";
import styles from "./CreateDirectChat.module.scss";

interface ICreateGroupChatProps {
  open: boolean;
  close: () => void;
  sendBirdGroupChannels: IUseSendBirdGroupChannels;
}

const SIZE = 100;

const CreateGroupChat = ({
  open,
  close,
  sendBirdGroupChannels,
}: ICreateGroupChatProps) => {
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslation();
  const [searchedUsers, setSearchedUsers] = useState<Array<IPartialUser>>([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver>();
  const scrollContainer = document.getElementById("members_container");

  const [getUsers] = useLazyQuery<IChatUsersResponse>(GET_USERS, {
    onCompleted: ({ users: { users, totalCount } }) => {
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: users.map((user) => user.email),
          },
        },
      });
      setSearchedUsers([...users]);
      setHasMoreUsers(totalCount > 0);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const lastUserElementRef = useCallback(
    (node) => {
      observer.current && observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreUsers) {
          if (scrollContainer && !scrolledToEnd(scrollContainer)) return;
          setSkip((skip) => skip + 100);
        }
      });
      node && observer.current.observe(node);
    },
    [hasMoreUsers]
  );

  useEffect(() => {
    if (!search.length) {
      getUsers({
        variables: {
          size: SIZE + skip,
        },
      });
    }
    search.length > SEARCH_TRIGGER &&
      getUsers({
        variables: {
          text: search,
          size: SIZE + skip,
        },
      });
  }, [search, skip]);

  const createDirectChat = () => {
    const onSuccess = () => {
      close();
      setSelectedUserEmail("");
      setSearch("");
      setSkip(0);
    };

    sendBirdGroupChannels.helpers.createDirectGroupChannel({
      selectedUserEmail,
      onSuccess,
      pathPrefix: CHAT_ROUTES.CHAT,
    });
  };

  return (
    <Modal
      className={styles.container}
      isOpen={open}
      onRequestClose={() => {
        setSearch("");
        setSelectedUserEmail("");
        close();
        setSkip(0);
      }}
      overlayClassName={styles.overlay}
    >
      <img
        onClick={() => {
          setSearch("");
          setSelectedUserEmail("");
          close();
          setSkip(0);
        }}
        src={Close}
        alt=""
        className={styles.close}
      />

      <div className={styles.searchContainer}>
        <SearchInput
          searchIcon={<img src={Search} alt="" className={styles.searchIcon} />}
          value={search}
          label={t("chat.group.createGroup.searchLabel")}
          labelClassName={styles.subTitle}
          className={styles.searchInput}
          onChange={(e) => setSearch(e.target.value)}
          name="memberSearch"
          placeholder={t("chat.direct.modal.searchPlaceholder")}
        />
      </div>

      <div className={styles.memberList} id="members_container">
        {searchedUsers.map((member, index) => (
          <div
            key={index}
            className={styles.memberItem}
            onClick={() =>
              selectedUserEmail === member.email
                ? setSelectedUserEmail("")
                : setSelectedUserEmail(member.email)
            }
          >
            <div
              style={{
                backgroundImage: `url(${
                  userImages
                    ? userImages.find((image) => image.email === member.email)?.image
                    : Avatar
                })`,
              }}
              className={styles.memberImage}
            ></div>
            <div>
              {searchedUsers.length === index + 1 ? (
                <div ref={lastUserElementRef} className={styles.memberName}>
                  {member.fullName}
                </div>
              ) : (
                <div className={styles.memberName}>{member.fullName}</div>
              )}
            </div>

            {selectedUserEmail === member.email ? (
              <img src={Checked} alt="" className={styles.checked} />
            ) : (
              <div className={styles.unChecked} />
            )}
          </div>
        ))}
      </div>

      <div className={styles.flexContainer}>
        <Button
          onClick={() => {
            close();
            setSelectedUserEmail("");
            setSearch("");
            setSkip(0);
          }}
          title={t("chat.direct.modal.cancel")}
          buttonType="link"
        />
        <Button
          onClick={createDirectChat}
          title={t("chat.direct.modal.create")}
          disabled={!selectedUserEmail}
          buttonType="primary"
        />
      </div>
    </Modal>
  );
};

export default CreateGroupChat;
