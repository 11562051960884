import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import SortDropdown from "@components/SortDropdown/SortDropdown";
import SuggestResourceModal from "@components/SuggestResourceModal/SuggestResourceModal";

import { ARTICLES_PATH } from "@constants/pathConstants";

import styles from "./ResourcesHeading.module.scss";

interface IResourcesHeadingProps {
  resultsCount: number;
  searchValue: string;
  sectionTitle?: string;
  isTitleDisplayed?: boolean;
}

const ResourcesHeading = ({
  resultsCount,
  searchValue,
  sectionTitle,
  isTitleDisplayed = true,
}: IResourcesHeadingProps) => {
  const { t } = useTranslation();
  const [openSuggestResourceModal, setOpenSuggestResourceModal] =
    useState<boolean>(false);
  const location = useLocation();
  const isCurrentPathArticles = location.pathname === ARTICLES_PATH;

  const getTitle = () => {
    const titlePrefix = `${t("resources.resultsFor")}`;
    const titleSuffix = `'${searchValue}' ${sectionTitle ?? ""}`;

    return `${titlePrefix} ${titleSuffix}`;
  };

  return (
    <div className={styles.headingContainer}>
      {!!resultsCount && searchValue && (
        <p>
          {resultsCount} {t("resources.resultsCount")}
        </p>
      )}
      {searchValue && (
        <div className={styles.row}>
          <div>
            {isTitleDisplayed && (
              <h2 className={styles.resultsSearchTerm}>{getTitle()}</h2>
            )}
            {isCurrentPathArticles && (
              <span className={styles.suggestRow}>
                <Trans
                  i18nKey="resources.resourcesSuggestLabel"
                  components={{
                    button: (
                      <Button
                        onClick={() => setOpenSuggestResourceModal(true)}
                        title={t("suggestResourceModal.resourcesButtonTitle")}
                        buttonType="link"
                      />
                    ),
                  }}
                />
              </span>
            )}
          </div>
          {sectionTitle && isCurrentPathArticles && (
            <div className={styles.sectionControls}>
              <SortDropdown />
            </div>
          )}
        </div>
      )}
      <SuggestResourceModal
        open={openSuggestResourceModal}
        close={() => {
          setOpenSuggestResourceModal(false);
        }}
      />
    </div>
  );
};

export default ResourcesHeading;
