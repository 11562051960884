import { gql } from "@apollo/client";

// creator, admins and members are excluded from this query BE
// includeUsersWithChatPrivacy is always false on BE default value

const GET_INVITABLE_USERS_TO_COMMUNITY = gql`
  query ($invitableUsersSearch: InvitableUsersToCommunityInputType!) {
    invitableUsersToCommunity(invitableUsersSearch: $invitableUsersSearch) {
      fullName
      email
      publicId
      organization {
        sector {
          name
        }
        name
        userRole
      }
    }
  }
`;
export default GET_INVITABLE_USERS_TO_COMMUNITY;
