import { gql } from "@apollo/client";

const GET_PAST_EVENTS = gql`
  query user {
    user {
      email
      fullName
      telephone
      isOnboarded
      pastEvents {
        name
        description
        hostingId
        publicId
        startDate
        endDate
        eventImageName
        imageDirectory
        eventType
        eventHost {
          fullName
          email
        }
      }
    }
  }
`;

export default GET_PAST_EVENTS;
