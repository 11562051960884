import { useEffect, useState } from "react";

export const useCommunityTilesTitleMaxLength = () => {
  const [maxTitleLength, setMaxTitleLength] = useState<number>(24);

  useEffect(() => {
    if (!window.innerWidth) return;

    if (1280 <= window.innerWidth && window.innerWidth < 1400) setMaxTitleLength(27);
    if (1400 <= window.innerWidth && window.innerWidth < 1440) setMaxTitleLength(32);
    if (1440 <= window.innerWidth && window.innerWidth < 1680) setMaxTitleLength(37);
    if (window.innerWidth >= 1680) setMaxTitleLength(50);
  }, []);

  return { maxTitleLength };
};
