import { createContext, useContext } from "react";
import { ISubscriptionContext } from "./type";
import { useSubscriptions } from "./hook";

interface ISubscriptionProvider {
  children: React.ReactElement;
}

const SubscriptionContext = createContext<ISubscriptionContext<unknown> | null>(null);

export function useSubscriptionsContext<T>() {
  const context = useContext(SubscriptionContext);
  return context as ISubscriptionContext<T> | null;
}

export const SubscriptionsProvider = ({ children }: ISubscriptionProvider) => {
  const { addSubscriber, removeSubscriber } = useSubscriptions();

  return (
    <SubscriptionContext.Provider value={{ addSubscriber, removeSubscriber }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
