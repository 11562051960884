/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import CreateEvent from "@components/CreateEvent/CreateEvent";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_USER_INFO_HOSTING_EVENTS from "@graphql/queries/getUserInfoHostingEvents";

import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { findFirstItemFromNextPage, simulatePagination } from "@utils/eventsHelper";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { IEvent } from "types/event";
import styles from "./HostingEvents.module.scss";

import Loading from "@components/Loading/Loading";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const HostingEvents = () => {
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const { t } = useTranslation();
  const [eventIdForScroll, setEventIdForScroll] = useState("");
  const [hostName, setHostName] = useState<string>("");
  const [paginationLimiter, setPaginationLimiter] = useState<number>(EVENTS_GRID_SIZE);

  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [getHostingEvents, { loading }] = useLazyQuery(GET_USER_INFO_HOSTING_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setEvents(data.userInfo.hostingEvents);
      setEventsCount(data.userInfo.hostingEvents.length);
      setHostName(data.userInfo.fullName);
    },
    onError: (error) => displayServerError(error),
  });

  const extendVisibleEvents = () => {
    setPaginationLimiter(paginationLimiter + EVENTS_GRID_SIZE);
  };

  useEffect(() => {
    if (!eventsCount || !paginationLimiter) return;
    setEventIdForScroll(findFirstItemFromNextPage(events, paginationLimiter));
  }, [paginationLimiter, eventsCount]);

  useEffect(() => {
    getHostingEvents({
      variables: {
        email: currentUserEmail,
        hostingEventSearch: { from: getDateInUtc(), size: 100 },
      },
    });
  }, []);

  if (loading) return <Loading disableBoxShadow e2eTestId="hosting-events-loader" />;

  return !events.length ? (
    <div
      className={styles.createEventContainer}
      e2e-test-id="no-hosting-events-message-container"
    >
      <CreateEvent />
    </div>
  ) : (
    <div className={styles.tabContainer}>
      <div className={styles.hostingTitleContainer}>
        <span className={styles.hostingTabTitle}>{t("createEvent.hosting")}</span>
      </div>
      <div className={styles.hostingEventsContainer}>
        <EventsGrid
          events={simulatePagination(events, paginationLimiter)}
          loadMore={extendVisibleEvents}
          eventsLoading={loading}
          eventsCount={eventsCount}
          eventsLoadingMore={false}
          firstItemId={eventIdForScroll}
          hostName={hostName}
        />
      </div>
    </div>
  );
};

export default HostingEvents;
