import { useSignalR } from "@customHooks/useSignalR";
import { HubConnection } from "@microsoft/signalr";
import { createContext, useContext } from "react";

interface ISignalRProvider {
  children: React.ReactElement;
}

const SignalRContext = createContext<HubConnection | null>(null);

export const useSignalRContext = () => useContext(SignalRContext);

export const SignalRProvider = ({ children }: ISignalRProvider) => {
  const connection = useSignalR();

  return <SignalRContext.Provider value={connection}>{children}</SignalRContext.Provider>;
};
