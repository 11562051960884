import { useLazyQuery } from "@apollo/client";
import basicStyles from "@components/BasicModal/BasicModal.module.scss";
import Modal from "@components/Modal/Modal";
import Avatar from "@images/avatar.svg";
import Exit from "@images/exit.svg";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// for user drawer
import GET_USER_INFO from "./../../graphql/queries/getUserInfo";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import getStorageImage from "@utils/getStorageImage";
import { IUser, IUserImageResponse } from "types/user";
import styles from "./AdminFollowersModal.module.scss";

interface AdminFollowersProps {
  title: string;
  isOpen: boolean;
  communityCreator: IUser;
  admins: IUser[];
  followers: IUser[];
  type: boolean;
  onClose: () => void;
}

interface IPerson {
  isCreator?: boolean;
  isAdmin?: boolean;
  fullName: string;
  email: string;
  organization: {
    name: string;
    userRole: string;
    sector: {
      name: string;
    };
  };
}

const AdminFollowersModal = ({
  title,
  isOpen,
  communityCreator,
  admins,
  followers,
  type,
  onClose,
}: AdminFollowersProps) => {
  const handleClose = () => onClose();
  const { t } = useTranslation();

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  useEffect(() => {
    const emails = admins
      .map((admin) => admin.email)
      .concat(followers.map((follower) => follower.email))
      .concat(communityCreator.email);

    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: emails,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUser && setUser(data.userInfo);
      setIsUserDrawerOpen(true);
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const Person = ({
    fullName,
    organization,
    email,
    isCreator = false,
    isAdmin = false,
  }: IPerson) => {
    const { name, userRole } = organization;
    return (
      <div
        className={styles.personItem}
        onClick={() => {
          getUserInfo({
            variables: {
              email,
            },
          });
          handleClose();
        }}
      >
        <div
          className={styles.avatar}
          style={{
            backgroundImage: `url(${
              userImages
                ? userImages.find((image) => image.email === email)?.image
                : Avatar
            })`,
          }}
        />
        <div className={styles.personTextContainer}>
          <p className={styles.title}>
            <span>{fullName}</span>
            {(isCreator || isAdmin) && <span className={styles.dotMargin}>•</span>}
            {isCreator && <span>{t("community.administration.roles.groupCreator")}</span>}
            {isAdmin && (
              <span>{t("community.administration.roles.groupAdministrator")}</span>
            )}
          </p>
          <p className={styles.subTitle}>
            {t("collections.singleCollection.personRoleAtCompany", {
              userRole,
              company: name,
            })}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal onRequestClose={handleClose} isOpen={isOpen} modalType="narrow">
        <div className={styles.textContainer} data-testid="modal-outer">
          <h4 className={basicStyles.title}>{title}</h4>
          <p className={styles.modalSubtitle}>
            {type}
            <span className={styles.dotMargin}>•</span>
            {admins.length === 1
              ? t("collections.communityCollections.singleAdmin", {
                  count: admins.length,
                })
              : t("collections.communityCollections.multipleAdmins", {
                  count: admins.length,
                })}
            <span className={styles.dotMargin}>•</span>
            {followers.length === 1
              ? t("collections.communityCollections.singleFollower", {
                  count: followers.length,
                })
              : t("collections.communityCollections.multipleFollowers", {
                  count: followers.length,
                })}
          </p>
          <img
            role="button"
            aria-label="close-modal"
            className={basicStyles.closeImage}
            onClick={handleClose}
            src={Exit}
            alt=""
          />
        </div>
        <div className={styles.peopleList}>
          {communityCreator && (
            <Person {...communityCreator} isCreator key={communityCreator.publicId} />
          )}
          {admins &&
            admins.map((person: IUser) => (
              <Person isAdmin {...person} key={person.publicId} />
            ))}
          {followers &&
            followers.map((person: IUser) => (
              <Person {...person} key={person.publicId} />
            ))}
        </div>
      </Modal>
      {user && isUserDrawerOpen && (
        <UserDrawer
          user={user}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={<></>}
        />
      )}
    </>
  );
};

export default AdminFollowersModal;
