export const termsContent = [
  {
    content: "contentOne",
  },
  {
    content: "contentTwo",
  },
  {
    title: "titleOne",
    content: "contentHrefOne",
  },
  {
    content: "contentHrefTwo",
  },
  {
    title: "titleTwo",
    content: "contentThree",
  },
  {
    content: "contentFour",
  },
  {
    content: "contentFive",
  },
  {
    title: "titleThree",
    content: "contentSix",
  },
  {
    title: "titleFour",
    content: "tableContent",
  },
  {
    title: "titleFive",
    content: "lastSectionContent",
  },
  {
    title: "titleSix",
    content: "lastContent",
  },
];
