import { Language } from "./enum";

const prefix = "select.language.";

export const LanguageToTranslationKey = {
  [Language.ENGLISH]: `${prefix}English`,
  [Language.KOREAN]: `${prefix}Korean`,
  [Language.SPANISH]: `${prefix}Spanish`,
  [Language.FRENCH]: `${prefix}French`,
  [Language.RUSSIAN]: `${prefix}Russian`,
  [Language.CHINESE]: `${prefix}Chinese`,
  [Language.ARABIC]: `${prefix}Arabic`,
};

export const LanguageToKey = {
  [Language.ENGLISH]: "en",
  [Language.KOREAN]: "ko",
  [Language.SPANISH]: "es",
  [Language.FRENCH]: "fr",
  [Language.RUSSIAN]: "ru",
  [Language.CHINESE]: "zh",
  [Language.ARABIC]: "ar",
};
