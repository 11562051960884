import React, { DragEvent, useEffect, useRef, useState } from "react";

import styles from "./DragAndDrop.module.scss";
import { preventDefaultAndStopPropagation } from "./helper";

interface IDragAndDrop {
  children: React.ReactNode;
  onDragStart?: (event: DragEvent) => void;
  onDrop?: (event: DragEvent) => void;
  onHover?: (isHovered: boolean) => void;
  isDraggable?: boolean;
}

export const DragAndDrop = ({
  children,
  onDrop,
  onHover,
  onDragStart,
  isDraggable,
}: IDragAndDrop) => {
  const [currentDepth, setCurrentDepth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (currentDepth > 0) return setIsHovered(true);

    setIsHovered(false);
  }, [currentDepth]);

  useEffect(() => {
    if (!didMountRef.current) return;
    onHover?.(isHovered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  const handleDragStart = (event: DragEvent) => {
    onDragStart?.(event);
    setIsDragging(true);
  };

  const handleDragEnter = (event: DragEvent) => {
    setCurrentDepth((current) => current + 1);
    preventDefaultAndStopPropagation(event);
  };

  const handleDragLeave = (event: DragEvent) => {
    setCurrentDepth((current) => current - 1);
    preventDefaultAndStopPropagation(event);
  };

  const handleDragOver = (event: DragEvent) => {
    preventDefaultAndStopPropagation(event);
  };

  const handleDrop = (event: DragEvent) => {
    setCurrentDepth(0);
    onDrop?.(event);
    preventDefaultAndStopPropagation(event);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={`${isDragging && styles.dragAndDrop}`}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      draggable={isDraggable}
    >
      {children}
    </div>
  );
};
