/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { RootState } from "../../store/RootState";
// SendBird
import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import Menu from "@components/Menu/Menu";
import SendBird from "sendbird";
import PinnedFilesSection from "./PinnedFilesSection/PinnedFilesSection";

import Loading from "@components/Loading/Loading";
import TooltipComponent from "@components/Tooltip/Tooltip";
import {
  DEFAULT_SLIDER_PAGE_SIZE,
  MAX_RECENT_DISCUSSIONS,
  NO_ACCESS_CODE,
  SORTING_MOST_RECENT,
  USER_NOT_MEMBER,
} from "@constants/constants";
import { UPDATED_COMMUNITY_CHANNELS } from "@constants/sendbirdSubsctiptionsConstants";
import { useSendBirdMessage } from "@customHooks/sendBird/useSendBirdMessage/useSendBirdMessage";
import {
  GET_COMMUNITY_COLLECTIONS_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_COLLECTIONS_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE,
  GET_PINNED_FILES_FOR_COMMUNITY_CHANEL_PAGE,
} from "@graphql/community-collection/queries";
import JOIN_PRIVATE_COMMUNITY from "@graphql/mutations/joinClosedCommunity";
import JOIN_PUBLIC_COMMUNITY from "@graphql/mutations/joinOpenCommunity";
import {
  CommunityType,
  ICommunity,
  ICommunityPreviewResponse,
  ICommunityResponse,
} from "types/community";
import { IFile } from "types/file";
import { useSignalRContext } from "../../customContext/contextSignalR";
import { setCommunityMembers } from "../../store/community/actions";

import DangerModal from "@components/DangerModal/DangerModal";
import InviteToCommunityModal from "@components/InviteToCommunityModal/InviteToCommunityModal";
import CommunityMembersSection from "./CommunityMembersSection/CommunityMembersSection";

import DefaultBannerImage from "@images/banner.png";
import DangerIcon from "@images/DangerIcon.png";
import PrivateCommunityIcon from "@images/privateCommunityIcon.svg";

import ClosedCommunityIcon from "@images/closedCommunityIcon.svg";
import { ReactComponent as MoreIcon } from "@images/moreInfo.svg";
import OpenCommunityIcon from "@images/openCommunityIcon.svg";

import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import { Channel } from "@components/CommunitySidemenu/Channel/Channel";
import { LinkedText } from "@components/DataDisplay/LinkedText/LinkedText";
import { IOption } from "@components/Menu/types";
import ShareModal from "@components/ShareModal/ShareModal";
import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import { UNLINK_COLLECTION_FROM_COMMUNITY } from "@graphql/collection/mutation";
import { CollectionType, ICollection } from "@graphql/collection/type";
import { DELETE_COMMUNITY } from "@graphql/mutations/deleteCommunity";
import LEAVE_COMMUNITY from "@graphql/mutations/leaveCommunity";
import GET_USER_COMMUNITIES from "@graphql/queries/userCommunities";
import LinkCollectionToCommunityModal from "@modules/Collections/LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";
import LinksList from "@modules/CommunityHomepage/LinksList/LinksList";
import UpcomingCommunityEvents from "@modules/CommunityHomepage/UpcomingCommunityEvents/UpcomingCommunityEvents";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import {
  displayErrorNotification,
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getMenuOptions } from "@utils/collectionsHelper";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import { Collapse } from "antd";
import { GraphQLError } from "graphql";
import { IEvent } from "types/event";
import { IJoinRequest } from "types/joinRequest";
import { ILinkListData } from "types/links";
import { addCrumb, clearBreadCrumbs } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";
import {
  clearDefaultCollection,
  setDefaultCollection,
} from "../../store/communityDefaultCollection/actions";
import styles from "./CommunityHomepage.module.scss";

enum USER_ROLE {
  owner = "owner",
  admin = "admin",
  member = "member",
}

const CommunityHomepage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const params = useParams<{
    community: string;
    channel: string;
    message: string;
  }>();
  const { sb } = useSendBirdContext();
  const [groupChannel, setGroupChannel] = useState<SendBird.GroupChannel>();
  const DEFAULT_CHANNEL = "General";
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const [isCheckingIfMember, setIsCheckingIfMember] = useState<boolean>(true);
  const { t } = useTranslation();
  const signalRConnection = useSignalRContext();
  const [isCurrentUserCommunityCreator, setIsCurrentUserCommunityCreator] =
    useState<boolean>(false);
  const [isUserNotAllowed, setIsUserNotAllowed] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<USER_ROLE>();
  const [manageOptions, setManageOptions] = useState<IOption[]>([]);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [isOpenDeleteCommunityModal, setOpenDeleteCommunityModal] =
    useState<boolean>(false);

  const [recentDiscussions, setRecentDiscussions] = useState<string[]>();

  const [communityIconType, setCommunityIconType] = useState();
  const [communityType, setCommunityType] = useState<string>();

  const [currentUserCommunities, setCurrentUserCommunities] = useState<ICommunity[]>([]);

  const [joinPublicLoading, setJoinPublicLoading] = useState<boolean>(false);
  const [joinPublicCommunity] = useMutation(JOIN_PUBLIC_COMMUNITY);

  const [isRequestingToJoin, setIsRequestingToJoin] = useState<boolean>(false);
  const [isJoinPrivateCommunityRequested, setIsJoinPrivateCommunityRequested] =
    useState<boolean>(false);
  const [joinPrivateCommunity] = useMutation(JOIN_PRIVATE_COMMUNITY);

  const { removeCommunityNotificationsRelatedToCommunity } =
    useCommunityNotificationsHelper();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareCollectionUrl, setShareCollectionUrl] = useState<string>();
  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState(false);
  const [targetCommunityId, setTargetCommunityId] = useState("");
  const [collectionToLinkId, setCollectionToLinkId] = useState<string>();
  const [collectionName, setCollectionName] = useState<string>();
  const [pinnedFiles, setPinnedFiles] = useState<IFile[]>([]);
  const [shouldRefetchPinnedFiles, setShouldRefetchPinnedFiles] =
    useState<boolean>(false);

  const [shouldGetPreviewData, setShouldGetPreviewData] = useState<boolean>(false);
  const [collections, setCollections] = useState<ICollection[]>([]);

  const [upcomingEventsData, setUpcomingEventsData] = useState<IEvent[]>([]);
  const [communityLinks, setCommunityLinks] = useState<ILinkListData[]>([]);

  const getGroupChannel = () => {
    if (!sb) return;

    sb.GroupChannel.getChannel(params.community, (groupChannel, error) => {
      if (error) {
        if (error.code === NO_ACCESS_CODE) return setIsUserNotAllowed(true);
        return;
      }

      setIsUserNotAllowed(true);
      setGroupChannel(groupChannel);
    });
  };
  useEffect(() => {
    getGroupChannel();
  }, [sb, params.community]);

  const sendBirdMessageHelper = useSendBirdMessage({ groupChannel });
  const {
    messageActions: { setSendBirdIdCommunity },
    messageState: { messages },
  } = sendBirdMessageHelper;

  const [community, setCommunity] = useState<ICommunity>();
  const [recentCommunityCollections, setRecentCommunityCollections] =
    useState<ICollection[]>();

  useEffect(() => {
    if (!signalRConnection) return;
    signalRConnection.on(UPDATED_COMMUNITY_CHANNELS, (ids) => {
      if (params.community === ids[0])
        displaySuccessNotification(t("community.addedToNewChannel"));
      getCommunity({ variables: { sendbirdId: ids[0] } });
    });
    return () => signalRConnection.off(UPDATED_COMMUNITY_CHANNELS);
  }, [signalRConnection]);

  const [leaveCommunity] = useMutation(LEAVE_COMMUNITY, {
    onCompleted: () => {
      removeCommunityNotificationsRelatedToCommunity(params.community);
      displaySuccessNotification(t("community.drawer.succesLeave"));
      setTimeout(() => {
        history.push("/portal/community");
      }, 500);
    },
  });
  const [DeleteCommunity] = useMutation(DELETE_COMMUNITY, {
    onCompleted: () => {
      displayErrorNotification(
        t("community.drawer.communitiesActions.successDeleteCommunity", {
          name: community?.name,
        })
      );
      history.push("/portal/community");
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const [unlinkCollectionFromCommunity] = useMutation(UNLINK_COLLECTION_FROM_COMMUNITY, {
    onCompleted: () => {
      displaySuccessNotification(
        t("community.drawer.communitiesActions.successUnlinkCollection", {
          name: community?.name,
        })
      );
      refetchCommunityCollections();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const handleLeaveCommunity = () =>
    leaveCommunity({ variables: { sendbirdId: params.community } });

  const handleDeleteCommunity = () =>
    DeleteCommunity({
      variables: {
        sendbirdId: params.community,
      },
    });

  const [getPinnedFiles] = useLazyQuery<ICommunityResponse>(
    GET_PINNED_FILES_FOR_COMMUNITY_CHANEL_PAGE,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ community }) => {
        setPinnedFiles(community.pinnedFiles!);
        setShouldRefetchPinnedFiles(false);
      },
    }
  );

  const [getCommunity, { loading: isLoadingCommunity }] =
    useLazyQuery<ICommunityResponse>(GET_COMMUNITY_FOR_COMMUNITY_CHANEL_PAGE, {
      fetchPolicy: "network-only",
      onCompleted: ({ community }) => {
        if (community.pinnedFiles) {
          setPinnedFiles(community.pinnedFiles);
        }

        if (!community) {
          displayServerError();
          return;
        }
        setCommunity(community);

        if (community?.communityType === CommunityType.open) {
          setCommunityIconType(OpenCommunityIcon);
          setCommunityType("community.communityType.openWithDescription");
        }
        if (community?.communityType === CommunityType.private) {
          setCommunityIconType(PrivateCommunityIcon);
          setCommunityType("community.communityType.privateWithDescription");
        }
        if (community?.communityType === CommunityType.closed) {
          setCommunityIconType(ClosedCommunityIcon);
          setCommunityType("community.communityType.closedWithDescription");
        }

        dispatch(
          setCommunityMembers({
            admins: community.admins,
            members: community.members,
            creator: community.communityCreator,
          })
        );

        const adminEmails = community.admins.map((admin) => admin.email);
        const memberEmails = community.members.map((member) => member.email);

        setIsCurrentUserCommunityCreator(
          community.communityCreator.email === currentUserEmail
        );

        if (adminEmails.includes(currentUserEmail)) {
          setUserRole(USER_ROLE.admin);
          setManageOptions([
            {
              label: "edit",
              action: () => history.push(`/portal/edit/community/${params.community}`),
              e2eTestId: "edit-community",
            },
            {
              label: "invite",
              action: () => setOpenInviteModal(true),
              e2eTestId: "invite-members",
            },
            ...(!(community.communityType === CommunityType.open)
              ? [
                  {
                    label: "requestsToJoin",
                    action: () =>
                      history.push(`/portal/community/requests/${params.community}`),
                    e2eTestId: "requests-to-join",
                  },
                ]
              : []),
            {
              label: "leave",
              action: () => handleLeaveCommunity(),
              e2eTestId: "leave-community",
            },
          ]);
        } else if (memberEmails.includes(currentUserEmail)) {
          setUserRole(USER_ROLE.member);
          setManageOptions([
            {
              label: "leave",
              action: () => handleLeaveCommunity(),
              e2eTestId: "leave-community",
            },
          ]);
        } else {
          setUserRole(USER_ROLE.owner);
          setManageOptions([
            {
              label: "edit",
              action: () => history.push(`/portal/edit/community/${params.community}`),
              e2eTestId: "edit-community",
            },
            {
              label: "invite",
              action: () => setOpenInviteModal(true),
              e2eTestId: "invite-members",
            },
            ...(!(community.communityType === CommunityType.open)
              ? [
                  {
                    label: "requestsToJoin",
                    action: () =>
                      history.push(`/portal/community/requests/${params.community}`),
                    e2eTestId: "requests-to-join",
                  },
                ]
              : []),
            {
              label: "delete",
              action: () => setOpenDeleteCommunityModal(true),
              e2eTestId: "delete-community",
              isDelete: true,
            },
          ]);
        }

        setIsJoinPrivateCommunityRequested(
          community.joinRequests.some(
            (joinRequest: IJoinRequest) => joinRequest.user.email === currentUserEmail
          )
        );

        // clear previously remembered path of breadcrumbs before assigning new one
        dispatch(clearBreadCrumbs());

        const breadCrumb: IBreadcrumb = {
          id: community.sendbirdId,
          name: community.name,
          path: location.pathname,
        };

        dispatch(addCrumb(breadCrumb));

        // Clear default community collection
        dispatch(clearDefaultCollection());
        if (community.defaultCollection) {
          const defaultCollection = {
            name: community.defaultCollection.name,
            publicId: community.defaultCollection.publicId,
          };

          // if there is a default community collection set it in the store
          dispatch(setDefaultCollection(defaultCollection));
        }
        // upcomingEventsData logic
        if (community.communityEvents) {
          setUpcomingEventsData(community.communityEvents);
        }

        if (community.communityLinks) {
          setCommunityLinks(community.communityLinks);
        }
      },
      onError: (error) => {
        const userIsNotAMemberError = error?.graphQLErrors.filter(
          (graphQLError: GraphQLError) => graphQLError.extensions.code === USER_NOT_MEMBER
        );

        if (userIsNotAMemberError) {
          setShouldGetPreviewData(true);
        } else {
          groupChannel && displayNotSuccessNotification("", error?.message);
        }
      },
    });

  const [getCommunityPreview, { loading: isLoadingCommunityPreview }] =
    useLazyQuery<ICommunityPreviewResponse>(
      GET_COMMUNITY_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE,
      {
        fetchPolicy: "no-cache",
        onCompleted: ({ communityPreview }) => {
          if (communityPreview.pinnedFiles) {
            setPinnedFiles(communityPreview.pinnedFiles);
          }

          if (!communityPreview) {
            displayServerError();
            return;
          }
          setCommunity(communityPreview as ICommunity);

          if (communityPreview.communityType === CommunityType.open) {
            setCommunityIconType(OpenCommunityIcon);
            setCommunityType("community.communityType.openWithDescription");
          }
          if (communityPreview.communityType === CommunityType.private) {
            setCommunityIconType(PrivateCommunityIcon);
            setCommunityType("community.communityType.privateWithDescription");
          }
          if (communityPreview.communityType === CommunityType.closed) {
            setCommunityIconType(ClosedCommunityIcon);
            setCommunityType("community.communityType.closedWithDescription");
          }

          setIsJoinPrivateCommunityRequested(
            communityPreview.joinRequests.some(
              (joinRequest: IJoinRequest) => joinRequest.user.email === currentUserEmail
            )
          );

          // upcomingEventsData logic
          if (communityPreview.communityEvents) {
            setUpcomingEventsData(communityPreview.communityEvents);
          }

          if (communityPreview.communityLinks) {
            setCommunityLinks(communityPreview.communityLinks);
          }
        },
        onError: (error) => {
          groupChannel && displayNotSuccessNotification("", error?.message);
        },
      }
    );

  const [
    getCommunityCollections,
    { refetch: refetchCommunityCollections, loading: isLoadingCollections },
  ] = useLazyQuery<{
    totalCount: number;
    allCommunityCollections: {
      collections: ICollection[];
    };
  }>(GET_COMMUNITY_COLLECTIONS_FOR_COMMUNITY_CHANEL_PAGE, {
    fetchPolicy: "no-cache",
    onCompleted: (response) =>
      setRecentCommunityCollections(response.allCommunityCollections.collections),
    onError: (error) => {
      const userIsNotAMemberError = error?.graphQLErrors.filter(
        (graphQLError: GraphQLError) => graphQLError.extensions.code === USER_NOT_MEMBER
      );

      if (userIsNotAMemberError) {
        setShouldGetPreviewData(true);
      } else {
        groupChannel && displayNotSuccessNotification("", error?.message);
      }
    },
  });

  const [getCommunityCollectionsPreview, { loading: isLoadingCollectionsPreview }] =
    useLazyQuery<{
      totalCount: number;
      allCommunityCollectionsPreview: {
        collections: ICollection[];
      };
    }>(GET_COMMUNITY_COLLECTIONS_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE, {
      fetchPolicy: "no-cache",
      onCompleted: (response) =>
        setRecentCommunityCollections(
          response.allCommunityCollectionsPreview.collections
        ),
    });

  const [, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  useEffect(() => {
    if (community?.sendbirdId) {
      setSendBirdIdCommunity(community.sendbirdId);
    }
  }, [community]);

  useEffect(() => {
    if (shouldGetPreviewData) {
      getCommunityPreview({ variables: { sendbirdId: params.community } });
      getCommunityCollectionsPreview({
        variables: {
          communityCollectionSearch: {
            sendbirdId: params.community,
            sortingCriteria: SORTING_MOST_RECENT,
            size: DEFAULT_SLIDER_PAGE_SIZE,
          },
        },
      });
    }
  }, [shouldGetPreviewData]);

  useEffect(() => {
    const sortedMessages = messages.sort((a, b) => b.createdAt - a.createdAt);
    const recentDiscussions = sortedMessages.map((message) => message.customType);

    const uniqueRecentDiscussions = recentDiscussions.filter(
      (discussion, index, array) => array.indexOf(discussion) === index
    ) as string[];

    if (uniqueRecentDiscussions.length) {
      setRecentDiscussions(
        uniqueRecentDiscussions.filter((disc) => disc).slice(0, MAX_RECENT_DISCUSSIONS)
      );
    } else {
      setRecentDiscussions([DEFAULT_CHANNEL]);
    }
  }, [messages]);

  useEffect(() => {
    if (!groupChannel || !currentUserCommunities.length) return;

    if (
      !currentUserCommunities.some(({ sendbirdId }) => sendbirdId === groupChannel.url)
    ) {
      setIsUserNotAllowed(true);
    } else {
      setIsUserNotAllowed(false);
    }

    setIsCheckingIfMember(false);
  }, [groupChannel, currentUserCommunities]);

  useEffect(() => {
    getCommunity({ variables: { sendbirdId: params.community } });
  }, [groupChannel, params.community]);

  useEffect(() => {
    if (!groupChannel) return;
    if (shouldRefetchPinnedFiles) {
      getPinnedFiles({ variables: { sendbirdId: groupChannel.url } });
    }
  }, [shouldRefetchPinnedFiles]);

  const [getUserCommunities] = useLazyQuery(GET_USER_COMMUNITIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      const reverse = [...res.user.communities].reverse();
      setCurrentUserCommunities(reverse);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  useEffect(() => {
    if (params.community) {
      getCommunityCollections({
        variables: {
          search: {
            sendbirdId: params.community,
            sortingCriteria: SORTING_MOST_RECENT,
            size: DEFAULT_SLIDER_PAGE_SIZE,
          },
        },
      });
    }

    getUserCommunities({
      variables: {
        email: currentUserEmail,
      },
    });
  }, []);

  const handleViewAllCollections = () => {
    history.push(`/portal/community-collection/${community?.sendbirdId}`);
  };

  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);

  const handleCopyLink = () => {
    if (shareCollectionUrl) {
      navigator.clipboard.writeText(shareCollectionUrl);
      displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
    }
  };

  const hadleLinkCollection = (collection: ICollection) => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
    setCollectionToLinkId(collection.publicId);
    setCollectionName(collection.name);
  };

  const handleUnlinkCollection = (collection: ICollection) => {
    unlinkCollectionFromCommunity({
      variables: {
        inputType: {
          sendbirdId: community?.sendbirdId,
          collectionPublicId: collection.publicId,
        },
      },
    });
  };

  const handleShareCollection = (collection: ICollection) => {
    setShareModalOpen(true);
    setShareCollectionUrl(
      `${window.location.origin}/portal/collection/${collection.publicId}`
    );
  };

  useEffect(() => {
    if (
      !isLoadingCommunity &&
      !isLoadingCommunityPreview &&
      !isLoadingCollections &&
      !isLoadingCollectionsPreview &&
      community?.defaultCollection &&
      recentCommunityCollections
    ) {
      const collectionsUpdated = [
        ...recentCommunityCollections
          .filter(
            (collections) =>
              collections.publicId !== community?.defaultCollection?.publicId
          )
          .slice(0, 3),
        community.defaultCollection,
      ].slice(0, 3);
      setCollections(collectionsUpdated);
    }
  }, [
    isLoadingCommunity,
    isLoadingCommunityPreview,
    isLoadingCollections,
    isLoadingCollectionsPreview,
    community?.defaultCollection,
    recentCommunityCollections,
  ]);

  const menuDots = (collection: ICollection) => {
    if (isUserNotAllowed) {
      return [];
    }
    // disable menu for linked collections
    if (collection.collectionType === CollectionType.USER_COLLECTION) {
      if (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner) {
        return [
          {
            label: "unlinkCollection",
            action: () => handleUnlinkCollection(collection),
          } as IOption,
        ];
      } else {
        return [];
      }
    }

    return getMenuOptions(collection, currentUserEmail, {
      link: (collection) => hadleLinkCollection(collection),
      share: (collection) => handleShareCollection(collection),
    });
  };
  if (
    (groupChannel && isCheckingIfMember && !isUserNotAllowed) ||
    isLoadingCommunity ||
    isLoadingCommunityPreview ||
    isLoadingCollections ||
    isLoadingCollectionsPreview
  )
    return <Loading disableBoxShadow />;

  const handleJoinPublicCommunity = (event: React.MouseEvent) => {
    setJoinPublicLoading(true);

    joinPublicCommunity({
      variables: { sendbirdId: params.community },
    })
      .then(() => {
        // Refresh the page after user has joined the community so he can have all the features as a member instead of remaining in the preview mode
        history.go(0);
      })
      .catch(() => {
        displayServerError();
      })
      .finally(() => setJoinPublicLoading(false));
  };

  const requestJoinPrivateCommunity = () => {
    setIsRequestingToJoin(true);
    joinPrivateCommunity({
      variables: {
        sendbirdId: params.community,
      },
    })
      .then(() => {
        setIsJoinPrivateCommunityRequested(true);
      })
      .catch(() => {
        displayServerError();
      })
      .finally(() => setIsRequestingToJoin(false));
  };

  const handleJoinPrivateCommunity = (event: React.MouseEvent) => {
    event.stopPropagation();

    requestJoinPrivateCommunity();
  };

  const renderJoinPrivateCommunityButton = () => {
    if (isJoinPrivateCommunityRequested) {
      return (
        <Button
          wrapperClassNames={styles.joinGroupButton}
          aria-label="Cancel Request Join Private Community"
          disabled={isJoinPrivateCommunityRequested}
          title={t("exploreCommunities.requested")}
        />
      );
    }

    return (
      <Button
        wrapperClassNames={styles.joinGroupButton}
        aria-label="Request Join Private Community"
        onClick={handleJoinPrivateCommunity}
        loading={isRequestingToJoin}
        title={t("exploreCommunities.requestAccess")}
      />
    );
  };

  if (
    !groupChannel &&
    !isLoadingCommunity &&
    !isLoadingCommunityPreview &&
    !community?.sendbirdId
  )
    return <PageNotFound />;

  return (
    <div className={styles.pageWrapper}>
      <div
        className={styles.bannerSection}
        style={{
          backgroundImage: `url(${community?.communityBannerUrl || DefaultBannerImage})`,
        }}
        e2e-test-id="community-page-banner-section"
      ></div>
      <div className={styles.mainWrapper}>
        <div className={styles.leftWrapper} e2e-test-id="community-page-left-section">
          <BackButton
            wrapperClassName={styles.backButton}
            path="/portal/community"
            label={t("community.backButton")}
          />
          <div className={styles.infoContainer}>
            <div className={styles.titleContainer}>
              <div
                className={styles.communityImage}
                style={{ backgroundImage: `url(${community?.communityImageUrl})` }}
                e2e-test-id="community-profile-image"
              />
              <p className={styles.communityName} e2e-test-id="community-name">
                {community?.name}
              </p>
              <TooltipComponent
                tooltipType="secondary"
                placement="top"
                title={t(communityType)}
                childToWrap={
                  <div className={styles.communityIconContainer}>
                    <img src={communityIconType} e2e-test-id="community-type-icon" />
                  </div>
                }
              />
              {!isUserNotAllowed ? (
                <>
                  {userRole && (
                    <span className={styles.roleTag} e2e-test-id="community-role-tag">
                      {t(`community.userRole.${userRole}`)}
                    </span>
                  )}
                  <Menu options={manageOptions}>
                    <div
                      className={styles.manageButton}
                      e2e-test-id="manage-community-menu-link"
                    >
                      <MoreIcon />
                      {t("collection.manage")}
                    </div>
                  </Menu>
                </>
              ) : community?.communityType === CommunityType.open ? (
                <Button
                  wrapperClassNames={styles.joinGroupButton}
                  loading={joinPublicLoading}
                  aria-label="Join Public Community"
                  onClick={handleJoinPublicCommunity}
                  title={t("exploreCommunities.joinGroup")}
                />
              ) : (
                renderJoinPrivateCommunityButton()
              )}
            </div>
            {community?.description && (
              <LinkedText
                text={community.description}
                hasReadMore
                isReadMoreStyled
                e2eTestId="community-description"
              />
            )}
            {community?.goals && (
              <Collapse
                expandIconPosition="start"
                className={styles.accordionHeader}
                ghost
                accordion
              >
                <Panel key="1" header={t("community.goals")}>
                  <p className={styles.regularText} e2e-test-id="community-goals-text">
                    {community?.goals}
                  </p>
                </Panel>
              </Collapse>
            )}
            {community?.rules && (
              <Collapse
                expandIconPosition="start"
                className={styles.accordionHeader}
                ghost
                accordion
              >
                <Panel key="1" header={t("community.rules")}>
                  <p className={styles.regularText} e2e-test-id="community-rules-text">
                    {community?.rules}
                  </p>
                </Panel>
              </Collapse>
            )}
          </div>
          <CommunityMembersSection
            isUserNotAllowed={isUserNotAllowed}
            isPublicCommunity={community?.communityType === CommunityType.open}
            isCurrentUserCommunityCreator={isCurrentUserCommunityCreator}
          />
          <PinnedFilesSection
            isUserNotAllowed={isUserNotAllowed}
            filesLoading={isLoadingCommunity || isLoadingCommunityPreview}
            userRole={userRole}
            pinnedFiles={pinnedFiles}
            filesCount={pinnedFiles?.length}
            refetchPinnedFiles={() => setShouldRefetchPinnedFiles(true)}
          />
          <div className={styles.collectionsSpaceContainer}>
            <div className={styles.communitySpaceTitleContainer}>
              <h2 className={styles.communitySpaceTitle}>
                {t("community.communityCollections")}
              </h2>
              {!isUserNotAllowed && (
                <Button
                  buttonType="link"
                  title={t("community.viewAll")}
                  onClick={handleViewAllCollections}
                  wrapperClassNames={styles.boldButtonText}
                  e2eTestId="view-in-hive-drive-link"
                />
              )}
            </div>
            <div
              className={styles.collectionsContainer}
              style={!isUserNotAllowed ? {} : { pointerEvents: "none" }}
            >
              {collections.map((collection) => (
                <CollectionsCard
                  key={collection.publicId}
                  title={collection.name}
                  description={collection.description}
                  collaboratorsCount={collection.collaboratorsCount}
                  resourcesCount={collection.articlesCount}
                  imageName={collection.imageName}
                  imageDirectory={collection.imageDirectory}
                  updateTime={collection.updateTime}
                  publicId={collection.publicId}
                  collectionCreator={collection.collectionCreator}
                  isPublic={collection.isPublic}
                  isGlobalCurated={collection.isGlobalCurated}
                  collectionType={collection.collectionType}
                  isLinked={collection.collectionType === CollectionType.USER_COLLECTION}
                  menuOptions={menuDots(collection)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper} e2e-test-id="community-page-right-section">
          <div className={styles.discussionsContainer}>
            <div className={styles.discussionsHeader}>
              <h4 className={styles.recentDiscussions}>
                {t("community.recentDiscussions")}
              </h4>
            </div>
            {recentDiscussions?.map((discussion) => (
              <Channel
                key={discussion}
                name={discussion}
                groupChannel={groupChannel}
                disabled={isUserNotAllowed}
                isRecentDiscussion
              />
            ))}
            <UpcomingCommunityEvents
              isUserNotAllowed={isUserNotAllowed}
              events={upcomingEventsData}
            />
            <LinksList isUserNotAllowed={isUserNotAllowed} linksData={communityLinks} />
          </div>
        </div>
      </div>
      {openInviteModal && (
        <InviteToCommunityModal
          community={community}
          open={openInviteModal}
          close={() => {
            setOpenInviteModal(false);
          }}
        />
      )}
      {isOpenDeleteCommunityModal && (
        <DangerModal
          title={t("community.drawer.communitiesActions.deleteCommunityTitle", {
            title: community?.name,
          })}
          subTitle={t("community.drawer.communitiesActions.deleteCommunitySubTitle")}
          isOpen={isOpenDeleteCommunityModal}
          onClose={() => setOpenDeleteCommunityModal(false)}
          onSubmit={() => handleDeleteCommunity()}
          image={<img src={DangerIcon} />}
          closeButton={t("community.drawer.communitiesActions.keepCommunity")}
          ctaButton={t("community.drawer.communitiesActions.deleteCommunity")}
          e2eTestId="delete-community-confirmation-modal"
        />
      )}
      {isLinkCollectionToCommuniyModalOpen && (
        <LinkCollectionToCommunityModal
          isOpen={isLinkCollectionToCommuniyModalOpen}
          collectionId={collectionToLinkId}
          collectionName={collectionName}
          targetCommunityId={targetCommunityId}
          onClose={handleCloseLinkCollectionToCommunityModal}
          onSetTargetCommunityId={handleSetTargetCommunityId}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        close={() => setShareModalOpen(false)}
        customUrl={shareCollectionUrl}
        copyLink={handleCopyLink}
        share={shareSuccessMessage}
      />
    </div>
  );
};

export default CommunityHomepage;
