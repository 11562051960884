import { useEffect, useState } from "react";

import { GroupChannel, Member } from "sendbird";
import { SendBirdHelper } from "../SendBirdHelper";

export const useGetMembers = (groupChannel?: GroupChannel) => {
  const [members, setMembers] = useState<Array<Member>>([]);

  useEffect(() => {
    if (!groupChannel) return;

    const initializeMembers = async () => {
      const members = await SendBirdHelper.getMembers(groupChannel);
      setMembers(members);
    };

    initializeMembers();
  }, [groupChannel]);

  return members;
};
