import { useMemo, useState } from "react";

import { Emoji } from "sendbird";

import Popover from "@components/Popover/Popover";

import styles from "./Reaction.module.scss";

interface IReactionProps {
  reaction: {
    emoji: Emoji;
    nickNames: Array<string | undefined>;
    userIds: Array<string>;
    isMyReaction?: boolean;
  };
  onClick: () => void;
}

export const Reaction = ({
  reaction: { emoji, nickNames, isMyReaction, userIds },
  onClick,
}: IReactionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const menu = useMemo(
    () => (
      <div className={styles.usersContainer}>
        {nickNames.map((nickName) => (
          <span key={nickName}>{nickName}</span>
        ))}
      </div>
    ),
    [nickNames]
  );

  return (
    <Popover
      overlayStyle={{ padding: 0 }}
      align={{ offset: [0, 0] }}
      showArrow={false}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      trigger="hover"
      childToWrap={
        <div
          className={`${styles.reaction} ${isMyReaction ? styles.myReaction : ""}`}
          onClick={onClick}
        >
          <img src={emoji.url} className={styles.image} />
          <span className={styles.number}>{userIds.length}</span>
        </div>
      }
      placement="top"
      menu={menu}
      menuState={isOpen}
      setMenuState={setIsOpen}
    />
  );
};
