import { Col, Row } from "antd";
import React from "react";
import styles from "./CollectionHeader.module.scss";

interface HeaderProps {
  newCollection?: React.ReactNode;
  goTo?: React.ReactNode;
  allCollections?: React.ReactNode;
  createdByMe?: React.ReactNode;
  sharedWithMe?: React.ReactNode;
  sort?: React.ReactNode;
}

const CollectionHeader = ({
  newCollection,
  goTo,
  allCollections,
  createdByMe,
  sharedWithMe,
  sort,
}: HeaderProps) => {
  return (
    <>
      <Row
        className={styles.collectionHeaderContainer}
        e2e-test-id="my-collections-header-container"
      >
        <Col span={10}>
          <Row>
            {newCollection && <>{newCollection}</>}
            {goTo && <>{goTo}</>}
          </Row>
        </Col>
        <Col span={14}>
          <Row className={styles.rightSideRowContainer} justify="end">
            {allCollections && <>{allCollections}</>}
            {createdByMe && <>{createdByMe}</>}
            {sharedWithMe && <>{sharedWithMe}</>}
            {sort && (
              <div
                className={styles.sortSelect}
                e2e-test-id="collections-sorting-dropdown"
              >
                {sort}
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CollectionHeader;
