import { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Popover from "@components/Popover/Popover";
import { BlockUserModal } from "@modules/ChatPage/ChatActions/BlockUserModal/BlockUserModal";
import ReportUserModal from "@modules/ChatPage/ChatActions/ReportUserModal/ReportUserModal";
import { DeleteUserModal } from "./DeleteUserModal/DeleteUserModal";

import { IUser } from "types/user";

import { ChatAction } from "@modules/ChatPage/ChatActions/ChatAction/ChatAction";

import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";

import { Action } from "./enum";

import { ReactComponent as InfoLogo } from "@images/moreInfo.svg";

import styles from "../CommunityOptions/CommunityOptions.module.scss";
import { isDocumentRtl } from "@utils/checkDocumentDir";

interface IChatActionsProps {
  sendBirdGroupChannel: IUseSendBirdGroupChannel;
  selectedUser: IUser;
  sendBirdId: string;
}

export const ChatActions = ({
  selectedUser,
  sendBirdGroupChannel: { actions, helpers },
  sendBirdId,
}: IChatActionsProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [action, setAction] = useState<Action | null>();

  const handleOpenModal = (selectedAction: Action) => {
    setIsModalOpen(true);
    setIsDropdownOpen(false);
    setAction(selectedAction);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const chatActions = [
    {
      isAccessible: true,
      ariaLabel: "reportUserOption",
      text: t("reportUser.title"),
      onOptionClick: () => handleOpenModal(Action.REPORT),
      children: (
        <ReportUserModal
          open={isModalOpen && action === Action.REPORT}
          close={handleCloseModal}
          publicId={selectedUser.publicId}
        />
      ),
    },
    {
      isAccessible: true,
      ariaLabel: "blockUserOption",
      text: t("blockUser.type"),
      onOptionClick: () => handleOpenModal(Action.BLOCK),
      children: (
        <BlockUserModal
          isOpen={isModalOpen && action === Action.BLOCK}
          onClose={handleCloseModal}
          onSubmit={() => actions.blockUser(sendBirdId)}
          user={selectedUser}
        />
      ),
    },
    {
      isAccessible: true,
      ariaLabel: "deleteUserOption",
      text: t("deleteUser.type"),
      onOptionClick: () => handleOpenModal(Action.DELETE),
      children: (
        <DeleteUserModal
          isOpen={isModalOpen && action === Action.DELETE}
          onClose={handleCloseModal}
          onSubmit={() => helpers.deleteChannel()}
          user={selectedUser}
        />
      ),
    },
  ];

  const renderActions = () => {
    return chatActions.map((action, index) => {
      if (action.isAccessible) {
        return <ChatAction key={index} {...action} />;
      }

      return <></>;
    });
  };

  return (
    <Popover
      menuState={isDropdownOpen}
      placement={isDocumentRtl() ? "bottomLeft" : "bottom"}
      setMenuState={setIsDropdownOpen}
      menu={renderActions()}
      className={styles.popoverContainer}
      childToWrap={
        <Button
          aria-label="dropDown"
          title=""
          buttonIcon={<InfoLogo />}
          buttonShape="circle"
          buttonSize="middle"
          onClick={() => setIsDropdownOpen(true)}
          wrapperClassNames={styles.moreMenu}
        />
      }
    />
  );
};
