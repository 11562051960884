import { gql } from "@apollo/client";

export const GET_FILE_BY_PUBLIC_ID = gql`
  query GetFolder($publicId: ID!) {
    fileInfo(publicId: $publicId) {
      publicId
      name
      originalName
      creator {
        fullName
        email
      }
      interests {
        name
      }
      creationTime
      updateTime
      updatedBy {
        fullName
        email
      }
    }
  }
`;
