import dayjs from "dayjs";
import ArabicDate from "dayjs/locale/ar";
import englishDate from "dayjs/locale/en";
import spanishDate from "dayjs/locale/es";
import FrenchDate from "dayjs/locale/fr";
import koreanDate from "dayjs/locale/ko";
import RussianDate from "dayjs/locale/ru";
import ChineseDate from "dayjs/locale/zh";

import { optionsZone } from "../modules/Events/NewEvent/timeZones";

import { Language } from "@feature/language";

export const getLocalTimeZone = () => {
  return -new Date().getTimezoneOffset() / 60;
};

export const getDefaultTimeZone = () => {
  return optionsZone.filter((t) => t.keyTime === getLocalTimeZone() && t.label)[0];
};

export const formatDate = (
  eventDate: Date | string,
  language: Language,
  format?: string
) => {
  switch (language) {
    case Language.ARABIC:
      dayjs.locale(ArabicDate);
      break;
    case Language.SPANISH:
      dayjs.locale(spanishDate);
      break;
    case Language.KOREAN:
      dayjs.locale(koreanDate);
      break;
    case Language.FRENCH:
      dayjs.locale(FrenchDate);
      break;
    case Language.RUSSIAN:
      dayjs.locale(RussianDate);
      break;
    case Language.CHINESE:
      dayjs.locale(ChineseDate);
      break;
    default:
      dayjs.locale(englishDate);
  }

  return dayjs(eventDate).format(format || "DD MMM YYYY, h:mmA");
};
