/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel, Col, Row, Typography } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import palette from "./../../../scss/exports.module.scss";

import media from "css-in-js-media";
import { configuration } from "../../../modules/LandingPage/Data";
import GreenLine from "../../../modules/LandingPage/icons/green-line.svg";
import { Collapse } from "../DataDisplay";
import SliderDots from "../DataDisplay/SliderDots";
import CollapseFeature, { ColorType } from "../Layout/CollapseFeature";
import { DesktopView, MobileView } from "./Utils";

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  color: ${palette.white} !important;
  padding-bottom: 2rem;
  width: 100%;
  ${media("<desktop")} {
    padding-bottom: 1rem;
    text-align: center;
  }
`;

const StyledCol = styled(Col)`
  padding-left: 4rem;
  ${media("<desktop")} {
    padding: 0 30px;
    width: 100%;
  }
`;

const StyledBgCol = styled(Col)`
  position: absolute;
  right: 0;
  bottom: 0%;
`;

const StyledImageWrapper = styled(Col)`
  padding: 0 1rem 0 0;
`;

const StyledImg = styled.img`
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const ImgWrap = styled.div`
  padding-bottom: 100%;
  position: relative;
`;

const StyledRow = styled(Row)<{ $bgColor: ColorType }>`
  background: ${(props) => props.$bgColor};
  padding: 3rem 0rem;
  position: relative;
`;

const StyledLineIcon = styled.img`
  position: absolute;
  top: 0;
  left: 20%;
`;

const StyledWrapperImgCol = styled(Col)`
  padding-bottom: 6rem;
`;

const SectionThree = forwardRef((_props, ref: ForwardedRef<HTMLDivElement>) => {
  const [activeItem, setActiveItem] = useState<string>("1");
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [sliderPercentage, setSliderPercentage] = useState(0);

  const { sectionThree } = configuration;

  useEffect(() => {
    if (sliderPercentage < 100) {
      const timer = setTimeout(() => {
        setSliderPercentage(sliderPercentage + 10);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [sliderPercentage, currentSlideIndex]);

  const carouselRef = useRef<CarouselRef>(null);

  const handleClick = (slideIdx: number) => {
    if (carouselRef.current) {
      setSliderPercentage(0);
      setCurrentSlideIndex(slideIdx);
      carouselRef.current?.goTo(slideIdx);
    }
  };

  // Automated accordion
  useEffect(() => {
    const timer = setTimeout(() => {
      const newId =
        parseInt(activeItem) + 1 <= sectionThree?.items?.length
          ? parseInt(activeItem) + 1
          : 1;
      setActiveItem(String(newId));
    }, 15000);
    return () => clearTimeout(timer);
  }, [activeItem]);

  const currentItem = sectionThree?.items.find(
    (item) => item.id === (activeItem ? activeItem : "1")
  );

  return (
    <>
      <DesktopView ref={ref}>
        <CollapseFeature
          bgColor={ColorType.NewGreen}
          leftItem={
            <StyledCol>
              <StyledTitle level={2}>{sectionThree?.title}</StyledTitle>
              <Col span={22}>
                <Collapse
                  bgColor={ColorType.NewGreen}
                  items={sectionThree?.items}
                  callback={(text) =>
                    Array.isArray(text) ? setActiveItem(text[0]) : setActiveItem(text)
                  }
                  activeItem={activeItem}
                  collapseType={"sectionThree"}
                />
              </Col>
            </StyledCol>
          }
          rightItem={
            <StyledImageWrapper>
              {/* <StyledBgCol>
                <img
                  width={sectionThree?.image?.width}
                  height={sectionThree?.image?.height}
                  src={sectionThree?.image?.src}
                  alt={sectionThree?.title}
                />
              </StyledBgCol> */}
              <Col>
                {currentItem?.image?.src && (
                  <ImgWrap>
                    <StyledImg src={currentItem?.image?.src} alt={sectionThree?.title} />
                  </ImgWrap>
                )}
              </Col>
            </StyledImageWrapper>
          }
        />
      </DesktopView>

      {/* Mobile version */}
      <MobileView>
        <StyledRow $bgColor={ColorType.Green}>
          <StyledCol>
            <StyledTitle level={2}>{sectionThree?.title}</StyledTitle>
          </StyledCol>
          <Col span={24}>
            <Carousel dots={false} fade={true} ref={carouselRef}>
              {sectionThree?.items.map((item) => (
                <div key={item.id}>
                  <StyledWrapperImgCol>
                    <StyledLineIcon src={GreenLine} alt="Icon" />
                    <StyledBgCol style={{ width: "60%", bottom: "10%" }}>
                      <img
                        width={sectionThree?.image?.width}
                        height={sectionThree?.image?.height}
                        src={sectionThree?.image?.src}
                        alt={sectionThree?.title}
                      />
                    </StyledBgCol>
                    <Col style={{ padding: "0 30px" }}>
                      <ImgWrap>
                        <StyledImg src={item?.image?.src} alt={item?.label} />
                      </ImgWrap>
                    </Col>
                  </StyledWrapperImgCol>
                  <Col style={{ padding: "0 30px" }}>
                    <Title style={{ color: palette.white }} level={3}>
                      {item.label}
                    </Title>
                  </Col>
                  <Col style={{ padding: "0 30px" }}>
                    <Text style={{ color: palette.white }}>{item.text}</Text>
                  </Col>
                  {sectionThree?.items.length > 1 && (
                    <SliderDots
                      padding="3rem 30px 0 30px"
                      data={sectionThree?.items}
                      currentSlideIndex={currentSlideIndex}
                      trailColor={palette.white}
                      strokeColor={palette.lightBlue}
                      sliderPercentage={sliderPercentage}
                      handleClick={handleClick}
                    />
                  )}
                </div>
              ))}
            </Carousel>
          </Col>
        </StyledRow>
      </MobileView>
    </>
  );
});

export default SectionThree;
