import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { communityNotificationsSelector } from "../../store/communityNotification/reducer";
import { Message } from "./Message/Message";
import styles from "./Messages.module.scss";

interface IMessagesd {
  closePopover?: () => void;
}

export const Messages = ({ closePopover }: IMessagesd) => {
  const { t } = useTranslation();
  const history = useHistory();
  const communityMessages = useSelector(communityNotificationsSelector);
  const [messages, setMessages] = useState([...communityMessages]);

  useEffect(() => {
    setMessages(
      [...communityMessages].sort(
        (notification1, notification2) =>
          notification2.timestamp - notification1.timestamp
      )
    );
  }, [communityMessages]);

  const getMessageClass = () => {
    if (!messages.length) return `${styles.messages} ${styles.noMessages}`;

    return styles.messages;
  };

  const renderMessages = () => {
    if (!messages.length)
      return <h4 className={styles.text}>{t("requests.noMessagePresent")}</h4>;

    return messages.map((notification) => (
      <div aria-label="notification" key={notification.id} className={styles.message}>
        <Message {...notification} />
      </div>
    ));
  };

  return (
    <div className={getMessageClass()}>
      <div className={styles.titleWrapper}>
        <h4 aria-label="heading" className={styles.heading}>
          {t("requests.messages")}
        </h4>
        <p
          className={styles.action}
          onClick={() => {
            closePopover?.();
            history.push("/portal/chat-page");
          }}
        >
          {t("requests.goToAllMessages")}
        </p>
      </div>
      {renderMessages()}
    </div>
  );
};
