import { useEffect, useState } from "react";

import ArLogo from "@images/Logos/ar.png";
import ChLogo from "@images/Logos/ch.png";
import EnLogo from "@images/Logos/en.png";
import EsLogo from "@images/Logos/es.png";
import FrLogo from "@images/Logos/fr.png";
import RuLogo from "@images/Logos/ru.png";

import { DEFAULT_LANGUAGE } from "@constants/defaultLanguages";

export const useWhoLogo = (userDefaultLanguage?: string) => {
  const [logo, setLogo] = useState(EnLogo);

  useEffect(() => {
    if (userDefaultLanguage === DEFAULT_LANGUAGE.ar) setLogo(ArLogo);
    if (userDefaultLanguage === DEFAULT_LANGUAGE.ch) setLogo(ChLogo);
    if (userDefaultLanguage === DEFAULT_LANGUAGE.es) setLogo(EsLogo);
    if (userDefaultLanguage === DEFAULT_LANGUAGE.fr) setLogo(FrLogo);
    if (
      userDefaultLanguage === DEFAULT_LANGUAGE.en ||
      userDefaultLanguage === DEFAULT_LANGUAGE.ko
    )
      setLogo(EnLogo);
    if (userDefaultLanguage === DEFAULT_LANGUAGE.ru) setLogo(RuLogo);
  }, [userDefaultLanguage]);

  return { logo };
};
