import { gql } from "@apollo/client";

const GET_USER_INFO = gql`
  query userInfo($email: String!) {
    userInfo(email: $email) {
      publicId
      fullName
      email
      isActive
      bio
      isOnboarded
      canReceiveMessages
      userLinkedInLink
      problemUserWouldLikeToSolve
      userSecretSuperPower
      userDefinedInterests
      memberSince
      pronouns
      country {
        name
      }
      organization {
        name
        sector {
          name
        }
        userRole
      }
      interests {
        name
      }
      countries {
        name
      }
      globalInterestLocation
    }
  }
`;

export default GET_USER_INFO;
