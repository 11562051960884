import SideBar from "@components/SideBar/SideBar";
import BuildingProfile from "@modules/BuildingProfile/BuildingProfile";
import Interests from "@modules/Interests/Interests";
import InterestsAndSocialMedia from "@modules/InterestsAndSocialMedia/InterestsAndSocialMedia";
import Locations from "@modules/Locations/Locations";
import ProfileDetails from "@modules/Onboarding/ProfileDetails/ProfileDetails";
import PersonalDetails from "@modules/PersonalDetails/PersonalDetails";
import { Redirect, Route, Switch } from "react-router";
import { IUser } from "types/user";
import styles from "./Onboarding.module.scss";

interface IOnboardingProps {
  user?: IUser;
}

const Onboarding = ({ user }: IOnboardingProps) => {
  if (user?.isOnboarded) return <Redirect to="/portal/resources" />;

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <SideBar onboardingSteps={true} />
      </div>
      <div className={styles.detailsContainer}>
        <Switch>
          <Route path="/onboarding/interests">
            <Interests />
          </Route>
          <Route path="/onboarding/personal-details">
            <PersonalDetails user={user} />
          </Route>
          <Route path="/onboarding/profile-details">
            <ProfileDetails />
          </Route>
          <Route path="/onboarding/locations">
            <Locations />
          </Route>
          <Route path="/onboarding/interests-and-social-media">
            <InterestsAndSocialMedia />
          </Route>
          <Route path="/onboarding/building">
            <BuildingProfile />
          </Route>
          <Route path="/onboarding" exact>
            <Redirect to="/onboarding/personal-details" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Onboarding;
