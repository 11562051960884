import { useEffect } from "react";

import { useField } from "formik";

import Input, { IInputProps } from "@components/Input/Input";

import { ErrorBox } from "../ErrorBox/ErrorBox";

import formStyles from "../form.style.module.scss";

export const InputText = (props: IInputProps) => {
  const [field, meta, helpers] = useField({ name: props.name });

  useEffect(() => {
    return () => helpers.setValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Input wrapperClassNames={formStyles.inputWrapper} {...props} {...field} />
      <ErrorBox meta={meta} />
    </div>
  );
};
