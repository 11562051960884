import {
  MESSAGE_CATEGORY,
  NEW_MESSAGE_SEND_ACTION,
} from "@constants/googleAnalyticsConstants";

export const SENDBIRD = {
  MESSAGE_SUCCESSFULLY_SENT: {
    action: NEW_MESSAGE_SEND_ACTION,
    category: MESSAGE_CATEGORY,
  },
};
