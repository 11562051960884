type CommunityForm = {
  REPORT: "REPORT";
  BLOCK: "BLOCK";
};

export type CommunityFormEnum = keyof CommunityForm;

export const CommunityFormType: Record<CommunityFormEnum, CommunityFormEnum> = {
  BLOCK: "BLOCK",
  REPORT: "REPORT",
};
