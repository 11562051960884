import { ActionTypes, IAddNewFolder, IRemoveNewFolder } from "./actions";
import { ADD_NEW_FOLDER, CLEAR_NEW_FOLDERS, REMOVE_NEW_FOLDER } from "./constants";
import { NewFoldersState } from "./types";

const initialState: NewFoldersState = {
  folders: [],
};

const addNewFolder = ({ folders }: NewFoldersState, { payload }: IAddNewFolder) => [
  ...folders,
  payload,
];

const removeNewFolder = (
  { folders }: NewFoldersState,
  { payload: { folderName } }: IRemoveNewFolder
) => folders.filter((folder) => folder.name !== folderName);

const newFoldersReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case ADD_NEW_FOLDER:
      return {
        ...state,
        folders: addNewFolder(state, action),
      };
    case REMOVE_NEW_FOLDER:
      return {
        ...state,
        folders: removeNewFolder(state, action),
      };

    case CLEAR_NEW_FOLDERS:
      return {
        ...state,
        folders: [],
      };

    default:
      return state;
  }
};

export default newFoldersReducer;
