import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store/store";

import Popover from "@components/Popover/Popover";

import { useLazyQuery, useMutation } from "@apollo/client";

import { useSubscription } from "@customHooks/useSubscription";

import { MARK_NOTIFICATIONS_AS_READ } from "@graphql/notification/mutations";
import {
  GET_UNREAD_NOTIFICATIONS_COUNT,
  GET_USER_NOTIFICATIONS,
} from "@graphql/notification/queries";
import {
  INotificationsResponse,
  IUnreadNotificationsCountResponse,
} from "@graphql/notification/types";

import {
  setIsNotificationsPopUpVisible,
  setUserUnreadNotificationsCount,
} from "../../../../store/notifications/actions";

import { isDocumentRtl } from "@utils/checkDocumentDir";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { Subscription } from "../../../../customContext/subscriptions/enum";

import { Notifications } from "../Notifications/Notifications";

import NotificationIcon from "@images/notification.svg";

import styles from "@components/HeaderBar/HeaderBar.module.scss";

export const NotificationPopover = () => {
  const dispatch = useDispatch();
  const unreadNotificationsCount = useSelector<RootState, number>(
    (state) => state.notification.unreadNotificationsCount
  );
  const isNotificationsPopUpVisible = useSelector<RootState, boolean>(
    (state) => state.notification.isNotificationsPopUpVisible
  );

  const [getUnreadNotificationsCount] = useLazyQuery<IUnreadNotificationsCountResponse>(
    GET_UNREAD_NOTIFICATIONS_COUNT,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        dispatch(
          setUserUnreadNotificationsCount(data.user.unreadNotificationsCount || 0)
        );
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );
  useSubscription(Subscription.NEW_NOTIFICATION, getUnreadNotificationsCount);
  useSubscription(Subscription.FEEDBACK_ADDED, getUnreadNotificationsCount);

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);
  const [getNotifications, { data: notificationsData }] =
    useLazyQuery<INotificationsResponse>(GET_USER_NOTIFICATIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        // when notifications are called, the logic for updating the number of unread notifications follows
        const userNotifications = data.user.notifications.notifications.map(
          (notification) => notification.publicId
        );
        markNotificationsAsRead({
          variables: {
            notificationsPublicId: userNotifications,
          },
        });
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const getNumberOfUnreadNotifications = () => {
    if (unreadNotificationsCount > 10) return "10+";
    if (unreadNotificationsCount <= 0) return "";
    return unreadNotificationsCount;
  };

  return (
    <Popover
      className={styles.communityNotificationPopUp}
      menu={
        <Notifications
          notifications={notificationsData?.user.notifications.notifications}
        />
      }
      placement={isDocumentRtl() ? "bottomLeft" : "bottomRight"}
      menuState={isNotificationsPopUpVisible}
      onOpenChange={(isOpen) => {
        dispatch(setIsNotificationsPopUpVisible(isOpen));
        if (isOpen) {
          getNotifications({
            variables: {
              notificationSearch: {
                size: 5,
                skip: 0,
              },
            },
          });
        }
      }}
      childToWrap={
        <div className={styles.item}>
          {getNumberOfUnreadNotifications() ? (
            <div role="alert" aria-label="notification" className={styles.alert}>
              <span className={styles.notificationNumber}>
                {getNumberOfUnreadNotifications()}
              </span>
            </div>
          ) : (
            <></>
          )}
          <img
            src={NotificationIcon}
            className={styles.iconStyle}
            alt=""
            e2e-test-id="notifications-icon"
          />
        </div>
      }
    />
  );
};
