import styled from "styled-components";
import media from "css-in-js-media";

export const DesktopView = styled.div`
  ${media("<desktop")} {
    display: none;
  }
`;

export const MobileView = styled.div`
  ${media(">desktop")} {
    display: none;
  }
`;
