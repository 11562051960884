import { useTranslation } from "react-i18next";

import { InputSelect } from "@components/formik/InputSelect/InputSelect";
import { InputText } from "@components/formik/InputText/InputText";
import { Label } from "@components/formik/Label/Label";

import { useGetSectors } from "@feature/sector/service/useGetSectors/useGetSectors";

import { FormField, FormFields, IForm, SECTOR_NOT_LISTED } from "../validation";

import styles from "@components/formik/form.style.module.scss";

interface IFormProps {
  isLabelDisplayed?: boolean;
  isLoading?: boolean;
  values: IForm;
  formPrefix?: string;
}

export const Form = ({
  isLoading,
  values,
  formPrefix = "",
  isLabelDisplayed,
}: IFormProps) => {
  const { sectorOptions } = useGetSectors();
  const { t } = useTranslation();

  return (
    <div className={styles.column}>
      {isLabelDisplayed && <Label text={t("form.label.sector")} name="" isRequired />}
      <div className={styles.column}>
        <InputSelect
          label=""
          name={`${formPrefix}name`}
          placeholder={t("form.placeholder.selectOne")}
          options={sectorOptions}
          isDisabled={isLoading}
        />
        {values?.name === SECTOR_NOT_LISTED && (
          <InputText
            label={t("form.label.sectorName")}
            placeholder={t("form.label.sectorName")}
            name={`${formPrefix}customName`}
            disabled={isLoading}
            {...FormFields[FormField.CUSTOM_NAME]}
          />
        )}
      </div>
    </div>
  );
};
