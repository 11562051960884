import { gql } from "@apollo/client";

const GET_PROFILE_IMAGES = gql`
  query profileImages($profileImagesInput: ProfileImageRequestInputType) {
    profileImages(profileImagesInput: $profileImagesInput) {
      userPhotoName
      imageDirectory
      email
    }
  }
`;

export default GET_PROFILE_IMAGES;
