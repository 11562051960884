import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SlickSlider from "react-slick";

import PinnedFileCard from "@components/FileCard/PinnedFileCard/PinnedFileCard";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";

import { UNPIN_FILE_FROM_COMMUNITY } from "@graphql/community/mutations";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { useDownloadFile } from "@utils/files/useDownloadFile";
import { disableNext } from "@utils/helpers";

import { IOption } from "@components/Menu/types";
import { FileActionProps } from "types/article";
import { IFile } from "types/file";

import PreviwDocument from "@components/PreviewDocument/PreviewDocument";
import {
  IMAGE_CONTENT_TYPE,
  PDF,
  PDF_CONTENT_TYPE,
  PDF_EXTENSION,
  PDF_FOLDER_NAME,
} from "@constants/constants";
import { PreviewModal } from "@feature/file";
import { useGetLazyFileByPublicId } from "@feature/file/service/useGetLazyByPublicId/useGetLazyByPublicId";
import {
  notSupportedExtensionsForDocumentPreview,
  supportedOfficeExtensions,
} from "@utils/files/constants";
import styles from "./PinnedFilesSection.module.scss";
import { ModalType } from "./enum";

enum USER_ROLE {
  owner = "owner",
  admin = "admin",
  member = "member",
}

interface IPinnedFilesSection {
  isUserNotAllowed: boolean;
  filesLoading: boolean;
  filesCount: number;
  userRole: USER_ROLE | undefined;
  pinnedFiles: IFile[] | undefined;
  refetchPinnedFiles: () => void;
}

const PinnedFilesSection = ({
  isUserNotAllowed,
  filesLoading,
  filesCount,
  pinnedFiles,
  userRole,
  refetchPinnedFiles,
}: IPinnedFilesSection) => {
  const { t } = useTranslation();
  const filesChildRef = useRef<SlickSlider>(null);
  const [sliderPage, setSliderPage] = useState<number>(0);
  const [slidesToShow, setSlidesToShow] = useState<number>();
  const [file, setFile] = useState<Blob | MediaSource>();
  const [type, setType] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>();
  const { getFile, data: fileData } = useGetLazyFileByPublicId();

  const handleOpenModal = (modalType: ModalType) => {
    setIsModalOpen(true);
    setModalType(modalType);
  };

  const onCloseModal = () => setIsModalOpen(false);

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  const [unpinFileFromCommunity] = useMutation<string>(UNPIN_FILE_FROM_COMMUNITY, {
    onCompleted: () => {
      refetchPinnedFiles();
      displaySuccessNotification(t("collections.collectionsActions.successUnpinFile"));
    },
    onError: () => {
      displayNotSuccessNotification();
    },
  });

  const downloadFile = useDownloadFile();

  const handlePreviousFiles = () => {
    filesChildRef.current?.slickPrev();
    setSliderPage(sliderPage - 1);
  };

  const handleNextFiles = () => {
    filesChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
  };

  const disableSliderNext = () => {
    return (
      filesLoading || disableNext(sliderPage, filesCount, 6) || pinnedFiles!.length < 7
    );
  };

  const disableSliderPrevious = () => {
    return filesLoading || sliderPage === 0 || pinnedFiles!.length < 7;
  };

  const handleUnpinFileFromCommunity = (filePublicId: string | undefined) => {
    unpinFileFromCommunity({
      variables: {
        filePublicId,
      },
    });
  };

  const handleFilePreview = ({ originalFileName, fileDirectory }: FileActionProps) => {
    const file = originalFileName;
    const directory = fileDirectory;
    const fileExt = originalFileName!.split(".").pop();
    let convertFile = false;
    if (supportedOfficeExtensions.includes("." + fileExt!) && fileExt !== PDF) {
      fileDirectory = fileDirectory + PDF_FOLDER_NAME;
      originalFileName = originalFileName!.replace("." + fileExt, PDF_EXTENSION);
      convertFile = true;
      setType(PDF_CONTENT_TYPE);
    } else {
      convertFile = false;
      setType(IMAGE_CONTENT_TYPE);
    }
    downloadFile(originalFileName!, fileDirectory!, false).then((res) => {
      if (res !== null) {
        if (!notSupportedExtensionsForDocumentPreview.includes("." + fileExt!)) {
          setFile(res);
          handleOpenModal(ModalType.PREVIEW_FILE);
        } else {
          const message = t(
            "collections.collectionsActions.formatNotSupportedForPreview"
          );
          displayNotSuccessNotification(message, message);
        }
      } else {
        const message = t("collections.collectionsActions.documentNotReady");
        displayNotSuccessNotification(message, message);
        const convertToPdfRequest = {
          fileName: file,
          directoryName: directory,
        };
        if (convertFile) {
          try {
            ConvertFileToPdf({
              variables: { convertToPdfRequest },
            });
          } catch (error) {
            displayNotSuccessNotification();
          }
        }
      }
    });
  };

  const fileMenuDots = ({
    publicId,
    fileName,
    fileDirectory,
    filePublicId,
    originalFileName,
  }: FileActionProps) => {
    let options: IOption[] = [
      {
        label: "download",
        action: () => {
          downloadFile(originalFileName!, fileDirectory!);
        },
      },
      {
        label: "preview",
        action: () => handleFilePreview({ originalFileName, fileDirectory, publicId }),
      },
      {
        label: "moreInfo",
        action: () => {
          getFile({
            fetchPolicy: "no-cache",
            variables: { publicId },
            onCompleted: () => handleOpenModal(ModalType.MORE_INFO_FILE),
          });
        },
      },
    ];

    if (userRole !== USER_ROLE.member) {
      options = [
        ...options,
        {
          label: "unpin",
          action: () => handleUnpinFileFromCommunity(filePublicId),
        },
      ];
    }

    return options;
  };

  useEffect(() => {
    if (!pinnedFiles) return;
    if (pinnedFiles?.length < 6) {
      setSlidesToShow(5);
    } else {
      setSlidesToShow(6);
    }
  }, [pinnedFiles]);

  return (
    <div className={styles.sectionWrapper}>
      {/* <p className={styles.title}>{t("community.pinnedSection.title")}</p> */}
      {pinnedFiles && pinnedFiles.length > 0 ? (
        <>
          <div
            className={styles.filesButtonContainer}
            data-testid="shift-buttons-container"
          >
            <p className={styles.title}>{t("community.pinnedSection.title")}</p>
            <ShiftButtons
              onForwardClick={handleNextFiles}
              onBackClick={handlePreviousFiles}
              disabledBack={disableSliderPrevious()}
              disabledForward={disableSliderNext()}
              hideSeeAllButton
            />
          </div>
          <Slider
            slidesToShow={slidesToShow}
            forwardedRef={filesChildRef}
            items={pinnedFiles?.map((file) => (
              <PinnedFileCard
                key={file.publicId}
                file={file}
                menuOptions={
                  isUserNotAllowed
                    ? []
                    : fileMenuDots({
                        publicId: file.publicId,
                        fileName: file.name,
                        fileDirectory: file.directory,
                        filePublicId: file.publicId,
                        originalFileName: file.originalName,
                      })
                }
              />
            ))}
          />
          <PreviwDocument
            open={isModalOpen && modalType === ModalType.PREVIEW_FILE}
            close={onCloseModal}
            file={file!}
            type={type!}
          />
          <PreviewModal
            isOpen={isModalOpen && modalType === ModalType.MORE_INFO_FILE}
            onClose={onCloseModal}
            file={fileData?.fileInfo}
          />
        </>
      ) : (
        <>
          <p className={styles.title}>{t("community.pinnedSection.title")}</p>
          <p className={styles.infoMessage}>{t("community.pinnedSection.noFiles")}</p>
        </>
      )}
    </div>
  );
};

export default PinnedFilesSection;
