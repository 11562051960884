import styles from "./TextAsideBanner.module.scss";

import WhoBlack from "@images/hero-who-black.png";

interface ITextAsideBannerProps {
  title: string;
  imgAlt: string;
  imgUrl: string;
}

const TextAsideBanner = ({ title, imgAlt, imgUrl }: ITextAsideBannerProps) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={WhoBlack} />
      </div>
      <div className={styles.textWrapper}>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <div className={styles.imgWrapper}>
        <img className={styles.img} src={imgUrl} alt={imgAlt} />
      </div>
    </div>
  );
};

export default TextAsideBanner;
