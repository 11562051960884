import { FormField } from "./enum";

export const SECTOR_NOT_LISTED = "Other sectors not listed above";

export const FormFields = {
  [FormField.NAME]: {
    isRequired: true,
  },
  [FormField.CUSTOM_NAME]: {
    maxLength: 60,
    isRequired: true,
  },
};
