/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DropdownIndicator } from "@images/dropdownIndicator.svg";
import styles from "./SortDropdown.module.scss";

import Button from "@components/Button/Button";
import Popover from "@components/Popover/Popover";
import { useTranslation } from "react-i18next";
import { sortArticles } from "../../store/portal/actions";
import { RootState } from "../../store/store";

const SortDropdown = () => {
  const { t } = useTranslation();

  const dropDownOptions = ["sortDropdown.mostRelevant", "sortDropdown.mostRecent"];

  const menuItems = dropDownOptions.map((option) => ({
    value: t(option),
    label: t(option),
  }));

  const [menuState, setMenuState] = useState(false);
  const dispatch = useDispatch();
  const sortBy = useSelector<RootState, string>((state) => state.portal.articles.sortBy);

  useEffect(() => {
    selectItem(sortBy);
  }, [sortBy]);

  const selectItem = (menuItem: string) => {
    setMenuState(false);
    dispatch(sortArticles(menuItem));
  };

  const menu = (
    <div className={styles.menuItemsContainer}>
      {menuItems.map((menuItem) => {
        return (
          <div className={styles.menuItemWrapper} key={menuItem.value}>
            <div
              className={styles.menuItem}
              role="menuitem"
              onClick={() => selectItem(menuItem.label)}
            >
              {menuItem.value}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.sortByContainer}>
      <Popover
        menu={menu}
        placement="bottom"
        menuState={menuState}
        setMenuState={(state) => setMenuState(state)}
        childToWrap={
          <Button
            aria-label="popover"
            title={`${t("sortDropdown.title")} ${sortBy}`}
            wrapperClassNames={styles.buttonWrapper}
            buttonIcon={
              <DropdownIndicator
                style={{
                  marginRight: 5,
                }}
              />
            }
          />
        }
      />
    </div>
  );
};

export default SortDropdown;
