import { useLazyQuery } from "@apollo/client";
import { GET_FILE_BY_PUBLIC_ID } from "./graphQL";
import { IGetFileRequest, IGetFileResponse } from "./type";

export const useGetLazyFileByPublicId = () => {
  const [getFile, { data, loading, error }] = useLazyQuery<
    IGetFileResponse,
    IGetFileRequest
  >(GET_FILE_BY_PUBLIC_ID);

  return { getFile, data, loading, error };
};
