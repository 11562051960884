import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { options } from "../options";

export const useGetLanguages = () => {
  const { t } = useTranslation();

  const languageOptions = useMemo(() => {
    return options?.map(({ value, label }) => ({
      value,
      label: t(label),
    }));
  }, [t]);

  return { languageOptions };
};
