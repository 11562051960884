import Button from "@components/Button/Button";
import CommmunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ICommunity } from "types/community";
import { RootState } from "../../store/RootState";
import styles from "./CommunitiesGrid.module.scss";

interface ICommunitiesProps {
  communities: Array<ICommunity>;
  loadMore: () => void;
  communitiesLoading: boolean;
  communitiesLoadingMore: boolean;
  communitiesCount: number;
  searchValue: string;
  gridAdditionalStyles?: string;
}

const COMMUNITIES = "communities";

const CommunitiesGrid = ({
  communities,
  loadMore,
  communitiesLoading,
  communitiesLoadingMore,
  communitiesCount,
  searchValue,
  gridAdditionalStyles,
}: ICommunitiesProps) => {
  const { t } = useTranslation();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  return (
    <div
      aria-label="communities-grid"
      className={`${styles.communitiesGridContainer} ${
        gridAdditionalStyles ? gridAdditionalStyles : ""
      }`}
    >
      {searchValue && (
        <h3 aria-label="search-header" className={styles.sectionTitle}>{`${t(
          "resources.communitiesTitle"
        )} ${t("resources.relatedTo")} ${searchValue}`}</h3>
      )}
      {communitiesLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading borderless disableBoxShadow />
        </div>
      ) : (
        <div className={styles.communitiesGridWrapper}>
          {communities.map((community, index) => (
            <CommmunityCard
              aria-label="community-card"
              community={community}
              key={index}
              loggedUser={currentUserEmail}
            />
          ))}
        </div>
      )}
      {communitiesCount > communities.length && (
        <Button
          aria-label="loadMoreBtn"
          title={t("resources.viewMore", {
            resources: `${COMMUNITIES}`,
          })}
          wrapperClassNames={styles.loadMoreButton}
          onClick={loadMore}
          loading={communitiesLoadingMore}
          disabled={communitiesLoadingMore}
        />
      )}
    </div>
  );
};

export default CommunitiesGrid;
