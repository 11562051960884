import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import PageHeading from "@components/PageHeading/PageHeading";

import { addPersonalDetails } from "../../store/onboarding/actions";
import { PersonalDetails as PersonalDetailsType } from "../../store/onboarding/types";
import { RootState } from "../../store/store";

import { CreateUpdateForm } from "./CreateUpdateForm/CreateUpdateForm";

import { IUser } from "types/user";

import { useMemo } from "react";
import styles from "./PersonaDetails.module.scss";

interface IPersonalDetailsProps {
  user?: IUser;
}

const PersonalDetails = ({ user }: IPersonalDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const personalDetails = useSelector<RootState, PersonalDetailsType>(
    (state) => state.onboarding.personalDetails
  );

  const personalDetailsInitialState: Partial<PersonalDetailsType> = useMemo(
    () => ({
      ...personalDetails,
      fullName: personalDetails.fullName ? personalDetails.fullName : user?.fullName,
      email: personalDetails.email ? personalDetails.email : user?.email,
    }),
    [personalDetails, user]
  );

  if (!user) return <></>;

  return (
    <>
      <div className={styles.container}>
        <PageHeading
          heading={t("personalDetails.pageTitle")}
          subheading={t("personalDetails.subheading")}
        />
        <CreateUpdateForm
          personalDetails={personalDetailsInitialState}
          onSubmit={(formData) => {
            dispatch(
              addPersonalDetails({
                ...formData,
                canReceiveMessages: personalDetails.canReceiveMessages,
              })
            );
            history.push("/onboarding/profile-details");
          }}
        />
      </div>
    </>
  );
};

export default PersonalDetails;
