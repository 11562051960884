import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { clearSearchTerm, saveSearchTerm } from "../../store/events/actions";
import { RootState } from "../../store/store";

import { COLLECTION } from "@utils/routes/collection";

import CollectionsBackButton from "@components/CollectionsBackButton/CollectionsBackButton";
import SearchInput from "@components/SearchInput/SearchInput";
import { ITab, Tabs } from "@components/navigation/Tabs";

import { SearchedCollections } from "./SearchedCollections/SearchedCollections";
// import Activity from "./Activity/Activity";
import CollectionsContainer from "./CollectionsContainer/CollectionsContainer";
import Explore from "./Explore/Explore";

import { ReactComponent as GoToIcon } from "@images/account_tree.svg";
import MagnifyGlassIcon from "@images/search_grey.svg";

import { useLazyQuery } from "@apollo/client";
import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import { CollectionsTreeNode } from "@components/CollectionsTree/types";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";
import {
  SORTING_A_Z,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
} from "@constants/constants";
import { GET_ALL_COLLECTIONS } from "@graphql/collection/queries";
import { ICollection, IUserCollectionResponse } from "@graphql/collection/type";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { setBreadcrumb } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";
import styles from "./Collections.module.scss";

const Collections = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [searchCollectionQuery, setSearchCollectionQuery] = useState<string>("");
  const [renderKey, setRenderKey] = useState<number>(0);

  const [userCollectionsForGoToModal, setUserCollectionsForGoToModal] =
    useState<Array<ICollection>>();
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);

  const handleGoTo = () => {
    setIsCollectionsModalOpen(true);
  };

  const handleCollectionsTreeModalCanceled = () => {
    setIsCollectionsModalOpen(false);
  };

  const [getCollectionsForGoToModal] = useLazyQuery<IUserCollectionResponse>(
    GET_ALL_COLLECTIONS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: IUserCollectionResponse) => {
        const collections = [...data.user.allCollections];
        const alphabeticallySortedCollections = collections.sort(
          sortingCollectionsObj[SORTING_A_Z].sort
        );
        setUserCollectionsForGoToModal(alphabeticallySortedCollections);
      },
      onError: (error) => displayServerError(error),
    }
  );

  const handleCollectionsTreeModalSelection = (
    path: CollectionsTreeNode[] | undefined
  ) => {
    if (path && path.length) {
      const collectionPublicId = path[0].key;
      const collectionUrl = `/portal/collection/${collectionPublicId}`;
      const crumbs: IBreadcrumb[] = path.map((pathElement, index) => {
        const folderPublicId = pathElement.key ?? "";
        const destinationPath =
          index === 0 ? collectionUrl : `${collectionUrl}/${folderPublicId}`;
        return {
          id: pathElement.key as string,
          name: pathElement.title as string,
          path: destinationPath,
        };
      });

      const destination = crumbs.pop()?.path ?? "";
      dispatch(setBreadcrumb(crumbs));
      history.push(destination);
    }
    setIsCollectionsModalOpen(false);
  };

  const searchTerm = useSelector<RootState, string>(
    (state) => state.searchTerm.searchTerm
  );

  useEffect(() => {
    getCollectionsForGoToModal();

    localStorage.setItem("editCollection", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLLECTIONS_TABS: Array<ITab> = [
    {
      id: "1",
      name: t("collections.myCollections"),
      path: COLLECTION.MY_COLLECTIONS,
      content: <CollectionsContainer />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
    {
      id: "2",
      name: t("collections.explore"),
      path: COLLECTION.EXPLORE,
      content: <Explore />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
  ];

  const handleSubmitSearchCollectionQuery = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchCollectionQuery.trim()) return;
    dispatch(saveSearchTerm(searchCollectionQuery.trim()));
    setSearchCollectionQuery("");
    setRenderKey(renderKey + 1);
  };

  const handleBackClick = () => {
    setSearchCollectionQuery("");
    dispatch(clearSearchTerm());
  };

  const eventsSearchField = () =>
    // show search collections on both explore and my collections page
    (location.pathname.includes("/explore") ||
      location.pathname.includes("/my-collections") ||
      location.pathname.includes("/collections")) && (
      <div className={styles.actionsWrapper}>
        <ButtonUpdated
          buttonSize="large"
          e2eTestId="goto-button"
          title={t("collections.buttons.goToCollection")}
          aria-label={"go-to"}
          buttonIcon={<GoToIcon />}
          onClick={handleGoTo}
          customButtonType="ternary"
        />
        <form onSubmit={handleSubmitSearchCollectionQuery}>
          <SearchInput
            name="searchCollection"
            placeholder={t("collections.search")}
            e2eTestId="collections-search-input"
            value={searchCollectionQuery}
            onChange={(event) => setSearchCollectionQuery(event.target.value)}
            searchIcon={
              <img
                src={MagnifyGlassIcon}
                onClick={handleSubmitSearchCollectionQuery}
                alt=""
                aria-label="Magnify Glass"
              />
            }
            className={styles.searchInput}
          />
        </form>
      </div>
    );

  return (
    <>
      <div className={styles.container} data-testid="collections-outer">
        {searchTerm ? (
          <div data-testid="searched-collections">
            <div className={styles.navigationTop}>
              <CollectionsBackButton onClick={handleBackClick} />
              {eventsSearchField()}
            </div>
            <SearchedCollections
              key={`collection_${renderKey}`}
              searchTerm={searchTerm}
            />
          </div>
        ) : (
          <div data-testid="tabs-container" e2e-test-id="hive-drive-header-container">
            <Tabs
              tabs={COLLECTIONS_TABS}
              size="large"
              routePrefix="/portal/collections"
              extraContent={eventsSearchField()}
            />
          </div>
        )}
      </div>
      {isCollectionsModalOpen && (
        <CollectionsTreeModal
          isOpen={isCollectionsModalOpen}
          treeModalType={TREE_MODAL_TYPE.goTo}
          collections={userCollectionsForGoToModal}
          onCancel={handleCollectionsTreeModalCanceled}
          onSelect={handleCollectionsTreeModalSelection}
          modalType="primary"
        />
      )}
    </>
  );
};

export default Collections;
