/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/Button/Button";
import Popover from "@components/Popover/Popover";
import { useCommunityService } from "@customHooks/useCommunityService";
import { ReactComponent as InfoLogo } from "@images/moreInfo.svg";
import ReportUserModal from "@modules/ChatPage/ChatActions/ReportUserModal/ReportUserModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommunityFormEnum, CommunityFormType } from "types/CommunityOptions";
import { IUser } from "types/user";
import { BlockUserModal } from "../ChatActions/BlockUserModal/BlockUserModal";
import { ChatAction } from "../ChatActions/ChatAction/ChatAction";
import styles from "./CommunityOptions.module.scss";

interface ICommunityOptionsProps {
  selectedUser: IUser;
  sendBirdId: string;
}

export const CommunityOptions = ({
  selectedUser,
  sendBirdId,
}: ICommunityOptionsProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [communityFormType, setCommunityFormType] = useState<CommunityFormEnum>(
    CommunityFormType.REPORT
  );
  const {
    banUserFromCommunity: { banUser, isBannable },
    setSendBirdId,
  } = useCommunityService(selectedUser.publicId, selectedUser.email);

  useEffect(() => {
    if (isModalOpen) setIsDropdownOpen(false);
  }, [isModalOpen]);

  useEffect(() => {
    setSendBirdId(sendBirdId);
  }, [sendBirdId]);

  const handleOpenModal = (communityFormType: CommunityFormEnum) => {
    setIsModalOpen(true);
    setCommunityFormType(communityFormType);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const communityOptions = [
    {
      isAccessible: true,
      ariaLabel: "reportUserOption",
      text: t("reportUser.title"),
      onOptionClick: () => handleOpenModal(CommunityFormType.REPORT),
      children: (
        <ReportUserModal
          open={isModalOpen && communityFormType === CommunityFormType.REPORT}
          close={handleCloseModal}
          publicId={selectedUser.publicId}
        />
      ),
    },
    {
      isAccessible: isBannable,
      ariaLabel: "blockUserOption",
      text: t("blockUser.type"),
      onOptionClick: () => handleOpenModal(CommunityFormType.BLOCK),
      children: (
        <BlockUserModal
          isOpen={isModalOpen && communityFormType === CommunityFormType.BLOCK}
          onClose={handleCloseModal}
          onSubmit={() => banUser(sendBirdId)}
          user={selectedUser}
        />
      ),
    },
  ];

  const renderCommunityOptions = () =>
    communityOptions.map((communityOption) => {
      if (communityOption.isAccessible) {
        return <ChatAction {...communityOption} />;
      }

      return <></>;
    });

  return (
    <Popover
      menuState={isDropdownOpen}
      placement="bottom"
      setMenuState={setIsDropdownOpen}
      menu={renderCommunityOptions()}
      className={styles.popoverContainer}
      childToWrap={
        <Button
          aria-label="dropDown"
          title=""
          buttonIcon={<InfoLogo />}
          buttonShape="circle"
          buttonSize="small"
          onClick={() => setIsDropdownOpen(true)}
          wrapperClassNames={styles.moreMenu}
        />
      }
    />
  );
};
