import { AnyAction } from "redux";
import { RootState } from "../store";
import {
  SET_LATEST_SUGGESTED_ARTICLES_BY_USER_ACTIVITIES,
  SET_NEXT_TIME_THRESHOLD_FOR_UPDATE,
} from "./constants";
import { Article } from "./types";

const initialState: Article = {
  nextUpdateTimeForSuggestedArticles: new Date(),
  suggestedArticleList: [],
};

const articleReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NEXT_TIME_THRESHOLD_FOR_UPDATE:
      return {
        ...state,
        nextUpdateTimeForSuggestedArticles: payload,
      };
    case SET_LATEST_SUGGESTED_ARTICLES_BY_USER_ACTIVITIES:
      return {
        ...state,
        suggestedArticleList: payload,
      };
    default:
      return state;
  }
};

export const getNextTimeThresholdForUpdate = (state: RootState) =>
  state.article.nextUpdateTimeForSuggestedArticles;

export const getLatestSuggestedArticlesByUserActivity = (state: RootState) =>
  state.article.suggestedArticleList;

export default articleReducer;
