/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import BackButton from "@components/BackButton/BackButton";
import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_EVENTS from "@graphql/queries/events";
import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEvent } from "types/event";
import styles from "./EventsViewMore.module.scss";

interface IEventsViewMoreProps {
  searchValue: string;
  interests: Array<string>;
}

const EventsViewMore = ({ searchValue, interests }: IEventsViewMoreProps) => {
  const { t } = useTranslation();
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [gridEvents, setGridEvents] = useState<Array<IEvent>>([]);
  const [gridEventsInitLoaded, setGridEventsInitLoaded] = useState<boolean>(false);
  const [gridEventsLoadingMore, setGridEventsLoadingMore] = useState<boolean>(false);
  const [firstEventId, setFirstEventId] = useState<string>("");

  const [getGridEvents] = useLazyQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.events) return;
      setGridEvents([...gridEvents, ...data.events.events]);
      data.events.totalCount && setEventsCount(data.events.totalCount);
      !gridEventsInitLoaded && setGridEventsInitLoaded(true);
      gridEventsLoadingMore && setGridEventsLoadingMore(false);
      data.events.events.length && setFirstEventId(data.events.events[0].publicId);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  useEffect(() => {
    if (!interests.length) return;
    loadGridEvents();
  }, [interests]);

  const loadGridEvents = () => {
    gridEventsInitLoaded && setGridEventsLoadingMore(true);
    getGridEvents({
      variables: {
        skip: gridEvents.length,
        size: EVENTS_GRID_SIZE,
        text: searchValue,
        from: getDateInUtc(),
        interests: searchValue.length ? [] : interests,
      },
    });
  };

  return (
    <div className={styles.eventsContainer}>
      <BackButton path="/portal/resources" label={t("resources.backToResult")} />
      <ResourcesHeading
        resultsCount={eventsCount}
        searchValue={searchValue}
        sectionTitle={t("resources.events")}
        isTitleDisplayed={false}
      />
      <EventsGrid
        events={gridEvents}
        searchValue={searchValue}
        loadMore={loadGridEvents}
        eventsLoading={!gridEventsInitLoaded}
        eventsCount={eventsCount}
        eventsLoadingMore={gridEventsLoadingMore}
        firstItemId={firstEventId}
      />
    </div>
  );
};

export default EventsViewMore;
