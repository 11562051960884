import { LeaveGroupButton } from "@components/LeaveGroupButton/LeaveGroupButton";
import { RequestApproval } from "@components/RequestCard/RequestApproval/RequestApproval";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CommunityNotificationType, ICommunityNotification } from "types/Notification";
import { MessageCard } from "../MessageCard/MessageCard";
import styles from "./CommunityNotification.module.scss";

export const CommunityNotification = (notification: ICommunityNotification) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { removeNotification } = useCommunityNotificationsHelper();

  const handleRedirectionToCommunity = ({ link: { path } }: ICommunityNotification) => {
    if (path) history.push(path);
  };

  const removeCommunityNotification = ({ id }: ICommunityNotification) =>
    removeNotification(id);

  const renderGeneralNotification = (notification: ICommunityNotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="General Notification"
      notification={notification}
      className={styles.notification}
      handleLink={notification.link.onClick}
    />
  );

  const renderAddedNotification = (notification: ICommunityNotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="Add Notification"
      notification={notification}
      className={styles.notification}
      handleLink={() => handleRedirectionToCommunity(notification)}
    >
      <LeaveGroupButton
        sendBirdId={notification.sendBirdId}
        onClick={() => removeCommunityNotification(notification)}
        type="link"
        title={t("notifications.leave")}
        wrapperClassNames={styles.leaveButton}
      />
    </MessageCard>
  );

  const renderRequestNotification = (notification: ICommunityNotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="Request Notification"
      notification={notification}
      className={styles.notification}
      handleLink={() => handleRedirectionToCommunity(notification)}
    >
      <RequestApproval
        className={styles.buttons}
        {...notification}
        onClick={() => removeCommunityNotification(notification)}
      />
    </MessageCard>
  );

  const renderRemoveNotification = (notification: ICommunityNotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="Remove Notification"
      notification={notification}
      className={`${styles.notification} ${styles.removeNotification}`}
      handleClickCard={notification.onClick}
    />
  );

  const renderCommunityNotificationType = {
    [CommunityNotificationType.GENERAL]: renderGeneralNotification,
    [CommunityNotificationType.REQUEST]: renderRequestNotification,
    [CommunityNotificationType.ADDED]: renderAddedNotification,
    [CommunityNotificationType.REMOVE]: renderRemoveNotification,
  };

  const renderNotification = (notification: ICommunityNotification) =>
    renderCommunityNotificationType[notification.type](notification);

  return renderNotification(notification);
};
