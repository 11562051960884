import { ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Progress, Spin } from "antd";
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";

// STORE
import { useDispatch, useSelector } from "react-redux";
import { addNewFolder, clearAllNewFolders } from "../../store/newFolders/actions";
import { RootState } from "../../store/store";

// GQL
import ADD_FILES_TO_FOLDER from "@graphql/mutations/addFilesToFolder";
import ADD_LINK_TO_FOLDER from "@graphql/mutations/addLinkToFolder";
import ADD_SUBFOLDER from "@graphql/mutations/addSubfolder";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import RENAME_FILE_IN_COLLECTION from "@graphql/mutations/renameFileInCollection";
import RENAME_FOLDER_IN_COLLECTION from "@graphql/mutations/renameFolderInCollection";
import GET_SINGLE_FOLDER from "@graphql/queries/getSingleFolder";

// TYPES
import {
  CollectionType,
  ICollection,
  INewFolder,
  ISingleCollection,
  IUserCollectionResponse,
} from "@graphql/collection/type";
import { DefaultOption } from "types/select";
import { IFolder } from "../../store/newFolders/types";

// HELPERS
import FileChecker from "@utils/files/fileChecker";
import { v4 as uuidv4 } from "uuid";
import {
  supportedOfficeExtensions,
  supportedUploadToCollectionExtension,
} from "../../utils/files/constants";

import styles from "./FolderSingle.module.scss";

import { PlusOutlined } from "@ant-design/icons";
import DangerIcon from "@images/DangerIcon.png";
import UniversalFileIcon from "@images/Files/universalFile.svg";
import CollectionsIcon from "@images/emptySingleCollection.svg";
import UploadIcon from "@images/upload.svg";

import Button from "@components/Button/Button";
import CollaboratorsFollowers from "@components/CollaboratorsFollowers/CollaboratorsFollowers";
import EmptyComponent from "@components/EmptyComponent/EmptyComponent";
import FolderFilesContainer from "@components/FolderFilesContainer/FolderFilesContainer";
import FolderFoldersContainer from "@components/FolderFoldersContainer/FolderFoldersContainer";
import Loading from "@components/Loading/Loading";
import ResourcesCategories from "@components/ResourcesCategories/ResourcesCategories";
import Select from "@components/Select/Select";
import Tabs from "@components/Tabs/Tabs";
import ViewCollaboratorsFollowersModal from "@components/ViewCollaboratorsFollowersModal/ViewCollaboratorsFollowersModal";

import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import { CollectionsTreeNode } from "@components/CollectionsTree";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";
import DangerModal from "@components/DangerModal/DangerModal";
import LinkModal from "@components/LinkModal/LinkModal";
import { IOption } from "@components/Menu/types";
import UploadModal from "@components/UploadModal/UploadModal";
import { UploadModalType } from "@components/UploadModal/type";
import {
  COMMUNITY_DEFAULT_COLLECTION_DEFAULT_FOLDER_NAME as DEFAULT_FOLDER_NAME,
  FILE_WITH_NAME_EXISTS_IN_COLLECTION_ERROR_CODE,
  SORTING_A_Z,
  SORTING_MOST_RECENT,
  SORTING_Z_A,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
  sortingFoldersObj,
} from "@constants/constants";
import {
  MOVE_LINK_TO_COLLECTION,
  MOVE_LINK_TO_FOLDER,
} from "@graphql/collection/mutation";
import { GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES } from "@graphql/collection/queries";
import { COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS } from "@graphql/community-collection/queries";
import DELETE_FOLDER from "@graphql/mutations/deleteFolder";
import { DELETE_LINK } from "@graphql/mutations/deleteLink";
import { UPDATE_LINK } from "@graphql/mutations/updateLink";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import checkDefaultSortType from "@utils/checkDefaultSortType";
import { isDocumentRtl } from "@utils/checkDocumentDir";
import { BlobStorageContainer, UploadError } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import { getSortedArray } from "@utils/helpers";
import { IFile } from "types/file";
import { ILink } from "types/link";
import { addCrumb } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";
import { newFoldersSelector } from "../../store/newFolders/selectors";

type ParamsProps = {
  collectionId: string;
  folderId: string;
};

const FolderSingle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams() as ParamsProps;
  const location = useLocation();
  const currentUserId = useSelector<RootState, string>(
    (state) => state.user.userPublicId
  );
  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );
  const userPublicId = useSelector<RootState, string>((state) => state.user.userPublicId);
  const newFoldersStored = useSelector(newFoldersSelector);

  const [folderParentCollection, setFolderParentCollection] =
    useState<ISingleCollection>();
  const [isUserCreatorOfCollection, setUserCreatorOfCollection] = useState(false);
  const [isUserCollaboratorOfCollection, setUserCollaboratorOfCollection] =
    useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [isOpenCollaborators, setOpenCollaborators] = useState(false);

  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState<boolean>(false);
  const [linkToBeRemoved, setLinkToBeRemoved] = useState<ILink>();
  const [isOpenLinkDangerModal, setIsOpenLinkDangerModal] = useState<boolean>(false);
  const [linkToUpdate, setLinkToUpdate] = useState<ILink>();
  const [isOpenUpdateLinkModal, setIsOpenUpdateLinkModal] = useState<boolean>(false);

  const [isMoveLinkModalOpen, setIsMoveLinkModalOpen] = useState<boolean>(false);
  const [linkToMoveId, setLinkToMoveId] = useState<string>("");
  const [linkToMoveName, setLinkToMoveName] = useState<string>("");

  const [
    isUploadFilesToCommunityCollectionModalOpen,
    setIsUploadFilesToCommunityCollectionModalOpen,
  ] = useState<boolean>(false);
  const [fileInterests, setFileInterests] = useState<string[]>([]);
  const [folders, setFolders] = useState<INewFolder[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);
  const [newFolderCreation, setNewFolderCreation] = useState(false);
  const {
    uploadFiles,
    isUploadInProgress,
    currentUpload,
    currentUploadBytesUploaded,
    currentUploadBytesTotal,
  } = useUploadFiles();

  const [filesUploaded, setFilesUploaded] = useState<number>(0);
  const [uploadProgressPercentage, setUploadProgressPercentage] = useState<number>(0);

  const [isDefaultFolder, setIsDefaultFolder] = useState<boolean>(false);

  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [countTotal, setCountTotal] = useState(0);

  const [destinationFolderName, setDestinationFolderName] = useState<string>("");
  const [destinationCollectionName, setDestinationCollectionName] = useState<string>("");
  const [modalActiveTab, setModalActiveTab] = useState<string>("");
  const [parentCollection, setParentCollection] = useState<string>("");

  const [userAndCollaboratingCollections, setUserAndCollaboratingCollections] =
    useState<Array<ICollection>>();
  const [communityCollections, setCommunityCollections] = useState<ICollection[]>([]);

  // GET USER AND COLLABORATING COLLECTIONS
  useQuery<IUserCollectionResponse>(GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IUserCollectionResponse) => {
      setUserAndCollaboratingCollections(
        [...data.user.myCollections, ...data.user.collaboratingCollections].sort(
          sortingCollectionsObj[SORTING_A_Z].sort
        )
      );
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  // GET ALL COMMUNITY COLLECTIONS FOR MOVE TO MODAL
  const [getAllCommunityCollections] = useLazyQuery(
    COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        const allCommunityCollections: ICollection[] =
          res.allCommunityCollections.collections;

        // Filter only collections that were created by
        // the current user or the user is a collaborator of
        const communityCollectionsCreatedOrCollaborator = allCommunityCollections.filter(
          (collection) =>
            collection.collectionCreator.publicId === currentUserId ||
            collection.collaborators?.some(
              (collaborator) => collaborator.publicId === currentUserId
            )
        );

        setCommunityCollections(communityCollectionsCreatedOrCollaborator);
      },
      onError: (err) => displayServerError(err),
    }
  );

  useEffect(() => {
    const communityCrumb = breadcrumbs.filter((crumb) =>
      crumb.path.includes("/portal/community")
    );

    let communityId;

    if (
      communityCrumb.length &&
      folderParentCollection?.collectionType === CollectionType.COMMUNITY_COLLECTION
    ) {
      communityId = communityCrumb[0].id;
    }

    if (communityId) {
      getAllCommunityCollections({
        variables: {
          communityCollectionSearch: {
            size: 1000,
            skip: 0,
            text: "",
            sendbirdId: communityId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderParentCollection]);

  const MAX_LINK_NAME_LENGTH = 30;

  const checkLinkNameLength = (linkName: string) => {
    if (linkName.length <= MAX_LINK_NAME_LENGTH) {
      return linkName;
    } else {
      return `${linkName.slice(0, MAX_LINK_NAME_LENGTH)}...`;
    }
  };

  const [moveLinkToCollection] = useMutation(MOVE_LINK_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(
        t("collections.collectionsActions.successMoveLinkToCollection", {
          linkToMoveName: checkLinkNameLength(linkToMoveName),
          destinationCollectionName,
        })
      );
      refetch();
      setIsMoveLinkModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.linkExistsOrNotSufficientRights",
          {
            name: linkToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7004") {
        const message = t("collections.singleCollection.linkAlreadyInCollection");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: checkLinkNameLength(linkToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [moveLinkToFolder] = useMutation(MOVE_LINK_TO_FOLDER, {
    onCompleted: () => {
      modalActiveTab === "Personal"
        ? displaySuccessNotification(
            t("collections.collectionsActions.successMoveLinkToFolderInOtherCollection", {
              linkToMoveName: checkLinkNameLength(linkToMoveName),
              destinationFolderName,
              collectionName: parentCollection,
            })
          )
        : displaySuccessNotification(
            t("collections.collectionsActions.successMoveLinkToFolder", {
              linkToMoveName: checkLinkNameLength(linkToMoveName),
              destinationFolderName,
            })
          );

      refetch();
      setIsMoveLinkModalOpen(false);
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.linkExistsOrNotSufficientRights",
          {
            name: linkToMoveName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7004") {
        const message = t("collections.singleCollection.linkAlreadyInFolder");
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: checkLinkNameLength(linkToMoveName),
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const handleMoveLinkToSelect = (path: CollectionsTreeNode[] | undefined) => {
    const lastChild = path?.at(-1);
    const firstChild = path?.at(0);

    setDestinationFolderName(lastChild!.title as string);

    if (lastChild?.type === "collection") {
      setDestinationCollectionName(lastChild!.title as string);
      moveLinkToCollection({
        variables: {
          moveLinkToCollectionRequest: {
            collectionPublicId: lastChild.key,
            linkPublicId: linkToMoveId,
          },
        },
      });
    }

    if (lastChild?.type === "folder") {
      setParentCollection(firstChild!.title as string);
      moveLinkToFolder({
        variables: {
          moveLinkToFolderRequest: {
            folderPublicId: lastChild.key,
            linkPublicId: linkToMoveId,
          },
        },
      });
    }
  };

  const collectionsTabTitle =
    folderParentCollection?.collectionType === CollectionType.USER_COLLECTION
      ? "personalTabTitle"
      : "personalCommunityTabTitle";

  const handleSetModalActiveTab = (activeTab: string) => setModalActiveTab(activeTab);

  const collectionsForMoveModal =
    folderParentCollection?.collectionType === CollectionType.USER_COLLECTION
      ? userAndCollaboratingCollections
      : communityCollections;

  useEffect(() => {
    if (showUploadProgress) {
      toast(`${uploadProgressPercentage.toString()}`, {
        className: styles.toaster,
        toastId: "toaster",
        position: isDocumentRtl() ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUploadProgress]);

  useEffect(() => {
    if (!isUploadInProgress) {
      setShowUploadProgress(false);
    }
  }, [isUploadInProgress]);

  // TODO: Move to constants
  const COLLECTIONS_TABS = [
    {
      name: t("collections.myCollections"),
      selectedByRoutes: ["/my-collections"],
    },
    {
      name: t("collections.explore"),
      selectedByRoutes: ["/explore", "/explore/view-more"],
    },

    // {
    //   name: t("collections.activity"),
    //   selectedByRoutes: ["/activity"],
    // },
  ];

  const [AddFilesToFolder] = useMutation(ADD_FILES_TO_FOLDER, {
    onCompleted: () => {
      setFilesUploaded((prev: number) => prev + 1);
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const fileThatFailedName = clientOptions.variables.addFilesToFolder.files[0].name;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            fileName: fileThatFailedName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          fileName: fileThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification(err);
      }
    },
  });

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  // TODO: Move outside
  const sortOptions = [
    { value: SORTING_MOST_RECENT, label: t("collections.sorting.mostRecent") },
    { value: SORTING_A_Z, label: t("collections.sorting.alphabeticalAZ") },
    { value: SORTING_Z_A, label: t("collections.sorting.alphabeticalZA") },
  ];
  const [sortValue, setSortValue] = useState(sortOptions[0]);

  const {
    data: folderSingleData,
    loading: folderSingleLoading,
    refetch,
  } = useQuery(GET_SINGLE_FOLDER, {
    variables: { publicId: params.folderId },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      const parentCollection = res.folder.parentCollection;
      setFolderParentCollection(parentCollection);

      // Check if folder is Attachments (default)
      if (res.folder.name === DEFAULT_FOLDER_NAME && !res.folder.isEditable) {
        setIsDefaultFolder(true);
      } else {
        setIsDefaultFolder(false);
      }

      // Check if user is creator
      setUserCreatorOfCollection(
        userPublicId === parentCollection.collectionCreator.publicId
      );

      // Check if user is collab
      setUserCollaboratorOfCollection(
        parentCollection.collaborators.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (collaborator: any) => collaborator.publicId === userPublicId
        )
      );

      // Get all categories from interests
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCategories(parentCollection.interests.map((cat: any) => cat.name));

      setFolders(res.folder.folders);
      setFiles(res.folder.files);
      setLinks(res.folder.links);
      setSortValue(sortOptions[0]);

      const breadCrumb: IBreadcrumb = {
        id: res.folder.publicId,
        name: res.folder.name,
        path: location.pathname,
      };

      dispatch(addCrumb(breadCrumb));
    },
  });

  const [addLinkToFolder] = useMutation(ADD_LINK_TO_FOLDER, {
    onCompleted: () => {
      displaySuccessNotification(t("collections.singleCollection.linkAdded"));
      setIsAddLinkModalOpen(false);
      refetch();
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const linkThatFailedName = clientOptions.variables.linkToAdd.name;

      if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: linkThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const handleAddLink = (link: ILink) => {
    addLinkToFolder({
      variables: {
        linkToAdd: {
          name: link.name,
          url: link.url,
          folderPublicId: params.folderId,
        },
      },
    });
  };

  useEffect(() => {
    const sortedFolders = getSortedArray(
      folders,
      sortingFoldersObj[sortValue.value].value,
      checkDefaultSortType(sortValue)
    );
    setFolders([...sortedFolders]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  const handlePeopleModal = () => {
    setOpenCollaborators(true);
  };

  const inputFileRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleUploadFilesBtnClick = () => {
    setFilesUploaded(0);
    inputFileRef.current.value = "";
    inputFileRef.current.click();
  };

  const onUploadBtnClick = () => {
    if (folderParentCollection?.collectionType === CollectionType.USER_COLLECTION) {
      handleUploadFilesBtnClick();
    }
    if (folderParentCollection?.collectionType === CollectionType.COMMUNITY_COLLECTION) {
      setIsUploadFilesToCommunityCollectionModalOpen(true);
    }
  };

  // Add new folder to store
  const handleCreateNewFolder = () => {
    const folder: IFolder = {
      id: uuidv4(),
      name: "New Folder",
    };

    dispatch(addNewFolder(folder));

    setNewFolderCreation(true);
  };

  // Clear all new folders from store
  const handleClearAllNewFolders = () => {
    dispatch(clearAllNewFolders());
  };

  // Clear new folders on load
  useEffect(() => {
    handleClearAllNewFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear new folders on page change
  useEffect(() => {
    handleClearAllNewFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const folderItems =
    folders?.length ||
    files?.length ||
    links?.length ||
    // || articles?.length
    newFolderCreation;

  const handleSetUploadableFiles = async (files: File[]) => {
    setShowUploadProgress(true);
    setCountTotal(files.length);
    setFilesUploaded(0);

    setTimeout(async () => {
      const isUploadable = await Promise.all(
        files.map((file) =>
          FileChecker.isUploadable(file, supportedUploadToCollectionExtension)
        )
      );

      const uploadableFiles = Array.from(files).filter((_, index) => isUploadable[index]);
      handleFiles(uploadableFiles);
    }, 0);
  };

  const changeHandler = async (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files as FileList);
    handleSetUploadableFiles(files);
  };

  const addUploadedFileToFolder = async (file: File, directory: string) => {
    const fileToUpload = [
      {
        name: file.name,
        size: file.size,
        directory,
      },
    ];
    const addFilesToFolder = {
      folderPublicId: params.folderId,
      files: fileToUpload,
      interests: fileInterests,
    };
    const convertToPdfRequest = {
      fileName: file.name,
      directoryName: directory,
    };
    try {
      await AddFilesToFolder({
        variables: { addFilesToFolder },
      });
    } catch (error) {
      displayNotSuccessNotification();
    }
    try {
      const fileExt = file.name!.split(".").pop();
      if (supportedOfficeExtensions.includes("." + fileExt!)) {
        await ConvertFileToPdf({
          variables: { convertToPdfRequest },
        });
      }
    } catch (error) {
      displayNotSuccessNotification();
    }
  };

  const uploadErrorHandler = (error: UploadError) => {
    error.files.forEach((file) =>
      displayNotSuccessNotification(
        undefined,
        t("collections.errorsMessages.fileUploadFailed", { filename: file.name })
      )
    );
  };

  const handleFiles = (newFiles: Array<File>) => {
    uploadFiles(newFiles, BlobStorageContainer.FILES, {
      onFileUpload: addUploadedFileToFolder,
      onError: uploadErrorHandler,
    });
  };

  useEffect(() => {
    if (!currentUploadBytesTotal) {
      return;
    }
    const uploadProgress = currentUploadBytesUploaded / currentUploadBytesTotal;
    const uploadProgressToPercentage = Math.ceil(100 * uploadProgress);

    setUploadProgressPercentage(uploadProgressToPercentage);

    toast.update("toaster", {
      className: `${styles.toaster} ${styles.active}`,
      autoClose: 5000,
      render: (
        <>
          <div className={styles.upperSection}>
            {filesUploaded < countTotal && countTotal > 1 && currentUpload ? (
              <Spin size="large" />
            ) : (
              <img src={UniversalFileIcon} className={styles.fileIcon} />
            )}
            <div className={styles.infoSection}>
              {currentUpload && (
                <p className={styles.uploadingFileName}>{currentUpload}</p>
              )}
              {t("collections.singleCollection.toaster.filesUploaded", {
                filesUploaded,
                totalFiles: countTotal,
              })}
            </div>
            {filesUploaded < countTotal && currentUpload ? (
              <span
                className={styles.cancelButton}
                // TODO Find a better way to abort uploading
                onClick={() => window.location.reload()}
              >
                {t("collections.singleCollection.toaster.cancelUpload")}
              </span>
            ) : (
              <span className={styles.cancelButton}>
                {t("collections.singleCollection.toaster.closeToaster")}
              </span>
            )}
          </div>
          {currentUpload && countTotal === 1 && (
            <Progress className={styles.progress} percent={uploadProgressPercentage} />
          )}
        </>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUploadBytesUploaded,
    currentUploadBytesTotal,
    filesUploaded,
    countTotal,
    uploadProgressPercentage,
  ]);

  const getFolderName = (folderName: string) => {
    const subfolderToAdd = {
      name: folderName,
      parentFolderPublicId: params.folderId,
    };

    addSubfolderToFolder({
      variables: {
        subfolderToAdd,
      },
    });
  };

  const [addSubfolderToFolder, { loading: addFolderLoading }] = useMutation(
    ADD_SUBFOLDER,
    {
      onCompleted: () => {
        handleClearAllNewFolders();
        displaySuccessNotification(t("collections.singleCollection.folderCreated"));
        setNewFolderCreation(false);
        refetch();
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      onError: (err: any, clientOptions: any) => {
        const folderThatFailedName = clientOptions.variables.subfolderToAdd.name;
        const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

        if (codeMessage === "2500") {
          const message = t(
            "collections.singleCollection.folderExistsOrNotSufficientRights",
            {
              name: folderThatFailedName,
            }
          );
          displayNotSuccessNotification(message, message);
        } else if (codeMessage === "2512") {
          const message = t(
            "collections.singleCollection.folderWithSameNameAlreadyExists",
            {
              name: folderThatFailedName,
            }
          );
          displayNotSuccessNotification(message, message);
        } else {
          displayNotSuccessNotification(err);
        }
      },
    }
  );

  const [renameFileInCollection, { loading: renameFileLoading }] = useMutation(
    RENAME_FILE_IN_COLLECTION,
    {
      onCompleted: () => {
        displaySuccessNotification(t("collections.singleCollection.fileRenamed"));
        refetch();
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const apolloError = error.networkError?.result?.errors[0];

        const errorCode = apolloError?.extensions?.code;
        const message = apolloError?.message;

        if (errorCode === FILE_WITH_NAME_EXISTS_IN_COLLECTION_ERROR_CODE) {
          displayNotSuccessNotification(message, message);
        } else {
          displayNotSuccessNotification(error);
        }
      },
    }
  );

  const handleFileRename = (fileId: string, fileName: string, fileExtension: string) => {
    const fileRename: { filePublicId: string; newName: string } = {
      filePublicId: fileId,
      newName: `${fileName}${fileExtension}`,
    };

    renameFileInCollection({
      variables: {
        fileRename,
      },
    });
  };

  const handleFolderRename = (folderId: string, folderName: string) => {
    const folderRename: { folderPublicId: string; newName: string } = {
      folderPublicId: folderId,
      newName: folderName,
    };
    renameFolderInCollection({
      variables: {
        folderRename,
      },
    });
  };

  const [renameFolderInCollection, { loading: renameLoading }] = useMutation(
    RENAME_FOLDER_IN_COLLECTION,
    {
      onCompleted: () => {
        displaySuccessNotification(t("collections.singleCollection.folderRenamed"));
        refetch();
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  const [deleteFolder, { loading: removeLoading }] = useMutation(DELETE_FOLDER, {
    onCompleted: () => {
      displaySuccessNotification(t("collections.singleCollection.folderDeleted"));
      refetch();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const handleDeleteFolder = (folderId: string) => {
    deleteFolder({
      variables: {
        folderPublicId: folderId,
      },
    });
  };

  const [deleteLinkFromFolder, { loading: isDeleteLinkInProgress }] = useMutation(
    DELETE_LINK,
    {
      onCompleted: () => {
        setIsOpenLinkDangerModal(false);
        displaySuccessNotification(
          t("collections.collectionsActions.successDeleteLink", {
            collectionName: folderParentCollection?.name,
          })
        );
        refetch();
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  const [updateLink] = useMutation(UPDATE_LINK, {
    onCompleted: () => {
      setIsOpenUpdateLinkModal(false);
      displaySuccessNotification(t("collections.collectionsActions.successUpdateLink"));
      setLinkToUpdate(undefined);
      refetch();
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const linkThatFailedName = clientOptions.variables.linkUpdate.newName;

      if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: linkThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const handleUpdateLink = (link: ILink) => {
    updateLink({
      variables: {
        linkUpdate: {
          newName: link.name,
          newUrl: link.url,
          linkPublicId: link.publicId,
        },
      },
    });
  };

  const deleteLinkFromFolderHandler = (link: ILink) => {
    deleteLinkFromFolder({
      variables: {
        deleteLinkRequest: {
          linkPublicId: link.publicId,
        },
      },
    });
  };

  const handleLinkDelete = (link: ILink) => {
    setLinkToBeRemoved(link);
    setIsOpenLinkDangerModal(true);
  };

  const handleCloseLinkModal = () => {
    setIsOpenLinkDangerModal(false);
    setLinkToBeRemoved(undefined);
  };

  const handleCloseUpdateLinkModal = () => {
    setIsOpenUpdateLinkModal(false);
    setLinkToUpdate(undefined);
  };

  const handleLinkUpdate = (link: ILink) => {
    setLinkToUpdate(link);
    setIsOpenUpdateLinkModal(true);
  };

  const linkMenuDots = useCallback(
    (link: ILink) => {
      let options: IOption[] = [];

      if (link.canUserDeleteLink) {
        options = [
          ...options,
          {
            isDelete: true,
            label: "delete",
            action: () => handleLinkDelete(link),
          },
        ];
      }

      if (isUserCreatorOfCollection || isUserCollaboratorOfCollection) {
        options = [
          {
            label: "update",
            action: () => handleLinkUpdate(link),
          },
          {
            label: "move",
            action: () => {
              setIsMoveLinkModalOpen(true);
              setLinkToMoveId(link.publicId);
              setLinkToMoveName(link.name);
            },
          },
          ...options,
        ];
      }

      return options;
    },
    [isUserCollaboratorOfCollection, isUserCreatorOfCollection]
  );

  const folderLoader =
    addFolderLoading || renameLoading || renameFileLoading || removeLoading;

  const handleFolderEditId = (folderId: string) => {
    const modifiedFolders = folders.map((folder) => {
      if (folder.publicId === folderId) {
        return { ...folder, isEdit: true };
      } else {
        return folder;
      }
    });

    setFolders(modifiedFolders);
  };

  const handleFileEditId = (fileId: string) => {
    const modifiedFiles = files.map((file) => ({
      ...file,
      isEdit: file.publicId === fileId,
    }));

    setFiles([...modifiedFiles]);
  };

  const handleRefetch = () => {
    refetch();
  };

  return (
    <>
      {folderSingleLoading ? (
        <Loading disableBoxShadow />
      ) : folderSingleData && folderSingleData.folder.name ? (
        <>
          <div className={styles.container}>
            <div className={styles.tabContainer}>
              <Tabs
                tabs={COLLECTIONS_TABS}
                size="large"
                routePrefix="/portal/collections"
              />
            </div>

            <div className={styles.breadCrumbsContainer}>
              <Breadcrumbs />
            </div>
            <div className={styles.topBar}>
              <h3 className={styles.title}>{folderSingleData.folder.name}</h3>
              {folderSingleData.folder.isEditable && (
                <div className={styles.actionWraper}>
                  {(isUserCollaboratorOfCollection || isUserCreatorOfCollection) && (
                    <>
                      <label htmlFor="collectionFile">
                        <Button
                          buttonSize="large"
                          className={styles.uploadButton}
                          disabled={isUploadInProgress}
                          buttonIcon={
                            <img
                              className={styles.uploadIcon}
                              src={UploadIcon}
                              alt="Upload Icon"
                            />
                          }
                          title={t("collections.buttons.uploadFile")}
                          onClick={onUploadBtnClick}
                        />
                        <input
                          type="file"
                          ref={inputFileRef}
                          name="collectionFile"
                          id="collectionFile"
                          multiple={true}
                          onChange={changeHandler}
                          accept={supportedUploadToCollectionExtension.join(",")}
                          aria-label="input"
                          className={styles.inputFile}
                        />
                      </label>
                      <Button
                        buttonType="primary"
                        buttonSize="large"
                        aria-label="new-folder"
                        title={t("collections.singleCollection.newFolder")}
                        buttonIcon={<PlusOutlined />}
                        onClick={handleCreateNewFolder}
                        disabled={newFoldersStored.length > 0}
                        fullWidth
                      />
                      <Button
                        buttonType="primary"
                        buttonSize="large"
                        aria-label="add-link"
                        title={t("collections.singleCollection.addLink")}
                        buttonIcon={<PlusOutlined />}
                        onClick={() => setIsAddLinkModalOpen(true)}
                        fullWidth
                      />
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={styles.middleBar}>
              <div>
                <ResourcesCategories categories={categories} />
              </div>
              {/* Hide collaborators and followers if folder is Attachments (default) */}
              {!isDefaultFolder && (
                <div className={styles.peopleOuter} onClick={() => handlePeopleModal()}>
                  <CollaboratorsFollowers collection={folderParentCollection} />
                </div>
              )}
            </div>
            {isDefaultFolder && (
              <div className={styles.bottomBar}>
                <p>
                  {t("collections.singleCollection.folders.defaultFolderDescription")}
                </p>
              </div>
            )}
            {folderLoader ? (
              <Loading disableBoxShadow />
            ) : (
              <>
                <div className={styles.sortContainer}>
                  <div className={styles.sortDropdownWrap}>
                    {folderItems && (
                      <Select
                        label=""
                        placeholder=""
                        name={"sort"}
                        value={sortValue}
                        options={sortOptions}
                        showDropdown
                        onChange={(option: DefaultOption) => {
                          setSortValue(option);
                          const sortedFolders = getSortedArray(
                            folders,
                            sortingFoldersObj[sortValue.value].value,
                            checkDefaultSortType(sortValue)
                          );
                          setFolders(sortedFolders);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.gridItems}>
                  <FolderFoldersContainer
                    currentCollection={folderParentCollection!}
                    collectionId={params.collectionId}
                    collectionType={folderParentCollection?.collectionType}
                    folders={folders}
                    getFolderName={getFolderName}
                    getFolderIdEdit={handleFolderEditId}
                    renameFolder={handleFolderRename}
                    deleteFolder={handleDeleteFolder}
                    isUserCreator={isUserCreatorOfCollection}
                    isUserCollaborator={isUserCollaboratorOfCollection}
                    refetchFolder={refetch}
                  />

                  {folderParentCollection && (
                    <FolderFilesContainer
                      folder={folderSingleData.folder}
                      files={files}
                      linkMenuDots={linkMenuDots}
                      sortValue={sortValue}
                      renameFile={handleFileRename}
                      getFileIdEdit={handleFileEditId}
                      isUserCollaborator={isUserCollaboratorOfCollection}
                      isUserCreator={isUserCreatorOfCollection}
                      refetchFolder={handleRefetch}
                      collectionType={folderParentCollection?.collectionType}
                      parentCollectionName={folderParentCollection.name}
                    />
                  )}
                </div>

                {!folderItems && (
                  <EmptyComponent
                    title={t("collections.singleCollection.folders.title")}
                    subTitle={
                      isDefaultFolder
                        ? ""
                        : t("collections.singleCollection.folders.subtitle")
                    }
                    image={CollectionsIcon}
                  />
                )}
              </>
            )}
          </div>
          {isAddLinkModalOpen && (
            <LinkModal
              isOpen={isAddLinkModalOpen}
              onCloseModal={() => setIsAddLinkModalOpen(false)}
              onSubmitLink={handleAddLink}
            />
          )}
          {linkToUpdate && (
            <LinkModal
              link={linkToUpdate}
              isOpen={isOpenUpdateLinkModal}
              onCloseModal={handleCloseUpdateLinkModal}
              onSubmitLink={handleUpdateLink}
            />
          )}
          {isMoveLinkModalOpen && (
            <CollectionsTreeModal
              showCurrentAndMyCollections
              collectionsTabTitle={collectionsTabTitle}
              handleSetModalActiveTab={handleSetModalActiveTab}
              collections={collectionsForMoveModal}
              treeModalType={TREE_MODAL_TYPE.move}
              isOpen={isMoveLinkModalOpen}
              currentCollection={folderParentCollection!}
              onCancel={() => setIsMoveLinkModalOpen(false)}
              onSelect={handleMoveLinkToSelect}
              modalType="primary"
            />
          )}
          {linkToBeRemoved && (
            <DangerModal
              title={t("collections.collectionsActions.deleteLinkTitle", {
                linkName: linkToBeRemoved.name,
              })}
              subTitle={t("collections.collectionsActions.deleteLinkSubTitle")}
              isOpen={isOpenLinkDangerModal}
              onClose={() => handleCloseLinkModal()}
              onSubmit={() => deleteLinkFromFolderHandler(linkToBeRemoved)}
              image={<img src={DangerIcon} />}
              closeButton={t("collections.collectionsActions.keepLink")}
              ctaButton={t("collections.collectionsActions.deleteLink")}
              isLoading={isDeleteLinkInProgress}
            />
          )}
          {isUploadFilesToCommunityCollectionModalOpen && (
            <UploadModal
              type={UploadModalType.FILES}
              handleUploadProgressWithModal={handleSetUploadableFiles}
              addFiles={handleFiles}
              handleAddInterests={setFileInterests}
              selectedInterests={fileInterests}
              open={isUploadFilesToCommunityCollectionModalOpen}
              multiple
              close={() => setIsUploadFilesToCommunityCollectionModalOpen(false)}
            />
          )}
          {folderParentCollection && (
            <ViewCollaboratorsFollowersModal
              title={folderParentCollection.name}
              isOpen={isOpenCollaborators}
              isUserCreatorOfCollection={isUserCreatorOfCollection}
              creator={folderParentCollection.collectionCreator}
              collaborators={folderParentCollection.collaborators}
              followers={folderParentCollection.followers}
              collectionType={
                folderParentCollection.isPublic
                  ? t("collections.public")
                  : t("collections.private")
              }
              refetchCollaborators={refetch}
              collectionId={params.collectionId}
              isCommunityCollection={
                folderParentCollection.collectionType ===
                CollectionType.COMMUNITY_COLLECTION
              }
              onClose={() => setOpenCollaborators(false)}
            />
          )}
        </>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default FolderSingle;
