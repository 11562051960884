import i18n from "i18next";

import _ from "lodash";

import {
  displayNotSuccessNotification,
  displayServerError,
} from "@services/NotificationService/NotifacitonService";

import { filterOutByProperty } from "@utils/array/helper";

import {
  Emoji,
  GroupChannel,
  Member,
  Reaction,
  ReactionEvent,
  SendBirdError,
  SendBirdInstance,
} from "sendbird";

import { SENDBIRD_ID_PREFIX } from "@constants/constants";

import { IUser } from "types/user";
import {
  DIRECT,
  ERROR_CODE,
  GROUP_TYPE,
  MAX_LIMIT,
  MEMBER_COUNT_IN_DIRECT_CHAT,
} from "./constant";
import { MessageType } from "./useSendBirdMessage/type";

export class SendBirdHelper {
  public static getDirectGroupOtherMember(
    groupChannel: GroupChannel,
    currentUserEmail: string
  ) {
    return groupChannel.members.find((member) => member.userId !== currentUserEmail);
  }

  public static getDirectGroupOtherMemberNickName(
    groupChannel: GroupChannel,
    currentUserEmail: string
  ) {
    return this.getDirectGroupOtherMember(groupChannel, currentUserEmail)?.nickname;
  }

  public static getGroupCurrentMember(
    groupChannel: GroupChannel,
    currentUserEmail: string
  ) {
    return groupChannel.members.find((member) => member.userId === currentUserEmail);
  }

  public static removeGroup(url: string, groupChannels?: GroupChannel[]) {
    if (!groupChannels?.length) return;

    return filterOutByProperty(groupChannels, "url", url);
  }

  public static isGroupPresent(url: string, groupChannels?: GroupChannel[]) {
    return groupChannels?.some((groupChannel) => groupChannel.url === url);
  }

  public static getDirectGroupsUserEmails(
    currentUserEmail: string,
    groupChannels?: GroupChannel[]
  ) {
    return (
      groupChannels?.reduce((current, groupChannel) => {
        const otherMemberFound = groupChannel.members.find(
          (member) => member.userId !== currentUserEmail
        );

        if (otherMemberFound) {
          current.push(otherMemberFound.userId);
        }

        return current;
      }, [] as Array<string>) || []
    );
  }

  public static isDirectGroup(groupChannel: GroupChannel) {
    return (
      groupChannel.customType === DIRECT &&
      groupChannel.memberCount === MEMBER_COUNT_IN_DIRECT_CHAT
    );
  }

  public static getDirectGroups(groupChannels?: GroupChannel[]) {
    return groupChannels?.filter(this.isDirectGroup);
  }

  public static getNonDirectGroups(groupChannels?: GroupChannel[]) {
    return groupChannels?.filter((groupChannel) => !this.isDirectGroup(groupChannel));
  }

  public static findGroupChannel = (url: string, groupChannels?: GroupChannel[]) => {
    return groupChannels?.find((groupChannel) => groupChannel.url === url);
  };

  public static findIndexGroupChannel = (url: string, groupChannels?: GroupChannel[]) => {
    return groupChannels?.findIndex((groupChannel) => groupChannel.url === url);
  };

  public static getGroupChannelUrlWithoutPrefix(groupChannel: GroupChannel) {
    return groupChannel.url.replace(SENDBIRD_ID_PREFIX, "");
  }

  public static getUrlWithoutPrefix(url?: string) {
    return url?.replace(SENDBIRD_ID_PREFIX, "");
  }

  public static async getMembers(groupChannel: GroupChannel) {
    let members: Member[] = [];

    try {
      const query = groupChannel.createMemberListQuery();
      members = await query.next();
    } catch (error) {
      displayServerError(error);
    }

    return members;
  }
}

export class SendBirdGroupChannelsHelper {
  public static async getGroupChannels(sb: SendBirdInstance) {
    let channels: GroupChannel[] | undefined = undefined;

    const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    listQuery.superChannelFilter = GROUP_TYPE;
    listQuery.includeEmpty = true;
    listQuery.order = "chronological";
    listQuery.limit = MAX_LIMIT;
    if (listQuery?.hasNext) {
      try {
        const groupChannels = await listQuery?.next();
        channels = groupChannels;
      } catch (error) {
        displayNotSuccessNotification(error);
      }
    }

    return channels;
  }
}

export interface ICreateDirectGroupChannel {
  sb: SendBirdInstance;
  currentUserEmail: string;
  selectedUserEmail: string;
  onSuccess?: (groupChannel: GroupChannel) => void;
}

export class SendBirdDirectGroupChannelHelper {
  public static async createDirectGroupChannel({
    sb,
    currentUserEmail,
    selectedUserEmail,
    onSuccess,
  }: ICreateDirectGroupChannel) {
    const params = new sb.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = true;
    params.isSuper = false;
    params.addUserId(selectedUserEmail);
    params.operatorUserIds = [currentUserEmail, selectedUserEmail];
    params.customType = DIRECT;

    let groupChannel: GroupChannel | undefined = undefined;
    try {
      groupChannel = await sb.GroupChannel.createChannel(params);
      onSuccess?.(groupChannel);
    } catch (error) {
      displayNotSuccessNotification(error);
    }

    return groupChannel;
  }
}

export interface ICreateGroupChannel {
  sb: SendBirdInstance;
  currentUserEmail: string;
  addUsers: string[];
  checked: boolean;
  groupName: string;
  description: string;
  onSuccess?: (groupChannel: GroupChannel) => void;
}

export class SendBirdGroupChannelHelper {
  public static async createGroupChannel({
    sb,
    currentUserEmail,
    addUsers,
    checked,
    groupName,
    description,
    onSuccess,
  }: ICreateGroupChannel) {
    const params = new sb.GroupChannelParams();
    params.isPublic = !checked;
    params.isEphemeral = false;
    params.isDistinct = false;
    params.isSuper = false;
    params.addUserIds(addUsers);
    params.operatorUserIds = [currentUserEmail];
    params.name = groupName;
    params.data = description;

    let groupChannel: GroupChannel | undefined = undefined;
    try {
      groupChannel = await sb.GroupChannel.createChannel(params);
      onSuccess?.(groupChannel);
    } catch (error) {
      const { code } = error as SendBirdError;

      code === ERROR_CODE.BLOCKED_USER_ERROR_CODE.code
        ? displayNotSuccessNotification(
            error,
            i18n.t(ERROR_CODE.BLOCKED_USER_ERROR_CODE.translationKey)
          )
        : displayServerError(error);
    }

    return groupChannel;
  }
}

export class SendBirdMessageHelper {
  public static getFileMessageParams(sb: SendBirdInstance, file: File) {
    const params = new sb.FileMessageParams();
    params.file = file;
    params.fileName = file.name;
    params.thumbnailSizes = [
      { maxWidth: 100, maxHeight: 100 },
      { maxWidth: 200, maxHeight: 200 },
    ];

    return params;
  }

  public static async getPreviousMessage(
    messageId: number,
    sb: SendBirdInstance | null,
    groupChannel?: GroupChannel
  ) {
    if (!groupChannel || !sb) return null;

    try {
      const params = new sb.MessageRetrievalParams();
      params.messageId = messageId;
      params.channelUrl = groupChannel.url;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.channelType = (groupChannel as any).channelType;

      return await sb.BaseMessage.getMessage(params);
    } catch (error) {
      displayServerError(error);
    }
  }

  public static mapReactionsWithExtendedData(
    emojis: Emoji[],
    members: Member[],
    reactions?: Reaction[],
    groupChannel?: GroupChannel,
    currentUser?: IUser
  ) {
    if (!reactions || !groupChannel || !currentUser) return [];

    const getEmoji = (key: string) => emojis.find((emoji) => emoji.key === key) as Emoji;
    const getUserNicknameByUserId = (userId: string) => {
      return members.find((member) => member.userId === userId)?.nickname || "";
    };

    return reactions.map(({ key, userIds }) => ({
      emoji: getEmoji(key),
      nickNames: userIds.map(getUserNicknameByUserId),
      isMyReaction: userIds.includes(currentUser.email),
      userIds,
    }));
  }

  public static findMessage(messages: Array<MessageType>, id: number) {
    return messages.find(({ messageId }) => messageId === id);
  }

  public static findIndexMessage(messages: Array<MessageType>, id: number) {
    return messages.findIndex(({ messageId }) => messageId === id);
  }

  public static updateMessageReaction(
    message: MessageType | undefined,
    reactionEvent: ReactionEvent
  ) {
    if (!message) return null;

    if (reactionEvent.operation === "add") {
      return SendBirdReactionHelper.addReaction(message, reactionEvent);
    }

    return SendBirdReactionHelper.deleteReaction(message, reactionEvent);
  }

  public static updateMessagesReaction(
    messages: Array<MessageType>,
    reactionEvent: ReactionEvent
  ) {
    const message = SendBirdMessageHelper.findMessage(messages, reactionEvent.messageId);
    const messageUpdated = SendBirdMessageHelper.updateMessageReaction(
      _.cloneDeep(message),
      reactionEvent
    );
    if (!messageUpdated) return;

    const messageUpdatedIndex = SendBirdMessageHelper.findIndexMessage(
      messages,
      messageUpdated.messageId
    );
    if (messageUpdatedIndex === -1) return;

    messages[messageUpdatedIndex] = messageUpdated;

    return messages;
  }
}

class SendBirdReactionHelper {
  public static addReaction(message: MessageType, reactionEvent: ReactionEvent) {
    const existingReactionIndex = message.reactions.findIndex(
      ({ key }) => key === reactionEvent.key
    );
    const existingReaction = message.reactions[existingReactionIndex];

    if (!existingReaction) {
      message.reactions = [
        ...message.reactions,
        SendBirdReactionHelper.mapReactEventToReaction(reactionEvent),
      ];
    }

    if (existingReaction) {
      existingReaction.userIds = [reactionEvent.userId, ...existingReaction.userIds];
      message.reactions[existingReactionIndex] = existingReaction;
    }

    return message;
  }

  public static deleteReaction(message: MessageType, reactionEvent: ReactionEvent) {
    const existingReactionIndex = message.reactions.findIndex(
      ({ key }) => key === reactionEvent.key
    );
    const existingReaction = message.reactions[existingReactionIndex];
    if (!existingReaction) return message;

    if (existingReaction.userIds.length === 1) {
      message.reactions = message.reactions.filter(
        ({ key }) => key !== existingReaction.key
      );
      return message;
    }

    existingReaction.userIds = existingReaction.userIds.filter(
      (userId) => userId !== reactionEvent.userId
    );
    message.reactions[existingReactionIndex] = existingReaction;

    return message;
  }

  public static mapReactEventToReaction(reactionEvent: ReactionEvent): Reaction {
    return {
      key: reactionEvent.key,
      updatedAt: reactionEvent.updatedAt,
      userIds: [reactionEvent.userId],
    };
  }
}
