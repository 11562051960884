/* eslint-disable react-hooks/exhaustive-deps */
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import axios from "axios";
import { useEffect, useState } from "react";

interface IConnectionInfo {
  Url: string;
  AccessToken: string;
}

export const useSignalR = () => {
  const [connectionInfo, setConnectionInfo] = useState<IConnectionInfo>({
    Url: "",
    AccessToken: "",
  });
  const [connectionSignalR, setConnectionSignalR] = useState<HubConnection | null>(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/backend/negotiate`)
      .then((res) => {
        setConnectionInfo({
          Url: res.data.Value.Url,
          AccessToken: res.data.Value.AccessToken,
        });
      })
      .catch(() => displayServerError());
  }, []);

  useEffect(() => {
    if (!connectionInfo.AccessToken || !connectionInfo.Url) return;
    connect();
  }, [connectionInfo]);

  const connect = () => {
    const connection = new HubConnectionBuilder()
      .withUrl(
        connectionInfo.Url,

        { accessTokenFactory: () => connectionInfo.AccessToken }
      )
      .withAutomaticReconnect()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.None)
      .build();

    connection.serverTimeoutInMilliseconds = 60000;

    connection.onclose(() => {
      console.assert(connection.state === HubConnectionState.Disconnected);
    });

    connection.onreconnecting(() => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
    });

    connection.onreconnected(() => {
      console.assert(connection.state === HubConnectionState.Connected);
    });

    const startSignalRConnection = async (connection: HubConnection) => {
      if (connection.state === HubConnectionState.Connected) {
        return;
      }

      try {
        await connection.start();
        setConnectionSignalR(connection);
      } catch (err) {
        setTimeout(() => startSignalRConnection(connection), 5000);
      }
    };

    startSignalRConnection(connection);
  };

  return connectionSignalR;
};
