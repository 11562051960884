/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_EVENTS from "@graphql/queries/events";
import SearchMoreIcon from "@images/searchMore.svg";
import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEvent, IEventsResponse } from "types/event";
import styles from "./SearchedEvents.module.scss";

interface ISearchedEventsProps {
  searchTerm: string;
}

export const SearchedEvents = ({ searchTerm }: ISearchedEventsProps) => {
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [firstEventId, setFirstEventId] = useState<string>("");
  const { t } = useTranslation();

  const [getEvents] = useLazyQuery<IEventsResponse>(GET_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ events: eventsData }) => {
      setInitialLoading(false);
      if (!eventsData) return;
      setEvents([...events, ...eventsData.events]);
      setEventsCount(eventsData.totalCount);
      loadingMore && setLoadingMore(false);
      eventsData.events.length && setFirstEventId(eventsData.events[0].publicId);
    },
    onError: () => {
      setInitialLoading(false);
      displayServerError();
    },
  });

  const loadEvents = () => {
    !initialLoading && setLoadingMore(true);
    getEvents({
      variables: {
        size: EVENTS_GRID_SIZE,
        skip: events?.length,
        text: searchTerm,
        from: getDateInUtc(),
      },
    });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  if (initialLoading) return <Loading disableBoxShadow />;
  return !events.length ? (
    <div aria-label="No Results Found" className={styles.noResultsContainer}>
      <div className={styles.imgWrapper}>
        <img src={SearchMoreIcon} className={styles.img} />
      </div>
      <span className={styles.noResultsText}>{t("events.noSearchResult")}</span>
      <span>{t("events.tryAgainSearch")}</span>
    </div>
  ) : (
    <EventsGrid
      events={events}
      searchValue={searchTerm}
      loadMore={loadEvents}
      eventsLoading={initialLoading}
      eventsCount={eventsCount}
      eventsLoadingMore={loadingMore}
      firstItemId={firstEventId}
    />
  );
};
