import ar from "antd/lib/locale/ar_EG";
import en from "antd/lib/locale/en_US";
import es from "antd/lib/locale/es_ES";
import fr from "antd/lib/locale/fr_FR";
import ko from "antd/lib/locale/ko_KR";
import ru from "antd/lib/locale/ru_RU";
import zh from "antd/lib/locale/zh_CN";
import { Locale } from "./type";

export const LOCALE = {
  [Locale.ENGLISH]: en,
  [Locale.SPANISH]: es,
  [Locale.KOREAN]: ko,
  [Locale.FRENCH]: fr,
  [Locale.RUSSIAN]: ru,
  [Locale.CHINESE]: zh,
  [Locale.ARABIC]: ar,
};
