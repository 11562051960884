import { useTranslation } from "react-i18next";

import ChatWarningModal from "@components/ChatWarningModal/ChatWarningModal";
import { IUser } from "types/user";

interface IBlockUserModal {
  user: IUser;
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const BlockUserModal = ({ user, onClose, onSubmit, isOpen }: IBlockUserModal) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <ChatWarningModal
      title={t("blockUser.title", {
        fullName: user.fullName,
      })}
      subtitle={t("blockUser.subtitle")}
      cancelText={t("blockUser.cancelText")}
      submitText={t("blockUser.submitText")}
      close={onClose}
      submit={handleSubmit}
      open={isOpen}
    />
  );
};
