import { ChangeEvent, DragEvent, FormEvent } from "react";

import { Trans, useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";

import { DragAndDrop } from "@components/utils/DragAndDrop/DragAndDrop";

import { useInterestHelper } from "@customHooks/useInterestHelper";
import { useUploadFile } from "@customHooks/useUploadFile/useUploadFile";

import { FileHelper } from "@utils/files/fileHelper";

import CancelIcon from "@images/cancelIcon.png";
import RemoveIcon from "@images/iconRemove.png";
import SearchIcon from "@images/search_icon.svg";
import AttachmentsIcon from "@images/upload.svg";

import { SelectUpdated } from "@components/formik/SelectUpdated/SelectUpdated";
import styles from "./UploadModal.module.scss";
import { UploadModalType } from "./type";

export interface IUploadModalProps {
  open: boolean;
  close: () => void;
  addFiles: (files: Array<File>) => void;
  selectedInterests?: string[];
  multiple?: boolean;
  supportedExtensions?: Array<string>;
  handleUploadProgressWithModal?: (files: File[]) => Promise<void>;
  handleAddInterests?: (interests: string[]) => void;
  type: UploadModalType;
}

const UploadModal = ({
  open,
  close,
  addFiles,
  selectedInterests,
  handleUploadProgressWithModal,
  handleAddInterests,
  type,
  ...props
}: IUploadModalProps) => {
  const { t } = useTranslation();
  const {
    multiple,
    supportedExtensions,
    validateAndUpdateSelectedFiles,
    clearFiles,
    removeFile,
    selectedFiles,
  } = useUploadFile(props);

  const { options: interestOptions } = useInterestHelper();

  const handleSelectedFiles = async (event: ChangeEvent) => {
    validateAndUpdateSelectedFiles((event.target as HTMLInputElement).files);
  };

  const handleDropFile = (event: DragEvent) => {
    validateAndUpdateSelectedFiles(event.dataTransfer.files);
  };

  const handleSubmit = (event: FormEvent) => {
    if (!selectedFiles) return;

    if (handleUploadProgressWithModal) handleUploadProgressWithModal(selectedFiles);

    event.preventDefault();
    addFiles(selectedFiles);
    clearFiles();
    close();
  };

  const handleClose = () => {
    clearFiles();
    close();
  };

  return (
    <Modal className={styles.uploadModalWrapper} isOpen={open}>
      <img
        src={CancelIcon}
        className={styles.removeIcon}
        onClick={handleClose}
        alt=""
        aria-label="close-img"
      />
      <form onSubmit={handleSubmit} className={styles.firstContainer}>
        <span className={styles.firstTitle}>
          {type === UploadModalType.ATTACMENTS
            ? t("addAttachments.title")
            : t("addFiles.title")}
        </span>
        <DragAndDrop onDrop={handleDropFile}>
          <div className={styles.boxContainer}>
            <img className={styles.attachment} src={AttachmentsIcon} alt="" />
            <div className={styles.textContainer}>
              <Trans
                i18nKey={"addAttachments.titleDragAndDrop"}
                components={{
                  link: (
                    <label
                      htmlFor="file"
                      className={styles.link}
                      dangerouslySetInnerHTML={{ __html: t("addAttachments.browse") }}
                    />
                  ),
                }}
              />
              <input
                id="file"
                className={styles.inputFile}
                type="file"
                name="file"
                multiple={multiple}
                onChange={handleSelectedFiles}
                accept={supportedExtensions.join(",")}
                aria-label="input"
              />
            </div>
          </div>
        </DragAndDrop>

        <div className={styles.fileBoxContainer}>
          {selectedFiles.map((file, index) => (
            <div key={index} className={styles.fileContainer}>
              <span className={styles.fileName} aria-label="fileName">
                {file.name}
              </span>
              <div className={styles.sizeContainer}>
                <span className={styles.size}>
                  {FileHelper.getSizeInKB(file.size)} KB
                </span>
                <img
                  src={RemoveIcon}
                  className={styles.iconCancel}
                  onClick={() => removeFile(index)}
                  alt=""
                  aria-label="remove-icon"
                />
              </div>
            </div>
          ))}
        </div>

        {type === UploadModalType.FILES && (
          <SelectUpdated
            label={t("addFiles.interestsLabel")}
            name="tags"
            placeholder={t("event.newEvent.select.tags.placeholder")}
            options={interestOptions}
            onChangeMulti={(selectedInterests) => handleAddInterests!(selectedInterests)}
            value={selectedInterests}
            icon={SearchIcon}
            isMulti
            isSearchable
          />
        )}

        <div className={styles.buttonContainer}>
          <span className={styles.cancelButton} onClick={handleClose} aria-label="cancel">
            {t("addAttachments.cancel")}
          </span>
          <Button
            type="submit"
            disabled={!selectedFiles.length}
            title={t("addFiles.buttonTitle")}
            onClick={handleSubmit}
            aria-label="button-submit"
          />
        </div>
      </form>
    </Modal>
  );
};

export default UploadModal;
