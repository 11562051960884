/* eslint-disable react-hooks/exhaustive-deps */
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import LinkifyMessage from "@components/LinkifyMessage/LinkifyMessage";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { handleAcceptingRedirect } from "@utils/helpers";
import {
  getAllOccurrences,
  getRegexGroupWithEscapedSpecialCharacters,
  replaceWhiteSpacesWithWhiteSpace,
} from "@utils/stringHelper";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import replaceWithJSX from "react-string-replace";
import { User } from "sendbird";
import { ISearchMessage } from "types/SearchMessage";
import withUser from "../../../hoc/withUser";
import styles from "./MessageHighlighted.module.scss";

interface IMessageHighlightedProps {
  messageId: number;
  message: string;
  searchMessage?: ISearchMessage;
  mentionedUsers?: User[];
}

export const MessageHighlighted = ({
  searchMessage,
  messageId,
  message,
  mentionedUsers,
}: IMessageHighlightedProps) => {
  const messageRef = useRef<HTMLDivElement>(null);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");
  const { t } = useTranslation();
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();

  const renderMessageHighlighted = () => {
    if (
      searchMessage &&
      (searchMessage.searchQuery || searchMessage.currentSearchMessageId)
    ) {
      let numberOfOccurrence = 0;
      const highlightedMessage = replaceWithJSX(
        replaceWhiteSpacesWithWhiteSpace(message),
        getRegexGroupWithEscapedSpecialCharacters(searchMessage.searchQuery),
        (match, i) => {
          const getRef = () => {
            const isEqualToCurrentSearchMessageId =
              searchMessage.isMessageIdEqualToCurrentSearchMessageId({
                messageId,
                numberOfOccurrence,
              });

            numberOfOccurrence++;
            return isEqualToCurrentSearchMessageId ? messageRef : null;
          };

          return (
            <span
              key={i}
              ref={getRef()}
              aria-label="searchedMessage"
              className={styles.highLighted}
            >
              {match}
            </span>
          );
        }
      );

      return highlightedMessage;
    } else if (mentionedUsers && mentionedUsers.length > 0) {
      const sendbirdMentionUsers = mentionedUsers;
      const userNames = sendbirdMentionUsers
        .map((user) => user.nickname)
        .map((nickname: string) => `@${nickname}`);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let highlightedUsers = message as any;

      userNames.forEach((userName: string, i: number) => {
        highlightedUsers = replaceWithJSX(highlightedUsers, userName, (match) => {
          return (
            <span
              className={styles.mentionedOption}
              key={sendbirdMentionUsers[i].userId}
              onClick={() => {
                setSelectedUserEmail(sendbirdMentionUsers[i].userId as string);
              }}
            >
              {match}
            </span>
          );
        });
      });

      return highlightedUsers;
    } else {
      return renderLinkedMessage(message);
    }
  };

  useEffect(() => {
    if (!searchMessage?.searchQuery) return;

    const allOccurrencesOfSearchQueryInMessage = getAllOccurrences(
      replaceWhiteSpacesWithWhiteSpace(message),
      getRegexGroupWithEscapedSpecialCharacters(searchMessage.searchQuery)
    );

    if (!allOccurrencesOfSearchQueryInMessage) {
      return;
    }

    allOccurrencesOfSearchQueryInMessage.map((__, index) => {
      return searchMessage.addSearchMessageId({
        messageId,
        numberOfOccurrence: index,
      });
    });
  }, [searchMessage?.searchQuery]);

  const renderLinkedMessage = (messageToRender: string | ReactNode) => (
    <LinkifyMessage
      messageToRender={messageToRender}
      openModal={() => setIsConfirmationModalOpened(true)}
      setClickedUrl={setClickedUrl}
    />
  );

  useEffect(() => {
    const scrollToMessageWithCurrentSearchMessageId = () => {
      if (!searchMessage?.currentSearchMessageId) return;

      if (searchMessage.currentSearchMessageId.messageId === messageId) {
        messageRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    scrollToMessageWithCurrentSearchMessageId();
  }, [searchMessage?.currentSearchMessageId]);

  const UserDrawerWithUser = withUser(UserDrawer);

  return (
    <span aria-label="highlightedMessage">
      {renderLinkedMessage(renderMessageHighlighted())}
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)}
        url={clickedUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
      <UserDrawerWithUser
        email={selectedUserEmail ?? ""}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </span>
  );
};
