import Loading from "@components/Loading/Loading";
import { Pagination } from "@components/Pagination/Pagination";
import { Table as AntDTable } from "antd";
import styles from "./Table.module.scss";

interface ITableProps {
  columns: Array<Record<string, unknown>>;
  data: Array<Record<string, unknown>>;
  pageSize: number;
  pageSizeOptions?: Array<string>;
  wrapperClassName?: string;
  loadingData?: boolean;
  onClick?: (record: Record<string, unknown>) => void;
  handlePageChange: (page: number, pageSize: number) => void;
  totalNumberOfRows: number;
  currentPage: number;
  totalPagesCount: number;
}

const Table = ({
  wrapperClassName,
  columns,
  data,
  pageSize,
  pageSizeOptions,
  loadingData,
  onClick,
  handlePageChange,
  totalNumberOfRows,
  currentPage,
  totalPagesCount,
}: ITableProps) => {
  const customTableLoader = {
    spinning: loadingData,
    indicator: <Loading disableBoxShadow />,
  };

  return (
    <div className={`${wrapperClassName} ${styles.wrapper}`}>
      <AntDTable
        onRow={(record) => {
          return { onClick: () => onClick && onClick(record) };
        }}
        className={styles.table}
        rowClassName={onClick && styles.cursor}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={customTableLoader}
      />

      <Pagination
        handlePageChange={handlePageChange}
        totalNumberOfRows={totalNumberOfRows}
        currentPage={currentPage}
        totalPagesCount={totalPagesCount}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        loadingData={loadingData}
      />
    </div>
  );
};

export default Table;
