import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SlickSlider from "react-slick";

import Loading from "@components/Loading/Loading";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";
import UserCard from "@components/UserCard/UserCard";

import UsersSectionIcon from "@images/star.svg";
import { disableNext } from "@utils/helpers";

import { NO_USERS_MESSAGES } from "@constants/constants";

import { IUserDashboard } from "types/user";

import { isDocumentRtl } from "@utils/checkDocumentDir";
import styles from "./ResourcesUsersSection.module.scss";

const { noUsers, noSearchedUsers } = NO_USERS_MESSAGES;

export interface IResourcesUsersSection {
  users: Array<IUserDashboard>;
  usersCount: number;
  usersLoading: boolean;
  loadUsers: () => void;
  hideSeeAllButton?: boolean;
  customHeaderTitle?: string;
  pageSize: number;
  searchResourcesSubmitted?: boolean;
}

const ResourcesUsersSection = ({
  users,
  usersCount,
  usersLoading,
  loadUsers,
  hideSeeAllButton,
  customHeaderTitle,
  pageSize,
  searchResourcesSubmitted,
}: IResourcesUsersSection) => {
  const usersChildRef = useRef<SlickSlider>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [sliderPage, setSliderPage] = useState<number>(0);
  const [noUsersMessage, setNoUsersMessage] = useState<string>("");

  const handlePreviousUsers = () => {
    isDocumentRtl()
      ? usersChildRef.current?.slickNext()
      : usersChildRef.current?.slickPrev();
    setSliderPage(sliderPage - 1);
  };

  const handleNextUsers = () => {
    isDocumentRtl()
      ? usersChildRef.current?.slickPrev()
      : usersChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
    if (users.length >= usersCount) return;
    loadUsers();
  };

  const openAllUsers = () => {
    history.push(`/portal/resources/users`);
  };

  const disableSliderNext = () => {
    return usersLoading || disableNext(sliderPage, usersCount, pageSize);
  };

  const disableSliderPrevious = () => {
    return usersLoading || sliderPage === 0;
  };

  useEffect(() => {
    setNoUsersMessage(searchResourcesSubmitted ? noSearchedUsers : noUsers);
  }, [searchResourcesSubmitted]);

  return (
    <div
      className={styles.usersContainer}
      data-testid="rs-collection-container"
      e2e-test-id="users-container"
    >
      <div className={styles.usersHeading}>
        <div className={styles.usersHeadingWrapper}>
          {!customHeaderTitle && (
            <img className={styles.usersIcon} src={UsersSectionIcon} alt="" />
          )}
          <h3 className={styles.usersTitle}>
            {customHeaderTitle || t("resources.usersTitle")}
          </h3>
        </div>
        {users.length !== 0 && (
          <div
            className={styles.usersButtonContainer}
            data-testid="shift-buttons-container"
          >
            <ShiftButtons
              onForwardClick={handleNextUsers}
              onBackClick={handlePreviousUsers}
              disabledBack={disableSliderPrevious()}
              viewAll={openAllUsers}
              disabledForward={disableSliderNext()}
              hideSeeAllButton={hideSeeAllButton}
            />
          </div>
        )}
      </div>

      {!users.length && !usersLoading ? (
        <span className={styles.textContainer} data-testid="no-results-container">
          {t(noUsersMessage)}
        </span>
      ) : (
        <div className={styles.usersContent} data-testid="results-container">
          {usersLoading && !users.length ? (
            <div className={styles.loaderWrapper}>
              <Loading borderless disableBoxShadow e2eTestId="users-loader" />
            </div>
          ) : (
            <Slider
              forwardedRef={usersChildRef}
              items={users?.map((user) => {
                return user.fullName === "placeholder" ? (
                  <Loading />
                ) : (
                  <UserCard
                    key={user.publicId}
                    email={user.email}
                    fullName={user.fullName}
                    bio={user.plainTextBio}
                    organization={user.organization}
                    country={user.country}
                    userPhotoName={user.userPhotoName}
                    imageDirectory={user.imageDirectory}
                  />
                );
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResourcesUsersSection;
