import { gql } from "@apollo/client";

const GET_USERS = gql`
  query users($size: UInt, $skip: UInt, $text: String) {
    users(userSearch: { skip: $skip, size: $size, text: $text }) {
      totalCount
      users {
        fullName
        email
      }
    }
  }
`;
export default GET_USERS;
