import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ILinkListData } from "types/links";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import TooltipComponent from "@components/Tooltip/Tooltip";

import { handleAcceptingRedirect } from "@utils/helpers";

import Button from "@components/Button/Button";
import { useHandleLinkClick } from "@customHooks/useHandleLinkClick";
import styles from "./LinksList.module.scss";

interface IlinksList {
  isUserNotAllowed: boolean;
  linksData: ILinkListData[];
}

const LinksList = ({ isUserNotAllowed, linksData }: IlinksList) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const { externalUrl, handleLinkClick } = useHandleLinkClick({
    onExternalLinkClick: () => setIsConfirmationModalOpened(true),
  });

  const renderList = () => {
    return linksData.map((link: ILinkListData, index: number) => (
      <TooltipComponent
        title={link.title}
        tooltipType="secondary"
        placement="bottom"
        key={index}
        childToWrap={
          <p
            className={styles.link}
            style={!isUserNotAllowed ? {} : { pointerEvents: "none" }}
            onClick={() => handleLinkClick(link.url)}
          >
            {link.title}
          </p>
        }
      />
    ));
  };

  return (
    <div className={styles.sectionWrapper}>
      <h4 className={styles.sectionTitle}>{t("linksList.title")}</h4>
      {linksData.length > 0 ? (
        renderList()
      ) : (
        <Button
          title={t("linksList.hiveSupport")}
          buttonType="link"
          onClick={() => history.push("/portal/support")}
        />
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(externalUrl, setIsConfirmationModalOpened)}
        url={externalUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </div>
  );
};

export default LinksList;
