/* eslint-disable react-hooks/exhaustive-deps */
import BackButton from "@components/BackButton/BackButton";
import { ICommunity } from "types/community";
import styles from "./RecommendedCommunities.module.scss";

import { useLazyQuery } from "@apollo/client";
import { DEFAULT_COMMUNITIES_GRID_SIZE } from "@constants/constants";
import GET_COMMUNITY_RESOURCES from "@graphql/queries/communityResources";
import CommunitiesGrid from "@modules/CommunitiesGrid/CommunitiesGrid";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const RecommendedCommunities = () => {
  const { t } = useTranslation();
  const [communities, setCommunities] = useState<Array<ICommunity>>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [communitiesCount, setCommunitiesCount] = useState<number>(0);

  const [getCommunities, { loading }] = useLazyQuery(GET_COMMUNITY_RESOURCES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      initialLoading && setInitialLoading(false);
      setCommunities([...communities, ...data.communities.communities]);
      data.communities.totalCount && setCommunitiesCount(data.communities.totalCount);
    },
    onError: (error) => displayServerError(error),
  });

  useEffect(() => {
    getCommunities({
      variables: { skip: 0, size: DEFAULT_COMMUNITIES_GRID_SIZE },
    });
  }, []);

  return (
    <div className={styles.container}>
      <BackButton
        path="/portal/community/explore"
        label={t("exploreCommunities.recommendedTitle")}
        wrapperClassName={styles.contentContainer}
      />
      <CommunitiesGrid
        communities={communities}
        loadMore={() =>
          getCommunities({
            variables: {
              skip: communities.length,
              size: DEFAULT_COMMUNITIES_GRID_SIZE,
            },
          })
        }
        communitiesLoading={initialLoading}
        communitiesLoadingMore={loading}
        communitiesCount={communitiesCount}
        searchValue=""
        gridAdditionalStyles={styles.contentContainer}
      />
    </div>
  );
};

export default RecommendedCommunities;
