import axios from "axios";

const TOKEN_RENEWAL_TRESHOLD = 600;

class AuthService {
  public static setSendBirdToken(token: string) {
    return localStorage.setItem("sbToken", token);
  }

  public static getStoredSendBirdToken() {
    return localStorage.getItem("sbToken");
  }

  public static clearSendBirdToken() {
    localStorage.removeItem("sbToken");
  }

  public static setSbTokenExpiresAt(expiresAt: Date) {
    localStorage.setItem("expiresAt", new Date(expiresAt).toString());
  }

  public static getSbTokenExpiresAt() {
    return localStorage.getItem("expiresAt");
  }

  public static async getSendBirdToken(fullName: string) {
    const myToken = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/third-party-communication/SendBirdToken`,
      { params: { fullName } }
    );

    return myToken;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static shouldTokenRefresh(idTokenClaims: any) {
    const currentTimeTenDecimal = Math.floor(new Date().getTime() / 1000);
    return idTokenClaims?.exp
      ? idTokenClaims?.exp - currentTimeTenDecimal < TOKEN_RENEWAL_TRESHOLD
      : false;
  }
}

export default AuthService;
