/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import TextareaComponent from "@components/Textarea/Textarea";

import { PlusOutlined } from "@ant-design/icons";
import Avatar from "@images/avatar.svg";
import CloseMark from "@images/close_toast_black.svg";
import SearchIcon from "@images/search_icon.svg";

import FileCheckerService from "@utils/files/fileChecker";
import { fileNameReplaceRegex, toggleInputSize } from "@utils/helpers";

import {
  COLLECTION_TYPES,
  MAX_INPUT_LENGTH_MID,
  MAX_INPUT_LENGTH_SHORT,
  STORAGE_IMAGE_TYPE_USER,
} from "@constants/constants";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import { IImage } from "types/image";
import { DefaultOption } from "types/select";

import ImagePickerWithModal from "@components/ImagePickerWithModal/ImagePickerWithModal";
import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { useHandleSearch } from "@customHooks/useHandleSearch/useHandleSearch";
import { IAddibleUsersResponse, IPagination } from "@feature/pagination/type";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import { ADD_COMMUNITY_COLLECTION } from "@graphql/collection/mutation";
import { CollectionType } from "@graphql/collection/type";
import { GET_ADDIBLE_USERS_TO_NEW_COMMUNITY_COLLECTION } from "@graphql/community-collection/queries";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage from "@utils/getStorageImage";
import { ICommunity } from "types/community";
import { IUser, IUserImageResponse } from "types/user";
import styles from "./CommunityCollectionCreateNew.module.scss";

type LocationProps = {
  state: {
    community: ICommunity;
    redirect: string;
    articleId?: string;
    showModalIfAborted?: boolean;
  };
};

interface InterestsTypes {
  name: string;
}

interface CollectionTypeItem {
  id: number;
  name: string;
  isPublic: boolean;
  label: string;
  subLabel: string;
}

const CommunityCollectionCreateNew = () => {
  const { t } = useTranslation();
  const location = useLocation() as unknown as LocationProps;
  const history = useHistory();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const descriptionButtonRef = useRef<HTMLDivElement>(null);
  const [inputNameElement, setInputNameElement] = useState<HTMLInputElement | null>(null);
  const [descriptionField, setDescriptionField] = useState(false);

  const { options: interestOptions } = useInterestHelper();
  const collectionTypes = [
    {
      id: 1,
      name: COLLECTION_TYPES.PRIVATE_TYPE,
      isPublic: false,
      label: t("collections.communityCollections.admin"),
      subLabel: t("collections.communityCollections.adminDescription"),
    },
    {
      id: 2,
      name: COLLECTION_TYPES.PUBLIC_TYPE,
      isPublic: true,
      label: t("collections.communityCollections.community"),
      subLabel: t("collections.communityCollections.communityDescription"),
    },
  ];

  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedInterests, setSelectedInterests] = useState<InterestsTypes[]>([]);
  const [selectedCollectionType, setSelectedCollectionType] =
    useState<CollectionTypeItem>(collectionTypes[0]);
  const [selectedImage, setSelectedImage] = useState<IImage>({} as IImage);

  const [shouldRenderSearchedUsersList, setShouldRenderSearchedUsersList] =
    useState<boolean>(true);

  const [selectedUsers, setSelectedUsers] = useState<Array<IUser>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [selectedUsersAvatars, setSelectedUsersAvatars] = useState<
    { email: string; image: string }[]
  >([]);

  const [isCreatingCollection, setIsCreatingCollection] = useState(false);

  const { uploadFiles } = useUploadFiles();

  const [getUsers, { loading: isGettingUsers }] = useLazyQuery<
    IAddibleUsersResponse,
    IPagination
  >(GET_ADDIBLE_USERS_TO_NEW_COMMUNITY_COLLECTION, {
    onCompleted: (data) => {
      const invitableUsersWithSelectedUsers = data.addibleUsersToNewCommunityCollection
        .filter(({ email }) => !selectedUsers.some((e) => e.email === email))
        .filter((user) => user.organization);

      setSearchedUsers([...invitableUsersWithSelectedUsers]);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const { handleSearch, search, searchedUsers, setSearch, setSearchedUsers } =
    useHandleSearch<IAddibleUsersResponse, IPagination>({
      queryFn: getUsers,
    });

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionName(event.target.value);
  };
  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const [addCollection] = useMutation<{
    addCommunityCollection: { name: string; publicId: string };
  }>(ADD_COMMUNITY_COLLECTION);

  useEffect(() => {
    toggleInputSize({
      descriptionButtonRef,
      inputNameRef: { current: inputNameElement },
      descriptionField,
    });
  }, [descriptionButtonRef, inputNameElement, descriptionField]);

  const createNewCollectionHandler = async () => {
    if (collectionName === location.state.community.defaultCollection?.name) {
      displayNotSuccessNotification(
        t("collections.errorsMessages.sameNameAsDefaultCommunityCollection")
      );
      return;
    }
    if (selectedImage.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImage.img
      );
      const fileSave = await FileCheckerService.isSafe(selectedImage.img);
      if (!fileSave || !fileSize) return;
    }
    setIsCreatingCollection(true);
    // first upload image
    let imageDirectory = "";
    if (selectedImage.img) {
      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (_, directory) => {
            imageDirectory = directory;
          },
        });
      } catch (error) {
        displayNotSuccessNotification();
        return;
      }
    }
    // then create collection
    const collection = {
      communitySendbirdId: location.state.community.sendbirdId,
      name: collectionName.trim(),
      description,
      isPublic: selectedCollectionType.isPublic,
      imageName: fileNameReplaceRegex(selectedImage.name),
      interests: selectedInterests,
      collaboratorInvitees: selectedUsers.map((member) => member.email),
      imageDirectory,
    };

    try {
      const response = await addCollection({ variables: { collection } });
      displaySuccessNotification(t("collections.addNewCollection.success"));
      redirect(response.data?.addCommunityCollection.publicId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      displayCustomNotSuccessNotification(errorCode);
    } finally {
      setIsCreatingCollection(false);
    }
  };

  const cancelHandler = () => {
    const redirect = location?.state?.redirect;
    const showModalIfAborted = location.state.showModalIfAborted;
    const articleId = location.state.articleId;
    if (!articleId) {
      history.push(redirect ? redirect : "/portal/collections/my-collections");
    } else {
      history.push({
        pathname: redirect,
        state: {
          newCollection: showModalIfAborted ? articleId : undefined,
        },
      });
    }
  };

  const redirect = (newCollectionId: string | undefined) => {
    const redirect = location?.state?.redirect;
    const articleId = location.state.articleId;
    const community = location.state.community;
    if (!articleId) {
      history.push(redirect ? redirect : "/portal/collections/my-collections");
    } else {
      history.push({
        pathname: redirect,
        state: {
          newCollection: articleId,
          newCollectionId,
          collectionType: CollectionType.COMMUNITY_COLLECTION,
          community,
        },
      });
    }
  };

  useEffect(() => {
    searchedUsers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: searchedUsers.map((member) => member.email),
          },
        },
      });
  }, [searchedUsers]);

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          event.target instanceof HTMLDivElement &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          setShouldRenderSearchedUsersList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const addUser = (user: IUser, index: number, userAvatar: string) => {
    const userIds = selectedUsers.map((user) => user.email);

    if (userIds.includes(user.email)) return;

    setSelectedUsers([user, ...selectedUsers]);
    setSearch("");
    setSearchedUsers([]);

    setSelectedUsersAvatars([
      ...selectedUsersAvatars,
      { email: user.email, image: userAvatar },
    ]);

    const filteredUsers = users;
    filteredUsers.splice(index, 1);

    setUsers(filteredUsers);
  };

  const removeUser = (user: IUser, index: number) => {
    setUsers([user, ...users]);

    const filteredUsers = selectedUsers;
    filteredUsers.splice(index, 1);

    setSelectedUsers(filteredUsers);
  };

  return (
    <div className={styles.pageWrapper} data-testid="add-new-collection-container">
      <h1 className={styles.pageTitle} e2e-test-id="new-community-collection-header">
        {t("collections.communityCollections.newCommunity")}
      </h1>
      <span className={styles.pageSubTitle} e2e-test-id="community-collection-subheader">
        {t("collections.communityCollections.newCommunityDescription")}
      </span>
      <form className={styles.newCommunityForm}>
        <div className={styles.inputContainer}>
          <Input
            onChange={handleChangeName}
            name="collectionName"
            e2eTestId="community-collection-name-input-field"
            value={collectionName}
            maxLength={MAX_INPUT_LENGTH_SHORT}
            label={t("collections.addNewCollection.form.collectionNameInput")}
            aria-label="collection-name"
            placeholder={t(
              "collections.addNewCollection.form.collectionNameInputPlaceholder"
            )}
            setInputElement={setInputNameElement}
            additionalElement={
              !descriptionField && (
                <Button
                  aria-label="add-description"
                  e2eTestId="community-collection-add-description-button"
                  title={t("collections.addNewCollection.actions.addDescription")}
                  buttonIcon={<PlusOutlined />}
                  wrapperClassNames={styles.inlineInputWrapper}
                  buttonSize="small"
                  customRef={descriptionButtonRef}
                  onClick={() => {
                    setDescriptionField(true);
                  }}
                />
              )
            }
          />
        </div>

        {descriptionField && (
          <TextareaComponent
            name="description"
            label={t("collections.addNewCollection.form.descriptionInput")}
            maxLength={MAX_INPUT_LENGTH_MID}
            aria-label="description-textarea"
            onChange={handleChangeDescription}
            e2e-test-id="community-collection-description-field"
          />
        )}

        <div className={styles.selectWrapper}>
          <SelectComponent
            options={interestOptions}
            placeholder={t("collections.addNewCollection.form.tagsInputPlaceholder")}
            className={"tagsSearch"}
            label={t("collections.communityCollections.categories")}
            name={"tags"}
            e2eTestId="categories-select-container"
            showDropdown
            selectWithIcon
            icon={SearchIcon}
            isMulti
            onChange={(selectedInterestOptions: DefaultOption[]) => {
              setSelectedInterests(
                selectedInterestOptions.map(({ value }) => ({
                  name: value,
                }))
              );
            }}
            isSearchable
          />
        </div>

        <div
          className={styles.communityTypeWrapper}
          e2e-test-id="collection-type-section"
        >
          <div className={styles.communityTypeHeading}>
            {t("collections.addNewCollection.form.collectionTypeRadio")}
          </div>
          {collectionTypes.map((collectionType) => (
            <RadioButton
              aria-label="communityType"
              id={collectionType.id}
              key={collectionType.name}
              name={collectionType.name}
              label={collectionType.label}
              subLabel={collectionType.subLabel}
              checked={collectionType.name === selectedCollectionType.name}
              onChange={() => {
                setSelectedCollectionType(collectionType);
              }}
            />
          ))}
        </div>

        <div
          onClick={() => setShouldRenderSearchedUsersList(true)}
          className={styles.selectWrapper}
        >
          <InputUpdated
            label={t("collections.addNewCollection.form.collaboratorsInput")}
            placeholder={t("community.inviteModal.searchPlaceholder")}
            type="search"
            value={search}
            onChange={(event) =>
              handleSearch(event, {
                text: event.target.value,
                size: 1000,
                skip: 0,
              })
            }
            loading={isGettingUsers}
            area="sm"
            e2eTestId="search-for-members-input"
          />
          {shouldRenderSearchedUsersList && searchedUsers.length > 0 && search && (
            <div ref={wrapperRef} className={styles.usersContainer}>
              {searchedUsers.map((user, index) => {
                const userAvatar = userImages
                  ? userImages.find((image) => image.email === user.email)?.image
                  : Avatar;

                return (
                  <div
                    onClick={() => addUser(user, index, userAvatar ? userAvatar : Avatar)}
                    className={styles.option}
                    key={user.publicId}
                    aria-label="user"
                    role="listitem"
                  >
                    <div
                      style={{ backgroundImage: `url(${userAvatar})` }}
                      className={styles.avatar}
                    ></div>
                    {user.fullName}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {selectedUsers.map((user, index) => {
          const userAvatar = userImages
            ? selectedUsersAvatars.find((image) => image.email === user.email)?.image
            : Avatar;
          return (
            <div key={index} className={styles.member}>
              <div className={styles.userDetails}>
                <div
                  style={{ backgroundImage: `url(${userAvatar})` }}
                  className={styles.avatar}
                ></div>
                <div>
                  <p className={styles.name}>{user.fullName}</p>
                  <p className={styles.role}>
                    {getRoleOrganizationText(user.organization)}
                  </p>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <span>{t("collections.addNewCollection.form.collaborator")}</span>
                <TooltipComponent
                  title={t("collections.addNewCollection.form.removeCollaboratorTooltip")}
                  tooltipType="secondary"
                  placement="bottom"
                  childToWrap={
                    <img src={CloseMark} onClick={() => removeUser(user, index)} />
                  }
                />
              </div>
            </div>
          );
        })}

        <div e2e-test-id="collection-image-section">
          <ImagePickerWithModal
            title={t("collections.addNewCollection.form.communityCollectionImage")}
            description={t(
              "collections.addNewCollection.form.communityCollectionImageDescription"
            )}
            images={[]}
            pickedImage={selectedImage}
            setPickedImage={setSelectedImage}
            isUpload
            isRequired
            uploadButtonId={"selectedImage"}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            aria-label="create-collection-button"
            buttonType="primary"
            wrapperClassNames={styles.buttonWrapper}
            title={t("collections.addNewCollection.actions.create")}
            onClick={createNewCollectionHandler}
            disabled={
              !collectionName.trim().length ||
              !selectedInterests.length ||
              !selectedImage.image ||
              isCreatingCollection
            }
            loading={isCreatingCollection}
            fullWidth
            e2eTestId="create-community-collection-button"
          />
          <Button
            aria-label="cancelBtn"
            buttonType="link"
            title={t("collections.addNewCollection.actions.cancel")}
            className={styles.cancelLink}
            disabled={isCreatingCollection}
            onClick={cancelHandler}
            e2eTestId="create-community-collection-cancel-link"
          />
        </div>
      </form>
    </div>
  );
};

export default CommunityCollectionCreateNew;
