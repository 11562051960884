import { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";

import { useSelector } from "react-redux";

import { useLazyQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import { RootState } from "../../store/RootState";

import getStorageImage from "@utils/getStorageImage";
import { getDateInUtc, handleAcceptingRedirect } from "@utils/helpers";
import { redirectToChatPage } from "@utils/redirectChat";

import GET_USER_INFO_COLLECTIONS from "@graphql/queries/getUserInfoCollections";
import GET_USER_INFO_COMMUNITIES from "@graphql/queries/getUserInfoCommunities";
import GET_USER_INFO_HOSTING_EVENTS from "@graphql/queries/getUserInfoHostingEvents";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";

import {
  displayNotSuccessNotification,
  displayServerError,
} from "@services/NotificationService/NotifacitonService";

import BackButton from "@components/BackButton/BackButton";
import Drawer from "@components/Backdrop/Drawer";
import Button from "@components/Button/Button";
import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import CommunityCard from "@components/CommunityCard/CommunityCard";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import { LinkedText } from "@components/DataDisplay/LinkedText/LinkedText";
import EventCard from "@components/EventCard/EventCard";
import Loading from "@components/Loading/Loading";

import { Pronouns, PronounsToTranslationKey } from "@feature/pronouns";

import sb from "@customHooks/sendBird/SendBird";

import { ICollection } from "@graphql/collection/type";
import { ICommunity } from "types/community";
import { IEvent } from "types/event";
import { IUser, IUserImageResponse } from "types/user";
import { UserDrawerEnum, UserDrawerType } from "types/userDrawer";

import { formatDate } from "@utils/eventsHelper";

import UserDrawerCollections from "./UserDrawerCollections/UserDrawerCollections";
import UserDrawerCommunities from "./UserDrawerCommunities/UserDrawerCommunities";
import UserDrawerEvents from "./UserDrawerEvents/UserDrawerEvents";
import { getInterests } from "./helper";

import Avatar from "@images/avatar.svg";
import BlueAvatar from "@images/blue_avatar.svg";
import xIcon from "@images/close_x_icon.svg";
import LinkedinIcon from "@images/linkedin.svg";
import bluePin from "@images/locationBlue.svg";
import MessageIcon from "@images/messageUser.svg";

import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";

import styles from "./UserDrawer.module.scss";

interface IUserDrawerProps {
  closeInfoDrawer: () => void;
  user: IUser;
  communityOptions?: JSX.Element;
}

const UserDrawer = ({ closeInfoDrawer, user, communityOptions }: IUserDrawerProps) => {
  const { t } = useTranslation();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>();
  const [userEvents, setUserEvents] = useState<IEvent[]>([]);
  const [userEventsTotalCount, setUserEventsTotalCount] = useState<number>(0);

  const [userCollections, setUserCollections] = useState<ICollection[]>([]);
  const [userCollectionsTotalCount, setUserCollectionsTotalCount] = useState<number>(0);

  const [userCommunities, setUserCommunities] = useState<ICommunity[]>([]);
  const [userCommunitiesTotalCount, setUserCommunitiesTotalCount] = useState<number>(0);

  const [resourceTypeToShow, setResourceTypeToShow] = useState<UserDrawerEnum>(
    UserDrawerType.GENERAL
  );

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    fetchPolicy: "no-cache",
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo?.userPhotoName,
          directoryName: userPhotosInfo?.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  const [getUserEvents, { loading: loadingEvents }] = useLazyQuery(
    GET_USER_INFO_HOSTING_EVENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserEvents(data.userInfo.hostingEvents);
        setUserEventsTotalCount(data.userInfo.hostingEventsTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  const [getUserCollections, { loading: loadingCollections }] = useLazyQuery(
    GET_USER_INFO_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserCollections(data.userInfo.myCollections);
        setUserCollectionsTotalCount(data.userInfo.myCollectionsTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  const [getUserCommunities, { loading: loadingCommunitiesInDrawer }] = useLazyQuery(
    GET_USER_INFO_COMMUNITIES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserCommunities(data.userInfo.communities);
        setUserCommunitiesTotalCount(data.userInfo.communitiesTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  useEffect(() => {
    if (!user) return;

    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: user.email,
        },
      },
    });
    getUserEvents({
      variables: {
        email: user.email,
        hostingEventSearch: { size: 1, skip: 0, from: getDateInUtc() },
      },
    });
    getUserCollections({
      variables: { email: user.email, collectionSearch: { size: 1, skip: 0 } },
    });
    getUserCommunities({
      variables: { email: user.email, communitySearch: { size: 1, skip: 0 } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const createDirectChat = () => {
    setDisableButton(true);
    const params = new sb.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = true;
    params.isSuper = false;
    params.addUserId(user.email);
    params.operatorUserIds = [currentUserEmail];
    params.customType = "direct";
    sb.GroupChannel.createChannel(params, function (groupChannel, error) {
      if (error) {
        displayServerError(error);
        return;
      }
      setDisableButton(false);
      redirectToChatPage(groupChannel, history);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLinkedinIconClick = () => {
    setIsConfirmationModalOpened(true);
    setClickedUrl(user.userLinkedInLink as string);
  };

  const renderButtons = () => (
    <div className={styles.buttonsContainer}>
      <Button
        wrapperClassNames={styles.buttonWrapper}
        aria-label={"direct-button"}
        onClick={() => createDirectChat()}
        title={t("community.drawer.sendMessage")}
        buttonType="primary"
        disabled={disableButton}
        buttonIcon={<img className={styles.img} src={MessageIcon} alt="" />}
      />
    </div>
  );

  const checkAccount = () => {
    if (currentUserEmail !== user.email && user.canReceiveMessages) {
      return true;
    }
  };

  const userRolAndPositionExist = user.organization?.userRole && user.organization?.name;

  // render events single rows
  const renderEventRow = () => {
    const firstEvent = userEvents[0];

    return (
      <>
        <EventCard
          inUserDrawer={true}
          startDate={formatDate(firstEvent?.startDate, t.language)}
          endDate={formatDate(firstEvent?.endDate, t.language)}
          name={firstEvent?.name}
          userName={firstEvent?.eventHost?.fullName}
          event={firstEvent}
        />
        {userEventsTotalCount > 1 && (
          <Button
            aria-label="all-members-btn"
            wrapperClassNames={styles.allResourcesBtnWrapper}
            title={t("community.drawer.seeMoreEvents")}
            onClick={() => setResourceTypeToShow(UserDrawerType.EVENTS)}
          />
        )}
      </>
    );
  };

  // render collections single rows
  const renderCollectionsRow = () => {
    const firstCollection = userCollections[0];

    return (
      <>
        <CollectionsCard
          inUserDrawer={true}
          key={firstCollection.publicId}
          title={firstCollection.name}
          description={firstCollection.description}
          resourcesCount={firstCollection.articlesCount}
          collaboratorsCount={firstCollection.collaboratorsCount}
          updateTime={firstCollection.updateTime}
          publicId={firstCollection.publicId}
          customStyles={styles.customStyles}
          collectionCreator={firstCollection.collectionCreator}
          imageName={firstCollection.imageName}
          imageDirectory={firstCollection.imageDirectory}
          isPublic={firstCollection.isPublic}
        />
        {userCollectionsTotalCount > 1 && (
          <Button
            aria-label="all-members-btn"
            wrapperClassNames={styles.allResourcesBtnWrapper}
            title={t("community.drawer.seeMoreCollections")}
            onClick={() => setResourceTypeToShow(UserDrawerType.COLLECTIONS)}
          />
        )}
      </>
    );
  };

  // render communities single rows
  const renderCommunityRow = () => {
    const firstCommunity = userCommunities[0];

    return (
      <>
        <div
          aria-label="community-card"
          role="listitem"
          className={styles.communityCardInUserDrawer}
        >
          <CommunityCard
            inUserDrawer={true}
            community={firstCommunity}
            loggedUser={currentUserEmail}
          />
        </div>
        {userCommunitiesTotalCount > 1 && (
          <Button
            aria-label="all-members-btn"
            wrapperClassNames={styles.allResourcesBtnWrapper}
            title={t("community.drawer.seeMoreCommunities")}
            onClick={() => setResourceTypeToShow(UserDrawerType.COMMUNITIES)}
          />
        )}
      </>
    );
  };

  const interests = useMemo(() => getInterests(user), [user]);

  return (
    <>
      <Drawer closeInfoDrawer={closeInfoDrawer}>
        <>
          {/* general information */}
          {resourceTypeToShow === UserDrawerType.GENERAL && (
            <div className={styles.container}>
              <span className={styles.buttonText} onClick={closeInfoDrawer}>
                <img className={styles.xIcon} src={xIcon} />
                {t("community.drawer.close")}
              </span>

              <div
                style={{ backgroundImage: `url(${userImage || Avatar})` }}
                className={styles.avatarImg}
              ></div>

              <div className={styles.infoContainer}>
                <div className={styles.fullNameContainer}>
                  <h3 className={styles.titleName}>{user?.fullName}</h3>
                  {user.pronouns !== Pronouns.NONE && (
                    <div>
                      <h6 className={styles.pronouns} aria-label="userRole">
                        {t(PronounsToTranslationKey[user.pronouns])}
                      </h6>
                    </div>
                  )}
                </div>
                {communityOptions && (
                  <div className={styles.buttonsContainer}>{communityOptions}</div>
                )}
              </div>

              {userRolAndPositionExist && (
                <div className={styles.row}>
                  <img src={BlueAvatar} alt="" aria-label="avatar" />
                  <span className={styles.titleOrganization} aria-label="userRole">
                    {getRoleOrganizationText(user.organization)}
                  </span>
                </div>
              )}
              <div className={styles.locationRow}>
                <img src={bluePin} />
                <span className={styles.titleOrganization}>
                  {user.country ? user.country?.name : t("users.noLocation")}{" "}
                </span>
              </div>
              {checkAccount() && renderButtons()}
              {/* bio */}
              {user.bio && (
                <>
                  <h4 className={styles.titleInterest}>{t("tabBar.bio")}</h4>
                  <LinkedText text={user.bio} hasReadMore />
                </>
              )}

              {interests.some(({ value }) => value) && (
                <div className={styles.container}>
                  <h4 className={styles.interestsTitle}>
                    {t("accountSettings.interestsAndSocialMedia.label.interests")}
                  </h4>
                  <div className={styles.interests}>
                    {interests.map(
                      ({ label, value }) =>
                        value && (
                          <p key={value}>
                            {t(label)}
                            {value}
                          </p>
                        )
                    )}
                  </div>
                </div>
              )}

              {user.userLinkedInLink && (
                <div className={styles.container}>
                  <h4>
                    {t("accountSettings.interestsAndSocialMedia.label.socialMedia")}
                  </h4>
                  <img
                    src={LinkedinIcon}
                    className={styles.linkedinIcon}
                    onClick={handleLinkedinIconClick}
                  />
                </div>
              )}

              {/* events */}
              {loadingEvents ? (
                <Loading disableBoxShadow />
              ) : (
                <>
                  {!!userEvents.length && (
                    <>
                      <h4 className={styles.titleInterest}>
                        {t("navbar.navigationItems.events")}
                      </h4>
                      <div className={styles.userInterests}>{renderEventRow()}</div>
                    </>
                  )}
                </>
              )}
              {/* collections */}
              {loadingCollections ? (
                <Loading disableBoxShadow />
              ) : (
                <>
                  {!!userCollections.length && (
                    <>
                      <h4 className={styles.titleInterest}>
                        {t("navbar.navigationItems.collections")}
                      </h4>
                      <div className={styles.userInterests}>{renderCollectionsRow()}</div>
                    </>
                  )}
                </>
              )}
              {/* communities */}
              {loadingCommunitiesInDrawer ? (
                <Loading disableBoxShadow />
              ) : (
                <>
                  {!!userCommunities.length && (
                    <>
                      <h4 className={styles.titleInterest}>
                        {t("navbar.navigationItems.community")}
                      </h4>
                      <div className={styles.userInterests}>{renderCommunityRow()}</div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {/* show all events */}
          {resourceTypeToShow === UserDrawerType.EVENTS && (
            <div className={styles.container}>
              <BackButton onClick={() => setResourceTypeToShow(UserDrawerType.GENERAL)} />

              <h4 className={styles.titleInterest}>
                {t("navbar.navigationItems.events")}
              </h4>
              <div className={styles.userInterests}>
                {user && <UserDrawerEvents userEmail={user.email} />}
              </div>
            </div>
          )}
          {/* show all collections */}
          {resourceTypeToShow === UserDrawerType.COLLECTIONS && (
            <div className={styles.container}>
              <BackButton onClick={() => setResourceTypeToShow(UserDrawerType.GENERAL)} />

              <h4 className={styles.titleInterest}>
                {t("navbar.navigationItems.collections")}
              </h4>
              <div className={styles.userInterests}>
                {user && <UserDrawerCollections userEmail={user.email} />}
              </div>
            </div>
          )}
          {/* show all communities */}
          {resourceTypeToShow === UserDrawerType.COMMUNITIES && (
            <div className={styles.container}>
              <BackButton onClick={() => setResourceTypeToShow(UserDrawerType.GENERAL)} />

              <h4 className={styles.titleInterest}>
                {t("navbar.navigationItems.community")}
              </h4>
              <div className={styles.userInterests}>
                {user && (
                  <UserDrawerCommunities
                    userEmail={user.email}
                    currentUserEmail={currentUserEmail}
                  />
                )}
              </div>
            </div>
          )}
        </>
      </Drawer>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)}
        url={clickedUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};

export default UserDrawer;
