/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../store/RootState";

import Button from "@components/Button/Button";
import CommunityCard from "@components/CommunityCard/CommunityCard";
import Loading from "@components/Loading/Loading";
import {
  COMMUNITIES_PAGINATION,
  INITIAL_PAGINATION,
  RECOMMENDED_COMMUNITIES_EMPTY_SEARCH,
} from "@constants/constants";
import { GET_RECOMMENDED_USER_COMMUNITIES } from "@graphql/community/queries";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { ICommunity } from "types/community";
import styles from "./Recommended.module.scss";

interface RecommendedProps {
  interests: string[];
}

const Recommended = ({ interests }: RecommendedProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [recommendedCommunities, setRecommendedCommunities] = useState<ICommunity[]>([]);
  const [recommendedCommunitiesCount, setRecommendedCommunitiesCount] = useState(0);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (userRecommendedLoader) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !userRecommendedLoader) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      handleLoadMoreCommunities();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const handleLoadMoreCommunities = () => {
    getUserRecommendedCommunities({
      variables: {
        communitySearch: {
          size: COMMUNITIES_PAGINATION,
          skip: recommendedCommunities.length,
          text: RECOMMENDED_COMMUNITIES_EMPTY_SEARCH,
          interests,
        },
      },
    });
  };

  const [getUserRecommendedCommunities, { loading: userRecommendedLoader }] =
    useLazyQuery(GET_RECOMMENDED_USER_COMMUNITIES, {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        setRecommendedCommunities([
          ...recommendedCommunities,
          ...res.communities.communities,
        ]);
        setRecommendedCommunitiesCount(res.communities.totalCount);
        setIsLoaded(true);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  useEffect(() => {
    setRecommendedCommunities([]);
    getUserRecommendedCommunities({
      variables: {
        communitySearch: {
          size: COMMUNITIES_PAGINATION,
          skip: INITIAL_PAGINATION,
          text: RECOMMENDED_COMMUNITIES_EMPTY_SEARCH,
          interests,
        },
      },
    });
  }, []);

  return (
    <>
      <div className={styles.recommendedForYouHeader}>
        <div className={styles.recommendedForYouFirstRow}>
          <span className={styles.sectionTitle} e2e-test-id="recommended-for-you-header">
            {t("community.recommendedForYou")}
          </span>
          <Button
            title={t("community.viewMore")}
            e2eTestId="view-more-link"
            buttonType="link"
            wrapperClassNames={styles.communityLinkButtons}
            onClick={() => {
              history.push("/portal/community/recommended");
            }}
          />
        </div>
        <span
          className={styles.rightSectionSubtitle}
          e2e-test-id="recommended-for-you-sub-text"
        >
          {t("community.recommendedForYouSubtitle")}
        </span>
      </div>
      {isLoaded ? (
        <div
          className={styles.scrollableContainer}
          e2e-test-id="recommended-communities-grid"
        >
          <div className={styles.scrollableDiv}>
            {!!recommendedCommunities.length && (
              <>
                <div className={styles.gridContainer}>
                  {recommendedCommunities.map((community: ICommunity) => (
                    <div
                      key={community.sendbirdId}
                      aria-label="community-card"
                      role="listitem"
                    >
                      <CommunityCard
                        community={community}
                        loggedUser={currentUserEmail}
                      />
                    </div>
                  ))}
                </div>

                {recommendedCommunitiesCount > recommendedCommunities.length && (
                  <Button
                    title={t("collections.buttons.viewMore")}
                    wrapperClassNames={styles.loadMoreButton}
                    buttonIcon={<PlusOutlined />}
                    onClick={handleLoadMoreCommunities}
                    loading={userRecommendedLoader}
                    disabled={userRecommendedLoader}
                    customRef={loadMoreElementRef}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loading disableBoxShadow e2eTestId="recommended-for-you-loader" />
      )}
    </>
  );
};

export default Recommended;
