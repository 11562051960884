/* eslint-disable prettier/prettier */
import SearchIcon from "@images/search_icon.svg";
import { usePredefinedClassIfFound } from "@utils/classNamesHelpers";
import React, { CSSProperties } from "react";
import styles from "./Input.module.scss";

export interface IInputProps {
  label?: string;
  isRequired?: boolean;
  name: string;
  type?: string;
  className?: string;
  placeholder?: string;
  inputClassNames?: string;
  wrapperClassNames?: string;
  labelClassNames?: string;
  styles?: CSSProperties;
  value?: string;
  readOnly?: boolean;
  required?: boolean;
  danger?: boolean;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  additionalElement?: React.ReactNode;
  maxLength?: number;
  pattern?: string;
  "aria-label"?: string;
  disabled?: boolean;
  setInputElement?: (element: HTMLInputElement) => void;
  customValidation?: boolean;
  e2eTestId?: string;
}

function Input({
  wrapperClassNames,
  labelClassNames,
  inputClassNames,
  additionalElement,
  e2eTestId,
  pattern,
  danger,
  customValidation,
  setInputElement,
  isRequired,
  ...props
}: IInputProps) {
  const isInputValid = (value: string) => {
    if (!pattern) return true;
    return !!value.match(pattern);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (customValidation && !isInputValid(e.target.value)) {
      return;
    }
    props.onChange?.(e);
  };

  const searchField: boolean = props.type === "search";
  const additionalElementsDefined = additionalElement || null;
  return (
    <div
      className={`${styles.inputWrapper} ${usePredefinedClassIfFound(
        wrapperClassNames,
        styles
      )}`}
    >
      <label
        className={`${styles.inputLabel} ${usePredefinedClassIfFound(
          labelClassNames,
          styles
        )}`}
        htmlFor={props.name}
      >
        {props.label} {isRequired && "*"}
      </label>
      <input
        {...props}
        autoComplete="off"
        e2e-test-id={e2eTestId}
        onChange={onChange}
        ref={(element) => {
          if (element) {
            setInputElement?.(element);
          }
        }}
        className={[
          props.className,
          styles.inputField,
          danger && styles.inputErrorlabel && styles.removeFocus,
          usePredefinedClassIfFound(inputClassNames, styles),
          searchField && styles.inputWithIcon,
        ].join(" ")}
        type={props.type || "text"}
        disabled={props.disabled}
      />
      {additionalElementsDefined && additionalElement}
      {searchField && (
        <img className={styles.inputIcon} src={SearchIcon} alt={props.label} />
      )}
    </div>
  );
}

export default Input;
