import { useTranslation } from "react-i18next";

import {
  ButtonUpdated,
  IButtonUpdatedProps,
} from "@components/ButtonUpdated/ButtonUpdated";

import { ReactComponent as CheckMark } from "@images/checkMark.svg";
import { ReactComponent as CloseMark } from "@images/closeMark.svg";

import styles from "./Button.module.scss";

interface IButtonProps {
  buttonSubmit: Omit<IButtonUpdatedProps, "title">;
  buttonDecline: Omit<IButtonUpdatedProps, "title">;
}

export const Buttons = ({ buttonSubmit, buttonDecline }: IButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buttons}>
      <ButtonUpdated
        aria-label="accept request"
        title={t("requests.requestCard.accept")}
        buttonIcon={<CheckMark />}
        buttonType="primary"
        {...buttonSubmit}
      />
      <ButtonUpdated
        aria-label="decline request"
        title={t("requests.requestCard.decline")}
        buttonIcon={<CloseMark />}
        {...buttonDecline}
      />
    </div>
  );
};
