import HeroLogo from "@images/hero-logo.png";
import { useState } from "react";

import HeroBee from "@images/hero-bee.png";
import RightBg from "@images/hero-right.png";
import WhoBlack from "@images/hero-who-black.png";
import Play from "@images/play.svg";

import Loading from "@components/Loading/Loading";
import Modal from "@components/Modal/Modal";
import styles from "./SectionOne.module.scss";

const SectionOne = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>();

  return (
    <>
      <div className={styles.sectionWrapper}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={WhoBlack} />
        </div>
        <img className={styles.rightBg} src={RightBg} />
        <img className={styles.heroBee} src={HeroBee} />
        <div className={styles.textWrapper}>
          <h4 className={styles.welcomeText}>Welcome to</h4>
          <img src={HeroLogo} />
          <p className={styles.primaryText}>
            a digital space for health emergency preparedness and response
          </p>
          <p className={styles.secondaryText}>powered by communities</p>
          <button
            title="play now"
            className={styles.playBtn}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <img src={Play} />
            Play video
          </button>
        </div>
      </div>
      <Modal
        className={styles.modal}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        {isVideoLoading && (
          <div className={styles.loadingWrapper}>
            <Loading borderless />
          </div>
        )}
        <video
          autoPlay
          src={process.env.REACT_APP_LANDING_PAGE_VIDEO}
          className={styles.video}
          controls
          disablePictureInPicture
          controlsList="nodownload"
          onLoadedData={() => setIsVideoLoading(false)}
          onLoadStart={() => setIsVideoLoading(true)}
        />
      </Modal>
    </>
  );
};

export default SectionOne;
