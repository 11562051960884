/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import BackButton from "@components/BackButton/BackButton";
import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_EVENTS from "@graphql/queries/events";
import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEvent, IEventsResponse } from "types/event";
import styles from "./PopularEvents.module.scss";

export interface IPopularEventsProps {
  submitValue: string;
}

const PopularEvents = ({ submitValue }: IPopularEventsProps) => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [firstEventId, setFirstEventId] = useState<string>("");
  const [prevPage, setPrevPage] = useState<string | null>("");

  const [getEvents] = useLazyQuery<IEventsResponse>(GET_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ events: eventsData }) => {
      initialLoading && setInitialLoading(false);
      if (!eventsData) return;
      setEvents([...events, ...eventsData.events]);
      setEventsCount(eventsData.totalCount);
      loadingMore && setLoadingMore(false);
      eventsData.events.length && setFirstEventId(eventsData.events[0].publicId);
    },
    onError: () => {
      setInitialLoading(false);
      displayServerError();
    },
  });

  const loadEvents = () => {
    !initialLoading && setLoadingMore(true);
    getEvents({
      variables: {
        size: EVENTS_GRID_SIZE,
        skip: events?.length,
        from: getDateInUtc(),
      },
    });
  };

  useEffect(() => {
    setPrevPage(localStorage.getItem("pageUrl"));
    loadEvents();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.backButtonContainer}>
        {prevPage?.includes("/portal/resources") ? (
          <BackButton path="/portal/resources" label={t("resources.backToResult")} />
        ) : (
          <BackButton path="/portal/events/explore" label={t("events.backToEvents")} />
        )}
      </div>
      <div className={styles.heading}>
        <ResourcesHeading
          sectionTitle={t("resources.events")}
          resultsCount={eventsCount}
          searchValue={submitValue}
        />
      </div>
      {submitValue && (
        <span className={styles.subHeading}>
          {t("events.relatedTo", { submitValue })}
        </span>
      )}
      <div className={styles.eventsContainer}>
        <EventsGrid
          events={events}
          loadMore={loadEvents}
          eventsLoading={initialLoading}
          eventsCount={eventsCount}
          eventsLoadingMore={loadingMore}
          firstItemId={firstEventId}
        />
      </div>
    </div>
  );
};

export default PopularEvents;
