import { CloseCircleFilled } from "@ant-design/icons";
import Button from "@components/Button/Button";
import { DefaultOption } from "types/select";
import styles from "./Pill.module.scss";

interface IPillProps {
  label: string;
  value: string;
  alternativeColor?: boolean;
  onClick?: (option: DefaultOption) => void;
  selected?: boolean;
  disabledPillClick?: boolean;
  showCloseButton?: boolean;
  ariaLabel?: string;
}

const Pill = ({
  label,
  value,
  onClick,
  selected,
  showCloseButton,
  disabledPillClick,
  alternativeColor,
  ariaLabel,
}: IPillProps) => {
  const getClassName = () => {
    let className = `${styles.pill} ${alternativeColor ? styles.alternativeColor : ""}`;

    if (showCloseButton) {
      className = `${className} ${styles.close}`;
    }

    if (selected) {
      className = `${className} ${styles.selected}`;
    }

    if (showCloseButton) {
      className = `${className} ${styles.withCloseButton}`;
    }

    if (disabledPillClick) {
      className = `${className} ${styles.disabled}`;
    }

    return className;
  };

  return (
    <div
      className={getClassName()}
      onClick={() =>
        !showCloseButton && !disabledPillClick && onClick?.({ value, label })
      }
      key={label}
      aria-label={ariaLabel}
    >
      {label}
      {showCloseButton && (
        <Button
          title=""
          onClick={() => onClick?.({ value, label })}
          wrapperClassNames={styles.closeButtonWrapper}
          buttonIcon={<CloseCircleFilled className={styles.closeIcon} />}
          buttonShape="round"
        />
      )}
    </div>
  );
};

export default Pill;
