import { useMemo } from "react";

import { ModeToClassName } from "./constant";
import { mode } from "./type";

import styles from "./Badge.module.scss";

interface IBadgeProps {
  count: number;
  maxCount?: number;
  children: React.ReactNode;
  mode?: mode;
}

export const Badge = ({ count, maxCount, children, mode = "primary" }: IBadgeProps) => {
  const text = useMemo(() => {
    let newText = `${count}`;
    if (maxCount && maxCount > count) {
      newText = `${newText}+`;
    }

    return newText;
  }, [count, maxCount]);

  return (
    <div className={styles.container}>
      {children}
      {count > 0 && (
        <div className={`${styles.badge} ${ModeToClassName[mode]}`}>
          <span className={styles.text}>{text}</span>
        </div>
      )}
    </div>
  );
};
