/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import { REACTION_TYPE } from "@constants/constants";
import REACT_ON_ARTICLE_COMMENT from "@graphql/mutations/reactOnArticleComment";
import REMOVE_REACTION_FROM_ARTICLE_COMMENT from "@graphql/mutations/removeReactionFromArticleComment";
import thumbUpActive from "@images/thumbUpActive.svg";
import thumbUpInactive from "@images/thumbUpInactive.svg";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComment } from "types/comment";

import styles from "./ArticleCommentReactions.module.scss";

interface IArticleCommentReactions {
  item: IComment;
}

const ArticleCommentReactions = ({ item }: IArticleCommentReactions) => {
  const { t } = useTranslation();
  const [reactionCounter, setReactionCounter] = useState<number>(item.reactionCount);
  const [isLoggedUserReacted, setIsUserReacted] = useState<boolean>(
    item.isLoggedUserReactedOnArticleComment
  );
  const [reactOnArticleComment] = useMutation(REACT_ON_ARTICLE_COMMENT, {
    variables: {
      articleCommentPublicId: item.publicId,
      reactionType: REACTION_TYPE,
    },
    onCompleted: (data) => {
      setReactionCounter(data.reactOnArticleComment.reactionCount);
      setIsUserReacted(true);
    },
    onError: () => {
      displayNotSuccessNotification();
    },
  });

  const [removeRectionFromArticleComment] = useMutation(
    REMOVE_REACTION_FROM_ARTICLE_COMMENT,
    {
      variables: {
        articleCommentPublicId: item.publicId,
      },
      onCompleted: (data) => {
        setReactionCounter(data.removeReactionFromArticleComment.reactionCount);
        setIsUserReacted(false);
      },
      onError: () => {
        displayNotSuccessNotification();
      },
    }
  );

  const reactionText = (reactionCounter: number, isLoggedUserReacted: boolean) => {
    if (!reactionCounter) return "";
    if (!isLoggedUserReacted && reactionCounter === 1)
      return t("singleArticle.commentSection.reactions.oneLikeAndNotYour");
    if (!isLoggedUserReacted && reactionCounter > 1)
      return t("singleArticle.commentSection.reactions.moreThanOneLikeAndNotYour", {
        reactionCounter,
      });
    if (reactionCounter === 1)
      return t("singleArticle.commentSection.reactions.oneLikeWithYour");
    if (reactionCounter === 2)
      return t("singleArticle.commentSection.reactions.twoLikesWithYour");
    if (reactionCounter > 2)
      return t("singleArticle.commentSection.reactions.moreThanTwoLikesWithYour", {
        reactionCounter,
      });
  };

  useEffect(() => {
    reactionText(reactionCounter, isLoggedUserReacted);
  }, [reactionCounter]);

  const handleReactionToggle = (isLoggedUserReacted: boolean) => {
    isLoggedUserReacted ? reactOnArticleComment() : removeRectionFromArticleComment();
  };

  return (
    <div aria-label="articleCommentReactions" className={styles.container}>
      <Button
        aria-label="toggleReactionsButton"
        title={""}
        wrapperClassNames={styles.reactionButtonStyle}
        buttonIcon={
          <img src={isLoggedUserReacted ? thumbUpActive : thumbUpInactive} alt="" />
        }
        buttonType="link"
        onClick={() => {
          handleReactionToggle(!isLoggedUserReacted);
        }}
      />
      <p
        className={
          isLoggedUserReacted ? styles.reactionTextStyleLiked : styles.reactionTextStyle
        }
      >
        {reactionText(reactionCounter, isLoggedUserReacted)}
      </p>
    </div>
  );
};

export default ArticleCommentReactions;
