import { CLEAR_SEARCH_TERM, SAVE_SEARCH_TERM } from "./constants";
import { EventState } from "./types";
import { AnyAction } from "redux";

const initialState: EventState = {
  searchTerm: "",
};

const searchTermReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload,
      };
    case CLEAR_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload,
      };
    default:
      return state;
  }
};

export default searchTermReducer;
