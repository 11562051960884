/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import withUser from "../../../hoc/withUser";
import UserDrawer from "../../UserDrawer/UserDrawer";
// components
import Button from "@components/Button/Button";
import InviteUserModal from "@components/InviteUserModal/InviteUserModal";
import Loading from "@components/Loading/Loading";
import Select from "@components/Select/Select";
import Table from "@components/Table/Table";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { Badge, Divider } from "antd";
import {
  initialState as PAGINATION_INITIAL_STATE,
  reducer as paginationReducer,
  setCurrentPage,
  setPageSize,
  setSearch,
  setTotalCount,
} from "../../../components/Pagination/paginationReducer";

// constants
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
// queries
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import GET_USER_APPLICATIONS_ROLE from "@graphql/queries/userApplicationRole";
import GET_USERS from "@graphql/queries/usersExtended";
// mutations
import DEMOTE_ADMIN from "@graphql/mutations/demoteAdmin";
import PROMOTE_USER from "@graphql/mutations/promoteUser";
// types
import getStorageImage from "@utils/getStorageImage";
import {
  formatDateForTableRow,
  getUserBadgeColor,
  getUserStatusText,
} from "@utils/helpers";
import { Option } from "types/select";
import {
  ISearchedUser,
  ISearchedUserResponse,
  IUser,
  IUserImageResponse,
  IUserResponse,
  TableUser,
} from "types/user";
// images and css
import { IOptions } from "@components/AddToCollectionModal/AddToCollectionModal";
import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import { useDebounce } from "@customHooks/uesDebounce/useDebounce";
import Avatar from "@images/avatar.svg";
import SearchIcon from "@images/search_icon.svg";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { Options } from "./Options/Options";
import styles from "./Users.module.scss";

const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const [openInviteUserModal, setOpenInviteUserModal] = useState<boolean>(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [tableData, setTableData] = useState<Array<TableUser>>([]);
  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [filters, setFilters] = useState<string[]>([]);
  const queryInputRef = useRef<HTMLInputElement | null>(null);
  const [
    { totalCount, currentPage, pageSize, totalPagesCount, skip, search },
    paginationDispatch,
  ] = useReducer(paginationReducer, PAGINATION_INITIAL_STATE);

  useEffect(() => {
    getUserApplicationRole();
  }, []);

  // component queries
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const [getUsers, { loading: isGettingUsers, refetch }] =
    useLazyQuery<ISearchedUserResponse>(GET_USERS, {
      onCompleted: ({ users: { users, totalCount } }) => {
        const data = users.map(
          ({
            fullName,
            email,
            isActive,
            isOnboarded,
            canReceiveMessages,
            memberSince,
            isTemporary,
            role,
          }: ISearchedUser) => ({
            user: {
              isActive,
              name: fullName,
              email,
              status: isActive,
              isOnboarded,
              isTemporary,
            },
            memberSince: formatDateForTableRow(memberSince),
            email,
            role,
            canReceiveMessages,
          })
        );
        getUserApplicationRole();
        getUserImages({
          variables: {
            profileImagesInput: {
              userEmails: users.map((user) => user.email),
            },
          },
        });
        setTableData(data);
        paginationDispatch(setTotalCount(totalCount));
      },
      onError: (error) => displayServerError(error),
      fetchPolicy: "cache-and-network",
    });
  const debouncer = useRef(
    _.debounce(() => paginationDispatch(setSearch(queryInputRef.current?.value)), 1000)
  );

  const variables = useMemo(
    () => ({
      size: pageSize,
      skip,
      text: search,
      includeUsersWithChatPrivacy: true,
      includeMyself: true,
      userRoles: filters,
    }),
    [currentPage, pageSize, skip, search, filters]
  );
  useDebounce({
    queryFn: getUsers,
    variables,
  });

  const [getUserApplicationRole, { loading: loadingRole }] = useLazyQuery<IUserResponse>(
    GET_USER_APPLICATIONS_ROLE,
    {
      onCompleted: (data) => {
        setLoggedInUserRole(data.user.role);
      },
      onError: (error) => displayServerError(error),
      fetchPolicy: "cache-and-network",
    }
  );

  // component mutations
  const [promoteUser] = useMutation(PROMOTE_USER, {
    onCompleted: () => {
      displaySuccessNotification(t("applicationRoles.userPromoted"));
      refetch();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const [demoteAdmin] = useMutation(DEMOTE_ADMIN, {
    onCompleted: () => {
      displaySuccessNotification(t("applicationRoles.userDemoted"));
      refetch();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const handleUserSelected = (user: {
    name: string;
    email: string;
    status: boolean;
    isOnboarded: boolean;
  }) => {
    setSelectedUserEmail(user.email);
  };

  const roleFilters = [
    { value: "HIVE Global Admin", label: t("adminPanel.users.roleFilters.globalAdmin") },
    { value: "Community Admin", label: t("adminPanel.users.roleFilters.admin") },
    { value: "User", label: t("adminPanel.users.roleFilters.user") },
  ];

  const tableColumns = [
    {
      title: t("adminPanel.users.table.user"),
      dataIndex: "user",
      key: "user",
      width: 450,
      render: (user: {
        isTemporary: boolean;
        name: string;
        email: string;
        status: boolean;
        isOnboarded: boolean;
        isActive: boolean;
      }) => {
        const profileImg = userImages
          ? userImages.find((img) => img.email === user.email)?.image
          : Avatar;

        return (
          <div data-testid="user-row" className={styles.userRow}>
            <div className={styles.user}>
              <TooltipComponent
                title={getUserStatusText(user.status, user.isOnboarded)}
                tooltipType="secondary"
                childToWrap={
                  <Badge
                    dot={true}
                    offset={[-35, 35]}
                    color={getUserBadgeColor(user.status, user.isOnboarded)}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${profileImg})`,
                        pointerEvents: user.isOnboarded ? "auto" : "none",
                      }}
                      onClick={() => handleUserSelected(user)}
                      className={styles.avatar}
                      aria-label="AvatarIcon"
                    ></div>
                  </Badge>
                }
              />

              <div
                className={styles.userInfoContainer}
                style={user.isOnboarded ? {} : { pointerEvents: "none" }}
                onClick={() => handleUserSelected(user)}
              >
                <span className={styles.nameValue}>{user.name}</span>
                <span className={styles.emailValue}>{user.email}</span>
              </div>
            </div>

            <div>
              <Options {...user} />
            </div>
          </div>
        );
      },
    },
    {
      title: t("adminPanel.users.table.memberSince"),
      dataIndex: "memberSince",
      key: "memberSince",
    },
    {
      title: t("adminPanel.users.table.privacySettings.privacySettings"),
      dataIndex: "canReceiveMessages",
      key: "canReceiveMessages",
      render: (canReceiveMessages: boolean | null) => {
        let privacySettings;

        if (canReceiveMessages) {
          privacySettings = t("adminPanel.users.table.privacySettings.public");
        }
        if (!canReceiveMessages) {
          privacySettings = t("adminPanel.users.table.privacySettings.private");
        }
        if (canReceiveMessages === null) {
          privacySettings = "";
        }

        return <span>{privacySettings}</span>;
      },
    },
    {
      title: t("adminPanel.users.table.role.role"),
      dataIndex: "role",
      key: "role",
      // Future implementation
      render: (role: string, row: IUser) => {
        const currentUserEmail = row.email;
        const userString = t("applicationRoles.memberSameForAllLang");
        const adminString = t("applicationRoles.adminSameForAllLang");
        const globalAdminString = t("applicationRoles.globalAdminSameForAllLang");
        let roles: Option[] = [];

        const defaultRoles = [
          {
            value: "1",
            label: userString,
          },
          {
            value: "2",
            label: adminString,
          },
          {
            value: "3",
            label: globalAdminString,
          },
        ];

        const userRole = defaultRoles.find((opt) => opt.label === role);

        if (
          // case when logged In User Role is global admin or admin and user from table is global admin
          (loggedInUserRole === globalAdminString || loggedInUserRole === adminString) &&
          role === globalAdminString
        ) {
          roles = [
            {
              value: "3",
              label: globalAdminString,
            },
          ];
        } else if (
          // case when logged In User Role is global and user from table is admin or user
          loggedInUserRole === globalAdminString &&
          role !== globalAdminString
        ) {
          roles = [
            {
              value: "1",
              label: userString,
            },
            {
              value: "2",
              label: adminString,
            },
          ];
        } else if (
          // case when both are admin
          loggedInUserRole === adminString &&
          role === adminString
        ) {
          roles = [
            {
              value: "2",
              label: adminString,
            },
          ];
        } else {
          // case when logged In User Role is admin and user from table is user
          roles = [
            {
              value: "1",
              label: userString,
            },
            {
              value: "2",
              label: adminString,
            },
          ];
        }
        return (
          <Select
            label=""
            placeholder=""
            name={"Role"}
            options={roles}
            value={userRole}
            showDropdown={true}
            isDisabled={roles.length < 2 ? true : false}
            onChange={(option: Option) => {
              if (role === option.label) return;
              const user = {
                email: currentUserEmail,
              };
              option.label === "Community Admin"
                ? promoteUser({
                    variables: {
                      user,
                    },
                  })
                : demoteAdmin({
                    variables: {
                      user,
                    },
                  });
            }}
          />
        );
      },
    },
  ];

  const handlePageChange = (newPage: number, newPageSize: number) => {
    if (newPage !== currentPage) {
      paginationDispatch(setCurrentPage(newPage));
    }

    if (newPageSize !== pageSize) {
      paginationDispatch(setPageSize(newPageSize));
    }
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  const isLoading = isGettingUsers || loadingRole;

  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.statusHeader}>
          <Button
            className={styles.allUsersButton}
            title={t("adminPanel.users.allUsersButton")}
          />
        </div>

        <Divider className={styles.statusHeaderDivider} />

        <div data-testid="searchForm" className={styles.filters}>
          <InputUpdated
            type="search"
            name="userSearch"
            aria-label="searchUser"
            onChange={({ target: { value } }) => value.length < 51 && debouncer.current()}
            ref={queryInputRef}
            placeholder={t("adminPanel.users.searchPlaceholder")}
            loading={isGettingUsers}
          />
          <div className={styles.right}>
            <Select
              placeholder={t("adminPanel.users.roleFilters.selectPlaceholder")}
              name={"roleFilters"}
              options={roleFilters}
              showDropdown
              onChange={(options: IOptions[]) => {
                setFilters(options.map((filter) => filter.value));
                paginationDispatch(setCurrentPage(1));
              }}
              isMulti
              isSearchable
              selectWithIcon
              icon={SearchIcon}
              className={styles.select}
            />
            <Button
              buttonType="primary"
              buttonSize="large"
              title={t("adminPanel.users.inviteUserModal.inviteUser")}
              onClick={() => setOpenInviteUserModal(true)}
              wrapperClassNames={styles.buttonWrapper}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading disableBoxShadow />
      ) : (
        <Table
          columns={tableColumns}
          data={tableData}
          totalNumberOfRows={totalCount}
          loadingData={isGettingUsers && loadingRole}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPagesCount={totalPagesCount}
          pageSize={pageSize}
        />
      )}
      <InviteUserModal
        open={openInviteUserModal}
        close={() => setOpenInviteUserModal(false)}
      />
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </>
  );
};

export default Users;
