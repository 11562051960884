import { ADD, CLEAR, REMOVE, SET_BREADCRUMB } from "./constants";
import { IBreadcrumb } from "./types";

// Actions
export const addCrumb = (crumb: IBreadcrumb) => ({
  type: ADD,
  payload: crumb,
});

export const removeCrumb = (crumb: string) => ({
  type: REMOVE,
  payload: { crumb },
});

export const clearBreadCrumbs = () => ({
  type: CLEAR,
  payload: [],
});

export const setBreadcrumb = (crumbs: IBreadcrumb[]) => ({
  type: SET_BREADCRUMB,
  payload: crumbs,
});

// Interfaces
export interface IAddCrumb {
  type: typeof ADD;
  payload: IBreadcrumb;
}
export interface IRemoveCrumb {
  type: typeof REMOVE;
  payload: { id: string };
}

export interface IClearBreadcrumbs {
  type: typeof CLEAR;
  payload: [];
}

export interface ISetBradcrumb {
  type: typeof SET_BREADCRUMB;
  payload: IBreadcrumb[];
}

// Action types
export type ActionTypes = IAddCrumb | IRemoveCrumb | IClearBreadcrumbs | ISetBradcrumb;
