import { gql } from "@apollo/client";

const UPDATE_PLATFORM_STATISTICS = gql`
  mutation updatePlatformStatistics(
    $platformStatisticsUpdate: UpdatePlatformStatisticsInputType!
  ) {
    updatePlatformStatistics(platformStatisticsUpdate: $platformStatisticsUpdate) {
      membersCountriesCount
      availableLanguagesCount
      viewsCount
    }
  }
`;

export default UPDATE_PLATFORM_STATISTICS;
