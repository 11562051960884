import { useLazyQuery } from "@apollo/client";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Language } from "@feature/language";
import { PublicRoutes } from "@feature/routes/components/PublicRoutes/PublicRoutes";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import { setDocumentDir } from "@utils/setDocumentDir";
import { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "./App.scss";
import WrapAppInsights from "./AppInsights";
import { AntDLocaleProvider } from "./customContext/locale/antD/context";
import GET_USER from "./graphql/queries/users";
import AuthComponent from "./modules/Auth/Auth";
import CodeOfConduct from "./modules/CodeOfConduct/CodeOfConduct";
import InviteOnly from "./modules/InviteOnly/InviteOnly";
import Onboarding from "./modules/Onboarding/Onboarding";
import Portal from "./modules/Portal/Portal";
import TermsAndConditions from "./modules/TermsAndConditions/TermsAndConditions";
import { IUser, IUserResponse } from "./types/user";
import ToastNotification from "@components/ToastNotification/ToastNotification";

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [userData, setUserData] = useState<IUser>();
  const [getUser] = useLazyQuery<IUserResponse>(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setUserData(data.user);
      setDocumentDir(data.user.defaultLanguage as Language);

      if (process.env.REACT_APP_GOOGLE_TRACKING_ID) {
        ReactGA.initialize([
          {
            trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
            gaOptions: {
              userId: data.user.publicId,
            },
          },
        ]);
      }
      setInitialized(true);
    },
  });

  // 7 days in miliseconds
  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  const now = new Date().getTime();
  const setupTimeForClearingTimeZones = localStorage.getItem(
    "setupTimeForClearingTimeZones"
  ) as number | null;

  useEffect(() => {
    if (localStorage.getItem("loggedInUser") === "true") {
      getUser();
      if (location.pathname === "/welcome") {
        history.push("/portal/resources");
      }
    }

    if (!setupTimeForClearingTimeZones) {
      localStorage.setItem("setupTimeForClearingTimeZones", JSON.stringify(now));
    } else {
      if (now - setupTimeForClearingTimeZones > oneWeek) {
        localStorage.removeItem("timeZones");
        localStorage.setItem("setupTimeForClearingTimeZones", JSON.stringify(now));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [initialized, location]);

  return (
    <div className="App">
      <ToastNotification />
      <AuthenticatedTemplate>
        <Suspense fallback="loading">
          <AuthComponent>
            <AntDLocaleProvider>
              <Switch>
                <Route path="/portal">
                  <Portal />
                </Route>
                <Route path="/invite-only">
                  <InviteOnly />
                </Route>
                <Route path="/terms-and-conditions">
                  <TermsAndConditions isOnboarded={!!userData?.isOnboarded} />
                </Route>
                <Route path="/code-of-conduct">
                  <CodeOfConduct isOnboarded={!!userData?.isOnboarded} />
                </Route>
                <Route path="/onboarding">
                  <Onboarding user={userData} />
                </Route>
                <Route component={PageNotFound} />
              </Switch>
            </AntDLocaleProvider>
          </AuthComponent>
        </Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Suspense fallback="loading">
          <PublicRoutes />
        </Suspense>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default WrapAppInsights(App);
