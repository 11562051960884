/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import SendBird, { FileMessage, GroupChannel, SendBirdError } from "sendbird";

import { useCommunityHelper } from "../../useCommunityHelper";
import { MAX_MESSAGES_LIMIT } from "../constant";
import { useSendBirdContext } from "../context/context";

import { SendBirdMessageHelper } from "../SendBirdHelper";
import { CHANNEL_HANDLER_ID } from "./constant";
import { ISendBirdMessageHelper, MessageType, SendChannelMessage } from "./type";

const handleSendBirdMessageError = (error?: SendBirdError, onError?: () => void) => {
  displayNotSuccessNotification(error);
  onError?.();
};

interface IUseSendBirdMessageProps {
  groupChannel?: GroupChannel;
}

export const useSendBirdMessage = ({
  groupChannel,
}: IUseSendBirdMessageProps): ISendBirdMessageHelper => {
  const { sb } = useSendBirdContext();
  const {
    communityActions: { setSendBirdId: setSendBirdIdCommunity },
    communityHelpers: { isAbleToManageUser },
  } = useCommunityHelper();
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [allFileMessages, setAllFileMessages] = useState<FileMessage[]>();

  console.log(messages);

  const loadChannelMessages = useCallback(
    (limit = MAX_MESSAGES_LIMIT) => {
      if (!groupChannel) return;

      const listQuery = groupChannel.createPreviousMessageListQuery();
      listQuery.limit = limit;
      listQuery.replyType = "all";
      listQuery.includeParentMessageInfo = true;
      listQuery.includeReactions = true;

      listQuery.load((messages, error) => {
        if (error) {
          displayNotSuccessNotification(error);
          return;
        }

        setMessages(messages);
      });
    },
    [groupChannel]
  );

  const loadAllChannelFileMessages = useCallback(() => {
    if (!groupChannel) return;

    const listQuery = groupChannel.createPreviousMessageListQuery();
    listQuery.replyType = "all";
    listQuery.includeParentMessageInfo = true;

    listQuery.load((messages, error) => {
      if (error) {
        setAllFileMessages([]);
        displayNotSuccessNotification(error);
        return;
      }

      setAllFileMessages(
        messages.filter((message) => message.isFileMessage()) as FileMessage[]
      );
    });
  }, [groupChannel]);

  useEffect(() => {
    if (!groupChannel) return;

    loadChannelMessages();
    loadAllChannelFileMessages();
  }, [groupChannel, loadAllChannelFileMessages, loadChannelMessages]);

  const getMessageById = useCallback(
    (id: number) => messages.find(({ messageId }: MessageType) => messageId === id),
    [messages]
  );

  const deleteChannelMessage = useCallback(
    (id: number) => {
      if (!groupChannel) return;

      const messageToDelete = getMessageById(id);
      if (!messageToDelete || messageToDelete.isAdminMessage())
        return handleSendBirdMessageError();

      const onMessageDeleted = (__: unknown, error: SendBird.SendBirdError) => {
        if (error) {
          const errorCode = error.code.toString();
          displayCustomNotSuccessNotification(errorCode);
        }

        setMessages((messages) => [
          ...messages.filter(({ messageId }) => messageId !== id),
        ]);
      };

      if (messageToDelete.isUserMessage()) {
        groupChannel.deleteMessage(
          messageToDelete as SendBird.UserMessage,
          onMessageDeleted
        );
        return;
      }

      groupChannel.deleteMessage(
        messageToDelete as SendBird.FileMessage,
        onMessageDeleted
      );
    },
    [getMessageById, groupChannel]
  );

  const sendChannelMessage: SendChannelMessage = useCallback(
    (messageParams, onSuccess, onError) => {
      if (!groupChannel) return;

      const onMessageSent = (message: MessageType, error: SendBird.SendBirdError) => {
        if (error) return handleSendBirdMessageError(error, onError);

        onSuccess?.(message);
        setMessages((messages) => [...messages, message]);
      };

      if (messageParams instanceof SendBird.getInstance().UserMessageParams) {
        groupChannel.sendUserMessage(messageParams, onMessageSent);
        return;
      }

      groupChannel.sendFileMessage(messageParams, onMessageSent);
    },
    [groupChannel]
  );

  const isChannelMessageDeletable = useCallback(
    (currentUserEmail: string, messageEmail: string) => {
      if (!currentUserEmail) return false;

      return messageEmail === currentUserEmail || isAbleToManageUser(messageEmail);
    },
    [isAbleToManageUser]
  );

  const resetChatHistory = useCallback(() => {
    if (!groupChannel) return;

    groupChannel.resetMyHistory().then(() => {
      setMessages([]);
    });
  }, [groupChannel]);

  useEffect(() => {
    if (!sb) return;
    const channelHandler = new sb.ChannelHandler();
    sb.addChannelHandler(CHANNEL_HANDLER_ID, channelHandler);

    channelHandler.onMessageReceived = (channel, message) => {
      if (groupChannel?.url === channel.url) setMessages((prev) => [...prev, message]);
    };

    channelHandler.onMessageDeleted = (channel) => {
      if (groupChannel?.url === channel.url) loadChannelMessages();
    };

    channelHandler.onReactionUpdated = (channel, reactionEvent) => {
      if (groupChannel?.url !== channel.url) return;

      const messagesUpdated = SendBirdMessageHelper.updateMessagesReaction(
        messages,
        reactionEvent
      );
      if (messagesUpdated) {
        setMessages([...messagesUpdated]);
      }
    };

    return () => {
      sb.removeChannelHandler(CHANNEL_HANDLER_ID);
    };
  }, [sb, groupChannel, loadChannelMessages, messages]);

  const sendBirdMessageHelper = useMemo(() => {
    return {
      messageActions: {
        loadChannelMessages,
        sendChannelMessage,
        deleteChannelMessage,
        isChannelMessageDeletable,
        setSendBirdIdCommunity,
        setMessages,
        resetChatHistory,
      },
      messageState: {
        messages,
        allFileMessages,
      },
      messageHelpers: {
        isChannelMessageDeletable,
      },
    };
  }, [
    allFileMessages,
    deleteChannelMessage,
    isChannelMessageDeletable,
    loadChannelMessages,
    messages,
    resetChatHistory,
    sendChannelMessage,
    setSendBirdIdCommunity,
  ]);

  return sendBirdMessageHelper;
};
