/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import SendBird from "sendbird";

import BackButton from "@components/BackButton/BackButton";
import Loading from "@components/Loading/Loading";
import RequestCard from "@components/RequestCard/RequestCard";
import GET_REQUESTS from "@graphql/queries/joinRequests";
import ProfileRequest from "@images/ProfileO.svg";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

import { IJoinRequest } from "types/joinRequest";

import globalStyles from "../../styles/Loader.module.scss";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import styles from "./RequestsPage.module.scss";

const RequestsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [getRequests] = useLazyQuery(GET_REQUESTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ joinRequests }) => {
      setRequestList(joinRequests);
      setLoading(false);
    },
    onError: (error) => displayServerError(error),
  });
  const params = useParams<{ id: string }>();
  const { sb } = useSendBirdContext();
  const [groupChannel, setGroupChannel] = useState<SendBird.GroupChannel>();
  const [requestList, setRequestList] = useState<Array<IJoinRequest>>([]);
  const { t } = useTranslation();

  const removeFromRequest = (userId: string) => {
    const newList = requestList.filter((request) => {
      return request.user.email !== userId;
    });
    setRequestList(newList);
  };

  useEffect(() => {
    if (!sb) return;
    sb.GroupChannel.getChannel(params.id, function (groupChannel, error) {
      if (error) {
        displayServerError(error);
        return;
      }
      getRequests({ variables: { sendbirdId: params.id } });
      setGroupChannel(groupChannel);
    });
  }, [sb]);

  return (
    <div className={styles.container}>
      <BackButton
        path={`/portal/community/community-page/${params.id}`}
        label={t("requests.noRequest.community")}
      />
      <div className={styles.titleContainer}>
        <span className={styles.title}>{t("requests.noRequest.requests")} •</span>
        <span className={styles.communityName}>{groupChannel?.name}</span>
      </div>

      {loading && (
        <div className={globalStyles.loader}>
          <Loading borderless disableBoxShadow />
        </div>
      )}

      {requestList.length !== 0 && !loading && (
        <div className={styles.requestsContainer}>
          {requestList?.map((request: IJoinRequest) => {
            return (
              <div key={request.user.email}>
                <RequestCard
                  onClick={(id: string) => removeFromRequest(id)}
                  community={groupChannel}
                  user={request.user}
                />
              </div>
            );
          })}
        </div>
      )}

      {!requestList.length && !loading && (
        <div className={styles.noRequestContainer}>
          <img className={styles.img} src={ProfileRequest} />
          <h3 className={styles.noRequestTitle}>{t("requests.noRequest.title")}</h3>
          <span className={styles.noRequestSubtitle}>
            {t("requests.noRequest.subtitlePart1")}
          </span>
          <span className={styles.noRequestSubtitle}>
            {t("requests.noRequest.subtitlePart2")}
          </span>
        </div>
      )}
    </div>
  );
};

export default RequestsPage;
