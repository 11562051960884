import { FieldMetaProps } from "formik";

import styles from "../form.style.module.scss";

interface IErrorBox<T> {
  meta?: FieldMetaProps<T>;
  isErrorDisplayed?: boolean;
}

export const ErrorBox = <T,>({ meta, isErrorDisplayed }: IErrorBox<T>) => {
  const isDisplayed = meta?.error && meta?.touched;

  return (
    <div className={styles.errorBox}>
      {isDisplayed && isErrorDisplayed && (
        <span className={styles.error}>{meta.error}</span>
      )}
    </div>
  );
};
