import { gql } from "@apollo/client";

const GET_EVENTS = gql`
  query events(
    $size: UInt
    $skip: UInt
    $text: String
    $interests: [String]
    $from: DateTime
  ) {
    events(
      eventSearch: {
        size: $size
        skip: $skip
        text: $text
        interests: $interests
        from: $from
      }
    ) {
      totalCount
      events {
        name
        description
        hostingId
        publicId
        startDate
        eventType
        endDate
        eventImageName
        imageDirectory
        eventHost {
          fullName
          email
        }
      }
    }
  }
`;

export default GET_EVENTS;
