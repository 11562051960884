import CollectionImage1 from "../../images/collections-baner-1.svg";
import CollectionImage2 from "../../images/collections-baner-2.svg";
import CollectionImage3 from "../../images/collections-baner-3.svg";

import {
  DEFAULT_COLLECTION_IMAGE_NAME_1,
  DEFAULT_COLLECTION_IMAGE_NAME_2,
  DEFAULT_COLLECTION_IMAGE_NAME_3,
} from "../../utils/getStorageImage";

export const defaultImages = [
  {
    name: DEFAULT_COLLECTION_IMAGE_NAME_1,
    label: "banner1",
    image: CollectionImage1,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: DEFAULT_COLLECTION_IMAGE_NAME_2,
    label: "banner2",
    image: CollectionImage2,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: DEFAULT_COLLECTION_IMAGE_NAME_3,
    label: "banner3",
    image: CollectionImage3,
    removeIcon: false,
    checkButton: true,
  },
];
