import { ChatPrivacy } from "@modules/AccountSettings/SettingsProfileDetails/constants";

import { IForm } from "./type";

export const initialValues: IForm = {
  workLocation: "",
  bio: "",
  organizationName: "",
  organizationRole: "",
  sector: "",
  chat: ChatPrivacy.PUBLIC,
};
