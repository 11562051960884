import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { TooltipPlacement } from "antd/lib/tooltip";

import Popover from "@components/Popover/Popover";

import { IOption } from "./types";

import DotsImage from "@images/dotsTwo.svg";

import styles from "./Menu.module.scss";

interface MenuProps {
  options?: IOption[];
  placement?: TooltipPlacement;
}

const Menu = ({
  options,
  children = <img src={DotsImage} />,
  placement = "bottom",
}: PropsWithChildren<MenuProps>) => {
  const [menuState, setMenuState] = useState(false);
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (event: Event) => {
    if (event.target instanceof Element) {
      event.target.contains(containerRef.current) && setMenuState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler, true);
    return () => document.removeEventListener("scroll", scrollHandler, true);
  }, []);

  const menu = (
    <div className={styles.menuItemContainer}>
      {options?.map(({ isDisplayed, ...menuItem }) => {
        return (
          (isDisplayed || isDisplayed === undefined) && (
            <div className={styles.menuItemWrapper} key={menuItem.label}>
              <div
                className={menuItem.isDelete ? styles.menuItemDelete : styles.menuItem}
                e2e-test-id={menuItem.e2eTestId}
                role="menuitem"
                onClick={() => {
                  menuItem.action();
                  setMenuState(false);
                }}
              >
                {t(`resources.menuItems.${menuItem.label}`)}
              </div>
            </div>
          )
        );
      })}
    </div>
  );

  return (
    <>
      {!!options?.length && (
        <div
          className={styles.dotsImage}
          aria-label="settings-button"
          e2e-test-id="three-dots-menu"
          ref={containerRef}
          onClick={(e) => {
            e.stopPropagation();
            setMenuState(!menuState);
          }}
        >
          <Popover
            menu={menu}
            className={styles.collectionSettingsPopup}
            placement={placement}
            menuState={menuState}
            setMenuState={(state) => setMenuState(state)}
            childToWrap={children}
          />
        </div>
      )}
    </>
  );
};

export default Menu;
