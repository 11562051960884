import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";

interface FolderNameFormProps {
  initialValue: string;
  onSubmit: (name: string) => void;
}

export const FolderNameForm = ({ initialValue, onSubmit }: FolderNameFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: initialValue,
    },
    validationSchema: Yup.object({ name: Yup.string().min(2).max(50).required() }),
    onSubmit: (values) => {
      onSubmit(values.name);
    },
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <input
        name="name"
        aria-label="folder name"
        type="text"
        defaultValue={values.name}
        maxLength={50}
        onClick={(event) => event.stopPropagation()}
        onChange={handleChange}
        ref={inputRef}
      />
    </form>
  );
};
