import { useLazyQuery } from "@apollo/client";
import getStorageImage from "@utils/getStorageImage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import GET_USER_INFO from "@graphql/queries/getUserInfo";

import UserDrawer from "@modules/UserDrawer/UserDrawer";
import UpcomingCommunityEventCard from "./UpcomingCommunityEventCard/UpcomingCommunityEventCard";

import { IEvent } from "types/event";
import { IImage } from "types/image";
import { IUser } from "types/user";

import { STORAGE_IMAGE_TYPE_EVENT } from "@constants/constants";

import styles from "./UpcomingCommunityEvents.module.scss";
import { useNoEventsPlaceholderData } from "./useNoEventsPlaceholderData";

interface IUpcomingCommunityEvents {
  isUserNotAllowed: boolean;
  events: IEvent[];
}

const UpcomingCommunityEvents = ({
  isUserNotAllowed,
  events,
}: IUpcomingCommunityEvents) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { NO_EVENTS_PLACEHOLDER_DATA } = useNoEventsPlaceholderData();

  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [getUser] = useLazyQuery(GET_USER_INFO, {
    onCompleted: (response) => {
      setSelectedUser(response.userInfo);
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  useEffect(() => {
    if (selectedUserEmail) {
      getUser({ variables: { email: selectedUserEmail } });
    } else {
      setSelectedUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserEmail]);

  const renderImage = (
    eventImageName: string | undefined,
    imageDirectory: string | undefined
  ) => {
    if (typeof imageDirectory !== "undefined" && typeof eventImageName !== "undefined") {
      const eventImage: IImage = {
        name: eventImageName,
        label: "Uploaded image",
        image: getStorageImage({
          image: eventImageName,
          directoryName: imageDirectory,
          type: STORAGE_IMAGE_TYPE_EVENT,
        }),
        removeIcon: true,
        checkButton: true,
      };

      return eventImage.image;
    }
  };

  const handleUserSelected = (userMail: string) => {
    setSelectedUserEmail(userMail);
  };

  const renderUpcomingCommunityEventCards = (events: IEvent[]) => {
    const openEventPage = (publicId: string) => {
      history.push(`/portal/event/${publicId}`);
    };
    return events.map((event: IEvent) => (
      <UpcomingCommunityEventCard
        isUserNotAllowed={isUserNotAllowed}
        event={event}
        openEventPage={openEventPage}
        renderImage={renderImage}
        handleUserSelected={handleUserSelected}
      />
    ));
  };

  return (
    <div className={styles.sectionWrapper}>
      <h4 className={styles.sectionTitle}>{t("community.upcomingEvents.title")}</h4>
      {events.length > 0 ? (
        renderUpcomingCommunityEventCards(events)
      ) : (
        <UpcomingCommunityEventCard
          event={NO_EVENTS_PLACEHOLDER_DATA}
          isUserNotAllowed={isUserNotAllowed}
        />
      )}
      {selectedUser && (
        <UserDrawer
          user={selectedUser}
          closeInfoDrawer={() => setSelectedUserEmail("")}
          communityOptions={<></>}
        />
      )}
    </div>
  );
};

export default UpcomingCommunityEvents;
