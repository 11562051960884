import { useField } from "formik";

import { ErrorBox } from "../ErrorBox/ErrorBox";
import { ISelectUpdatedProps, SelectUpdated } from "../SelectUpdated/SelectUpdated";

interface IInputSelectProps<T> extends ISelectUpdatedProps<T> {
  isErrorDisplayed?: boolean;
}

export const InputSelect = <T,>({ isErrorDisplayed, ...props }: IInputSelectProps<T>) => {
  const [field, meta, helpers] = useField<T>({ name: props.name });

  return (
    <div>
      <SelectUpdated
        {...props}
        onBlur={() => helpers.setTouched(true)}
        name={field.name}
        value={field.value}
        onChange={(value) => {
          props.onChange?.(value);
          helpers.setValue(value);
        }}
      />
      <ErrorBox meta={meta} isErrorDisplayed={isErrorDisplayed} />
    </div>
  );
};
