import i18n from "i18next";

export const getCustomNotificationByCode = (code: string) => {
  let message = "";

  switch (code) {
    case "1003":
      message = i18n.t("collections.errorsMessages.collectionNameUsed");
      break;
    case "1004":
      message = i18n.t("collections.errorsMessages.articleDoesntExist");
      break;
    case "1005":
      message = i18n.t("collections.errorsMessages.articleAlreadyInCollection");
      break;
    case "1006":
      message = i18n.t("collections.errorsMessages.articleIsNotAPartOfCollection");
      break;
    case "1007":
      message = i18n.t("collections.errorsMessages.creatorCollaboratorCantFollow");
      break;
    case "1009":
      message = i18n.t(
        "collections.errorsMessages.userNotAllowedToAddCommunityCollection"
      );
      break;
    case "1010":
      message = i18n.t(
        "collections.errorsMessages.onlyCommunityMembersCanBeCollaborators"
      );
      break;
    case "1011":
      message = i18n.t("collections.errorsMessages.sameNameAsDefaultCommunityCollection");
      break;
    case "1500":
      message = i18n.t("notifications.error.emailExists");
      break;
    case "1502":
      message = i18n.t("notifications.error.workLocationMandatory");
      break;
    case "1503":
      message = i18n.t("notifications.error.emailAlreadyExists");
      break;
    case "1505":
      message = i18n.t("notifications.error.userAlreadyDeactivated");
      break;
    case "1506":
      message = i18n.t("notifications.error.userAlreadyActivated");
      break;
    case "2001":
      message = i18n.t("notifications.error.noJoinInvitation");
      break;
    case "2500":
      message = i18n.t("collections.singleCollection.folderExistsOrNotSufficientRights");
      break;
    case "2503":
      message = i18n.t(
        "collections.errorsMessages.articleAlreadyInFolderOrNotSufficientRights"
      );
      break;
    case "2508":
      message = i18n.t("collections.singleCollection.folderAlreadyInFolder");
      break;
    case "2509":
      message = i18n.t("collections.singleCollection.folderAlreadyInCollection");
      break;
    case "2512":
      message = i18n.t("collections.singleCollection.folderWithSameNameAlreadyExists");
      break;
    case "3000":
      message = i18n.t("collections.singleCollection.fileExistsOrNotSufficientRights");
      break;
    case "3009":
      message = i18n.t("collections.singleCollection.fileAlreadyInFolder");
      break;
    case "3010":
      message = i18n.t("collections.singleCollection.fileAlreadyInCollection");
      break;
    case "3011":
      message = i18n.t("collections.singleCollection.fileWithSameNameAlreadyExists");
      break;
    case "5501":
      message = i18n.t("notifications.error.generateTokenFailed");
      break;
    case "5502":
      message = i18n.t("notifications.error.inviteUserFailed");
      break;
    case "6001":
      message = i18n.t("notifications.error.noSlots");
      break;
    case "6002":
      message = i18n.t("collections.errorsMessages.noPermissionsToAdd");
      break;
    case "6003":
      message = i18n.t("events.errorsMessages.editingEventFormatNotAllowed ");
      break;
    case "400920":
      message = i18n.t("notifications.error.deleteReplyFirst");
      break;
    case "1504":
      message = i18n.t("notifications.error.userAlreadyAttendsEvent");
      break;
    case "6005":
      message = i18n.t("notifications.error.editingOpenToPublicEventTypeNotAllowed");
      break;
    case "6006":
      message = i18n.t(
        "notifications.error.generatingParticipantListIsAllowedForPastEvents"
      );
      break;
    case "6007":
      message = i18n.t(
        "notifications.error.generatingParticipantListIsNotAllowedForExternalEvents"
      );
      break;
    default:
      message = "";
  }

  return message;
};

export const arrayLengthSame = (
  firstArray: Array<string> | string,
  secondArray: Array<string> | string
) => firstArray.length === secondArray.length;

export const arraysHaveSameValues = (
  firstArray: Array<string>,
  secondArray: Array<string>
) => firstArray.sort().join(",") === secondArray.sort().join(",");

export const objectsHaveSameValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firstObject: { [key: string]: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  secondObject: { [key: string]: any }
) =>
  Object.keys(secondObject).reduce((acc: boolean, key: string) => {
    if (!acc) return acc;
    acc = firstObject[key] === secondObject[key];
    return acc;
  }, true);
