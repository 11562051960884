import MessageIcon from "@images/Message.svg";
import styles from "./DiscussionStartMessage.module.scss";

interface IDiscussionStartMessage {
  message: string;
  text: string;
}

const DiscussionStartMessage = ({ message, text }: IDiscussionStartMessage) => {
  return (
    <div className={styles.container}>
      <div className={styles.detailsContainer}>
        <img src={MessageIcon} />
        <span className={styles.message}>{message}</span>
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
};

export default DiscussionStartMessage;
