import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface INavigateProps {
  path: string;
}

export const Navigate = ({ path }: INavigateProps) => {
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.replace(path), []);

  return <></>;
};
