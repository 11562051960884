import { useTranslation } from "react-i18next";

export const useNoEventsPlaceholderData = () => {
  const { t } = useTranslation();

  const NO_EVENTS_PLACEHOLDER_DATA = {
    name: t("community.upcomingEvents.noEvents"),
    startDate: new Date(),
    endDate: new Date(),
    hostingId: 0,
    publicId: "",
    description: "",
    eventHost: {
      fullName: t("community.upcomingEvents.exploreEvents"),
      email: "",
      __typeName: "",
    },
    attachments: [],
    attachmentsDirectory: "",
  };

  return { NO_EVENTS_PLACEHOLDER_DATA };
};
