import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UserDrawer from "@modules/UserDrawer/UserDrawer";

import { IUser, IUserImageResponse } from "types/user";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import GET_USER_INFO from "@graphql/queries/getUserInfo";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

import getStorageImage from "@utils/getStorageImage";

import { INotification } from "@graphql/notification/types";

// general part for notifications
import { GeneralNotificationPart } from "./GeneralNotificationPart/GeneralNotificationPart";

// request notifications
import { AcceptOrDeclineCollectionCollaborationInvitation } from "./AcceptOrDeclineCollectionCollaborationInvitation/AcceptOrDeclineCollaborationInvitation";
import { AdminInvitedToCommunity } from "./AdminInvitedToCommunity/AdminInvitedToCommunity";
import { JoinPrivateCommunityRequested } from "./JoinPrivateCommunityRequested/JoinPrivateCommunityRequested";
import { MemberInvitedToCommunity } from "./MemberInvitedToCommunity/MemberInvitedToCommunity";

import {
  ADMIN_INVITED_TO_COMMUNITY,
  ADMIN_IN_COMMUNITY_DEMOTED,
  BANNED_FROM_COMMUNITY,
  COMMUNITY_CREATOR_CHANGED,
  COMMUNITY_NAME_UPDATED,
  COMMUNITY_PRIVACY_UPDATED,
  CONTRIBUTOR_REMOVED_FROM_COLLECTION,
  DELETED_COMMUNITY,
  DELETED_EVENT,
  EVENT_ATTACHMENTS_UPDATED,
  EVENT_END_TIME_UPDATED,
  EVENT_LINKED_TO_COMMUNITY,
  EVENT_NAME_UPDATED,
  EVENT_PRIVACY_UPDATED,
  EVENT_RECORDINGS_SAVED,
  EVENT_START_REMINDER,
  EVENT_START_TIME_UPDATED,
  FEEDBACK_ADDED,
  JOIN_PRIVATE_COMMUNITY_ACCEPTED,
  JOIN_PRIVATE_COMMUNITY_DECLINED,
  JOIN_PRIVATE_COMMUNITY_REQUESTED,
  MEMBER_INVITED_TO_COMMUNITY,
  MEMBER_IN_COMMUNITY_PROMOTED,
  REMOVED_FROM_COMMUNITY,
  USER_ACCEPTED_COMMUNITY_JOIN_INVITATION,
  USER_ACCEPTED_INVITATION_TO_BE_COLLECTION_COLLABORATOR,
  USER_ADDED_AS_COMMUNITY_COLLECTION_COLLABORATOR,
  USER_ADDED_AS_COMMUNITY_MEMBER_FOR_COMMUNITY_CREATOR_OR_ADMIN,
  USER_ADDED_AS_COMMUNITY_MEMBER_FOR_USER,
  USER_DECLINED_COMMUNITY_JOIN_INVITATION,
  USER_DECLINED_INVITATION_TO_BE_COLLECTION_COLLABORATOR,
  USER_INVITED_AS_COLLECTION_COLLABORATOR,
} from "./constants";

import Avatar from "@images/avatar.svg";

import { ADMIN_PANEL_ROUTES } from "@utils/routes/adminPanel";
import { CommunityType } from "types/community";
import styles from "./NotificationCard.module.scss";

interface INotificationCardProps {
  notification: INotification;
  notificationPageWrapper?: boolean;
}

export const NotificationCard = ({
  notification,
  notificationPageWrapper,
}: INotificationCardProps) => {
  const { t } = useTranslation();
  const notificationData = JSON.parse(notification.data);
  const creationTime = new Date(notification.creationTime).getTime();
  const [userImage, setUserImage] = useState<string>();
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const isJoinPrivateCommunityAccepted =
    notification.notificationType === JOIN_PRIVATE_COMMUNITY_ACCEPTED;
  const isJoinPrivateCommunityDeclined =
    notification.notificationType === JOIN_PRIVATE_COMMUNITY_DECLINED;
  const isCommunityNameUpdated = notification.notificationType === COMMUNITY_NAME_UPDATED;
  const isCommunityPrivacyUpdated =
    notification.notificationType === COMMUNITY_PRIVACY_UPDATED;
  const isRemovedFromCommunity = notification.notificationType === REMOVED_FROM_COMMUNITY;
  const isBannedFromCommunity = notification.notificationType === BANNED_FROM_COMMUNITY;
  const isMemberInCommunityPromoted =
    notification.notificationType === MEMBER_IN_COMMUNITY_PROMOTED;
  const isAdminInCommunityDemoted =
    notification.notificationType === ADMIN_IN_COMMUNITY_DEMOTED;
  const isCommunityDeleted = notification.notificationType === DELETED_COMMUNITY;
  const isUserAcceptedCommunityJoinInvitation =
    notification.notificationType === USER_ACCEPTED_COMMUNITY_JOIN_INVITATION;
  const isUserDeclinedCommunityJoinInvitation =
    notification.notificationType === USER_DECLINED_COMMUNITY_JOIN_INVITATION;
  const isEventStartingReminder = notification.notificationType === EVENT_START_REMINDER;
  const isEventRecordingsSaved = notification.notificationType === EVENT_RECORDINGS_SAVED;
  const isEventNameUpdated = notification.notificationType === EVENT_NAME_UPDATED;
  const isEventStartTimeUpdated =
    notification.notificationType === EVENT_START_TIME_UPDATED;
  const isEventEndTimeUpdated = notification.notificationType === EVENT_END_TIME_UPDATED;
  const isEventPrivacyUpdated = notification.notificationType === EVENT_PRIVACY_UPDATED;
  const isEventLinkedToCommunity =
    notification.notificationType === EVENT_LINKED_TO_COMMUNITY;
  const isEventAttachmentsUpdated =
    notification.notificationType === EVENT_ATTACHMENTS_UPDATED;
  const isEventDeleted = notification.notificationType === DELETED_EVENT;
  const isUserAcceptedInvitationToBeCollectionCollaborator =
    notification.notificationType ===
    USER_ACCEPTED_INVITATION_TO_BE_COLLECTION_COLLABORATOR;
  const isUserDeclinedInvitationToBeCollectionCollaborator =
    notification.notificationType ===
    USER_DECLINED_INVITATION_TO_BE_COLLECTION_COLLABORATOR;
  const isContributorRemovedFromCollection =
    notification.notificationType === CONTRIBUTOR_REMOVED_FROM_COLLECTION;
  const isUserAddedAsCommunityCollectionCollaborator =
    notification.notificationType === USER_ADDED_AS_COMMUNITY_COLLECTION_COLLABORATOR;
  const isAdminInvitedToCommunity =
    notification.notificationType === ADMIN_INVITED_TO_COMMUNITY;
  const isJoinPrivateCommunityRequested =
    notification.notificationType === JOIN_PRIVATE_COMMUNITY_REQUESTED;
  const isMemberInvitedToCommunity =
    notification.notificationType === MEMBER_INVITED_TO_COMMUNITY;
  const isAcceptOrDeclineCollectionCollaborationInvitation =
    notification.notificationType === USER_INVITED_AS_COLLECTION_COLLABORATOR;
  const isUserAddedAsCommunityMemberForUser =
    notification.notificationType === USER_ADDED_AS_COMMUNITY_MEMBER_FOR_USER;
  const isUserAddedAsCommunityMemberForCommunityCreatorOrAdmin =
    notification.notificationType ===
    USER_ADDED_AS_COMMUNITY_MEMBER_FOR_COMMUNITY_CREATOR_OR_ADMIN;
  const isCommunityCreatorChanged =
    notification.notificationType === COMMUNITY_CREATOR_CHANGED;
  const isFeedbackAdded = notification.notificationType === FEEDBACK_ADDED;

  // user image
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo?.userPhotoName,
          directoryName: userPhotosInfo?.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  useEffect(() => {
    notificationData &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: notificationData?.UserEmail,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  // getUser
  const [getUser] = useLazyQuery(GET_USER_INFO, {
    onCompleted: (response) => {
      setSelectedUser(response.userInfo);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  useEffect(() => {
    if (selectedUserEmail) {
      getUser({ variables: { email: selectedUserEmail } });
    } else {
      setSelectedUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserEmail]);

  const handleUserSelected = (event: MouseEvent) => {
    event.stopPropagation();
    setSelectedUserEmail(notificationData?.UserEmail ?? "");
  };

  // notification image
  const renderUserImage = () => (
    <div className={styles.user}>
      <div className={styles.avatarWrapper} onClick={handleUserSelected}>
        <div
          aria-label="avatar"
          style={{ backgroundImage: `url(${userImage || Avatar})` }}
          className={styles.avatar}
        ></div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`${styles.info} ${
          notificationPageWrapper && styles.notificationPageWrapper
        }`}
      >
        <div className={styles.user}>
          {notificationData?.UserEmail && renderUserImage()}
          <span aria-label="notificationText" className={styles.text}>
            {/* generalNotifications */}
            {isFeedbackAdded && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: t("notification.feedback.link"),
                    path: `${ADMIN_PANEL_ROUTES.FEEDBACKS}/${notificationData.FeedbackPublicId}`,
                  },
                ]}
                text={"notification.feedback.added"}
                config={{ userFullName: { position: "right" } }}
              />
            )}
            {isRemovedFromCommunity && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"responses.removed"}
              />
            )}
            {isMemberInCommunityPromoted && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.memberPromoted"}
              />
            )}
            {isAdminInCommunityDemoted && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.memberDemoted"}
              />
            )}
            {isUserAcceptedCommunityJoinInvitation && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.acceptedInvitation"}
              />
            )}
            {isCommunityCreatorChanged && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.communityCreatorChanged"}
              />
            )}
            {isUserDeclinedCommunityJoinInvitation && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.declinedInvitation"}
              />
            )}
            {isJoinPrivateCommunityAccepted && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.requestAccepted"}
              />
            )}
            {isJoinPrivateCommunityDeclined && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.requestDeclined"}
              />
            )}
            {isBannedFromCommunity && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"requests.bannedFromCommunity"}
              />
            )}
            {isEventDeleted && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[]}
                text={t("event.deleteEvent.deletedEvent", {
                  name: notificationData.EventName,
                })}
              />
            )}
            {isCommunityDeleted && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community`,
                  },
                ]}
                text={"requests.deletedCommunity"}
              />
            )}
            {isEventStartingReminder && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={"requests.eventNotification"}
              />
            )}
            {isEventRecordingsSaved && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={"requests.eventRecording"}
              />
            )}
            {isUserAcceptedInvitationToBeCollectionCollaborator && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/collection/${notificationData.CollectionPublicId}`,
                  },
                ]}
                text={"requests.collection.acceptedInvitationToBeCollectionCollaborator"}
              />
            )}
            {isUserDeclinedInvitationToBeCollectionCollaborator && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/collection/${notificationData.CollectionPublicId}`,
                  },
                ]}
                text={"requests.collection.declinedInvitationToBeCollectionCollaborator"}
              />
            )}
            {isContributorRemovedFromCollection && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/collection/${notificationData.CollectionPublicId}`,
                  },
                ]}
                text={"requests.collection.removedAsCollectionCollaborator"}
              />
            )}
            {isUserAddedAsCommunityCollectionCollaborator && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                  {
                    text: `${notificationData.CollectionName}`,
                    path: `/portal/collection/${notificationData.CollectionPublicId}`,
                  },
                ]}
                text={"requests.userAddedAsCommunityCollectionCollaborator"}
              />
            )}
            {isCommunityNameUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.OldName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                  {
                    text: `${notificationData.NewName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={"communityNotificationUpdate.changedName"}
              />
            )}
            {isCommunityPrivacyUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={t("communityNotificationUpdate.type", {
                  name: notificationData.CommunityName,
                  oldType:
                    notificationData.OldType === CommunityType.closed
                      ? t("communityNotificationUpdate.closed")
                      : notificationData.OldType === CommunityType.open
                      ? t("communityNotificationUpdate.open")
                      : notificationData.OldType === CommunityType.private
                      ? t("communityNotificationUpdate.private")
                      : "",
                  newType:
                    notificationData.NewType === CommunityType.closed
                      ? t("communityNotificationUpdate.closed")
                      : notificationData.NewType === CommunityType.open
                      ? t("communityNotificationUpdate.open")
                      : notificationData.NewType === CommunityType.private
                      ? t("communityNotificationUpdate.private")
                      : "",
                })}
              />
            )}
            {isEventLinkedToCommunity && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.CommunitySendbirdId}`,
                  },
                ]}
                text={t("eventNotification.linkedToCommunity")}
              />
            )}
            {isEventNameUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.OldName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                  {
                    text: `${notificationData.NewName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={t("eventNotification.changedName")}
              />
            )}
            {isEventStartTimeUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={t("eventNotification.changedStartTime", {
                  timeFirst: dayjs
                    .unix(notificationData.OldTime)
                    .format("DD/MM/YYYY HH:mm"),
                  timeSecond: dayjs
                    .unix(notificationData.NewTime)
                    .format("DD/MM/YYYY HH:mm"),
                })}
              />
            )}
            {isEventEndTimeUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={t("eventNotification.changedEndTime", {
                  timeFirst: dayjs
                    .unix(notificationData.OldTime)
                    .format("DD/MM/YYYY HH:mm"),
                  timeSecond: dayjs
                    .unix(notificationData.NewTime)
                    .format("DD/MM/YYYY HH:mm"),
                })}
              />
            )}
            {isEventPrivacyUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={t("eventNotification.type", {
                  oldType: notificationData.OldType,
                  newType: notificationData.NewType,
                })}
              />
            )}
            {isEventAttachmentsUpdated && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.EventName}`,
                    path: `/portal/event/${notificationData.EventPublicId}`,
                  },
                ]}
                text={t("eventNotification.attachmentsUpdated")}
              />
            )}
            {isUserAddedAsCommunityMemberForUser && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.CommunityName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={t("requests.isUserAddedAsCommunityMemberForUser")}
              />
            )}
            {isUserAddedAsCommunityMemberForCommunityCreatorOrAdmin && (
              <GeneralNotificationPart
                notificationData={notificationData}
                links={[
                  {
                    text: `${notificationData.ResourceName}`,
                    path: `/portal/community/community-page/${notificationData.SendbirdId}`,
                  },
                ]}
                text={t(
                  "requests.isUserAddedAsCommunityMemberForCommunityCreatorOrAdmin"
                )}
              />
            )}

            {/* requestNotifications */}
            {isAdminInvitedToCommunity && <AdminInvitedToCommunity {...notification} />}
            {isMemberInvitedToCommunity && <MemberInvitedToCommunity {...notification} />}
            {isJoinPrivateCommunityRequested && (
              <JoinPrivateCommunityRequested {...notification} />
            )}
            {isAcceptOrDeclineCollectionCollaborationInvitation && (
              <AcceptOrDeclineCollectionCollaborationInvitation {...notification} />
            )}
          </span>
        </div>
        <div className={styles.time}>{dayjs(creationTime).fromNow()}</div>
      </div>
      {selectedUser && (
        <UserDrawer
          user={selectedUser}
          closeInfoDrawer={() => setSelectedUserEmail("")}
          communityOptions={<></>}
        />
      )}
    </>
  );
};
