import { AnyAction } from "redux";
import {
  ACCEPT_CODE,
  ACCEPT_TERMS,
  ADD_INTERESTS,
  ADD_LOCATIONS,
  ADD_PERSONAL_DETAILS,
  ADD_PROFILE_DETAILS,
  CLEAR,
  RECEIVE_MESSAGES,
} from "./constants";
import { OnboardingState } from "./types";

const initialState: OnboardingState = {
  personalDetails: {
    canReceiveMessages: true,
  },
  profileDetails: {
    workLocation: { name: "", callingCode: "" },
    bio: "",
    organizationName: "",
    organizationRole: "",
    sector: { name: "", customName: "" },
  },
  interests: [],
  locations: [],
  acceptCondition: {},
};

const onboardingReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: payload,
      };
    case ADD_PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: payload,
      };
    case ADD_INTERESTS:
      return {
        ...state,
        interests: payload,
      };
    case ADD_LOCATIONS:
      return {
        ...state,
        locations: payload,
      };
    case RECEIVE_MESSAGES:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          canReceiveMessages: payload,
        },
      };
    case ACCEPT_TERMS:
      return {
        ...state,
        acceptCondition: {
          ...state.acceptCondition,
          isAcceptTerms: payload,
        },
      };
    case ACCEPT_CODE:
      return {
        ...state,
        acceptCondition: {
          ...state.acceptCondition,
          isAcceptCode: payload,
        },
      };
    case CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default onboardingReducer;
