import { useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useHandleError } from "@services/error";

import GET_USERS from "@graphql/queries/usersExtended";

import { ACTIVATE_USER, DEACTIVATE_USER } from "./mutation";
import {
  IActivateUserRequest,
  IActivateUserResponse,
  IDeactivateUserRequest,
  IDeactivateUserResponse,
} from "./type";

interface IProps {
  onCompleted?: () => void;
  onError?: () => void;
}

export const useActivateDeactivateUser = ({ onCompleted, onError }: IProps) => {
  const { t } = useTranslation();
  const { handleError } = useHandleError();

  const [activateUser, { loading: isActivating }] = useMutation<
    IActivateUserResponse,
    IActivateUserRequest
  >(ACTIVATE_USER, {
    onCompleted: () => {
      displaySuccessNotification(t("adminPanel.users.actionFinishedSuccessfully"));
      onCompleted?.();
    },
    onError: (error) => {
      handleError(error);
      onError?.();
    },
    refetchQueries: [GET_USERS],
  });

  const [deactivateUser, { loading: isDeactivating }] = useMutation<
    IDeactivateUserResponse,
    IDeactivateUserRequest
  >(DEACTIVATE_USER, {
    onCompleted: () => {
      displaySuccessNotification(t("adminPanel.users.actionFinishedSuccessfully"));
      onCompleted?.();
    },
    onError: (error) => {
      handleError(error);
      onError?.();
    },
    refetchQueries: [GET_USERS],
  });

  return { activateUser, isActivating, deactivateUser, isDeactivating };
};
