import { gql } from "@apollo/client";

const DEMOTE_ADMIN = gql`
  mutation demoteAdmin($user: DemoteAdminToUserInputType) {
    demoteAdmin(user: $user) {
      email
      publicId
      role
    }
  }
`;

export default DEMOTE_ADMIN;
