import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import Modal from "@components/Modal/Modal";

import ErrorCircle from "@images/circleError.svg";
import Exit from "@images/exit.svg";

import { urlRegex } from "@utils/helpers";

import LINK_PREVIEW from "@graphql/queries/linkPreview";

import { ApolloError, useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { ILink, ILinkPreview } from "types/link";
import styles from "./LinkModal.module.scss";

const externalUrlValidation = Yup.object({
  url: Yup.string().notRequired().matches(urlRegex, { excludeEmptyString: true }),
});

const MAX_LINK_NAME_LENGTH = 100;
const MAX_LINK_URL_LENGTH = 1000;

interface ILinkModal {
  isOpen: boolean;
  link?: ILink;
  onCloseModal: () => void;
  onSubmitLink: (link: ILink) => void;
}

const LinkModal = ({
  isOpen,
  link,
  onCloseModal,
  onSubmitLink,
  ...props
}: ILinkModal) => {
  const { t } = useTranslation();

  const [linkName, setLinkName] = useState<string>(link?.name ?? "");
  const [linkUrl, setLinkUrl] = useState<string>(link?.url ?? "");

  const [maxLimitName, setMaxLimitName] = useState<boolean>(false);
  const [maxLimitUrl, setMaxLimitUrl] = useState<boolean>(false);

  const [userAgent, setUserAgent] = useState<string>();

  const [linkPreview, setLinkPreview] = useState<ILinkPreview>();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>();

  const [urlInvalid, setUrlInvalid] = useState<boolean>(true);

  const [getLinkPreview, { loading: previewLoading }] = useLazyQuery<{
    linkPreview: ILinkPreview;
  }>(LINK_PREVIEW, {
    onCompleted: (res) => {
      setLinkPreview({
        url: res.linkPreview.url,
        title: res.linkPreview.title,
        description: res.linkPreview.description,
        imageUrl: res.linkPreview.imageUrl,
      });
    },
    onError: (err: ApolloError) => {
      setLinkPreview(undefined);
      const codeMessage = err?.graphQLErrors[0]?.extensions.code;
      if (codeMessage === "7006") {
        setLinkPreview(undefined);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const handleSubmitLink = () => {
    if (link) {
      onSubmitLink({ name: linkName, url: linkUrl, publicId: link.publicId });
    } else {
      onSubmitLink({ name: linkName, url: linkUrl, publicId: "" });
    }
  };

  const urlValidation = async () => {
    const isValid = await externalUrlValidation.isValid({ url: linkUrl });
    setUrlInvalid(!isValid);
  };

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, []);

  useEffect(() => {
    urlValidation();

    if (previewLoading) {
      setLinkPreview(undefined);
    }
    if (linkUrl && !urlInvalid) {
      getLinkPreview({
        variables: {
          metaInformationFromLinkInputType: { url: linkUrl, userAgent },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkUrl, urlInvalid]);

  useEffect(() => {
    if (linkName.length === MAX_LINK_NAME_LENGTH) {
      setMaxLimitName(true);
    } else {
      setMaxLimitName(false);
    }

    if (linkUrl.length === MAX_LINK_URL_LENGTH) {
      setMaxLimitUrl(true);
    } else {
      setMaxLimitUrl(false);
    }
  }, [linkName, linkUrl]);

  useEffect(() => {
    if (
      urlInvalid ||
      !linkUrl ||
      !linkName ||
      (linkName === link?.name && linkUrl === link?.url)
    ) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [urlInvalid, linkUrl, linkName, link?.url, link?.name]);

  const decodeHtmlCharCodes = (str: string) => {
    const decodedString = document.createElement("textarea");
    decodedString.innerHTML = str;
    return decodedString.value;
  };

  return (
    <Modal
      modalType="primary"
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
    >
      <div className={styles.titleContainer}>
        <h4 aria-label={"title"} className={styles.title}>
          {link
            ? t("collections.singleCollection.linkModal.title.update")
            : t("collections.singleCollection.linkModal.title.add")}
        </h4>
        <Button
          aria-label={"closeButton"}
          title=""
          buttonShape="circle"
          wrapperClassNames={styles.buttonBorderless}
          onClick={onCloseModal}
          buttonIcon={<img className={styles.pointer} src={Exit} alt="" />}
        />
      </div>
      <div>
        <Input
          name="link-name"
          value={linkName}
          label={t("collections.singleCollection.linkModal.linkName")}
          placeholder={t("collections.singleCollection.linkModal.linkNamePlaceholder")}
          onChange={(e) => setLinkName(e.target.value)}
          maxLength={MAX_LINK_NAME_LENGTH}
        />
        {maxLimitName && (
          <div className={styles.errorContainer}>
            <img alt="" src={ErrorCircle} />
            <span className={styles.errorMessage}>
              {t("collections.singleCollection.linkModal.maxLimitReached", {
                maxlength: MAX_LINK_NAME_LENGTH,
              })}
            </span>
          </div>
        )}
        <Input
          name="link-url"
          value={linkUrl}
          label={t("collections.singleCollection.linkModal.linkUrl")}
          placeholder={t("collections.singleCollection.linkModal.linkUrlPlaceholder")}
          onChange={(e) => setLinkUrl(e.target.value)}
          maxLength={MAX_LINK_URL_LENGTH}
        />
        {maxLimitUrl && (
          <div className={styles.errorContainer}>
            <img alt="" src={ErrorCircle} />
            <span className={styles.errorMessage}>
              {t("collections.singleCollection.linkModal.maxLimitReached", {
                maxlength: MAX_LINK_URL_LENGTH,
              })}
            </span>
          </div>
        )}
        {urlInvalid && (
          <div className={styles.errorContainer}>
            <img alt="" src={ErrorCircle} />
            <span className={styles.errorMessage}>
              {t("event.newEvent.error.externalUrlNotValid")}
            </span>
          </div>
        )}
      </div>
      {linkUrl && !urlInvalid ? (
        previewLoading ? (
          <div className={styles.loadingContainer}>
            <Loading borderless disableBoxShadow />
          </div>
        ) : linkPreview ? (
          <div className={styles.linkPreviewContainer}>
            <h3 className={styles.linkPreviewTitle}>{linkPreview.title}</h3>
            <p>{decodeHtmlCharCodes(linkPreview.description)}</p>
            <img className={styles.linkPreviewImage} src={linkPreview.imageUrl} />
          </div>
        ) : (
          <p className={styles.noPreviewAvailable}>
            {t("collections.singleCollection.linkModal.noPreview")}
          </p>
        )
      ) : null}
      <div className={styles.buttonsContainer}>
        <Button
          title={t("collections.singleCollection.linkModal.button.cancel")}
          buttonSize="large"
          onClick={onCloseModal}
        />
        <Button
          title={
            link
              ? t("collections.singleCollection.linkModal.button.updateLink")
              : t("collections.singleCollection.linkModal.button.addLink")
          }
          buttonType="primary"
          buttonSize="large"
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmitLink}
        />
      </div>
    </Modal>
  );
};

export default LinkModal;
