/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import Loading from "@components/Loading/Loading";
import { ICollection } from "@graphql/collection/type";
import GET_USER_INFO_COLLECTIONS from "@graphql/queries/getUserInfoCollections";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import userDrawerStyles from "../UserDrawer.module.scss";

import styles from "./UserDrawerCollections.module.scss";

interface UserDrawerCollectionsProps {
  userEmail: string;
}

const UserDrawerCollections = ({ userEmail }: UserDrawerCollectionsProps) => {
  const { t } = useTranslation();
  const [userCollections, setUserCollections] = useState<ICollection[]>([]);
  const [userCollectionsTotalCount, setUserCollectionsTotalCount] = useState<number>(0);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  const [getUserCollections, { loading: loadingCollections }] = useLazyQuery(
    GET_USER_INFO_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserCollections([...userCollections, ...data.userInfo.myCollections]);
        setUserCollectionsTotalCount(data.userInfo.myCollectionsTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (loadingCollections) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loadingCollections) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreUserCollections();
      setShouldLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const loadMoreUserCollections = () => {
    getUserCollections({
      variables: {
        email: userEmail,
        collectionSearch: { size: 6, skip: userCollections?.length },
      },
    });
  };

  useEffect(() => {
    if (!userEmail) return;
    getUserCollections({
      variables: { email: userEmail, collectionSearch: { size: 6, skip: 0 } },
    });
  }, [userEmail]);

  return (
    <>
      {loadingCollections && (
        <div className={userDrawerStyles.loader}>
          <Loading disableBoxShadow />
        </div>
      )}

      {!!userCollections.length && (
        <>
          {userCollections.map((collection: ICollection) => (
            <CollectionsCard
              inUserDrawer={true}
              key={collection.publicId}
              title={collection.name}
              description={collection.description}
              resourcesCount={collection.articlesCount}
              collaboratorsCount={collection.collaboratorsCount}
              updateTime={collection.updateTime}
              publicId={collection.publicId}
              customStyles={styles.customStyles}
              collectionCreator={collection.collectionCreator}
              imageName={collection.imageName}
              imageDirectory={collection.imageDirectory}
              isPublic={collection.isPublic}
            />
          ))}
        </>
      )}

      {!!userCollections.length && userCollectionsTotalCount > userCollections.length && (
        <Button
          data-testid="view-more-collections"
          aria-label="view-more-collections"
          title={t("collections.buttons.viewMore")}
          wrapperClassNames={styles.loadMoreButton}
          buttonIcon={<span className={styles.plusIcon}>+</span>}
          onClick={loadMoreUserCollections}
          loading={loadingCollections}
          disabled={loadingCollections}
          customRef={loadMoreElementRef}
        />
      )}
    </>
  );
};

export default UserDrawerCollections;
