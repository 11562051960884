/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import DatePicker from "@components/DatePicker/DatePicker";
import DynamicSelect from "@components/DynamicSelect/DynamicSelect";
import Input from "@components/Input/Input";
import Modal from "@components/Modal/Modal";

import { DefaultOption, SELECT_SEARCH_TYPE } from "types/select";

import { COMMUNITIES_FOR_WHO_ADMINS } from "@graphql/community/queries";
import { ICommunitiesForWhoAdmins } from "@graphql/community/types";
import USER_EXISTS from "@graphql/queries/userExists";

import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";

import removeIcon from "@images/closeModal.svg";
import ReactSwitch from "react-switch";

import {
  IForm,
  MIN_DATE_DEACTIVATION,
  emailValidation,
  getDeactivationDate,
  initialValues,
  validationSchema,
} from "./validation";

import { useInviteUser } from "@graphql/admin-panel/useInviteUser/userInviteUser";
import palette from "@scss/exports.module.scss";
import styles from "./InviteUserModal.module.scss";

const maxCommunitiesForSelect = 10;

interface InviteUserModalProps {
  open: boolean;
  close: () => void;
}

const InviteUserModal = ({ open, close }: InviteUserModalProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [userExists, setUserExists] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [communitiesByMe, setCommunitiesByMe] = useState<DefaultOption[]>([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const { inviteUser, isInvitingUser } = useInviteUser({
    onCompleted: () => closeModal(),
  });

  const [getUserExists] = useLazyQuery(USER_EXISTS, {
    onCompleted: (response) => {
      const userExists = response.data.userExists;
      setUserExists(userExists);
    },
  });

  // get communities managed by user
  const [getCommunitiesForWhoAdmins] = useLazyQuery<ICommunitiesForWhoAdmins>(
    COMMUNITIES_FOR_WHO_ADMINS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        const userCommunities = res.communitiesForWhoAdmins.communities.map(
          (community) => ({
            value: community.sendbirdId,
            label: `${community.name} ${t("prepositions.by")} ${
              community.communityCreator.fullName
            }`,
          })
        );

        setCommunitiesByMe(userCommunities);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => displaySuccessNotification(error),
    }
  );

  const [getCommunitiesForWhoAdminsScroll] = useLazyQuery<ICommunitiesForWhoAdmins>(
    COMMUNITIES_FOR_WHO_ADMINS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        const userCommunities = res.communitiesForWhoAdmins.communities.map(
          (community) => ({
            value: community.sendbirdId,
            label: `${community.name} ${t("prepositions.by")} ${
              community.communityCreator.fullName
            }`,
          })
        );

        const allCommunities = communitiesByMe.concat(userCommunities);

        setCommunitiesByMe(allCommunities);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => displaySuccessNotification(error),
    }
  );

  useEffect(() => {
    getCommunitiesForWhoAdmins({
      variables: {
        communitySearchOptions: {
          size: 10,
          skip: 0,
        },
      },
    });
  }, []);

  const inviteUserForm = useFormik<IForm>({
    validateOnMount: true,
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: ({ isTemporary, selectedCommunities, deactivationDate, ...formData }) => {
      inviteUser({
        variables: {
          user: {
            ...formData,
            isTemporary,
            deactivationDate: isTemporary ? getDeactivationDate(deactivationDate) : null,
            sendbirdIds: selectedCommunities,
          },
        },
      });
    },
  });

  useEffect(() => {
    // We set debounce of 1sec so we dont trigger graphql after every character
    const setData = setTimeout(() => {
      // We first check if email format is valid
      emailValidation.isValid({ email: emailValue }).then((valid) => {
        // If email is valid we will call query to check if user exists
        // and set user exist value
        // else we will set user form as invalid and set error that user already exists
        if (valid) {
          getUserExists({
            variables: {
              email: emailValue,
            },
          });
        } else {
          setUserExists(false);
          inviteUserForm.setErrors({
            email: t("adminPanel.users.inviteUserModal.invalidEmail"),
          });
        }
      });
    }, 1000);

    return () => clearTimeout(setData);
  }, [emailValue]);

  const closeModal = () => {
    inviteUserForm.resetForm();
    setUserExists(false);
    close();
  };

  const handleSubmitForm = () => {
    inviteUserForm.handleSubmit();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    inviteUserForm.setFieldValue("email", emailValue.toLowerCase(), true);
    setEmailValue(emailValue.toLowerCase());
  };

  const getCommunitiesOnSearch = (searchValue: string, searchEvent: string) => {
    if (searchEvent === SELECT_SEARCH_TYPE.INPUT_CHANGE) {
      getCommunitiesForWhoAdmins({
        variables: {
          communitySearchOptions: {
            size: 10,
            skip: 0,
            text: searchValue,
          },
        },
      });
    } else if (searchEvent === SELECT_SEARCH_TYPE.SCROLL_TO_BOTTOM) {
      getCommunitiesForWhoAdminsScroll({
        variables: {
          communitySearchOptions: {
            size: 10,
            skip: communitiesByMe.length,
            text: searchValue,
          },
        },
      });
    }
  };

  const isButtonDisabled = !inviteUserForm.isValid || userExists || isInvitingUser;

  return (
    <>
      <Modal
        className={styles.modalWrapper}
        modalType="primary"
        onRequestClose={closeModal}
        isOpen={open}
      >
        <div className={styles.modalHeader}>
          <h4 aria-label="title">{t("adminPanel.users.inviteUserModal.inviteUser")}</h4>
          <Button
            title=""
            wrapperClassNames={styles.closeButtonWrapper}
            buttonShape="circle"
            onClick={closeModal}
            buttonIcon={<img alt="" className={styles.removeIcon} src={removeIcon} />}
          />
        </div>

        <form>
          <div className={styles.inviteUserContainer}>
            <div className={styles.formRow}>
              <Input
                wrapperClassNames={styles.inputWrapper}
                label=""
                placeholder={t("adminPanel.users.inviteUserModal.firstName")}
                maxLength={40}
                {...inviteUserForm.getFieldProps("firstName")}
              />
              <Input
                wrapperClassNames={styles.inputWrapper}
                label=""
                placeholder={t("adminPanel.users.inviteUserModal.lastName")}
                maxLength={40}
                {...inviteUserForm.getFieldProps("lastName")}
              />
            </div>

            <Input
              label=""
              placeholder={t("adminPanel.users.inviteUserModal.email")}
              {...inviteUserForm.getFieldProps("email")}
              onChange={(e) => handleChange(e)}
              danger={userExists ? true : false}
              customValidation={false}
            />

            {inviteUserForm.touched.email && inviteUserForm.errors.email ? (
              <span className={styles.errorUserMessage}>
                {inviteUserForm.errors.email}
              </span>
            ) : null}

            {userExists && (
              <span className={styles.errorUserMessage}>
                {t("adminPanel.users.inviteUserModal.userAlreadyInvited")}
              </span>
            )}

            <div className={`${styles.formColumn} ${styles.formGroup}`}>
              <div className={styles.formRow}>
                <label>
                  {t("adminPanel.users.inviteUserModal.automaticallyDeactivateUser")}
                </label>
                <ReactSwitch
                  checked={inviteUserForm.getFieldProps("isTemporary").value}
                  onColor={palette.primaryBlue}
                  aria-label="chat-option"
                  {...inviteUserForm.getFieldProps("isTemporary")}
                  onChange={(isChecked) =>
                    inviteUserForm.setFieldValue("isTemporary", isChecked)
                  }
                />
              </div>

              <DatePicker
                value={inviteUserForm.getFieldProps("deactivationDate").value}
                isOpen={isDatePickerOpen}
                open={() => setIsDatePickerOpen(true)}
                close={() => setIsDatePickerOpen(false)}
                onChange={(date: Date) => {
                  inviteUserForm.setFieldValue("deactivationDate", date);
                }}
                minDate={MIN_DATE_DEACTIVATION}
                locale={i18n.language}
                disabled={!inviteUserForm.values.isTemporary}
                className={styles.datePicker}
              />
            </div>

            <div className={styles.selectWrapper}>
              <DynamicSelect
                updateOptions={getCommunitiesOnSearch}
                options={communitiesByMe}
                placeholder={t("adminPanel.users.inviteUserModal.selectPlaceholder")}
                className={"tagsSearch"}
                label={t("event.newEvent.select.communities.label")}
                name={"communities"}
                onChange={(selectedCommunities: string[]) => {
                  inviteUserForm.setFieldValue(
                    "selectedCommunities",
                    selectedCommunities
                  );
                }}
                isMulti
                isDisabled={false}
                selectedValues={inviteUserForm.values.selectedCommunities}
                maxSelectedOptions={maxCommunitiesForSelect}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              aria-label="cancelButton"
              buttonType="link"
              title={t("adminPanel.users.inviteUserModal.cancel")}
              onClick={closeModal}
            />
            <Button
              aria-label="confirmButton"
              buttonType="primary"
              buttonSize="large"
              title={t("adminPanel.users.inviteUserModal.invite")}
              loading={isInvitingUser}
              onClick={handleSubmitForm}
              disabled={isButtonDisabled}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default InviteUserModal;
