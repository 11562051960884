import { gql } from "@apollo/client";

const ADD_ARTICLE_COMMENT = gql`
  mutation addArticleComment($articleId: String!, $content: String!) {
    addArticleComment(articleComment: { articleId: $articleId, content: $content }) {
      publicId
    }
  }
`;

export default ADD_ARTICLE_COMMENT;
