import { useMutation } from "@apollo/client";
import GENERATE_SAS_TOKEN from "@graphql/mutations/generateSasToken";
import GENERATE_SAS_TOKEN_WITH_DIRECTORY from "@graphql/mutations/generateSasTokenWithDirectory";
import uploadFileToBlob from "@utils/storageBlob";
import { useRef, useState } from "react";
import { fileNameReplaceRegex } from "../helpers";
import {
  BlobStorageContainer,
  GenerateSasTokenError,
  UnknownBlobStorageContainer,
  UploadError,
} from "./types";

const containerNames = {
  [BlobStorageContainer.ATTACHMENTS]: process.env.REACT_APP_BLOB_CONTAINER_NAME,
  [BlobStorageContainer.FILES]: process.env.REACT_APP_BLOB_FILE_CONTAINER_NAME,
  [BlobStorageContainer.IMAGES]: process.env.REACT_APP_IMAGE_CONTAINER_NAME,
};

export const useUploadFiles = () => {
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [bytesUploaded, setBytesUploaded] = useState(0);
  const [bytesTotal, setBytesTotal] = useState(0);
  const [countUploaded, setCountUploaded] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [currentUpload, setCurrentUpload] = useState<string>();
  const [currentUploadBytesUploaded, setCurrentUploadBytesUploaded] = useState(0);
  const [currentUploadBytesTotal, setCurrentUploadBytesTotal] = useState(0);
  const [uploadDirectory, setUploadDirectory] = useState<string>();
  const uploadInProgressReff = useRef(false);
  const directoryNameRef = useRef<string>();
  const [generateSasTokenMutation] = useMutation(GENERATE_SAS_TOKEN);
  const [generateSasTokenWithDirectoryMutation] = useMutation(
    GENERATE_SAS_TOKEN_WITH_DIRECTORY
  );

  const uploadStartHandler = (files: File[]) => {
    const bytesTotal = files.reduce((acc, file) => acc + file.size, 0);
    setIsUploadInProgress(true);
    setBytesUploaded(0);
    setBytesTotal(bytesTotal);
    setCountUploaded(0);
    setCountTotal(files.length);
    uploadInProgressReff.current = true;
  };

  const fileUploadStartHandler = (file: File) => {
    setCurrentUpload(file.name);
    setCurrentUploadBytesUploaded(0);
    setCurrentUploadBytesTotal(file.size);
  };

  const fileUploadProgressHandler = (uploadedBytes: number) => {
    setCurrentUploadBytesUploaded(uploadedBytes);
  };

  const fileUploadEndHandler = (file: File, directory: string) => {
    setUploadDirectory(directory);
    setCountUploaded((prevCount) => prevCount + 1);
  };

  const uploadEndHandler = () => {
    setIsUploadInProgress(false);
    uploadInProgressReff.current = false;
  };

  const generateSasToken = async (
    file: File,
    containerName: string,
    directory?: string
  ) => {
    const mutation = directory
      ? generateSasTokenWithDirectoryMutation
      : generateSasTokenMutation;
    const resposenKey = directory ? "generateSasTokenWithDirectory" : "generateSasToken";

    const blobStorage: {
      fileNames: string;
      containerName: string;
      directoryName?: string;
    } = {
      fileNames: file.name,
      containerName,
    };
    if (directory) {
      blobStorage.directoryName = directory;
    }

    try {
      const response = await mutation({
        variables: {
          blobStorage,
        },
      });

      const {
        directoryName,
        values: [url],
      } = response.data[resposenKey];

      if (!directoryName || !url) {
        throw new Error("generateSasToken response: Directory or url not defined");
      }

      return {
        directoryName,
        url,
      };
    } catch (error) {
      throw new GenerateSasTokenError("Generate Sas token failed", { cause: error });
    }
  };

  const uploadFiles = async (
    filesToUpload: File[] | File,
    blobStorageContainer: BlobStorageContainer,
    options: {
      onFileUpload?: (file: File, directory: string) => void;
      onError?: (error: UploadError) => void;
      directory?: string;
      useSameDirectory?: boolean;
    }
  ) => {
    if (uploadInProgressReff.current) {
      return;
    }

    const files = Array.isArray(filesToUpload) ? filesToUpload : [filesToUpload];

    if (files.length === 0) {
      return;
    }

    const { onFileUpload, onError, directory, useSameDirectory = false } = options;

    const containerName = containerNames[blobStorageContainer];
    if (!containerName) {
      const uploadError = new UploadError({
        files,
        cause: new UnknownBlobStorageContainer(),
      });
      if (onError) {
        onError(uploadError);
        return;
      } else {
        throw uploadError;
      }
    }

    const shouldUseSameDirectory = !!directory || useSameDirectory;
    if (shouldUseSameDirectory) {
      directoryNameRef.current = directory;
    } else {
      directoryNameRef.current = undefined;
    }

    const filesWithValidName = files.map((file) =>
      Object.defineProperty(file, "name", {
        writable: true,
        value: fileNameReplaceRegex(file.name),
      })
    );

    uploadStartHandler(filesWithValidName);

    for await (const file of filesWithValidName) {
      fileUploadStartHandler(file);
      try {
        const { directoryName, url } = await generateSasToken(
          file,
          containerName,
          directoryNameRef.current
        );

        if (shouldUseSameDirectory) {
          directoryNameRef.current = directoryName;
        }

        const uploadResponse = await uploadFileToBlob(
          file,
          url,
          fileUploadProgressHandler
        );

        if (uploadResponse) {
          fileUploadEndHandler(file, directoryName);
          onFileUpload && onFileUpload(file, directoryName);
        } else {
          throw new Error("uploadFileToBlob: response not defined");
        }
      } catch (error) {
        const uploadError = new UploadError({ files: [file], cause: error });
        if (onError) {
          onError(uploadError);
        } else {
          setIsUploadInProgress(false);
          throw uploadError;
        }
      }
    }

    uploadEndHandler();
  };

  return {
    uploadFiles,
    isUploadInProgress,
    bytesUploaded,
    bytesTotal,
    countUploaded,
    countTotal,
    currentUpload,
    currentUploadBytesUploaded,
    currentUploadBytesTotal,
    uploadDirectory,
  };
};
