import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Modal from "@components/Modal/Modal";

import { LINK_COLLECTION_TO_COMMUNITY } from "@graphql/collection/mutation";
import { GET_USER_COMMUNITIES } from "@graphql/community/queries";
import {
  ICommunityManagedByUser,
  ICommunityManagedByUserResponse,
} from "types/community";

import Exit from "@images/exit.svg";

import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import styles from "./LinkCollectionToCommunityModal.module.scss";

interface ILinkCollectionToCommunityModal {
  isOpen: boolean;
  targetCommunityId?: string;
  collectionId?: string;
  collectionName?: string;
  onClose: () => void;
  onSetTargetCommunityId: (id: string) => void;
}

const LinkCollectionToCommunityModal = ({
  isOpen,
  targetCommunityId,
  collectionId,
  collectionName,
  onClose,
  onSetTargetCommunityId,
}: ILinkCollectionToCommunityModal) => {
  const { t } = useTranslation();

  const [communitiesManagedByUser, setCommunitiesManagedByUser] =
    useState<ICommunityManagedByUser[]>();

  const [communityName, setCommunityName] = useState<string>();

  const { data: userCommunitiesData, loading } =
    useQuery<ICommunityManagedByUserResponse>(GET_USER_COMMUNITIES, {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setCommunitiesManagedByUser(userCommunitiesData?.user.communitiesManagedByMe);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [linkCollectionToCommunity, { loading: linkingInProgress }] = useMutation(
    LINK_COLLECTION_TO_COMMUNITY,
    {
      onCompleted: () => {
        const toasterMessage = t("community.linkCollectionToCommunity.toasterMessage", {
          collectionName,
          communityName,
        });

        onClose();
        displaySuccessNotification(toasterMessage);
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  const handleLinkCollectionToCommunity = (
    sendbirdId?: string,
    collectionPublicId?: string
  ) => {
    linkCollectionToCommunity({
      variables: {
        linkPublicCollectionToCommunity: {
          sendbirdId,
          collectionPublicId,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} modalType="narrow">
      <img
        role="button"
        aria-label="close-modal"
        className={styles.closeImage}
        onClick={onClose}
        src={Exit}
        alt=""
      />
      <h2>{t("community.linkCollectionToCommunity.title")}</h2>
      <div className={styles.communitiesWrapper}>
        {!loading && !communitiesManagedByUser?.length && (
          <h3 className={styles.noCommunities}>
            {t("community.linkCollectionToCommunity.noCommunities")}
          </h3>
        )}
        {!loading ? (
          communitiesManagedByUser
            ?.sort((communityA, communityB) =>
              communityA.name > communityB.name ? 1 : -1
            )
            .map((community) => (
              <div
                key={community.sendbirdId}
                className={
                  targetCommunityId === community.sendbirdId
                    ? styles.selectedRow
                    : styles.row
                }
                onClick={() => {
                  onSetTargetCommunityId(community.sendbirdId);
                  setCommunityName(community.name);
                }}
              >
                <div
                  className={styles.communityImage}
                  style={{ backgroundImage: `url(${community.communityImageUrl})` }}
                />
                <span className={styles.communityName}>{community.name}</span>
              </div>
            ))
        ) : (
          <Loading disableBoxShadow />
        )}
      </div>
      <div className={styles.buttonsSection}>
        <Button
          title={t("community.linkCollectionToCommunity.cancel")}
          onClick={onClose}
          disabled={linkingInProgress}
        />
        <Button
          disabled={!targetCommunityId || linkingInProgress}
          buttonType="primary"
          title={t("community.linkCollectionToCommunity.linkToCommunity")}
          onClick={() => handleLinkCollectionToCommunity(targetCommunityId, collectionId)}
        />
      </div>
    </Modal>
  );
};

export default LinkCollectionToCommunityModal;
