/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useSubscriptionsContext } from "../customContext/subscriptions/context";
import { Subscription } from "../customContext/subscriptions/enum";
import { v4 as uuidv4 } from "uuid";

export const useSubscription = <T>(
  type: Subscription,
  callback: (response: T) => void
) => {
  const subscriptions = useSubscriptionsContext<T>();
  const callBackRef = useRef(callback);
  const [id] = useState(() => uuidv4());

  useEffect(() => {
    callBackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!subscriptions) return;

    const handler = (response: T) => callBackRef.current(response);
    subscriptions.addSubscriber(type, { id, callback: handler });

    return () => subscriptions.removeSubscriber(type, id);
  }, []);
};
