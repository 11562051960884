import { ReactComponent as LinkIcon } from "@images/link-icon-small.svg";
import { ReactComponent as ArticleIcon } from "@images/tree-article.svg";
import { ReactComponent as CollectionIcon } from "@images/tree-collection.svg";
import { ReactComponent as FileIcon } from "@images/tree-file.svg";
import { ReactComponent as FolderIcon } from "@images/tree-folder.svg";
import Loading from "../Loading/Loading";
import { CollectionsTreeNodeType } from "./types";

export const NodeIcon = (props: { data: { type: CollectionsTreeNodeType } }) => {
  switch (props.data.type) {
    case "collection":
      return <CollectionIcon />;
    case "folder":
      return <FolderIcon />;
    case "file":
      return <FileIcon />;
    case "article":
      return <ArticleIcon />;
    case "new-folder":
      return <FolderIcon />;
    case "link":
      return <LinkIcon />;
    case "loading":
      return <Loading borderless disableBoxShadow size="Small" />;
    default:
      return <></>;
  }
};
