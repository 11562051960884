import { gql } from "@apollo/client";

const GET_ARTICLE_COMMENTS = gql`
  query articleComments($articleId: String!) {
    articleComments(articleId: $articleId) {
      publicId
      commenter {
        fullName
        email
      }
      content
      creationTime
      reactionCount
      isLoggedUserReactedOnArticleComment
    }
  }
`;

export default GET_ARTICLE_COMMENTS;
