import * as yup from "yup";

import { getValidationSchema as getValidationSchemaSector } from "@feature/sector";

import { requiredStringValidation, stringValidation } from "@utils/yup/string";

export const getValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    workLocation: requiredStringValidation(t),
    bio: stringValidation(t),
    organizationName: requiredStringValidation(t),
    organizationRole: requiredStringValidation(t),
    sector: getValidationSchemaSector(t),
    chat: requiredStringValidation(t),
  });
