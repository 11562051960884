import { Language } from "@feature/language";
import { AnyAction } from "redux";
import { RootState } from "store/store";
import { SET_CLAIMS, SET_USER, SET_USER_IMAGE, SET_USER_PUBLIC_ID } from "./constants";
import { UserState } from "./types";

const initialState: UserState = {
  claims: [],
  userPublicId: "",
  userImage: "",
  email: "",
  fullName: "",
  defaultLanguage: Language.ENGLISH,
};

const userReducer = (state = initialState, action: AnyAction): UserState => {
  const { type, payload } = action;
  switch (type) {
    case SET_CLAIMS:
      return {
        ...state,
        claims: payload,
      };
    case SET_USER_PUBLIC_ID:
      return {
        ...state,
        userPublicId: payload,
      };
    case SET_USER_IMAGE:
      return {
        ...state,
        userImage: payload,
      };
    case SET_USER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const defaultLanguageSelector: (state: RootState) => Language = (
  state: RootState
) => {
  return state.user.defaultLanguage;
};

export default userReducer;
