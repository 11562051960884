/* eslint-disable react-hooks/exhaustive-deps */ import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { RootState } from "../../store/RootState";

import CommunityChat from "@components/CommunityChat/CommunityChat";
import CommunitySideMenu from "@components/CommunitySidemenu/CommunitySidemenu";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Loading from "@components/Loading/Loading";

import { NO_ACCESS_CODE } from "@constants/constants";
import { UPDATED_COMMUNITY_CHANNELS } from "@constants/sendbirdSubsctiptionsConstants";
import GET_COMMUNITY from "@graphql/queries/community";
import GET_USER_COMMUNITIES from "@graphql/queries/userCommunities";
import SendBird from "sendbird";
import { ICommunity, ICommunityResponse } from "types/community";
import { IUser } from "types/user";
import { useSignalRContext } from "../../customContext/contextSignalR";
import { setCommunityMembers } from "../../store/community/actions";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";

import PageNotFound from "@modules/PageNotFound/PageNotFound";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import styles from "./CommunityChannelPage.module.scss";

const DEFAULT_CHANNEL = "General";

const CommunityChannelPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sb } = useSendBirdContext();
  const params = useParams<{ community: string; channel: string; message: string }>();
  const [groupChannel, setGroupChannel] = useState<SendBird.GroupChannel>();
  const [channels, setChannels] = useState<Array<string>>([DEFAULT_CHANNEL]);
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const [isCheckingIfMember, setIsCheckingIfMember] = useState<boolean>(true);
  const { t } = useTranslation();
  const signalRConnection = useSignalRContext();
  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);

  const [allMembers, setAllMembers] = useState<IUser[]>([]);

  const [community, setCommunity] = useState<ICommunity>();

  const [isValidChannel, setIsValidChannel] = useState<boolean>(true);

  const [currentUserCommunities, setCurrentUserCommunities] = useState<ICommunity[]>([]);

  useEffect(() => {
    if (!signalRConnection) return;
    signalRConnection.on(UPDATED_COMMUNITY_CHANNELS, (ids) => {
      if (params.community === ids[0])
        displaySuccessNotification(t("community.addedToNewChannel"));
      getCommunity({ variables: { sendbirdId: ids[0] } });
    });
    return () => signalRConnection.off(UPDATED_COMMUNITY_CHANNELS);
  }, [signalRConnection]);

  const [getCommunity] = useLazyQuery<ICommunityResponse>(GET_COMMUNITY, {
    fetchPolicy: "no-cache",
    onCompleted: ({ community }) => {
      if (!community) {
        displayServerError();
        return;
      }
      setCommunity(community);
      community.channels && setChannels([DEFAULT_CHANNEL, ...community.channels]);
      setAllMembers([
        ...community.admins,
        ...community.members,
        community.communityCreator,
      ]);
      dispatch(
        setCommunityMembers({
          admins: community.admins,
          members: community.members,
          creator: community.communityCreator,
        })
      );
    },
    onError: (error) => setIsValidChannel(false),
  });

  useEffect(() => {
    if (!groupChannel || !currentUserCommunities.length) return;

    if (!currentUserCommunities.some(({ sendbirdId }) => sendbirdId === groupChannel.url))
      setIsUserNotAllowedModalOpened(true);

    setIsCheckingIfMember(false);
  }, [groupChannel, currentUserCommunities]);

  useEffect(() => {
    if (!groupChannel) return;
    getCommunity({ variables: { sendbirdId: groupChannel.url } });
  }, [groupChannel]);

  const [getUserCommunities] = useLazyQuery(GET_USER_COMMUNITIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      const reverse = [...res.user.communities].reverse();
      setCurrentUserCommunities(reverse);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  useEffect(() => {
    getUserCommunities({
      variables: {
        email: currentUserEmail,
      },
    });
  }, []);

  const getGroupChannel = () => {
    if (!sb) return;

    sb.GroupChannel.getChannel(params.community, (groupChannel, error) => {
      if (error) {
        if (error.code === NO_ACCESS_CODE) return setIsUserNotAllowedModalOpened(true);
        setIsValidChannel(false);
        return;
      }
      setGroupChannel(groupChannel);
    });
  };

  useEffect(() => {
    setChannels([DEFAULT_CHANNEL]);
    getGroupChannel();
  }, [sb, params.community]);

  const setChannelsHandler = (communityChannels: Array<string>) => {
    const filteredChannels = communityChannels.filter(
      (channel) => !channels.includes(channel)
    );

    setChannels([...channels, ...filteredChannels]);
  };

  if (!isValidChannel) return <PageNotFound />;

  if (isCheckingIfMember && !isUserNotAllowedModalOpened)
    return <Loading disableBoxShadow />;

  return (
    <>
      <ConfirmationModal
        isOpen={isUserNotAllowedModalOpened}
        messageTitle={t("community.communityAccess.title")}
        messageContent={t("community.communityAccess.content")}
        closeModal={() => setIsUserNotAllowedModalOpened(false)}
        goBack={() => history.push("/portal/community")}
      />
      <div className={styles.container}>
        <CommunitySideMenu
          groupChannel={groupChannel}
          channels={channels}
          defaultChannel={DEFAULT_CHANNEL}
          setChannels={setChannelsHandler}
          community={community}
          currentUserEmail={currentUserEmail}
        />
        <CommunityChat
          channels={channels}
          community={params.community}
          selectedMessage={parseInt(params.message)}
          allMembers={allMembers}
        />
      </div>
    </>
  );
};

export default CommunityChannelPage;
