import { KeyboardEvent } from "react";

import { useTranslation } from "react-i18next";

import Select, {
  InputActionMeta,
  MultiValue,
  SingleValue,
  StylesConfig,
  createFilter,
} from "react-select";

import { isEqual } from "lodash";

import { getDefaultStyles } from "@components/Select/styles/selectStyles";
import { Label } from "@components/formik/Label/Label";

import { Control } from "./Control/Control";
import { DropdownIndicator } from "./DropdownIndicator/DropDownIndicator";
import { MenuList } from "./MenuList/MenuList";

import { indicatorPosition } from "./type";

import formStyles from "../form.style.module.scss";

import styles from "./SelectUpdated.module.scss";

export interface IOption<T> {
  label: string;
  value: T;
}

export interface ISelectUpdatedProps<T> {
  label?: string;
  subLabel?: string;
  isRequired?: boolean;
  virtualized?: boolean;
  icon?: string;
  iconSide?: indicatorPosition;
  nullValue?: T;
  name: string;
  options: Array<IOption<T>>;
  placeholder?: string;
  isSearchable?: boolean;
  styles?: StylesConfig;
  onBlur?: () => void;
  value?: T | Array<T>;
  isMulti?: boolean;
  onChange?: (value: T) => void;
  onChangeMulti?: (value: T[]) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  filterOption?: { matchFrom?: "start" | "any" | undefined };
  className?: string;
  inputType?: "text" | "number";
  onInputChanged?: (newValue: string, actionMeta: InputActionMeta) => void;
}

export const SelectUpdated = <T,>({
  label,
  isRequired,
  virtualized,
  icon,
  nullValue,
  inputType,
  iconSide = "left",
  ...props
}: ISelectUpdatedProps<T>) => {
  const { t } = useTranslation();

  const getStyles = () =>
    props.styles
      ? { ...getDefaultStyles(!!icon), ...props.styles }
      : getDefaultStyles(!!icon);

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (inputType !== "number") return;
    if (e.key !== "Backspace" && isNaN(parseInt(e.key))) e.preventDefault();
  };

  const onChange = (option: SingleValue<IOption<T>> | MultiValue<IOption<T>>) => {
    if (!option) {
      return nullValue && props.onChange?.(nullValue);
    }

    if (!props.isMulti) {
      return props.onChange?.((option as SingleValue<IOption<T>>)?.value as T);
    }

    if (props.isMulti) {
      props.onChangeMulti?.(
        (option as MultiValue<IOption<T>>)?.map(({ value }) => value)
      );
    }
  };

  const getValue = () => {
    if (props.isMulti) {
      return props.options.filter((option) => {
        return (props.value as Array<T> | undefined)?.some(
          (value) => value === option.value
        );
      });
    }

    return props.options.find(({ value }) => isEqual(value, props.value));
  };

  return (
    <div className={`${formStyles.column} ${styles[props?.className || ""]}`}>
      {label && (
        <Label
          text={label}
          name={props.name || ""}
          subText={props.subLabel}
          isRequired={isRequired}
        />
      )}
      <Select
        {...props}
        filterOption={createFilter({
          ignoreAccents: false,
          matchFrom: props.filterOption?.matchFrom,
        })}
        onChange={onChange}
        maxMenuHeight={200}
        value={getValue()}
        onKeyDown={handleKeyDown}
        // TO DO Refactor so no any is used
        components={
          {
            Control,
            MenuList,
            DropdownIndicator,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        styles={getStyles() as any}
        noOptionsMessage={() => t("select.noOptions")}
        menuPlacement="auto"
        menuPosition="fixed"
        data-virtualized={virtualized}
        data-icon={icon}
        data-icon-position={iconSide}
      />
    </div>
  );
};
