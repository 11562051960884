import { DEFAULT_PAGE_SIZE, INITIAL_START_PAGE } from "../../constants/constants";

type SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
type SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
type SET_PAGE_SIZE = "SET_PAGE_SIZE";
type SET_SEARCH = "SET_SEARCH";

type actionTypes = SET_TOTAL_COUNT | SET_CURRENT_PAGE | SET_PAGE_SIZE | SET_SEARCH;

interface Action {
  type: actionTypes;
  // eslint-disable-next-line
  payload?: any;
}

export interface State {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  totalPagesCount: number;
  search: string | undefined;
  skip: number;
}

export const setTotalCount = (totalCount: number): Action => ({
  type: "SET_TOTAL_COUNT",
  payload: totalCount,
});

export const setCurrentPage = (currentPage: number): Action => ({
  type: "SET_CURRENT_PAGE",
  payload: currentPage,
});

export const setPageSize = (pageSize: number): Action => ({
  type: "SET_PAGE_SIZE",
  payload: pageSize,
});

export const setSearch = (searchTerm: string | undefined): Action => ({
  type: "SET_SEARCH",
  payload: searchTerm,
});

export const initialState: State = {
  currentPage: INITIAL_START_PAGE,
  totalCount: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPagesCount: 1,
  search: undefined,
  skip: 0,
};

export const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case "SET_TOTAL_COUNT": {
      return {
        ...state,
        totalCount: payload,
        totalPagesCount: Math.ceil(payload / state.pageSize),
      };
    }
    case "SET_PAGE_SIZE": {
      return {
        ...state,
        pageSize: payload,
        currentPage: 1,
        skip: 0,
        totalPagesCount: Math.ceil(state.totalCount / payload),
      };
    }
    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: payload,
        skip: payload > 0 ? state.pageSize * (payload - 1) : 0,
      };
    }
    case "SET_SEARCH": {
      return {
        ...state,
        currentPage: 1,
        skip: 0,
        search: payload,
      };
    }
    default:
      return state;
  }
};
