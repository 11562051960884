import { gql } from "@apollo/client";

const GET_HOSTING_AND_UPCOMING_EVENTS = gql`
  query user {
    user {
      email
      fullName
      telephone
      isOnboarded
      hostingAndAttendingUpcomingEvents {
        name
        description
        hostingId
        publicId
        startDate
        endDate
        eventImageName
        imageDirectory
        eventType
        eventHost {
          fullName
          email
        }
      }
    }
  }
`;

export default GET_HOSTING_AND_UPCOMING_EVENTS;
