import { IUser, IUserResponse } from "../../types/user";

export const isCurrentlyLoggedInUserAdmin = (
  currentUserData: IUserResponse | undefined,
  admins: IUser[]
) => {
  if (!currentUserData?.user) return false;
  const adminsIds = admins?.map((admin) => admin.email);
  return adminsIds.includes(currentUserData.user.email);
};

export const isMemberInCurrentRowCommunityCreator = (
  communityCreator: IUser | undefined,
  member: IUser
) => communityCreator?.email === member.email;

export const isMemberInCurrentRowRegularMember = (
  regularMembers: IUser[],
  member: IUser
) => regularMembers.some(({ email }) => email === member.email);

export const isMemberInCurrentRowAdmin = (admins: IUser[], member: IUser) =>
  admins.some(({ email }) => email === member.email);

export const currentlyLoggedInUserIsAdminOrCreator = (
  isCurrentUserCommunityCreator: boolean,
  currentUserData: IUserResponse | undefined,
  admins: IUser[]
) =>
  isCurrentUserCommunityCreator || isCurrentlyLoggedInUserAdmin(currentUserData, admins);
