/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { RootState } from "../../store/RootState";

import AdminFollowers from "@components/AdminFollowers/AdminFollowers";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import Button from "@components/Button/Button";
import ResourcesCategories from "@components/ResourcesCategories/ResourcesCategories";
import Select from "@components/Select/Select";
import ShareModal from "@components/ShareModal/ShareModal";
import Tabs from "@components/Tabs/Tabs";
import {
  ALL_COLLECTIONS,
  PRIVATE,
  PUBLIC,
  SORTING_A_Z,
  SORTING_MOST_RECENT,
  SORTING_Z_A,
  sortingFoldersObj,
} from "@constants/constants";
import { getMenuOptions } from "@utils/collectionsHelper";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";

import { ReactComponent as VisitCommunityIcon } from "@images/visit-community-icon.svg";

import { DefaultOption } from "types/select";

import { useLazyQuery, useMutation } from "@apollo/client";
import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";
import Loading from "@components/Loading/Loading";
import checkDefaultSortType from "@utils/checkDefaultSortType";
import { getSortedArray } from "@utils/helpers";

import {
  COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
  COMMUNITY_COLLECTION_GET_COMMUNITY,
  COMMUNITY_COLLECTION_GET_PRIVATE_COMMUNITY_COLLECTIONS,
  COMMUNITY_COLLECTION_GET_PUBLIC_COMMUNITY_COLLECTIONS,
} from "@graphql/community-collection/queries";

import AdminFollowersModal from "@components/AdminFollowersModal/AdminFollowersModal";
import { CollectionType, ICollection } from "@graphql/collection/type";
import LinkCollectionToCommunityModal from "@modules/Collections/LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";
import { CommunityType, ICommunity } from "types/community";
import { IInterest } from "types/interest";
import { addCrumb, clearBreadCrumbs } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";

import { UNLINK_COLLECTION_FROM_COMMUNITY } from "@graphql/collection/mutation";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import styles from "./CommunityCollectionSingle.module.scss";

type ParamsProps = {
  id: string;
};

const CommunityCollectionSingle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams() as ParamsProps;
  const [activeButton, setActiveButton] = useState(ALL_COLLECTIONS);
  const [communityCollections, setCommunityCollections] = useState([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [isOpenAdminFollowers, setOpenAdminFollowers] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareCollectionUrl, setShareCollectionUrl] = useState<string>();
  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState(false);
  const [targetCommunityId, setTargetCommunityId] = useState("");
  const [collectionToLinkId, setCollectionToLinkId] = useState<string>();
  const [collectionName, setCollectionName] = useState<string>();

  const user = useSelector((state: RootState) => state.user);

  const COLLECTIONS_TABS = [
    {
      name: t("collections.myCollections"),
      selectedByRoutes: ["/my-collections"],
    },
    {
      name: t("collections.explore"),
      selectedByRoutes: ["/explore", "/explore/view-more"],
    },

    // {
    //   name: t("collections.activity"),
    //   selectedByRoutes: ["/activity"],
    // },
  ];

  const sortOptions = [
    { value: SORTING_MOST_RECENT, label: t("collections.sorting.mostRecent") },
    { value: SORTING_A_Z, label: t("collections.sorting.alphabeticalAZ") },
    { value: SORTING_Z_A, label: t("collections.sorting.alphabeticalZA") },
  ];

  const [sortValue, setSortValue] = useState(sortOptions[0]);
  const [community, setCommunity] = useState<ICommunity>();
  const isCommunityOwner = !!(community?.communityCreator.email === user.email);
  const isCommunityAdmin = !!community?.admins.some(
    (admin) => admin.email === user.email
  );

  const [getCommunity] = useLazyQuery(COMMUNITY_COLLECTION_GET_COMMUNITY, {
    onCompleted: (response) => {
      setCommunity(response.community);
      setCategories(response.community.interests.map((cat: IInterest) => cat.name));

      const breadCrumb: IBreadcrumb = {
        id: response.community.sendbirdId,
        name: response.community.name,
        path: location.pathname,
      };

      dispatch(addCrumb(breadCrumb));
    },
    onError: (err) => displayServerError(err),
  });

  // GET ALL
  const [getAllCommunityCollections, { loading: allCommunityCollectionsLoader }] =
    useLazyQuery(COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        setCommunityCollections(res.allCommunityCollections.collections);
      },
      onError: (err) => displayServerError(err),
    });

  // GET PUBLIC
  const [getPublicCommunityCollections, { loading: publicCommunityCollectionsLoader }] =
    useLazyQuery(COMMUNITY_COLLECTION_GET_PUBLIC_COMMUNITY_COLLECTIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        setCommunityCollections(res.publicCommunityCollections.collections);
      },
      onError: (err) => displayServerError(err),
    });

  // GET PRIVATE
  const [getPrivateCommunityCollections, { loading: privateCommunityCollectionsLoader }] =
    useLazyQuery(COMMUNITY_COLLECTION_GET_PRIVATE_COMMUNITY_COLLECTIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        setCommunityCollections(res.privateCommunityCollections.collections);
      },
      onError: (err) => displayServerError(err),
    });

  const [unlinkCollectionFromCommunity] = useMutation(UNLINK_COLLECTION_FROM_COMMUNITY, {
    onCompleted: () => {
      displaySuccessNotification(
        t("community.drawer.communitiesActions.successUnlinkCollection", {
          name: community?.name,
        })
      );
      fetchCommunityCollections();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  useEffect(() => {
    fetchCommunityCollections();
  }, [activeButton]);

  const fetchCommunityCollections = () => {
    const variables = {
      communityCollectionSearch: {
        size: 1000,
        skip: 0,
        text: "",
        sendbirdId: params.id,
        sortingCriteria: sortValue.value,
      },
    };
    switch (activeButton) {
      case ALL_COLLECTIONS:
        getAllCommunityCollections({
          variables,
        });
        break;

      case PUBLIC:
        getPublicCommunityCollections({
          variables,
        });
        break;

      case PRIVATE:
        getPrivateCommunityCollections({
          variables,
        });
        break;
    }
  };

  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  const adminPrivilege =
    !!community &&
    [community.communityCreator, ...community.admins].some(
      (user) => user.email === currentUserEmail
    );

  useEffect(() => {
    if (params.id) {
      getCommunity({ variables: { sendbirdId: params.id } });
    }
  }, [params.id]);

  const handleVisitCommunity = () => {
    history.push(`/portal/community/community-page/${params.id}`);
  };

  const handleCreateNewCollection = () => {
    // do stuff
    const redirectLocation = {
      pathname: "/portal/community-collection/new",
      state: {
        redirect: location.pathname,
        community,
      },
    };
    history.push(redirectLocation);
  };

  // on start load all community collections
  useEffect(() => {
    dispatch(clearBreadCrumbs());

    getAllCommunityCollections({
      variables: {
        communityCollectionSearch: {
          size: 1000,
          skip: 0,
          text: "",
          sendbirdId: params.id,
          sortingCriteria: sortValue.value,
        },
      },
    });
  }, []);

  useEffect(() => {
    const sortedCommunityCollections = getSortedArray(
      communityCollections,
      sortingFoldersObj[sortValue.value].value,
      checkDefaultSortType(sortValue)
    );

    setCommunityCollections([...sortedCommunityCollections]);
  }, [sortValue]);

  const handleActiveButtonClick = (e: React.MouseEvent) => {
    const activeButton = e.currentTarget.id;
    setActiveButton(activeButton);
  };

  const handleAdminFollowerModal = () => {
    if (!community) return;
    const peopleCount = community?.admins.length + community?.members.length;
    !!peopleCount && setOpenAdminFollowers(true);
  };

  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);

  const handleCopyLink = () => {
    if (shareCollectionUrl) {
      navigator.clipboard.writeText(shareCollectionUrl);
      displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
    }
  };

  const hadleLinkCollection = (collection: ICollection) => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
    setCollectionToLinkId(collection.publicId);
    setCollectionName(collection.name);
  };

  const handleShareCollection = (collection: ICollection) => {
    setShareModalOpen(true);
    setShareCollectionUrl(
      `${window.location.origin}/portal/collection/${collection.publicId}`
    );
  };

  const handleUnlinkCollection = (collection: ICollection) => {
    unlinkCollectionFromCommunity({
      variables: {
        inputType: {
          sendbirdId: community?.sendbirdId,
          collectionPublicId: collection.publicId,
        },
      },
    });
  };

  const menuDots = (collection: ICollection) => {
    // disable menu for linked collections
    if (collection.collectionType === CollectionType.USER_COLLECTION) {
      if (isCommunityOwner || isCommunityAdmin) {
        return [
          {
            label: "unlinkCollection",
            action: () => handleUnlinkCollection(collection),
          },
        ];
      } else {
        return [];
      }
    }

    return getMenuOptions(collection, user.email, {
      link: (collection) => hadleLinkCollection(collection),
      share: (collection) => handleShareCollection(collection),
    });
  };

  const isLoading =
    allCommunityCollectionsLoader ||
    publicCommunityCollectionsLoader ||
    privateCommunityCollectionsLoader;

  return (
    <>
      <div
        className={styles.container}
        e2e-test-id="community-collections-page-container"
      >
        <div className={styles.tabContainer} e2e-test-id="collection-tabs-container">
          <Tabs tabs={COLLECTIONS_TABS} size="large" routePrefix="/portal/collections" />
        </div>
        <div
          className={styles.breadCrumbsContainer}
          e2e-test-id="breadcrumbs-and-goto-container"
        >
          <Breadcrumbs />
        </div>
        <div className={styles.topBar}>
          <h3 className={styles.title} e2e-test-id="community-name">
            {community?.name}
          </h3>
          <div className={styles.actionWraper}>
            <Button
              buttonSize="large"
              aria-label="visit-community"
              title={t("collections.communityCollections.visitCommunity")}
              buttonIcon={<VisitCommunityIcon />}
              className={styles.visitCommunityButton}
              onClick={handleVisitCommunity}
              e2eTestId="visit-community-button"
            />
            {adminPrivilege ? (
              <Button
                buttonType="primary"
                buttonSize="large"
                aria-label="new-collection"
                title={t("collections.communityCollections.newCollection")}
                buttonIcon={<PlusOutlined />}
                onClick={handleCreateNewCollection}
                e2eTestId="new-collection-button"
              />
            ) : null}
          </div>
        </div>
        <div className={styles.middleBar}>
          <div e2e-test-id="categories-section">
            <ResourcesCategories categories={categories} />
          </div>
          <div
            className={styles.peopleOuter}
            e2e-test-id="community-type-and-people-section"
          >
            {community ? (
              <div onClick={() => handleAdminFollowerModal()}>
                <AdminFollowers
                  type={community.communityType!}
                  admins={community.admins}
                  followers={community?.members}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.sortContainer} e2e-test-id="filters-and-sorting-container">
          <div className={styles.collectionTypes}>
            <>
              <Button
                idElement={ALL_COLLECTIONS}
                buttonSize="large"
                className={
                  activeButton === ALL_COLLECTIONS
                    ? styles.activeButton
                    : styles.notActive
                }
                title={t("collections.communityCollections.allCollections")}
                onClick={handleActiveButtonClick}
              />
              <Button
                idElement={PUBLIC}
                buttonSize="large"
                className={
                  activeButton === PUBLIC ? styles.activeButton : styles.notActive
                }
                title={t("collections.communityCollections.tab.public")}
                onClick={handleActiveButtonClick}
              />
              <Button
                idElement={PRIVATE}
                buttonSize="large"
                className={
                  activeButton === PRIVATE ? styles.activeButton : styles.notActive
                }
                title={t("collections.communityCollections.tab.private")}
                onClick={handleActiveButtonClick}
              />
            </>
          </div>
          <div className={styles.sortDropdownWrap}>
            <Select
              label=""
              placeholder=""
              name={"sort"}
              defaultSelect={sortOptions[0]}
              value={sortValue}
              options={sortOptions}
              showDropdown
              onChange={(option: DefaultOption) => setSortValue(option)}
            />
          </div>
        </div>
        <div className={styles.gridContainer} e2e-test-id="community-collections-grid">
          {isLoading ? (
            <Loading disableBoxShadow />
          ) : (
            <>
              <CollectionsGrid
                collections={communityCollections}
                collectionsCount={0}
                collectionsLoading={isLoading}
                collectionsLoadingMore={false}
                defaultCollection={community?.defaultCollection}
                loadMore={() => {
                  // FUTURE IMPLEMENTATION OF PAGINATION
                }}
                menuDots={menuDots}
                collectionType={activeButton}
                linkedCollectionsIds={(communityCollections as ICollection[])
                  .filter(
                    (collection) =>
                      collection.collectionType === CollectionType.USER_COLLECTION
                  )
                  .map((collection) => collection.publicId)}
              />
            </>
          )}
        </div>
        {community && (
          <AdminFollowersModal
            title={community.name}
            isOpen={isOpenAdminFollowers}
            onClose={() => setOpenAdminFollowers(false)}
            communityCreator={community.communityCreator}
            admins={community.admins}
            followers={community.members}
            type={
              community.communityType === CommunityType.open
                ? t("collections.communityCollections.public")
                : t("collections.communityCollections.private")
            }
          />
        )}
      </div>
      {isLinkCollectionToCommuniyModalOpen && (
        <LinkCollectionToCommunityModal
          isOpen={isLinkCollectionToCommuniyModalOpen}
          collectionId={collectionToLinkId}
          collectionName={collectionName}
          targetCommunityId={targetCommunityId}
          onClose={handleCloseLinkCollectionToCommunityModal}
          onSetTargetCommunityId={handleSetTargetCommunityId}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        close={() => setShareModalOpen(false)}
        customUrl={shareCollectionUrl}
        copyLink={handleCopyLink}
        share={shareSuccessMessage}
      />
    </>
  );
};

export default CommunityCollectionSingle;
