import { TransferProgressEvent } from "@azure/core-http";
import { BlockBlobClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";

import i18n from "i18next";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import fileDownload from "js-file-download";

const handleDownload = (blob: Blob | undefined, filename: string) => {
  if (!blob) return;
  fileDownload(blob, filename);
};

export const downloadFileFromBlob = async (
  name: string,
  url: string,
  downloadFile = true
) => {
  // create blobClient for container
  const blobClient = new BlockBlobClient(url);

  try {
    const downloadBlockBlobResponse = await blobClient.downloadToFile(url);
    if (downloadFile) {
      handleDownload(await downloadBlockBlobResponse.blobBody, name);
    }
    return await downloadBlockBlobResponse.blobBody;
  } catch (error) {
    if (downloadFile) {
      displayNotSuccessNotification(i18n.t("notifications.error.downloadError"));
    }
  }

  return null;
};

const uploadFileToBlob = async (
  file: File | undefined,
  url: string,
  handleUploadProgressChange: (loadedBytes: number) => void = () => null
) => {
  if (!file) return;

  // create blobClient for container
  const blobClient = new BlockBlobClient(url);

  // set mimetype as determined from browser with file upload control
  const options: BlockBlobParallelUploadOptions = {
    blobHTTPHeaders: { blobContentType: file.type },
    tags: {
      modified: "false",
    },
    blockSize: 100000,
    maxSingleShotSize: 100000,
    onProgress: (e: TransferProgressEvent) => handleUploadProgressChange(e.loadedBytes),
  };

  // upload file
  try {
    await blobClient.uploadData(file, options);
    return file;
  } catch (error) {
    displayNotSuccessNotification(i18n.t("notifications.error.uploadError"));
  }

  return null;
};

export default uploadFileToBlob;
