import { ButtonHTMLAttributes, CSSProperties, ReactNode } from "react";

import { ButtonShape } from "antd/lib/button";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import TextTruncated from "@components/TextTruncated/TextTruncated";

import { Button as AntDButton } from "antd";

import { getClassName } from "./helper";
import { IClassName } from "./type";

import styles from "./ButtonUpdated.module.scss";

export interface IButtonUpdatedProps
  extends Omit<IClassName, "styles">,
    ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  style?: CSSProperties;
  wrapperClassNames?: string;
  iconAfter?: boolean;
  buttonLengthLimit?: number;
  e2eTestId?: string;
  // AntD Button Props
  buttonShape?: ButtonShape;
  buttonSize?: SizeType;
  buttonIcon?: ReactNode;
  loading?: boolean;
  block?: boolean;
  danger?: boolean;
  ghost?: boolean;
  idElement?: string;
  customRef?: React.Ref<HTMLDivElement>;
}

export const ButtonUpdated = ({
  wrapperClassNames,
  style,
  iconAfter,
  buttonSize,
  buttonType,
  buttonShape = "round",
  buttonIcon,
  buttonLengthLimit,
  hasBorder = true,
  customButtonType,
  e2eTestId,
  textSize,
  ...props
}: IButtonUpdatedProps) => {
  const renderTitle = () => {
    if (!props.title) return;

    return buttonLengthLimit ? (
      <TextTruncated
        textToTruncate={props.title}
        length={buttonLengthLimit}
        placement="bottom"
      />
    ) : (
      <span>{props.title}</span>
    );
  };

  return (
    <div className={`${styles.container}`}>
      <AntDButton
        e2e-test-id={e2eTestId}
        className={getClassName({
          styles,
          buttonType,
          hasBorder,
          className: props.className,
          customButtonType,
          textSize,
        })}
        {...props}
        style={style}
        size={buttonSize}
        icon={iconAfter ? false : buttonIcon}
        type={buttonType}
        shape={buttonShape}
      >
        {renderTitle()}
      </AntDButton>
    </div>
  );
};
