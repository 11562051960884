/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../store/RootState";

import Button from "@components/Button/Button";
import CommunityCollectionCard from "@components/CommunityCollectionCard/CommunityCollectionCard";
import Loading from "@components/Loading/Loading";
import {
  COMMUNITY,
  INITIAL_PAGINATION,
  MANAGED_BY_ME_PAGINATION,
} from "@constants/constants";
import { COMMUNITIES_MANAGED_BY_USER } from "@graphql/community/queries";
import { ICommunitiesManagedByUser } from "@graphql/community/types";
import { ClaimsService } from "@services/ClaimsService";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { getUserTag } from "@utils/helpers";
import { ICommunityCollectionCard } from "types/CommunityCollections";
import { ICommunity } from "types/community";
import styles from "./ManagedByMe.module.scss";

const ManagedByMe = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const hasCreateCommunityClaim: boolean = ClaimsService.hasCreateCommunityClaim();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [communitiesManagedByMe, setCommunitiesManagedByMe] = useState<ICommunity[]>([]);
  const [communitiesManagedByMeTotalCount, setCommunitiesManagedByMeTotalCount] =
    useState<number>(0);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (managedByLoader) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !managedByLoader) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreManagedByMeCommunities();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const loadMoreManagedByMeCommunities = () => {
    getCommunitiesManagedByUser({
      variables: {
        userCommunitySearchOptions: {
          size: MANAGED_BY_ME_PAGINATION,
          skip: communitiesManagedByMe.length,
        },
      },
    });
  };

  const [getCommunitiesManagedByUser, { loading: managedByLoader }] =
    useLazyQuery<ICommunitiesManagedByUser>(COMMUNITIES_MANAGED_BY_USER, {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        setCommunitiesManagedByMe([
          ...communitiesManagedByMe,
          ...res.communitiesManagedByUser.communities,
        ]);
        setCommunitiesManagedByMeTotalCount(res.communitiesManagedByUser.totalCount);
        setIsLoaded(true);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  useEffect(() => {
    setCommunitiesManagedByMe([]);
    getCommunitiesManagedByUser({
      variables: {
        userCommunitySearchOptions: {
          size: MANAGED_BY_ME_PAGINATION,
          skip: INITIAL_PAGINATION,
        },
      },
    });
  }, []);

  const communityCreator = 1;

  const communitiesByMe: ICommunityCollectionCard[] = communitiesManagedByMe?.map(
    (community) => ({
      id: community.sendbirdId,
      image: community.communityImageUrl,
      name: community.name,
      numberOfMembers:
        community.members.length + community.admins.length + communityCreator ?? 0,
      role: t(getUserTag(currentUserEmail, community)),
      communityType: community.communityType,
      communityCreator: community.communityCreator,
    })
  );

  return (
    <>
      <span className={styles.sectionTitle} e2e-test-id="managed-by-me-header">
        {t("community.managedByMe")}
      </span>
      {isLoaded ? (
        <div className={styles.managedByMe} e2e-test-id="managed-by-me-section">
          {!!communitiesByMe.length && (
            <>
              {communitiesByMe.map((community) => (
                <span
                  key={community.id}
                  onClick={() => {
                    history.push(`/portal/community/community-page/${community.id}`);
                  }}
                >
                  <CommunityCollectionCard
                    from={COMMUNITY}
                    image={community.image}
                    name={community.name}
                    role={community.role}
                    numberOfMembers={community.numberOfMembers}
                    communityType={community.communityType}
                    communityCreatorEmail={community.communityCreator!.email}
                  />
                </span>
              ))}

              {communitiesManagedByMeTotalCount > communitiesManagedByMe.length && (
                <Button
                  title={t("collections.buttons.viewMore")}
                  wrapperClassNames={styles.loadMoreButton}
                  buttonIcon={<PlusOutlined />}
                  onClick={loadMoreManagedByMeCommunities}
                  loading={managedByLoader}
                  disabled={managedByLoader}
                  customRef={loadMoreElementRef}
                />
              )}
            </>
          )}

          {!communitiesByMe.length && (
            <div className={styles.emptyManagedByMe}>
              <span className={styles.noManagedByMeText}>
                {t("community.noManagedByMeCommunities")}
              </span>
              {hasCreateCommunityClaim && (
                <Button
                  title={t("community.buttonText")}
                  buttonIcon={<PlusOutlined />}
                  onClick={() => history.push("/portal/community/new")}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <Loading disableBoxShadow e2eTestId="managed-by-me-loader" />
      )}
    </>
  );
};

export default ManagedByMe;
