import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { useHistory, useParams } from "react-router";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { RootState } from "../../store/RootState";

import { ADD_FILES_TO_CHAT } from "@graphql/community/mutations";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import GET_USER from "@graphql/queries/users";

import AttachmentCard from "@components/AttachmentCard/AttachmentCard";
import ChatInput from "@components/ChatInput/ChatInput";
import CommunityHeadBar from "@components/CommunityHeadbar/CommunityHeadbar";
import CommunityMessage from "@components/CommunityMessage/CommunityMessage";
import CommunityReplyMessage from "@components/CommunityReplyMessage/CommunityReplyMessage";
import DiscussionStartMessage from "@components/DiscussionStartMessage/DiscussionStartMessage";
import EmojiPicker from "@components/EmojiPicker/EmojiPicker";
import { SearchMessagesInput } from "@components/SearchInput/SearchMessagesInput/SearchMessagesInput";
import UploadModal from "@components/UploadModal/UploadModal";
import { UploadModalType } from "@components/UploadModal/type";

import {
  ALL_DISCUSSIONS,
  ALL_DISCUSSIONS_URL,
  STORAGE_IMAGE_TYPE_USER,
} from "@constants/constants";

import { ADMIN } from "@customHooks/sendBird/constant";
import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import { useGetGroupChannel } from "@customHooks/sendBird/useGetGroupChannel/useGetGroupChannel";
import { useSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/useSendBirdGroupChannel";
import { MessageType } from "@customHooks/sendBird/useSendBirdMessage/type";
import { useSendBirdMessage } from "@customHooks/sendBird/useSendBirdMessage/useSendBirdMessage";
import { useSearchMessages } from "@customHooks/useSearchMessages";

import { SENDBIRD } from "@feature/reactGA/constant";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { supportedOfficeExtensions } from "@utils/files/constants";
import { FileHelper } from "@utils/files/fileHelper";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage from "@utils/getStorageImage";

import { MentionOption } from "types/SearchMessage";
import { IUser, IUserImageResponse, IUserResponse } from "types/user";

import avatar from "@images/avatar.svg";

import { useSendBirdMessageReply } from "@customHooks/sendBird/useSendBirdMessageReply/useSendBirdMessageReply";

import styles from "./CommunityChat.module.scss";

const CURSOR_INACTIVE = -1;
interface ICommunityChatProps {
  community: string;
  selectedMessage?: number;
  allMembers: IUser[];
  channels: Array<string>;
}

const CommunityChat = ({
  community,
  selectedMessage,
  allMembers,
  channels,
}: ICommunityChatProps) => {
  const { sb } = useSendBirdContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { channel: selectedInterest } = useParams<{ channel: string }>();

  const [message, setMessage] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmojiPickerSubmitted, setIsEmojiPickerSubmitted] = useState<boolean>(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { data: currentUserData } = useQuery<IUserResponse>(GET_USER);

  const { groupChannel, setSendBirdId } = useGetGroupChannel();
  const sendBirdGroupChannel = useSendBirdGroupChannel({ groupChannel });
  const sendBirdMessageHelper = useSendBirdMessage({ groupChannel });
  const {
    messageActions: { sendChannelMessage, setSendBirdIdCommunity },
    messageState: { messages },
  } = sendBirdMessageHelper;
  const { parentMessageData, selectParentMessage, resetParentMessage } =
    useSendBirdMessageReply({
      groupChannel,
    });
  const { actions, state, controls, helper } = useSearchMessages();
  const [emoji, setEmoji] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(CURSOR_INACTIVE);
  const [mentionedUserArray, setMentionedUserArray] = useState<MentionOption[]>([]);
  const [communityMembers, setCommunityMembers] = useState<MentionOption[]>([]);
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const [addFilesToChat] = useMutation(ADD_FILES_TO_CHAT);

  const { isUploadInProgress, uploadFiles } = useUploadFiles();

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: (res) => {
      const userPhotoInfo = res.profileImages;

      setUserImages(
        userPhotoInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const addEmojiToMessage = (emoji: string) => {
    const newMessage = message.replace(
      new RegExp(`^(.{${cursorPosition}})(.*)`),
      `$1${emoji}$2`
    );
    inputRef.current?.focus();
    setMessage(newMessage);
    setCursorPosition(cursorPosition + emoji.length);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(CURSOR_INACTIVE);
    setEmoji(false);
    setMessage(event.target.value.trimStart());

    // if we delete part of mention option remove it from setMentionedUserArray
    if (mentionedUserArray.length > 0) {
      mentionedUserArray.forEach(function (mentionOption, index) {
        const mentionedOptionValue = `@${mentionOption.value}`;
        const mentionedOptionValueIncluded = event.target.value
          .trimStart()
          .includes(mentionedOptionValue);

        !mentionedOptionValueIncluded &&
          setMentionedUserArray([...mentionedUserArray.slice(0, index)]);
      });
    }
  };

  useEffect(() => {
    allMembers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: allMembers.map((member) => member.email),
          },
        },
      });

    const prepareCommunityMembers = allMembers
      .filter((user) => user.email !== currentUserEmail)
      .map((user) => {
        return {
          value: user.fullName,
          label: user.email,
        };
      });

    setCommunityMembers(prepareCommunityMembers as MentionOption[]);
  }, [allMembers, currentUserEmail, getUserImages]);

  const checkEmail = useCallback(
    (email: string | undefined) => {
      return userImages
        ? userImages.find((image) => image.email === email)?.image
        : avatar;
    },
    [userImages]
  );

  useEffect(() => {
    setSendBirdIdCommunity(community);
    setSendBirdId(community);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  useEffect(() => {
    if (cursorPosition !== CURSOR_INACTIVE)
      inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition, message]);

  useEffect(() => {
    if (isEmojiPickerSubmitted) sendMessageOrFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmojiPickerSubmitted]);

  const finishSendingMessage = () => setLoading(false);

  const onMessageSentSuccessfully = (message: MessageType) => {
    setMessage("");
    setSelectedFile(undefined);
    history.push(
      `/portal/community/community-page/${groupChannel?.url}/${selectedInterest}/${message.messageId}`
    );
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (loading) return;
    if (message) sendMessage();
    if (selectedFile) sendFile();
  };

  const sendMessageOrFile = () => {
    if (loading) return;
    if (selectedFile) sendFile();
    if (message) sendMessage();
    if (isEmojiPickerSubmitted) setIsEmojiPickerSubmitted(false);
  };

  const sendMessage = () => {
    if (!sb || !message.trim()) return;

    const mentionedUserIdsForSendbird = mentionedUserArray.map(function (user) {
      return user.label;
    });

    const params = new sb.UserMessageParams();
    params.message = message.trimStart();
    params.mentionType = "users";
    params.mentionedUserIds = mentionedUserIdsForSendbird;
    params.customType = selectedInterest;
    if (parentMessageData) {
      params.parentMessageId = parentMessageData.messageId;
    }
    setLoading(true);

    const onError = () => finishSendingMessage();
    const onSuccess = (message: MessageType) => {
      finishSendingMessage();
      onMessageSentSuccessfully(message);
      ReactGA.event(SENDBIRD.MESSAGE_SUCCESSFULLY_SENT);
      resetParentMessage();
      setMentionedUserArray([]);
    };

    sendChannelMessage(params, onSuccess, onError);
  };

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  const sendFile = () => {
    if (!sb || !selectedFile) return;

    const params = new sb.FileMessageParams();
    if (selectedFile) {
      params.file = selectedFile;
      params.fileName = selectedFile?.name;
      params.thumbnailSizes = [
        { maxWidth: 100, maxHeight: 100 },
        { maxWidth: 200, maxHeight: 200 },
      ];
      params.customType = selectedInterest;
    }
    setLoading(true);

    const onError = () => finishSendingMessage();
    const onSuccess = (message: MessageType) => {
      finishSendingMessage();
      onMessageSentSuccessfully(message);
      ReactGA.event(SENDBIRD.MESSAGE_SUCCESSFULLY_SENT);
      resetParentMessage();
      setMentionedUserArray([]);
    };

    try {
      uploadFiles(selectedFile, BlobStorageContainer.FILES, {
        onFileUpload: async (file, directory) => {
          try {
            await addFilesToChat({
              variables: {
                addFileToChatRequest: {
                  communitySendbirdId: community,
                  discussionChannelName: selectedInterest,
                  file: {
                    name: file.name,
                    size: file.size,
                    directory,
                  },
                },
              },
            });
            sendChannelMessage(params, onSuccess, onError);
            const convertToPdfRequest = {
              fileName: file.name,
              directoryName: directory,
            };
            const fileExt = file.name!.split(".").pop();
            if (supportedOfficeExtensions.includes("." + fileExt!)) {
              await ConvertFileToPdf({
                variables: { convertToPdfRequest },
              });
            }
          } catch (error) {
            displayNotSuccessNotification(error);
          }
        },
      });
    } catch (error) {
      displayNotSuccessNotification(error);
    }
  };

  const searchMessage = useMemo(
    () => ({
      searchQuery: state.currentSearchQuery,
      addSearchMessageId: actions.addSearchMessageId,
      currentSearchMessageId: state.currentSearchMessageId,
      isMessageIdEqualToCurrentSearchMessageId:
        helper.isMessageIdEqualToCurrentSearchMessageId,
    }),
    [
      actions.addSearchMessageId,
      helper.isMessageIdEqualToCurrentSearchMessageId,
      state.currentSearchMessageId,
      state.currentSearchQuery,
    ]
  );

  const updateMessageOnMention = (value: string) => {
    setMessage(value);
  };

  const updateMentionedUserArray = (value: MentionOption) => {
    setMentionedUserArray([...mentionedUserArray, value]);
  };

  const removeSelectedParentMessage = () => {
    resetParentMessage();
    setMentionedUserArray([]);
  };

  const lastMessageRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ block: "end", behavior: "auto" });
  }, [messages.length, selectedInterest]);

  const messagesRendered = () => {
    const messagesFiltered = messages.filter(({ customType }) => {
      if (selectedInterest === ALL_DISCUSSIONS_URL) return true;
      return customType === selectedInterest;
    });

    return messagesFiltered.map((msg, index) => (
      <div
        key={msg.messageId}
        className={styles.message}
        ref={index === messagesFiltered.length - 1 ? lastMessageRef : null}
      >
        <CommunityMessage
          key={msg.messageId}
          message={msg}
          sendBirdGroupChannel={sendBirdGroupChannel}
          searchMessage={searchMessage}
          image={msg.messageType !== ADMIN && checkEmail(msg.sender?.userId)}
          sendBirdMessageHelper={sendBirdMessageHelper}
          currentUser={currentUserData?.user}
          groupChannel={groupChannel}
          selectParentMessage={selectParentMessage}
          showReplyButton={selectedInterest}
          isOptionsDisplayed={selectedInterest !== ALL_DISCUSSIONS_URL}
        />
      </div>
    ));
  };

  return (
    <>
      <div className={styles.chatPage}>
        <CommunityHeadBar
          title={groupChannel?.name}
          selectedInterest={
            selectedInterest === ALL_DISCUSSIONS_URL ? ALL_DISCUSSIONS : selectedInterest
          }
          searchInput={
            <SearchMessagesInput
              searchMessages={{ controls, actions, state, helper }}
              clearSearchMessagesDependencies={[selectedInterest]}
            />
          }
        />

        <div className={styles.messenger}>
          {selectedInterest === ALL_DISCUSSIONS_URL && !messages?.length && (
            <DiscussionStartMessage
              message={t("community.startMessage")}
              text={t("community.startText")}
            />
          )}

          <div className={styles.messages}>{messagesRendered()}</div>
        </div>

        {channels.includes(selectedInterest) && selectedInterest !== ALL_DISCUSSIONS_URL && (
          <div className={styles.input}>
            {emoji && (
              <EmojiPicker
                closeEmojiTable={() => {
                  setCursorPosition(CURSOR_INACTIVE);
                  setEmoji(false);
                }}
                addEmojiToMessage={addEmojiToMessage}
                setIsEmojiPickerSubmitted={setIsEmojiPickerSubmitted}
              />
            )}
            {selectedFile && (
              <div className={styles.attachmentBox}>
                <AttachmentCard
                  name={selectedFile.name}
                  size={FileHelper.getSizeInKB(selectedFile.size)}
                  removeFile={() => setSelectedFile(undefined)}
                />
              </div>
            )}
            {parentMessageData && (
              <div className={styles.parentMessageBox}>
                <CommunityReplyMessage
                  message={parentMessageData}
                  searchMessage={searchMessage}
                  removeSelectedParentMessage={removeSelectedParentMessage}
                  unsetRemoveIcon={true}
                  currentUser={currentUserData?.user}
                  beforeInput={true}
                />
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <ChatInput
                updateMessageOnMention={(newValue) => updateMessageOnMention(newValue)}
                updateMentionedUserArray={updateMentionedUserArray}
                optionsForMentions={communityMembers}
                value={message}
                file={selectedFile}
                removeFile={() => setSelectedFile(undefined)}
                name={selectedFile ? "inputWithAttachment" : "chat"}
                placeholder={t("chat.chatInput.message", { name: "" })}
                inputName="inputChat"
                openEmojiPicker={() => {
                  if (inputRef.current && !emoji) {
                    setCursorPosition(inputRef.current?.selectionStart);
                  }
                  setEmoji(!emoji);
                }}
                onChange={handleChange}
                onClick={sendMessageOrFile}
                openAttachments={() => setOpenModal(true)}
                inputReference={inputRef}
                fileUploading={isUploadInProgress}
              />
            </form>
          </div>
        )}
      </div>
      <UploadModal
        type={UploadModalType.ATTACMENTS}
        open={openModal}
        close={() => setOpenModal(false)}
        addFiles={(file: File[]) => setSelectedFile(file[0])}
      />
    </>
  );
};

export default CommunityChat;
