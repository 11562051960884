import { gql } from "@apollo/client";

const GET_USERS = gql`
  query users(
    $size: UInt
    $skip: UInt
    $text: String
    $includeMyself: Boolean
    $includeUsersWithChatPrivacy: Boolean
    $userRoles: [String]
  ) {
    users(
      userSearch: {
        skip: $skip
        size: $size
        text: $text
        includeMyself: $includeMyself
        includeUsersWithChatPrivacy: $includeUsersWithChatPrivacy
        userRoles: $userRoles
      }
    ) {
      totalCount
      users {
        publicId
        canReceiveMessages
        fullName
        email
        role
        isTemporary
        deactivationDate
        isActive
        isOnboarded
        memberSince
      }
    }
  }
`;
export default GET_USERS;
