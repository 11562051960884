/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { GroupBase, StylesConfig } from "react-select";

import Avatar from "@images/avatar.svg";
import palette from "@scss/exports.module.scss";
import styles from "./ChatOptions.module.scss";
// import Mute from "@images/mute.svg";
// import Warning from "@images/warning.svg";
// import ThumbsDown from "@images/thumbsDown.svg";
// import Archive from "@images/archive.svg";
import ChatWarningModal from "@components/ChatWarningModal/ChatWarningModal";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Exit from "@images/exit.svg";
import getStorageImage from "@utils/getStorageImage";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GroupChannel } from "sendbird";
import { IUserImageResponse } from "types/user";
import { leaveGroupChat } from "../../store/chatPage/actions";

import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import withUser from "../../hoc/withUser";

type isMulti = false;

const selectStyles: StylesConfig<any, isMulti> = {
  menu: () => ({
    border: "none",
    boxShadow: "none",
    background: "transparent",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: () => ({
    fontWeight: 700,
  }),
  option: () => ({
    background: "transparent",
    padding: "10px 0",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
    fontWeight: 700,
    color: palette.black,
  }),
  control: () => ({
    padding: "10px 0",
    border: "none",
    display: "flex",
    outline: "none",
    cursor: "pointer",
  }),
};

interface IChatOptions {
  groupChannel?: GroupChannel;
}

const ChatOptions = ({ groupChannel }: IChatOptions) => {
  const dispatch = useDispatch();
  const { id: groupId } = useParams<{ id: string }>();

  const history = useHistory();
  const [openLeaveGroupModal, setOpenLeaveGroupModal] = useState<boolean>(false);
  const [openArchiveGroupModal, setOpenArchiveGroupModal] = useState<boolean>(false);
  const [members, setMembers] = useState<readonly (any | GroupBase<any>)[]>();
  const { t } = useTranslation();
  const [groupLeft, setGroupLeft] = useState<boolean>(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  useEffect(() => {
    groupChannel &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: groupChannel?.members.map((member) => member.userId),
          },
        },
      });
    setMembers(
      groupChannel?.members.map((item) => ({
        value: item.userId,
        label: item.nickname,
      }))
    );
  }, [groupChannel]);

  const membersOptionLabel = ({ label, value }: any) => {
    const userAvatar = userImages
      ? userImages.find((image) => image.email === value)?.image
      : Avatar;

    return (
      <div className={styles.optionContainer} onClick={() => setSelectedUserEmail(value)}>
        <div className={styles.avatarContainer}>
          <div
            style={{ backgroundImage: `url(${userAvatar})` }}
            className={styles.avatar}
          ></div>
        </div>
        <div className={styles.label}>{label}</div>
        {/* <div className={styles.remove}>Remove</div> */}
      </div>
    );
  };

  // const settingsOptions = [
  // #842 Chat - right menu - in privacy settings: mute notifications, block messages, report conversations, archive group.
  // {
  //   value: "Mute notifications",
  //   label: t("chat.group.privacyGroup.muteNotifications"),
  //   icon: Mute,
  // },
  // {
  //   name: "Block Messages",
  //   label: t("chat.group.privacyGroup.blockMessages"),
  //   icon: Warning,
  // },
  // {
  //   name: "Report conversations",
  //   label: t("chat.group.privacyGroup.reportConversations"),
  //   icon: ThumbsDown,
  // },
  // {
  //   name: "Archive group",
  //   label: t("chat.group.privacyGroup.archiveGroup"),
  //   icon: Archive,
  //   onClick: () => setOpenArchiveGroupModal(true),
  // },
  //   {
  //     name: "Leave group",
  //     label: t("chat.group.privacySettings.leaveGroup"),
  //     icon: Exit,
  //     last: true,
  //     onClick: () => setOpenLeaveGroupModal(true),
  //   },
  // ];

  const leaveGroup = () => {
    groupChannel?.leave(function (response, error) {
      if (error) {
        console.error(error);
      }

      displaySuccessNotification(t("sendBird.groupChat.leave"));
      setGroupLeft(true);
      setOpenLeaveGroupModal(false);
      history.push("/portal/chat-page");
    });
  };

  useEffect(() => {
    dispatch(leaveGroupChat(groupLeft));
    setGroupLeft(false);
  }, [groupLeft]);

  if (!groupId) return <></>;

  const UserDrawerWithUser = withUser(UserDrawer);
  return (
    <div className={styles.container}>
      <ChatWarningModal
        open={openArchiveGroupModal}
        close={() => setOpenArchiveGroupModal(false)}
        title={`${t("chat.group.archiveGroup.title")} '${groupChannel?.name}'?`}
        subtitle={t("chat.group.archiveGroup.subtitle")}
        cancelText={t("chat.group.archiveGroup.cancelText")}
        submitText={t("chat.group.archiveGroup.submitText")}
        submit={() => setOpenArchiveGroupModal(false)}
      />
      <ChatWarningModal
        open={openLeaveGroupModal}
        close={() => setOpenLeaveGroupModal(false)}
        title={`${t("chat.group.leaveGroup.title")} '${groupChannel?.name}'?`}
        subtitle={t("chat.group.leaveGroup.subtitle")}
        cancelText={t("chat.group.leaveGroup.cancelText")}
        submitText={t("chat.group.leaveGroup.submitText")}
        submit={() => leaveGroup()}
      />
      {groupChannel && (
        <Select
          placeholder={t("chat.group.options.chatMembers")}
          options={members}
          styles={selectStyles}
          isSearchable={false}
          noOptionsMessage={() => ""}
          controlShouldRenderValue={false}
          formatOptionLabel={membersOptionLabel}
          closeMenuOnSelect={false}
        />
      )}

      {groupChannel && (
        <div
          className={styles.leaveGroupContainer}
          onClick={() => setOpenLeaveGroupModal(true)}
        >
          <img className={styles.leaveIcon} src={Exit} /> <span>Leave Group</span>
        </div>
      )}
      <UserDrawerWithUser
        email={selectedUserEmail ?? ""}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />

      {/*
      // #842 Chat - right menu - shared media
      <Select
        placeholder="Shared Media"
        options={[]}
        styles={selectStyles}
        isSearchable={false}
        controlShouldRenderValue={false}
        formatOptionLabel={membersOptionLabel}
        noOptionsMessage={() => "No shared files"}
        closeMenuOnSelect={false}
      /> */}
    </div>
  );
};

export default ChatOptions;
