import { useCallback, useState } from "react";

import SendBird, { GroupChannel } from "sendbird";
import { SendBirdMessageHelper } from "../SendBirdHelper";
import { useSendBirdContext } from "../context/context";

interface IUseSendBirdMessageReplyProps {
  groupChannel?: GroupChannel;
}

export const useSendBirdMessageReply = ({
  groupChannel,
}: IUseSendBirdMessageReplyProps) => {
  const { sb } = useSendBirdContext();
  const [parentMessageData, setParentMessageData] = useState<
    SendBird.UserMessage | SendBird.AdminMessage | SendBird.FileMessage | null
  >(null);

  const selectParentMessage = useCallback(
    async (messageId: number) => {
      const message = await SendBirdMessageHelper.getPreviousMessage(
        messageId,
        sb,
        groupChannel
      );

      if (message) {
        setParentMessageData(message);
      }
    },
    [groupChannel, sb]
  );

  const resetParentMessage = useCallback(() => {
    setParentMessageData(null);
  }, []);

  return {
    selectParentMessage,
    resetParentMessage,
    parentMessageData,
  };
};
