import Button from "@components/Button/Button";
import DragAndDropUpload from "@components/DragAndDropUpload/DragAndDropUpload";
import Modal from "@components/Modal/Modal";
import Avatar from "@images/avatar.svg";
import Camera from "@images/camera.svg";
import CancelIcon from "@images/cancelIcon.png";
import Trash from "@images/trash.svg";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import FileCheckerService from "@utils/files/fileChecker";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import { urlToObject } from "@utils/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setUserImage } from "../../store/user/actions";
import styles from "./UserPhotoModal.module.scss";

interface UserPhotoModalProps {
  isOpen: boolean;
  close: () => void;
  currentUserPhoto: string;
  setUploadedImage: (photoName: string, directoryName: string) => void;
}

const UserPhotoModal = ({
  isOpen,
  close,
  currentUserPhoto,
  setUploadedImage,
}: UserPhotoModalProps) => {
  const { t } = useTranslation();
  const [isUpdatePhotoOpen, setIsUpdatePhotoOpen] = useState<boolean>(false);
  const [choosenImage, setChoosenImage] = useState<File>();
  const dispatch = useDispatch();

  const { uploadFiles } = useUploadFiles();

  const handleUpload = async (file: File | undefined) => {
    if (!file || !(await FileCheckerService.isImageUploadable(file))) return;
    const selectedImage = {
      name: file.name,
      label: "Uploaded image",
      url: URL.createObjectURL(file),
      img: file,
    };

    if (selectedImage.img) {
      dispatch(setUserImage(selectedImage.url));
      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            setUploadedImage(file.name, directory);
          },
        });
      } catch (error) {
        displayNotSuccessNotification(error);
      }
    }
  };

  const removeProfilePicture = async () => {
    const file = await urlToObject(Avatar);
    const selectedImage = {
      name: file.name,
      label: "Uploaded image",
      url: Avatar,
      img: file,
    };

    if (selectedImage.img) {
      dispatch(setUserImage(selectedImage.url));
      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            setUploadedImage(file.name, directory);
          },
        });
      } catch (error) {
        displayNotSuccessNotification(error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} modalType="narrow">
      {isUpdatePhotoOpen ? (
        <div className={styles.uploadImageWrapper}>
          <img
            src={CancelIcon}
            className={styles.closeIcon}
            onClick={() => setIsUpdatePhotoOpen(false)}
          />
          <h4 className={styles.uploadHeaderText}>File upload</h4>
          <DragAndDropUpload type="image" setImageFunction={setChoosenImage} />
          <Button
            onClick={() => {
              handleUpload(choosenImage);
              setIsUpdatePhotoOpen(false);
              close();
            }}
            title={"Done"}
            disabled={!choosenImage}
            buttonType={"primary"}
            className={styles.uploadButton}
          />
        </div>
      ) : (
        <>
          <img
            src={CancelIcon}
            className={styles.removeIcon}
            onClick={() => {
              setIsUpdatePhotoOpen(false);
              close();
            }}
            alt=""
            aria-label="close-img"
          />
          <div className={styles.mainWrapper}>
            <div
              style={{ backgroundImage: `url(${currentUserPhoto || Avatar})` }}
              className={styles.image}
              aria-label="AvatarIcon"
            ></div>
            <div className={styles.buttonsWrapper}>
              <div
                onClick={() => setIsUpdatePhotoOpen(true)}
                className={[styles.updatePhotoButton, styles.button].join(" ")}
              >
                <img className={styles.icon} src={Camera} />
                <h6 className={styles.buttonText}>
                  {t("accountSettings.profileImage.buttons.updatePhoto")}
                </h6>
              </div>
              <div className={styles.button} onClick={() => removeProfilePicture()}>
                <img className={styles.icon} src={Trash} />
                <h6 className={styles.buttonText}>
                  {t("accountSettings.profileImage.buttons.delete")}
                </h6>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default UserPhotoModal;
