export const filterOutDuplicatedProperty = <T>(elements: Array<T>, key: keyof T) => {
  return elements.filter(
    (value, index, array) =>
      array.findIndex((value2) => value2[key] === value[key]) === index
  );
};

export const checkIfDuplicatedProperty = <T>(elements: Array<T>, key: keyof T) => {
  const values = elements.map((element) => element[key]);
  return values.length > new Set(values).size;
};

export const filterOutByProperty = <T, U extends keyof T>(
  elements: Array<T>,
  key: U,
  value: T[U]
) => {
  return elements.filter((element) => element[key] !== value);
};
