import { gql } from "@apollo/client";

const ACCEPT_OR_DECLINE_INVITATION = gql`
  mutation acceptOrDeclineCommunityJoinInvitation(
    $invitationResponse: JoinCommunityInvitationResponseInputType!
  ) {
    acceptOrDeclineCommunityJoinInvitation(invitationResponse: $invitationResponse) {
      name
      sendbirdId
    }
  }
`;

export default ACCEPT_OR_DECLINE_INVITATION;
