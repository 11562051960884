import Pill from "@components/Pill/Pill";
import { useTranslation } from "react-i18next";

import { DefaultOption } from "types/select";

import styles from "./Pills.module.scss";

interface IPillsProps {
  pills: Array<DefaultOption>;
  onPillClick?: (option: DefaultOption) => void;
  isPillClickDisabled?: boolean;
  selectedPills?: Array<DefaultOption>;
  limiter?: number;
  wrapperClassName?: string;
  showCloseButton?: boolean;
  alternativeColor?: boolean;
  ariaLabel?: string;
}

const PillsContainer = ({
  pills,
  selectedPills,
  onPillClick,
  isPillClickDisabled,
  limiter,
  wrapperClassName,
  showCloseButton,
  alternativeColor,
  ariaLabel,
}: IPillsProps) => {
  const { t } = useTranslation();

  const isSelected = (value: string) =>
    selectedPills?.some((pill) => pill.value === value);

  const renderPill = ({ value, label }: DefaultOption) => (
    <Pill
      key={value}
      ariaLabel={ariaLabel}
      alternativeColor={alternativeColor}
      value={value}
      label={label}
      onClick={onPillClick}
      disabledPillClick={isPillClickDisabled}
      selected={isSelected(value)}
      showCloseButton={showCloseButton}
    />
  );

  const renderPills = () => {
    if (!limiter) return pills.map(renderPill);

    const placeHolder = {
      label: t("pills.placeholder", {
        pillsNumber: pills.length - limiter,
      }),
      value: "",
    };

    const getPillsWithPlaceHolder = (
      acc: Array<DefaultOption>,
      option: DefaultOption,
      index: number
    ) => {
      if (index > limiter) return acc;
      if (index === limiter) return [...acc, placeHolder];
      return [...acc, option];
    };

    return pills.reduce(getPillsWithPlaceHolder, []).map(renderPill);
  };

  return (
    <div className={`${styles.pillsWrapper} ${wrapperClassName ? wrapperClassName : ""}`}>
      {renderPills()}
    </div>
  );
};

export default PillsContainer;
