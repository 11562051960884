import { gql } from "@apollo/client";

const ADD_LINK_TO_FOLDER = gql`
  mutation addLinkToFolder($linkToAdd: AddLinkToFolderInputType!) {
    addLinkToFolder(linkToAdd: $linkToAdd) {
      name
      publicId
    }
  }
`;

export default ADD_LINK_TO_FOLDER;
