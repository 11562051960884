import { gql } from "@apollo/client";

const CANCEL_COLLECTION_PENDING_INVITATION = gql`
  mutation cancelCollectionPendingInvitation(
    $cancelCollectionPendingInvitation: CancelCollectionPendingInvitationInputType!
  ) {
    cancelCollectionPendingInvitation(
      cancelCollectionPendingInvitation: $cancelCollectionPendingInvitation
    )
  }
`;

export default CANCEL_COLLECTION_PENDING_INVITATION;
