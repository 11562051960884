/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import RadioButton from "@components/RadioButton/RadioButton";
import FileCheckerService from "@utils/files/fileChecker";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import bin from "@images/bin.png";

import { IImage } from "types/image";

import styles from "./ImagePickerWithModal.module.scss";

interface IImagePickerWithModal {
  title: string;
  description?: string;
  images: IImage[];
  pickedImage: IImage;
  setPickedImage: Dispatch<SetStateAction<IImage>>;
  disabled?: boolean;
  isGlobalCurated?: boolean;
  isRequired?: boolean;
  isUpload?: boolean;
  uploadButtonId?: string;
}

const ImagePickerWithModal = ({
  title,
  description,
  images,
  pickedImage,
  setPickedImage,
  isRequired,
  disabled,
  isUpload,
  uploadButtonId,
  isGlobalCurated,
}: IImagePickerWithModal) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const [pickedImageHelper, setPickedImageHelper] = useState<IImage>({} as IImage);

  const handleUpload = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files?.length || !(await FileCheckerService.isImageUploadable(files[0]))) return;

    // set image
    setPickedImage({
      name: files[0].name,
      label: "Uploaded image",
      image: URL.createObjectURL(files[0]),
      removeIcon: true,
      checkButton: true,
      img: files[0],
    });

    // reset helper
    setPickedImageHelper({} as IImage);
  };

  const selectImage = () => {
    setPickedImage(pickedImageHelper);
    setModalOpen(false);
  };

  const removeImage = () => {
    if (disabled) return;

    setPickedImage({} as IImage);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <h6 className={styles.heading}>
          {title} {isRequired && "*"}
        </h6>
        {description && <p className={styles.description}>{description}</p>}
        {Object.keys(pickedImage).length !== 0 && (
          <div className={styles.imagePreviewWrapper}>
            <img
              className={styles.imagePreview}
              src={pickedImage.image}
              alt="selected"
              e2e-test-id="uploaded-image"
            />
            {!disabled && (
              <img
                className={styles.removeIcon}
                src={bin}
                width={35}
                height={35}
                onClick={removeImage}
                alt=""
                aria-label="img-3"
                e2e-test-id="delete-image"
              />
            )}
          </div>
        )}
        <div className={styles.buttonsWrapper}>
          {!disabled && (
            <Button
              aria-label="choose-image"
              e2eTestId="upload-image-button"
              title={
                isUpload
                  ? t("imagePickerWithModal.buttons.uploadImage")
                  : t("imagePickerWithModal.buttons.chooseImage")
              }
              uploadButton
              idElement={uploadButtonId}
              uploadOnChange={handleUpload}
            />
          )}
          {images.length > 0 && !isGlobalCurated && (
            <Button
              aria-label="choose-image"
              e2eTestId="select-image-from-library-button"
              title={t("imagePickerWithModal.buttons.selectFromLibrary")}
              onClick={() => setModalOpen(true)}
              disabled={disabled}
            />
          )}
        </div>
      </div>

      <Modal className={styles.modal} isOpen={modalOpen} modalType="primary">
        <h4 aria-label="modal-title" className={styles.title}>
          {title}
        </h4>

        <div className={images.length > 3 ? styles.gridWithFour : styles.gridWithThree}>
          {images.length > 0 &&
            images.map((image, index) => (
              <RadioButton
                aria-label="select-image"
                id={index}
                key={index}
                name={image.image}
                label={image.label}
                radioImage={image.image}
                removeIcon={image.removeIcon}
                checkButton={image.checkButton}
                checked={image.name === pickedImageHelper?.name}
                onChange={({ target: { name } }) => {
                  if (image.image === name) setPickedImageHelper(image);
                }}
                groupClassName={styles.radioGroup}
                disabled={disabled}
              />
            ))}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            title={t("imagePickerWithModal.buttons.cancel")}
            onClick={() => setModalOpen(false)}
            aria-label="cancel-btn"
          />
          <Button
            title={t("imagePickerWithModal.buttons.select")}
            disabled={Object.keys(pickedImageHelper).length === 0}
            aria-label="invite-btn"
            onClick={selectImage}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImagePickerWithModal;
