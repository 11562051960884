import { RightOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import { CollectionsTreeNode } from "@components/CollectionsTree";
import CollectionsTreeModal from "@components/CollectionsTreeModal/CollectionsTreeModal";
import {
  SORTING_A_Z,
  TREE_MODAL_TYPE,
  sortingCollectionsObj,
} from "@constants/constants";
import { GET_ALL_COLLECTIONS } from "@graphql/collection/queries";
import { ICollection, IUserCollectionResponse } from "@graphql/collection/type";
import { ReactComponent as GoToIcon } from "@images/account_tree.svg";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setBreadcrumb } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";
import { RootState } from "../../store/store";
import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentCollection, setCurrentCollection] =
    useState<Pick<ICollection, "publicId" | "name">>();

  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );

  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);
  const [allCollections, setAllCollections] = useState<Array<ICollection>>();

  useEffect(() => {
    const pathRegex = /\/portal\/collection\/([a-zA-Z0-9|-]*)\/{0,1}/;
    const collectionBreadcrumb = breadcrumbs.find((breadcrumb) =>
      breadcrumb.path.match(pathRegex)
    );

    if (collectionBreadcrumb) {
      setCurrentCollection({
        publicId: collectionBreadcrumb.id,
        name: collectionBreadcrumb.name,
      });
    } else {
      setCurrentCollection(undefined);
    }
  }, [breadcrumbs]);

  // GET ALL COLLECTIONS
  useQuery<IUserCollectionResponse>(GET_ALL_COLLECTIONS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: IUserCollectionResponse) => {
      const collections = data.user.allCollections;

      const alphabeticallySortedCollections = collections.sort(
        sortingCollectionsObj[SORTING_A_Z].sort
      );
      setAllCollections(alphabeticallySortedCollections);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const goToPath = (path: string) => {
    if (location.pathname === path) {
      return;
    }

    history.push(path);
  };

  const setCssClass = (index: number): string => {
    if (index === breadcrumbs.length - 1) {
      return styles.lastItem;
    } else {
      return styles.item;
    }
  };

  const handleGoTo = () => {
    setIsCollectionsModalOpen(true);
  };

  const handleCollectionsTreeModalCanceled = () => {
    setIsCollectionsModalOpen(false);
  };

  const handleCollectionsTreeModalSelection = (
    path: CollectionsTreeNode[] | undefined
  ) => {
    if (path && path.length) {
      const collectionPublicId = path[0].key;
      const collectionUrl = `/portal/collection/${collectionPublicId}`;
      const crumbs: IBreadcrumb[] = path.map((pathElement, index) => {
        const folderPublicId = pathElement.key ?? "";
        const destinationPath =
          index === 0 ? collectionUrl : `${collectionUrl}/${folderPublicId}`;
        return {
          id: pathElement.key as string,
          name: pathElement.title as string,
          path: destinationPath,
        };
      });

      const destination = crumbs.pop()?.path ?? "";
      dispatch(setBreadcrumb(crumbs));
      goToPath(destination);
    }
    setIsCollectionsModalOpen(false);
  };

  return (
    <>
      <Breadcrumb
        className={styles.breadCrumbsContainer}
        separator={<RightOutlined className={styles.separatorIcon} />}
      >
        <Breadcrumb.Item
          className={styles.breadCrumbStart}
          onClick={() => {
            goToPath("/portal/collections/my-collections");
          }}
        >
          {t("collections.myCollections")}
        </Breadcrumb.Item>
        {breadcrumbs &&
          breadcrumbs.map((crumb, index) => (
            <Breadcrumb.Item
              className={setCssClass(index)}
              onClick={() => {
                goToPath(crumb.path);
              }}
              key={crumb.id}
            >
              {crumb.name}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
      <ButtonUpdated
        buttonSize="middle"
        title={t("collections.buttons.goToCollection")}
        aria-label="go-to"
        buttonIcon={<GoToIcon />}
        onClick={handleGoTo}
        customButtonType="ternary"
        textSize="sm"
      />
      {isCollectionsModalOpen && (
        <CollectionsTreeModal
          isOpen={isCollectionsModalOpen}
          treeModalType={TREE_MODAL_TYPE.goTo}
          collections={allCollections}
          currentCollection={currentCollection}
          onCancel={handleCollectionsTreeModalCanceled}
          onSelect={handleCollectionsTreeModalSelection}
          modalType="primary"
        />
      )}
    </>
  );
};

export default Breadcrumbs;
