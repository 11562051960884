import { gql } from "@apollo/client";

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($user: DeactivateUserInputType!) {
    deactivateUser(user: $user) {
      email
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation activateUser($user: ActivateUserInputType!) {
    activateUser(user: $user) {
      email
    }
  }
`;
