import { CLEAR_DEFAULT_COLLECTION, SET_DEFAULT_COLLECTION } from "./constants";
import { DefaultCollectionState } from "./types";

// Actions
export const setDefaultCollection = (collection: DefaultCollectionState) => ({
  type: SET_DEFAULT_COLLECTION,
  payload: collection,
});

export const clearDefaultCollection = () => ({
  type: CLEAR_DEFAULT_COLLECTION,
  payload: null,
});
