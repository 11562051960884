import { Tabs as AntdTabs } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useHistory, useLocation } from "react-router-dom";
import { ITab } from "types/tabs";
import styles from "./Tabs.module.scss";

interface ITabsProps {
  tabs: Array<ITab>;
  size?: SizeType;
  extraContent?: React.ReactNode;
  routerTabs?: boolean;
  routePrefix?: string;
  fullWidthTabBar?: boolean;
  regularSizeTabs?: boolean;
}

const Tabs = ({
  tabs,
  size,
  extraContent,
  routerTabs,
  routePrefix,
  fullWidthTabBar,
  regularSizeTabs,
}: ITabsProps) => {
  const location = useLocation();
  const history = useHistory();

  const onTabChange = (key: string) => {
    const tabsTriggeredByRoute = tabs.filter((tab) => tab.selectedByRoutes);
    const tabFoundByKey = tabs[parseInt(key)];

    if (tabsTriggeredByRoute.length) {
      const routerTab = tabsTriggeredByRoute.find(
        (tab) => tab.name === tabFoundByKey.name
      );
      return routerTab && routerTab.selectedByRoutes
        ? history.push(`${routePrefix}${routerTab.selectedByRoutes[0]}`)
        : history.push(`${routePrefix}`);
    }

    if (!routerTabs) return;
    history.push(`${routePrefix}/${key}`);
  };

  const renderCustomTabBar = <T,>(props: T, DefaultTabBar: React.ElementType) => (
    <DefaultTabBar
      {...props}
      className={`${fullWidthTabBar ? styles.customTabPane : ""} ${
        regularSizeTabs ? styles.customTabButton : ""
      }`}
    />
  );

  const determineDefaultActiveKey = () => {
    const tabsTriggeredByRoute = tabs.filter((tab) => tab.selectedByRoutes);
    if (tabsTriggeredByRoute.length) {
      const selectedTab = tabsTriggeredByRoute.find((route) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const locationHelper = location as any;

        let tabToSelect = route.selectedByRoutes?.includes(
          location.pathname.replace(`${routePrefix}`, "")
        );

        // This is used to select tab based on the page that the user came from
        const prevPathName = locationHelper.state?.prevPath?.split("/").pop();
        if (!tabToSelect)
          tabToSelect = route.selectedByRoutes?.includes(`/${prevPathName}`);

        return tabToSelect;
      });

      return selectedTab && tabs.indexOf(selectedTab).toString();
    }
  };

  const renderExtraContent = () => {
    const selectedTab = tabs.find((route) =>
      route.selectedByRoutes?.includes(location.pathname.replace(`${routePrefix}`, ""))
    );
    const selectedTabIndex = selectedTab && tabs.indexOf(selectedTab);
    if (selectedTabIndex && !!tabs[selectedTabIndex].hideExtraContent) return;
    return extraContent;
  };

  return (
    <AntdTabs
      defaultActiveKey={determineDefaultActiveKey()}
      size={size || "large"}
      tabBarExtraContent={renderExtraContent()}
      renderTabBar={renderCustomTabBar}
      onChange={(key) => onTabChange(key)}
      destroyInactiveTabPane
      items={tabs.map((tab, index) => {
        return {
          key: tab.key || index.toString(),
          label: tab.name,
          children: tab.content,
        };
      })}
    ></AntdTabs>
  );
};

export default Tabs;
