import { gql } from "@apollo/client";

export const CANCEL_USER_DEACTIVATION = gql`
  mutation cancelTemporaryUserDeactivation(
    $user: CancelTemporaryUserDeactivationInputType!
  ) {
    cancelTemporaryUserDeactivation(user: $user) {
      email
    }
  }
`;
