import { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";

import Button from "@components/Button/Button";

// Using PNG since SVG image doesn't want to show on page. Anyways SVG is imported in images directory
import HiveLogo from "@images/hive_logo.svg";
import WHOLogo from "@images/who_logo.svg";
// Using PNG since SVG image doesn't want to show on page. Anyways SVG is imported in images directory
import HeroBee from "@images/invite-bee.png";

import styles from "./InviteOnly.module.scss";

const InviteOnly = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Delete all the cache files
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    // Clear local storage
    localStorage.clear();
    // Clear session storage
    sessionStorage.clear();
  }, []);

  return (
    <div className={styles.sorryScreenWrapper} data-testid="container">
      <div className={styles.sorryFormWrapper}>
        <div className={styles.sorryFormContainer}>
          <img className={styles.epicenterLogo} src={HiveLogo} alt="" />
          <h1 className={styles.epicenterHeading}>{t("login.inviteOnly.heading")}</h1>
          <ul>
            <li>
              <span className={styles.introText}>
                <Trans
                  i18nKey={"login.inviteOnly.reasonOne"}
                  components={{
                    redText: <span className={styles.redText}></span>,
                    blueText: <span className={styles.blueText}></span>,
                  }}
                />
              </span>
            </li>
            <li>
              <span className={styles.introText}>
                <Trans
                  i18nKey={"login.inviteOnly.reasonTwo"}
                  components={{
                    redText: <span className={styles.redText}></span>,
                  }}
                />
              </span>
            </li>
            <li>
              <span className={styles.introText}>
                <Trans
                  i18nKey={"login.inviteOnly.reasonThree"}
                  components={{
                    blueText: <span className={styles.blueText}></span>,
                  }}
                />
              </span>
            </li>
          </ul>
          <a href="/">
            <Button
              title={t("login.inviteOnly.goToHomepage")}
              buttonSize="large"
              buttonType="primary"
            />
          </a>
          <div className={styles.whoWrapper}>
            <span>{t("login.inviteOnly.poweredBy")}</span>
            <img className={styles.whoLogo} src={WHOLogo} alt="WHO Logo" />
          </div>
        </div>
      </div>
      <div className={styles.sorryImageContainer}>
        <img src={HeroBee} className={styles.placeholderImage} />
      </div>
    </div>
  );
};

export default InviteOnly;
