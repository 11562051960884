import styles from "./PageHeading.module.scss";

export interface IPageHeadingProps {
  heading: string;
  subheading: string;
}

const PageHeading = ({ heading, subheading }: IPageHeadingProps) => {
  return (
    <div className={styles.textContainer}>
      <span className={styles.heading} aria-label="heading">
        {heading}
      </span>
      <span className={styles.subheading} aria-label="subheading">
        {subheading}
      </span>
    </div>
  );
};

export default PageHeading;
