/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { RootState } from "store/store";

import Button from "@components/Button/Button";
import CreateDirectChat from "@components/CreateDirectChat/CreateDirectChat";
import CreateGroupChat from "@components/CreateGroupChat/CreateGroupChat";

import { MenuItems } from "./MenuItems/MenuItems";
import { MenuItemsGroup } from "./MenuItemsGroup/MenuItemsGroup";

import { IUseSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/type";

import { PlusOutlined } from "@ant-design/icons";

import styles from "./ChatSideMenu.module.scss";

interface IChatSideMenuProps {
  sendBirdGroupChannels: IUseSendBirdGroupChannels;
}

const ChatSideMenu = ({ sendBirdGroupChannels }: IChatSideMenuProps) => {
  const { t } = useTranslation();

  const {
    actions: { getGroupChannels },
  } = sendBirdGroupChannels;
  const isLeaveGroup = useSelector<RootState, boolean>((state) => state.chatPage.isLeave);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState<boolean>(false);
  const [openNewDirectChat, setOpenNewDirectChat] = useState<boolean>(false);

  useEffect(() => {
    getGroupChannels();
  }, [isCreateGroupModalOpen, isLeaveGroup]);

  return (
    <div className={styles.container}>
      <CreateGroupChat
        open={isCreateGroupModalOpen}
        close={() => setIsCreateGroupModalOpen(false)}
        sendBirdGroupChannels={sendBirdGroupChannels}
      />
      <CreateDirectChat
        open={openNewDirectChat}
        close={() => setOpenNewDirectChat(false)}
        sendBirdGroupChannels={sendBirdGroupChannels}
      />

      <div className={styles.headerContainer}>
        <h4>{t("chat.sidemenu.chatsLabel")}</h4>
      </div>

      <span className={styles.subtitle}>{t("chat.sidemenu.groupsLabel")}</span>
      <MenuItemsGroup sendBirdGroupChannel={sendBirdGroupChannels} />
      <Button
        wrapperClassNames={styles.wrapperButton}
        onClick={() => setIsCreateGroupModalOpen(true)}
        buttonIcon={<PlusOutlined />}
        fullWidth
        title={t("chat.sidemenu.buttonNewGroup")}
      />

      <span className={styles.subtitle}>{t("chat.sidemenu.directMessages")}</span>
      <MenuItems sendBirdGroupChannel={sendBirdGroupChannels} />
      <Button
        wrapperClassNames={styles.wrapperButton}
        onClick={() => setOpenNewDirectChat(true)}
        buttonIcon={<PlusOutlined />}
        fullWidth
        title={t("chat.sidemenu.buttonDirectChat")}
      />
    </div>
  );
};

export default ChatSideMenu;
