import { gql } from "@apollo/client";

const ADD_SUBFOLDER = gql`
  mutation addSubfolder($subfolderToAdd: AddSubfolderInputType!) {
    addSubfolder(subfolderToAdd: $subfolderToAdd) {
      name
      publicId
    }
  }
`;

export default ADD_SUBFOLDER;
