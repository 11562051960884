import { IOption } from "../components/Menu/types";
import { COLLECTIONS_GRID_SIZE } from "../constants/constants";
import { CollectionType, ICollection } from "../graphql/collection/type";

export const simulatePagination = (collections: Array<ICollection>, limiter: number) => {
  return collections ? collections.slice(0, limiter) : [];
};

export const findFirstItemFromNextPage = (
  collections: Array<ICollection>,
  limiter: number
) => simulatePagination(collections, limiter)[limiter - COLLECTIONS_GRID_SIZE].publicId;

type actionHandler = (collection: ICollection) => void;
type MenuActionsTypes = "link" | "share" | "follow" | "unfollow";
type MenuActions = Record<MenuActionsTypes, actionHandler>;

export const getMenuOptions = (
  collection: ICollection,
  userEmail: string,
  actions: Partial<MenuActions>
) => {
  if (!collection || !userEmail || !actions) {
    return [];
  }

  const isCreator = !!(collection.collectionCreator.email === userEmail);
  const isCollaborator = !!collection.collaborators?.some(
    (collaborator) => collaborator.email === userEmail
  );
  const isFollower = !!collection.followers?.some(
    (follower) => follower.email === userEmail
  );
  const otherUser = !(isCreator || isCollaborator || isFollower);

  const linkOption: IOption | false = !!actions.link &&
    collection.collectionType === CollectionType.USER_COLLECTION &&
    collection.isPublic && {
      label: "linkCollection",
      action: () => actions.link && actions.link(collection),
      e2eTestId: "link-to-community-menu-item",
    };
  const shareOption: IOption | false = !!actions.share &&
    collection.collectionType !== CollectionType.COMMUNITY_COLLECTION && {
      label: "shareCollection",
      action: () => actions.share && actions.share(collection),
      e2eTestId: "share-collection-menu-item",
    };
  const followOption: IOption | false = !!actions.follow &&
    collection.collectionType !== CollectionType.COMMUNITY_COLLECTION &&
    collection.isPublic && {
      label: "followCollection",
      action: () => actions.follow && actions.follow(collection),
      e2eTestId: "follow-collection-menu-item",
    };
  const unfollowOption: IOption | false = !!actions.unfollow &&
    collection.collectionType !== CollectionType.COMMUNITY_COLLECTION &&
    collection.isPublic && {
      label: "unfollowCollection",
      action: () => actions.unfollow && actions.unfollow(collection),
      e2eTestId: "unfollow-collection-menu-item",
    };

  return [
    ...(isCreator && linkOption ? [linkOption] : []),
    ...(isCreator && shareOption ? [shareOption] : []),

    ...(isCollaborator && linkOption ? [linkOption] : []),
    ...(isCollaborator && shareOption ? [shareOption] : []),

    ...(isFollower && linkOption ? [linkOption] : []),
    ...(isFollower && shareOption ? [shareOption] : []),
    ...(isFollower && unfollowOption ? [unfollowOption] : []),

    ...(otherUser && linkOption ? [linkOption] : []),
    ...(otherUser && shareOption ? [shareOption] : []),
    ...(otherUser && followOption ? [followOption] : []),
  ];
};
