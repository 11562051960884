import { gql } from "@apollo/client";

const ADD_LINK_TO_COLLECTION = gql`
  mutation addLinkToCollection($linkToAdd: AddLinkToCollectionInputType!) {
    addLinkToCollection(linkToAdd: $linkToAdd) {
      name
      publicId
    }
  }
`;

export default ADD_LINK_TO_COLLECTION;
