import { ReactElement } from "react";

import { useTranslation } from "react-i18next";

import { getClassName } from "./helper";

import { useReadMore } from "./useReadMore";

import styles from "./ReadMore.module.scss";

interface IReadMoreProps {
  children: ReactElement;
  isStyled?: boolean;
}

export const ReadMore = ({ children, isStyled }: IReadMoreProps) => {
  const { t } = useTranslation();
  const { textRef, shouldRenderSeeMore, isReadMore, toggleReadMore } = useReadMore();

  return (
    <>
      <div ref={textRef} className={getClassName({ isReadMore, isStyled, styles })}>
        {children}
      </div>
      {shouldRenderSeeMore && (
        <span onClick={toggleReadMore} className={styles.seeMore}>
          {isReadMore ? t("community.drawer.seeMore") : t("community.drawer.seeLess")}
        </span>
      )}
    </>
  );
};
