import { ADD_NEW_FOLDER, CLEAR_NEW_FOLDERS, REMOVE_NEW_FOLDER } from "./constants";
import { IFolder } from "./types";

// Actions
export const addNewFolder = (folder: IFolder) => ({
  type: ADD_NEW_FOLDER,
  payload: folder,
});

export const removeNewFolder = (folderName: string) => ({
  type: REMOVE_NEW_FOLDER,
  payload: { folderName },
});

export const clearAllNewFolders = () => ({
  type: CLEAR_NEW_FOLDERS,
  payload: [],
});

// Interaces
export interface IAddNewFolder {
  type: typeof ADD_NEW_FOLDER;
  payload: IFolder;
}

export interface IRemoveNewFolder {
  type: typeof REMOVE_NEW_FOLDER;
  payload: { folderName: string };
}

export interface IClearAllNewFolders {
  type: typeof CLEAR_NEW_FOLDERS;
  payload: [];
}

// Action types
export type ActionTypes = IAddNewFolder | IRemoveNewFolder | IClearAllNewFolders;
