/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@components/Button/Button";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import TextareaComponent from "@components/Textarea/Textarea";

import {
  ADD_COLLECTION,
  ADD_GLOBAL_CURATED_COLLECTION,
} from "@graphql/collection/mutation";
import { GET_MY_COLLECTIONS_NAMES } from "@graphql/collection/queries";

import { ClaimsService } from "@services/ClaimsService";
import { CLAIM_TYPES } from "../../store/user/constants";

import Avatar from "@images/avatar.svg";
import CloseMark from "@images/close_toast_black.svg";
import CuratedIcon from "@images/curated.svg";
import SearchIcon from "@images/search_icon.svg";

import {
  COLLECTION_TYPES,
  MAX_INPUT_LENGTH_MID,
  MAX_INPUT_LENGTH_SHORT,
  STORAGE_IMAGE_TYPE_USER,
} from "@constants/constants";
import { IImage } from "types/image";
import { DefaultOption } from "types/select";
import { IUser, IUserImageResponse } from "types/user";
import { defaultImages } from "./constants";

import FileCheckerService from "@utils/files/fileChecker";
import { fileNameReplaceRegex } from "@utils/helpers";

import styles from "./CollectionCreateNew.module.scss";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import ImagePickerWithModal from "@components/ImagePickerWithModal/ImagePickerWithModal";
import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { useHandleSearch } from "@customHooks/useHandleSearch/useHandleSearch";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import { IPagination, ISearchUserResponse } from "@feature/pagination/type";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import { CollectionType, IMyCollectionsResponse } from "@graphql/collection/type";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import { GET_SEARCHED_USERS_FOR_INVITE } from "@graphql/queries/usersSearch";
import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage from "@utils/getStorageImage";

interface InterestsTypes {
  name: string;
}
interface CollectionTypeItem {
  id: number;
  name: string;
  isPublic: boolean;
  label: string;
  subLabel: string;
}

type LocationProps = {
  state: {
    articleId?: string;
    redirect?: string;
    showModalIfAborted?: boolean;
  };
};

const CollectionCreateNew = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation() as unknown as LocationProps;
  const { getRoleOrganizationText } = useUserTranslationHelper();

  const isAdmin: boolean = ClaimsService.hasAllClaims([
    CLAIM_TYPES.USER_READ,
    CLAIM_TYPES.FEEDBACK_READ,
  ]);

  const collectionTypes: CollectionTypeItem[] = useMemo(() => {
    let collectionTypes = [
      {
        id: 1,
        name: COLLECTION_TYPES.PUBLIC_TYPE,
        isPublic: true,
        label: t("collections.addNewCollection.form.collectionTypeRadioPublic"),
        subLabel: t(
          "collections.addNewCollection.form.collectionTypeRadioPublicDescription"
        ),
      },
      {
        id: 2,
        name: COLLECTION_TYPES.PRIVATE_TYPE,
        isPublic: false,
        label: t("collections.addNewCollection.form.collectionTypeRadioPrivate"),
        subLabel: t(
          "collections.addNewCollection.form.collectionTypeRadioPrivateDescription"
        ),
      },
    ];

    if (isAdmin) {
      collectionTypes = [
        ...collectionTypes,
        {
          id: 3,
          name: COLLECTION_TYPES.GLOBAL_CURATED_TYPE,
          isPublic: true,
          label: (
            <span>
              {t("collections.addNewCollection.form.collectionTypeRadioGlobalCurated")}
              <img src={CuratedIcon} className={styles.curatedIcon} />
            </span>
          ),
          subLabel: t(
            "collections.addNewCollection.form.collectionTypeRadioGlobalCuratedDescription"
          ),
        },
      ];
    }

    return collectionTypes;
  }, [isAdmin]);

  const { options: interestOptions } = useInterestHelper();

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          event.target instanceof HTMLDivElement &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          setShouldRenderSearchedUsersList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { uploadFiles } = useUploadFiles();

  const [addCollection] = useMutation(ADD_COLLECTION);
  const [addGlobalCuratedCollection] = useMutation(ADD_GLOBAL_CURATED_COLLECTION);

  const [selectedInterests, setSelectedInterests] = useState<Array<InterestsTypes>>([]);
  const [collectionName, setCollectionName] = useState<string>("");

  const [isCreatingCollection, setIsCreatingCollection] = useState<boolean>(false);
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  const [selectedCollectionType, setSelectedCollectionType] =
    useState<CollectionTypeItem>(collectionTypes[0]);

  const [selectedImage, setSelectedImage] = useState<IImage>({} as IImage);

  const [shouldRenderSearchedUsersList, setShouldRenderSearchedUsersList] =
    useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<Array<IUser>>([]);
  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [users, setUsers] = useState<Array<IUser>>([]);

  const [selectedUsersAvatars, setSelectedUsersAvatars] = useState<
    { email: string; image: string }[]
  >([]);

  const isGlobalCuratedSelected = selectedCollectionType.id === 3;

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionName(event.target.value);
  };
  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const [getUsers, { loading: isGettingUsers }] = useLazyQuery<
    ISearchUserResponse,
    IPagination
  >(GET_SEARCHED_USERS_FOR_INVITE, {
    onCompleted: (data) => {
      const invitableUsersWithSelectedUsers = data.usersByFullName.users.filter(
        ({ email }) => !selectedUsers.some((e) => e.email === email)
      );

      setSearchedUsers([...invitableUsersWithSelectedUsers]);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const { handleSearch, search, searchedUsers, setSearch, setSearchedUsers } =
    useHandleSearch<ISearchUserResponse, IPagination>({
      queryFn: getUsers,
    });

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const [getMyCollection] = useLazyQuery<IMyCollectionsResponse>(
    GET_MY_COLLECTIONS_NAMES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const isExistingCollection = data.user.myCollections.some(
          (collection) =>
            collection.name.toLowerCase() === collectionName.toLowerCase().trim()
        );
        if (isExistingCollection) {
          setIsCreatingCollection(false);
        }
        createNewCollection();
      },
      onError: (error) => {
        displayServerError(error);
        setIsCreatingCollection(false);
      },
    }
  );

  const createNewCollectionHandler = () => {
    setIsCreatingCollection(true);
    getMyCollection();
  };

  const handleAddCollection = (collection: CollectionTypeItem) => {
    if (isGlobalCuratedSelected) {
      return addGlobalCuratedCollection({
        variables: {
          collection,
        },
      });
    }
    return addCollection({
      variables: {
        collection,
      },
    });
  };

  const addUser = (user: IUser, index: number, userAvatar: string) => {
    const userIds = selectedUsers.map((user) => user.email);

    if (userIds.includes(user.email)) return;

    setSelectedUsers([user, ...selectedUsers]);
    setSearch("");
    setSearchedUsers([]);

    setSelectedUsersAvatars([
      ...selectedUsersAvatars,
      { email: user.email, image: userAvatar },
    ]);

    const filteredUsers = users;
    filteredUsers.splice(index, 1);

    setUsers(filteredUsers);
  };

  const removeUser = (user: IUser, index: number) => {
    setUsers([user, ...users]);

    const filteredUsers = selectedUsers;
    filteredUsers.splice(index, 1);

    setSelectedUsers(filteredUsers);
  };

  const createNewCollection = async () => {
    if (selectedImage.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImage.img
      );

      const fileSave = await FileCheckerService.isSafe(selectedImage.img);
      if (!fileSave || !fileSize) return;
    }

    const controlledCollectionVariable = {
      name: collectionName.trim(),
      description,
      isPublic: selectedCollectionType.isPublic,
      interests: selectedInterests,
      collaboratorInvitees: selectedUsers.map((member) => member.email),
      imageName: fileNameReplaceRegex(selectedImage.name),
    };

    let imageDirectory = null;

    if (selectedImage.img) {
      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (_, directory) => {
            imageDirectory = directory;
          },
        });
      } catch (error) {
        displayNotSuccessNotification(error);
      }
    }

    const collection = {
      ...controlledCollectionVariable,
      ...(imageDirectory ? { imageDirectory } : {}),
    };

    handleAddCollection(collection as unknown as CollectionTypeItem)
      .then((r) => {
        const newCollectionId = isGlobalCuratedSelected
          ? r.data.addGlobalCuratedCollection.publicId
          : r.data.addCollection.publicId;

        const articleId = location?.state?.articleId;
        const redirect = location?.state?.redirect;

        if (articleId) {
          const location = {
            pathname: redirect,
            state: {
              newCollection: articleId,
              newCollectionId,
              collectionType: CollectionType.USER_COLLECTION,
            },
          };

          history.push(location);

          displaySuccessNotification(t("collections.addNewCollection.success"));
          return;
        }

        history.push("/portal/collections/my-collections");
        displaySuccessNotification(t("collections.addNewCollection.success"));
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        displayCustomNotSuccessNotification(errorCode);
      })
      .finally(() => setIsCreatingCollection(false));
  };

  const cancelHandler = () => {
    const articleId = location?.state?.articleId;
    const redirect = location?.state?.redirect;
    const showModalAfterCanceling = location?.state?.showModalIfAborted;

    if (articleId) {
      const location = {
        pathname: redirect,
        state: {
          newCollection: showModalAfterCanceling ? articleId : undefined,
        },
      };

      history.push(location);
      return;
    }
    history.push(redirect ? redirect : "/portal/collections/explore");
  };

  useEffect(() => {
    searchedUsers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: searchedUsers.map((member) => member.email),
          },
        },
      });
  }, [searchedUsers]);

  return (
    <div className={styles.pageWrapper} data-testid="add-new-collection-container">
      <h1 className={styles.pageTitle} e2e-test-id="new-personal-collection-form-header">
        {t("collections.addNewCollection.title")}
      </h1>
      <span className={styles.pageSubTitle} e2e-test-id="collection-form-subheader">
        {t("collections.addNewCollection.description")}
      </span>
      <form className={styles.newCommunityForm}>
        <div>
          <InputUpdated
            onChange={handleChangeName}
            name="collectionName"
            value={collectionName}
            maxLength={MAX_INPUT_LENGTH_SHORT}
            label={t("collections.addNewCollection.form.collectionNameInput")}
            aria-label="collection-name"
            e2eTestId="collection-name-input-field"
            placeholder={t(
              "collections.addNewCollection.form.collectionNameInputPlaceholder"
            )}
            additionalElement={
              !descriptionField && (
                <ButtonUpdated
                  aria-label="add-description"
                  e2eTestId="add-collection-description-button"
                  title={t("collections.addNewCollection.actions.addDescription")}
                  buttonIcon={<PlusOutlined />}
                  buttonSize="small"
                  onClick={() => setDescriptionField(true)}
                />
              )
            }
          />
          {descriptionField && (
            <div>
              <TextareaComponent
                name="description"
                e2e-test-id="collection-description-input-field"
                label={t("collections.addNewCollection.form.descriptionInput")}
                maxLength={MAX_INPUT_LENGTH_MID}
                aria-label="description-textarea"
                onChange={handleChangeDescription}
              />
            </div>
          )}
        </div>

        <div className={styles.selectWrapper}>
          <SelectComponent
            options={interestOptions}
            placeholder={t("collections.addNewCollection.form.tagsInputPlaceholder")}
            className={"tagsSearch"}
            label={t("collections.addNewCollection.form.tagsInput")}
            name={"tags"}
            e2eTestId="categories-select-container"
            showDropdown
            selectWithIcon
            icon={SearchIcon}
            isMulti
            onChange={(selectedInterestOptions: DefaultOption[]) => {
              setSelectedInterests(
                selectedInterestOptions.map(({ value }) => ({
                  name: value,
                }))
              );
            }}
            isSearchable
          />
        </div>

        <div
          className={styles.communityTypeWrapper}
          e2e-test-id="collection-type-section"
        >
          <div className={styles.collectionTypeHeading}>
            {t("collections.addNewCollection.form.collectionTypeRadio")}
          </div>
          {collectionTypes.map((collectionType) => (
            <RadioButton
              aria-label="communityType"
              id={collectionType.id}
              key={collectionType.name}
              name={collectionType.name}
              label={collectionType.label}
              subLabel={collectionType.subLabel}
              checked={collectionType.name === selectedCollectionType.name}
              onChange={() => {
                setSelectedCollectionType(collectionType);
              }}
            />
          ))}
        </div>
        <div
          onClick={() => setShouldRenderSearchedUsersList(true)}
          className={styles.selectWrapper}
        >
          <InputUpdated
            label={t("collections.addNewCollection.form.collaboratorsInput")}
            placeholder={t("community.inviteModal.searchPlaceholder")}
            type="search"
            value={search}
            onChange={(event) =>
              handleSearch(event, {
                text: event.target.value,
                size: 1000,
                skip: 0,
              })
            }
            loading={isGettingUsers}
            area="sm"
            e2eTestId="search-for-members-input"
          />
          {shouldRenderSearchedUsersList && searchedUsers.length > 0 && search && (
            <div ref={wrapperRef} className={styles.usersContainer}>
              {searchedUsers.map((user, index) => {
                const userAvatar = userImages
                  ? userImages.find((image) => image.email === user.email)?.image
                  : Avatar;

                return (
                  <div
                    onClick={() => addUser(user, index, userAvatar ? userAvatar : Avatar)}
                    className={styles.option}
                    key={user.publicId}
                    aria-label="user"
                    role="listitem"
                  >
                    <div
                      style={{ backgroundImage: `url(${userAvatar})` }}
                      className={styles.avatar}
                    ></div>
                    {user.fullName}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {selectedUsers.map((user, index) => {
          const userAvatar = userImages
            ? selectedUsersAvatars.find((image) => image.email === user.email)?.image
            : Avatar;
          return (
            <div key={index} className={styles.member}>
              <div className={styles.userDetails}>
                <div
                  style={{ backgroundImage: `url(${userAvatar})` }}
                  className={styles.avatar}
                ></div>
                <div>
                  <p className={styles.name}>{user.fullName}</p>
                  <p className={styles.role}>
                    {getRoleOrganizationText(user.organization)}
                  </p>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <span>{t("collections.addNewCollection.form.collaborator")}</span>
                <TooltipComponent
                  title={t("collections.addNewCollection.form.removeCollaboratorTooltip")}
                  tooltipType="secondary"
                  placement="bottom"
                  childToWrap={
                    <img src={CloseMark} onClick={() => removeUser(user, index)} />
                  }
                />{" "}
              </div>
            </div>
          );
        })}
        <div e2e-test-id="collection-image-section">
          <ImagePickerWithModal
            title={t("collections.addNewCollection.form.collectionImageTitle")}
            description={
              isGlobalCuratedSelected
                ? t("collections.addNewCollection.form.imageRequiredDescription")
                : ""
            }
            images={isGlobalCuratedSelected ? [] : defaultImages}
            pickedImage={selectedImage}
            setPickedImage={setSelectedImage}
            isUpload
            isRequired
            uploadButtonId={"selectedImage"}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            aria-label="create-collection-button"
            e2eTestId="create-collection-button"
            buttonType="primary"
            wrapperClassNames={styles.buttonWrapper}
            title={t("collections.addNewCollection.actions.create")}
            onClick={createNewCollectionHandler}
            disabled={
              !collectionName.trim().length ||
              !selectedInterests.length ||
              !selectedImage.image ||
              isCreatingCollection
            }
            loading={isCreatingCollection}
            fullWidth
          />
          <Button
            aria-label="cancelBtn"
            e2eTestId="create-collection-cancel-link"
            buttonType="link"
            title={t("collections.addNewCollection.actions.cancel")}
            className={styles.cancelLink}
            disabled={isCreatingCollection}
            onClick={cancelHandler}
          />
        </div>
      </form>
    </div>
  );
};

export default CollectionCreateNew;
