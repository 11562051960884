/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import globalStyles from "../../styles/Loader.module.scss";
import styles from "./PendingInvitations.module.scss";

// queries
import { ALL_PENDING_COLLECTION_COLLABORATION_INVITATIONS } from "@graphql/queries/pendingCollectionCollaborationInvitations";
import { ALL_PENDING_COMMUNITY_INVITATIONS } from "@graphql/queries/pendingCommunityInvitations";

// types
import { ICollection } from "@graphql/collection/type";
import { ICommunity } from "types/community";
import { IUser } from "types/user";

// icons
import Collections from "@images/collections.svg";
import Community from "@images/community.svg";

// child components
import { CommunityOptions } from "@modules/ChatPage/CommunityOptions/CommunityOptions";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import NoPendingInvitationsCard from "./NoPendingInvitationCard/NoPendingInvitationsCard";
import PendingInvitationsCard from "./PendingInvitationsCard/PendingInvitationsCard";
import PendingInvitationsCardEmpty from "./PendingInvitationsCardEmpty/PendingInvitationsCardEmpty";

interface ICommunitiesInvitations {
  user: IUser;
  community: ICommunity;
  status: string;
  inviter: IUser;
}

interface ICollectionsInvitations {
  user: IUser;
  collection: ICollection;
  status: string;
  inviter: IUser;
}

const Invitations = () => {
  const { t } = useTranslation();

  // user drawer
  const [user, setUser] = useState<IUser>();
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);

  // vars for storing data
  const [communitiesInvitations, setCommunitiesInvitations] = useState<
    Array<[ICommunitiesInvitations]>
  >([]);
  const [collectionsInvitations, setCollectionsInvitations] = useState<
    Array<ICollectionsInvitations>
  >([]);
  // if no invitation helper var
  const [noInvitations, setNoInvitations] = useState<boolean>(false);

  //  remove loader - for multiple requests
  const [loadingCommunityInvitations, setLoadingCommunityInvitations] =
    useState<boolean>(false);
  const [loadingCollaborationInvitations, setLoadingCollaborationInvitations] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // remove loader after all requests are completed
  useEffect(() => {
    if (loadingCommunityInvitations && loadingCollaborationInvitations) {
      setLoading(false);
    }
  }, [loadingCommunityInvitations, loadingCollaborationInvitations]);

  // get user communities invitations
  const [getPendingCommunityInvitations] = useLazyQuery(
    ALL_PENDING_COMMUNITY_INVITATIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCommunitiesInvitations(data.pendingCommunityInvitations);
        setLoadingCommunityInvitations(true);
      },
      onError: (error) => displayServerError(error),
    }
  );

  // get user collection invitations
  const [getPendingCollectionCollaborationInvitations] = useLazyQuery(
    ALL_PENDING_COLLECTION_COLLABORATION_INVITATIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionsInvitations(data.pendingCollectionCollaborationInvitations);
        setLoadingCollaborationInvitations(true);
      },
      onError: (error) => displayServerError(error),
    }
  );

  // get invitations
  useEffect(() => {
    getPendingCommunityInvitations();
    getPendingCollectionCollaborationInvitations();
  }, []);

  // check if we have any invitations, if not we render NoPendingInvitationsCard
  useEffect(() => {
    if (communitiesInvitations.length === 0 && collectionsInvitations.length === 0) {
      setNoInvitations(false);
    } else {
      setNoInvitations(true);
    }
  }, [communitiesInvitations, collectionsInvitations]);

  const renderPendingInvitationsCard = (
    resourceType: string,
    resourceIcon: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resourceData: any
  ) => {
    if (resourceType === "community") {
      return resourceData?.map((resourceItem: ICommunitiesInvitations, index: number) => (
        <PendingInvitationsCard
          key={index}
          imageType={resourceIcon}
          inviterName={resourceItem.inviter.fullName}
          inviterMail={resourceItem.inviter.email}
          text={t("invitations.invitationTextForCommunity")}
          additionalText={
            resourceItem.status === "MemberInvited"
              ? t("invitations.invitedAsCommunityMember")
              : t("invitations.invitedAsCommunityAdmin")
          }
          resourceType={resourceType}
          resourceName={resourceItem.community.name}
          resourceId={resourceItem.community.sendbirdId}
          triggerListUpdate={() => getPendingCommunityInvitations()}
          setUser={setUser}
          openUserDrawer={() => setIsUserDrawerOpen(true)}
        />
      ));
    } else if (resourceType === "collection") {
      return resourceData?.map((resourceItem: ICollectionsInvitations, index: number) => (
        <PendingInvitationsCard
          key={index}
          imageType={resourceIcon}
          inviterName={resourceItem.collection.collectionCreator.fullName}
          inviterMail={resourceItem.collection.collectionCreator.email as string}
          text={t("invitations.invitationTextForCollection")}
          additionalText={t("invitations.invitedAsCollectionCollaborator")}
          resourceType={resourceType}
          resourceName={resourceItem.collection.name}
          resourceId={resourceItem.collection.publicId}
          triggerListUpdate={() => getPendingCollectionCollaborationInvitations()}
          setUser={setUser}
          openUserDrawer={() => setIsUserDrawerOpen(true)}
        />
      ));
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <h2 className={styles.title}>{t("invitations.title")}</h2>
      {loading && (
        <div className={globalStyles.loader}>
          <Loading borderless disableBoxShadow />
        </div>
      )}

      {noInvitations && !loading ? (
        <div className={styles.pendingInvitationsContainer}>
          <>
            {/* communities section*/}
            <h3 className={styles.pendingInvitationsSectionTitle}>
              {t("invitations.communitiesTitle")}
            </h3>
            {/* render community notifications or Empty card component */}
            {communitiesInvitations.length > 0 ? (
              renderPendingInvitationsCard("community", Community, communitiesInvitations)
            ) : (
              <PendingInvitationsCardEmpty
                imageType={Community}
                text={t("invitations.noPendingInvitations.subtitleCommunity")}
              />
            )}
            {/* collections section*/}
            <h3 className={styles.pendingInvitationsSectionTitle}>
              {t("invitations.collectionsTitle")}
            </h3>
            {/* render collection notifications or Empty card component */}
            {collectionsInvitations.length > 0 ? (
              renderPendingInvitationsCard(
                "collection",
                Collections,
                collectionsInvitations
              )
            ) : (
              <PendingInvitationsCardEmpty
                imageType={Collections}
                text={t("invitations.noPendingInvitations.subtitleCollection")}
              />
            )}
          </>
        </div>
      ) : (
        !loading && <NoPendingInvitationsCard />
      )}

      {user && isUserDrawerOpen && (
        <UserDrawer
          user={user}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={<CommunityOptions sendBirdId={""} selectedUser={user} />}
        />
      )}
    </div>
  );
};

export default Invitations;
