export const EVENT_ROUTES_PREFIX = "/portal/events";

export enum EVENT {
  MY_EVENTS = "my-events",
  EXPLORE = "explore",
}

export enum MY_EVENT_SUB_SECTION {
  UPCOMING = "upcoming",
  HOSTING = "hosting",
  PAST = "past",
}

export const EVENT_ROUTES = {
  EVENTS: EVENT_ROUTES_PREFIX,
  MY_EVENTS: `${EVENT_ROUTES_PREFIX}/${EVENT.MY_EVENTS}`,
  UPCOMING: `${EVENT_ROUTES_PREFIX}/${EVENT.MY_EVENTS}/${MY_EVENT_SUB_SECTION.UPCOMING}`,
  HOSTING: `${EVENT_ROUTES_PREFIX}/${EVENT.MY_EVENTS}/${MY_EVENT_SUB_SECTION.HOSTING}`,
  PAST: `${EVENT_ROUTES_PREFIX}/${EVENT.MY_EVENTS}/${MY_EVENT_SUB_SECTION.PAST}`,
  EXPLORE: `${EVENT_ROUTES_PREFIX}/${EVENT.EXPLORE}`,
};
