import { PersonalDetails } from "store/onboarding/types";

import { initialValues } from "./constant";
import { IForm } from "./type";

export const getInitialValues = (data?: Partial<PersonalDetails>): IForm => {
  return {
    fullName: data?.fullName || initialValues.fullName,
    email: data?.email || initialValues.email,
    phone: { countryCode: data?.countryCode || "", telephone: data?.telephone || "" },
    defaultLanguage: data?.defaultLanguage || initialValues.defaultLanguage,
    pronouns: data?.pronouns || initialValues.pronouns,
    canReceiveMessages: data?.canReceiveMessages || true,
  };
};
