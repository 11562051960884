import { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { isDocumentRtl } from "@utils/checkDocumentDir";

import { IOrganization } from "types/user";

export const useUserTranslationHelper = () => {
  const { t } = useTranslation();

  const getRoleOrganizationText = useCallback(
    (organization: Pick<IOrganization, "name" | "userRole">) =>
      t("community.administration.occupation", {
        role: isDocumentRtl() ? organization.name : organization.userRole,
        organization: isDocumentRtl() ? organization.userRole : organization.name,
      }),
    [t]
  );

  return { getRoleOrganizationText };
};
