import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Button from "@components/Button/Button";
import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SLIDER_ANIMATION_TIMING } from "../../store/portal/slider";
import styles from "./ShiftButtons.module.scss";

interface IShiftButtons {
  onForwardClick: () => void;
  onBackClick: () => void;
  viewAll?: () => void;
  disabledBack?: boolean;
  disabledForward?: boolean;
  hideSeeAllButton?: boolean;
}

const ShiftButtons = ({
  onForwardClick,
  onBackClick,
  viewAll,
  disabledBack,
  disabledForward,
  hideSeeAllButton,
}: IShiftButtons) => {
  const { t } = useTranslation();
  const [animationActivated, setAnimationActivated] = useState<boolean>(false);

  useEffect(() => {
    if (animationActivated) {
      setTimeout(() => {
        setAnimationActivated(false);
        // This timeout must match number that represents animation time in Slider.tsx
        // If changed will cause conflicts with disabled state of buttons and click events for prevous/next page
        // Offset has added 50ms gap in order to last longer then animation so it can never finish before animation finishes
      }, SLIDER_ANIMATION_TIMING + 50);
    }
  }, [animationActivated]);

  return (
    <div className={styles.buttonsAndLinkContainer}>
      {!hideSeeAllButton && (
        <Button title={t("button.viewAll")} onClick={viewAll} buttonType="link" />
      )}
      <div className={styles.buttonsContainer}>
        <ButtonUpdated
          title=""
          buttonIcon={<LeftOutlined />}
          onClick={() => {
            setAnimationActivated(true);
            onBackClick();
          }}
          disabled={disabledBack || animationActivated}
          buttonShape="circle"
        />
        <ButtonUpdated
          title=""
          buttonIcon={<RightOutlined />}
          onClick={() => {
            onForwardClick();
            setAnimationActivated(true);
          }}
          disabled={disabledForward || animationActivated}
          buttonShape="circle"
        />
      </div>
    </div>
  );
};

export default ShiftButtons;
