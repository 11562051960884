import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";

import { Emoji, GroupChannel } from "sendbird";

import { checkText } from "smile2emoji";

import { useLazyQuery } from "@apollo/client";

import GET_USER_INFO from "@graphql/queries/getUserInfo";

import { CommunityOptions } from "@modules/ChatPage/CommunityOptions/CommunityOptions";

import AttachmentCard from "@components/AttachmentCard/AttachmentCard";
import CommunityReplyMessage from "@components/CommunityReplyMessage/CommunityReplyMessage";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import LinkifyMessage from "@components/LinkifyMessage/LinkifyMessage";

import { Reactions } from "@customHooks/sendBird/components/Reactions/Reactions";
import { ADMIN, FILE } from "@customHooks/sendBird/constant";
import {
  ISendBirdMessageHelper,
  MessageType,
} from "@customHooks/sendBird/useSendBirdMessage/type";

import { MessageHighlighted } from "./MessageHighlighted/MessageHighlighted";
import { MessageOptions } from "./MessageOptions/MessageOptions";

import { FileHelper } from "@utils/files/fileHelper";
import { convertTime, handleAcceptingRedirect } from "@utils/helpers";

import { ISearchMessage } from "types/SearchMessage";
import { IUser } from "types/user";

import Avatar from "@images/avatar.svg";

import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import Popover from "@components/Popover/Popover";
import styles from "./CommunityMessage.module.scss";

interface ICommunityMessageProps {
  message?: MessageType;
  messageDefault?: { name: string; message: string };
  sendBirdMessageHelper: ISendBirdMessageHelper;
  sendBirdGroupChannel?: IUseSendBirdGroupChannel;
  groupChannel?: GroupChannel;
  searchMessage?: ISearchMessage;
  image: unknown;
  currentUser?: IUser;
  startMessage?: boolean;
  description?: string;
  selectParentMessage?: (messageId: number) => void;
  showReplyButton?: string;
  isOptionsDisplayed?: boolean;
}

const CommunityMessage = ({
  message,
  messageDefault,
  sendBirdMessageHelper,
  sendBirdGroupChannel,
  groupChannel,
  searchMessage,
  image,
  currentUser,
  startMessage,
  description,
  selectParentMessage,
  showReplyButton,
  isOptionsDisplayed = true,
}: ICommunityMessageProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ community: string; channel: string; message: string }>();
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");
  const email = message?.messageType !== ADMIN ? message?.sender?.userId : "";
  const isCurrentUserMessage = currentUser !== undefined && currentUser.email === email;
  const [isOpen, setIsOpen] = useState(false);

  const [getUser, { data }] = useLazyQuery<{ userInfo: IUser }, { email: string }>(
    GET_USER_INFO,
    {
      onCompleted: () => {
        setIsUserDrawerOpen(true);
      },
      onError: displayNotSuccessNotification,
      fetchPolicy: "no-cache",
    }
  );

  const toggleReaction = useCallback(
    async ({ key }: Emoji) => {
      await sendBirdGroupChannel?.actions.toggleReaction(
        key,
        message,
        currentUser?.email
      );
    },
    [currentUser, message, sendBirdGroupChannel]
  );

  const renderMessage = () => {
    let text = messageDefault ? messageDefault.message : "";
    if (message && message.messageType !== "file") {
      text = message.message || "";
    }

    if (!message) {
      return <span aria-label="message">{checkText(text)}</span>;
    }

    return (
      message && (
        <MessageHighlighted
          mentionedUsers={message.mentionedUsers}
          message={checkText(text)}
          messageId={message.messageId}
          searchMessage={searchMessage}
        />
      )
    );
  };

  const getMessengerName = () => {
    let text = messageDefault ? messageDefault.name : "";
    if (message && message.messageType !== "admin") {
      text = message.sender?.nickname || "";
    }
    text = `${text} ${startMessage ? "" : "•"}`;

    return text;
  };

  const getSelectedUser = () => {
    if (email && !messageDefault) {
      getUser({ variables: { email } });
    }
  };

  return (
    <>
      <div
        aria-label="communityMessage"
        className={`${styles.communityMessage} ${
          isCurrentUserMessage ? styles.ownMessage : ""
        }`}
      >
        <div>
          <div
            style={{ backgroundImage: `url(${image || Avatar})` }}
            className={`${styles.avatar} ${startMessage ? styles.greetingMessage : ""}`}
            onClick={getSelectedUser}
          ></div>
        </div>
        <div className={styles.messageContainerWrapper}>
          {!messageDefault && sendBirdGroupChannel && (
            <div className={styles.reactions}>
              <Reactions
                sendBirdGroupChannel={sendBirdGroupChannel}
                toggleReaction={toggleReaction}
                reactions={message?.reactions}
                currentUser={currentUser}
              />
            </div>
          )}
          <Popover
            overlayStyle={{ padding: 0 }}
            align={{ offset: [-10, 20] }}
            showArrow={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            mouseEnterDelay={0.4}
            trigger="hover"
            placement="topRight"
            menu={
              <div className={styles.messageOptions}>
                {message && isOptionsDisplayed && (
                  <MessageOptions
                    message={message}
                    sendBirdMessageHelper={sendBirdMessageHelper}
                    toggleReaction={toggleReaction}
                    currentUser={currentUser}
                    selectParentMessage={selectParentMessage}
                    showReplyButton={showReplyButton}
                  />
                )}
              </div>
            }
            menuState={isOpen}
            setMenuState={setIsOpen}
            childToWrap={
              <div className={styles.messageContainer}>
                <div className={styles.messageInfoContainer}>
                  <span
                    onClick={getSelectedUser}
                    className={`${styles.userName} ${
                      startMessage ? styles.greetingMessage : ""
                    }`}
                  >
                    {getMessengerName()}
                  </span>
                  {message?.createdAt && (
                    <span className={styles.time}>{convertTime(message.createdAt)}</span>
                  )}
                </div>
                {window.location.pathname.includes("/portal/community/community-page") &&
                  message && (
                    <div
                      onClick={() => {
                        history.push(
                          `/portal/community/community-page/${groupChannel?.url}/${message.customType}`
                        );
                      }}
                      className={styles.discussionName}
                    >
                      {message.customType}
                    </div>
                  )}
                {message?.parentMessage && (
                  <CommunityReplyMessage
                    message={message.parentMessage}
                    searchMessage={searchMessage}
                    currentUser={currentUser}
                  />
                )}
                <span className={styles.description}>{description}</span>
                <div className={startMessage ? styles.startMessage : styles.message}>
                  <LinkifyMessage
                    messageToRender={renderMessage()}
                    openModal={() => setIsConfirmationModalOpened(true)}
                    setClickedUrl={setClickedUrl}
                  />
                  {message?.messageType === FILE && (
                    <div className={styles.fileContainer}>
                      <AttachmentCard
                        name={message.name}
                        size={FileHelper.getSizeInKB(message.size)}
                        attachmentEvent
                        downloadAttachment={() => {
                          window.location.assign(message.url);
                        }}
                      />
                    </div>
                  )}
                  <ConfirmationModal
                    isOpen={isConfirmationModalOpened}
                    accept={() =>
                      handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)
                    }
                    url={clickedUrl}
                    closeModal={() => setIsConfirmationModalOpened(false)}
                    messageTitle={t("chat.group.urlModal.title")}
                    messageContent={t("chat.group.urlModal.text")}
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
      {isUserDrawerOpen && data?.userInfo && (
        <UserDrawer
          user={data.userInfo}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={
            params.community ? (
              <CommunityOptions
                sendBirdId={params.community}
                selectedUser={data.userInfo}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
};

export default CommunityMessage;
