import { isDocumentRtl } from "@utils/checkDocumentDir";
import dayjs from "dayjs";

interface IIsActualDate {
  startDate: Date;
  endDate: Date;
}

export const isBetween = (params: IIsActualDate) => {
  const startDate = dayjs(params.startDate);
  const endDate = dayjs(params.endDate);
  const currentDate = dayjs();

  return (
    currentDate.diff(startDate, "seconds") >= 0 &&
    currentDate.diff(endDate, "seconds") < 0
  );
};

export const formatDate = (date: string) => {
  if (isDocumentRtl()) {
    return dayjs(date).format("YYYY/MM/DD");
  }

  return dayjs(date).format("DD/MM/YYYY");
};
