import React, { LegacyRef } from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SLIDER_ANIMATION_TIMING } from "../../store/portal/slider";
import styles from "./Slider.module.scss";

interface ISliderProps {
  slidesToShow?: number;
  items?: Array<React.ReactNode>;
  forwardedRef: LegacyRef<SlickSlider>;
  "aria-label"?: string;
}
const Slider = ({ slidesToShow = 4, items, forwardedRef, ...props }: ISliderProps) => {
  const settings = {
    dots: false,
    infinite: false,
    // Be cautious when changing this value.
    // It's used in setTimeout for ShiftButtons.tsx and will cause conflicts with click events and disabled states
    speed: SLIDER_ANIMATION_TIMING,
    slidesToShow,
    slidesToScroll: 4,
    currentSlide: 0,
    draggable: false,
    arrows: false,
  };

  return (
    <div className={styles.sliderContainer} aria-label={props["aria-label"]}>
      <SlickSlider ref={forwardedRef} {...settings} className={styles.slider}>
        {items?.map((item, index) => (
          <div className={styles.sliderItem} key={index}>
            {item}
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default Slider;
