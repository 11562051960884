import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";
import { useEffect, useState } from "react";
import { IFile } from "types/file";
import styles from "./FileCard.module.scss";

import {
  supportedArchiveExtensions,
  supportedDocumentExtensions,
  supportedImageExtensions,
  supportedSpreadSheetExtensions,
  supportedVideoExtensions,
} from "@utils/files/constants";

// icons
import TextTruncated from "@components/TextTruncated/TextTruncated";
import { useCardTextMaxLength } from "@customHooks/useCardTextMaxLength";
import DocumentIcon from "@images/Files/doc.svg";
import ImageIcon from "@images/Files/image.svg";
import PdfIcon from "@images/Files/pdf-large.svg";
import SpreadsheetIcon from "@images/Files/spreadsheet-large.svg";
import UniversalFileIcon from "@images/Files/universalFile.svg";
import VideoIcon from "@images/Files/video-large.svg";
import ZipIcon from "@images/Files/zip-large.svg";
import Input from "@components/Input/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileHelper } from "@utils/files/fileHelper";

interface IFileCardProps {
  id?: string;
  file: IFile;
  menuOptions?: IOption[];
  isEdit?: boolean;
  renameFile?: (fileId: string, fileName: string, fileExtension: string) => void;
}

const FileCard = ({ id, file, menuOptions, isEdit, renameFile }: IFileCardProps) => {
  const fileName = FileHelper.getFileName(file.name);
  const [fileIcon, setFileIcon] = useState("");

  const [fileNameElement, setFileNameElement] = useState<HTMLInputElement | null>(null);
  const [displayedFileName, setDisplayedFileName] = useState<string>("");

  const { maxTitleLength } = useCardTextMaxLength();

  useEffect(() => {
    const fileExtension = FileHelper.getExtension(file.originalName);

    if (supportedDocumentExtensions.includes(fileExtension) && fileExtension !== ".pdf") {
      return setFileIcon(DocumentIcon);
    }
    if (supportedImageExtensions.includes(fileExtension)) {
      return setFileIcon(ImageIcon);
    }
    if (fileExtension === ".pdf") {
      return setFileIcon(PdfIcon);
    }
    if (supportedSpreadSheetExtensions.includes(fileExtension)) {
      return setFileIcon(SpreadsheetIcon);
    }
    if (supportedVideoExtensions.includes(fileExtension)) {
      return setFileIcon(VideoIcon);
    }
    if (supportedArchiveExtensions.includes(fileExtension)) {
      return setFileIcon(ZipIcon);
    }
    return setFileIcon(UniversalFileIcon);
  }, [file.originalName]);

  useEffect(() => {
    if (file.name !== file.originalName) {
      setDisplayedFileName(`${file.name.replace(/_/g, " ")}`);
    } else {
      setDisplayedFileName(file.originalName.replace(/_/g, " "));
    }
  }, [displayedFileName, file]);

  const newFileDefaultValues = {
    validateOnMount: true,
    fileName,
  };

  const newFileForm = useFormik({
    initialValues: newFileDefaultValues,
    validationSchema: Yup.object({
      fileName: Yup.string().min(2).max(50).required(),
    }),
    onSubmit: () => {
      if (fileNameElement !== null) {
        const inputRenameValue = fileNameElement?.value;

        if (id && inputRenameValue && renameFile) {
          renameFile(id, inputRenameValue, FileHelper.getExtension(file.originalName));
        }
      }
    },
  });

  useEffect(() => {
    fileNameElement?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  // Update form and field value when name changes
  useEffect(() => {
    newFileForm.setFieldValue("fileName", fileName, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.name]);

  // Handle enter as submit action
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isEdit) {
        event.preventDefault();

        newFileForm.handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [newFileForm, isEdit]);

  return (
    <div className={styles.card}>
      {menuOptions ? (
        <div className={styles.menuWrapper}>
          <Menu options={menuOptions} />
        </div>
      ) : null}
      <img src={fileIcon} className={styles.icon} />

      {isEdit ? (
        <form>
          <Input
            inputClassNames={styles.fileNameInput}
            wrapperClassNames={styles.fileNameInputWrapper}
            setInputElement={setFileNameElement}
            maxLength={100}
            label=""
            {...newFileForm.getFieldProps("fileName")}
          />
          {FileHelper.getExtension(file.originalName)}
        </form>
      ) : (
        <TextTruncated
          textToTruncate={displayedFileName}
          length={maxTitleLength}
          placement="topLeft"
        />
      )}
    </div>
  );
};

export default FileCard;
