import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";

import { AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import axios from "axios";
import App from "./App";
import { loginRequest, msalConfig } from "./authConfig";
import "./i18n";
import "./index.css";
import AuthService from "./services/AuthService";
import store from "./store/store";

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

let client;
const msalInstance = new PublicClientApplication(msalConfig);

const link = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/api/backend/graphql`,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": false,
  },
});

const linkExternal = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/api/backend-external/ExternalGraphQL`,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": false,
  },
});

const OCP_APIM = {
  subscriptionKey: "Ocp-Apim-Subscription-Key",
  trace: "Ocp-Apim-Trace",
};

const withToken = setContext(() => {
  const account = msalInstance.getAllAccounts()[0];

  if (typeof account !== "undefined") {
    localStorage.setItem("loggedInUser", "true");
  } else {
    localStorage.setItem("loggedInUser", "false");
  }

  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh: AuthService.shouldTokenRefresh(account.idTokenClaims),
    })
    .then((response: AuthenticationResult) => {
      const token = response.idToken;
      return { token };
    })
    .catch(() => {
      msalInstance.loginRedirect(loginRequest);
    });
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  return forward(operation);
});

axios.interceptors.request.use((config) => {
  const account = msalInstance.getAllAccounts()[0];
  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh: AuthService.shouldTokenRefresh(account.idTokenClaims),
    })
    .then((response: AuthenticationResult) => {
      const token = response.idToken;
      if (
        token &&
        process.env.REACT_APP_BASE_URL &&
        config.url?.includes(process.env.REACT_APP_BASE_URL)
      ) {
        config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common[OCP_APIM.subscriptionKey] =
          process.env.REACT_APP_SUBSCRIPTION_KEY;
        config.headers.common[OCP_APIM.trace] = false;
      }
      return config;
    })
    .catch(() => {
      msalInstance.loginRedirect(loginRequest);
    });
});

const handleResponse = () => {
  if (typeof msalInstance.getAllAccounts()[0] !== "undefined") {
    localStorage.setItem("loggedInUser", "true");
    client = new ApolloClient({
      link: ApolloLink.from([withToken, authMiddleware, link]),
      cache: new InMemoryCache(),
    });

    const app = (
      <React.StrictMode>
        <ApolloProvider client={client}>
          <Router>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistStore(store)}>
                <MsalProvider instance={msalInstance}>
                  <App />
                </MsalProvider>
              </PersistGate>
            </Provider>
          </Router>
        </ApolloProvider>
      </React.StrictMode>
    );

    const rootElement = document.getElementById("root");
    if (rootElement?.hasChildNodes()) {
      ReactDOM.hydrate(app, rootElement);
    } else {
      ReactDOM.render(app, rootElement);
    }
  } else {
    localStorage.setItem("loggedInUser", "false");

    client = new ApolloClient({
      cache: new InMemoryCache(),
      link: linkExternal,
    });

    const app = (
      <React.StrictMode>
        <ApolloProvider client={client}>
          <Router>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistStore(store)}>
                <MsalProvider instance={msalInstance}>
                  <App />
                </MsalProvider>
              </PersistGate>
            </Provider>
          </Router>
        </ApolloProvider>
      </React.StrictMode>
    );

    const rootElement = document.getElementById("root");
    if (rootElement?.hasChildNodes()) {
      ReactDOM.hydrate(app, rootElement);
    } else {
      ReactDOM.render(app, rootElement);
    }
  }
};

msalInstance
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((err) => {
    console.error(err);
  });
