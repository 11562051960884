import dateIcon from "@images/iconData.svg";
import { formatDate } from "@utils/eventsHelper";
import DateTimePicker from "react-datetime-picker";

import { isDocumentRtl } from "@utils/checkDocumentDir";
import styles from "./DatePicker.module.scss";

interface IDatePickerProps {
  value: Date;
  isOpen: boolean;
  maxDate?: Date;
  minDate?: Date;
  locale: string;
  className?: string;
  open: () => void;
  close: () => void;
  onChange: (value: Date) => void;
  disabled?: boolean;
}

const DatePicker = ({
  value,
  isOpen,
  minDate,
  maxDate,
  locale,
  className,
  open,
  close,
  onChange,
  disabled,
}: IDatePickerProps) => {
  return (
    <>
      <div
        className={`${styles.datePicker} ${className}  ${
          disabled ? styles.disabled : ""
        }`}
        onClick={open}
      >
        <img src={dateIcon} alt="" className={styles.dateIcon} />
        <bdi className={styles.dateValue}>
          {formatDate(value, locale, isDocumentRtl() ? "YY MMM D" : "D MMM YY")}
        </bdi>
      </div>

      {isOpen && (
        <DateTimePicker
          value={value}
          locale={locale}
          className={styles.picker}
          disableClock
          isClockOpen={false}
          onChange={onChange}
          isCalendarOpen
          minDate={minDate || new Date()}
          maxDate={maxDate}
          onCalendarClose={close}
          disableCalendar={disabled}
        />
      )}
    </>
  );
};

export default DatePicker;
