import { gql } from "@apollo/client";

const GENERATE_USER_CODE = gql`
  mutation generateUserCode {
    generateUserCode {
      userCode
      verificationUri
    }
  }
`;

export default GENERATE_USER_CODE;
