import { useTranslation } from "react-i18next";
import { CommunityType } from "types/community";
import { IUser } from "types/user";
import styles from "./AdminFollowers.module.scss";

interface AdminFollowersProps {
  type: CommunityType;
  admins: IUser[];
  followers: IUser[];
}

const AdminFollowers = ({ type, admins, followers }: AdminFollowersProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.text}>
        {type === CommunityType.open ? (
          <p>{t("collections.communityCollections.public")}</p>
        ) : (
          <p>{t("collections.communityCollections.private")}</p>
        )}
        <span className={styles.dotMargin}>•</span>
        {admins.length === 1
          ? t("collections.communityCollections.singleAdmin", {
              count: admins.length,
            })
          : t("collections.communityCollections.multipleAdmins", {
              count: admins.length,
            })}
        <span className={styles.dotMargin}>•</span>
        {followers.length === 1
          ? t("collections.communityCollections.singleFollower", {
              count: followers.length,
            })
          : t("collections.communityCollections.multipleFollowers", {
              count: followers.length,
            })}
      </div>
    </>
  );
};

export default AdminFollowers;
