/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { GET_RECOMMENDED_GLOBAL_CURATED_COLLECTIONS } from "@graphql/collection/queries";
import RecommendedGCCSliderSection from "./RecommendedGCCSliderSection/RecommendedGCCSliderSection";

import { SORTING_MOST_RECENT } from "@constants/constants";
import {
  ICollection,
  IRecommendedGlobalCuratedCollectionsResponse,
} from "@graphql/collection/type";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

interface RecommendedGlobalCuratedCollectionsSectionProps {
  interests: string[];
  menuDots?: (collection: ICollection) => void;
}

const RecommendedGlobalCuratedCollectionsSection = ({
  interests,
  menuDots,
}: RecommendedGlobalCuratedCollectionsSectionProps) => {
  const pageSize = 4;
  const fetchSize = 4;

  const [recommendedGCC, setRecommendedGCC] = useState<ICollection[]>([]);
  const [recommendedGCCTotalCount, setRecommendedGCCTotalCount] = useState<number>(0);

  const [getRecommendedGCC, { loading: recommendedGCCLoader }] =
    useLazyQuery<IRecommendedGlobalCuratedCollectionsResponse>(
      GET_RECOMMENDED_GLOBAL_CURATED_COLLECTIONS,
      {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          setRecommendedGCC(data.recommendedGlobalCuratedCollections.collections);
          setRecommendedGCCTotalCount(
            data.recommendedGlobalCuratedCollections.totalCount
          );
        },
        onError: (error) => displayNotSuccessNotification(error),
      }
    );

  useEffect(() => {
    getRecommendedGCC({
      variables: {
        globalCuratedCollectionSearch: {
          skip: 0,
          size: fetchSize,
          text: "",
          interests,
          sortingCriteria: SORTING_MOST_RECENT,
        },
      },
    });
  }, []);

  const handleLoadMore = (sliderPage: number) => {
    getRecommendedGCC({
      variables: {
        globalCuratedCollectionSearch: {
          skip: sliderPage * pageSize,
          size: fetchSize,
          text: "",
          interests,
          sortingCriteria: SORTING_MOST_RECENT,
        },
      },
    });
  };
  return (
    <>
      {!!recommendedGCC.length && (
        <RecommendedGCCSliderSection
          collections={recommendedGCC}
          menuDots={menuDots}
          collectionsCount={recommendedGCCTotalCount}
          collectionsLoading={recommendedGCCLoader}
          pageSize={pageSize}
          loadMore={handleLoadMore}
        />
      )}
    </>
  );
};

export default RecommendedGlobalCuratedCollectionsSection;
