import Button from "@components/Button/Button";
import Calendar from "@images/Calendar.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styles from "./CreateEvent.module.scss";

const CreateEvent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img src={Calendar} width={50} height={50} />
      <span className={styles.title}>{t("createEvent.title")}</span>
      <div className={styles.textContainer}>
        <span>{t("createEvent.firstText")}</span>
        <span>{t("createEvent.secondText")}</span>
      </div>

      <Button
        title={t("createEvent.buttonText")}
        onClick={() => history.push("/portal/event/new")}
        buttonType="primary"
      />
    </div>
  );
};

export default CreateEvent;
