import { useField } from "formik";
import { useRef } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Label } from "../Label/Label";

import { ErrorBox } from "../ErrorBox/ErrorBox";

import formStyles from "../form.style.module.scss";

interface IInputQuillProps {
  label: string;
  name: string;
  placeholder: string;
  isRequired?: boolean;
}

export const InputQuill = (props: IInputQuillProps) => {
  const reactQuillRef = useRef<ReactQuill>(null);
  const [field, meta, helpers] = useField({ name: props.name });

  const handleTextChange = (text: string) => {
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor;
    const editor = reactQuillRef.current?.editor;
    if (unprivilegedEditor !== undefined && unprivilegedEditor?.getLength() > 1000) {
      editor?.deleteText(1000, unprivilegedEditor.getLength());
    }

    helpers.setValue(text);
  };

  return (
    <div className={formStyles.column}>
      {props.label && (
        <Label text={props.label} name={props.name} isRequired={props.isRequired} />
      )}
      <div onBlur={() => helpers.setTouched(true)}>
        <ReactQuill
          value={field.value}
          placeholder={props.placeholder}
          theme="snow"
          onChange={handleTextChange}
          ref={reactQuillRef}
        />
      </div>
      <ErrorBox meta={meta} />
    </div>
  );
};
