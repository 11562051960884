import { gql } from "@apollo/client";

const INVITE_MEMBERS_TO_COMMUNITY = gql`
  mutation inviteMembersToCommunity(
    $memberEmails: [String]!
    $adminEmails: [String]
    $sendbirdId: String!
  ) {
    inviteMembersToCommunity(
      inviteMembersToCommunity: {
        memberEmails: $memberEmails
        adminEmails: $adminEmails
        sendbirdId: $sendbirdId
      }
    )
  }
`;

export default INVITE_MEMBERS_TO_COMMUNITY;
