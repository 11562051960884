import { gql } from "@apollo/client";

const GET_USER = gql`
  query user {
    user {
      publicId
      fullName
      bio
      userPhotoName
      imageDirectory
      email
      telephone
      defaultLanguage
      isOnboarded
      isActive
      canReceiveMessages
      unreadNotificationsCount
      userLinkedInLink
      problemUserWouldLikeToSolve
      userSecretSuperPower
      userDefinedInterests
      pronouns
      organization {
        sector {
          customSectorName
          name
        }
        name
        userRole
      }
      country {
        name
        callingCode
      }
      countries {
        name
      }
      globalInterestLocation {
        key
      }
      interests {
        name
      }
      # hiddenArticles {
      #  articleId
      #}
      #inappropriateArticles {
      #  articleId
      #}
    }
  }
`;

export default GET_USER;
