import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import media from "css-in-js-media";
import ResizeObserver from "resize-observer-polyfill";

const ElementStyle = styled.div`
  width: 100%;
`;

const ElementAnimation = styled(motion.div)`
  ${media("<desktop")} {
    transform: none !important;
    opacity: 1 !important;
    width: 100%;
  }
  ${media(">desktop")} {
    will-change: transform, opacity;
  }
`;

interface IAnimatedSectionProps {
  children?: React.ReactNode;
}

const AnimatedSection = ({ children }: IAnimatedSectionProps) => {
  const elements = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [elementPos, setElementPos] = useState(0);

  useEffect(() => {
    if (elements.current !== null) setElementPos(elements.current.offsetTop);
  }, [elements]);

  const updateElements = useCallback((entries) => {
    for (const entry of entries) {
      setElementPos(entry.target.offsetTop);
    }
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      updateElements(entries);
    });
    elements && elements.current && resizeObserver.observe(elements.current);
    return () => resizeObserver.disconnect();
  }, [elements, updateElements]);

  return (
    <>
      <ElementStyle>
        <div ref={elements}></div>
        <ElementAnimation>{children}</ElementAnimation>
      </ElementStyle>
    </>
  );
};

export default AnimatedSection;
