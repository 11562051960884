/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import Popover from "@components/Popover/Popover";
import ShareModal from "@components/ShareModal/ShareModal";
import FLAG_ARTICLE from "@graphql/mutations/flagArticle";
import GET_USER_ARTICLES from "@graphql/queries/userArticles";
import { ReactComponent as CommentsIcon } from "@images/commentsIcon.svg";
import { ReactComponent as DownloadIcon } from "@images/downloadIcon.svg";
import { ReactComponent as InfoLogo } from "@images/moreInfo.svg";
import { ReactComponent as PlusIcon } from "@images/plusIcon_circle.svg";
import { ReactComponent as Share } from "@images/shareIcon.svg";
import ArticleService from "@services/ArticleService";
import { handleAcceptingRedirect, parseFileName } from "@utils/helpers";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { IArticle, IArticleId } from "types/article";
import { IUserArticleResponse } from "types/user";
import SingleArticleDrawer from "../SingleArticleDrawer/SingleArticleDrawer";

import AddToCollectionModal from "@components/AddToCollectionModal/AddToCollectionModal";
import BackButton from "@components/BackButton/BackButton";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Loading from "@components/Loading/Loading";
import TooltipComponent from "@components/Tooltip/Tooltip";
import {
  ARTICLE,
  ARTICLE_ID,
  ARTICLE_OPEN_ACTION,
  SEARCH_ID,
  TIMESTAMP,
  USER_ACTIVITIES_CATEGORY,
  USER_ID,
} from "@constants/googleAnalyticsConstants";
import icon from "@images/time.svg";
import { downloadFileFromBlob } from "@utils/storageBlob";
import {
  SHARE_MODAL_TYPE_ARTICLE,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import dayjs from "dayjs";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../store/store";
import styles from "./SingleArticle.module.scss";

import {
  COLLECTION_URL,
  DASHBOARD_URL,
  FLAG_ARTICLE_HIDDEN,
  FLAG_ARTICLE_INAPPROPRIATE,
  FLAG_ARTICLE_INTERESTING,
  RESOURCES_URL,
} from "@constants/constants";
import { ReactComponent as Check } from "@images/check.svg";
import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

type LocationProps = {
  pathname: string;
  state: {
    newCollection?: string;
    redirect?: string;
    currentPage?: number;
  };
};
interface ISingleArticleProps {
  setHiddenArticleIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

const SingleArticle = ({ setHiddenArticleIds }: ISingleArticleProps) => {
  const [singleArticle, setSingleArticle] = useState<IArticle>();
  const params = useParams<{ id: string }>();
  const [contentText, setContentText] = useState<JSX.Element>();
  const history = useHistory();
  const location = useLocation() as unknown as LocationProps;
  const popoverRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [menuState, setMenuState] = useState<boolean>(false);
  const [commentDrawer, setCommentDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [flag, setFlag] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [flagArticle] = useMutation(FLAG_ARTICLE);
  const [openRedirectModal, setOpenRedirectModal] = useState<boolean>(false);
  const [isOpenAddToCollection, setOpenAddToCollection] = useState<boolean>(false);
  const [copyLink, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_ARTICLE,
  });
  const searchId = useSelector<RootState, string>(
    (state) => state.searchQuery.searchEventId
  );
  const userPublicId = useSelector<RootState, string>((state) => state.user.userPublicId);
  const [downloadUrl, setDownloadUrl] = useState<string>("");

  const backToResourcesLabel = t("singleArticle.back");

  const [backToLabel, setBackToLabel] = useState(backToResourcesLabel);

  const handleScroll = useCallback(() => {
    setMenuState(false);
  }, []);

  const handleExternalLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsConfirmationModalOpened(true);
  };

  const menuItems = [
    {
      text: t("singleArticle.feedback.interesting"),
      show: true,
      flag: FLAG_ARTICLE_INTERESTING,
    },
    {
      text: t("singleArticle.feedback.inappropriate"),
      show: true,
      flag: FLAG_ARTICLE_INAPPROPRIATE,
    },
    {
      text: t("singleArticle.feedback.hidden"),
      show: true,
      flag: FLAG_ARTICLE_HIDDEN,
    },
  ];

  useEffect(() => {
    singleArticle &&
      !!getUserArticles() &&
      setDownloadUrl(
        `${process.env.REACT_APP_BASE_URL}/public-download-articles/${
          singleArticle.id
        }/${parseFileName(singleArticle.title)}.pdf`
      );
  }, [singleArticle]);

  const [getUserArticles] = useLazyQuery<IUserArticleResponse>(GET_USER_ARTICLES, {
    fetchPolicy: "no-cache",
    onCompleted: ({
      user: { hiddenArticles, inappropriateArticles, interestingArticles },
    }) => {
      const isEqualToArticleId = (article: IArticleId) =>
        article.articleId === singleArticle?.id;

      if (
        inappropriateArticles.some(isEqualToArticleId) ||
        hiddenArticles.some(isEqualToArticleId)
      )
        return history.push("/portal/resources");

      if (interestingArticles.some(isEqualToArticleId)) setFlag(FLAG_ARTICLE_INTERESTING);

      setLoading(false);
    },
    onError: (error) => {
      displayServerError(error);
      setLoading(false);
    },
  });

  useEffect(() => {
    const div = popoverRef.current;
    if (!div) return;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll, loading]);

  useEffect(() => {
    ArticleService.getArticleById(params.id)
      .then((result) => {
        const noLinkContent = result?.data?.content.replace(/<a[^>]*>|<\/a>/g, "");
        setSingleArticle({
          ...result?.data,
          content: noLinkContent,
        });
        if (noLinkContent) {
          setContentText(<div dangerouslySetInnerHTML={{ __html: noLinkContent }} />);
        }
        sendOpenedArticleValuesToGA();
      })
      .catch((error) => {
        displayServerError(error);
        history.push("/portal/resources");
      });
  }, []);

  useEffect(() => {
    if (location.state?.newCollection) {
      setOpenAddToCollection(true);
    }

    if (location.state?.redirect) {
      if (location.state.redirect.includes(COLLECTION_URL)) {
        setBackToLabel(t("singleArticle.backToCollection"));
      } else if (location.state.redirect.includes(RESOURCES_URL)) {
        setBackToLabel(t("singleArticle.backToDashboard"));
      } else if (location.state.redirect.includes(DASHBOARD_URL)) {
        setBackToLabel(t("singleArticle.backToResources"));
      } else {
        setBackToLabel(t("singleArticle.backToResults"));
      }
    }
  }, [location]);

  const handlePrevPage = () => {
    if (location.state) {
      const prevButton = location.state?.redirect;
      const prevGridPage = location.state?.currentPage;
      const newLoc = {
        pathname: prevButton ? prevButton : "/portal/resources",
        state: {
          ...location.state,
          newCollection: undefined,
          ...(prevGridPage && { currentPage: prevGridPage }),
        },
      };
      history.push(newLoc);
    } else {
      history.goBack();
    }
  };

  const secondsToMinutes = (value: string) => {
    const sec = parseInt(value, 10);
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);

    return minutes;
  };

  const sendFeedbackFlag = (flag: string) => {
    if (!singleArticle) return;
    flagArticle({
      variables: {
        article: {
          articleId: singleArticle.id,
          articleFlag: flag,
        },
      },
    })
      .then(() => {
        if (flag !== FLAG_ARTICLE_INTERESTING)
          // setHiddenArticleIds((hiddenArticleIds) => [
          //   ...hiddenArticleIds,
          //   singleArticle.id,
          // ]);

          switch (flag) {
            case FLAG_ARTICLE_INTERESTING:
              displaySuccessNotification(
                t("singleArticle.feedback.interestingToasterMessage")
              );
              break;
            case FLAG_ARTICLE_INAPPROPRIATE:
              displaySuccessNotification(
                t("singleArticle.feedback.inappropriateToasterMessage")
              );
              break;
            case FLAG_ARTICLE_HIDDEN:
              displaySuccessNotification(
                t("singleArticle.feedback.hiddenToasterMessage")
              );
              break;
            default:
              displaySuccessNotification();
              break;
          }

        setMenuState(false);
        setFlag(flag);
      })
      .catch((error) => displayServerError(error));
  };

  const menu = () =>
    menuItems.map((menuItem) => {
      const checked = flag === menuItem.flag;

      return (
        <div
          className={`${styles.menuItemWrapper} ${checked && styles.disabled}`}
          key={menuItem.text}
        >
          {menuItem.show && (
            <div
              onClick={() => sendFeedbackFlag(menuItem.flag)}
              className={styles.menuItem}
            >
              {checked && <Check className={styles.checkImage} />}
              <span>{menuItem.text}</span>
            </div>
          )}
        </div>
      );
    });

  const sendOpenedArticleValuesToGA = () => {
    const eventId = uuidv4();
    // dynamically chunked article id
    const chunkedArticleId = params.id.match(/.{1,55}/g);

    ReactGA.event({
      action: ARTICLE_OPEN_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${ARTICLE}*${USER_ID}*${userPublicId}`,
    });
    if (searchId.length) {
      ReactGA.event({
        action: ARTICLE_OPEN_ACTION,
        category: USER_ACTIVITIES_CATEGORY,
        label: `${eventId}*${ARTICLE}*${SEARCH_ID}*${searchId}`,
      });
    }
    ReactGA.event({
      action: ARTICLE_OPEN_ACTION,
      category: USER_ACTIVITIES_CATEGORY,
      label: `${eventId}*${ARTICLE}*${TIMESTAMP}*${dayjs().unix().toString()}`,
    });
    chunkedArticleId?.map((item, index) => {
      return ReactGA.event({
        action: ARTICLE_OPEN_ACTION,
        category: USER_ACTIVITIES_CATEGORY,
        label: `${eventId}*${ARTICLE}*${ARTICLE_ID}${index}*${item}`,
      });
    });
  };

  const summary = () => {
    return (
      <>
        <div className={styles.summaryContainer}>
          <span className={styles.summaryTitle}>{t("singleArticle.summary")}</span>
          <span className={styles.summaryContent}>{singleArticle?.summary}</span>
        </div>
      </>
    );
  };

  const renderOrganisationPublishTimeAndDate = () => {
    return (
      <div className={styles.publishedAndOrganisationContainer}>
        <Link
          aria-label="source-link"
          className={styles.link}
          to={""}
          onClick={handleExternalLinkClick}
        >
          {t("singleArticle.link")}
        </Link>
        <span className={styles.publishTime}>
          {dayjs(singleArticle?.published).format("DD/MM/YYYY")}
        </span>
        {singleArticle?.timeToRead && (
          <div className={styles.timeReadContainer}>
            <img src={icon} width={20} height={20} alt="" />
            <span className={styles.readingTime}>
              {secondsToMinutes(singleArticle?.timeToRead)}
              {t("singleArticle.readTime")}
            </span>
          </div>
        )}
      </div>
    );
  };

  const content = () => {
    return (
      <div aria-label={"content"} className={styles.contentContainer}>
        <span className={styles.content}>{contentText}</span>
      </div>
    );
  };

  const title = () => {
    return (
      <span aria-label={"title"} className={styles.title}>
        {singleArticle?.title}
      </span>
    );
  };

  const downloadArticle = () => {
    if (!singleArticle) return;

    const html = ReactDOMServer.renderToStaticMarkup(
      <div className={styles.container}>
        {title()}
        {summary()}
        {content()}
      </div>
    );

    const request = new XMLHttpRequest();
    request.open("GET", downloadUrl, true);
    request.send();

    request.onload = () => {
      const articleTitle = parseFileName(singleArticle.title);
      request.status === 200
        ? downloadFileFromBlob(`${articleTitle}.pdf`, downloadUrl)
        : ArticleService.uploadArticle(singleArticle.id, articleTitle, html)
            .then(() => {
              downloadFileFromBlob(`${articleTitle}.pdf`, downloadUrl);
            })
            .catch((error) => {
              displayServerError(error);
            });
    };
  };

  if (loading) return <Loading disableBoxShadow />;

  return (
    <>
      {singleArticle?.domain && (
        <ConfirmationModal
          isOpen={openRedirectModal}
          accept={() =>
            handleAcceptingRedirect(
              `https://${singleArticle?.domain}`,
              setOpenRedirectModal
            )
          }
          closeModal={() => setOpenRedirectModal(false)}
          messageTitle={t("singleArticle.urlModal.title")}
          messageContent={t("singleArticle.urlModal.text")}
        />
      )}
      <ShareModal
        copyLink={copyLink}
        open={openModal}
        close={() => setOpenModal(false)}
        share={shareSuccessMessage}
      />
      <div aria-label={"backButton"} className={styles.backButton}>
        <BackButton label={backToLabel} onClick={handlePrevPage} />
      </div>
      <div ref={popoverRef} className={styles.container}>
        {title()}
        <div className={styles.row}>
          <div>{renderOrganisationPublishTimeAndDate()}</div>
          <div className={styles.articleActionsOuter}>
            <TooltipComponent
              title={t("singleArticle.collection.addToCollectionTooltip")}
              tooltipType="secondary"
              childToWrap={
                <button
                  className={styles.addToCollectionButton}
                  aria-label="add-to-collection"
                  onClick={() => setOpenAddToCollection(true)}
                >
                  <PlusIcon />
                </button>
              }
            />
            <TooltipComponent
              title={t("singleArticle.collection.shareResourceTooltip")}
              tooltipType="secondary"
              childToWrap={
                <button
                  className={styles.addToCollectionButton}
                  aria-label="share-article"
                  onClick={() => setOpenModal(true)}
                >
                  <Share />
                </button>
              }
            />
            <TooltipComponent
              title={t("singleArticle.collection.downloadArticleTooltip")}
              tooltipType="secondary"
              childToWrap={
                <button
                  className={styles.addToCollectionButton}
                  aria-label="download-article"
                  onClick={downloadArticle}
                >
                  <DownloadIcon />
                </button>
              }
            />

            <TooltipComponent
              title={t("singleArticle.collection.writeACommentTooltio")}
              tooltipType="secondary"
              childToWrap={
                <button
                  className={styles.addToCollectionButton}
                  aria-label="share-article"
                  onClick={() => setCommentDrawer(!commentDrawer)}
                >
                  <CommentsIcon />
                </button>
              }
            />
            <div className={styles.flex}>
              <Popover
                menu={menu}
                placement="bottomLeft"
                menuState={menuState}
                className={styles.popoverContainer}
                setMenuState={(state) => setMenuState(state)}
                childToWrap={
                  <Button
                    title=""
                    buttonIcon={<InfoLogo />}
                    buttonShape="circle"
                    buttonSize="small"
                    wrapperClassNames={styles.moreMenu}
                  />
                }
              />
            </div>
          </div>
        </div>
        {singleArticle?.summary && summary()}
        {commentDrawer && singleArticle && (
          <SingleArticleDrawer
            closeInfoDrawer={() => setCommentDrawer(!commentDrawer)}
            articleId={singleArticle.id}
          />
        )}
        {content()}
        <div>
          <Link
            aria-label="source-link"
            className={styles.link}
            to={""}
            onClick={handleExternalLinkClick}
          >
            {t("singleArticle.link")}
          </Link>
        </div>
      </div>
      <AddToCollectionModal
        articleId={params.id}
        redirectToAfterAddNewCollection={location.pathname}
        isOpen={isOpenAddToCollection}
        onClose={() => setOpenAddToCollection(false)}
        showModalIfAborted={true}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() =>
          singleArticle &&
          handleAcceptingRedirect(singleArticle.url, setIsConfirmationModalOpened)
        }
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("singleArticle.urlModal.title")}
        messageContent={t("singleArticle.urlModal.text")}
      />
    </>
  );
};

export default SingleArticle;
