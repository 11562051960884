import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useTranslation } from "react-i18next";

export const SHARE_MODAL_TYPE_ARTICLE = "ARTICLE";
export const SHARE_MODAL_TYPE_EVENT = "EVENT";
export const SHARE_MODAL_TYPE_COLLECTION = "COLLECTION";

interface IShareModalHelpers {
  type: "EVENT" | "COLLECTION" | "ARTICLE";
}

export const useShareModalHelpers = ({ type }: IShareModalHelpers) => {
  const { t } = useTranslation();

  let successMessage = "";

  switch (type) {
    case SHARE_MODAL_TYPE_EVENT:
      successMessage = t("singleArticle.shareModal.shareSuccessMessageEvent");
      break;
    case SHARE_MODAL_TYPE_COLLECTION:
      successMessage = t("singleArticle.shareModal.shareSuccessMessageCollection");
      break;
    case SHARE_MODAL_TYPE_ARTICLE:
      successMessage = t("singleArticle.shareModal.shareSuccessMessageArticle");
      break;
    default:
      successMessage = t("singleArticle.shareModal.shareSuccessMessageDefault");
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
  };

  const shareSuccessMessage = () => {
    displaySuccessNotification(successMessage);
  };

  return [copyLink, shareSuccessMessage];
};
