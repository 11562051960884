import { IUser } from "types/user";

export const getInterests = (user: IUser) => {
  return [
    {
      label: "accountSettings.interestsAndSocialMedia.label.userDefinedInterests",
      value: user.userDefinedInterests,
    },
    {
      label: "accountSettings.interestsAndSocialMedia.label.userSecretSuperPower",
      value: user.userSecretSuperPower,
    },
    {
      label: "accountSettings.interestsAndSocialMedia.label.problemUserWouldLikeToSolve",
      value: user.problemUserWouldLikeToSolve,
    },
  ];
};
