import Button from "@components/Button/Button";
import TextareaComponent from "@components/Textarea/Textarea";
import send_arrow from "@images/send_arrow.svg";
import send_arrow_disabled from "@images/send_arrow_disabled.svg";
import { FormEvent } from "react";
import styles from "./CommentInput.module.scss";

interface ICommentInput {
  name: string;
  placeholder: string;
  inputName: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onClick?: (e: FormEvent) => void;
  value?: string;
  maxLength?: number;
}

const CommentInput = ({
  name,
  placeholder,
  onChange,
  onClick,
  value,
  maxLength,
}: ICommentInput) => {
  return (
    <div className={styles[name]} aria-label="container">
      <TextareaComponent
        aria-label="textarea-input"
        name={""}
        label={""}
        defaultValue={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        wrapperClassNames={styles.textAreaWrapper}
        textareaClassNames={styles.textArea}
        labelClassNames={styles.label}
      />

      <div className={styles.iconContainer}>
        <Button
          title=""
          onClick={onClick}
          wrapperClassNames={styles.chatButtonWrapper}
          buttonShape="circle"
          disabled={!value?.trim()}
          buttonIcon={
            <img
              className={styles.arrowImg}
              src={!value?.trim() ? send_arrow_disabled : send_arrow}
            />
          }
        />
      </div>
    </div>
  );
};

export default CommentInput;
