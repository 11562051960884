import { gql } from "@apollo/client";

const GET_USER_INFO_HOSTING_EVENTS = gql`
  query userInfo($email: String!, $hostingEventSearch: EventSearchInputType!) {
    userInfo(email: $email) {
      publicId
      fullName
      email
      hostingEvents(
        where: { OR: [{ startDate_gt: "now" }, { isPublic: true }] }
        hostingEventSearch: $hostingEventSearch
      ) {
        eventType
        publicId
        name
        eventImageName
        imageDirectory
        startDate
        endDate
        eventHost {
          fullName
          email
        }
      }
      hostingEventsTotalCount(
        where: { OR: [{ startDate_gt: "now" }, { isPublic: true }] }
        hostingEventSearch: $hostingEventSearch
      )
    }
  }
`;

export default GET_USER_INFO_HOSTING_EVENTS;
