import { Helmet } from "react-helmet";

import Logo from "@images/hive_logo.png";

import styles from "./Register.module.scss";

const Register = () => {
  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className={styles.header}>
        <a href="/">
          <img className={styles.logo} src={Logo} />
        </a>
      </div>
      {/* Removed as a hotfix until it is updated */}
      {/* <SignupSection /> */}
    </div>
  );
};

export default Register;
