import { gql } from "@apollo/client";

const GET_HOSTED_EVENTS = gql`
  query user {
    user {
      email
      fullName
      telephone
      isOnboarded
      hostingEvents {
        name
        description
        hostingId
        publicId
        startDate
        endDate
        eventImageName
        imageDirectory
        externalUrl
        eventType
        eventHost {
          fullName
          email
        }
      }
    }
  }
`;

export default GET_HOSTED_EVENTS;
