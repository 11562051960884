import { USER_INVITED_STATUS } from "@constants/constants";

import { ICountry } from "@feature/country";
import { Language } from "@feature/language";
import { IPagination } from "@feature/pagination/type";
import { Pronouns } from "@feature/pronouns";
import { ISector, ISectorUpdate } from "@feature/sector";
import { ICollection } from "../graphql/collection/type";
import { IArticleId } from "./article";
import { ICommunity } from "./community";
import { IEvent } from "./event";

export interface IOrganization {
  name: string;
  userRole: string;
  sector: ISector;
  __typename?: string;
}

export interface IOrganizationUpdate
  extends Omit<IOrganization, "__typename" | "sector"> {
  sector: ISectorUpdate;
}

export interface IUpdateUserRequest extends Omit<IUser, "organization"> {
  workLocation: ICountry;
  organization: IOrganizationUpdate;
}

export interface IUser {
  publicId: string;
  fullName: string;
  bio?: string;
  country: ICountry;
  status?: USER_INVITED_STATUS;
  userPhotoName?: string;
  imageDirectory?: string;
  userDefinedInterests?: string;
  userSecretSuperPower?: string;
  problemUserWouldLikeToSolve?: string;
  userLinkedInLink?: string;
  email: string;
  pronouns: Pronouns;
  telephone: string;
  defaultLanguage: Language;
  isOnboarded: boolean;
  memberSince: string;
  isActive: boolean;
  role: string;
  canReceiveMessages: boolean;
  organization: IOrganization;
  interests: Array<{ name: string; __typename: string }>;
  locations: Array<{ country: string; __typename: string }>;
  countries: Array<{ name: string; __typename: string }>;
  globalInterestLocation?: Record<string, unknown> | null;
  cities: Array<{ name: string; __typename: string }>;
  hiddenArticles: Array<{ articleId: string }>;
  inappropriateArticles: Array<{ articleId: string }>;
  hostingEvents?: IEvent[];
  hostingAndAttendingUpcomingEvents?: IEvent[];
  myCollections?: ICollection[];
  communities?: ICommunity[];
  deactivationDate?: string;
  isTemporary?: boolean;
  callingCode?: string;
  unreadNotificationsCount?: number;
}

export interface IUserResponse {
  user: IUser;
}

export interface IUserArticleResponse {
  user: {
    inappropriateArticles: IArticleId[];
    hiddenArticles: IArticleId[];
    interestingArticles: IArticleId[];
  };
}

export interface IUsersResponse {
  users: {
    totalCount: number;
    users: Array<IUser>;
  };
}

export interface IUserCommunityResponse {
  users: {
    totalCount: number;
    users: Array<ICommunityUser>;
  };
}

export interface ICommunityUser {
  publicId: string;
  fullName: string;
  email: string;
  canReceiveMessages: boolean;
  organization: {
    name: string;
    userRole: string;
    sector: {
      name: string;
    };
  };
  interests: Array<{ name: string; __typename: string }>;
}

export interface IPartialUser {
  email: string;
  fullName: string;
}

export interface ISearchedUser {
  email: string;
  fullName: string;
  isActive: boolean;
  isOnboarded: boolean;
  memberSince: string;
  canReceiveMessages: boolean;
  organization: {
    name: string;
    userRole: string;
  };
  deactivationDate: string;
  isTemporary: string;
  role: string;
  publicId: string;
}

export interface IUserDashboard
  extends Pick<
    IUser,
    "publicId" | "fullName" | "email" | "bio" | "imageDirectory" | "userPhotoName"
  > {
  plainTextBio: string;
  organization: Omit<IOrganization, "sector">;
  country: Omit<ICountry, "callingCode">;
}

export interface ISearchedUserResponse {
  users: {
    totalCount: number;
    users: Array<ISearchedUser>;
  };
}

export interface IInvitableUsersSearchResponseCommunity {
  invitableUsersToCommunity: Array<IUser>;
}

interface IInvitableUsersSearchCommunity extends IPagination {
  sendbirdId?: string;
}

export interface IInvitableUsersSearchRequestCommunity {
  invitableUsersSearch: IInvitableUsersSearchCommunity;
}

interface IInvitableUsersSearchCollection extends IPagination {
  publicId: string;
}

export interface IInvitableUsersSearchRequestCollection {
  invitableUsersSearch: IInvitableUsersSearchCollection;
}

export interface IInvitableUsersSearchResponseCollection {
  invitableUsersToCollection: Array<IUser>;
}

export interface IChatUsersResponse {
  users: {
    totalCount: number;
    users: Array<IPartialUser>;
  };
}

export type TableUser = {
  // status: boolean;
  email: string;
  role: string;
  user: {
    name: string;
    status: boolean;
  };
  memberSince: string;
};

export type UserImage = {
  userPhotoName: string;
  imageDirectory: string;
  email: string;
};

export interface IUserImageResponse {
  profileImages: Array<{
    userPhotoName: string;
    imageDirectory: string;
    email: string;
  }>;
}

export interface IUserImageRequest {
  profileImagesInput: {
    userEmails: Array<string>;
  };
}

export const EMPTY_USER = {
  publicId: "",
  fullName: "",
  pronouns: Pronouns.NONE,
  country: {
    name: "",
    callingCode: "",
  },
  email: "",
  telephone: "",
  defaultLanguage: Language.ENGLISH,
  canReceiveMessages: false,
  isOnboarded: true,
  role: "",
  countries: [],
  interests: [],
  organization: {
    name: "",
    sector: {
      name: "",
      customSectorName: "",
    },
    userRole: "",
  },
  isActive: true,
  locations: [],
  cities: [],
  memberSince: "",
  hiddenArticles: [],
  inappropriateArticles: [],
};
