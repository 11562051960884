import { truncateStringIfTooLong } from "@utils/helpers";
import { TooltipPlacement } from "antd/lib/tooltip";

import TooltipComponent from "@components/Tooltip/Tooltip";

interface ITextTruncated {
  textToTruncate: string;
  length: number;
  placement?: TooltipPlacement;
}
const TextTruncated = ({ textToTruncate, length, placement }: ITextTruncated) => {
  const text = truncateStringIfTooLong(textToTruncate, length);
  return (
    <>
      {textToTruncate && textToTruncate.length > length ? (
        <TooltipComponent
          tooltipType="secondary"
          title={textToTruncate}
          childToWrap={text}
          placement={placement || "top"}
        />
      ) : (
        <span>{textToTruncate}</span>
      )}
    </>
  );
};

export default TextTruncated;
