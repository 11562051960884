import { Feedback } from "@components/DataDisplay/Feedback/Feedback";

import { useTranslation } from "react-i18next";

import styles from "./LiveHeader.module.scss";

interface ILiveHeaderProps {
  name: string;
}

export const LiveHeader = ({ name }: ILiveHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div aria-label="live-container" className={styles.container}>
      <div>
        <h4 className={styles.title}>{name}</h4>
      </div>
      <div className={styles.feedBack}>
        <Feedback translationKey="eventDetail.liveButton" />
        <span className={styles.subtitle}>{t("eventDetail.liveSubtitle")}</span>
      </div>
    </div>
  );
};
