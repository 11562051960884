import Button from "@components/Button/Button";
import AttachmentIcon from "@images/attachment_icon.svg";
import emoji from "@images/emoji.svg";
import sendArrow from "@images/send_arrow.svg";
import disabledSendArrow from "@images/send_arrow_disabled.svg";
import React, { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ChatInput.module.scss";

interface MentionOption {
  value: string;
  label: string;
}

export interface IChatInput {
  name: string;
  placeholder: string;
  inputName: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  inputReference: RefObject<HTMLTextAreaElement>;
  file?: File;
  onClick?: () => void;
  openAttachments?: () => void;
  removeFile?: () => void;
  value?: string;
  openEmojiPicker?: () => void;
  optionsForMentions?: MentionOption[];
  updateMessageOnMention?: (newValue: string) => void;
  updateMentionedUserArray?: (mentionedUserArray: MentionOption) => void;
  fileUploading?: boolean;
}

const ChatInput = ({
  name,
  placeholder,
  onChange,
  onClick,
  value,
  file,
  openAttachments,
  inputReference,
  inputName,
  openEmojiPicker,
  optionsForMentions,
  updateMessageOnMention,
  updateMentionedUserArray,
  fileUploading,
}: IChatInput) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<MentionOption[]>([]);

  useEffect(() => {
    if (typeof value !== "undefined") setTextAreaValue(value);
  }, [value]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      setShowDropdown(false);
      onClick && onClick();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setTextAreaValue(newText);

    if (newText.endsWith("  ") || newText === "") {
      setShowDropdown(false);
      return;
    }

    if (newText.endsWith("@") && optionsForMentions) {
      setShowDropdown(true);
      setFilteredOptions(optionsForMentions);
      return;
    }

    if (showDropdown && optionsForMentions) {
      setFilteredOptions(
        optionsForMentions.filter((option) =>
          option.value
            .toLowerCase()
            .startsWith(newText.slice(newText.lastIndexOf("@") + 1).toLowerCase())
        )
      );
    }

    onChange?.(event);
  };

  useEffect(() => {
    if (!filteredOptions.length) {
      setShowDropdown(false);
    }
  }, [filteredOptions]);

  const handleMentionClick = (mention: MentionOption) => {
    if (inputReference.current) {
      setTextAreaValue(
        textAreaValue.slice(0, textAreaValue.lastIndexOf("@")) + `@${mention.value} `
      );
      setShowDropdown(false);
      inputReference.current!.focus();
    }

    updateMentionedUserArray?.(mention);

    updateMessageOnMention?.(
      textAreaValue.slice(0, textAreaValue.lastIndexOf("@")) + `@${mention.value} `
    );
  };

  return (
    <div className={styles[name]} aria-label="container">
      <textarea
        ref={inputReference}
        rows={1}
        value={textAreaValue}
        onKeyDown={onKeyPress}
        onChange={handleChange}
        placeholder={file ? "" : placeholder}
        className={styles[inputName]}
        aria-label="text-area"
      />
      {showDropdown && (
        <div className={styles.mentions}>
          {filteredOptions.map((mention) => (
            <span
              className={styles.item}
              key={mention.label}
              onClick={() => handleMentionClick(mention)}
            >
              <span className={styles.letter}>
                {mention.value.charAt(0).toUpperCase()}
              </span>
              {mention.value}
            </span>
          ))}
        </div>
      )}
      <div className={styles.iconContainer}>
        <Button
          idElement={"buttonEmoji"}
          buttonShape="circle"
          onClick={openEmojiPicker}
          wrapperClassNames={styles.chatButtonWrapper}
          buttonIcon={<img id={"imgEmoji"} src={emoji} alt="" />}
          data-testid="emoji-button"
        />
        <Button
          buttonShape="circle"
          onClick={openAttachments}
          wrapperClassNames={styles.chatButtonWrapper}
          buttonIcon={<img src={AttachmentIcon} alt="" />}
          data-testid="attachment-button"
        />
        <Button
          title={t("chat.chatInput.buttonTitle")}
          onClick={onClick}
          wrapperClassNames={styles.chatButtonWrapper}
          className={styles.buttonSend}
          buttonShape="round"
          disabled={(!value && !file) || fileUploading}
          iconAfter
          icon={
            <img
              className={styles.arrowImg}
              src={(!value && !file) || fileUploading ? disabledSendArrow : sendArrow}
              alt=""
            />
          }
          data-testid="click-button"
        />
      </div>
    </div>
  );
};

export default ChatInput;
