import * as yup from "yup";

import { stringValidation } from "@utils/yup/string";

export const getValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    pronouns: pronounsValidation(t),
  });

export const pronounsValidation = (t: (value: string) => string) => stringValidation(t);
