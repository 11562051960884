import MetricsTemplate from "@images/metrics-card-template.png";

interface IEventMetricsCard {
  name: string;
  eventType: string;
  date: string;
  attendeesTotalCount: number;
  attendeesCountriesTotalCount: number;
}

const EventMetricsCard = ({
  name,
  eventType,
  date,
  attendeesTotalCount,
  attendeesCountriesTotalCount,
}: IEventMetricsCard) => {
  return (
    <>
      <div
        style={{
          width: "1040px",
          height: "720px",
          backgroundImage: `url(${MetricsTemplate})`,
          backgroundSize: "cover",
          position: "relative",
          paddingLeft: "80px",
          paddingTop: "40px",
          fontWeight: "bold",
        }}
      >
        <h2 style={{ fontWeight: 700, fontSize: "30px", lineHeight: 1.2 }}>{name}</h2>
        <div style={{ display: "block" }}>
          <span style={{ paddingRight: "10px", borderRight: "2px solid #ffffff" }}>
            {eventType} event
          </span>
          <span style={{ paddingLeft: "10px" }}>{date}</span>
        </div>
        <span
          style={{
            display: "inline-block",
            lineHeight: 1,
            fontWeight: 700,
            fontSize: "150px",
            color: "#ffffff",
            marginTop: "120px",
            textAlign: "right",
            width: "40%",
          }}
        >
          {attendeesTotalCount}
        </span>
        <span
          style={{
            fontWeight: 700,
            fontSize: "260px",
            color: "#ffffff",
            lineHeight: 1,
            marginLeft: "140px",
            marginTop: "50px",
            position: "absolute",
            top: "200px",
            left: "450px",
          }}
        >
          {attendeesCountriesTotalCount}
        </span>
      </div>
    </>
  );
};

export default EventMetricsCard;
