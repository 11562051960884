import { useHistory } from "react-router-dom";

import { Badge } from "@components/DataDisplay/Badge/Badge";

import { redirectToChatPage } from "@utils/redirectChat";

import { IUseSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/type";

import ActiveCommunity from "@images/activeCommunities.svg";
import CommunityIcon from "@images/community.svg";

import styles from "../MenuItems/MenuItems.module.scss";

interface IMenuItemsGroupProps {
  sendBirdGroupChannel: IUseSendBirdGroupChannels;
}

export const MenuItemsGroup = ({
  sendBirdGroupChannel: {
    state: { groups, selectedGroupChannel },
  },
}: IMenuItemsGroupProps) => {
  const history = useHistory();

  return (
    <div className={styles.menuItems}>
      {groups?.map((group) => (
        <Badge count={group.unreadMessageCount} mode="secondary" key={group.url}>
          <div
            onClick={() => redirectToChatPage(group, history)}
            className={styles.messageContainer}
            key={group.url}
          >
            <img
              alt=""
              src={
                selectedGroupChannel?.url === group.url ? ActiveCommunity : CommunityIcon
              }
              className={styles.image}
            />
            <span
              className={
                selectedGroupChannel?.url === group.url
                  ? styles.activeUserName
                  : styles.userName
              }
            >
              {group.name}
            </span>
          </div>
        </Badge>
      ))}
    </div>
  );
};
