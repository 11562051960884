import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";

import { GET_ACCESS_EVENT_INFORMATION } from "@graphql/open-events/mutation";

import Loading from "@components/Loading/Loading";

const cryptKey = `Vk5uVWXWs4L`;

const OpenPublicZoomEventPage = () => {
  const [isLoadingInProgress, setIsLoadingInProgress] = useState<boolean>(true);
  const [getAccessEventInformation] = useMutation(GET_ACCESS_EVENT_INFORMATION);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const currentUrlParts = currentUrl.split("/");

    const eventData = {
      hostingId: 0,
      externalUserId: "",
    };

    eventData.hostingId = parseInt(currentUrlParts[2]);
    eventData.externalUserId = currentUrlParts[3];

    getAccessEventInformation({
      variables: { getAccessEventInformationInput: eventData },
    })
      .then(({ data: { getAccessEventInformation } }) => {
        if (
          !getAccessEventInformation ||
          !eventData.externalUserId ||
          !eventData.hostingId
        )
          return;
        const cryptedValue = {
          sdkKey: getAccessEventInformation.sdkKey,
          signature: getAccessEventInformation.signature,
          userEmail: getAccessEventInformation.joinEmail,
          meetingNumber: eventData.hostingId,
          userName: getAccessEventInformation.fullName,
          userPublicId: eventData.externalUserId,
        };

        const encJson = CryptoJS.AES.encrypt(
          JSON.stringify(cryptedValue),
          cryptKey
        ).toString();
        const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));

        window.open(
          `${window.location.origin}/meeting?p=${eventData.externalUserId}&m=${encData}`,
          "_self"
        );

        setIsLoadingInProgress(false);
      })
      .catch((error) => displayNotSuccessNotification(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoadingInProgress ? (
    <div>
      <Loading disableBoxShadow />
    </div>
  ) : (
    <div></div>
  );
};

export default OpenPublicZoomEventPage;
