/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";

import { Progress, Spin } from "antd";

import Button from "@components/Button/Button";
import InviteToCollectionModal from "@components/InviteToCollectionModal/InviteToCollectionModal";
import Select from "@components/Select/Select";
import Tag from "@components/Tag/Tag";
import TooltipComponent from "@components/Tooltip/Tooltip";
import ViewCollaboratorsFollowersModal from "@components/ViewCollaboratorsFollowersModal/ViewCollaboratorsFollowersModal";
import LinkCollectionToCommunityModal from "../Collections/LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";

import Loading from "@components/Loading/Loading";
import { IFile } from "types/file";
import { DefaultOption } from "types/select";

import styles from "./CollectionSingle.module.scss";

import DangerIcon from "@images/Danger.svg";
import CollectionsIcon from "@images/emptySingleCollection.svg";
import UniversalFileIcon from "@images/Files/universalFile.svg";
import { ReactComponent as MoreIcon } from "@images/moreInfo.svg";
import UploadIcon from "@images/upload.svg";
import Verified from "@images/verified.svg";

import {
  DELETE_COLLECTION,
  FOLLOW_COLLECTION,
  UNFOLLOW_COLLECTION,
} from "@graphql/collection/mutation";
import { GET_SINGLE_COLLECTION } from "@graphql/collection/queries";
import CONVERT_TO_PDF_REQUEST from "@graphql/mutations/convertToPdfRequest";
import UPLOAD_FILE_TO_COLLECTION from "@graphql/mutations/uploadFileToCollection";

import { getSortedArray } from "@utils/helpers";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store/store";

import DangerModal from "@components/DangerModal/DangerModal";
import CollectionsElements from "./CollectionsElements/CollectionsElements";

import ShareModal from "@components/ShareModal/ShareModal";
import FileChecker from "@utils/files/fileChecker";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";

import {
  FILE_WITH_NAME_EXISTS_IN_COLLECTION_ERROR_CODE,
  ISortValue,
  SORTING_A_Z,
  SORTING_MOST_RECENT,
  SORTING_Z_A,
  sortingFoldersObj,
} from "@constants/constants";
import {
  supportedOfficeExtensions,
  supportedUploadToCollectionExtension,
} from "../../utils/files/constants";

import EmptyComponent from "@components/EmptyComponent/EmptyComponent";
import ResourcesCategories from "@components/ResourcesCategories/ResourcesCategories";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import { useSubscription } from "@customHooks/useSubscription";
import {
  CollectionType,
  ICollectionUser,
  INewFolder,
  ISingleCollection,
  ISingleCollectionResponse,
} from "@graphql/collection/type";
import GET_USER from "@graphql/queries/users";
import { IUserResponse } from "types/user";
import { Subscription } from "../../customContext/subscriptions/enum";

import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import LinkModal from "@components/LinkModal/LinkModal";
import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";
import Tabs from "@components/Tabs/Tabs";
import ADD_FOLDER_TO_COLLECTION from "@graphql/mutations/addFolderToCollection";
import ADD_LINK_TO_COLLECTION from "@graphql/mutations/addLinkToCollection";
import DELETE_FOLDER from "@graphql/mutations/deleteFolder";
import RENAME_FILE_IN_COLLECTION from "@graphql/mutations/renameFileInCollection";
import RENAME_FOLDER_IN_COLLECTION from "@graphql/mutations/renameFolderInCollection";
import { ReactComponent as Link } from "@images/link.svg";
import { ReactComponent as Minus } from "@images/minusIcon.svg";
import { ReactComponent as Plus } from "@images/plus.svg";
import { ReactComponent as Share } from "@images/shareIcon.svg";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import {
  displayCustomNotSuccessNotification,
  displayErrorNotification,
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import checkDefaultSortType from "@utils/checkDefaultSortType";
import { BlobStorageContainer, UploadError } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";

import { UploadModalType } from "@components/UploadModal/type";
import UploadModal from "@components/UploadModal/UploadModal";
import { isDocumentRtl } from "@utils/checkDocumentDir";
import { ILink } from "types/link";
import { v4 as uuidv4 } from "uuid";
import CollaboratorsFollowers from "../../components/CollaboratorsFollowers/CollaboratorsFollowers";
import { addCrumb, clearBreadCrumbs } from "../../store/breadcrumbs/actions";
import { IBreadcrumb } from "../../store/breadcrumbs/types";
import { DefaultCollectionState } from "../../store/communityDefaultCollection/types";
import { addNewFolder, clearAllNewFolders } from "../../store/newFolders/actions";
import { newFoldersSelector } from "../../store/newFolders/selectors";
import { IFolder } from "../../store/newFolders/types";

type ParamsProps = {
  id: string;
};

// type ArticlesIdList = {
//   articleId: string;
// };

type LocationProps = {
  pathname: string;
  state: {
    redirect?: string;
    prevPath?: string;
  };
};

const CollectionSingle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams() as unknown as ParamsProps;
  const history = useHistory();
  const location = useLocation() as LocationProps;

  const userPublicId = useSelector<RootState, string>((state) => state.user.userPublicId);

  const {
    uploadFiles,
    isUploadInProgress,
    currentUpload,
    currentUploadBytesUploaded,
    currentUploadBytesTotal,
  } = useUploadFiles();

  const sortOptions = [
    { value: SORTING_MOST_RECENT, label: t("collections.sorting.mostRecent") },
    { value: SORTING_A_Z, label: t("collections.sorting.alphabeticalAZ") },
    { value: SORTING_Z_A, label: t("collections.sorting.alphabeticalZA") },
  ];

  const [DeleteCollection] = useMutation(DELETE_COLLECTION);

  const [sortValue, setSortValue] = useState<ISortValue>(sortOptions[0]);
  const [isOpenCollaborators, setOpenCollaborators] = useState<boolean>(false);
  const [isUserFollowingState, setUserFollowingState] = useState<boolean>(false);
  const [isUserCreatorOfCollection, setUserCreatorOfCollection] =
    useState<boolean>(false);
  const [isUserCollaboratorOfCollection, setUserCollaborator] = useState<boolean>(false);
  // const [articlesIds, setArticlesIds] = useState<Array<ArticlesIdList>>([]);
  // const [articles, setArticles] = useState([]);
  // const [areArticlesLoading, setAreArticlesLoading] = useState(true);
  const [files, setFiles] = useState<Array<IFile>>([]);
  const [folders, setFolders] = useState<INewFolder[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);

  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState<boolean>(false);
  const [
    isUploadFilesToCommunityCollectionModalOpen,
    setIsUploadFilesToCommunityCollectionModalOpen,
  ] = useState<boolean>(false);
  const [isOpenDangerModal, setOpenDangerModal] = useState<boolean>(false);
  const [deletedSignal, setDeletedSignal] = useState<boolean>(false);
  const [isGlobalCurated, setIsGlobalCurated] = useState<boolean>(false);
  const [creatorName, setCreatorName] = useState<string>("");
  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState<boolean>(false);
  const [targetCommunityId, setTargetCommunityId] = useState<string>();
  const [fileInterests, setFileInterests] = useState<string[]>([]);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);
  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const [followCollection] = useMutation(FOLLOW_COLLECTION);
  const [unfollowCollection] = useMutation(UNFOLLOW_COLLECTION);
  const [collection, setCollection] = useState<ISingleCollection>();
  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);
  const newFoldersStored = useSelector(newFoldersSelector);

  const [getCurrentUser, { data: currentUserData }] =
    useLazyQuery<IUserResponse>(GET_USER);

  const [categories, setCategories] = useState<Array<string>>([]);
  const followRequestInProgres = useRef(false);

  const [copyLink, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  // Get folders from state
  const newFolders = useSelector<RootState, IFolder[]>(
    (state) => state.newFolders.folders
  );

  const defaultCollection = useSelector<RootState, DefaultCollectionState>(
    (state) => state.defaultCollection
  );

  const [isDefaultCollection, setIsDefaultCollection] = useState<boolean>(false);

  const editHandler = () => history.push(`/portal/collection/${params.id}/edit`);

  const inviteHandler = () => setOpenInviteModal(true);

  const shareHandler = () => setOpenModal(true);

  const linkHandler = () => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
  };

  const deleteHandler = () => setOpenDangerModal(true);

  const [collectionType, setCollectionType] = useState<string>("");

  const editOption = {
    label: "edit",
    action: editHandler,
    e2eTestId: "edit-collection-menu-item",
  };
  const inviteOption = {
    label: "inviteCollaborators",
    action: inviteHandler,
    e2eTestId: "invite-collaborators-menu-item",
  };
  const shareOption = {
    label: "share",
    action: shareHandler,
    e2eTestId: "share-collection-menu-item",
  };
  const linkOption = {
    label: "link",
    action: linkHandler,
    e2eTestId: "link-collection-menu-item",
  };
  const deleteOption = {
    label: "delete",
    isDelete: true,
    action: deleteHandler,
    e2eTestId: "delete-collection-menu-item",
  };

  let manageOptions: IOption[] = [];

  if (isUserCollaboratorOfCollection) {
    if (
      collection?.isPublic &&
      collection.collectionType !== CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [inviteOption, shareOption, linkOption];
    } else if (
      collection?.isPublic &&
      collection.collectionType === CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [inviteOption];
    } else if (!collection?.isPublic) {
      manageOptions = [inviteOption];
    } else {
      manageOptions = [];
    }
  }
  if (isUserCreatorOfCollection) {
    if (
      collection?.isPublic &&
      collection?.isEditable &&
      collection.collectionType !== CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [editOption, inviteOption, shareOption, linkOption];
    } else if (
      !collection?.isPublic &&
      collection?.isEditable &&
      collection.collectionType !== CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [editOption, inviteOption, shareOption];
    } else if (
      collection?.isPublic &&
      !collection.isEditable &&
      collection.collectionType !== CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [shareOption, linkOption];
    } else if (
      collection?.isEditable &&
      collection.collectionType === CollectionType.COMMUNITY_COLLECTION
    ) {
      manageOptions = [editOption, inviteOption];
    } else {
      manageOptions = [];
    }
  }
  if (collection?.canUserDeleteCollection) {
    manageOptions = [...manageOptions, deleteOption];
  }

  const [getCollection, { loading: singleCollectionLoading, refetch, data }] =
    useLazyQuery<ISingleCollectionResponse>(GET_SINGLE_COLLECTION, {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        if (data.collection) {
          setCollection(data.collection);
          setCategories(data.collection.interests.map((cat) => cat.name));
          setCollectionType(data.collection.collectionType!);

          const isUserFollowing =
            !!data.collection.followers.length &&
            !!data.collection.followers.filter((i) => i.publicId === userPublicId).length;
          setUserFollowingState(isUserFollowing);

          const isUserCreator =
            userPublicId === data.collection.collectionCreator.publicId;
          const isUserCollaborator = data.collection.collaborators.some(
            (i) => i.publicId === userPublicId
          );

          setFiles(data.collection.files);
          setLinks(data.collection.links);

          if (data.collection.folders) {
            const modifiedFolders = data.collection.folders.map((folder) => {
              return { ...folder, isEdit: false };
            });

            setFolders(modifiedFolders);
          }

          if (data.collection.files) {
            const modifiedFiles = data.collection.files.map((file) => {
              return { ...file, isEdit: false };
            });

            setFiles(modifiedFiles);
          }

          setSortValue(sortOptions[0]);

          setUserCreatorOfCollection(isUserCreator);
          setUserCollaborator(isUserCollaborator);

          if (data.collection.isGlobalCurated)
            setIsGlobalCurated(data.collection.isGlobalCurated);

          setCreatorName(data.collection.collectionCreator.fullName);

          const breadCrumb: IBreadcrumb = {
            id: data.collection.publicId,
            name: data.collection.name,
            path: location.pathname,
          };

          dispatch(addCrumb(breadCrumb));
        }
      },
    });

  const followHandler = () => {
    if (followRequestInProgres.current) {
      return;
    }

    followRequestInProgres.current = true;
    followCollection({
      variables: {
        collectionPublicId: params.id,
      },
    })
      .then((response) => {
        if (!response?.data?.followCollection?.name) {
          return displayNotSuccessNotification();
        }
        displaySuccessNotification(t("collections.singleCollection.successFollow"));

        setUserFollowingState(true);
        setTimeout(() => {
          followRequestInProgres.current = false;
        }, 1000);
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        displayCustomNotSuccessNotification(errorCode);
      });
  };

  const unfollowHandler = () => {
    if (followRequestInProgres.current) {
      return;
    }

    followRequestInProgres.current = true;
    unfollowCollection({
      variables: {
        collectionPublicId: params.id,
      },
    })
      .then((response) => {
        if (!response?.data?.unfollowCollection?.name) {
          return displayNotSuccessNotification(
            "Unfollow an collection was not successful"
          );
        }

        displaySuccessNotification(t("collections.singleCollection.successUnfollow"));
        setUserFollowingState(false);
        setTimeout(() => {
          followRequestInProgres.current = false;
        }, 1000);
      })
      .catch((error) => displayServerError(error));
  };

  const handlePeopleModal = () => {
    setOpenCollaborators(true);
  };

  const handleDeleteCollection = () => {
    DeleteCollection({
      variables: {
        collectionPublicId: params.id,
      },
    })
      .then((response) => {
        if (!response.data) return;
        displaySuccessNotification(
          t("collections.collectionsActions.successDeleteCollection", {
            name: collection?.name,
          })
        );
        history.push("/portal/collections/my-collections");
      })
      .catch((error) => {
        displayNotSuccessNotification(error);
      });
  };

  const onDeleteCollectionSignal = () => {
    displayErrorNotification(
      t("collections.collectionsActions.deletedCollectionByOwner", {
        name: collection?.name,
      })
    );
    history.push("/portal/collections/my-collections");
  };

  const isUserAllowedInCollection = (
    user: ICollectionUser,
    collection: ISingleCollection
  ) =>
    !collection.isPublic &&
    !collection.collaborators.some(
      (collaborator) => collaborator.fullName === user.fullName
    ) &&
    collection.collectionCreator.fullName !== user.fullName;

  useEffect(() => {
    getCurrentUser();

    // When component first loads clear the store
    handleClearAllNewFolders();
  }, []);

  const breadcrumbs = useSelector<RootState, IBreadcrumb[]>(
    (state) => state.breadcrumbs.breadcrumbs
  );

  const checkCrumbPaths = () => {
    for (const crumb of breadcrumbs) {
      if (
        crumb.path.includes("/portal/community-collection/") ||
        crumb.path.includes("/portal/community/community-page/")
      ) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (location && location.state?.prevPath?.includes("/portal/collections")) {
      dispatch(clearBreadCrumbs());
    }

    // If we came here from community collection page or community homepage, do not reset the breadcrumbs
    if (
      location &&
      !location.state?.prevPath?.includes("/portal/community-collection/") &&
      !location.state?.prevPath?.includes("/portal/community/community-page/") &&
      checkCrumbPaths()
    ) {
      dispatch(clearBreadCrumbs());
    }
  }, [location]);

  useEffect(() => {
    getCollection({
      variables: {
        publicId: params.id,
      },
    });
  }, [params.id]);

  useEffect(() => {
    if (currentUserData && collection) {
      const currentUser: ICollectionUser = {
        publicId: currentUserData.user.publicId,
        fullName: currentUserData.user.fullName,
        organization: {
          name: currentUserData.user.organization.name,
        },
      };

      if (isUserAllowedInCollection(currentUser, collection))
        setIsUserNotAllowedModalOpened(true);
    }
  }, [currentUserData, collection]);

  useSubscription(Subscription.DELETED_ARTICLE_FROM_COLLECTION, () => {
    if (isUserCreatorOfCollection) return;
    refetch();
  });

  useSubscription(Subscription.DELETED_COLLECTION, () => {
    if (isUserCreatorOfCollection) return;
    !deletedSignal && setDeletedSignal(true);
  });

  useEffect(() => {
    if (deletedSignal) {
      onDeleteCollectionSignal();
    }
  }, [deletedSignal]);

  const showCollectionType = () => {
    if (isGlobalCurated) return t("collections.globalCurated");

    if (collection?.isPublic && !isGlobalCurated) return t("collections.public");

    if (!collection?.isPublic) return t("collections.private");
  };

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<number>(0);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [countTotal, setCountTotal] = useState(0);
  const [uploadProgressPercentage, setUploadProgressPercentage] = useState<number>(0);

  useEffect(() => {
    if (showUploadProgress) {
      toast(`${uploadProgressPercentage.toString()}`, {
        className: `${styles.toaster}`,
        toastId: "toaster",
        position: isDocumentRtl() ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    }
  }, [showUploadProgress]);

  useEffect(() => {
    if (!isUploadInProgress) {
      setShowUploadProgress(false);
    }
  }, [isUploadInProgress]);

  useEffect(() => {
    if (!currentUploadBytesTotal) {
      return;
    }
    const uploadProgress = currentUploadBytesUploaded / currentUploadBytesTotal;
    const uploadProgressToPercentage = Math.ceil(100 * uploadProgress);

    setUploadProgressPercentage(uploadProgressToPercentage);

    toast.update("toaster", {
      className: `${styles.toaster} ${styles.active}`,
      autoClose: 5000,
      render: (
        <>
          <div className={styles.upperSection}>
            {filesUploaded < countTotal && countTotal > 1 && currentUpload ? (
              <Spin size="large" />
            ) : (
              <img src={UniversalFileIcon} className={styles.fileIcon} />
            )}
            <div className={styles.infoSection}>
              {currentUpload && (
                <p className={styles.uploadingFileName}>{currentUpload}</p>
              )}
              {t("collections.singleCollection.toaster.filesUploaded", {
                filesUploaded,
                totalFiles: countTotal,
              })}
            </div>
            {filesUploaded < countTotal && currentUpload ? (
              <span
                className={styles.cancelButton}
                // TODO Find a better way to abort uploading
                onClick={() => window.location.reload()}
              >
                {t("collections.singleCollection.toaster.cancelUpload")}
              </span>
            ) : (
              <span className={styles.cancelButton}>
                {t("collections.singleCollection.toaster.closeToaster")}
              </span>
            )}
          </div>
          {currentUpload && countTotal === 1 && (
            <Progress className={styles.progress} percent={uploadProgressPercentage} />
          )}
        </>
      ),
    });
  }, [
    currentUploadBytesUploaded,
    currentUploadBytesTotal,
    filesUploaded,
    countTotal,
    uploadProgressPercentage,
  ]);

  const [UploadFileToCollection] = useMutation(UPLOAD_FILE_TO_COLLECTION, {
    onCompleted: () => {
      setFilesUploaded((prev: number) => prev + 1);
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const fileThatFailedName =
        clientOptions.variables.addFilesToCollection.files[0].name;

      if (codeMessage === "3000") {
        const message = t(
          "collections.singleCollection.fileExistsOrNotSufficientRights",
          {
            name: fileThatFailedName,
          }
        );
        displayNotSuccessNotification(message, message);
      } else if (codeMessage === "3011") {
        const message = t("collections.singleCollection.fileWithSameNameAlreadyExists", {
          name: fileThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const [ConvertFileToPdf] = useMutation(CONVERT_TO_PDF_REQUEST);

  const addUploadedFileToCollection = async (file: File, directory: string) => {
    const fileToUpload = [
      {
        name: file.name,
        size: file.size,
        directory,
      },
    ];
    const addFilesToCollection = {
      collectionPublicId: collection?.publicId,
      files: fileToUpload,
      interests: fileInterests,
    };

    const convertToPdfRequest = {
      fileName: file.name,
      directoryName: directory,
    };

    try {
      await UploadFileToCollection({
        variables: { addFilesToCollection },
      });
    } catch (error) {
      displayNotSuccessNotification(
        undefined,
        t("collections.errorsMessages.fileUploadFailed", { filename: file.name })
      );
    }
    try {
      const fileExt = file.name!.split(".").pop();
      if (supportedOfficeExtensions.includes("." + fileExt!)) {
        await ConvertFileToPdf({
          variables: { convertToPdfRequest },
        });
      }
    } catch (error) {
      displayNotSuccessNotification();
    }
  };

  const uploadErrorHandler = (error: UploadError) => {
    error.files.forEach((file) =>
      displayNotSuccessNotification(
        undefined,
        t("collections.errorsMessages.fileUploadFailed", { filename: file.name })
      )
    );
  };

  const handleFiles = async (newFiles: Array<File>) => {
    await uploadFiles(newFiles, BlobStorageContainer.FILES, {
      onFileUpload: addUploadedFileToCollection,
      onError: uploadErrorHandler,
    });

    setSelectedFiles([]);
  };

  const handleSetUploadableFiles = async (files: File[]) => {
    setShowUploadProgress(true);
    setCountTotal(files.length);
    setFilesUploaded(0);

    const isUploadable = await Promise.all(
      files.map((file) =>
        FileChecker.isUploadable(file, supportedUploadToCollectionExtension)
      )
    );

    const uploadableFiles = Array.from(files).filter((_, index) => isUploadable[index]);
    setSelectedFiles(uploadableFiles);
  };

  const changeHandler = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files as FileList);
    handleSetUploadableFiles(files);
  };

  const inputFileRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleUploadFilesBtnClick = () => {
    setSelectedFiles([]);
    setFilesUploaded(0);
    inputFileRef.current.value = "";
    inputFileRef.current.click();
  };

  const onUploadBtnClick = () => {
    if (collection?.collectionType === CollectionType.USER_COLLECTION) {
      handleUploadFilesBtnClick();
    }
    if (collection?.collectionType === CollectionType.COMMUNITY_COLLECTION) {
      setIsUploadFilesToCommunityCollectionModalOpen(true);
    }
  };

  useEffect(() => {
    selectedFiles.length > 0 && handleFiles(selectedFiles);
  }, [selectedFiles]);

  const COLLECTIONS_TABS = [
    {
      name: t("collections.myCollections"),
      selectedByRoutes: ["/my-collections"],
    },
    {
      name: t("collections.explore"),
      selectedByRoutes: ["/explore", "/explore/view-more"],
    },

    // {
    //   name: t("collections.activity"),
    //   selectedByRoutes: ["/activity"],
    // },
  ];

  // Add new folder to store
  const handleCreateNewFolder = () => {
    const folder: IFolder = {
      id: uuidv4(),
      name: "New Folder",
    };

    dispatch(addNewFolder(folder));
  };

  // Clear all new folders from store
  const handleClearAllNewFolders = () => {
    dispatch(clearAllNewFolders());
  };

  const getFolderName = (folderName: string) => {
    const folderToAdd: { name: string; collectionPublicId: string } = {
      name: folderName,
      collectionPublicId: params.id,
    };

    addFolderToCollection({
      variables: {
        folderToAdd,
      },
    });
  };

  const [addLinkToCollection] = useMutation(ADD_LINK_TO_COLLECTION, {
    onCompleted: () => {
      displaySuccessNotification(t("collections.singleCollection.linkAdded"));
      setIsAddLinkModalOpen(false);
      refetch();
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onError: (err: any, clientOptions: any) => {
      const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;
      const linkThatFailedName = clientOptions.variables.linkToAdd.name;

      if (codeMessage === "7001") {
        const message = t("collections.singleCollection.linkWithSameNameAlreadyExists", {
          name: linkThatFailedName,
        });
        displayNotSuccessNotification(message, message);
      } else {
        displayNotSuccessNotification();
      }
    },
  });

  const handleAddLink = (link: ILink) => {
    addLinkToCollection({
      variables: {
        linkToAdd: {
          name: link.name,
          url: link.url,
          collectionPublicId: params.id,
        },
      },
    });
  };

  const [addFolderToCollection, { loading: addFolderLoading }] = useMutation(
    ADD_FOLDER_TO_COLLECTION,
    {
      onCompleted: () => {
        handleClearAllNewFolders();
        displaySuccessNotification(t("collections.singleCollection.folderCreated"));
        refetch();
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      onError: (err: any, clientOptions: any) => {
        const folderThatFailedName = clientOptions.variables.folderToAdd.name;
        const codeMessage = err?.networkError?.result?.errors[0]?.extensions?.code;

        if (codeMessage === "2500") {
          const message = t(
            "collectionscollections.singleCollection.folderExistsOrNotSufficientRights",
            {
              name: folderThatFailedName,
            }
          );
          displayNotSuccessNotification(message, message);
        } else if (codeMessage === "2512") {
          const message = t(
            "collections.singleCollection.folderWithSameNameAlreadyExists",
            {
              name: folderThatFailedName,
            }
          );
          displayNotSuccessNotification(message, message);
        } else {
          displayNotSuccessNotification(err);
        }
      },
    }
  );

  const handleFolderRename = (folderId: string, folderName: string) => {
    const folderRename: { folderPublicId: string; newName: string } = {
      folderPublicId: folderId,
      newName: folderName,
    };

    renameFolderInCollection({
      variables: {
        folderRename,
      },
    });
  };

  const [renameFolderInCollection, { loading: renameLoading }] = useMutation(
    RENAME_FOLDER_IN_COLLECTION,
    {
      onCompleted: () => {
        displaySuccessNotification(t("collections.singleCollection.folderRenamed"));
        refetch();
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  const [renameFileInCollection, { loading: renameFileLoading }] = useMutation(
    RENAME_FILE_IN_COLLECTION,
    {
      onCompleted: () => {
        displaySuccessNotification(t("collections.singleCollection.fileRenamed"));
        refetch();
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const apolloError = error.networkError?.result?.errors[0];

        const errorCode = apolloError?.extensions?.code;
        const message = apolloError?.message;

        if (errorCode === FILE_WITH_NAME_EXISTS_IN_COLLECTION_ERROR_CODE) {
          displayNotSuccessNotification(message, message);
        } else {
          displayNotSuccessNotification(error);
        }
      },
    }
  );

  const [deleteFolder, { loading: removeLoading }] = useMutation(DELETE_FOLDER, {
    onCompleted: () => {
      displaySuccessNotification(t("collections.singleCollection.folderDeleted"));
      refetch();
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const handleFileRename = (fileId: string, fileName: string, fileExtenstion: string) => {
    const fileRename: { filePublicId: string; newName: string } = {
      filePublicId: fileId,
      newName: `${fileName}${fileExtenstion}`,
    };

    renameFileInCollection({
      variables: {
        fileRename,
      },
    });
  };

  const handleFolderDelete = (folderId: string) => {
    deleteFolder({
      variables: {
        folderPublicId: folderId,
      },
    });
  };

  const handleFolderEditId = (folderId: string) => {
    const modifiedFolders = folders.map((folder) => {
      if (folder.publicId === folderId) {
        return { ...folder, isEdit: true };
      } else {
        return folder;
      }
    });

    setFolders(modifiedFolders);
  };

  const handleFileEditId = (fileId: string) => {
    const modifiedFiles = files.map((file) => ({
      ...file,
      isEdit: file.publicId === fileId,
    }));

    setFiles([...modifiedFiles]);
  };

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    const sortedFolders = getSortedArray(
      folders,
      sortingFoldersObj[sortValue.value]?.value,
      checkDefaultSortType(sortValue)
    );
    setFolders([...sortedFolders]);
  }, [sortValue]);

  useEffect(() => {
    if (!defaultCollection && !collection) return;

    if (defaultCollection.publicId === collection?.publicId) {
      setIsDefaultCollection(true);
    }
  }, [defaultCollection, collection]);

  const contentLoader =
    addFolderLoading ||
    renameLoading ||
    renameFileLoading ||
    singleCollectionLoading ||
    removeLoading;

  return (
    <>
      <ConfirmationModal
        isOpen={isUserNotAllowedModalOpened}
        messageTitle={t("collections.singleCollection.userNotAllowedModal.title")}
        messageContent={t("collections.singleCollection.userNotAllowedModal.content")}
        closeModal={() => setIsUserNotAllowedModalOpened(false)}
        goBack={() => history.push("/portal/collections/explore")}
      />
      {singleCollectionLoading ? (
        <div className={styles.loadingContainer}>
          <Loading disableBoxShadow />
        </div>
      ) : collection && collection.name ? (
        <>
          <div
            className={styles.container}
            data-testid="single-collection-container"
            e2e-test-id="personal-collection-container"
          >
            <div
              data-testid="tabs-container"
              className={styles.tabContainer}
              e2e-test-id="collection-tabs-container"
            >
              <Tabs
                tabs={COLLECTIONS_TABS}
                size="large"
                routePrefix="/portal/collections"
              />
            </div>

            <div
              className={styles.breadCrumbsContainer}
              e2e-test-id="breadcrumbs-and-goto-container"
            >
              <Breadcrumbs />
            </div>
            <div className={styles.topBar}>
              <div className={styles.titleContainer}>
                <h3 className={styles.title} e2e-test-id="collection-name">
                  {collection.name}
                </h3>
                {isUserCollaboratorOfCollection ||
                isUserCreatorOfCollection ||
                collection.canUserDeleteCollection ? (
                  <Menu options={manageOptions}>
                    <div className={styles.manageButton} e2e-test-id="manage-menu">
                      <MoreIcon />
                      {t("collection.manage")}
                    </div>
                  </Menu>
                ) : null}
              </div>
              <div className={styles.actionWraper}>
                {collection?.isEditable &&
                (isUserCollaboratorOfCollection || isUserCreatorOfCollection) ? (
                  // Creator and collab
                  <>
                    <label htmlFor="collectionFile">
                      <Button
                        buttonSize="large"
                        disabled={isUploadInProgress}
                        buttonIcon={
                          <img
                            className={styles.uploadIcon}
                            src={UploadIcon}
                            alt="Upload Icon"
                          />
                        }
                        title={t("collections.buttons.uploadFile")}
                        onClick={onUploadBtnClick}
                      />
                      <input
                        type="file"
                        ref={inputFileRef}
                        name="collectionFile"
                        id="collectionFile"
                        multiple={true}
                        onChange={changeHandler}
                        accept={supportedUploadToCollectionExtension.join(",")}
                        aria-label="input"
                        className={styles.inputFile}
                        e2e-test-id="upload-file-input-field"
                      />
                    </label>
                    <Button
                      buttonType="primary"
                      buttonSize="large"
                      aria-label="new-folder"
                      title={t("collections.singleCollection.newFolder")}
                      buttonIcon={<PlusOutlined />}
                      onClick={handleCreateNewFolder}
                      fullWidth
                      e2eTestId="new-folder-button"
                      disabled={addFolderLoading || newFoldersStored.length > 0}
                    />
                    <Button
                      buttonType="primary"
                      buttonSize="large"
                      aria-label="add-link"
                      title={t("collections.singleCollection.addLink")}
                      buttonIcon={<PlusOutlined />}
                      onClick={() => setIsAddLinkModalOpen(true)}
                      fullWidth
                      e2eTestId="add-link-button"
                    />
                  </>
                ) : (
                  // Members
                  <>
                    {/* Show member/follower actions only on user type collection */}
                    {collectionType === CollectionType.USER_COLLECTION ? (
                      <>
                        <TooltipComponent
                          title={t("collections.buttons.linkCollection")}
                          tooltipType="secondary"
                          childToWrap={
                            <button
                              className={styles.actionButton}
                              aria-label="link-collection"
                              onClick={linkHandler}
                            >
                              <Link />
                            </button>
                          }
                        />
                        <TooltipComponent
                          title={t("collections.buttons.shareCollection")}
                          tooltipType="secondary"
                          childToWrap={
                            <button
                              className={styles.actionButton}
                              aria-label="share-collection"
                              onClick={() => setOpenModal(true)}
                            >
                              <Share />
                            </button>
                          }
                        />
                        <TooltipComponent
                          title={t(
                            isUserFollowingState
                              ? "collections.buttons.unfollowCollection"
                              : "collections.buttons.followCollection"
                          )}
                          tooltipType="secondary"
                          childToWrap={
                            <button
                              className={`${styles.actionButton} ${styles.actionText}`}
                              aria-label="follow-collection"
                              onClick={
                                isUserFollowingState ? unfollowHandler : followHandler
                              }
                            >
                              {isUserFollowingState ? <Minus /> : <Plus />}
                              <span>
                                {t(
                                  isUserFollowingState
                                    ? "collections.buttons.unfollowText"
                                    : "collections.buttons.followText"
                                )}
                              </span>
                            </button>
                          }
                        />
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className={styles.middleBar}>
              <div className={styles.categoriesWrapper} e2e-test-id="categories-section">
                {isGlobalCurated && (
                  <TooltipComponent
                    tooltipType="secondary"
                    placement="bottom"
                    title={t("collections.singleCollection.createdBy", { creatorName })}
                    childToWrap={
                      <span className={styles.verifiedTag}>
                        <Tag
                          value={t("collections.singleCollection.globalCuratedTag")}
                          icon={Verified}
                          color="#FFFFFF"
                          backgroundColor="#567AE8"
                        />
                      </span>
                    }
                  />
                )}
                <ResourcesCategories categories={categories} />
              </div>
              {/* Hide collaborators and followers if collection is default */}
              {!isDefaultCollection && (
                <div
                  className={styles.peopleOuter}
                  onClick={() => handlePeopleModal()}
                  e2e-test-id="collaborators-followers-section"
                >
                  <CollaboratorsFollowers collection={collection} />
                </div>
              )}
            </div>

            <div className={styles.bottomBar}>
              {collection.description && (
                <p e2e-test-id="collection-description">{collection.description}</p>
              )}
            </div>

            {
              /* articles || */ files || newFolders || links ? (
                <>
                  <div className={styles.sortContainer}>
                    <div className={styles.sortDropdownWrap}>
                      <Select
                        label=""
                        placeholder=""
                        name={"sort"}
                        value={sortValue}
                        options={sortOptions}
                        showDropdown
                        onChange={(option: DefaultOption) => setSortValue(option)}
                        e2eTestId="contents-sorting-dropdown"
                      />
                    </div>
                  </div>

                  {contentLoader ? (
                    <Loading disableBoxShadow />
                  ) : (
                    <CollectionsElements
                      collection={data?.collection}
                      sortValue={sortValue}
                      articles={[]}
                      userIsCreator={isUserCreatorOfCollection}
                      userIsCollaborator={isUserCollaboratorOfCollection}
                      collectionName={collection.name}
                      folders={folders}
                      files={files}
                      getFolderName={getFolderName}
                      getFolderIdEdit={handleFolderEditId}
                      getFileIdEdit={handleFileEditId}
                      renameFile={handleFileRename}
                      renameFolder={handleFolderRename}
                      deleteFolder={handleFolderDelete}
                      refetchCollection={handleRefetch}
                      collectionType={collectionType}
                    />
                  )}
                </>
              ) : (
                <EmptyComponent
                  title={t("collections.singleCollection.noArticles.title")}
                  subTitle={t("collections.singleCollection.noArticles.subtitle")}
                  image={CollectionsIcon}
                />
              )
            }
          </div>
          <ViewCollaboratorsFollowersModal
            title={collection.name}
            isOpen={isOpenCollaborators}
            isUserCreatorOfCollection={isUserCreatorOfCollection}
            creator={collection.collectionCreator}
            collaborators={collection.collaborators}
            followers={collection.followers}
            isCommunityCollection={
              collection.collectionType === CollectionType.COMMUNITY_COLLECTION
            }
            collectionType={showCollectionType()}
            refetchCollaborators={refetch}
            collectionId={collection.publicId}
            onClose={() => setOpenCollaborators(false)}
          />
          <DangerModal
            title={t("collections.collectionsActions.deleteCollectionTitle", {
              title: collection.name,
            })}
            subTitle={t("collections.collectionsActions.deleteCollectionSubTitle")}
            isOpen={isOpenDangerModal}
            onClose={() => setOpenDangerModal(false)}
            onSubmit={() => handleDeleteCollection()}
            image={<img src={DangerIcon} />}
            closeButton={t("collections.collectionsActions.keepCollection")}
            ctaButton={t("collections.collectionsActions.deleteCollection")}
            e2eTestId="delete-collection-confirmation-modal"
          />
        </>
      ) : (
        <PageNotFound />
      )}
      <ShareModal
        copyLink={copyLink}
        open={isOpenModal}
        close={() => setOpenModal(false)}
        share={shareSuccessMessage}
      />
      {isUploadFilesToCommunityCollectionModalOpen && (
        <UploadModal
          type={UploadModalType.FILES}
          handleUploadProgressWithModal={handleSetUploadableFiles}
          addFiles={handleFiles}
          handleAddInterests={setFileInterests}
          selectedInterests={fileInterests}
          open={isUploadFilesToCommunityCollectionModalOpen}
          multiple
          close={() => setIsUploadFilesToCommunityCollectionModalOpen(false)}
        />
      )}
      {isLinkCollectionToCommuniyModalOpen && (
        <LinkCollectionToCommunityModal
          isOpen={isLinkCollectionToCommuniyModalOpen}
          collectionId={params.id}
          collectionName={collection?.name}
          targetCommunityId={targetCommunityId}
          onClose={handleCloseLinkCollectionToCommunityModal}
          onSetTargetCommunityId={handleSetTargetCommunityId}
        />
      )}
      {isOpenInviteModal && (
        <InviteToCollectionModal
          collection={collection!}
          open={isOpenInviteModal}
          close={() => {
            setOpenInviteModal(false);
          }}
        />
      )}
      {isAddLinkModalOpen && (
        <LinkModal
          isOpen={isAddLinkModalOpen}
          onCloseModal={() => setIsAddLinkModalOpen(false)}
          onSubmitLink={handleAddLink}
        />
      )}
    </>
  );
};

export default CollectionSingle;
