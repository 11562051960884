import { useMutation } from "@apollo/client";
import AttachmentCard from "@components/AttachmentCard/AttachmentCard";
import GENERATE_SAS_TOKEN from "@graphql/mutations/generateSasToken";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { FileHelper } from "@utils/files/fileHelper";
import { downloadFileFromBlob } from "@utils/storageBlob";
import styles from "./EventAttachmentTab.module.scss";

interface IEventAttachmentTab {
  attachments: Array<{ name: string; size: number }>;
  attachmentsDirectory: string;
}

const EventAttachmentTab = ({
  attachments,
  attachmentsDirectory,
}: IEventAttachmentTab) => {
  const [generateEventSasToken] = useMutation(GENERATE_SAS_TOKEN);
  const downloadFile = (attachment: string) => {
    generateEventSasToken({
      variables: {
        blobStorage: {
          fileNames: attachment,
          containerName: process.env.REACT_APP_BLOB_CONTAINER_NAME,
          directoryName: attachmentsDirectory,
        },
      },
    })
      .then(async (res) => {
        const { values: urls } = res?.data?.generateSasToken;

        const fileUrl = urls.find((url: string) => url.includes(encodeURI(attachment)));

        try {
          await downloadFileFromBlob(attachment, fileUrl);
        } catch (error) {
          displayNotSuccessNotification(error);
        }
      })
      .catch((error) => displayNotSuccessNotification(error));
  };

  return (
    <div className={styles.attachmentBox}>
      {attachments.map((attachment: { name: string; size: number }, index) => (
        <div key={index}>
          <AttachmentCard
            name={attachment.name}
            size={FileHelper.getSizeInKB(attachment.size)}
            wrapperClassNames={styles.fileContainer}
            attachmentEvent={true}
            downloadAttachment={() => downloadFile(attachment.name)}
          />
        </div>
      ))}
    </div>
  );
};

export default EventAttachmentTab;
