import { createContext, useContext, useMemo } from "react";

import { Emoji, SendBirdInstance } from "sendbird";

import { useSendBird } from "../useSendBird/useSendbird";
import { useSendBirdEmojis } from "../useSendBirdEmojis/useSendBirdEmojis";

interface ISendBirdProvider {
  children: React.ReactElement;
}

interface IContext {
  sb: SendBirdInstance | null;
  emojiList: Array<Emoji>;
}

const SendBirdContext = createContext<IContext>({ sb: null, emojiList: [] });

export function useSendBirdContext() {
  const context = useContext(SendBirdContext);
  return context as IContext;
}

export const SendBirdProvider = ({ children }: ISendBirdProvider) => {
  const sb = useSendBird();
  const emojiList = useSendBirdEmojis(sb);

  const value = useMemo(
    () => ({
      sb,
      emojiList,
    }),
    [emojiList, sb]
  );

  return <SendBirdContext.Provider value={value}>{children}</SendBirdContext.Provider>;
};
