/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import basicStyles from "@components/BasicModal/BasicModal.module.scss";
import Modal from "@components/Modal/Modal";
import { CollectionType, ICollectionUser } from "@graphql/collection/type";
import REMOVE_COLLABORATOR from "@graphql/mutations/removeCollaborator";
import GET_USER_INFO from "@graphql/queries/getUserInfo";
import Avatar from "@images/avatar.svg";
import Exit from "@images/exit.svg";
import {
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import styles from "./ViewCollaboratorsFollowersModal.module.scss";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";

import CollaboratorsFollowers from "@components/CollaboratorsFollowers/CollaboratorsFollowers";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import getStorageImage from "@utils/getStorageImage";
import { getSortedArray } from "@utils/helpers";
import { IUser, IUserImageResponse } from "types/user";

interface ViewCollaboratorsFollowersModalProps {
  title: string;
  isOpen: boolean;
  isUserCreatorOfCollection: boolean;
  creator: ICollectionUser;
  followers: ICollectionUser[];
  collaborators: ICollectionUser[];
  collectionType: CollectionType;
  isCommunityCollection: boolean;
  collectionId: string;
  refetchCollaborators: () => void;
  onClose: () => void;
}

interface IPersonItem extends ICollectionUser {
  isCreator: boolean;
  isCollaborator: boolean;
}

const ViewCollaboratorsFollowersModal = ({
  isOpen,
  onClose,
  isUserCreatorOfCollection,
  title,
  creator,
  collaborators,
  followers,
  collectionType,
  collectionId,
  refetchCollaborators,
  isCommunityCollection,
}: ViewCollaboratorsFollowersModalProps) => {
  const { t } = useTranslation();
  const handleClose = () => onClose();

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  useEffect(() => {
    const emails = collaborators
      .map((col) => col.email)
      .concat(followers.map((fol) => fol.email))
      .concat(creator.email);

    getUserImages({
      variables: {
        profileImagesInput: {
          userEmails: emails,
        },
      },
    });
  }, []);

  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUser(data.userInfo);
      setIsUserDrawerOpen(true);
    },
    onError: (error) => {
      displayNotSuccessNotification(error);
    },
  });

  const [removeCollaborator] = useMutation(REMOVE_COLLABORATOR, {
    onCompleted: () => {
      refetchCollaborators();
      displaySuccessNotification(
        t("collections.singleCollection.removedCollaboratorNotification")
      );
    },
  });

  const handleRemoveCollaborator = (email: string) => {
    removeCollaborator({
      variables: {
        removeCollectionCollaborator: {
          publicId: collectionId,
          collaborators: [email],
        },
      },
    });
  };

  const Person = ({
    fullName,
    organization,
    isCollaborator,
    isCreator,
    email,
  }: IPersonItem) => {
    const { name, userRole } = organization;
    return (
      <div className={styles.row}>
        <div
          className={styles.personItem}
          onClick={() => {
            getUserInfo({
              variables: {
                email,
              },
            });
            handleClose();
          }}
        >
          <div
            className={styles.avatar}
            style={{
              backgroundImage: `url(${
                userImages
                  ? userImages.find((image) => image.email === email)?.image
                  : Avatar
              })`,
            }}
          />
          <div>
            <p className={styles.title}>
              {fullName}
              {isCreator && t("collections.singleCollection.creatorInModal")}
              {isCollaborator && t("collections.singleCollection.collaboratorInModal")}
            </p>
            <p className={styles.subTitle}>
              {t("collections.singleCollection.personRoleAtCompany", {
                userRole,
                company: name,
              })}
            </p>
          </div>
        </div>
        {isCollaborator && isUserCreatorOfCollection && (
          <span
            className={styles.removeCollaborator}
            onClick={() => handleRemoveCollaborator(email!)}
          >
            {t("collections.singleCollection.removeCollaborator")}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal onRequestClose={handleClose} isOpen={isOpen} modalType="narrow">
        <div className={styles.textContainer} data-testid="modal-outer">
          <h4 className={basicStyles.title}>{title}</h4>
          <p className={styles.collectionInfo}>
            <span>{collectionType}</span>
            <span className={styles.dotMargin}>•</span>
            <CollaboratorsFollowers
              isOnModal
              collection={{ collaborators, followers, collectionType }}
              isCollectionInfoDisplayed={!isCommunityCollection}
            />
          </p>
          <img
            role="button"
            aria-label="close-modal"
            className={basicStyles.closeImage}
            onClick={handleClose}
            src={Exit}
            alt=""
          />
        </div>
        <div className={styles.peopleList}>
          <Person
            {...creator}
            key={creator.publicId}
            isCreator={true}
            isCollaborator={false}
          />
          {!!collaborators.length &&
            getSortedArray(collaborators, "fullName", true).map(
              (person: ICollectionUser) => (
                <Person
                  {...person}
                  key={person.publicId}
                  isCreator={false}
                  isCollaborator={true}
                />
              )
            )}
          {!!followers.length &&
            getSortedArray(followers, "fullName", true).map((person: ICollectionUser) => (
              <Person
                {...person}
                key={person.publicId}
                isCreator={false}
                isCollaborator={false}
              />
            ))}
        </div>
      </Modal>
      {user && isUserDrawerOpen && (
        <UserDrawer
          user={user}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={<></>}
        />
      )}
    </>
  );
};

export default ViewCollaboratorsFollowersModal;
