import * as yup from "yup";

import { stringValidation } from "@utils/yup/string";

export const getValidationSchema = (t: (value: string) => string) =>
  yup.object().shape(
    {
      countryCode: stringValidation(t).when("telephone", (telephone, schema) => {
        if (telephone) return schema.required(t("form.error.required"));
        return schema;
      }),
      telephone: stringValidation(t).when("countryCode", (countryCode, schema) => {
        if (countryCode) return schema.required(t("form.error.required"));
        return schema;
      }),
    },
    [["telephone", "countryCode"]]
  );
