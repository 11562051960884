import { gql } from "@apollo/client";

const CREATE_EVENT = gql`
  mutation createEvent($event: CreateEventInputType!) {
    createEvent(event: $event) {
      name
      description
      eventType
      eventImageName
      imageDirectory
      startDate
      endDate
    }
  }
`;

export default CREATE_EVENT;
