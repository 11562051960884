import { AnyAction } from "redux";
import {
  SET_USER_UNREAD_NOTIFICATIONS_COUNT,
  SET_IS_NOTIFICATIONS_POP_UP_VISIBLE,
} from "./constants";
import { NotificationsState } from "./types";

const initialState: NotificationsState = {
  unreadNotificationsCount: 0,
  isNotificationsPopUpVisible: false,
};

const notificationReducer = (
  state = initialState,
  action: AnyAction
): NotificationsState => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unreadNotificationsCount: payload,
      };
    case SET_IS_NOTIFICATIONS_POP_UP_VISIBLE:
      return {
        ...state,
        isNotificationsPopUpVisible: payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
