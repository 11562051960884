/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";

import { WHO_PAGE } from "@constants/constants";

import { useWhoLogo } from "@customHooks/useWhoLogo";
import { handleAcceptingRedirect } from "@utils/helpers";

import { MENU_ITEMS } from "./constant";
import { Menu } from "./enum";
import { getMenuItemClassName } from "./helper";

import { ReactComponent as CaretLeft } from "@images/double-caret-left.svg";
import { ReactComponent as HamburgerMenu } from "@images/hamburger_menu.svg";
import Hive from "@images/hive_logo.png";
import LogoCollapsed from "@images/Logos/collapsed.png";
// import ActiveCollections from "../../images/activeCollections.svg";
// import Collections from "../../images/collections.svg";
// import Note from "../../images/note.svg";

import styles from "./Navbar.module.scss";
import { IMenuItem } from "./type";

interface INavbar {
  userDefaultLanguage?: string;
}

const Navbar = ({ userDefaultLanguage }: INavbar) => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = useLocation();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState(MENU_ITEMS);

  const { logo } = useWhoLogo(userDefaultLanguage);

  useEffect(() => {
    const foundItem = menuItems.find(({ includedPaths }) =>
      includedPaths.some((includedPath) => path.pathname.includes(includedPath))
    );
    if (foundItem) {
      updateActiveMenuItem(foundItem.id);
    }
  }, [path.pathname]);

  const updateActiveMenuItem = (id: Menu) => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) => ({ ...item, isActive: item.id === id }))
    );
  };

  const renderMenuItem = (item: IMenuItem) => {
    return (
      <div
        key={item.id}
        role="navigation"
        onClick={() => history.push(item.path)}
        className={getMenuItemClassName(item, isExpanded, styles)}
      >
        <div className={styles.itemContainer} e2e-test-id={item.e2eTestId}>
          <item.Icon className={styles.icon} />
          <span className={styles.itemText}>{t(item.translationKey)}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        accept={() => handleAcceptingRedirect(WHO_PAGE, setIsConfirmationModalOpen)}
        closeModal={() => setIsConfirmationModalOpen(false)}
        messageTitle={t("singleArticle.urlModal.title")}
        messageContent={t("singleArticle.urlModal.text")}
        url={WHO_PAGE}
      />
      <div
        className={`${styles.navbar} ${!isExpanded ? styles.collapsed : ""}`}
        e2e-test-id="left-menu-container"
      >
        {isExpanded ? (
          <div className={styles.logoContainer}>
            <CaretLeft
              onClick={() => setIsExpanded(false)}
              className={styles.collapseIcon}
              aria-label="collapse"
            />
            <div className={styles.logo}>
              <img
                src={Hive}
                alt=""
                role="img"
                aria-label="hiveLogo"
                width="89.25px"
                height="28px"
              />
            </div>
            <h5 className={styles.text} role="heading">
              {t("navbar.subheading")}
            </h5>
          </div>
        ) : (
          <div className={styles.logoCollapsedContainer}>
            <HamburgerMenu
              onClick={() => setIsExpanded(true)}
              className={styles.hamburgerMenu}
            />
          </div>
        )}

        <div className={`${styles.menu} ${!isExpanded ? styles.collapsed : ""}`}>
          <div className={styles.submenu}>
            {menuItems.filter(({ isBottom }) => !isBottom).map(renderMenuItem)}
          </div>
          <div className={styles.submenu}>
            {menuItems.filter(({ isBottom }) => isBottom).map(renderMenuItem)}
          </div>
        </div>

        <div>
          {isExpanded ? (
            <img
              src={logo}
              alt=""
              role="img"
              aria-label="bottomLogo"
              className={styles.whoLogo}
              onClick={() => setIsConfirmationModalOpen(true)}
            />
          ) : (
            <img
              src={LogoCollapsed}
              alt=""
              role="img"
              aria-label="bottomLogo"
              className={styles.whoLogoCollapsed}
              onClick={() => setIsConfirmationModalOpen(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
