import { SORTING_A_Z } from "../constants/constants";

type SortType = {
  value: string;
};

const checkDefaultSortType = (sortValue: SortType) => {
  let isAsc = false;
  if (sortValue.value === SORTING_A_Z) {
    isAsc = true;
  }
  return isAsc;
};

export default checkDefaultSortType;
