import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY || "",
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();

/* eslint-disable @typescript-eslint/no-explicit-any */
const WrapAppInsights = (Component: any) => withAITracking(reactPlugin, Component);
export default WrapAppInsights;
export const appInsights = ai.appInsights;
