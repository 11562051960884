interface IValue {
  __typename?: string;
}

export const removeTypeName = <T extends IValue>(value: T) => {
  if (value) {
    const { __typename, ...rest } = value;
    return rest;
  }

  return value;
};
