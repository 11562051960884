import { useCallback, useEffect, useRef } from "react";

const DAY_IN_MILLISECONDS = 86400000;

export const useTimeout = (callback: () => void, delayInMilliseconds: number) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    if (delayInMilliseconds > DAY_IN_MILLISECONDS) {
      return;
    }
    timeoutRef.current = setTimeout(() => callbackRef.current(), delayInMilliseconds);
  }, [delayInMilliseconds]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delayInMilliseconds, set, clear]);
};
