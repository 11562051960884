/* eslint-disable react-hooks/exhaustive-deps */
import Input from "@components/Input/Input";
import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";
import TextTruncated from "@components/TextTruncated/TextTruncated";
import { useCardTextMaxLength } from "@customHooks/useCardTextMaxLength";
import NewFolderIcon from "@images/NewFolderIcon.svg";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "./NewFolderCard.module.scss";

interface INewFolderCard {
  id?: string;
  isEdit?: boolean;
  name?: string;
  folderNameSubmit?: (folderName: string) => void;
  renameFolder?: (folderId: string, folderName: string) => void;
  menuOptions?: IOption[];
  onClick?: (folderId: string) => void;
}
const NewFolderCard = ({
  id,
  isEdit = false,
  name = "",
  folderNameSubmit,
  renameFolder,
  menuOptions,
  onClick,
}: INewFolderCard) => {
  const { t } = useTranslation();

  const [folderNameElement, setFolderNameElement] = useState<HTMLInputElement | null>(
    null
  );

  const { maxTitleLength } = useCardTextMaxLength();

  const newFolderDefaultValues = {
    validateOnMount: true,
    folderName: t("collections.singleCollection.newFolder") as string,
  };

  const newFolderForm = useFormik({
    initialValues: newFolderDefaultValues,
    validationSchema: Yup.object({
      folderName: Yup.string().min(2).max(50).required(),
    }),
    onSubmit: (values) => {
      if (folderNameElement !== null) {
        const inputRenameValue = folderNameElement?.value;

        if (id && inputRenameValue && renameFolder) {
          renameFolder(id, inputRenameValue);
        }
      }

      if (folderNameSubmit) folderNameSubmit(values.folderName);
    },
  });

  // Set input to focus when component is loaded
  useEffect(() => {
    folderNameElement?.focus();
  }, []);

  useEffect(() => {
    folderNameElement?.focus();
  }, [isEdit]);

  // Update form and field value when name changes
  useEffect(() => {
    if (!name) {
      return;
    }
    newFolderForm.setFieldValue("folderName", name, true);
  }, [name]);

  // Handle enter as submit action
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isEdit) {
        event.preventDefault();

        newFolderForm.handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [newFolderForm]);

  const handleClick = () => {
    onClick && id && !isEdit && onClick(id);
  };

  return (
    <div className={styles.card}>
      {menuOptions?.length ? (
        <div className={styles.menuWrapper}>
          <Menu options={menuOptions} />
        </div>
      ) : (
        <div className={styles.menuMockDiv} />
      )}
      <div className={id ? styles.clickable : ""} onClick={handleClick}>
        <img src={NewFolderIcon} className={styles.icon} />
        {/* <br /> */}
        {isEdit ? (
          <form>
            <Input
              inputClassNames={styles.folderNameInput}
              setInputElement={setFolderNameElement}
              maxLength={50}
              label=""
              {...newFolderForm.getFieldProps("folderName")}
            />
          </form>
        ) : (
          // <span className={styles.fileName}>{name}</span>
          <TextTruncated
            textToTruncate={name}
            length={maxTitleLength}
            placement="topLeft"
          />
        )}
      </div>
    </div>
  );
};

export default NewFolderCard;
