/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { EXPLORE_COLLECTIONS } from "@graphql/collection/queries";
import GET_COMMUNITY_RESOURCES from "@graphql/queries/communityResources";
import { GET_SEARCHED_USERS, USER_PLACEHOLDER } from "@graphql/queries/usersSearch";
import { useEffect, useState } from "react";
import { ICommunity, ICommunityResourcesResponse } from "types/community";
import styles from "./Resources.module.scss";

import ResourcesHeading from "@components/ResourceHeading/ResourcesHeading";
import { COLLECTIONS_PLACEHOLDER } from "@constants/placeholders";
import GET_EVENTS from "@graphql/queries/events";
import { getDateInUtc } from "@utils/helpers";
import { EVENTS_PLACEHOLDER, IEvent, IEventsResponse } from "types/event";
import { EMPTY_USER, IUserDashboard } from "types/user";
import ResourcesCollectionsSection from "../ResourcesCollectionsSection/ResourcesCollectionsSection";
import ResourcesCommunitySection from "../ResourcesCommunitySection/ResourcesCommunitySection";
import ResourcesEventSection from "../ResourcesEventsSection/ResourcesEventSection";
import ResourcesUsersSection from "../ResourcesUsersSection/ResourcesUsersSection";

import ShareModal from "@components/ShareModal/ShareModal";
import { FOLLOW_COLLECTION, UNFOLLOW_COLLECTION } from "@graphql/collection/mutation";
import { ICollection, ICollectionsResponse } from "@graphql/collection/type";
import { IUser } from "@microsoft/applicationinsights-common";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getMenuOptions } from "@utils/collectionsHelper";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/RootState";
import { UserState } from "../../store/user/types";
import LinkCollectionToCommunityModal from "../Collections/LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";

const pageSize = 4;
interface IResourcesProps {
  submitValue: string;
  userLoaded: boolean;
  interests: Array<string>;
  locationNames?: Array<string>;
  hiddenArticles?: Array<string>;
  searchResourcesSubmitted: boolean;
}

const Resources = ({
  submitValue,
  userLoaded,
  interests,
  searchResourcesSubmitted,
}: IResourcesProps) => {
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [communities, setCommunities] = useState<Array<ICommunity>>([]);
  const [users, setUsers] = useState<Array<IUserDashboard>>([]);
  const [communitiesInitLoaded, setCommunitiesInitLoaded] = useState<boolean>(false);
  const [eventsInitLoaded, setEventsInitLoaded] = useState<boolean>(false);
  const [collectionsInitLoaded, setCollectionsInitLoaded] = useState<boolean>(false);
  const [usersInitLoaded, setUsersInitLoaded] = useState<boolean>(false);
  const [communitiesCount, setCommunitiesCount] = useState<number>(0);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [collectionsCount, setCollectionsCount] = useState<number>(0);
  const [usersCount, setUsersCount] = useState<number>(0);
  // const [articlesLoader, setArticlesLoader] = useState<boolean>(false);
  // const [articles, setArticles] = useState<Array<IArticle>>([]);
  // const [articlesCount, setArticlesCount] = useState<number>(0);
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [allEventsBySearchTerm, setAllEventsBySearchTerm] = useState<boolean>(false);
  const [displayAllEvents, setDisplayAllEvents] = useState<boolean>(false);

  const [collections, setCollections] = useState<Array<ICollection>>([]);
  const [resourcesCleared, setResourcesCleared] = useState<boolean>(false);

  const user = useSelector<RootState, UserState>((state) => state.user);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareCollectionUrl, setShareCollectionUrl] = useState<string>();
  const [collectionToLinkId, setCollectionToLinkId] = useState<string>();
  const [targetCommunityId, setTargetCommunityId] = useState<string>();
  const [collectionName, setCollectionName] = useState<string>();
  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState<boolean>(false);

  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);

  const { t } = useTranslation();

  const [, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  // renderKey is used to destroy child components whenever it's updated
  // It's useful here since it's hard determining when each of entities got reset (events, articles and communities)
  // Whenever search is triggered, render key will get updated, which will destroy old components, and render new ones
  // This helps with resetting slider states to 0 for all entities. Other approaches would increase complexity, code amount
  // and would require adding logic to 3 more components
  const [renderKey, setRenderKey] = useState<number>(0);

  const [getEvents, { loading: loadingEvent }] = useLazyQuery<IEventsResponse>(
    GET_EVENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data?.events || !data?.events?.events) return;

        data.events.events.length
          ? setEvents([
              ...events.filter((event) => !(event.name === "placeholder")),
              ...data.events.events,
            ])
          : setEvents([]);
        setEventsCount(data?.events?.totalCount || 0);
        !eventsInitLoaded && setEventsInitLoaded(true);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  const [getCommunityResources, { loading }] = useLazyQuery<ICommunityResourcesResponse>(
    GET_COMMUNITY_RESOURCES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.communities || !data.communities.communities) return;
        data.communities.communities.length
          ? setCommunities([
              ...communities.filter((community) => !(community.name === "placeholder")),
              ...data.communities.communities,
            ])
          : setCommunities([]);
        setCommunitiesCount(data?.communities?.totalCount || 0);
        !communitiesInitLoaded && setCommunitiesInitLoaded(true);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  const [getCollections, { loading: loadingCollections }] =
    useLazyQuery<ICollectionsResponse>(EXPLORE_COLLECTIONS, {
      fetchPolicy: "network-only",
      onCompleted: ({ collections: collectionData }) => {
        if (!collectionData || !collectionData.collections) return;

        collectionData.collections.length
          ? setCollections([
              ...collections.filter((collection) => !(collection.name === "placeholder")),
              ...collectionData.collections,
            ])
          : setCollections([]);

        setCollectionsCount(collectionData?.totalCount || 0);
        !collectionsInitLoaded && setCollectionsInitLoaded(true);
      },
      onError: (error) => displayNotSuccessNotification(error),
    });

  const [getUsers, { loading: loadingUsers }] = useLazyQuery<{
    users: { totalCount: number; users: IUserDashboard[] };
  }>(GET_SEARCHED_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.users || !data.users.users) return;
      data.users.users.length
        ? setUsers([
            ...users.filter((user) => !(user.fullName === "placeholder")),
            ...data.users.users,
          ])
        : setUsers([]);
      setUsersCount(data?.users?.totalCount || 0);
      !usersInitLoaded && setUsersInitLoaded(true);
    },
    onError: (error) => displayNotSuccessNotification(error),
  });

  const [followCollection] = useMutation(FOLLOW_COLLECTION, {
    onCompleted: (data) => {
      if (!data?.followCollection?.name) {
        const message = t("collections.singleCollection.followCollection");
        displayNotSuccessNotification(message, message);
      }
      displaySuccessNotification(t("collections.singleCollection.successFollow"));
    },
    onError: (error) => displayServerError(error),
  });

  const [unfollowCollection] = useMutation(UNFOLLOW_COLLECTION, {
    onCompleted: (data) => {
      if (!data?.unfollowCollection?.name) {
        const message = t("collections.singleCollection.unfollowCollection");
        displayNotSuccessNotification(message, message);
      }
      displaySuccessNotification(t("collections.singleCollection.successUnfollow"));
    },
    onError: (error) => displayServerError(error),
  });

  const loadResourceSections = () => {
    // searchArticles();
    loadCommunities();
    loadEvents();
    loadCollections();
    loadUsers();
  };

  // const searchArticles = () => {
  //   setArticlesLoader(true);
  //   articles.length &&
  //     setArticles([
  //       ...articles,
  //       ...addArticlePlaceholders(
  //         articlesCount - articles.length < pageSize
  //           ? articlesCount - articles.length
  //           : pageSize
  //       ),
  //     ]);

  //   ArticleService.getSearchedArticles(
  //     submitValue,
  //     pageSize,
  //     submitValue.length ? [] : [...interests],
  //     articles.length,
  //     submitValue.length ? [] : [...locationNames],
  //     submitValue.length
  //       ? MOST_RELEVANT_ARTICLES_PARAMS
  //       : `${ARTICLES_SORT_TYPES.PUBLISHED} ${ARTICLES_ORDER_TYPES.DESC}`,
  //     hiddenArticles
  //   ).then((result) => {
  //     if (!result?.data || !result?.data?.Articles) return;
  //     articles.length
  //       ? setArticles([
  //           ...articles.filter((article) => !(article.id === "placeholder")),
  //           ...result?.data?.Articles.filter(
  //             (newArticle: IArticle) =>
  //               !articles.find((article) => article.id === newArticle.id)
  //           ),
  //         ])
  //       : result?.data?.Articles.length > 0 && setArticles(result?.data?.Articles);
  //     setArticlesCount(result?.data?.TotalCount || 0);
  //     setArticlesLoader(false);
  //   });
  // };

  const loadCommunities = () => {
    communities.length &&
      setCommunities([
        ...communities,
        ...addCommunityPlaceholders(
          communitiesCount - communities.length < pageSize
            ? communitiesCount - communities.length
            : pageSize
        ),
      ]);
    getCommunityResources({
      variables: {
        skip: communities.length,
        size: pageSize,
        text: submitValue,
        interests: submitValue.length ? [] : interests,
      },
    });
  };
  const loadCollections = () => {
    collections.length &&
      setCollections([
        ...collections,
        ...addCollectionsPlaceholders(
          collectionsCount - collections.length < pageSize
            ? collectionsCount - collections.length
            : pageSize
        ),
      ]);

    getCollections({
      variables: {
        collectionSearch: {
          skip: collections.length,
          size: pageSize,
          text: submitValue,
          interests: submitValue.length ? [] : interests,
        },
      },
    });
  };

  const loadUsers = () => {
    users.length &&
      setUsers([
        ...users,
        ...addUsersPlaceholders(
          usersCount - users.length < pageSize ? usersCount - users.length : pageSize
        ),
      ]);
    getUsers({
      variables: {
        skip: users.length,
        size: pageSize,
        text: submitValue,
      },
    });
  };

  const loadEvents = () => {
    if (allEventsBySearchTerm) {
      loadEventsBasedOnSearch();
    }
    if (displayAllEvents) {
      loadAllEvents();
    }
    events.length &&
      setEvents([
        ...events,
        ...addEventPlaceholders(
          eventsCount - events.length < pageSize ? eventsCount - events.length : pageSize
        ),
      ]);
    getEvents({
      variables: submitValue
        ? {
            skip: events.length,
            size: pageSize,
            text: submitValue,
            interests: [],
            from: getDateInUtc(),
          }
        : {
            skip: events.length,
            size: pageSize,
            interests,
            from: getDateInUtc(),
          },
    });
  };

  useEffect(() => {
    allEventsBySearchTerm && loadEventsBasedOnSearch();
  }, [allEventsBySearchTerm]);

  useEffect(() => {
    displayAllEvents && loadAllEvents();
  }, [displayAllEvents]);

  const loadEventsBasedOnSearch = () => {
    events.length &&
      setEvents([
        ...events,
        ...addEventPlaceholders(
          eventsCount - events.length < pageSize ? eventsCount - events.length : pageSize
        ),
      ]);

    getEvents({
      variables: {
        text: submitValue,
        skip: events.length,
        size: pageSize,
        from: getDateInUtc(),
        interests: submitValue.length ? [] : interests,
      },
    });
  };

  const loadAllEvents = () => {
    events.length &&
      setEvents([
        ...events,
        ...addEventPlaceholders(
          eventsCount - events.length < pageSize ? eventsCount - events.length : pageSize
        ),
      ]);

    getEvents({
      variables: {
        skip: events.length,
        size: pageSize,
        from: getDateInUtc(),
        interests: submitValue.length ? [] : interests,
      },
    });
  };

  // const addArticlePlaceholders = (size: number) => {
  //   const placeholderArray = [];
  //   for (let i = 0; i < size; i++) {
  //     placeholderArray.push(ArticleService.addPlaceholderArticle());
  //   }
  //   return placeholderArray;
  // };

  const addEventPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push(EVENTS_PLACEHOLDER);
    }
    return placeholderArray;
  };

  const addCollectionsPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push(COLLECTIONS_PLACEHOLDER);
    }
    return placeholderArray;
  };

  const addUsersPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push(USER_PLACEHOLDER);
    }
    return placeholderArray;
  };

  const addCommunityPlaceholders = (size: number) => {
    const placeholderArray = [];
    for (let i = 0; i < size; i++) {
      placeholderArray.push({
        name: "placeholder",
        description: "",
        sendbirdId: "",
        communityImageUrl: "",
        isPublic: false,
        isHidden: false,
        members: [],
        joinRequests: [],
        admins: [],
        communityCreator: EMPTY_USER,
      });
    }
    return placeholderArray;
  };
  useEffect(() => {
    if (!userLoaded) return;
    loadResourceSections();
  }, [userLoaded]);

  const clearResources = () => {
    // setArticles([]);
    setCommunities([]);
    setEvents([]);
    setCollections([]);
    setUsers([]);
  };

  useEffect(() => {
    if (!userLoaded) return;
    // It changes only when search term gets updated (triggered by pressing Enter key)
    setRenderKey(renderKey + 1);
    clearResources();
    setResourcesCleared(true);
    setDisplayAllEvents(false);
    setAllEventsBySearchTerm(false);
  }, [submitValue]);

  useEffect(() => {
    if (!resourcesCleared) return;

    loadResourceSections();

    setResourcesCleared(false);
  }, [resourcesCleared]);

  useEffect(() => {
    setResultsCount(usersCount + collectionsCount + communitiesCount + eventsCount || 0);
  }, [usersCount, collectionsCount, communitiesCount, eventsCount]);

  // useEffect(() => {
  //   if (!articles.length) {
  //     setArticlesLoader(true);
  //   }
  // }, []);

  const handleCopyLink = () => {
    if (shareCollectionUrl) {
      navigator.clipboard.writeText(shareCollectionUrl);
      displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
    }
  };

  const hadleLinkCollection = (collection: ICollection) => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
    setCollectionToLinkId(collection.publicId);
    setCollectionName(collection.name);
  };

  const handleShareCollection = (collection: ICollection) => {
    setShareModalOpen(true);
    setShareCollectionUrl(
      `${window.location.origin}/portal/collection/${collection.publicId}`
    );
  };

  const handleFollowCollection = (collection: ICollection) => {
    followCollection({
      variables: {
        collectionPublicId: collection.publicId,
      },
    }).then((data) => {
      setCollections((stateCollectins) =>
        stateCollectins.map((stateCol) => {
          if (stateCol.publicId === collection.publicId) {
            return {
              ...stateCol,
              // @ts-expect-error problem with ts checker, same line work in other parts of the app
              followers: stateCol.followers?.concat({
                email: user.email,
              } as IUser),
            };
          } else {
            return stateCol;
          }
        })
      );
    });
  };

  const handleUnfollowCollection = (collection: ICollection) => {
    unfollowCollection({
      variables: {
        collectionPublicId: collection.publicId,
      },
    }).then((data) => {
      setCollections((stateCollections) =>
        stateCollections.map((stateCol) => {
          if (stateCol.publicId === collection.publicId) {
            return {
              ...stateCol,
              followers: stateCol.followers?.filter(
                (follower) => follower.email !== user.email
              ),
            };
          } else {
            return stateCol;
          }
        })
      );
    });
  };

  const menuDots = (collection: ICollection) => {
    return getMenuOptions(collection, user.email, {
      link: (collection) => hadleLinkCollection(collection),
      share: (collection) => handleShareCollection(collection),
      follow: (collection) => handleFollowCollection(collection),
      unfollow: (collection) => handleUnfollowCollection(collection),
    });
  };

  return (
    <div className={styles.resourcesContainer} e2e-test-id="dashboard-container">
      <ResourcesHeading
        resultsCount={resultsCount}
        searchValue={submitValue}
        isTitleDisplayed={false}
      />
      {/* <ResourcesArticlesSection
        // React will react to any renderKey change will be destroying old component,
        // creating new one with new key, and rendering it instead
        key={`articles_${renderKey}`}
        articles={articles}
        articlesCount={articlesCount}
        searchArticles={searchArticles}
        loader={articlesLoader}
        pageSize={pageSize}
      /> */}
      <ResourcesCommunitySection
        key={`communities_${renderKey}`}
        communities={communities}
        communitiesCount={communitiesCount}
        communitiesLoading={!communitiesInitLoaded || loading}
        loadCommunities={loadCommunities}
        pageSize={pageSize}
        searchResourcesSubmitted={searchResourcesSubmitted}
      />
      <ResourcesCollectionsSection
        key={`collections_${renderKey}`}
        collections={collections}
        collectionsCount={collectionsCount}
        collectionsLoading={!collectionsInitLoaded || loadingCollections}
        loadCollections={loadCollections}
        pageSize={pageSize}
        searchResourcesSubmitted={searchResourcesSubmitted}
        menuDots={menuDots}
      />
      <ResourcesEventSection
        key={`events_${renderKey}`}
        events={events}
        eventsCount={eventsCount}
        loading={!eventsInitLoaded || loadingEvent}
        loadEvents={
          !displayAllEvents && !allEventsBySearchTerm
            ? loadEvents
            : allEventsBySearchTerm
            ? loadEventsBasedOnSearch
            : loadAllEvents
        }
        pageSize={pageSize}
        searchResourcesSubmitted={searchResourcesSubmitted}
      />
      <ResourcesUsersSection
        key={`users_${renderKey}`}
        users={users}
        usersCount={usersCount}
        usersLoading={!usersInitLoaded || loadingUsers}
        loadUsers={loadUsers}
        pageSize={pageSize}
        searchResourcesSubmitted={searchResourcesSubmitted}
      />
      {isLinkCollectionToCommuniyModalOpen && (
        <LinkCollectionToCommunityModal
          isOpen={isLinkCollectionToCommuniyModalOpen}
          collectionId={collectionToLinkId}
          collectionName={collectionName}
          targetCommunityId={targetCommunityId}
          onClose={handleCloseLinkCollectionToCommunityModal}
          onSetTargetCommunityId={handleSetTargetCommunityId}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        close={() => setShareModalOpen(false)}
        customUrl={shareCollectionUrl}
        copyLink={handleCopyLink}
        share={shareSuccessMessage}
      />
    </div>
  );
};

export default Resources;
