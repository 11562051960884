import { useMutation, useQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Modal from "@components/Modal/Modal";
import SelectComponent from "@components/Select/Select";
import TextareaComponent from "@components/Textarea/Textarea";
import REPORT_USER from "@graphql/mutations/reportUser";
import GET_REPORT_TYPES from "@graphql/queries/reportTypes";
import Cancel from "@images/cancelIcon.png";
import { displaySuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IReportType } from "types/reportType";
import styles from "./ReportUserModal.module.scss";

interface IReportUserModalProps {
  open: boolean;
  close: () => void;
  publicId: string;
  maxTextLength?: number;
}

const ReportUserModal = ({
  open,
  close,
  publicId,
  maxTextLength,
}: IReportUserModalProps) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState<string>();
  const [reportTypeId, setReportTypeId] = useState<string>("");
  const [reportUser] = useMutation(REPORT_USER);

  const { data: reportTypes, loading } = useQuery(GET_REPORT_TYPES);

  const disableSubmit: boolean = !feedback || !reportTypeId;

  const mapForSelect = (types: IReportType[]) =>
    types?.length
      ? types.map((type) => {
          return {
            value: type.name,
            label: type.name,
            publicId: type.publicId,
          };
        })
      : [];

  const handleClose = () => {
    setReportTypeId("");
    setFeedback("");
    close();
  };

  const handleSubmit = () => {
    reportUser({
      variables: {
        feedback,
        reportedPublicId: publicId,
        reportTypePublicId: reportTypeId,
      },
    });
    displaySuccessNotification(t("notifications.success.reportUser"));
    handleClose();
  };

  return (
    <>
      {loading ? (
        <Loading borderless disableBoxShadow />
      ) : (
        <Modal className={styles.wrapper} isOpen={open} modalType="primary">
          <div className={styles.header}>
            <h4>{t("reportUser.title")}</h4>
            <img src={Cancel} className={styles.removeIcon} onClick={close} alt="" />
          </div>
          <SelectComponent
            label={t("reportUser.reportReason")}
            placeholder={t("reportUser.reportPlaceholder")}
            name={""}
            options={mapForSelect(reportTypes?.userReportTypes)}
            onChange={(type: IReportType) => setReportTypeId(type.publicId)}
            showDropdown
            boldLabel
          />
          <TextareaComponent
            onChange={(event) => setFeedback(event.target.value)}
            name="description"
            label={t("reportUser.feedback")}
            aria-label="description textarea"
            placeholder={t("reportUser.feedbackPlacehodler")}
            boldLabel
            maxLength={maxTextLength}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t("reportUser.buttons.cancel")}
              buttonType="link"
              onClick={close}
              buttonSize="large"
            />
            <Button
              aria-label="reportUserModalSubmit"
              title={t("reportUser.buttons.buttonTitle")}
              buttonType="primary"
              onClick={handleSubmit}
              disabled={disableSubmit}
              buttonSize="large"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReportUserModal;
