import { IImage } from "../../../types/image";

import { DEFAULT_USER_IMAGE_NAME } from "../../../utils/getStorageImage";

import userImage from "../../../images/defaultUserImage.svg";

export const defaultUserImage: IImage = {
  name: DEFAULT_USER_IMAGE_NAME,
  label: "banner1",
  image: userImage,
  removeIcon: false,
  checkButton: true,
};

export enum ChatPrivacy {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export const ChatPrivacyToTranslation = {
  [ChatPrivacy.PUBLIC]: "accountSettings.profileDetails.label.recieveMessage",
  [ChatPrivacy.PRIVATE]: "accountSettings.profileDetails.label.noRecieveMessage",
};

export const getCanReceiveMessage = (chatPrivacy: ChatPrivacy) => {
  return chatPrivacy === ChatPrivacy.PUBLIC;
};

export const getChatPrivacy = (canReceiveMessages?: boolean) => {
  return canReceiveMessages ? ChatPrivacy.PUBLIC : ChatPrivacy.PRIVATE;
};

export const CHAT_PRIVACY_OPTIONS = [
  {
    value: ChatPrivacy.PUBLIC,
    label: "accountSettings.profileDetails.chatOptions.public",
  },
  {
    value: ChatPrivacy.PRIVATE,
    label: "accountSettings.profileDetails.chatOptions.private",
  },
];
