export interface IFeedback {
  subject: string;
  publicId: number;
  autoIncrementedId: number;
  content: string;
  reporter: {
    fullName: string;
    email: string;
  };
}

export const EMPTY_FEEDBACK = {
  subject: "",
  publicId: 0,
  autoIncrementedId: 0,
  content: "",
  reporter: {
    fullName: "",
    email: "",
  },
};
