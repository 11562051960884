import { gql } from "@apollo/client";

const GET_EVENT_METRICS = gql`
  query Event($publicId: ID!) {
    event(publicId: $publicId) {
      attendeesTotalCount
      attendeesCountriesTotalCount
    }
  }
`;

export default GET_EVENT_METRICS;
