import Modal from "@components/Modal/Modal";
import removeIcon from "@images/cancelIcon.png";
import ticks from "@images/ticks.png";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SentFeedbackModal.module.scss";

interface IFeedbackModalProps {
  open: boolean;
  close: () => void;
  closeProvideModal: () => void;
}

const SentFeedbackModal = ({ open, close, closeProvideModal }: IFeedbackModalProps) => {
  const { t } = useTranslation();

  const closeModals = useCallback(() => {
    close();
    closeProvideModal();
  }, [close, closeProvideModal]);

  return (
    <Modal isOpen={open}>
      <img src={removeIcon} className={styles.removeIcon} onClick={closeModals} alt="" />
      <div className={styles.titleContainer}>
        <img src={ticks} alt="" width={60} height={60} />
        <span className={styles.title}>{t("feedback.sentFeedbackModal.title")}</span>
        <span className={styles.textBellow}>
          {t("feedback.sentFeedbackModal.messageFirst")}
        </span>
        <span className={styles.textBellowSecond}>
          {t("feedback.sentFeedbackModal.messageSecond")}
        </span>
      </div>
    </Modal>
  );
};

export default SentFeedbackModal;
