/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import ArticleComment from "@components/ArticleComment/ArticleComment";
import Button from "@components/Button/Button";
import CommentInput from "@components/CommentInput/CommentInput";
import Loading from "@components/Loading/Loading";
import { ARTICLE_COMMENT_MAX_LENGTH } from "@constants/constants";
import ADD_ARTICLE_COMMENT from "@graphql/mutations/addArticleComment";
import GET_ARTICLE_COMMENTS from "@graphql/queries/articleComments";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { Divider } from "antd";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComment } from "types/comment";
import styles from "./SingleArticleDrawer.module.scss";

interface ISingleArticleDrawerProps {
  closeInfoDrawer: () => void;
  articleId: string;
}

const SingleArticleDrawer = ({
  closeInfoDrawer,
  articleId,
}: ISingleArticleDrawerProps) => {
  const { t } = useTranslation();
  const [newCommentContent, setNewCommentContent] = useState<string>("");
  const [comments, setComments] = useState<Array<IComment>>();

  const [addArticleComment, { loading: loadingAddComment }] = useMutation(
    ADD_ARTICLE_COMMENT,
    {
      variables: {
        articleId,
        content: newCommentContent,
      },
      onCompleted: () => {
        setNewCommentContent("");
        setComments([]);
        getArticleComments();
      },
      onError: (error) => {
        displayServerError(error);
      },
    }
  );
  const [getArticleComments, { loading }] = useLazyQuery(GET_ARTICLE_COMMENTS, {
    fetchPolicy: "no-cache",
    variables: {
      articleId,
    },
    onCompleted: (data) => {
      setComments(data.articleComments);
    },
    onError: (error) => displayServerError(error),
  });

  useEffect(() => {
    getArticleComments();
  }, []);

  const handleCommentInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommentContent(event.target.value);
  };

  const handleComment = (e: FormEvent) => {
    e.preventDefault();
    if (!newCommentContent.trim()) return;
    addArticleComment();
    setNewCommentContent("");
  };

  return (
    <div className={styles.backdrop} aria-label="container">
      <div className={styles.drawer}>
        <div className={styles.drawerContainer}>
          <div>
            {loading || loadingAddComment ? (
              <Loading disableBoxShadow />
            ) : (
              <div>
                <div className={styles.header}>
                  <h3 className={styles.headerTitle}>
                    {`

							${t("singleArticle.commentSection.comments")} (${comments?.length || "0"})
							`}
                  </h3>
                  <Button
                    title={""}
                    buttonType="link"
                    style={{}}
                    buttonIcon={<CloseOutlined />}
                    onClick={closeInfoDrawer}
                  />
                </div>
                <div className={styles.input}>
                  <form onSubmit={handleComment}>
                    <CommentInput
                      name={"chat"}
                      value={newCommentContent}
                      maxLength={ARTICLE_COMMENT_MAX_LENGTH}
                      onChange={handleCommentInput}
                      placeholder={t("singleArticle.commentSection.commentPlaceholder")}
                      inputName={"inputChat"}
                      onClick={handleComment}
                    />
                  </form>
                </div>
                <div>
                  {comments &&
                    comments.map((item) => {
                      return (
                        <div key={item.publicId}>
                          <ArticleComment item={item} />
                          <Divider className={styles.dividerColor} />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleArticleDrawer;
