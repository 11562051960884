import { gql } from "@apollo/client";

const GET_OPEN_EVENTS = gql`
  query events(
    $size: UInt
    $skip: UInt
    $text: String
    $interests: [String]
    $from: DateTime
    $to: DateTime
  ) {
    events(
      eventSearch: {
        size: $size
        skip: $skip
        text: $text
        interests: $interests
        from: $from
        to: $to
      }
    ) {
      totalCount
      events {
        name
        publicId
        description
        eventType
        hostingId
        eventImageName
        imageDirectory
        eventFormat
        startDate
        endDate
        eventHost {
          fullName
        }
      }
    }
  }
`;

const GET_EVENT = gql`
  query Event($publicId: ID!) {
    event(publicId: $publicId) {
      publicId
      name
      description
      eventType
      hostingId
      eventImageName
      imageDirectory
      eventFormat
      startDate
      endDate
      eventHost {
        fullName
        userPhotoName
        imageDirectory
      }
    }
  }
`;

export { GET_EVENT, GET_OPEN_EVENTS };
