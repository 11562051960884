import { ILocation } from "../../types/location";
import {
  ACCEPT_CODE,
  ACCEPT_TERMS,
  ADD_INTERESTS,
  ADD_LOCATIONS,
  ADD_PERSONAL_DETAILS,
  ADD_PROFILE_DETAILS,
  CLEAR,
  RECEIVE_MESSAGES,
} from "./constants";
import { Interest, PersonalDetails, ProfileDetails } from "./types";

export const addPersonalDetails = (data: PersonalDetails) => ({
  type: ADD_PERSONAL_DETAILS,
  payload: data,
});

export const addProfileDetails = (data: ProfileDetails) => ({
  type: ADD_PROFILE_DETAILS,
  payload: data,
});

export const addInterests = (data: Array<Interest>) => ({
  type: ADD_INTERESTS,
  payload: data,
});

export const addLocations = (data: Array<ILocation>) => ({
  type: ADD_LOCATIONS,
  payload: data,
});

export const setReceiveMessages = (data: boolean) => ({
  type: RECEIVE_MESSAGES,
  payload: data,
});

export const clear = () => ({
  type: CLEAR,
});

export const setAcceptTerms = (data: boolean) => ({
  type: ACCEPT_TERMS,
  payload: data,
});

export const setAcceptCode = (data: boolean) => ({
  type: ACCEPT_CODE,
  payload: data,
});
