import { gql } from "@apollo/client";

const GENERATE_TOKEN = gql`
  mutation generateToken {
    generateToken {
      scalar
    }
  }
`;

export default GENERATE_TOKEN;
