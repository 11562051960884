import { FileErrorKey } from "./enum";

export const supportedVideoExtensions = [".webm", ".ogg", ".flv", ".avi", ".wmv", ".mp4"];
const supportedAudioExtensions = [".wma", ".wav", ".mp3", ".aiff", ".flac"];
export const supportedImageExtensions = [
  ".png",
  ".jpg",
  ".jpeg",
  ".ico",
  ".eps",
  ".bmp",
  ".webp",
  ".ai",
  ".svg",
];
export const notSupportedExtensionsForDocumentPreview = [
  ".wma",
  ".avi",
  ".mov",
  ".mkv",
  ".flv",
  ".mpeg",
  ".wmv",
  ".ai",
  ".zip",
  ".ods",
  ".xls",
  ".xlsx",
  ".xlsm",
];
export const supportedDocumentExtensions = [".rtf", ".odt", ".pdf", ".doc", ".docx"];
export const supportedSpreadSheetExtensions = [".xlsx", ".xlsm", ".xls", ".ods"];
const supportedPresentationExtensions = [".pptx", ".ppt", ".odp"];
const supportedMarkUpExtensions = [".xml"];
export const supportedArchiveExtensions = [".zip"];

export const defaultSupportedExtensions = [
  ...supportedVideoExtensions,
  ...supportedAudioExtensions,
  ...supportedImageExtensions,
  ...supportedDocumentExtensions,
  ...supportedSpreadSheetExtensions,
  ...supportedPresentationExtensions,
  ...supportedMarkUpExtensions,
  ...supportedArchiveExtensions,
];

const supportedEventVideoExtensions = [".mp4", ".mov", ".mpeg"];

export const supportedEventExtensions = [
  ...defaultSupportedExtensions,
  ...supportedEventVideoExtensions,
];

export const supportedUploadToCollectionExtension = [
  ...defaultSupportedExtensions,
  ...supportedEventVideoExtensions,
  ".mkv",
  ".gif",
];

export const supportedOfficeExtensions = [
  ...supportedDocumentExtensions,
  ...supportedSpreadSheetExtensions,
  ...supportedPresentationExtensions,
  ...supportedMarkUpExtensions,
];

export const FileErrorKeyToTranslation = new Map<FileErrorKey, string>();
FileErrorKeyToTranslation.set(FileErrorKey.MULTIPLE, "file.error.multiple");
FileErrorKeyToTranslation.set(FileErrorKey.DUPLICATE, "file.error.duplicate");
