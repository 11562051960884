import ReactModal from "react-modal";
import styles from "./Modal.module.scss";

type ModalType = {
  default: "default";
  primary: "primary";
  narrow: "narrow";
};

const ModalTypeClasses: Record<keyof ModalType, string> = {
  default: styles.default,
  primary: styles.primary,
  narrow: styles.narrow,
};

export interface IModalProps extends ReactModal.Props {
  children: React.ReactNode;
  contentStyles?: React.CSSProperties;
  modalType?: keyof ModalType;
}

const Modal = (props: IModalProps) => {
  const getContainerClassName = () =>
    `${props.className} ${styles.modalContainer} ${
      ModalTypeClasses[props.modalType || "default"]
    }`;

  return (
    <ReactModal {...props} ariaHideApp={false} className={getContainerClassName()}>
      {props.children}
    </ReactModal>
  );
};

export default Modal;
