import styles from "./ChatAction.module.scss";

interface IChatActionProps {
  onOptionClick: () => void;
  text: string;
  children: React.ReactNode;
  ariaLabel: string;
}

export const ChatAction = ({
  onOptionClick,
  text,
  children,
  ariaLabel,
}: IChatActionProps) => {
  return (
    <>
      <div
        aria-label={ariaLabel}
        className={styles.option}
        onClick={onOptionClick}
        role="listitem"
      >
        <span className={styles.dropdownItem}>{text}</span>
      </div>
      {children}
    </>
  );
};
