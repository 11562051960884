import { gql } from "@apollo/client";

const GENERATE_ACCESS_EVENT_INFORMATION = gql`
  mutation getAccessEventInformation($hostingId: Long) {
    getAccessEventInformation(hostingId: $hostingId) {
      signature
      joinEmail
      sdkKey
    }
  }
`;

export default GENERATE_ACCESS_EVENT_INFORMATION;
