import { LanguageToTranslationKey } from "./constant";

import { Language } from "./enum";

export const options = [
  { value: Language.ENGLISH, label: LanguageToTranslationKey[Language.ENGLISH] },
  { value: Language.KOREAN, label: LanguageToTranslationKey[Language.KOREAN] },
  { value: Language.SPANISH, label: LanguageToTranslationKey[Language.SPANISH] },
  { value: Language.FRENCH, label: LanguageToTranslationKey[Language.FRENCH] },
  { value: Language.RUSSIAN, label: LanguageToTranslationKey[Language.RUSSIAN] },
  { value: Language.CHINESE, label: LanguageToTranslationKey[Language.CHINESE] },
  { value: Language.ARABIC, label: LanguageToTranslationKey[Language.ARABIC] },
];
