import { gql } from "@apollo/client";

const CANCEL_PENDING_INVITATION = gql`
  mutation cancelPendingInvitation(
    $cancelPendingInvitation: CancelPendingInvitationInputType!
  ) {
    cancelPendingInvitation(cancelPendingInvitation: $cancelPendingInvitation)
  }
`;

export default CANCEL_PENDING_INVITATION;
