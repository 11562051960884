import { gql } from "@apollo/client";

const DEMOTE_MEMBER = gql`
  mutation demoteCommunityAdmin($communitySendbirdId: String!, $adminEmail: String!) {
    demoteCommunityAdmin(
      demoteAdmin: { communitySendbirdId: $communitySendbirdId, adminEmail: $adminEmail }
    ) {
      name
      sendbirdId
    }
  }
`;

export default DEMOTE_MEMBER;
