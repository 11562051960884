/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import PageHeading from "@components/PageHeading/PageHeading";
import UserPhotoModal from "@components/UserPhotoModal/UserPhotoModal";

import UPDATE_USER from "@graphql/mutations/updateUser";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { addProfileDetails, setReceiveMessages } from "../../../store/onboarding/actions";
import {
  PersonalDetails,
  ProfileDetails as ProfileDetailsType,
} from "../../../store/onboarding/types";
import { RootState } from "../../../store/store";

import Avatar from "@images/avatar.svg";

import { CreateUpdateForm } from "./CreateUpdateForm/CreateUpdateForm";

import { Label } from "@components/formik/Label/Label";

import styles from "./ProfileDetails.module.scss";

const ProfileDetails = () => {
  const fullName = useSelector<RootState, string>(
    (state) => state.onboarding.personalDetails.fullName
  );
  const profileDetails = useSelector<RootState, ProfileDetailsType>(
    (state) => state.onboarding.profileDetails
  );
  const { canReceiveMessages } = useSelector<RootState, PersonalDetails>(
    (state) => state.onboarding.personalDetails
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isUserPhotoModalOpen, setIsUserPhotoModalOpen] = useState<boolean>(false);
  const [updateUser] = useMutation(UPDATE_USER);

  const [formData, setFormData] = useState<ProfileDetailsType>({
    workLocation: profileDetails.workLocation || { name: "", callingCode: "" },
    bio: profileDetails.bio || "",
    sector: profileDetails.sector || "",
    organizationName: profileDetails.organizationName || "",
    organizationRole: profileDetails.organizationRole || "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const userPhoto = useSelector<RootState, string>((state) => state.user.userImage);

  const setUploadedImage = (imageName: string, directoryName: string) => {
    updateUser({
      variables: {
        user: {
          userPhotoName: imageName,
          imageDirectory: directoryName,
        },
      },
    })
      .then((response) => {
        // Currently doesn't support validation for same strings, so Error notification will be shown if save is triggered on save string
        if (!response.data.updateUser) {
          displayNotSuccessNotification();
          return;
        }

        displaySuccessNotification();
      })
      .catch(() => {
        displayServerError();
      });
  };

  return (
    <div>
      <UserPhotoModal
        isOpen={isUserPhotoModalOpen}
        close={() => setIsUserPhotoModalOpen(false)}
        setUploadedImage={setUploadedImage}
        currentUserPhoto={userPhoto}
      />
      <div className={styles.detailsContainer}>
        <PageHeading
          heading={t("profileDetails.pageTitle")}
          subheading={t("profileDetails.subheading")}
        />
        <Input
          aria-label="fullName"
          name={"fullName"}
          label={t("input.fullName.label")}
          inputClassNames={styles.disabledInputColor}
          placeholder={t("input.fullName.placeholder")}
          onChange={(e) => handleChange(e)}
          value={fullName}
          readOnly
        />
        <div className={styles.profileImageContainer}>
          <div>
            <Label
              text={t("accountSettings.profileDetails.label.profileImage")}
              name=""
            />
            <Button
              title={t("accountSettings.profileDetails.button.profileImage")}
              onClick={() => setIsUserPhotoModalOpen(true)}
            />
          </div>
          <div
            style={{ backgroundImage: `url(${userPhoto || Avatar})` }}
            className={styles.image}
          ></div>
        </div>

        <CreateUpdateForm
          canReceiveMessages={canReceiveMessages}
          profileDetails={profileDetails}
          onSubmit={(data, isPublic) => {
            dispatch(addProfileDetails(data));
            dispatch(setReceiveMessages(isPublic));
            history.push("/onboarding/interests");
          }}
        />
      </div>
    </div>
  );
};

export default ProfileDetails;
