import { gql } from "@apollo/client";

const GENERATE_SAS_TOKEN_WITH_DIRECTORY = gql`
  mutation generateSasTokenWithDirectory($blobStorage: BlobStorageInputType!) {
    generateSasTokenWithDirectory(blobStorage: $blobStorage) {
      values
      directoryName
    }
  }
`;

export default GENERATE_SAS_TOKEN_WITH_DIRECTORY;
