import DayJs from "dayjs";

export const MIN_CHARACTERS = 3;
export const MAX_CHARACTERS = 40;
export const NUMBER_OF_DAYS_FOR_DEFAULT_DEACTIVATION_DATE = 14;

export const DEFAULT_DATE_DEACTIVATION = DayJs()
  .add(NUMBER_OF_DAYS_FOR_DEFAULT_DEACTIVATION_DATE, "days")
  .toDate();

export const MIN_DATE_DEACTIVATION = DayJs().add(1, "days").toDate();

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  deactivationDate: DEFAULT_DATE_DEACTIVATION,
  isTemporary: false,
  selectedCommunities: [],
};
