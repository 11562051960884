/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { KeyboardEvent } from "react";

import { useTranslation } from "react-i18next";

import Select, {
  MenuListProps,
  StylesConfig,
  components,
  createFilter,
} from "react-select";

import { FixedSizeList as List } from "react-window";

import { getDefaultStyles } from "./styles/selectStyles";

import { Control } from "@components/formik/SelectUpdated/Control/Control";
import { DropdownIndicator } from "@components/formik/SelectUpdated/DropdownIndicator/DropDownIndicator";
import { indicatorPosition } from "@components/formik/SelectUpdated/type";

import style from "./Select.module.scss";

interface IOption {
  value: string;
  label: string;
}
export interface ISelectProps {
  label?: string | JSX.Element;
  name: string;
  value?:
    | string[]
    | null
    | Array<{ value: string; label: string }>
    | { value: string; label: string };
  options: Array<IOption>;
  placeholder?: string;
  className?: string;
  isMulti?: boolean;
  defaultSelect?:
    | { value: string; label: string | JSX.Element }
    | { value: string; label: string }[];
  onChange?: any;
  selectWithIcon?: boolean;
  isClearable?: boolean;
  icon?: string;
  textBottom?: string;
  noneValue?: boolean | undefined;
  numberOnlyInput?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  virtualized?: boolean;
  boldLabel?: boolean;
  styles?: any;
  e2eTestId?: string;
  controlShouldRenderValue?: boolean;
  filterOption?: { matchFrom?: "any" | "start" };
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  iconSide?: indicatorPosition;
  showDropdown?: boolean;
}

const BACKSPACE = "Backspace";
const height = 42;

const SelectComponent = ({
  options,
  placeholder,
  className,
  label,
  name,
  onChange,
  value,
  isMulti,
  selectWithIcon,
  icon,
  textBottom,
  defaultSelect,
  numberOnlyInput,
  isSearchable,
  isClearable,
  isDisabled,
  virtualized,
  boldLabel,
  styles,
  e2eTestId,
  controlShouldRenderValue = true,
  filterOption,
  onBlur,
  iconSide = "left",
}: ISelectProps) => {
  const { t } = useTranslation();
  const getStyles = () =>
    styles
      ? ({ ...getDefaultStyles(selectWithIcon), ...styles } as StylesConfig)
      : (getDefaultStyles(selectWithIcon) as StylesConfig);

  const handleNumberOnlyInput = (e: KeyboardEvent<HTMLElement>) => {
    if (!numberOnlyInput) return;
    if (e.key !== BACKSPACE && isNaN(parseInt(e.key))) e.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomOption = ({ children, ...props }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    return (
      <components.Option className={style.customOption} {...props}>
        {children}
      </components.Option>
    );
  };

  const MenuList = (props: MenuListProps) => {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    const childrenOptions = React.Children.toArray(children);

    if (virtualized)
      return (
        <List
          height={maxHeight}
          itemCount={childrenOptions.length || 0}
          itemSize={height}
          initialScrollOffset={initialOffset}
          width={"100%"}
          className={style.list}
        >
          {({ index, style }) => <div style={style}>{childrenOptions[index]}</div>}
        </List>
      );

    return <components.MenuList {...props}>{children}</components.MenuList>;
  };

  return (
    <div
      className={`${className}  ${className && style[className]}`}
      data-testid="select"
      e2e-test-id={e2eTestId}
    >
      {label && (
        <label
          className={`${style.label} ${boldLabel ? style.boldLabel : style.inputLabel}`}
          htmlFor={name}
        >
          <div>{label}</div>
          <span className={style.textBottom}>{textBottom}</span>
        </label>
      )}
      <Select
        aria-label={`select-${name}`}
        onBlur={onBlur}
        defaultValue={defaultSelect}
        styles={getStyles()}
        options={options}
        components={{
          Control,
          Option: CustomOption,
          MenuList,
          DropdownIndicator,
        }}
        menuPosition="fixed"
        filterOption={createFilter({ ignoreAccents: false, ...filterOption })}
        placeholder={placeholder}
        className={style.select}
        maxMenuHeight={200}
        onChange={onChange}
        isMulti={isMulti}
        isClearable={isClearable}
        backspaceRemovesValue
        value={value}
        tabSelectsValue={false}
        onKeyDown={handleNumberOnlyInput}
        isSearchable={!!isSearchable}
        isDisabled={isDisabled}
        menuPlacement="auto"
        noOptionsMessage={() => t("select.noOptions")}
        controlShouldRenderValue={controlShouldRenderValue}
        data-icon={icon}
        data-icon-position={iconSide}
      />
    </div>
  );
};

export default SelectComponent;
