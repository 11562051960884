import VerticalLinearStepper from "@components/Stepper/VerticalLinearStepper";
import Epicenter from "@images/hive_logo.svg";
import Who from "@images/who_logo.svg";
import { useTranslation } from "react-i18next";
import styles from "./SideBar.module.scss";

interface ISideBarProps {
  onboardingSteps?: boolean;
}

const SideBar = ({ onboardingSteps }: ISideBarProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img className={styles.epicenterImage} src={Epicenter} />

      {onboardingSteps && (
        <div className={styles.step}>
          <VerticalLinearStepper />
        </div>
      )}

      <div className={styles.whoWrapper}>
        <span>{t("verticalLinearStepper.textBottom")}</span>
        <img className={styles.logo} src={Who} alt="" />
      </div>
    </div>
  );
};

export default SideBar;
