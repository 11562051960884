import { History } from "history";

import { GroupChannel } from "sendbird";

import { SENDBIRD_ID_PREFIX } from "../constants/constants";

export const redirectToChatPage = (groupChannel: GroupChannel, history: History) => {
  const id = groupChannel.url.replace(SENDBIRD_ID_PREFIX, "");
  history.push({ pathname: `/portal/chat-page/${id}` });
};
