// import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

// import LeftSideMenu from "./LeftSideMenu/LeftSideMenu";
import { ITab } from "@components/navigation/Tabs";
import { Tabs } from "@components/navigation/Tabs/Tabs";
import { ClaimsService } from "@services/ClaimsService";
import { ADMIN_PANEL, ADMIN_PANEL_ROUTES_PREFIX } from "@utils/routes/adminPanel";
import { useTranslation } from "react-i18next";
import styles from "./AdminPanel.module.scss";
import Community from "./Community/Community";
import FeedbackTable from "./Feedbacks/Feedbacks";
import GenerateAccessToken from "./GenerateAccessToken/GenerateAccessToken";
import PlatformStatistics from "./PlatformStatistics/PlatformStatistics";
import Users from "./Users/Users";
import { renderPanel } from "./utils";

// const defaultPage = () => {
//   if (ClaimsService.hasUserReadClaim())
//     return <Redirect to="/portal/admin-panel/users" />;
//   else if (ClaimsService.hasFeedbackReadClaim())
//     return <Redirect to="/portal/admin-panel/feedbacks" />;

//   return <></>;
// };

const AdminPanel = () => {
  const { t } = useTranslation();

  const tabs: ITab[] = [
    {
      id: "1",
      name: t("adminPanel.sidemenu.users"),
      path: ADMIN_PANEL.USERS,
      content: <Users />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
    {
      id: "2",
      name: t("adminPanel.sidemenu.community"),
      path: ADMIN_PANEL.COMMUNITY,
      content: <Community />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
    {
      id: "3",
      name: t("adminPanel.sidemenu.feedback"),
      path: ADMIN_PANEL.FEEDBACKS,
      content: <FeedbackTable />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
  ];

  if (ClaimsService.hasDeviceTokenCreate()) {
    tabs.push({
      id: "4",
      name: t("adminPanel.sidemenu.generateToken"),
      path: ADMIN_PANEL.GENERATE_ACCESS_TOKEN,
      content: <GenerateAccessToken />,
      isExtraContentDisplayed: true,
      isRoot: true,
    });
    tabs.push({
      id: "5",
      name: t("adminPanel.sidemenu.platformStatistics"),
      path: ADMIN_PANEL.PLATFORM_STATISTICS,
      content: <PlatformStatistics />,
      isExtraContentDisplayed: true,
      isRoot: true,
    });
  }

  if (renderPanel())
    return (
      <div className={styles.layout}>
        <div className={styles.pageWrapper}>
          <Tabs tabs={tabs} routePrefix={ADMIN_PANEL_ROUTES_PREFIX} />
        </div>

        {/* <LeftSideMenu /> */}
        {/* <div className={styles.pageWrapper}>
          <Switch>
            <Route path="/portal/admin-panel/feedbacks">
              {ClaimsService.hasFeedbackReadClaim() && <FeedbackTable />}
            </Route>
            <Route path="/portal/admin-panel/users">
              {ClaimsService.hasUserReadClaim() && <Users />}
            </Route>
            <Route path="/portal/admin-panel" exact>
              {defaultPage()}
            </Route>
          </Switch>
        </div> */}
      </div>
    );

  return <Redirect to="/portal" />;
};

export default AdminPanel;
