import { ICommunityNotification } from "../../types/Notification";
import {
  ADD_COMMUNITY_NOTIFICATION,
  CLEAR_ALL_COMMUNITY_NOTIFICATIONS,
  REMOVE_COMMUNITY_NOTIFICATION,
  REMOVE_COMMUNITY_NOTIFICATIONS_RELATED_TO_COMMUNITY,
  UPDATE_COMMUNITY_NOTIFICATION,
  UPDATE_COMMUNITY_NOTIFICATIONS_AS_READ,
} from "./constants";

export const addCommunityNotificationAction = (
  communityNotification: ICommunityNotification
) => ({
  type: ADD_COMMUNITY_NOTIFICATION,
  payload: communityNotification,
});

export const clearAllCommunityNotificationsAction = () => ({
  type: CLEAR_ALL_COMMUNITY_NOTIFICATIONS,
  payload: [],
});

export const removeCommunityNotificationAction = (id: string) => ({
  type: REMOVE_COMMUNITY_NOTIFICATION,
  payload: {
    id,
  },
});

export const updateCommunityNotificationAction = (
  communityNotification: ICommunityNotification
) => ({
  type: UPDATE_COMMUNITY_NOTIFICATION,
  payload: communityNotification,
});

export const updateCommunityNotificationsAsReadAction = () => ({
  type: UPDATE_COMMUNITY_NOTIFICATIONS_AS_READ,
});

export const removeCommunityNotificationsRelatedToCommunityAction = (
  sendBirdId: string
) => ({
  type: REMOVE_COMMUNITY_NOTIFICATIONS_RELATED_TO_COMMUNITY,
  payload: { sendBirdId },
});

export interface IAddCommunityNotification {
  type: typeof ADD_COMMUNITY_NOTIFICATION;
  payload: ICommunityNotification;
}

interface IClearAllCommunityNotifications {
  type: typeof CLEAR_ALL_COMMUNITY_NOTIFICATIONS;
}

export interface IRemoveCommunityNotification {
  type: typeof REMOVE_COMMUNITY_NOTIFICATION;
  payload: { id: string };
}

export interface IUpdateCommunityNotification {
  type: typeof UPDATE_COMMUNITY_NOTIFICATION;
  payload: ICommunityNotification;
}

export interface IUpdateCommunityNotificationsAsRead {
  type: typeof UPDATE_COMMUNITY_NOTIFICATIONS_AS_READ;
}

export interface IRemoveCommunityNotificationsRelatedToCommunity {
  type: typeof REMOVE_COMMUNITY_NOTIFICATIONS_RELATED_TO_COMMUNITY;
  payload: { sendBirdId: string };
}

export type ActionTypes =
  | IAddCommunityNotification
  | IClearAllCommunityNotifications
  | IRemoveCommunityNotification
  | IUpdateCommunityNotification
  | IUpdateCommunityNotificationsAsRead
  | IRemoveCommunityNotificationsRelatedToCommunity;
