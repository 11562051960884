/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDateLocale } from "@customHooks/useDateLocale";
import GET_USERS from "@graphql/queries/users";
import BuildingProfile from "@modules/BuildingProfile/BuildingProfile";
import { ClaimsService } from "@services/ClaimsService";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { isObjectEmpty } from "@utils/helpers";
import { useSelector } from "react-redux";
import { IUserResponse } from "types/user";
import { loginRequest } from "../../authConfig";
import { OnboardingState } from "../../store/onboarding/types";
import { RootState } from "../../store/store";

interface IAuthProps {
  children: React.ReactNode;
}

interface IdTokenClaims {
  email?: string;
  preferred_username: string;
}

const AuthComponent = (props: IAuthProps) => {
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const [email, setEmail] = useState("");
  const [userRequestsFinished, setUserRequestsFinished] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  useDateLocale();

  localStorage.setItem("loggedInUser", "true");

  const onboarding = useSelector<RootState, OnboardingState>((state) => state.onboarding);

  const resolveStep = () => {
    if (!onboarding.acceptCondition?.isAcceptTerms) {
      return "/terms-and-conditions";
    } else if (!onboarding.acceptCondition?.isAcceptCode) {
      return "/code-of-conduct";
    } else if (isObjectEmpty(onboarding.personalDetails)) {
      return "/onboarding/personal-details";
    } else if (isObjectEmpty(onboarding.profileDetails)) {
      return "/onboarding/profile-details";
    } else if (!onboarding.interests.length) {
      return "/onboarding/interests";
    } else {
      return "/onboarding/locations";
    }
  };

  const [getUser] = useLazyQuery(GET_USERS, {
    onCompleted: ({ user }: IUserResponse) => {
      setUserRequestsFinished(true);
      if (!user || !user.isActive) {
        history.replace("/invite-only");
        return;
      }
      if (!user.isOnboarded) {
        const path = resolveStep();
        history.replace(path);
        return;
      }
      if (
        location.pathname === "/" ||
        location.pathname === "/events" ||
        (location.pathname.includes("/event") && !location.pathname.includes("/portal"))
      ) {
        history.replace("/portal/resources");
      }
    },
    onError: () => {
      setUserRequestsFinished(true);
      history.replace("/invite-only");
    },
  });

  useEffect(() => {
    ClaimsService.getUserClaims();

    RequestProfileData();
  }, []);

  useEffect(() => {
    getUser();
  }, [email]);

  function RequestProfileData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response: AuthenticationResult) => {
        const idTokenClaims = response.idTokenClaims as IdTokenClaims;

        if (!idTokenClaims.email) {
          const errorMessage = t("Email not defined in the token!");
          displayNotSuccessNotification(errorMessage, errorMessage);
          // TODO: Redirect to "Ooops, something went wrong page"
          return;
        }
        setEmail(idTokenClaims.email);
      })
      .catch(() => {
        instance.loginRedirect(loginRequest);
      });
  }

  return !userRequestsFinished ? <BuildingProfile /> : <>{props.children}</>;
};

export default AuthComponent;
