/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Pills from "@components/Pills/Pills";
import SearchInput from "@components/SearchInput/SearchInput";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import removeIcon from "@images/closeModal.svg";
import arrow from "@images/orderArrow.svg";
import BlackSearch from "@images/search_icon.svg";
import { getSortedArray } from "@utils/helpers";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DefaultOption } from "types/select";
import { Interest } from "../../store/onboarding/types";
import { RootState } from "../../store/store";
import styles from "./SelectInterestsModal.module.scss";

interface ISelectInterestsModalProps {
  open: boolean;
  closeModal: () => void;
  setSelectedInterests: (interests: Array<DefaultOption>) => void;
  selectedInterests: Array<DefaultOption>;
  currentUserInterests: Array<string>;
}

const SelectInterestsModal = ({
  open,
  closeModal,
  setSelectedInterests,
  selectedInterests,
  currentUserInterests,
}: ISelectInterestsModalProps) => {
  const { t } = useTranslation();
  const { options: allOptions } = useInterestHelper();
  const allInterestsSelector = useSelector<RootState, Array<Interest>>(
    (state) => state.onboarding.interests
  );
  const [options, setOptions] = useState<Array<DefaultOption>>(allInterestsSelector);
  const [chosenOptions, setChosenOptions] = useState<Array<DefaultOption>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchByAlphabet, setSearchByAlphabet] = useState<boolean>(false);

  const getDefaultInterests = () => {
    if (!allOptions.length) return;

    setOptions(allOptions);
    const optionsCurrentUser = allOptions.filter(({ value }) =>
      currentUserInterests.includes(value)
    );
    setSelectedInterests(optionsCurrentUser);
    setChosenOptions(optionsCurrentUser);
  };

  useEffect(() => {
    getDefaultInterests();
  }, [allOptions, currentUserInterests]);

  const getSortedByAlphabetCategories = () => {
    const sortedOptions = getSortedArray(options, "label", true);
    setOptions(sortedOptions);
  };

  const handleSearchChange = ({
    target: { value: searchQuery },
  }: ChangeEvent<HTMLInputElement>) => {
    setOptions(
      allOptions.filter(({ value }) =>
        value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setSearchText(searchQuery);
  };

  const handleOptionSelection = (option: DefaultOption) => {
    const isOptionFound = allOptions.find(({ value }) => value === option.value);
    if (!isOptionFound) return;

    const isPreviouslySelected = chosenOptions.some(
      ({ value }) => value === option.value
    );

    if (isPreviouslySelected) {
      return setChosenOptions(
        chosenOptions.filter(({ value }) => value !== option.value)
      );
    }

    setChosenOptions((options) => [...options, option]);
  };

  const handleShowResultsClick = () => {
    chosenOptions && setSelectedInterests(chosenOptions);
    setSearchText("");
    setOptions(allOptions);
    closeModal();
  };

  const handleCancelSelectionClick = () => {
    setChosenOptions(selectedInterests);
    setSearchText("");
    setOptions(allOptions);
    closeModal();
  };

  const handleAlphabetSearchClick = () => {
    setSearchByAlphabet((prevMostSearched) => {
      const newMostSearched = !prevMostSearched;
      newMostSearched ? getSortedByAlphabetCategories() : getDefaultInterests();
      return newMostSearched;
    });
  };

  return (
    <Modal isOpen={open} className={styles.modalContainer}>
      <div className={styles.heading}>
        <span className={styles.title}>{t("interestsModal.title")}</span>
        <img
          src={removeIcon}
          className={styles.removeIcon}
          onClick={() => {
            setSearchByAlphabet(false);
            handleCancelSelectionClick();
          }}
          alt=""
        />
      </div>
      <div className={styles.searchHeading}>
        <div className={styles.orderHeading}>
          <span>{t("interestsModal.subtitle")}</span>
          <img src={arrow} alt="" className={styles.imageArrow} />
          <Button
            aria-label="order-btn"
            title={t("interestsModal.alphabetical")}
            wrapperClassNames={
              searchByAlphabet ? styles.mostSearched : styles.randomSearched
            }
            buttonType="link"
            onClick={handleAlphabetSearchClick}
            buttonSize="large"
          />
        </div>

        <div className={styles.selectContainer}>
          <SearchInput
            value={searchText}
            onChange={handleSearchChange}
            placeholder=""
            name="search-interests"
            className={styles.searchInterests}
            searchIcon={<img src={BlackSearch} alt="" />}
          />
        </div>
      </div>
      <div className={styles.interestsContainer}>
        <Pills
          ariaLabel="interests"
          onPillClick={handleOptionSelection}
          pills={options}
          selectedPills={chosenOptions}
          alternativeColor
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          aria-label="cancel-btn"
          title={t("interestsModal.cancelButton")}
          buttonSize="large"
          buttonType="link"
          onClick={handleCancelSelectionClick}
        />
        <Button
          aria-label="results-btn"
          className={styles.showResultButton}
          title={t("interestsModal.resultButton")}
          buttonType="primary"
          buttonSize="large"
          disabled={!chosenOptions.length}
          onClick={handleShowResultsClick}
        />
      </div>
    </Modal>
  );
};

export default SelectInterestsModal;
