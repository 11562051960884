import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import { useHistory } from "react-router";
import styles from "./PendingInvitationsCard.module.scss";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
// import { useCollectionNotificationsHelper } from "@utils/collectionNotifications/useCollectionNotificationsHelper";
import { useCommunityNotificationsHelper } from "@utils/useCommunityNotificationsHelper";

// types
import { IUser } from "types/user";

// queries
import GET_USER_INFO from "@graphql/queries/getUserInfo";

// mutations
import ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION from "@graphql/mutations/acceptOrDeclineCollectionCollaborationInvitation";
import ACCEPT_OR_DECLINE_COMMUNITY_INVITATION from "@graphql/mutations/acceptOrDeclineInvitation";

// icons
import CheckMark from "@images/checkMark.svg";
import CloseMark from "@images/closeMark.svg";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { getCustomNotificationByCode } from "@services/NotificationService/NotificationHelper";

interface IPendingInvitationsCard {
  imageType: string;
  inviterName: string;
  inviterMail: string;
  text: string;
  additionalText?: string;
  resourceType: string;
  resourceName: string;
  resourceId: string;
  triggerListUpdate?: () => void;
  setUser?: (user: IUser) => void;
  openUserDrawer?: () => void;
}

const PendingInvitationsCard = ({
  imageType,
  inviterName,
  inviterMail,
  text,
  additionalText,
  resourceType,
  resourceName,
  resourceId,
  triggerListUpdate,
  setUser,
  openUserDrawer,
}: IPendingInvitationsCard) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isAnswerInProgress, setIsAnswerInProgress] = useState<boolean>(false);

  // user drawer
  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    onCompleted: (data) => {
      setUser && setUser(data.userInfo);
      openUserDrawer && openUserDrawer();
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  // community handle
  const [errorCode, setErrorCode] = useState<string>("");
  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);

  const { removeCommunityNotificationsRelatedToCommunity } =
    useCommunityNotificationsHelper();

  // const { removeCollectionNotificationsRelatedToCollection } =
  //   useCollectionNotificationsHelper();

  const closeCommunityAccessModal = () => {
    removeCommunityNotificationsRelatedToCommunity(resourceId);
    setIsUserNotAllowedModalOpened(false);
    triggerListUpdate?.();
  };

  // community request
  const [handleJoinCommunityRequest] = useMutation(
    ACCEPT_OR_DECLINE_COMMUNITY_INVITATION,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        removeCommunityNotificationsRelatedToCommunity(resourceId);
        triggerListUpdate?.();
        setIsAnswerInProgress(false);
      },
      // TO DO : expend Apollo Error for custom Errors
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        const msg = getCustomNotificationByCode(errorCode);
        setErrorCode(msg);
        setIsUserNotAllowedModalOpened(true);
      },
    }
  );

  // collection request
  const [joinCollectionRequest] = useMutation(
    ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        // removeCollectionNotificationsRelatedToCollection(resourceId);
        triggerListUpdate?.();
        setIsAnswerInProgress(false);
      },
      onError: () => {
        displayServerError();
      },
    }
  );

  const resolveStatusRequest = (isAcceptedInvitation: boolean) => {
    setIsAnswerInProgress(true);
    if (resourceType === "community") {
      handleJoinCommunityRequest({
        variables: {
          invitationResponse: {
            sendbirdId: resourceId,
            inviterEmail: inviterMail,
            isAccepted: isAcceptedInvitation,
          },
        },
      });
    } else if (resourceType === "collection") {
      joinCollectionRequest({
        variables: {
          invitationResponse: {
            collectionPublicId: resourceId,
            isAccepted: isAcceptedInvitation,
            inviterEmail: inviterMail,
          },
        },
      });
    }
  };

  const goToResource = (resourceId: string) => {
    if (resourceType === "community") {
      history.push(`/portal/community/community-page/${resourceId}`);
    } else if (resourceType === "collection") {
      history.push(`/portal/collection/${resourceId}`);
    }
  };

  const renderButtonTitle = (title: string, url: string) => {
    return (
      <>
        <img src={url} className={styles.iconBeforeTitle} />
        <span>{title}</span>
      </>
    );
  };

  return (
    <div className={styles.pendingInvitationsCard}>
      <img src={imageType} alt="" />
      <div className={styles.pendingInvitationsText}>
        <span
          onClick={() => {
            getUserInfo({
              variables: {
                email: inviterMail,
              },
            });
          }}
          className={styles.pendingInvitationsLink}
        >
          {inviterName}
        </span>
        <span>{text}</span>
        <span
          onClick={() => {
            goToResource(resourceId);
          }}
          className={styles.pendingInvitationsLinkResource}
        >
          {resourceName}
        </span>
        <span>{additionalText}</span>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            resolveStatusRequest(true);
          }}
          disabled={isAnswerInProgress}
          title={renderButtonTitle(t("requests.requestCard.accept"), CheckMark)}
          className={styles.buttonAccept}
        />
        <Button
          onClick={() => {
            resolveStatusRequest(false);
          }}
          disabled={isAnswerInProgress}
          className={styles.buttonDecline}
          title={renderButtonTitle(t("requests.requestCard.decline"), CloseMark)}
        />
        {/* if user not allowed in community */}
        <ConfirmationModal
          isOpen={isUserNotAllowedModalOpened}
          messageTitle={t("community.communityAccess.title")}
          messageContent={errorCode}
          closeModal={() => closeCommunityAccessModal()}
          goBack={() => closeCommunityAccessModal()}
        />
      </div>
    </div>
  );
};

export default PendingInvitationsCard;
