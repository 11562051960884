/* eslint-disable prettier/prettier */
import { useLazyQuery } from "@apollo/client";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import Menu from "@components/Menu/Menu";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { STORAGE_IMAGE_TYPE_COLLECTION } from "@constants/constants";
import { truncateStringIfTooLong } from "@utils/helpers";

import Tag from "@components/Tag/Tag";
import { CollectionType, IPartialUser } from "@graphql/collection/type";
import { ReactComponent as PrivateChip } from "@images/Chip.svg";
import CuratedIcon from "@images/curated.svg";
import LinkIcon from "@images/linkIcon.svg";
import Verified from "@images/verified.svg";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import getStorageImage from "@utils/getStorageImage";

// for user drawer
import GET_USER_INFO from "@graphql/queries/getUserInfo";
import { IUser } from "types/user";
import { IOption } from "../Menu/types";

import { TextTruncated } from "@components/utils/TextTruncated/TextTruncated";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { defaultImages } from "../../modules/NewCommunity/constants";
import styles from "./CollectionsCard.module.scss";

export interface CollectionCardProps {
  title: string;
  description: string | null;
  collaboratorsCount: number;
  resourcesCount: number;
  customStyles?: string;
  imageName?: string;
  imageDirectory?: string;
  updateTime: number;
  publicId: string;
  collectionCreator: IPartialUser;
  menuOptions?: IOption[];
  isPublic?: boolean;
  isGlobalCurated?: boolean;
  userRole?: string;
  inUserDrawer?: boolean;
  collectionType?: CollectionType;
  isLinked?: boolean;
  isDefault?: boolean;
}

const CollectionsCard = ({
  title,
  description,
  resourcesCount,
  collaboratorsCount,
  publicId,
  customStyles,
  updateTime,
  collectionCreator,
  imageName,
  imageDirectory,
  menuOptions,
  isPublic,
  isGlobalCurated,
  userRole,
  inUserDrawer,
  collectionType,
  isLinked,
  isDefault,
}: CollectionCardProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState<boolean>(false);

  const [user, setUser] = useState<IUser>();

  const TITLE_LENGTH_ONE_LINE = 22;
  const DESRIPTION_LENGTH_TWO_LINES = 75;
  const DESRIPTION_LENGTH_THREE_LINES = 120;

  // owner counts as one collaborator
  const totalColaborators = collaboratorsCount + 1;

  const trimedDescription = description
    ? title.length > TITLE_LENGTH_ONE_LINE
      ? truncateStringIfTooLong(description, DESRIPTION_LENGTH_TWO_LINES)
      : truncateStringIfTooLong(description, DESRIPTION_LENGTH_THREE_LINES)
    : description;

  const openCollection = () => {
    const nextLocation = {
      pathname: `/portal/collection/${publicId}`,
      state: { redirect: location.pathname, prevPath: location.pathname },
    };
    history.push(nextLocation);
  };

  const handleUserSelected = (event: MouseEvent) => {
    event.stopPropagation();
    const creatorEmail = collectionCreator.email;
    getUserInfo({
      variables: { email: creatorEmail },
    });
    setIsUserDrawerOpen(true);
  };

  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUser && setUser(data.userInfo);
      setIsUserDrawerOpen(true);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const renderBackgroundImage = (): string => {
    if (imageName?.includes("DefaultCommunityImage")) {
      const loadedImageForBanner = defaultImages.find(
        (image) => image.name === imageName
      );
      return `url(${loadedImageForBanner?.image})`;
    } else {
      return `url(${getStorageImage({
        image: imageName,
        directoryName: imageDirectory,
        type: STORAGE_IMAGE_TYPE_COLLECTION,
      })})`;
    }
  };

  return (
    <div
      className={`${styles.outer} ${customStyles} ${
        inUserDrawer ? styles.inUserDrawer : ""
      }`}
      data-testid="collection-card"
      e2e-test-id="collection-card"
    >
      {/* top card */}
      <div
        style={{
          backgroundImage: renderBackgroundImage(),
        }}
        className={styles.mainImage}
        e2e-test-id="collection-card-image-container"
        onClick={openCollection}
      >
        <Menu options={menuOptions} />
      </div>
      {/* bottom card */}
      <div className={styles.textInner} onClick={openCollection}>
        {/* header */}
        {(isGlobalCurated || userRole || isLinked) && (
          <div className={styles.tagWrapper} e2e-test-id="collection-card-tags-section">
            {isLinked && (
              <span
                className={styles.linkedCollectionTag}
                e2e-test-id="linked-collection-tag"
              >
                <Tag
                  value={t("collections.card.linkedCollection")}
                  icon={LinkIcon}
                  color="#5B4C16"
                  backgroundColor="#F3CB3A"
                />
              </span>
            )}
            {isGlobalCurated && (
              <TooltipComponent
                tooltipType="secondary"
                title={t("collections.card.globalCuratedTooltip")}
                childToWrap={
                  <span
                    className={styles.verifiedTag}
                    e2e-test-id="global-curated-collection-tag"
                  >
                    <Tag
                      value={t("collections.card.globalCurated")}
                      icon={Verified}
                      color="#FFFFFF"
                      backgroundColor="#567AE8"
                    />
                  </span>
                }
              />
            )}
            {userRole && (
              <span className={styles.tagContainer} e2e-test-id="collection-role">
                <Tag value={userRole} color="#697377" backgroundColor="#DDE3E6" />
              </span>
            )}
          </div>
        )}

        {/* content */}
        <div className={styles.textHeightInner}>
          <TextTruncated
            Tag="h2"
            className={styles.title}
            text={title}
            e2eTestId="collection-name"
          />
          <p className={styles.paragraph} e2e-test-id="collection-description">
            {trimedDescription}
          </p>
        </div>
        {/* footer */}
        <div className={styles.cardFooter}>
          <div className={styles.resources}>
            {/* <span>
              {resourcesCount === 1
                ? t("collections.singleArticle", {
                    count: resourcesCount,
                  })
                : t("collections.multipleArticles", {
                    count: resourcesCount,
                  })}
            </span> */}
            {/* <span className={styles.dot}>•</span> */}
            <span e2e-test-id="number-of-collaborators">
              {totalColaborators === 1
                ? t("collections.singleCollaborator", {
                    count: totalColaborators,
                  })
                : t("collections.multipleCollaborators", {
                    count: totalColaborators,
                  })}
            </span>
            {!isPublic && (
              <TooltipComponent
                tooltipType="secondary"
                title={t("collections.card.privateCollection")}
                childToWrap={
                  <span className={styles.privateChip}>
                    <PrivateChip />
                  </span>
                }
              />
            )}
          </div>
          <div className={styles.createdBy} e2e-test-id="collection-created-by-section">
            {t("collections.card.createdBy")}
            <span onClick={handleUserSelected} className={styles.createdByUser}>
              {isGlobalCurated && (
                <img src={CuratedIcon} className={styles.curatedIcon} />
              )}
              <span e2e-test-id="collection-creator-name">
                {collectionCreator?.fullName}
              </span>
            </span>
          </div>
        </div>
      </div>
      {user && isUserDrawerOpen && (
        <UserDrawer
          user={user}
          closeInfoDrawer={() => setIsUserDrawerOpen(false)}
          communityOptions={<></>}
        />
      )}
    </div>
  );
};

export default CollectionsCard;
