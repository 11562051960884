import { useState } from "react";

import { useQuery } from "@apollo/client";

import { GET_COUNTRIES } from "../../../../modules/AccountSettings/SettingsProfileDetails/queries";

import { DefaultOption } from "types/select";

import { sortByString } from "@utils/helpers";

import { filterOutDuplicatedProperty } from "@utils/array/helper";

import { EMPTY_COUNTRY_CODE_OPTION } from "../constant";
import { ICountry, IGetCountriesResponse } from "../type";

export const useGetCountries = () => {
  const [countryOptions, setCountryOptions] = useState<Array<DefaultOption>>([]);
  const [callingCodeOptions, setCallingCodeOptions] = useState<Array<DefaultOption>>([]);

  const getCallingCodeOptions = (countries: Array<ICountry>) => {
    return filterOutDuplicatedProperty(countries, "callingCode")
      .map(getPhoneOption)
      .sort(sortByString(({ label }) => label, true));

    function getPhoneOption({ callingCode }: ICountry) {
      return {
        value: `+${callingCode}`,
        label: callingCode,
      };
    }
  };

  const getCountryOptions = (countries: Array<ICountry>) => {
    return countries.map(({ name }) => ({
      value: name,
      label: name,
    }));
  };

  const { data: countriesData } = useQuery<IGetCountriesResponse>(GET_COUNTRIES, {
    onCompleted: ({ countries }) => {
      setCallingCodeOptions([
        EMPTY_COUNTRY_CODE_OPTION,
        ...getCallingCodeOptions(countries),
      ]);
      setCountryOptions(getCountryOptions(countries));
    },
  });

  const getPhoneData = (telephone: string) => {
    let phoneData = { countryCode: "", telephone };

    const callingCodeOptionFound = callingCodeOptions
      .filter(({ value }) => value !== "")
      .find(({ value }) => telephone.startsWith(value));

    if (callingCodeOptionFound) {
      phoneData = {
        countryCode: callingCodeOptionFound.value,
        telephone: telephone.replace(callingCodeOptionFound.value, ""),
      };
    }

    return phoneData;
  };

  return { countriesData, countryOptions, callingCodeOptions, getPhoneData };
};
