import { combineReducers } from "redux";
import articleReducer from "./article/reducer";
import breadcrumbsReducer from "./breadcrumbs/reducer";
import chatPageReducer from "./chatPage/reducer";
import communityReducer from "./community/reducer";
import defaultCollectionReducer from "./communityDefaultCollection/reducer";
import communityNotificationsReducer from "./communityNotification/reducer";
import searchTermReducer from "./events/reducer";
import newFoldersReducer from "./newFolders/reducer";
import onboardingReducer from "./onboarding/reducer";
import portalReducer from "./portal/reducer";
import searchQueryReducer from "./search/reducer";
import userReducer from "./user/reducer";
import notificationReducer from "./notifications/reducer";

export default combineReducers({
  onboarding: onboardingReducer,
  portal: portalReducer,
  chatPage: chatPageReducer,
  community: communityReducer,
  communityNotifications: communityNotificationsReducer,
  searchQuery: searchQueryReducer,
  user: userReducer,
  searchTerm: searchTermReducer,
  article: articleReducer,
  newFolders: newFoldersReducer,
  breadcrumbs: breadcrumbsReducer,
  notification: notificationReducer,
  defaultCollection: defaultCollectionReducer,
});
