import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import UserCard from "@components/UserCard/UserCard";
import { useTranslation } from "react-i18next";
import { IUserDashboard } from "types/user";
import styles from "./UsersGrid.module.scss";

interface IUsersGrid {
  users: IUserDashboard[];
  loadMore: () => void;
  usersLoading: boolean;
  usersLoadingMore: boolean;
  usersCount: number;
  searchValue: string;
  gridAdditionalStyles?: string;
}

const USERS = "users";

const UsersGrid = ({
  users,
  loadMore,
  usersLoading,
  usersLoadingMore,
  usersCount,
  searchValue,
  gridAdditionalStyles,
}: IUsersGrid) => {
  const { t } = useTranslation();

  return (
    <div
      aria-label="users-grid"
      className={`${styles.usersGridContainer} ${
        gridAdditionalStyles ? gridAdditionalStyles : ""
      }`}
    >
      {searchValue && (
        <h3 aria-label="search-header" className={styles.sectionTitle}>{`${t(
          "resources.usersTitle"
        )} ${t("resources.relatedTo")} ${searchValue}`}</h3>
      )}
      {usersLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading borderless disableBoxShadow />
        </div>
      ) : (
        <div className={styles.usersGridWrapper}>
          {users.map((user) => (
            <UserCard
              key={user.publicId}
              email={user.email}
              fullName={user.fullName}
              bio={user.plainTextBio}
              organization={user.organization}
              country={user.country}
              userPhotoName={user.userPhotoName}
              imageDirectory={user.imageDirectory}
            />
          ))}
        </div>
      )}
      {usersCount > users.length && (
        <Button
          aria-label="loadMoreBtn"
          title={t("resources.viewMore", {
            resources: `${USERS}`,
          })}
          wrapperClassNames={styles.loadMoreButton}
          onClick={loadMore}
          loading={usersLoadingMore}
          disabled={usersLoadingMore}
        />
      )}
    </div>
  );
};

export default UsersGrid;
