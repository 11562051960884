import { Subscription } from "./enum";
import { IValue } from "./type";

export const subscriptionSubscribers = new Map<Subscription, Array<IValue<unknown>>>();
subscriptionSubscribers.set(Subscription.JOIN_PRIVATE_COMMUNITY_REQUESTED, []);
subscriptionSubscribers.set(Subscription.CANCEL_JOIN_PRIVATE_COMMUNITY_REQUEST, []);
subscriptionSubscribers.set(Subscription.JOIN_PRIVATE_COMMUNITY_DECLINED, []);
subscriptionSubscribers.set(Subscription.JOIN_PRIVATE_COMMUNITY_ACCEPTED, []);
subscriptionSubscribers.set(Subscription.COMMUNITY_CREATED, []);
subscriptionSubscribers.set(Subscription.JOINED_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.ADDED_TO_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.ADDED_TO_EXISTING_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.REMOVED_FROM_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.BANNED_FROM_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.MEMBER_IN_COMMUNITY_PROMOTED, []);
subscriptionSubscribers.set(Subscription.ADMIN_IN_COMMUNITY_DEMOTED, []);
subscriptionSubscribers.set(Subscription.UPDATED_COMMUNITY_CHANNELS, []);
subscriptionSubscribers.set(Subscription.COMMUNITY_UPDATED, []);
subscriptionSubscribers.set(Subscription.MEMBER_INVITED_TO_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.ADMIN_INVITED_TO_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.USER_ACCEPTED_COMMUNITY_JOIN_INVITATION, []);
subscriptionSubscribers.set(Subscription.USER_DECLINED_COMMUNITY_JOIN_INVITATION, []);
subscriptionSubscribers.set(Subscription.DELETED_COMMUNITY, []);
subscriptionSubscribers.set(Subscription.EVENT_RECORDINGS_SAVED, []);
subscriptionSubscribers.set(Subscription.EVENT_START_REMINDER, []);
subscriptionSubscribers.set(Subscription.DELETED_COLLECTION, []);
subscriptionSubscribers.set(Subscription.DELETED_ARTICLE_FROM_COLLECTION, []);
subscriptionSubscribers.set(Subscription.EVENT_UPDATED, []);
subscriptionSubscribers.set(Subscription.DELETED_EVENT, []);
// Accept & Decline Collection
subscriptionSubscribers.set(Subscription.USER_INVITED_AS_COLLECTION_COLLABORATOR, []);
subscriptionSubscribers.set(
  Subscription.USER_ACCEPTED_INVITATION_TO_BE_COLLECTION_COLLABORATOR,
  []
);
subscriptionSubscribers.set(
  Subscription.USER_DECLINED_INVITATION_TO_BE_COLLECTION_COLLABORATOR,
  []
);
subscriptionSubscribers.set(Subscription.CONTRIBUTOR_REMOVED_FROM_COLLECTION, []);
subscriptionSubscribers.set(
  Subscription.USER_ADDED_AS_COMMUNITY_COLLECTION_COLLABORATOR,
  []
);
// New Notification
subscriptionSubscribers.set(Subscription.NEW_NOTIFICATION, []);
subscriptionSubscribers.set(Subscription.FEEDBACK_ADDED, []);
