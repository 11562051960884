import { ICommunityNotification } from "../../types/Notification";
import { RootState } from "../store";
import {
  ActionTypes,
  IAddCommunityNotification,
  IRemoveCommunityNotification,
  IRemoveCommunityNotificationsRelatedToCommunity,
  IUpdateCommunityNotification,
} from "./actions";
import {
  ADD_COMMUNITY_NOTIFICATION,
  CLEAR_ALL_COMMUNITY_NOTIFICATIONS,
  REMOVE_COMMUNITY_NOTIFICATION,
  REMOVE_COMMUNITY_NOTIFICATIONS_RELATED_TO_COMMUNITY,
  UPDATE_COMMUNITY_NOTIFICATION,
  UPDATE_COMMUNITY_NOTIFICATIONS_AS_READ,
} from "./constants";
import { ICommunityNotificationState } from "./types";

const initialState: ICommunityNotificationState = {
  communityNotifications: [],
};

const addNotification = (
  { communityNotifications }: ICommunityNotificationState,
  { payload }: IAddCommunityNotification
) => [...communityNotifications, payload];

const removeNotification = (
  { communityNotifications }: ICommunityNotificationState,
  { payload: { id } }: IRemoveCommunityNotification
) => communityNotifications.filter((notification) => notification.id !== id);

const removeNotificationsRelatedToCommunity = (
  { communityNotifications }: ICommunityNotificationState,
  { payload: { sendBirdId } }: IRemoveCommunityNotificationsRelatedToCommunity
) =>
  communityNotifications.filter((notification) => notification.sendBirdId !== sendBirdId);

const updateNotification = (
  { communityNotifications }: ICommunityNotificationState,
  { payload }: IUpdateCommunityNotification
) => {
  const notificationIndex = communityNotifications.findIndex(
    (notification) => notification.id === payload.id
  );

  const updatedNotification = {
    ...communityNotifications[notificationIndex],
    ...payload,
  };

  const updatedNotifications = [...communityNotifications];
  updatedNotifications[notificationIndex] = updatedNotification;

  return updatedNotifications;
};

const updateNotificationsAsRead = ({
  communityNotifications,
}: ICommunityNotificationState) =>
  communityNotifications.map((notification) => ({
    ...notification,
    isRead: true,
  }));

const communityNotificationsReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case ADD_COMMUNITY_NOTIFICATION:
      return {
        ...state,
        communityNotifications: addNotification(state, action),
      };
    case CLEAR_ALL_COMMUNITY_NOTIFICATIONS:
      return {
        ...state,
        communityNotifications: [],
      };
    case REMOVE_COMMUNITY_NOTIFICATION:
      return {
        ...state,
        communityNotifications: removeNotification(state, action),
      };
    case UPDATE_COMMUNITY_NOTIFICATION:
      return {
        ...state,
        communityNotifications: updateNotification(state, action),
      };
    case UPDATE_COMMUNITY_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        communityNotifications: updateNotificationsAsRead(state),
      };
    case REMOVE_COMMUNITY_NOTIFICATIONS_RELATED_TO_COMMUNITY:
      return {
        ...state,
        communityNotifications: removeNotificationsRelatedToCommunity(state, action),
      };
    default:
      return state;
  }
};

export const communityNotificationsSelector: (
  state: RootState
) => ICommunityNotification[] = (state: RootState) => {
  return state.communityNotifications.communityNotifications;
};

export const numberOfUnreadCommunityNotificationsSelector = (state: RootState) =>
  state.communityNotifications.communityNotifications.filter(({ isRead }) => !isRead)
    .length;

export default communityNotificationsReducer;
