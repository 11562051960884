import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { useTranslation } from "react-i18next";

export enum ValidationOption {
  REQUIRED = "required",
}

const useCheckValidation = () => {
  const { t } = useTranslation();

  const checkValidation = (
    requirements: ValidationOption[],
    inputValue: string
  ): [boolean, string[]] => {
    let isInputValid = false;
    const errorMessages: string[] = [];

    if (requirements.includes(ValidationOption.REQUIRED)) {
      if (!inputValue.length) {
        errorMessages.push(t("input.error.required"));
        displayNotSuccessNotification(t("input.error.required"));
        return [isInputValid, errorMessages];
      }
    }

    isInputValid = errorMessages.length > 0 ? false : true;

    return [isInputValid, errorMessages];
  };

  return checkValidation;
};

export default useCheckValidation;
