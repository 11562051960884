import { useRef, useState } from "react";

import { LazyQueryExecFunction } from "@apollo/client";

import { debounce } from "lodash";

import { SEARCH_TRIGGER } from "@constants/constants";

import { IUser } from "types/user";

import { DELAY_MILLISECONDS } from "./constant";

interface IUseHandleSearch<Y, Z> {
  queryFn: LazyQueryExecFunction<Y, Z>;
}

export const useHandleSearch = <Y, Z>({ queryFn }: IUseHandleSearch<Y, Z>) => {
  const abortController = useRef<AbortController>();
  const [search, setSearch] = useState("");
  const [searchedUsers, setSearchedUsers] = useState<Array<IUser>>([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useRef(
    debounce((variables: Z, isTriggered: boolean) => {
      if (!isTriggered) return;

      const controller = new window.AbortController();
      abortController.current = controller;

      queryFn({
        variables,
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    }, DELAY_MILLISECONDS)
  );

  const abortLatest = () => {
    abortController.current && abortController.current.abort();
  };

  const handleSearch = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    variables: Z
  ) => {
    abortLatest();
    debouncedCall.current(variables, value.length > SEARCH_TRIGGER);

    if (value.length <= SEARCH_TRIGGER) {
      setSearchedUsers([]);
    }

    setSearch(value);
  };

  return {
    handleSearch,
    search,
    searchedUsers,
    setSearchedUsers,
    setSearch,
    abortLatest,
  };
};
