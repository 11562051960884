import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import { useInterestHelper } from "@customHooks/useInterestHelper";
import GET_COMMUNITY_BY_NAME from "@graphql/queries/communityByName";
import SearchIcon from "@images/search_icon.svg";
import ReactQuill from "react-quill";
import { CommunityType, ICommunitiesResponse } from "types/community";
import { DefaultOption, Option } from "types/select";
import { IUser, IUserImageResponse } from "types/user";
import { Subscription } from "../../customContext/subscriptions/enum";
import { defaultImages } from "./constants";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import ImagePickerWithModal from "@components/ImagePickerWithModal/ImagePickerWithModal";
import { useSubscription } from "@customHooks/useSubscription";
import CREATE_COMMUNITY from "@graphql/mutations/createCommunity";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import FileCheckerService from "@utils/files/fileChecker";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import getStorageImage, { getStorageImageUrl } from "@utils/getStorageImage";
import { fileNameReplaceRegex } from "@utils/helpers";

import { IImage } from "types/image";

import Avatar from "@images/avatar.svg";
import CloseMark from "@images/close_toast_black.svg";
import ClosedCommunityIcon from "@images/closedCommunityIcon.svg";
import PrivateCommunityIcon from "@images/privateCommunityIcon.svg";

import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import { GET_SEARCHED_USERS_FOR_INVITE } from "@graphql/queries/usersSearch";
import "react-quill/dist/quill.snow.css";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import formStyles from "@components/formik/form.style.module.scss";
import { useHandleSearch } from "@customHooks/useHandleSearch/useHandleSearch";
import { IPagination, ISearchUserResponse } from "@feature/pagination/type";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import styles from "./NewCommunity.module.scss";

const MAX_INPUT_LENGTH = 1000;

const NewCommunity = () => {
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const { t } = useTranslation();
  const history = useHistory();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const { options: interestOptions } = useInterestHelper();

  const communityTypes = [
    {
      id: 1,
      name: CommunityType.open,
      label: t("community.newCommunity.communityTypes.open.label"),
      subLabel: t("community.newCommunity.communityTypes.open.text"),
    },
    {
      id: 2,
      name: CommunityType.closed,
      label: (
        <span className={styles.communityTypeIconsContainer}>
          {t("community.newCommunity.communityTypes.closed.label")}
          <img className={styles.zoran} src={ClosedCommunityIcon} alt="Locked" />
        </span>
      ),
      subLabel: t("community.newCommunity.communityTypes.closed.text"),
    },
    {
      id: 3,
      name: CommunityType.private,
      label: (
        <span className={styles.communityTypeIconsContainer}>
          {t("community.newCommunity.communityTypes.private.label")}
          <img src={PrivateCommunityIcon} alt="Hidden" />
        </span>
      ),
      subLabel: t("community.newCommunity.communityTypes.private.text"),
    },
  ];

  const roles = [
    {
      value: "1",
      label: t("community.newCommunity.select.members.memberSameForAllLang"),
    },
    {
      value: "2",
      label: t("community.newCommunity.select.members.adminSameForAllLang"),
    },
  ];

  const [title, setTitle] = useState<string>("");
  const [goals, setGoals] = useState<string>("");
  const [rules, setRules] = useState<string>("");
  const [selectedCommunityType, setSelectedCommunityType] = useState<string>(
    communityTypes[0].name
  );
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedInterests, setSelectedInterests] = useState<Array<string>>([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
  const ADMIN = currentUserEmail;
  const [admins, setAdmins] = useState<Array<string>>([]);
  const [isCreatingCommunity, setIsCreatingCommunity] = useState(false);

  const { uploadFiles } = useUploadFiles();

  const [createCommunity] = useMutation(CREATE_COMMUNITY, {
    onCompleted: () => {
      displaySuccessNotification(t("community.created"));
      setIsCreatingCommunity(false);
      history.push("/portal/community");
    },
    onError: () => {
      setIsCreatingCommunity(false);
      displayNotSuccessNotification();
    },
  });

  const [users, setUsers] = useState<Array<IUser>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<IUser>>([]);
  const [shouldRenderSearchedUsersList, setShouldRenderSearchedUsersList] =
    useState<boolean>(true);

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();

  const [selectedUsersAvatars, setSelectedUsersAvatars] = useState<
    { email: string; image: string }[]
  >([]);

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          event.target instanceof HTMLDivElement &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          setShouldRenderSearchedUsersList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const [getUsers, { loading: isGettingUsers }] = useLazyQuery<
    ISearchUserResponse,
    IPagination
  >(GET_SEARCHED_USERS_FOR_INVITE, {
    onCompleted: (data) => {
      const withoutSelectedUsers = data.usersByFullName.users.filter(
        ({ email }) => !selectedUsers.some((e) => e.email === email)
      );
      setSearchedUsers(withoutSelectedUsers);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const { handleSearch, search, searchedUsers, setSearch, setSearchedUsers } =
    useHandleSearch<ISearchUserResponse, IPagination>({
      queryFn: getUsers,
    });

  const addUser = (user: IUser, index: number, userAvatar: string) => {
    const userIds = selectedUsers.map((user) => user.email);

    if (userIds.includes(user.email)) return;

    setSelectedUsers([user, ...selectedUsers]);
    setSearch("");
    setSearchedUsers([]);

    setSelectedUsersAvatars([
      ...selectedUsersAvatars,
      { email: user.email, image: userAvatar },
    ]);

    const filteredUsers = users;
    filteredUsers.splice(index, 1);

    setUsers(filteredUsers);
  };

  const removeUser = (user: IUser, index: number) => {
    setUsers([user, ...users]);

    const filteredUsers = selectedUsers;
    filteredUsers.splice(index, 1);

    setSelectedUsers(filteredUsers);
  };

  useEffect(() => {
    searchedUsers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: searchedUsers.map((member) => member.email),
          },
        },
      });
  }, [getUserImages, searchedUsers]);

  const [getCommunityByName] = useLazyQuery<ICommunitiesResponse>(GET_COMMUNITY_BY_NAME, {
    fetchPolicy: "no-cache",
    onCompleted: ({ communities: { communities } }) => {
      const isExistingCommunity = communities.some(
        (community) => community.name === title.trim()
      );
      if (isExistingCommunity) {
        setIsCreatingCommunity(false);
        const message = t("community.newCommunity.errors.alreadyExists");
        displayNotSuccessNotification(message, message);
        return;
      }

      createNewCommunity();
    },
    onError: (error) => {
      displayServerError(error);
      setIsCreatingCommunity(false);
    },
  });

  useSubscription(Subscription.COMMUNITY_CREATED, () => setIsCreatingCommunity(false));

  const addAdmin = (email: string) => {
    setAdmins([...admins, email]);
  };

  const removeFromAdmins = (email: string) => {
    const newArray = admins.filter((existEmail) => {
      return existEmail !== email;
    });
    setAdmins(newArray);
  };

  // community profile image
  const [selectedImage, setSelectedImage] = useState<IImage>({} as IImage);
  // community banner image
  const [selectedImageBanner, setSelectedImageBanner] = useState<IImage>({} as IImage);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeGoals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGoals(event.target.value);
  };

  const handleChangeRules = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRules(event.target.value);
  };

  const createCommunityChannel = () => {
    setIsCreatingCommunity(true);
    getCommunityByName({
      variables: {
        communitySearch: {
          text: title,
        },
      },
    });
  };

  const createNewCommunity = async () => {
    // community profile image upload
    let imageDirectory = "";
    let imageUrl = selectedImage.image;
    const imageName = fileNameReplaceRegex(selectedImage.name);
    // community banner image upload
    let communityBannerDirectory = "";
    let bannerImageUrl = selectedImageBanner.image;
    const bannerImageName = fileNameReplaceRegex(selectedImageBanner.name);

    if (selectedImage.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImage.img
      );

      const fileSave = await FileCheckerService.isSafe(selectedImage.img);
      if (!fileSave || !fileSize) {
        setIsCreatingCommunity(false);
        return;
      }

      try {
        await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            imageDirectory = directory;
            imageUrl = getStorageImageUrl(file.name, directory);
          },
        });
      } catch (error) {
        setIsCreatingCommunity(false);
        displayNotSuccessNotification();
      }
    }

    if (selectedImageBanner.img) {
      const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
        selectedImageBanner.img
      );

      const fileSave = await FileCheckerService.isSafe(selectedImageBanner.img);
      if (!fileSave || !fileSize) {
        setIsCreatingCommunity(false);
        return;
      }

      try {
        await uploadFiles(selectedImageBanner.img, BlobStorageContainer.IMAGES, {
          onFileUpload: (file, directory) => {
            communityBannerDirectory = directory;
            bannerImageUrl = getStorageImageUrl(file.name, directory);
          },
        });
      } catch (error) {
        setIsCreatingCommunity(false);
        displayNotSuccessNotification();
      }
    }

    const community = {
      name: title.trim(),
      communityImageUrl: imageUrl,
      coverImageName: imageName,
      imageDirectory,
      communityBannerUrl: bannerImageUrl,
      communityBannerName: bannerImageName,
      communityBannerDirectory,
      communityType: selectedCommunityType,
      description,
      goals,
      rules,
      adminEmails: admins,
      tags: selectedInterests,
      memberEmails: selectedUsers
        .map((member) => member.email)
        .filter((member) => !admins.includes(member)),
      isEphemeral: false,
      isDistinct: false,
      isSuper: true,
      customType: selectedCommunityType,
    };

    createCommunity({ variables: { createCommunity: community } });
  };

  useEffect(() => {
    const selectedMembersEmails = selectedUsers.map(({ email }) => email);
    //  eslint-disable-next-line
    admins.map((memberEmail) => {
      //  eslint-disable-next-line
      if (memberEmail === ADMIN) return;
      if (!selectedMembersEmails.includes(memberEmail)) {
        const filteredAdmins = admins.filter((email) => email !== memberEmail);
        setAdmins(filteredAdmins);
      }
    });
  }, [ADMIN, admins, selectedUsers]);

  const reactQuillRef = useRef<ReactQuill>(null);

  const validateInput = (description: string) => {
    const unprivilegedEditor = reactQuillRef.current?.unprivilegedEditor;
    const editor = reactQuillRef.current?.editor;
    editor?.on("text-change", () => {
      if (unprivilegedEditor !== undefined && unprivilegedEditor?.getLength() > 1000) {
        editor?.deleteText(1000, unprivilegedEditor.getLength());
      }
    });
    setDescription(description);
  };

  return (
    <div className={styles.pageWrapper}>
      <h2 className={styles.pageTitle} e2e-test-id="new-community-form-header">
        {t("community.newCommunity.title")}
      </h2>
      <span className={styles.pageSubTitle} e2e-test-id="community-form-subheader">
        {t("community.newCommunity.subTitle")}
      </span>
      <form className={styles.newCommunityForm}>
        <div className={formStyles.column}>
          <InputUpdated
            onChange={handleChange}
            name="communityName"
            maxLength={100}
            label={t("community.newCommunity.communityName.label")}
            aria-label="communityName"
            e2eTestId="community-name-input-field"
            placeholder={t("community.newCommunity.communityName.placeholder")}
            additionalElement={
              !descriptionField && (
                <ButtonUpdated
                  aria-label="addDescription"
                  e2eTestId="add-community-description-button"
                  title={t("community.newCommunity.buttons.addDescription")}
                  buttonIcon={<PlusOutlined />}
                  buttonSize="small"
                  onClick={() => setDescriptionField(true)}
                />
              )
            }
          />
          {descriptionField && (
            <ReactQuill
              theme="snow"
              onChange={(description: string) => validateInput(description)}
              ref={reactQuillRef}
              className="description"
              placeholder={t("community.newCommunity.textarea.label")}
              aria-label="description textarea"
              // #842 Remove current Description text
              value={description}
              defaultValue={description}
            />
          )}
        </div>

        <div className={styles.selectWrapper}>
          <SelectComponent
            options={interestOptions}
            placeholder={t("community.newCommunity.select.tags.placeholder")}
            className={"tagsSearch"}
            label={t("community.newCommunity.select.tags.label")}
            name={"tags"}
            e2eTestId="categories-select-container"
            showDropdown
            selectWithIcon
            icon={SearchIcon}
            isMulti
            onChange={(selectedInterestOptions: DefaultOption[]) =>
              setSelectedInterests(selectedInterestOptions.map(({ value }) => value))
            }
            isSearchable
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            onChange={handleChangeGoals}
            name="communityGoals"
            maxLength={MAX_INPUT_LENGTH}
            label={t("community.newCommunity.communityGoals.label")}
            aria-label="communityGoals"
            e2eTestId="community-goals-input-field"
            placeholder={t("community.newCommunity.communityGoals.placeholder")}
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            onChange={handleChangeRules}
            name="communityRules"
            maxLength={MAX_INPUT_LENGTH}
            label={t("community.newCommunity.communityRules.label")}
            aria-label="communityRules"
            e2eTestId="community-rules-input-field"
            placeholder={t("community.newCommunity.communityRules.placeholder")}
          />
        </div>
        <div e2e-test-id="community-profile-image-section">
          <ImagePickerWithModal
            title={t("community.newCommunity.radio.image.profileImageLabel")}
            description={t("community.newCommunity.radio.image.profileImageDescription")}
            images={[]}
            pickedImage={selectedImage}
            setPickedImage={setSelectedImage}
            isUpload
            isRequired
            uploadButtonId={"selectedImage"}
          />
        </div>
        <div e2e-test-id="community-banner-image-section">
          <ImagePickerWithModal
            title={t("community.newCommunity.radio.image.bannerLabel")}
            description={t("community.newCommunity.radio.image.bannerDescription")}
            images={defaultImages}
            pickedImage={selectedImageBanner}
            setPickedImage={setSelectedImageBanner}
            isUpload
            isRequired
            uploadButtonId={"selectedImageBanner"}
          />
        </div>

        <div
          onClick={() => setShouldRenderSearchedUsersList(true)}
          className={styles.selectWrapper}
        >
          <InputUpdated
            label={t("community.newCommunity.inviteMembersLabel")}
            placeholder={t("community.inviteModal.searchPlaceholder")}
            type="search"
            value={search}
            onChange={(event) => {
              handleSearch(event, {
                text: event.target.value,
                size: 1000,
                skip: 0,
              });
            }}
            loading={isGettingUsers}
            area="sm"
            e2eTestId="search-for-members-input"
          />
          {shouldRenderSearchedUsersList && searchedUsers.length > 0 && search && (
            <div ref={wrapperRef} className={styles.usersContainer}>
              {searchedUsers.map((user, index) => {
                const userAvatar = userImages
                  ? userImages.find((image) => image.email === user.email)?.image
                  : Avatar;

                return (
                  <div
                    onClick={() => addUser(user, index, userAvatar ? userAvatar : Avatar)}
                    className={styles.option}
                    key={user.publicId}
                    aria-label="user"
                    role="listitem"
                  >
                    <div
                      style={{ backgroundImage: `url(${userAvatar})` }}
                      className={styles.avatar}
                    ></div>
                    {user.fullName}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {selectedUsers.map((user, index) => {
          const userAvatar = userImages
            ? selectedUsersAvatars.find((image) => image.email === user.email)?.image
            : Avatar;
          return (
            <div key={index} className={styles.member}>
              <div className={styles.userDetails}>
                <div
                  style={{ backgroundImage: `url(${userAvatar})` }}
                  className={styles.avatar}
                ></div>
                <div>
                  <p className={styles.name}>{user.fullName}</p>
                  <p className={styles.role}>
                    {getRoleOrganizationText(user.organization)}
                  </p>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <SelectComponent
                  options={roles}
                  className="selectRole"
                  placeholder={roles[0].label}
                  showDropdown={true}
                  label=""
                  name=""
                  value={admins.includes(user.email) ? roles[1] : roles[0]}
                  onChange={(option: Option) => {
                    option.label === "Admin"
                      ? addAdmin(user.email)
                      : removeFromAdmins(user.email);
                  }}
                />
                <TooltipComponent
                  title={t("community.newCommunity.removeMemberTooltip")}
                  tooltipType="secondary"
                  placement="bottom"
                  childToWrap={
                    <img src={CloseMark} onClick={() => removeUser(user, index)} />
                  }
                />
              </div>
            </div>
          );
        })}
        <div className={styles.communityTypeWrapper} e2e-test-id="community-type-section">
          <div className={styles.communityTypeHeading}>
            {t("community.newCommunity.communityTypes.title")}
          </div>
          {communityTypes.map((communityType) => (
            <RadioButton
              aria-label="communityType"
              id={communityType.id}
              key={communityType.name}
              name={communityType.name}
              label={communityType.label}
              subLabel={communityType.subLabel}
              checked={communityType.name === selectedCommunityType}
              onChange={(event) => {
                setSelectedCommunityType(event.target.name);
              }}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <Button
            aria-label="createCommunityBtn"
            e2eTestId="create-community-button"
            buttonType="primary"
            wrapperClassNames={styles.buttonWrapper}
            title={t("community.newCommunity.buttons.createCommunity")}
            onClick={() => setIsConfirmModalOpened(true)}
            loading={isCreatingCommunity}
            disabled={
              !title.trim().length ||
              !selectedInterests.length ||
              isCreatingCommunity ||
              !selectedImage?.image ||
              !selectedImageBanner?.image
            }
            fullWidth
          />
          <Button
            aria-label="cancelBtn"
            e2eTestId="create-community-cancel-link"
            buttonType="link"
            title={t("community.newCommunity.buttons.cancel")}
            className={styles.cancelLink}
            onClick={() => history.push("/portal/community")}
          />
          <ConfirmationModal
            isOpen={isConfirmModalOpened}
            e2eTestId="new-community-modal-container"
            accept={() => {
              setIsConfirmModalOpened(false);
              createCommunityChannel();
            }}
            messageTitle={t("community.newCommunity.confirmModal.title")}
            messageContent=""
            closeModal={() => setIsConfirmModalOpened(false)}
            customButtonsText={{
              accept: t("community.newCommunity.confirmModal.button.accept"),
              cancel: t("community.newCommunity.confirmModal.button.cancel"),
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default NewCommunity;
