/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Tabs from "@components/Tabs/Tabs";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import { ICollection } from "@graphql/collection/type";
import Avatar from "@images/avatar.svg";
import CloseMark from "@images/close_toast_black.svg";
import Loader from "@images/spinner.svg";
import styles from "./InviteToCollectionModal.module.scss";

import CANCEL_COLLECTION_PENDING_INVITATION from "@graphql/mutations/cancelCollectionPendingInvitation";
import INVITE_MEMBERS_TO_COLLECTION from "@graphql/mutations/inviteMembersToCollection";
import GET_INVITABLE_USERS_TO_COLLECTION from "@graphql/queries/invitableUsersSearchToCollection";
import { PENDING_COLLECTION_COLLABORATION_INVITATIONS } from "@graphql/queries/pendingCollectionCollaborationInvitations";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";

import { ReactComponent as CloseModalIcon } from "@images/exit.svg";
import { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IInvitableUsersSearchRequestCollection,
  IInvitableUsersSearchResponseCollection,
  IUser,
  IUserImageResponse,
} from "types/user";

import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import TooltipComponent from "@components/Tooltip/Tooltip";
import { useHandleSearch } from "@customHooks/useHandleSearch/useHandleSearch";
import { useUserTranslationHelper } from "@feature/user/hooks/useUserTranslationHelper/useUserTranslationHelper";
import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import getStorageImage from "@utils/getStorageImage";

enum USER_STATUS {
  PENDING = "pending",
  INVITED = "invited",
}

interface IInviteToCollectionModal {
  collection: ICollection;
  open: boolean;
  close: () => void;
}

const InviteToCollectionModal = ({
  collection,
  open,
  close,
}: IInviteToCollectionModal) => {
  const { t } = useTranslation();
  const { getRoleOrganizationText } = useUserTranslationHelper();
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<IUser>>([]);
  const [pendingUsers, setPendingUsers] = useState<IUser[]>([]);
  const [selectedUsersAvatars, setSelectedUsersAvatars] = useState<
    { email: string; image: string }[]
  >([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [shouldRenderSearchedUsersList, setShouldRenderSearchedUsersList] =
    useState<boolean>(true);

  const [userCancelingInvitation, setUserCancelingInvitation] = useState<string>();

  const [inviteMembers] = useMutation(INVITE_MEMBERS_TO_COLLECTION, {
    onCompleted: () => {
      refetch();
    },
  });
  const [cancelPendingInvitation] = useMutation(CANCEL_COLLECTION_PENDING_INVITATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          event.target instanceof HTMLDivElement &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          setShouldRenderSearchedUsersList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [getUsers, { loading: isGettingUsers }] = useLazyQuery<
    IInvitableUsersSearchResponseCollection,
    IInvitableUsersSearchRequestCollection
  >(GET_INVITABLE_USERS_TO_COLLECTION, {
    onCompleted: (data) => {
      const invitableUsersWithSelectedUsers = data.invitableUsersToCollection
        .filter(({ email }) => !selectedUsers.some((e) => e.email === email))
        .filter((user) => user.organization);

      setSearchedUsers([...invitableUsersWithSelectedUsers]);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const { handleSearch, search, searchedUsers, setSearch, setSearchedUsers } =
    useHandleSearch<
      IInvitableUsersSearchResponseCollection,
      IInvitableUsersSearchRequestCollection
    >({
      queryFn: getUsers,
    });

  const [getPendingUsers, { refetch }] = useLazyQuery<{
    usersWithPendingCollectionCollaborationInvitations: IUser[];
  }>(PENDING_COLLECTION_COLLABORATION_INVITATIONS, {
    onCompleted: (data) => {
      setPendingUsers(data.usersWithPendingCollectionCollaborationInvitations);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>([]);
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages([
        ...userImages,
        ...userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        }),
      ]);
    },
  });

  useEffect(() => {
    searchedUsers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: searchedUsers.map((member) => member.email),
          },
        },
      });
  }, [searchedUsers]);

  useEffect(() => {
    pendingUsers.length > 0 &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: pendingUsers.map((member) => member.email),
          },
        },
      });
    setUserCancelingInvitation("");
  }, [pendingUsers]);

  useEffect(() => {
    getPendingUsers({
      variables: {
        collectionPublicId: collection.publicId,
      },
    });
  }, [open]);

  const closeModal = () => {
    setSearch("");
    setSelectedUsers([]);
    setSearchedUsers([]);
    setUsers([]);
    close();
  };

  const addUser = (user: IUser, index: number, userAvatar: string) => {
    const userIds = selectedUsers.map((user) => user.email);

    if (userIds.includes(user.email)) return;

    setSelectedUsers([user, ...selectedUsers]);
    setSearch("");
    setSearchedUsers([]);

    setSelectedUsersAvatars([
      ...selectedUsersAvatars,
      { email: user.email, image: userAvatar },
    ]);

    const filteredUsers = users;
    filteredUsers.splice(index, 1);

    setUsers(filteredUsers);
  };

  const removeUserInvitation = (userToBeRemoved: IUser) => {
    setSelectedUsers((prevState) =>
      prevState.filter((user) => user.publicId !== userToBeRemoved.publicId)
    );
  };

  const cancelUserPendingInvitation = (userEmail: string, publicId: string) => {
    setUserCancelingInvitation(userEmail);
    cancelPendingInvitation({
      variables: {
        cancelCollectionPendingInvitation: {
          invitedUserEmail: userEmail,
          collectionPublicId: publicId,
        },
      },
    });
  };

  const handleInvite = async () => {
    if (!collection) return;
    setDisabled(true);

    const userIds = selectedUsers.map((user) => user.email);

    inviteMembers({
      variables: {
        inviteMembersToCollection: {
          collaboratorEmails: [...userIds],
          collectionPublicId: collection.publicId,
        },
      },
    });

    displaySuccessNotification();
    closeModal();
    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(!selectedUsers.length);
  }, [selectedUsers]);

  const renderUserRow = (user: IUser, userAvatar: string, userStatus: USER_STATUS) => {
    return (
      <>
        <div key={user.publicId} className={styles.member}>
          <div className={styles.userDetails}>
            <div
              style={{ backgroundImage: `url(${userAvatar})` }}
              className={styles.avatar}
            ></div>
            <div>
              <p className={styles.name}>{user.fullName}</p>
              <p className={styles.role}>{getRoleOrganizationText(user.organization)}</p>
            </div>
          </div>
          <div className={styles.actionsContainer}>
            <p>{t("collection.inviteModal.collaborator")}</p>
            {userCancelingInvitation !== user.email ? (
              <TooltipComponent
                title={
                  userStatus === USER_STATUS.INVITED
                    ? t("collections.addNewCollection.form.removeCollaboratorTooltip")
                    : t("collection.inviteModal.cancelInvitationTooltip")
                }
                tooltipType="secondary"
                placement="bottom"
                childToWrap={
                  <img
                    src={CloseMark}
                    className={styles.cancelBtn}
                    onClick={() => {
                      setDisabled(false);
                      if (userStatus === USER_STATUS.INVITED) {
                        return removeUserInvitation(user);
                      }
                      if (userStatus === USER_STATUS.PENDING) {
                        return cancelUserPendingInvitation(
                          user.email,
                          collection.publicId
                        );
                      }
                    }}
                  />
                }
              />
            ) : (
              <img className={styles.spinner} src={Loader} />
            )}
          </div>
        </div>
      </>
    );
  };

  const INVITE_MODAL_TABS = [
    {
      name: t("collection.inviteModal.invite"),
      content: (
        <>
          <h4 aria-label="modal-title" className={styles.title}>
            {t("collection.inviteModal.invite")}
          </h4>
          <div
            onClick={() => setShouldRenderSearchedUsersList(true)}
            className={styles.selectWrapper}
          >
            <InputUpdated
              placeholder={t("collection.inviteModal.searchPlaceholder")}
              type="search"
              value={search}
              onChange={(event) =>
                handleSearch(event, {
                  invitableUsersSearch: {
                    publicId: collection.publicId,
                    text: event.target.value,
                    size: 1000,
                    skip: 0,
                  },
                })
              }
              loading={isGettingUsers}
              area="sm"
              e2eTestId="search-for-members-input"
            />
            {shouldRenderSearchedUsersList && searchedUsers.length > 0 && search && (
              <div
                ref={wrapperRef}
                className={`${styles.usersContainer} ${
                  users.length && styles.noTopBorderRadius
                }`}
              >
                {searchedUsers.map((user, index) => {
                  const userAvatar = userImages
                    ? userImages.find((image) => image.email === user.email)?.image
                    : Avatar;

                  return (
                    <div
                      onClick={() =>
                        addUser(user, index, userAvatar ? userAvatar : Avatar)
                      }
                      className={styles.option}
                      key={user.publicId}
                      aria-label="user"
                      role="listitem"
                    >
                      <div
                        style={{ backgroundImage: `url(${userAvatar})` }}
                        className={styles.avatar}
                      ></div>
                      {user.fullName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.usersList}>
            {selectedUsers.map((user) => {
              const userAvatar = userImages
                ? selectedUsersAvatars.find((image) => image.email === user.email)?.image
                : Avatar;
              return renderUserRow(user, userAvatar, USER_STATUS.INVITED);
            })}
          </div>
        </>
      ),
    },
    {
      name: t("collection.inviteModal.pending"),
      content: (
        <div className={styles.usersList}>
          {pendingUsers.map((user) => {
            const userAvatar = userImages
              ? userImages.find((image) => image.email === user.email)?.image
              : Avatar;
            return renderUserRow(user, userAvatar, USER_STATUS.PENDING);
          })}
        </div>
      ),
    },
  ];

  return (
    <Modal
      className={styles.modal}
      onRequestClose={closeModal}
      isOpen={open}
      modalType="primary"
    >
      <CloseModalIcon className={styles.exit} onClick={closeModal} />
      <Tabs tabs={INVITE_MODAL_TABS} />
      <div className={styles.buttonContainer}>
        <Button
          title={t("collection.inviteModal.cancel")}
          buttonType="link"
          onClick={closeModal}
          aria-label="cancel-btn"
        />
        <Button
          onClick={handleInvite}
          title={t("collection.inviteModal.done")}
          buttonType="primary"
          disabled={disabled}
          aria-label="invite-btn"
        />
      </div>
    </Modal>
  );
};

export default InviteToCollectionModal;
