export interface Option {
  value: string;
  label: string;
}

export interface DefaultOption {
  value: string;
  label: string;
}

export const SELECT_SEARCH_TYPE = {
  INPUT_CHANGE: "inputChange",
  SCROLL_TO_BOTTOM: "scrollToBottom",
};
