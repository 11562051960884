import * as Yup from "yup";

import { MAX_CHARACTERS, MIN_CHARACTERS } from "./constant";

export const validationSchema = (t: (value: string) => string) => {
  return Yup.object({
    firstName: Yup.string().min(MIN_CHARACTERS).max(MAX_CHARACTERS).required(),
    lastName: Yup.string().min(MIN_CHARACTERS).max(MAX_CHARACTERS).required(),
    email: Yup.string()
      .email(t("adminPanel.users.inviteUserModal.invalidEmail"))
      .required(t("adminPanel.users.inviteUserModal.emailRequired")),
    isTemporary: Yup.boolean(),
    deactivationDate: Yup.date(),
  });
};

export const emailValidation = Yup.object({
  email: Yup.string().email(),
});
