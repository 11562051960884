export enum BlobStorageContainer {
  ATTACHMENTS = "ATTACHMENTS",
  FILES = "FILES",
  IMAGES = "IMAGES",
}

export class BaseError extends Error {
  constructor(message: string, options?: { cause?: unknown }) {
    super(message, { cause: options?.cause });
    this.name = this.constructor.name;
  }
}

export class UnknownBlobStorageContainer extends BaseError {
  constructor() {
    super("Unknown blob storage container");
  }
}

export class GenerateSasTokenError extends BaseError {
  constructor(message: string, options?: { cause?: unknown }) {
    super(message, { cause: options?.cause });
  }
}

export class UploadError extends BaseError {
  files: File[] = [];

  constructor(options?: { files: File[]; cause?: unknown }) {
    super("Upload Failded", { cause: options?.cause });

    if (options?.files) {
      this.files = options?.files;
    }
  }
}
