import React from "react";

import Loading from "@components/Loading/Loading";

import { getClassName } from "./helper";

import styles from "./SearchInput.module.scss";

interface ISeachInput {
  name: string;
  placeholder: string;
  e2eTestId?: string;
  className?: string;
  value: string[] | string;
  searchIcon?: JSX.Element;
  label?: string;
  labelClassName?: string;
  onEnter?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  loading?: boolean;
  isRounded?: boolean;
  hasBorder?: boolean;
}

const SearchInput = ({
  loading,
  isRounded = true,
  hasBorder = true,
  ...props
}: ISeachInput) => {
  return (
    <>
      {props.label && (
        <label className={`${styles.label} ${props.labelClassName}`} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <div className={styles.searchContainer}>
        {props.searchIcon && (
          <div className={styles.iconContainer}>
            <div className={styles.searchIcon}>{props.searchIcon}</div>
          </div>
        )}
        <input
          aria-label={props.name}
          autoComplete="off"
          name={props.name}
          placeholder={props.placeholder}
          e2e-test-id={props.e2eTestId}
          value={props.value}
          className={getClassName({
            styles,
            className: props.className,
            hasBorder,
            isRounded,
            loading,
            searchIcon: props.searchIcon,
          })}
          type="search"
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
        />
        <div className={styles.loaderContainer}>
          {loading && <Loading borderless size="Small" />}
        </div>
      </div>
    </>
  );
};

export default SearchInput;
