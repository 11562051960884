import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Close from "@images/close.svg";
import Danger from "@images/Danger.svg";
import palette from "@scss/exports.module.scss";
import styles from "./ChatWarningModal.module.scss";
export interface IChatWarningModalProps {
  open: boolean;
  title?: string;
  subtitle?: string;
  submitText?: string;
  cancelText?: string;
  close: () => void;
  submit: () => void;
}

const ChatWarningModal = ({
  open,
  title,
  subtitle,
  submitText,
  cancelText,
  close,
  submit,
}: IChatWarningModalProps) => {
  return (
    <Modal
      className={styles.container}
      isOpen={open}
      onRequestClose={close}
      overlayClassName={styles.overlay}
      // We are using inline style here because we need to override the library inline style
      contentStyles={{ backgroundColor: palette.white }}
    >
      <img src={Danger} alt="" className={styles.danger} />
      <img onClick={close} src={Close} alt="" className={styles.close} />
      <h3 className={styles.title} aria-label="title">
        {title}
      </h3>
      <p className={styles.subtitle} aria-label="subtitle">
        {subtitle}
      </p>
      <div className={styles.buttonsContainer}>
        <Button
          aria-label="cancelChatWarning"
          onClick={close}
          wrapperClassNames={styles.buttonWrapper}
          title={cancelText || ""}
        />
        <Button
          aria-label="submitChatWarning"
          onClick={submit}
          wrapperClassNames={styles.buttonWrapper}
          danger
          title={submitText || ""}
        />
      </div>
    </Modal>
  );
};

export default ChatWarningModal;
