import { gql } from "@apollo/client";

const UPDATE_COMMUNITY_PRIVATE_JOIN = gql`
  mutation updateCommunityJoinRequest($joinRequest: UpdateJoinRequestInputType!) {
    updateCommunityJoinRequest(joinRequest: $joinRequest) {
      name
      sendbirdId
    }
  }
`;

export default UPDATE_COMMUNITY_PRIVATE_JOIN;
