import { gql } from "@apollo/client";

const PROMOTE_MEMBER = gql`
  mutation PromoteCommunityMember($communitySendbirdId: String!, $memberEmail: String!) {
    promoteCommunityMember(
      promoteMember: {
        communitySendbirdId: $communitySendbirdId
        memberEmail: $memberEmail
      }
    ) {
      name
      sendbirdId
    }
  }
`;

export default PROMOTE_MEMBER;
