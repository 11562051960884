import { configuration } from "@modules/LandingPage/Data";
import { Col, Row } from "antd";

import Button from "@components/Button/Button";

import WhoBlack from "@images/hero-who-black.png";

import { ReactComponent as OpenInNew } from "@images/open_in_new.svg";
import Bee from "@images/welcome-banner-bee.svg";
import WelcomeBanner from "@images/welcome-banner-no-bee.svg";

import styles from "./WelcomeSection.module.scss";

interface IWelcomeSectionProps {
  handleLoginReact: () => void;
  goToLandingPage: () => void;
}

const WelcomeSection = ({ handleLoginReact, goToLandingPage }: IWelcomeSectionProps) => {
  const { welcomeSection } = configuration;

  return (
    <Row className={styles.welcomeSectionContainer}>
      <Col span={12}>
        <Row className={styles.containerSections} justify="center" align="middle">
          <Col span={24}>
            <Row>
              <span className={styles.leftSideTitle}>{welcomeSection?.title}</span>
            </Row>
            <Row>
              <span className={styles.leftSideDescription}>
                {welcomeSection?.description}
              </span>
            </Row>
            <Row>
              <div className={styles.welcomePageButtonContainer}>
                <Button
                  className={styles.joinCommunityButton}
                  buttonType="text"
                  title="Join Community"
                  onClick={handleLoginReact}
                />
                <Button
                  title="What is the Hive"
                  buttonType="text"
                  className={styles.whatIsHiveButton}
                  iconAfter
                  icon={<OpenInNew />}
                  onClick={goToLandingPage}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row className={styles.containerSections} justify="end" align="middle">
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={WhoBlack} />
          </div>
          <img className={styles.bee} src={Bee} />
          <img width="85%" height="85%" src={WelcomeBanner} alt={welcomeSection?.title} />
        </Row>
      </Col>
    </Row>
  );
};

export default WelcomeSection;
