/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_PAST_EVENTS from "@graphql/queries/pastEvents";
import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { findFirstItemFromNextPage, simulatePagination } from "@utils/eventsHelper";
import { useEffect, useState } from "react";
import { IEvent } from "types/event";
import styles from "./PastEvents.module.scss";

const PastEvents = () => {
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [paginationLimiter, setPaginationLimiter] = useState<number>(EVENTS_GRID_SIZE);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [eventIdForScroll, setEventIdForScroll] = useState("");

  const [getPastEvents, { loading }] = useLazyQuery(GET_PAST_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setEvents(data.user.pastEvents);
      setEventsCount(data.user.pastEvents.length);
    },
    onError: (error) => displayServerError(error),
  });

  const extendVisibleEvents = () => {
    setPaginationLimiter(paginationLimiter + EVENTS_GRID_SIZE);
  };

  useEffect(() => {
    if (!eventsCount || !paginationLimiter) return;
    setEventIdForScroll(findFirstItemFromNextPage(events, paginationLimiter));
  }, [paginationLimiter, eventsCount]);

  useEffect(() => {
    getPastEvents();
  }, []);

  return loading ? (
    <Loading disableBoxShadow />
  ) : (
    <div className={styles.eventContainer}>
      <div className={styles.pastEvents}>
        <EventsGrid
          events={simulatePagination(events, paginationLimiter)}
          loadMore={extendVisibleEvents}
          eventsLoading={loading}
          eventsCount={eventsCount}
          eventsLoadingMore={false}
          firstItemId={eventIdForScroll}
        />
      </div>
    </div>
  );
};

export default PastEvents;
