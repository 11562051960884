import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Input from "@components/Input/Input";

import ErrorCircle from "@images/circleError.svg";

import UPDATE_USER from "@graphql/mutations/updateUser";
import { IUser } from "types/user";

import {
  determineNotificationByResponse,
  displayNotSuccessNotification,
  displayServerError,
} from "@services/NotificationService/NotifacitonService";
import { handleAcceptingRedirect } from "@utils/helpers";

import styles from "./SettingInterestsAndSocialMedia.module.scss";

interface ISettingsInterestsAndSocialMedia {
  data: {
    userDefinedInterests: string | undefined;
    userSecretSuperPower: string | undefined;
    problemUserWouldLikeToSolve: string | undefined;
    userLinkedInLink: string | undefined;
  };
  refetchData: () => void;
}

const SettingsInterestsAndSocialMedia = ({
  data,
  refetchData,
}: ISettingsInterestsAndSocialMedia) => {
  const { t } = useTranslation();

  const [updateUser] = useMutation(UPDATE_USER);

  const MAX_INPUT_LENGTH = 200;

  const [editInterestsField, setEditInterestsField] = useState(false);
  const [editSocialMediaField, setEditSocialMediaField] = useState(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);
  const [clickedUrl, setClickedUrl] = useState<string>("");

  const [userDefinedInterests, setUserDefinedInterests] = useState<string | undefined>(
    data.userDefinedInterests
  );
  const [userSecretSuperPower, setUserSecretSuperPower] = useState<string | undefined>(
    data.userSecretSuperPower
  );
  const [problemUserWouldLikeToSolve, setProblemUserWouldLikeToSolve] = useState<
    string | undefined
  >(data.problemUserWouldLikeToSolve);
  const [userLinkedInLink, setUserLinkedInLink] = useState<string | undefined>(
    data.userLinkedInLink
  );

  const [isLinkedinValidUrl, setIsLinkedinValidUrl] = useState<boolean>();

  const mapResponseDataForCheck = (responseData: IUser) => {
    return {
      userDefinedInterests: responseData.userDefinedInterests,
      userSecretSuperPower: responseData.userSecretSuperPower,
      problemUserWouldLikeToSolve: responseData.bio,
    };
  };

  const linkedinRegex = new RegExp(
    /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!]))?/
  );

  useEffect(() => {
    setIsLinkedinValidUrl(!!userLinkedInLink?.match(linkedinRegex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLinkedInLink]);

  const handleSocialMediaSave = () => {
    setEditSocialMediaField(false);

    updateUser({
      variables: {
        user: {
          userLinkedInLink,
        },
      },
    })
      .then((response) => {
        // Currently doesn't support validation for same strings, so Error notification will be shown if save is triggered on save string
        if (!response.data.updateUser) {
          displayNotSuccessNotification();
          return;
        }

        const mappedResponseData = mapResponseDataForCheck(response.data.updateUser);

        determineNotificationByResponse(data, mappedResponseData) && refetchData();
      })
      .catch((error) => {
        displayServerError();
      });
  };

  const handleInterestsSave = () => {
    setEditInterestsField(false);

    updateUser({
      variables: {
        user: {
          userDefinedInterests,
          userSecretSuperPower,
          problemUserWouldLikeToSolve,
        },
      },
    })
      .then((response) => {
        // Currently doesn't support validation for same strings, so Error notification will be shown if save is triggered on save string
        if (!response.data.updateUser) {
          displayNotSuccessNotification();
          return;
        }

        const mappedResponseData = mapResponseDataForCheck(response.data.updateUser);

        determineNotificationByResponse(data, mappedResponseData) && refetchData();
      })
      .catch((error) => {
        displayServerError();
      });
  };

  const handleLinkedinLinkClick = () => {
    setIsConfirmationModalOpened(true);
    setClickedUrl(data.userLinkedInLink as string);
  };

  return (
    <>
      <div
        className={styles.container}
        e2e-test-id="interests-and-social-media-container"
      >
        <span className={styles.title} e2e-test-id="interests-and-social-media-title">
          {t("accountSettings.interestsAndSocialMedia.heading")}
        </span>
        <div className={styles.fieldContainer} e2e-test-id="interests-section">
          <div className={styles.rowContainer}>
            <span className={styles.rowTitle}>
              {t("accountSettings.interestsAndSocialMedia.label.interests")}
            </span>
            <Button
              title={
                editInterestsField
                  ? t("accountSettings.personalDetails.button.cancel")
                  : t("accountSettings.personalDetails.button.edit")
              }
              onClick={() => {
                setEditInterestsField(!editInterestsField);
                setUserDefinedInterests(data.userDefinedInterests);
                setUserSecretSuperPower(data.userSecretSuperPower);
                setProblemUserWouldLikeToSolve(data.problemUserWouldLikeToSolve);
              }}
              buttonType="link"
              data-testid="edit-field"
            />
          </div>
          {editInterestsField ? (
            <>
              <Input
                maxLength={MAX_INPUT_LENGTH}
                placeholder={t(
                  "accountSettings.interestsAndSocialMedia.input.placeholder"
                )}
                name="userDefinedInterests"
                label={t(
                  "accountSettings.interestsAndSocialMedia.input.userDefinedInterests"
                )}
                labelClassNames={styles.inputLabel}
                value={userDefinedInterests}
                onChange={(e) => setUserDefinedInterests(e.target.value)}
              />
              <Input
                maxLength={MAX_INPUT_LENGTH}
                placeholder={t(
                  "accountSettings.interestsAndSocialMedia.input.placeholder"
                )}
                name="userSecretSuperPower"
                label={t(
                  "accountSettings.interestsAndSocialMedia.input.userSecretSuperPower"
                )}
                labelClassNames={styles.inputLabel}
                value={userSecretSuperPower}
                onChange={(e) => setUserSecretSuperPower(e.target.value)}
              />
              <Input
                maxLength={MAX_INPUT_LENGTH}
                placeholder={t(
                  "accountSettings.interestsAndSocialMedia.input.placeholder"
                )}
                name="problemUserWouldLikeToSolve"
                label={t(
                  "accountSettings.interestsAndSocialMedia.input.problemUserWouldLikeToSolve"
                )}
                labelClassNames={styles.inputLabel}
                value={problemUserWouldLikeToSolve}
                onChange={(e) => setProblemUserWouldLikeToSolve(e.target.value)}
              />
              <Button
                title={t("accountSettings.interestsAndSocialMedia.button.save")}
                buttonType="primary"
                onClick={() => handleInterestsSave()}
              />
            </>
          ) : (
            <>
              {data.userDefinedInterests && (
                <>
                  <p className={styles.value}>
                    {t(
                      "accountSettings.interestsAndSocialMedia.label.userDefinedInterests"
                    )}
                    {data.userDefinedInterests}
                  </p>
                </>
              )}
              {data.userSecretSuperPower && (
                <p className={styles.value}>
                  {t(
                    "accountSettings.interestsAndSocialMedia.label.userSecretSuperPower"
                  )}
                  {data.userSecretSuperPower}
                </p>
              )}
              {data.problemUserWouldLikeToSolve && (
                <p className={styles.value}>
                  {t(
                    "accountSettings.interestsAndSocialMedia.label.problemUserWouldLikeToSolve"
                  )}
                  {data.problemUserWouldLikeToSolve}
                </p>
              )}
            </>
          )}
        </div>
        <div className={styles.fieldContainer} e2e-test-id="social-media-section">
          <div className={styles.rowContainer}>
            <span className={styles.rowTitle}>
              {t("accountSettings.interestsAndSocialMedia.label.socialMedia")}
            </span>
            <Button
              title={
                editSocialMediaField
                  ? t("accountSettings.personalDetails.button.cancel")
                  : t("accountSettings.personalDetails.button.edit")
              }
              onClick={() => {
                setEditSocialMediaField(!editSocialMediaField);
                setUserLinkedInLink(data.userLinkedInLink);
              }}
              buttonType="link"
              data-testid="edit-field"
            />
          </div>
          {editSocialMediaField ? (
            <>
              <Input
                name="userLinkedInLink"
                placeholder="eg. https://linkedin.com/in/user-profile-page-123456"
                label={t(
                  "accountSettings.interestsAndSocialMedia.input.userLinkedInLink"
                )}
                labelClassNames={styles.inputLabel}
                value={userLinkedInLink}
                onChange={(e) => setUserLinkedInLink(e.target.value)}
              />
              {!isLinkedinValidUrl && userLinkedInLink !== "" && (
                <div className={styles.errorContainer}>
                  <img alt="" src={ErrorCircle} />
                  <span className={styles.errorMessage}>
                    {t("event.newEvent.error.externalUrlNotValid")}
                  </span>
                </div>
              )}
              <Button
                title={t("accountSettings.interestsAndSocialMedia.button.save")}
                buttonType="primary"
                onClick={() => handleSocialMediaSave()}
                disabled={!isLinkedinValidUrl && userLinkedInLink !== ""}
              />
            </>
          ) : (
            data.userLinkedInLink && (
              <>
                <span className={styles.rowTitle}>
                  {t("accountSettings.interestsAndSocialMedia.label.linkedin")}
                </span>
                <span className={styles.valueLink} onClick={handleLinkedinLinkClick}>
                  {data.userLinkedInLink}
                </span>
              </>
            )
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        accept={() => handleAcceptingRedirect(clickedUrl, setIsConfirmationModalOpened)}
        url={clickedUrl}
        closeModal={() => setIsConfirmationModalOpened(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};

export default SettingsInterestsAndSocialMedia;
