/* eslint-disable react-hooks/exhaustive-deps */
import { MINIMUM_REQUIRED_CHARACTERS_FOR_SEARCH } from "@customHooks/useSearchMessages";
import DownArrowIcon from "@images/caret-down.svg";
import UpArrowIcon from "@images/caret-up.svg";
import CloseIcon from "@images/close.svg";
import SearchIcon from "@images/search_icon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISearchMessages } from "types/SearchMessage";
import SearchInput from "../SearchInput";
import styles from "./SearchMessagesInput.module.scss";

interface ISearchMessagesProps {
  searchMessages: ISearchMessages;
  clearSearchMessagesDependencies: Array<unknown>;
}

export const SearchMessagesInput = ({
  searchMessages: { actions, controls, state },
  clearSearchMessagesDependencies = [],
}: ISearchMessagesProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const handleChangeSearchQuery = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
    actions.updateCurrentSearchQuery(value);
  };

  const handleClearSearchMessages = () => {
    setSearchQuery("");
    actions.updateCurrentSearchQuery("");
  };

  const handleKeyDownSearchMessages = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      controls.goToNextSearchMessage();
    }
  };

  useEffect(() => {
    if (!clearSearchMessagesDependencies.length) return;

    handleClearSearchMessages();
  }, [...clearSearchMessagesDependencies]);

  const renderSearchInput = () => {
    if (searchQuery.length < MINIMUM_REQUIRED_CHARACTERS_FOR_SEARCH) {
      return (
        <div>
          <SearchInput
            value={searchQuery}
            onChange={handleChangeSearchQuery}
            placeholder={t("chat.searchDiscussionPlaceHolder")}
            name="search"
            className={styles.searchInput}
            searchIcon={<img src={SearchIcon} />}
            hasBorder={false}
          />
        </div>
      );
    }

    return (
      <div className={styles.searchInputContainer}>
        <SearchInput
          value={searchQuery}
          onChange={handleChangeSearchQuery}
          placeholder={t("chat.searchDiscussionPlaceHolder")}
          name="searchMessages"
          onKeyDown={handleKeyDownSearchMessages}
          className={styles.searchInput}
          hasBorder={false}
        />
        <span className={styles.resultsFound}>
          {actions.getIndexOfCurrentSearchMessageId() + 1}/{state.searchMessageIds.length}
        </span>
        <span className={styles.divider}>|</span>
        <div className={styles.controls}>
          <div
            aria-label="goToNextMessage"
            className={styles.imgWrapper}
            onClick={controls.goToNextSearchMessage}
          >
            <img src={UpArrowIcon} alt="" className={styles.arrowIcon} />
          </div>
          <div
            aria-label="goToPreviousMessage"
            className={styles.imgWrapper}
            onClick={controls.goToPreviousSearchMessage}
          >
            <img src={DownArrowIcon} alt="" className={styles.arrowIcon} />
          </div>
          <div
            aria-label="clearSearchMessages"
            className={styles.imgWrapper}
            onClick={handleClearSearchMessages}
          >
            <img src={CloseIcon} alt="" className={styles.closeIcon} />
          </div>
        </div>
      </div>
    );
  };

  return renderSearchInput();
};
