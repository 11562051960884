import { gql } from "@apollo/client";

const GET_USER_INFO_COLLECTIONS = gql`
  query userInfo($email: String!, $collectionSearch: SearchInputType!) {
    userInfo(email: $email) {
      publicId
      fullName
      email
      myCollections(collectionSearch: $collectionSearch) {
        publicId
        name
        description
        isPublic
        isGlobalCurated
        imageName
        imageDirectory
        creationTime
        updateTime
        collectionCreator {
          fullName
          email
        }
        # articlesCount
        collaboratorsCount
        followersCount
        activitiesCount
        collaborators {
          fullName
          email
        }
      }
      myCollectionsTotalCount
    }
  }
`;

export default GET_USER_INFO_COLLECTIONS;
