import { gql } from "@apollo/client";

const INVITE_USER = gql`
  mutation createUser($user: CreateUserInputType!) {
    createUser(user: $user) {
      fullName
    }
  }
`;

export default INVITE_USER;
