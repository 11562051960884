import {
  CommunityNotificationType,
  ICommunityNotification,
  INotification,
  NotificationType,
} from "types/Notification";
import { CommunityNotification } from "../CommunityNotification/CommunityNotification";
import { MessageCard } from "../MessageCard/MessageCard";
import styles from "./Message.module.scss";

export const Message = (notification: INotification) => {
  const renderGeneralNotification = (notification: INotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="General Notification"
      notification={notification}
      handleLink={notification.link.onClick}
    />
  );

  const renderRemoveNotification = (notification: INotification) => (
    <MessageCard
      key={notification.id}
      ariaLabel="Remove Notification"
      notification={notification}
      className={styles.removeNotification}
      handleClickCard={notification.onClick}
    />
  );

  const renderCommunityNotification = (notification: INotification) => {
    const communityNotification = () => (
      <CommunityNotification {...(notification as ICommunityNotification)} />
    );

    const renderNotificationType = {
      [CommunityNotificationType.GENERAL]: renderGeneralNotification,
      [CommunityNotificationType.REMOVE]: renderRemoveNotification,
      [CommunityNotificationType.ADDED]: communityNotification,
      [CommunityNotificationType.REQUEST]: communityNotification,
    };

    const renderNotification = (notification: ICommunityNotification) =>
      renderNotificationType[notification.type](notification);

    return renderNotification(notification as ICommunityNotification);
  };

  const renderNotificationType = {
    [NotificationType.COMMUNITY]: renderCommunityNotification,
  };

  const renderNotification = (notification: INotification) =>
    renderNotificationType[0](notification);

  return renderNotification(notification);
};
