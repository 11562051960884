/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { PlusOutlined } from "@ant-design/icons";
import {
  MAX_TEXT_AREA_LENGTH,
  NUMBER_OF_USERS_PER_PAGE,
  SEARCH_TRIGGER,
  STORAGE_IMAGE_TYPE_USER,
} from "@constants/constants";
import { IUseSendBirdGroupChannels } from "@customHooks/sendBird/useSendBirdGroupChannels/type";
import GET_GENERAL_USERS_INFORMATION from "@graphql/queries/getGeneralUsersInformation";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Avatar from "@images/avatar.svg";
import Checked from "@images/checked.svg";
import Close from "@images/close.svg";
import Search from "@images/search_icon.svg";
import palette from "@scss/exports.module.scss";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import getStorageImage from "@utils/getStorageImage";
import { CHAT_ROUTES } from "@utils/routes/chat";
import Switch from "react-switch";
import { IUser, IUserImageResponse, IUsersResponse } from "types/user";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import SearchInput from "../SearchInput/SearchInput";
import TextareaComponent from "../Textarea/Textarea";
import styles from "./CreateGroupChat.module.scss";
interface ICreateGroupChatProps {
  open: boolean;
  close: () => void;
  sendBirdGroupChannels: IUseSendBirdGroupChannels;
}

const CreateGroupChat = ({
  open,
  close,
  sendBirdGroupChannels,
}: ICreateGroupChatProps) => {
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [addUsers, setAddUsers] = useState<Array<string>>([]);
  const [validationErrors, setValidationErrors] = useState({ groupName: "" });
  const [skip, setSkip] = useState<number>(0);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver>();

  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const [getUsers] = useLazyQuery<IUsersResponse>(GET_GENERAL_USERS_INFORMATION, {
    onCompleted: ({ users: { users, totalCount } }) => {
      setUsers([...users]);
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: users.map((member) => member.email),
          },
        },
      });
      setHasMoreUsers(totalCount > 0);
    },
    onError: (error) => displayServerError(error),
    fetchPolicy: "cache-and-network",
  });

  const lastUserElementRef = useCallback(
    (node) => {
      observer.current && observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreUsers) {
          setSkip((skip) => skip + NUMBER_OF_USERS_PER_PAGE);
        }
      });
      node && observer.current.observe(node);
    },
    [hasMoreUsers]
  );

  useEffect(() => {
    if (!search.length) {
      getUsers({
        variables: {
          size: NUMBER_OF_USERS_PER_PAGE + skip,
        },
      });
    }
    search.length > SEARCH_TRIGGER &&
      getUsers({
        variables: {
          text: search,
          size: NUMBER_OF_USERS_PER_PAGE + skip,
        },
      });
  }, [search, skip]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value.trim());
  };

  const createGroupChannel = () => {
    sendBirdGroupChannels.helpers.createGroupChannel({
      pathPrefix: CHAT_ROUTES.CHAT,
      addUsers,
      checked,
      description,
      groupName,
      onSuccess: handleCloseModal,
    });
  };

  const addToInvite = (email: string) => {
    setAddUsers([...addUsers, email]);
  };

  const removeFromInvite = (email: string) => {
    const newArray = addUsers.filter((existEmail) => {
      return existEmail !== email;
    });
    setAddUsers(newArray);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSkip(0);
    setSearch(e.target.value);
  };
  const handleCreateNewGroup = () => {
    if (!groupName) {
      setValidationErrors({
        groupName: t("chat.group.createGroup.requiredField"),
      });
      return;
    }

    createGroupChannel();
    setGroupName("");
    setSkip(0);
  };

  const handleCloseModal = () => {
    setDescriptionField(false);
    setDescription("");
    setChecked(false);
    setSearch("");
    setAddUsers([]);
    setUsers([]);
    setGroupName("");
    setValidationErrors({ groupName: "" });
    setSkip(0);
    close();
  };

  return (
    <Modal
      className={styles.container}
      isOpen={open}
      onRequestClose={handleCloseModal}
      overlayClassName={styles.overlay}
    >
      <h3 className={styles.title}>{t("chat.group.createGroup.title")}</h3>
      <img onClick={handleCloseModal} src={Close} alt="" className={styles.close} />

      <Input
        name="GroupName"
        label={t("chat.group.createGroup.groupNameLabel")}
        labelClassNames={styles.label}
        type="text"
        onChange={handleChange}
        maxLength={50}
        placeholder={t("chat.group.createGroup.groupNamePlaceholder")}
        additionalElement={
          !descriptionField && (
            <Button
              title={t("community.newCommunity.buttons.addDescription")}
              buttonIcon={<PlusOutlined />}
              wrapperClassNames={styles.inlineInputWrapper}
              buttonSize="small"
              onClick={() => {
                setDescriptionField(true);
              }}
            />
          )
        }
      />
      {validationErrors.groupName && (
        <span className={styles.errorName}>{validationErrors.groupName}</span>
      )}
      {descriptionField && (
        <TextareaComponent
          maxLength={MAX_TEXT_AREA_LENGTH}
          onChange={handleChangeDescription}
          name="description"
          label={t("chat.group.createGroup.descriptionLabel")}
          placeholder={t("chat.group.createGroup.descriptionPlaceholder")}
          defaultValue={description}
          labelClassNames={styles.label}
          textAreaStyle={{ hasBorder: true }}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className={styles.searchContainer}>
          <SearchInput
            searchIcon={<img src={Search} alt="" className={styles.searchIcon} />}
            value={search}
            label={t("chat.group.createGroup.searchLabel")}
            labelClassName={styles.subTitle}
            className={styles.searchInput}
            onChange={(e) => handleSearch(e)}
            name="memberSearch"
            placeholder={t("chat.group.createGroup.searchPlaceholder")}
          />
        </div>
      </form>

      <div className={styles.memberList}>
        {users.map((member, index) => {
          const userAvatar = userImages
            ? userImages.find((image) => image.email === member.email)?.image
            : Avatar;
          return (
            <div
              key={index}
              className={styles.memberItem}
              onClick={() =>
                addUsers.includes(member.email)
                  ? removeFromInvite(member.email)
                  : addToInvite(member.email)
              }
            >
              <div
                style={{ backgroundImage: `url(${userAvatar})` }}
                className={styles.memberImage}
              ></div>
              <div>
                <div>
                  {users.length === index + 1 ? (
                    <div ref={lastUserElementRef} className={styles.memberName}>
                      {member.fullName}
                    </div>
                  ) : (
                    <div className={styles.memberName}>{member.fullName}</div>
                  )}
                </div>

                <div className={styles.memberPosition}>
                  {member?.organization?.sector?.name}
                </div>
              </div>

              {addUsers.includes(member.email) ? (
                <img src={Checked} alt="" className={styles.checked} />
              ) : (
                <div className={styles.unChecked} />
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.flexColumn}>
        <h3 className={styles.subTitle}>{t("chat.group.createGroup.private")}</h3>
        <Switch
          onChange={() => setChecked(!checked)}
          checked={false}
          onColor={palette.primaryBlue}
          readOnly
        />
      </div>
      <div className={styles.flexContainer}>
        <Button
          onClick={handleCloseModal}
          buttonType="link"
          title={t("chat.group.createGroup.cancel")}
        />
        <Button
          onClick={handleCreateNewGroup}
          buttonType="primary"
          disabled={!groupName.length}
          title={t("chat.group.createGroup.create")}
        />
      </div>
    </Modal>
  );
};

export default CreateGroupChat;
