import { RootState } from "./../store";
import { AnyAction } from "redux";
import { SORT_BY } from "./constants";
import PortalState from "./types";

const initialState: PortalState = {
  articles: {
    sortBy: "Most recent",
  },
};

const portalReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case SORT_BY:
      return {
        ...state,
        articles: {
          ...state.articles,
          sortBy: payload,
        },
      };
    default:
      return state;
  }
};

export const articlesSortBySelector = (state: RootState) => state.portal.articles.sortBy;

export default portalReducer;
