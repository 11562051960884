import { gql } from "@apollo/client";

const GET_INTERESTS = gql`
  query interests {
    interests {
      name
    }
  }
`;

export default GET_INTERESTS;
