import { gql } from "@apollo/client";

const GET_FEEDBACK = gql`
  query feedback($publicId: String!) {
    feedback(publicId: $publicId) {
      subject
      publicId
      autoIncrementedId
      content
      reporter {
        fullName
        email
      }
    }
  }
`;

export default GET_FEEDBACK;
