import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";

import Button from "@components/Button/Button";
import { MouseEvent } from "react";

export interface IBackButtonProps {
  path?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  wrapperClassName?: string;
}

const BackButton = (props: IBackButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handlePath = () => {
    props.path ? history.push(props.path) : history.goBack();
  };

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    props.onClick ? props.onClick(event) : handlePath();
  };

  return (
    <div
      className={`${props.wrapperClassName ? props.wrapperClassName : ""}`}
      data-testid="container"
    >
      <Button
        title={props.label || t("button.back")}
        buttonIcon={<ArrowLeftOutlined />}
        onClick={handleOnClick}
        buttonType="link"
        data-testid="button"
        e2eTestId="back-to-previous-page-link"
      />
    </div>
  );
};

export default BackButton;
