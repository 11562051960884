import { gql } from "@apollo/client";

export const ALL_PENDING_COMMUNITY_INVITATIONS = gql`
  query {
    pendingCommunityInvitations {
      user {
        fullName
        email
      }
      community {
        name
        sendbirdId
        communityType
      }
      status
      inviter {
        fullName
        email
      }
    }
  }
`;

export const PENDING_COMMUNITY_INVITATIONS = gql`
  query usersWithPendingCommunityInvitations($sendbirdId: String!) {
    usersWithPendingCommunityInvitations(sendbirdId: $sendbirdId) {
      fullName
      email
      publicId
      status
      organization {
        sector {
          name
        }
        name
        userRole
      }
    }
  }
`;
