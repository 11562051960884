// Labels part - constants which define in what part of application is used and the name of the field(value)
export const SEARCH = "s";
export const ARTICLE = "a";

export const USER_ID = "user_id";
export const SEARCH_ID = "search_id";
export const TIMESTAMP = "timestamp";
export const SEARCH_VALUE = "sv";
export const ARTICLE_ID = "aid";

// Actions
export const ARTICLE_OPEN_ACTION = "HIVE_article_open";
export const NEW_MESSAGE_SEND_ACTION = "HIVE_new_message_send";
export const ZOOM_EVENT_ATTENDANCE_ACTION = "HIVE_zoom_event_attendance";
export const REGISTER_SUBMIT_ACTION = "HIVE_register_submit";
export const NEW_EVENT_CREATE_ACTION = "HIVE_new_event_create";
export const NEW_COMMUNITY_CREATE_ACTION = "HIVE_new_community_create";
export const SEARCH_PORTAL_ACTION = "HIVE_search_portal";

// Categories
export const USER_ACTIVITIES_CATEGORY = "HIVE_user_activities";
export const MESSAGE_CATEGORY = "HIVE_message";
export const EVENT_ATTENDANCE_CATEGORY = "HIVE_event_attendance";
export const REGISTER_CATEGORY = "HIVE_register";
export const NEW_EVENT_CATEGORY = "HIVE_new_event";
export const NEW_COMMUNITY_CATEGORY = "HIVE_new_community";
