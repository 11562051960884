import Loading from "@components/Loading/Loading";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";
import { disableNext } from "@utils/helpers";
import styles from "./RecommendedGCCSliderSection.module.scss";

import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import { NO_COLLECTIONS_MESSAGES } from "@constants/constants";
import { ICollection } from "@graphql/collection/type";
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SlickSlider from "react-slick";

const { noCollections } = NO_COLLECTIONS_MESSAGES;

export interface IRecommendedGCCSliderSection {
  collections: Array<ICollection>;
  collectionsCount: number;
  collectionsLoading: boolean;
  pageSize: number;
  menuDots?: (collection: ICollection) => void;
  loadMore: (sliderPageValue: number) => void;
}

const RecommendedGCCSliderSection = ({
  collections,
  collectionsCount,
  collectionsLoading,
  pageSize,
  loadMore,
  menuDots,
}: IRecommendedGCCSliderSection) => {
  const collectionsChildRef = useRef<SlickSlider>(null);
  const { t } = useTranslation();
  const [sliderPage, setSliderPage] = useState<number>(0);

  const handlePreviousCollections = () => {
    collectionsChildRef.current?.slickPrev();
    setSliderPage(sliderPage - 1);
  };

  const handleNextCollections = () => {
    collectionsChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
  };

  const disableSliderNext = () => {
    return collectionsLoading || disableNext(sliderPage, collectionsCount, pageSize);
  };

  const disableSliderPrevious = () => {
    return collectionsLoading || sliderPage === 0;
  };

  useEffect(() => {
    loadMore(sliderPage);
  }, [sliderPage]);

  return (
    <div
      className={styles.collectionsContainer}
      data-testid="rs-collection-container"
      e2e-test-id="recommended-gcc-slider-container"
    >
      <div className={styles.collectionsHeading}>
        <div className={styles.collectionsHeadingWrapper}>
          <h3
            className={styles.collectionsTitle}
            e2e-test-id="recommended-gcc-slider-title"
          >
            {t("collections.recommendedGCC")}
          </h3>
        </div>
        {collections.length !== 0 && (
          <div
            className={styles.collectionsButtonContainer}
            data-testid="shift-buttons-container"
            e2e-test-id="recommended-gcc-slider-controls"
          >
            <ShiftButtons
              onForwardClick={handleNextCollections}
              onBackClick={handlePreviousCollections}
              disabledBack={disableSliderPrevious()}
              disabledForward={disableSliderNext()}
              hideSeeAllButton
            />
          </div>
        )}
      </div>

      {!collections.length && !collectionsLoading ? (
        <span className={styles.textContainer} data-testid="no-results-container">
          {t(noCollections)}
        </span>
      ) : (
        <div
          className={styles.collectionsContent}
          data-testid="results-container"
          e2e-test-id="recommended-gcc-slider-cards"
        >
          {collectionsLoading ? (
            <div className={styles.loaderWrapper}>
              <Loading borderless disableBoxShadow e2eTestId="gcc-collections-loader" />
            </div>
          ) : (
            <>
              {!!collections.length && (
                <Slider
                  forwardedRef={collectionsChildRef}
                  items={collections?.map((collection: ICollection) => {
                    const menu = menuDots && menuDots(collection);

                    return (
                      <CollectionsCard
                        menuOptions={menu || undefined}
                        key={collection.publicId}
                        title={collection.name}
                        description={collection.description}
                        resourcesCount={collection.articlesCount}
                        collaboratorsCount={collection.collaboratorsCount}
                        updateTime={collection.updateTime}
                        publicId={collection.publicId}
                        customStyles={styles.customStyles}
                        collectionCreator={collection.collectionCreator}
                        imageName={collection.imageName}
                        imageDirectory={collection.imageDirectory}
                        isPublic={collection.isPublic}
                        isGlobalCurated={collection.isGlobalCurated}
                      />
                    );
                  })}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RecommendedGCCSliderSection;
