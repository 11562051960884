import { gql } from "@apollo/client";

export const GET_SEARCHED_USERS = gql`
  query users($size: UInt, $skip: UInt, $text: String, $includeMyself: Boolean) {
    users(
      userSearch: { skip: $skip, size: $size, text: $text, includeMyself: $includeMyself }
    ) {
      totalCount
      users {
        publicId
        fullName
        email
        bio
        plainTextBio
        organization {
          name
          userRole
        }
        country {
          name
        }
        userPhotoName
        imageDirectory
      }
    }
  }
`;

export const GET_SEARCHED_USERS_FOR_INVITE = gql`
  query usersByFullName($size: UInt, $skip: UInt, $text: String) {
    usersByFullName(userSearch: { skip: $skip, size: $size, text: $text }) {
      totalCount
      users {
        publicId
        fullName
        email
        organization {
          name
          userRole
        }
        country {
          name
        }
        userPhotoName
        imageDirectory
      }
    }
  }
`;

export const USER_PLACEHOLDER = {
  publicId: "",
  fullName: "placeholder",
  email: "",
  bio: "",
  plainTextBio: "",
  imageDirectory: "",
  userPhotoName: "",
  organization: {
    name: "",
    userRole: "",
  },
  country: {
    name: "",
  },
};
