/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../store/RootState";

import Button from "@components/Button/Button";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import Loading from "@components/Loading/Loading";
import RadioButton from "@components/RadioButton/RadioButton";
import SelectComponent from "@components/Select/Select";
import TextareaComponent from "@components/Textarea/Textarea";

import { EDIT_COLLECTION } from "@graphql/collection/mutation";
import { GET_SINGLE_COLLECTION } from "@graphql/collection/queries";
import GET_GENERAL_USERS_INFORMATION from "@graphql/queries/getGeneralUsersInformation";

import { ClaimsService } from "@services/ClaimsService";
import { CLAIM_TYPES } from "../../store/user/constants";

import CuratedIcon from "@images/curated.svg";
import SearchIcon from "@images/search_icon.svg";

import { IImage } from "types/image";
import { DefaultOption } from "types/select";
import { IUsersResponse } from "types/user";

import {
  COLLECTION_TYPES,
  COLLECTION_TYPES_IDS,
  MAX_INPUT_LENGTH_MID,
  MAX_INPUT_LENGTH_SHORT,
  STORAGE_IMAGE_TYPE_COLLECTION,
} from "../../constants/constants";
import { defaultImages } from "../CollectionCreateNew/constants";

import { fileNameReplaceRegex } from "../../utils/helpers";

import styles from "../CollectionCreateNew/CollectionCreateNew.module.scss";

import ImagePickerWithModal from "../../components/ImagePickerWithModal/ImagePickerWithModal";
import { useInterestHelper } from "../../customHooks/useInterestHelper";
import {
  CollectionType,
  ISingleCollection,
  ISingleCollectionResponse,
} from "../../graphql/collection/type";
import getStorageImage, {
  DEFAULT_COLLECTION_IMAGE_NAME_1,
  DEFAULT_COLLECTION_IMAGE_NAME_2,
  DEFAULT_COLLECTION_IMAGE_NAME_3,
} from "../../utils/getStorageImage";
import { mapToOption } from "../../utils/selectHelper";

import { ButtonUpdated } from "@components/ButtonUpdated/ButtonUpdated";
import { InputUpdated } from "@components/InputUpdated/InputUpdated";
import PageNotFound from "@modules/PageNotFound/PageNotFound";
import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { BlobStorageContainer } from "@utils/files/types";
import { useUploadFiles } from "@utils/files/useUploadFiles";
import FileCheckerService from "../../utils/files/fileChecker";

interface InterestsTypes {
  name: string;
}

interface CollectionTypeItem {
  id: number;
  name: string;
  value: boolean;
  label: string;
  subLabel: string;
}

type ParamsProps = {
  id: string;
};

const CollectionEdit = () => {
  const { t } = useTranslation();
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const history = useHistory();
  const params = useParams() as unknown as ParamsProps;

  const [loading, setLoading] = useState<boolean>(true);

  const isAdmin: boolean = ClaimsService.hasAllClaims([
    CLAIM_TYPES.USER_READ,
    CLAIM_TYPES.FEEDBACK_READ,
  ]);

  const [isGlobalCuratedByDefault, setIsGlobalCuratedByDefault] =
    useState<boolean>(false);

  const allCollectionTypes: CollectionTypeItem[] = [
    {
      id: COLLECTION_TYPES_IDS.PUBLIC_TYPE,
      name: COLLECTION_TYPES.PUBLIC_TYPE,
      value: true,
      label: t("collections.addNewCollection.form.collectionTypeRadioPublic"),
      subLabel: t(
        "collections.addNewCollection.form.collectionTypeRadioPublicDescription"
      ),
    },
    {
      id: COLLECTION_TYPES_IDS.PRIVATE_TYPE,
      name: COLLECTION_TYPES.PRIVATE_TYPE,
      value: false,
      label: t("collections.addNewCollection.form.collectionTypeRadioPrivate"),
      subLabel: t(
        "collections.addNewCollection.form.collectionTypeRadioPrivateDescription"
      ),
    },
    {
      id: COLLECTION_TYPES_IDS.GLOBAL_CURATED_TYPE,
      name: COLLECTION_TYPES.GLOBAL_CURATED_TYPE,
      value: true,
      label: (
        <span>
          {t("collections.addNewCollection.form.collectionTypeRadioGlobalCurated")}
          <img src={CuratedIcon} className={styles.curatedIcon} />
        </span>
      ),
      subLabel: t(
        "collections.addNewCollection.form.collectionTypeRadioGlobalCuratedDescription"
      ),
    },
  ];

  const [collectionTypes, setCollectionTypes] = useState<CollectionTypeItem[]>([]);
  const { options: interestOptions } = useInterestHelper();

  const { uploadFiles } = useUploadFiles();

  const { data: userData } = useQuery<IUsersResponse>(GET_GENERAL_USERS_INFORMATION, {
    variables: {
      text: "",
    },
  });
  const [editCollection] = useMutation(EDIT_COLLECTION);

  const [selectedInterests, setSelectedInterests] = useState<Array<InterestsTypes>>([]);
  const [selectedDefaultInterestOptions, setSelectedDefaultInterestOptions] = useState<
    Array<DefaultOption>
  >([]);
  const [collectionName, setCollectionName] = useState<string>("");

  const [currentCollectionData, setCurrentCollectionData] = useState<ISingleCollection>();

  const [isCreatingCollection, setIsCreatingCollection] = useState<boolean>(false);
  const [originalImageName, setOriginalImageName] = useState<string>("");
  const [descriptionField, setDescriptionField] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  const [selectedCollectionType, setSelectedCollectionType] =
    useState<CollectionTypeItem>(allCollectionTypes[0]);

  const [allImages, setAllImages] = useState<IImage[]>(defaultImages);
  const [selectedImage, setSelectedImage] = useState<IImage>({} as IImage);

  const [collectionTypeName, setCollectionTypeName] = useState<string>("");

  const [isCollectionNotFound, setIsCollectionNotFound] = useState<boolean>(false);

  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionName(event.target.value);
  };
  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const [getCollection] = useLazyQuery(GET_SINGLE_COLLECTION, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data: ISingleCollectionResponse) => {
      if (data.collection) {
        const isCurrentUserCollaborator = data.collection.collaborators.some(
          (element) => {
            if (element.email === currentUserEmail) {
              return true;
            }

            return false;
          }
        );

        if (
          data.collection.collectionCreator.email !== currentUserEmail &&
          !isCurrentUserCollaborator
        ) {
          setIsUserNotAllowedModalOpened(true);
          setLoading(false);
          return;
        }

        const {
          name,
          description,
          interests: defaultInterests,
          isPublic,
          isGlobalCurated,
          imageName,
          imageDirectory,
        } = data.collection;
        setCurrentCollectionData(data.collection);
        setCollectionName(name);
        setSelectedImage(allImages.find((img) => img.name === imageName) || allImages[0]);
        setIsGlobalCuratedByDefault(!!isGlobalCurated);
        let collectionType;
        if (isGlobalCurated) {
          collectionType = allCollectionTypes[2];
        } else {
          collectionType = isPublic ? allCollectionTypes[0] : allCollectionTypes[1];
        }
        setSelectedCollectionType(collectionType);

        description && setDescriptionField(true);
        description && setDescription(description);

        const defaultSelectedInterestOptions: Array<DefaultOption> = defaultInterests.map(
          ({ name }) => mapToOption(name, t(`categories.type.${name}`))
        );

        setSelectedDefaultInterestOptions(defaultSelectedInterestOptions);
        setSelectedInterests(defaultInterests);

        isGlobalCurated && setAllImages([]);

        let defaultImage: IImage | null | undefined;
        switch (imageName) {
          case DEFAULT_COLLECTION_IMAGE_NAME_1:
            defaultImage = defaultImages[0];
            break;
          case DEFAULT_COLLECTION_IMAGE_NAME_2:
            defaultImage = defaultImages[1];
            break;
          case DEFAULT_COLLECTION_IMAGE_NAME_3:
            defaultImage = defaultImages[2];
            break;
        }

        if (!defaultImage) {
          const userImage: IImage = {
            name: imageName,
            label: "Uploaded image",
            image: getStorageImage({
              image: imageName,
              directoryName: imageDirectory,
              type: STORAGE_IMAGE_TYPE_COLLECTION,
            }),
            removeIcon: true,
            checkButton: true,
          };
          setAllImages((prev) => [...prev, userImage]);
          setSelectedImage(userImage);
          setOriginalImageName(userImage.name);
        }

        setLoading(false);

        if (data.collection.collectionType) {
          setCollectionTypeName(data.collection.collectionType);
        }
      }
      if (!data.collection) {
        setIsCollectionNotFound(true);
      }
    },
    onError: (error) => setIsCollectionNotFound(true),
  });

  useEffect(() => {
    if (!collectionTypeName) return;

    if (collectionTypeName === CollectionType.USER_COLLECTION) {
      if (isAdmin) {
        setCollectionTypes(allCollectionTypes);
      } else {
        const sliced = allCollectionTypes.slice(0, 2);
        setCollectionTypes(sliced);
      }
    } else if (collectionTypeName === CollectionType.COMMUNITY_COLLECTION) {
      const communityTypeCollections = [
        {
          id: COLLECTION_TYPES_IDS.PRIVATE_TYPE,
          name: COLLECTION_TYPES.PRIVATE_TYPE,
          value: false,
          label: t("collections.communityCollections.admin"),
          subLabel: t("collections.communityCollections.adminDescription"),
        },
        {
          id: COLLECTION_TYPES_IDS.PUBLIC_TYPE,
          name: COLLECTION_TYPES.PUBLIC_TYPE,
          value: true,
          label: t("collections.communityCollections.community"),
          subLabel: t("collections.communityCollections.communityDescription"),
        },
      ];

      setCollectionTypes(communityTypeCollections);
    }
  }, [collectionTypeName]);

  const editCollectionHandler = () => {
    setIsCreatingCollection(true);
    editCurrentCollection();
  };

  const editCurrentCollection = async () => {
    const controlledCollectionVariable = {
      publicId: params.id,
      name: collectionName.trim(),
      description,
      isPublic: selectedCollectionType.value,
      interests: selectedInterests.map(({ name }) => ({
        name,
      })),
      imageName: fileNameReplaceRegex(selectedImage.name),
    };

    let imageDirectory = null;

    if (
      selectedImage.name === originalImageName &&
      !selectedImage.name.includes("DefaultCollectionImage")
    ) {
      imageDirectory = currentCollectionData?.imageDirectory;
    } else {
      if (selectedImage.img) {
        const fileSize = FileCheckerService.isValidImageSizeWhenCreateUpdate(
          selectedImage.img
        );

        const fileSave = await FileCheckerService.isSafe(selectedImage.img);
        if (!fileSave || !fileSize) {
          setIsCreatingCollection(false);
          return;
        }

        imageDirectory = "";
        try {
          await uploadFiles(selectedImage.img, BlobStorageContainer.IMAGES, {
            onFileUpload: (_, directory) => (imageDirectory = directory),
          });
          // eslint-disable-next-line
        } catch (error: any) {
          displayNotSuccessNotification(error);
        }
      }
    }

    const editCollectionVariables = {
      ...controlledCollectionVariable,
      ...(imageDirectory && { imageDirectory }),
    };
    editCollection({
      variables: {
        editCollection: editCollectionVariables,
      },
    })
      .then(() => {
        displaySuccessNotification(t("collections.editCollection.success"));

        history.goBack();
      })
      .catch((error) => {
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        displayCustomNotSuccessNotification(errorCode);
      })
      .finally(() => setIsCreatingCollection(false));
  };

  useEffect(() => {
    if (interestOptions.length && userData?.users.users.length) {
      getCollection({
        variables: {
          publicId: params.id,
        },
      });
    }
  }, [params.id, interestOptions, userData]);

  const renderImagePickerTitle = (): string => {
    return collectionTypeName === CollectionType.COMMUNITY_COLLECTION
      ? t("collections.addNewCollection.form.communityCollectionImage")
      : t("collections.editCollection.form.collectionImageTitle");
  };

  if (isCollectionNotFound) return <PageNotFound />;

  return (
    <div className={styles.pageWrapper} data-testid="add-new-collection-container">
      {loading ? (
        <Loading borderless />
      ) : currentCollectionData && currentCollectionData.name ? (
        <>
          <h1 className={styles.pageTitle} e2e-test-id="collection-edit-title">
            {currentCollectionData.name}
          </h1>
          <span className={styles.pageSubTitle} e2e-test-id="collection-form-subheader">
            {t("collections.editCollection.description")}
          </span>
          <form className={styles.newCommunityForm}>
            <div className={styles.inputContainer}>
              <InputUpdated
                onChange={handleChangeName}
                name="collectionName"
                value={collectionName}
                e2eTestId="collection-name-input-field"
                maxLength={MAX_INPUT_LENGTH_SHORT}
                label={t("collections.editCollection.form.collectionNameInput")}
                aria-label="collection-name"
                placeholder={t(
                  "collections.editCollection.form.collectionNameInputPlaceholder"
                )}
                additionalElement={
                  !descriptionField && (
                    <ButtonUpdated
                      aria-label="add-description"
                      e2e-test-id="add-collection-description-button"
                      title={t("collections.editCollection.actions.addDescription")}
                      buttonIcon={<PlusOutlined />}
                      buttonSize="small"
                      onClick={() => setDescriptionField(true)}
                    />
                  )
                }
              />
              {descriptionField && (
                <div>
                  <TextareaComponent
                    defaultValue={description}
                    name="description"
                    e2e-test-id="collection-description-input-field"
                    label={t("collections.editCollection.form.descriptionInput")}
                    maxLength={MAX_INPUT_LENGTH_MID}
                    aria-label="description-textarea"
                    onChange={handleChangeDescription}
                  />
                </div>
              )}
            </div>

            <div className={styles.selectWrapper}>
              <SelectComponent
                options={interestOptions}
                defaultSelect={selectedDefaultInterestOptions}
                placeholder={t("collections.editCollection.form.tagsInputPlaceholder")}
                className={"tagsSearch"}
                label={t("collections.editCollection.form.tagsInput")}
                name={"tags"}
                e2eTestId="categories-select-container"
                showDropdown
                selectWithIcon
                icon={SearchIcon}
                isMulti
                onChange={(selectedInterestOptions: DefaultOption[]) =>
                  setSelectedInterests(
                    selectedInterestOptions.map(({ value }) => ({
                      name: value,
                    }))
                  )
                }
                isSearchable
              />
            </div>
            <div
              className={styles.communityTypeWrapper}
              e2e-test-id="collection-type-section"
            >
              <div className={styles.collectionTypeHeading}>
                {t("collections.editCollection.form.collectionTypeRadio")}
              </div>
              {collectionTypes.map(
                (collectionType) =>
                  (isGlobalCuratedByDefault ||
                    collectionType.id !== COLLECTION_TYPES_IDS.GLOBAL_CURATED_TYPE) && (
                    <RadioButton
                      aria-label="communityType"
                      id={collectionType.id}
                      key={collectionType.name}
                      name={collectionType.name}
                      label={collectionType.label}
                      subLabel={collectionType.subLabel}
                      checked={collectionType.id === selectedCollectionType.id}
                      disabled={
                        selectedCollectionType.id ===
                        COLLECTION_TYPES_IDS.GLOBAL_CURATED_TYPE
                      }
                      onChange={() => {
                        setSelectedCollectionType(collectionType);
                      }}
                    />
                  )
              )}
            </div>
            <div e2e-test-id="collection-image-section">
              <ImagePickerWithModal
                title={renderImagePickerTitle()}
                description={
                  isGlobalCuratedByDefault
                    ? t("collections.addNewCollection.form.imageRequiredDescription")
                    : ""
                }
                images={
                  collectionTypeName === CollectionType.USER_COLLECTION
                    ? defaultImages
                    : []
                }
                pickedImage={selectedImage}
                setPickedImage={setSelectedImage}
                isUpload
                isRequired
                uploadButtonId={"selectedImage"}
                isGlobalCurated={isGlobalCuratedByDefault}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                aria-label="create-collection-button"
                buttonType="primary"
                wrapperClassNames={styles.buttonWrapper}
                title={t("collections.editCollection.actions.update")}
                onClick={editCollectionHandler}
                disabled={
                  !collectionName.trim().length ||
                  !selectedInterests.length ||
                  !selectedImage.image ||
                  isCreatingCollection
                }
                loading={isCreatingCollection}
                fullWidth
                e2eTestId="confirm-collection-edit-button"
              />
              <Button
                aria-label="cancelBtn"
                buttonType="link"
                title={t("collections.editCollection.actions.cancel")}
                className={styles.cancelLink}
                disabled={isCreatingCollection}
                onClick={() => history.push("/portal/collections/my-collections")}
                e2eTestId="cancel-collection-edit-button"
              />
            </div>
          </form>
        </>
      ) : (
        <div>
          {isUserNotAllowedModalOpened ? (
            <ConfirmationModal
              isOpen={isUserNotAllowedModalOpened}
              messageTitle={t("collection.collectionAccess.title")}
              messageContent={t("collection.collectionAccess.content")}
              closeModal={() => setIsUserNotAllowedModalOpened(true)}
              goBack={() => history.goBack()}
            />
          ) : (
            <p>No data</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CollectionEdit;
