/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_USER_INFO from "@graphql/queries/getUserInfo";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Avatar from "@images/avatar.svg";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import getStorageImage from "@utils/getStorageImage";
import dayjs from "dayjs";
import { MouseEvent, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { INotification } from "types/Notification";
import { IUser, IUserImageResponse } from "types/user";
import { removeCommunityNotificationAction } from "../../../store/communityNotification/actions";
import styles from "./MessageCard.module.scss";

interface INotificationCardProps {
  notification: INotification;
  className?: string;
  children?: React.ReactElement;
  handleClickCard?: () => void;
  handleLink?: () => void;
  ariaLabel?: string;
}

interface transComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

export const MessageCard = ({
  notification: {
    link: { text: linkText, path },
    links,
    text,
    title,
    titlePath,
    timestamp,
    image,
    id,
    user,
  },
  className,
  children,
  handleClickCard,
  handleLink,
  ariaLabel,
}: INotificationCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userImage, setUserImage] = useState<string>();
  const [transComponents, setTransComponents] = useState<transComponent>({});
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [getUser] = useLazyQuery(GET_USER_INFO, {
    onCompleted: (response) => {
      setSelectedUser(response.userInfo);
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  useEffect(() => {
    if (selectedUserEmail) {
      getUser({ variables: { email: selectedUserEmail } });
    } else {
      setSelectedUser(undefined);
    }
  }, [selectedUserEmail]);

  const checkFunctionForMultipleLinks = (linkPath: string) => () => {
    if (!handleLink) {
      if (linkPath) history.push(linkPath);
      return;
    }

    handleLink?.();
  };

  useEffect(() => {
    const transComponentsHelper: transComponent | null = {};

    if (typeof links !== "undefined" && links.length > 0) {
      links.forEach(function (linkItem, index) {
        transComponentsHelper["link" + index] = (
          <Button
            wrapperClassNames={styles.btn}
            className={styles.linkText}
            title={linkItem.text}
            onClick={checkFunctionForMultipleLinks(linkItem.path as string)}
            aria-label="notificationButton"
          />
        );
      });

      setTransComponents(transComponentsHelper);
    }
  }, []);

  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages[0];
      setUserImage(
        getStorageImage({
          image: userPhotosInfo?.userPhotoName,
          directoryName: userPhotosInfo?.imageDirectory,
          type: STORAGE_IMAGE_TYPE_USER,
        })
      );
    },
  });

  useEffect(() => {
    user &&
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: user?.email,
          },
        },
      });
  }, [user]);

  const checkFunction = () => {
    if (!handleLink) {
      dispatch(removeCommunityNotificationAction(id));
      if (path) history.push(path);
      return;
    }

    handleLink?.();
  };

  const handleUserSelected = (event: MouseEvent) => {
    event.stopPropagation();
    setSelectedUserEmail(user?.email ?? "");
  };

  return (
    <>
      <div
        onClick={handleClickCard}
        role="listitem"
        aria-label={ariaLabel}
        className={`${styles.requestApprovalCard} ${className}`}
      >
        <div className={styles.info}>
          <div className={styles.user}>
            {image && (
              <div className={styles.avatarWrapper} onClick={handleUserSelected}>
                <div
                  aria-label="avatar"
                  style={{ backgroundImage: `url(${userImage || Avatar})` }}
                  className={styles.avatar}
                ></div>
              </div>
            )}
            <div className={styles.title}>
              <span className={styles.name} onClick={handleUserSelected}>
                {title}
              </span>
              <span aria-label="notificationText" className={styles.text}>
                {!links && (
                  <Trans
                    i18nKey={text}
                    components={{
                      link: (
                        <Button
                          wrapperClassNames={styles.btn}
                          className={styles.linkText}
                          title={linkText}
                          onClick={checkFunction}
                          aria-label="notificationButton"
                        />
                      ),
                    }}
                  />
                )}
                {links && <Trans i18nKey={text} components={transComponents} />}
              </span>
            </div>
          </div>
          <div className={styles.time}>{dayjs(new Date(timestamp)).fromNow()}</div>
        </div>
        {children}
      </div>
      {selectedUser && (
        <UserDrawer
          user={selectedUser}
          closeInfoDrawer={() => setSelectedUserEmail("")}
          communityOptions={<></>}
        />
      )}
    </>
  );
};
