/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../store/RootState";

import { ADMIN, DIRECT } from "@customHooks/sendBird/constant";
import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { v4 as uuidv4 } from "uuid";
import { ALL_DISCUSSIONS_URL, SENDBIRD_ID_PREFIX } from "../../../constants/constants";
import { Subscription } from "../../../customContext/subscriptions/enum";
import { useSubscription } from "../../../customHooks/useSubscription";
import GET_USER_COMMUNITIES from "../../../graphql/queries/userCommunities";
import {
  addCommunityNotificationAction,
  removeCommunityNotificationAction,
} from "../../../store/communityNotification/actions";
import { communityNotificationsSelector } from "../../../store/communityNotification/reducer";
import {
  CommunityNotificationType,
  ICommunityNotification,
  NotificationType,
} from "../../../types/Notification";
import { ICommunity } from "../../../types/community";

export const useChatNotification = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const communityNotifications = useSelector(communityNotificationsSelector);
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const { sb } = useSendBirdContext();
  const [communityIDs, setCommunityIDs] = useState<string[]>([]);

  const [getUserCommunities] = useLazyQuery(GET_USER_COMMUNITIES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCommunityIDs(getCommunityIDs(data.user.communities));
    },
    onError: (error) => {
      displayServerError(error);
    },
  });

  const getCommunityIDs = (communities: ICommunity[]) =>
    communities.map((community) => community.sendbirdId);

  const isChannelInCommunity = (channelId: string) => communityIDs.includes(channelId);

  const addNewCommunityId = ([sendBirdId]: Array<string>) => {
    setCommunityIDs((communityIDs) => {
      if (communityIDs.includes(sendBirdId)) return communityIDs;
      return [...communityIDs, sendBirdId];
    });
  };

  useSubscription(Subscription.ADDED_TO_COMMUNITY, addNewCommunityId);
  useSubscription(Subscription.ADDED_TO_EXISTING_COMMUNITY, addNewCommunityId);
  useSubscription(Subscription.COMMUNITY_CREATED, addNewCommunityId);
  useSubscription(Subscription.JOINED_COMMUNITY, addNewCommunityId);
  useSubscription(Subscription.MEMBER_IN_COMMUNITY_PROMOTED, addNewCommunityId);

  useEffect(() => {
    getUserCommunities();
  }, []);

  useEffect(
    () =>
      communityNotifications.forEach(({ id, sendBirdId }) => {
        if (pathname.includes(sendBirdId) && pathname.includes("chat")) {
          dispatch(removeCommunityNotificationAction(id));
        }
      }),
    [pathname, communityNotifications]
  );

  useEffect(() => {
    if (!sb) return;

    const channelHandler = new sb.ChannelHandler();
    sb.addChannelHandler("messageHandler", channelHandler);

    channelHandler.onMessageReceived = (channel, message) => {
      const path = isChannelInCommunity(channel.url)
        ? `/portal/community/community-page/${channel.url}/${ALL_DISCUSSIONS_URL}`
        : `/portal/chat-page/${channel.url.replace(SENDBIRD_ID_PREFIX, "")}`;
      const title = message.messageType !== ADMIN ? message?.sender?.nickname : "";
      const user =
        message.messageType !== ADMIN
          ? {
              email: message?.sender?.userId || "",
              fullName: message?.sender?.nickname || "",
            }
          : { email: "", fullName: "" };
      const text =
        channel.customType === DIRECT
          ? "requests.directChatNotificationText"
          : t("requests.groupChatNotificationText", {
              groupName: channel.name,
            });
      const linkText = t("requests.directChatLink");
      // channel.customType === DIRECT ? t("requests.directChatLink") : `${channel.name}`;

      if (path === pathname || user.email === currentUserEmail) return;

      const communityNotification: ICommunityNotification = {
        id: uuidv4(),
        notificationType: NotificationType.COMMUNITY,
        type: CommunityNotificationType.GENERAL,
        sendBirdId: channel.url,
        user,
        title: title || "",
        text,
        link: {
          text: linkText,
          path,
          onClick: () => {
            history.push(path);
            dispatch(removeCommunityNotificationAction(communityNotification.id));
          },
        },
        timestamp: message.createdAt,
        isRead: false,
        // TODO: Will be updated when user images are uploaded
        image: "IMAGE",
      };

      dispatch(addCommunityNotificationAction(communityNotification));
    };
  }, [sb, communityIDs, pathname, currentUserEmail]);
};
