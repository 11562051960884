import {
  displayErrorNotification,
  displayNotSuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { checkIfDuplicatedProperty } from "@utils/array/helper";
import i18n from "i18next";
import { FileErrorKeyToTranslation, supportedImageExtensions } from "./constants";
import { FileErrorKey } from "./enum";
import { FileHelper } from "./fileHelper";

const fileTypeSignatures: Map<string, Array<number>> = new Map<string, Array<number>>([
  ["jpg", [0xff, 0xd8, 0xff]],
  ["jpeg", [0xff, 0xd8, 0xff, 0xe0]],
  ["bmp", [0x42, 0x4d]],
  ["jpf", [0xff, 0x4f, 0xff, 0x51]],
  ["png", [0x89, 0x50, 0x4e, 0x47]],
  ["gif", [0x47, 0x49, 0x46, 0x38]],
  ["eps", [0xc5, 0xd0, 0xd3, 0xc6]],
  ["pdf", [0x25, 0x50, 0x44, 0x46]],
  ["tiff", [0x49, 0x20, 0x49]],
  ["ico", [0x00, 0x00, 0x01, 0x00]],
  ["avi", [0x52, 0x49, 0x46, 0x46]],
  ["flv", [0x46, 0x4c, 0x56]],
  ["mkv", [0x1a, 0x45, 0xdf, 0xa3]],
  ["wmv", [0x30, 0x26, 0xb2, 0x75]],
  ["mp3", [0x49, 0x44, 0x33]],
  ["wav", [0x52, 0x49, 0x46, 0x46]],
  ["ogg", [0x4f, 0x67, 0x67, 0x53]],
  ["wma", [0x30, 0x26, 0xb2, 0x75]],
  ["flac", [0x66, 0x4c, 0x61, 0x43]],
  ["aiff", [0x46, 0x4f, 0x52, 0x4d]],
  ["doc", [0xd0, 0xcf, 0x11, 0xe0]],
  ["docx", [0x50, 0x4b, 0x03, 0x04]],
  ["odt", [0x50, 0x4b, 0x03, 0x04]],
  ["rtf", [0x7b, 0x5c, 0x72, 0x74]],
  ["xls", [0xd0, 0xcf, 0x11, 0xe0]],
  ["xlsx", [0x50, 0x4b, 0x03, 0x04]],
  ["ppt", [0xd0, 0xcf, 0x11, 0xe0]],
  ["pptx", [0x50, 0x4b, 0x03, 0x04]],
  ["log", [0x2a, 0x2a, 0x2a, 0x20]],
  ["xml", [0x3c, 0x3f, 0x78, 0x6d]],
  ["odp", [0x50, 0x4b, 0x03, 0x04]],
  ["zip", [0x50, 0x4b, 0x03, 0x04]],
  ["ai", [0x25, 0x50, 0x44, 0x46]],
  ["mp4", [0x00, 0x00, 0x00]],
  ["mov", [0x4b, 0x41, 0x4d, 0x76]],
  ["mpeg", [0x00, 0x00, 0x01, 0xba]],
  ["webp", [0x52, 0x49, 0x46]],
  ["webm", [0x1a, 0x45, 0xdf, 0xa3]],
  ["xlsm", [0x50, 0x4b, 0x03, 0x04]],
  ["ods", [0x50, 0x4b, 0x03, 0x04]],
  ["svg", [0x3c, 0x73, 0x76, 0x67]],
]);
const BYTES_TO_CHECK = 4;

const hexToString = (hex: number) => {
  return hex.toString(16).padStart(2, "0");
};

export class FileChecker {
  private static typeSignatureToHexString(fileType: string) {
    const signatureRaw: number[] = fileTypeSignatures.get(fileType) || [];
    return signatureRaw.reduce(
      (acc: string, curr: number) => acc + hexToString(curr),
      ""
    );
  }

  public static isSafeCheck(fileBlob: Blob): Promise<boolean> {
    return new Promise((resolve) => {
      const fr = new FileReader();

      fr.onloadend = (e) => {
        const arrBuff = e.target?.result;
        if (!(arrBuff instanceof ArrayBuffer)) return;

        const buffer = new Uint8Array(arrBuff).subarray(0, BYTES_TO_CHECK);
        let header = "";
        for (let i = 0; i < buffer.length; i++) {
          header += hexToString(buffer[i]);
        }

        const result = Array.from(fileTypeSignatures.keys()).reduce(
          (passedTest, fileType) => {
            const fileHeader = FileChecker.typeSignatureToHexString(fileType);
            return passedTest || header.includes(fileHeader);
          },
          false
        );

        resolve(result);
      };

      fr.readAsArrayBuffer(fileBlob);
    });
  }

  public static isValidSize(file: File) {
    const isValid = FileHelper.isValidSize(file);
    if (!isValid) {
      const message = i18n.t("event.newEvent.errors.fileSize", {
        fileName: file.name,
      });
      displayNotSuccessNotification(message, message);
    }
    return isValid;
  }

  public static hasDuplicateFileName(files: File[], selectedFiles: File[]) {
    const hasDuplicate = checkIfDuplicatedProperty([...files, ...selectedFiles], "name");

    if (hasDuplicate) {
      return displayErrorNotification(
        i18n.t(FileErrorKeyToTranslation.get(FileErrorKey.DUPLICATE) as string)
      );
    }

    return hasDuplicate;
  }

  public static isValidImageSizeWhenCreateUpdate(file: File) {
    const isValid = FileHelper.isValidImageSizeWhenCreateUpdate(file);
    if (!isValid) {
      const message = i18n.t("event.newEvent.errors.fileSizeCreateUpdate");
      displayNotSuccessNotification(message, message);
    }
    return isValid;
  }

  public static async isSafe(file: File) {
    const isValid = await FileChecker.isSafeCheck(file);
    if (!isValid) {
      displayNotSuccessNotification(
        undefined,
        i18n.t("event.newEvent.errors.unsafeFile", { fileName: file.name })
      );
    }
    return isValid;
  }

  public static isSupported(file: File, supportedExtensions: Array<string>) {
    const extension = `.${FileHelper.getExtensionForFileChecker(file).toLowerCase()}`;
    const isValid = supportedExtensions.some((ext) => ext.toLowerCase() === extension);
    if (!isValid) {
      displayNotSuccessNotification(
        undefined,
        i18n.t("event.newEvent.errors.unsupportedFile", { fileName: file.name })
      );
    }
    return isValid;
  }

  public static async isUploadable(file: File, supportedExtensions: Array<string>) {
    return (
      this.isSupported(file, supportedExtensions) &&
      (await this.isSafe(file)) &&
      this.isValidSize(file)
    );
  }

  public static async areUploadable(files: File[], supportedExtensions: Array<string>) {
    return (
      await Promise.all(
        files.map(async (file) => await this.isUploadable(file, supportedExtensions))
      )
    ).every(Boolean);
  }

  public static async isImageUploadable(file: File) {
    return this.isUploadable(file, supportedImageExtensions);
  }
}

export default FileChecker;
