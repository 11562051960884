import { replaceClassIfFoundInStyles } from "@utils/classNamesHelpers";

import { ITextAreaStyle } from "./type";

export const getTextAreaStyle = (
  styles: { [className: string]: string },
  textAreaClassName?: ITextAreaStyle
) => {
  let className = styles.textarea;

  if (textAreaClassName?.hasBorder) {
    className = `${className} ${replaceClassIfFoundInStyles("border", styles)}`;
  }

  return className;
};
