export const COLLECTIONS_PLACEHOLDER = {
  publicId: "",
  __typename: "",
  name: "placeholder",
  description: "",
  isPublic: true,
  interests: [],
  collaboratorsCount: 0,
  articlesCount: 0,
  updateTime: 0,
  creationTime: 0,
  imageName: "",
  imageDirectory: "",
  activitiesCount: 0,
  collectionCreator: {
    publicId: "",
    fullName: "",
    organization: {
      name: "",
      userRole: "",
    },
  },
};
