import { useEffect, useState } from "react";
import { ISearchMessageId, ISearchMessages } from "types/SearchMessage";

export const MINIMUM_REQUIRED_CHARACTERS_FOR_SEARCH = 3;

enum DIRECTION {
  NEXT,
  PREVIOUS,
}

export const useSearchMessages = (): ISearchMessages => {
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [searchMessageIds, setSearchMessageIds] = useState<Array<ISearchMessageId>>([]);
  const [currentSearchMessageId, setCurrentSearchMessageId] =
    useState<ISearchMessageId | null>(null);

  const isMessageIdEqualToCurrentSearchMessageId = ({
    messageId,
    numberOfOccurrence,
  }: ISearchMessageId) => {
    return (
      messageId === currentSearchMessageId?.messageId &&
      numberOfOccurrence === currentSearchMessageId?.numberOfOccurrence
    );
  };

  const getIndexOfCurrentSearchMessageId = () => {
    const indexOfCurrentSelectedMessageId = searchMessageIds.findIndex(
      isMessageIdEqualToCurrentSearchMessageId
    );

    return indexOfCurrentSelectedMessageId;
  };

  const goToNextSearchMessage = () => {
    goToSearchMessage(DIRECTION.NEXT);
  };

  const goToPreviousSearchMessage = () => {
    goToSearchMessage(DIRECTION.PREVIOUS);
  };

  const goToSearchMessage = (direction: DIRECTION) => {
    const getIndexOfMessageIdToBeSelected = () => {
      if (direction === DIRECTION.PREVIOUS) {
        return indexOfCurrentSelectedMessageId - 1;
      }

      return indexOfCurrentSelectedMessageId + 1;
    };

    const goToMessage = () => {
      const indexOfMessageIdToBeSelected = getIndexOfMessageIdToBeSelected();

      if (indexOfMessageIdToBeSelected === -1) {
        setCurrentSearchMessageId(searchMessageIds[searchMessageIds.length - 1]);
        return;
      }

      if (indexOfMessageIdToBeSelected === searchMessageIds.length) {
        setCurrentSearchMessageId(searchMessageIds[0]);
        return;
      }

      setCurrentSearchMessageId(searchMessageIds[indexOfMessageIdToBeSelected]);
    };

    const indexOfCurrentSelectedMessageId = getIndexOfCurrentSearchMessageId();
    if (indexOfCurrentSelectedMessageId === -1) {
      return;
    }

    goToMessage();
  };

  const clearSearchMessageIds = () => setSearchMessageIds([]);

  const addSearchMessageId = (searchMessageId: ISearchMessageId) => {
    setSearchMessageIds((messageIds) => [searchMessageId, ...messageIds]);
  };

  const updateCurrentSearchQuery = (value: string) => {
    clearSearchMessageIds();

    if (value.length >= MINIMUM_REQUIRED_CHARACTERS_FOR_SEARCH) {
      setCurrentSearchQuery(value);
      return;
    }

    setCurrentSearchQuery("");
  };

  useEffect(() => {
    const resetCurrentSearchMessageId = () => {
      if (!searchMessageIds.length) {
        setCurrentSearchMessageId(null);
        return;
      }

      setCurrentSearchMessageId(searchMessageIds[0]);
    };

    resetCurrentSearchMessageId();
  }, [searchMessageIds]);

  return {
    controls: {
      goToNextSearchMessage,
      goToPreviousSearchMessage,
    },
    state: {
      currentSearchQuery,
      currentSearchMessageId,
      searchMessageIds,
    },
    actions: {
      addSearchMessageId,
      updateCurrentSearchQuery,
      getIndexOfCurrentSearchMessageId,
    },
    helper: {
      isMessageIdEqualToCurrentSearchMessageId,
    },
  };
};
