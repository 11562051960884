import { ApolloError } from "@apollo/client";

import i18n from "i18next";
import { toast } from "react-toastify";
import { SendBirdError } from "sendbird";
import { appInsights } from "../../AppInsights";
import {
  arrayLengthSame,
  arraysHaveSameValues,
  getCustomNotificationByCode,
  objectsHaveSameValues,
} from "./NotificationHelper";

// Generic server error message
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const displayServerError = (error?: ApolloError | SendBirdError | any) => {
  if (error) {
    appInsights.trackException({ error, severityLevel: 0 });
  }
  // This is commented out because we do not want to show internal server error toast anymore
  // return toast.error(i18n.t("notifications.error.serverError"));
  return;
};

// Not success error message
export const displayNotSuccessNotification = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: ApolloError | SendBirdError | any,
  message?: string
) => {
  if (error) {
    appInsights.trackException({ error, severityLevel: 0 });
  }

  return toast.error(message || i18n.t("notifications.error.notSuccessful"));
};

// Success with green background
export const displaySuccessNotification = (message?: string) => {
  return toast.success(message || i18n.t("notifications.success.saved"));
};

// Success with red background
export const displayErrorNotification = (message: string) => {
  return toast.error(message);
};

// Notification with custom error code from BE
export const displayCustomNotSuccessNotification = (code: string) => {
  const message = getCustomNotificationByCode(code);

  if (message) {
    return toast.error(message);
  }
  // This is commented out because we do not want to show internal server error toast anymore
  //  else {
  //   return toast.error(i18n.t("notifications.error.serverError"));
  // }
};

export const determineNotificationByResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  original: { [key: string]: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: { [key: string]: any }
) => {
  return displayMessageBySameValue(objectsHaveSameValues(original, response));
};

export const determineNotificationByResponseArray = (
  original: Array<string>,
  response: Array<string>
) => {
  const sameLength: boolean = arrayLengthSame(original, response);
  return displayMessageBySameValue(
    sameLength && arraysHaveSameValues(original, response)
  );
};

export const displayMessageBySameValue = (sameValues: boolean) => {
  return sameValues
    ? displayNotSuccessNotification() && false
    : displaySuccessNotification() && true;
};
