import { gql } from "@apollo/client";

const UPDATE_USER_LOCATIONS = gql`
  mutation updateUserLocationsOfInterest($user: UpdateUserInputType!) {
    updateUserLocationsOfInterest(user: $user) {
      countries {
        name
      }
    }
  }
`;

export default UPDATE_USER_LOCATIONS;
