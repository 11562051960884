export const SET_CLAIMS = "SET_CLAIMS";
export const SET_USER_PUBLIC_ID = "SET_USER_PUBLIC_ID";
export const SET_USER_IMAGE = "SET_USER_IMAGE";
export const SET_USER = "SET_USER";
export const SET_USER_UNREAD_NOTIFICATIONS_COUNT = "SET_USER_UNREAD_NOTIFICATIONS_COUNT";

export const CLAIM_TYPES = {
  USER_CREATE: "user:create",
  USER_READ: "user:read",
  USER_UPDATE: "user:update",
  USER_DELETE: "user:delete",
  COMMUNITY_CREATE: "community:create",
  COMMUNITY_UPDATE: "community:update",
  COMMUNITY_DELETE: "community:delete",
  EVENT_CREATE: "event:create",
  EVENT_UPDATE: "event:update",
  EVENT_DELETE: "event:delete",
  GROUP_CHAT_CREATE: "group_chat:create",
  GROUP_CHAT_UPDATE: "group_chat:update",
  GROUP_CHAT_DELETE: "group_chat:delete",
  DIRECT_CHAT_CREATE: "direct_chat:create",
  DIRECT_CHAT_UPDATE: "direct_chat:update",
  DIRECT_CHAT_DELETE: "direct_chat:delete",
  COLLECTIONS_CREATE: "collections:create",
  COLLECTIONS_READ: "collections:read",
  COLLECTIONS_UPDATE: "collections:update",
  COLLECTIONS_DELETE: "collections:delete",
  FEEDBACK_CREATE: "feedback:create",
  FEEDBACK_READ: "feedback:read",
  FEEDBACK_UPDATE: "feedback:update",
  FEEDBACK_DELETE: "feedback:delete",
  ARTICLE_DELETE: "article:delete",
  ARTICLE_SOURCING_DELETE: "article_sourcing:delete",
  DEVICE_TOKEN_CREATE: "device_token:create",
};
