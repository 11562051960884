import { IOption } from "@components/formik/SelectUpdated/SelectUpdated";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";

interface IUseGetTranslatedOptions<T> {
  options: Array<IOption<T>>;
}

export const useGetTranslatedOptions = <T>({ options }: IUseGetTranslatedOptions<T>) => {
  const { t } = useTranslation();

  const optionsTranslated = useMemo(
    () => options.map(({ value, label }) => ({ value, label: t(label) })),
    [options, t]
  );

  return optionsTranslated;
};
