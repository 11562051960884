/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import MapChart from "@components/Map/MapChart";
import Pills from "@components/Pills/Pills";
import SelectComponent from "@components/Select/Select";

import UPDATE_USER_LOCATIONS from "@graphql/mutations/updateUserLocations";

import LocationService from "@services/LocationService";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { mapToOption } from "@utils/selectHelper";

import { ICountry, ILocation } from "types/location";
import { DefaultOption } from "types/select";
import { IUser } from "types/user";

import SearchIcon from "@images/search_icon.svg";

import { GLOBAL_LOCATION } from "@constants/constants";
import styles from "./SettingsLocations.module.scss";

interface ISettingsLocationsProps {
  allLocations?: Array<ILocation>;
  userLocations?: Array<ILocation>;
  countriesData?: { countries: Array<ICountry> };
  globalInterestLocation: boolean;
  setUserData: (user: IUser) => void;
  originalUserData?: IUser;
  refetchUserData: () => void;
}

const SettingsLocations = ({
  allLocations,
  userLocations,
  globalInterestLocation,
  countriesData,
  setUserData,
  refetchUserData,
}: ISettingsLocationsProps) => {
  const [updateUser] = useMutation(UPDATE_USER_LOCATIONS);
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectDirty, setSelectDirty] = useState(false);
  const [locations, setLocations] = useState<Array<ILocation>>([]);
  const [selectedLocations, setSelectedLocations] = useState<Array<ILocation>>([]);
  const [newSelectedLocations, setNewSelectedLocations] = useState<Array<ILocation>>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  useEffect(() => {
    if (!allLocations || !userLocations) return;

    setLocations(
      LocationService.filterSelectedLocationsFromAll(allLocations, userLocations)
    );
  }, [allLocations, userLocations]);

  useEffect(() => {
    if (!userLocations?.length || selectedLocations.length || selectDirty) return;

    setSelectedLocations(userLocations);
    setNewSelectedLocations([]);
  }, [locations, userLocations]);

  useEffect(() => {
    if (!allLocations || !selectedLocations.length) return;
    const filteredLocations = LocationService.filterSelectedLocationsFromAll(
      allLocations,
      selectedLocations
    );
    setLocations(filteredLocations);
  }, [selectedLocations]);

  const handleChangeLocation = (selectedLocationsInput: Array<ILocation>) => {
    if (!selectDirty) {
      setSelectDirty(true);
    }

    setSelectedLocations([...selectedLocations, ...selectedLocationsInput]);
    setNewSelectedLocations([]);
  };

  const saveButtonValidation = () => {
    if (!selectedLocations.length || disableSaveButton) return true;
  };

  const onSave = () => {
    if (!selectedLocations || !countriesData || !countriesData.countries) return;
    setDisableSaveButton(true);

    // Filter is added because we can't send undefined value and it needs to be removed,
    // but we are sending globalInterestLocation: {} because the backend accepts it like that
    const selectedCountries = LocationService.extractStatesFromSelectedLocations(
      selectedLocations,
      countriesData.countries
    ).filter(Boolean);

    const isGlobalSelected = selectedLocations.some(
      (location) => location.name === GLOBAL_LOCATION
    );

    const mutationToExecute = () => {
      if (!isGlobalSelected) {
        return updateUser({
          variables: {
            user: {
              countries: selectedCountries,
            },
          },
        });
      } else {
        return updateUser({
          variables: {
            user: {
              countries: selectedCountries,
              globalInterestLocation: {},
            },
          },
        });
      }
    };

    mutationToExecute()
      .then((response) => {
        if (!response?.data?.updateUserLocationsOfInterest) {
          displayNotSuccessNotification();
          return;
        }

        displaySuccessNotification();
        setUserData(response.data.updateUserLocationsOfInterest);
        setIsEditMode(false);
        setNewSelectedLocations([]);
        refetchUserData();
      })
      .catch(() => {
        displayServerError();
      });
  };

  const markCountryOnMap = () => {
    const isGlobalSelected = selectedLocations.some(
      (location) => location.name === GLOBAL_LOCATION
    );

    if (allLocations && isGlobalSelected) {
      return LocationService.mapCountryDataForMap(allLocations);
    } else {
      return LocationService.mapCountryDataForMap(selectedLocations);
    }
  };

  const mapForSelect = (locations: Array<ILocation>) => {
    const translatedMap = LocationService.mapLocationForSelect(locations)?.map(
      (location) => {
        return { ...location, label: t(`countries.${location.value}`) };
      }
    );

    return translatedMap;
  };

  const handleRemoveLocationSelection = (location: DefaultOption) => {
    if (!isEditMode) return;
    const result = selectedLocations.filter(({ name }) => name !== location.value);
    setSelectedLocations(result);
    setDisableSaveButton(false);
  };

  return (
    <div className={styles.container} e2e-test-id="locations-container">
      <div className={styles.headingContainer}>
        <span className={styles.title} e2e-test-id="locations-title">
          {t("accountSettings.locations.heading")}
        </span>
      </div>
      <div className={styles.locationsHeadingContainer}>
        <span className={styles.locationsHeading}>
          {t("accountSettings.locations.subheading")}
        </span>
        {userLocations && !isEditMode && (
          <Button
            data-testid="edit-btn"
            buttonType="link"
            e2eTestId="update-locations-link"
            wrapperClassNames={styles.locationsHeadingButton}
            title={t("accountSettings.locations.button.update")}
            onClick={() => {
              !isEditMode && setDisableSaveButton(true);
              setIsEditMode(!isEditMode);
            }}
          />
        )}
      </div>
      {userLocations ? (
        <>
          <div
            className={styles.locationsContainer}
            e2e-test-id="locations-pills-container"
          >
            <Pills
              pills={selectedLocations.map(({ name }) =>
                mapToOption(name, t(`countries.${name}`))
              )}
              onPillClick={handleRemoveLocationSelection}
              showCloseButton={isEditMode}
              isPillClickDisabled={!isEditMode}
            />
          </div>
          {isEditMode && (
            <div>
              <SelectComponent
                showDropdown={true}
                options={mapForSelect(locations)}
                placeholder={t("locations.placeholder")}
                name="country"
                onChange={(value: Array<ILocation>) => {
                  setDisableSaveButton(false);
                  handleChangeLocation(value);
                  setNewSelectedLocations([]);
                }}
                value={mapForSelect(newSelectedLocations)}
                isMulti
                isSearchable
                virtualized
                selectWithIcon
                icon={SearchIcon}
              />
              <div className={styles.buttonsContainer}>
                <Button
                  type="submit"
                  aria-label="save-btn"
                  title={t("accountSettings.locations.button.save")}
                  buttonType="primary"
                  onClick={onSave}
                  disabled={saveButtonValidation()}
                />
                <Button
                  data-testid="edit-btn"
                  buttonType="link"
                  wrapperClassNames={styles.locationsHeadingButton}
                  title={t("accountSettings.locations.button.cancel")}
                  onClick={() => {
                    isEditMode && setSelectedLocations(userLocations);
                    setIsEditMode(!isEditMode);
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.mapStyles} e2e-test-id="map-container">
            <MapChart
              selectedCountry={markCountryOnMap()}
              className={styles.mapStyles}
              projectionConfig={{ scale: undefined }}
            />
          </div>
        </>
      ) : (
        <Loading borderless disableBoxShadow />
      )}
    </div>
  );
};

export default SettingsLocations;
