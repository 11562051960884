import Button from "@components/Button/Button";
import { BUTTON_URL_LIMIT } from "@constants/constants";
import { ReactNode } from "react";
import Linkify from "react-linkify";
import styles from "./LinkifyMessage.module.scss";

interface ILinkifyMessageProps {
  messageToRender: string | ReactNode;
  openModal: () => void;
  setClickedUrl: (url: string) => void;
  buttonWrapperClass?: string;
}

const LinkifyMessage = ({
  messageToRender,
  openModal,
  setClickedUrl,
  buttonWrapperClass,
}: ILinkifyMessageProps) => {
  const handleUrlClick = (url: string) => {
    url.includes(window.location.hostname)
      ? (window.location.href = url)
      : handleExternalLinkClick(url);
  };

  const handleExternalLinkClick = (url: string) => {
    openModal();
    setClickedUrl(url);
  };

  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <Button
          title={decoratedText}
          buttonLengthLimit={BUTTON_URL_LIMIT}
          key={key}
          buttonType="link"
          wrapperClassNames={buttonWrapperClass}
          className={styles.link}
          onClick={(e) => {
            e.stopPropagation();
            handleUrlClick(decoratedHref);
          }}
        />
      )}
    >
      {messageToRender}
    </Linkify>
  );
};

export default LinkifyMessage;
