import { FileTwoTone } from "@ant-design/icons";
import DownloadIcon from "@images/downloadIcon.svg";
import removeFiles from "@images/removAttachment.svg";
import pallete from "@scss/exports.module.scss";
import { usePredefinedClassIfFound } from "@utils/classNamesHelpers";
import Button from "../Button/Button";

import styles from "./AttachmentCard.module.scss";

export interface IAttachmentCardProps {
  name: string;
  size: number;
  removeFile?: () => void;
  attachmentEvent?: boolean;
  downloadAttachment?: () => void;
  wrapperClassNames?: string;
}

const AttachmentCard = ({
  name,
  size,
  removeFile,
  attachmentEvent,
  wrapperClassNames,
  downloadAttachment,
}: IAttachmentCardProps) => {
  return (
    <div
      className={`${styles.container} ${usePredefinedClassIfFound(
        wrapperClassNames,
        styles
      )}`}
      aria-label="container"
    >
      {!attachmentEvent && (
        <img
          className={styles.removeIcon}
          src={removeFiles}
          onClick={() => removeFile && removeFile()}
          alt=""
          aria-label="img"
        />
      )}
      <div className={styles.row}>
        <FileTwoTone
          twoToneColor={pallete.primaryBlue}
          className={styles.universalFileIcon}
        />
        <div className={styles.fileContainer}>
          <span className={styles.fileName} aria-label="name">
            {name}
          </span>
          <span className={styles.size} aria-label="size">
            {size}kb
          </span>
        </div>
      </div>
      {attachmentEvent && (
        <Button
          title=""
          onClick={downloadAttachment}
          buttonShape="circle"
          buttonIcon={<img src={DownloadIcon} alt="" className={styles.downloadImage} />}
          aria-label="button"
        />
      )}
    </div>
  );
};

export default AttachmentCard;
