import { IGetClassName } from "./type";

export const getClassName = ({ isReadMore, isStyled, styles }: IGetClassName) => {
  let className = styles.description;

  if (!isReadMore) {
    return className;
  }

  className = `${className} ${styles.trimmed}`;
  if (isReadMore && isStyled) {
    return `${className} ${styles.shadow}`;
  }

  return className;
};
