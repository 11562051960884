import { CustomTypeToClassName, TextSizeToClassName, TypeToClassName } from "./constant";
import { IClassName } from "./type";

export const getClassName = ({
  buttonType,
  hasBorder = true,
  styles,
  className,
  customButtonType,
  textSize = "md",
}: IClassName) => {
  let name = buttonType ? `${TypeToClassName[buttonType]}` : styles.hiveButton;

  if (customButtonType) {
    name = `${name} ${CustomTypeToClassName[customButtonType]}`;
  }

  if (textSize) {
    name = `${name} ${TextSizeToClassName[textSize]}`;
  }

  if (className) {
    name = `${name} ${className}`;
  }

  if (!hasBorder) {
    name = `${name} ${styles.noBorder}`;
  }

  return name;
};
