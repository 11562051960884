import { getChatPrivacy } from "@modules/AccountSettings/SettingsProfileDetails/constants";

import { ProfileDetails } from "store/onboarding/types";
import { initialValues } from "./constant";

export const getInitialValues = (
  profileDetails: ProfileDetails,
  canReceiveMessages: boolean
) => {
  return {
    workLocation: profileDetails.workLocation?.name || initialValues.workLocation,
    bio: profileDetails.bio || initialValues.bio,
    organizationName: profileDetails.organizationName || initialValues.organizationName,
    organizationRole: profileDetails.organizationRole || initialValues.organizationRole,
    sector: profileDetails.sector,
    chat: getChatPrivacy(canReceiveMessages),
  };
};
