export const MAX_MESSAGES_LIMIT = 100;
export const DIRECT = "direct";
export const ADMIN = "admin";
export const FILE = "file";
export const MEMBER_COUNT_IN_DIRECT_CHAT = 2;
export const GROUP_TYPE = "nonsuper";
export const MAX_LIMIT = 100;

export const ERROR_CODE = {
  CHANNEL_NOT_FOUND: {
    code: 400201,
    translationKey: "sendBird.errorCode.channelNotFound",
  },
  BLOCKED_USER_ERROR_CODE: {
    code: 400701,
    translationKey: "notifications.error.blockedUserError",
  },
};
