import { gql } from "@apollo/client";

const RENAME_FILE_IN_COLLECTION = gql`
  mutation renameFile($fileRename: RenameFileInputType!) {
    renameFile(fileRename: $fileRename) {
      name
      publicId
    }
  }
`;

export default RENAME_FILE_IN_COLLECTION;
