import { gql } from "@apollo/client";

const REMOVE_COLLABORATOR = gql`
  mutation removeCollaborator(
    $removeCollectionCollaborator: RemoveCollectionCollaboratorInputType!
  ) {
    removeCollaborator(removeCollectionCollaborator: $removeCollectionCollaborator)
  }
`;

export default REMOVE_COLLABORATOR;
