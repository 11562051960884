import styles from "./EmptyComponent.module.scss";

type EmptyComponentProps = {
  title: string;
  subTitle: string;
  image: string;
  extraContent?: React.ReactNode;
  customSubTitle?: React.ReactNode;
};

const EmptyComponent = ({
  title,
  subTitle,
  image,
  customSubTitle,
  extraContent,
}: EmptyComponentProps) => {
  return (
    <div className={styles.outer} aria-label="wrapper">
      <img aria-label="image" className={styles.image} src={image} alt="" />
      <h2 aria-label="title" className={styles.title}>
        {title}
      </h2>

      {customSubTitle ? (
        <p aria-label="custom-subtitle" className={styles.subTitle}>
          {customSubTitle}
        </p>
      ) : (
        <p aria-label="main-subtitle" className={styles.subTitle}>
          {subTitle}
        </p>
      )}
      {extraContent && (
        <div className={styles.extraContent} aria-label="extra-content">
          {extraContent}
        </div>
      )}
    </div>
  );
};

export default EmptyComponent;
