type UserDrawer = {
  GENERAL: "GENERAL";
  EVENTS: "EVENTS";
  COLLECTIONS: "COLLECTIONS";
  COMMUNITIES: "COMMUNITIES";
};

export type UserDrawerEnum = keyof UserDrawer;

export const UserDrawerType: Record<UserDrawerEnum, UserDrawerEnum> = {
  GENERAL: "GENERAL",
  EVENTS: "EVENTS",
  COLLECTIONS: "COLLECTIONS",
  COMMUNITIES: "COMMUNITIES",
};
