/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/Button/Button";
import UploadIcon from "@images/upload.svg";
import UploadImagePlaceholder from "@images/uploadImagePlaceholder.svg";
import { FileHelper } from "@utils/files/fileHelper";
import { useEffect, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Trans } from "react-i18next";
import styles from "./DragAndDropUpload.module.scss";

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface DragAndDropUploadProps {
  setImageFunction: (file: File) => void;
  type: "image" | "attachments";
}

const DragAndDropUpload = ({ setImageFunction, type }: DragAndDropUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const isImageChoosen = () => type === "image" && acceptedFiles.length === 1;

  useEffect(() => {
    isImageChoosen() && setImageFunction(acceptedFiles[0]);
  }, [acceptedFiles]);

  return (
    <div className={styles.DragAndDropUpload} data-testid="DragAndDropUpload">
      <div
        {...getRootProps({
          className: ["dropzone", styles.frame].join(" "),
          style,
        })}
      >
        <input ref={inputRef} {...getInputProps()} />
        <img className={styles.uploadIcon} src={UploadIcon} alt="Upload Icon" />
        <span className={styles.info}>
          <Trans
            i18nKey={"Drag files to upload or <link></link> your files"}
            components={{
              link: (
                <Button
                  buttonType="link"
                  wrapperClassNames={styles.btn}
                  className={styles.linkText}
                  title={"browse"}
                  onClick={() => inputRef.current?.click()}
                  aria-label="notificationButton"
                />
              ),
            }}
          />
        </span>
      </div>
      {isImageChoosen() && (
        <div className={styles.uploadedImagesWrapper}>
          <img className={styles.uploadImagePlaceholder} src={UploadImagePlaceholder} />
          <div className={styles.imageInfoWrapper}>
            <h6 className={[styles.imageInfoText, styles.imageName].join(" ")}>
              {acceptedFiles[0].name}
            </h6>
            <h6 className={styles.imageInfoText}>
              {FileHelper.getSizeInKB(acceptedFiles[0].size)} KB
            </h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDropUpload;
