import Loading from "@components/Loading/Loading";
import ShiftButtons from "@components/ShiftButtons/ShiftButtons";
import Slider from "@components/Slider/Slider";
import CollectionsIcon from "@images/FolderIcon.svg";
import { disableNext, getUserRole } from "@utils/helpers";
import styles from "./ResourcesCollectionsSection.module.scss";

import CollectionsCard from "@components/CollectionsCard/CollectionsCard";
import { IOption } from "@components/Menu/types";
import { NO_COLLECTIONS_MESSAGES } from "@constants/constants";
import { ICollection } from "@graphql/collection/type";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SlickSlider from "react-slick";
import { RootState } from "../../store/store";
import { isDocumentRtl } from "@utils/checkDocumentDir";

const { noCollections, noSearchedCollections } = NO_COLLECTIONS_MESSAGES;

export interface IResourcesCollectionsSection {
  collections: Array<ICollection>;
  collectionsCount: number;
  collectionsLoading: boolean;
  loadCollections: () => void;
  hideSeeAllButton?: boolean;
  customHeaderTitle?: string;
  pageSize: number;
  searchResourcesSubmitted?: boolean;
  menuDots: (collectin: ICollection) => IOption[];
}

const ResourcesCollectionsSection = ({
  collections,
  collectionsCount,
  collectionsLoading,
  loadCollections,
  hideSeeAllButton,
  customHeaderTitle,
  pageSize,
  searchResourcesSubmitted,
  menuDots,
}: IResourcesCollectionsSection) => {
  const collectionsChildRef = useRef<SlickSlider>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [sliderPage, setSliderPage] = useState<number>(0);
  const [noCollectionsMessage, setNoCollectionsMessage] = useState<string>("");

  const userPublicId = useSelector<RootState, string>((state) => state.user.userPublicId);

  const translatedRoles: string[] = [
    t("collections.roles.owner"),
    t("collections.roles.follower"),
    t("collections.roles.collaborator"),
  ];

  const openAllCollections = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.includes("/explore")
      ? "/portal/collections/explore/view-more"
      : "/portal/resources/collections";
    history.push(newPath);
  };

  const handlePreviousCollections = () => {
    isDocumentRtl()
      ? collectionsChildRef.current?.slickNext()
      : collectionsChildRef.current?.slickPrev();

    setSliderPage(sliderPage - 1);
  };

  const handleNextCollections = () => {
    isDocumentRtl()
      ? collectionsChildRef.current?.slickPrev()
      : collectionsChildRef.current?.slickNext();
    setSliderPage(sliderPage + 1);
    if (collections.length >= collectionsCount) return;
    loadCollections();
  };

  const disableSliderNext = () => {
    return collectionsLoading || disableNext(sliderPage, collectionsCount, pageSize);
  };

  const disableSliderPrevious = () => {
    return collectionsLoading || sliderPage === 0;
  };

  useEffect(() => {
    setNoCollectionsMessage(
      searchResourcesSubmitted ? noSearchedCollections : noCollections
    );
  }, [searchResourcesSubmitted]);

  return (
    <div
      className={styles.collectionsContainer}
      data-testid="rs-collection-container"
      e2e-test-id="collections-container"
    >
      <div className={styles.collectionsHeading}>
        <div className={styles.collectionsHeadingWrapper}>
          {!customHeaderTitle && (
            <img className={styles.collectionsIcon} src={CollectionsIcon} alt="" />
          )}
          <h3 className={styles.collectionsTitle}>
            {customHeaderTitle || t("resources.collectionsTitle")}
          </h3>
        </div>
        {collections.length !== 0 && (
          <div
            className={styles.collectionsButtonContainer}
            data-testid="shift-buttons-container"
          >
            <ShiftButtons
              onForwardClick={handleNextCollections}
              onBackClick={handlePreviousCollections}
              viewAll={openAllCollections}
              disabledBack={disableSliderPrevious()}
              disabledForward={disableSliderNext()}
              hideSeeAllButton={hideSeeAllButton}
            />
          </div>
        )}
      </div>

      {!collections.length && !collectionsLoading ? (
        <span className={styles.textContainer} data-testid="no-results-container">
          {t(noCollectionsMessage)}
        </span>
      ) : (
        <div className={styles.collectionsContent} data-testid="results-container">
          {collectionsLoading && !collections.length ? (
            <div className={styles.loaderWrapper}>
              <Loading borderless disableBoxShadow e2eTestId="collections-loader" />
            </div>
          ) : (
            <Slider
              forwardedRef={collectionsChildRef}
              items={collections?.map((collection: ICollection) => {
                const userRole = getUserRole(
                  userPublicId,
                  collection.collectionCreator,
                  collection.followers!,
                  collection.collaborators!,
                  translatedRoles
                );

                return collection.name === "placeholder" ? (
                  <Loading />
                ) : (
                  <CollectionsCard
                    key={collection.name}
                    title={collection.name}
                    description={collection.description}
                    resourcesCount={collection.articlesCount}
                    collaboratorsCount={collection.collaboratorsCount}
                    updateTime={collection.updateTime}
                    publicId={collection.publicId}
                    customStyles={styles.customStyles}
                    collectionCreator={collection.collectionCreator}
                    imageName={collection.imageName}
                    imageDirectory={collection.imageDirectory}
                    isPublic={collection.isPublic}
                    isGlobalCurated={collection.isGlobalCurated}
                    userRole={userRole}
                    menuOptions={menuDots(collection)}
                  />
                );
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResourcesCollectionsSection;
