import { useMemo } from "react";

import Menu from "@components/Menu/Menu";
import { IOption } from "@components/Menu/types";

import { IUser } from "types/user";

import { useActivateDeactivateUser } from "@graphql/admin-panel/useActivateDeactivateUser/useActivateDeactivateUser";
import { useCancelDeactivateUser } from "@graphql/admin-panel/useCancelDeactivationUser/useCancelDeactivationUser";

type IOptionsProps = Pick<IUser, "email" | "isActive" | "isTemporary">;

export const Options = ({ email, isActive, isTemporary }: IOptionsProps) => {
  const { activateUser, deactivateUser } = useActivateDeactivateUser({});
  const { cancelDeactivateUser } = useCancelDeactivateUser({});

  const options = useMemo<IOption[]>(
    () => [
      {
        label: "activate",
        action: () => activateUser({ variables: { user: { email } } }),
        isDisplayed: !isActive && !isTemporary,
      },
      {
        label: "deactivate",
        action: () => deactivateUser({ variables: { user: { email } } }),
        isDisplayed: isActive && !isTemporary,
      },
      {
        label: "cancelDeactivation",
        action: () => cancelDeactivateUser({ variables: { user: { email } } }),
        isDisplayed: isTemporary,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, email, isTemporary]
  );

  return <Menu options={options} />;
};
