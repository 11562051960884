import HiveLogo from "@images/hive_logo.svg";
import WHOLogo from "@images/who_logo.svg";
import styles from "./PageNotFound.module.scss";
// Using PNG since SVG image doesn't want to show on page. Anyways SVG is imported in images directory
import Button from "@components/Button/Button";
import HeroBee from "@images/404.svg";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.sorryScreenWrapper} data-testid="container">
        <div className={styles.sorryFormWrapper}>
          <div className={styles.sorryFormContainer}>
            <img className={styles.epicenterLogo} src={HiveLogo} alt="" />
            <h1 className={styles.epicenterHeading}>{t("pageNotFound.title")}</h1>
            <p className={styles.introText}>{t("pageNotFound.text")}</p>
            <a href="/">
              <Button
                title={t("pageNotFound.btn")}
                buttonSize="large"
                buttonType="primary"
              />
            </a>
            <div className={styles.whoWrapper}>
              <img className={styles.whoLogo} src={WHOLogo} alt="WHO Logo" />
            </div>
          </div>
        </div>
        <div className={styles.sorryImageContainer}>
          <img src={HeroBee} className={styles.placeholderImage} />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
