import { useTranslation } from "react-i18next";
import styles from "./ResourcesCategories.module.scss";

export interface IResourcesCategoriesProps {
  categories: Array<string>;
  notPills?: boolean;
}

const ResourcesCategories = ({ categories, notPills }: IResourcesCategoriesProps) => {
  const { t } = useTranslation();
  return (
    <div className={notPills ? styles.container : styles.detailsPage}>
      {!notPills && (
        <span className={styles.title}>{t("resourcesCategories.heading")}</span>
      )}
      {categories.map((category, index) => (
        <span
          key={index}
          className={notPills ? styles.notPillCategory : styles.category}
          aria-label="category"
        >
          {index && !notPills
            ? `• ${t(`categories.type.${category}`)}`
            : `${t(`categories.type.${category}`)}`}
        </span>
      ))}
    </div>
  );
};

export default ResourcesCategories;
