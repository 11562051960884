import { gql } from "@apollo/client";

const GET_USER_COMMUNITIES = gql`
  query user {
    user {
      email
      communities {
        name
        sendbirdId
        communityImageUrl
        communityType
        description
        channels
      }
    }
  }
`;

export const GET_COMMUNITIES_FOR_COMMUNITY_SPACES = gql`
  query UserCommunities {
    user {
      email
      communities {
        sendbirdId
        name
        admins {
          email
        }
        members {
          email
        }
        communityCreator {
          email
        }
        allCommunityCollectionsCount
      }
    }
  }
`;

export default GET_USER_COMMUNITIES;
