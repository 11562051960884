import * as yup from "yup";

import { getValidationSchema as getPhoneValidationSchema } from "@feature/phone";
import { pronounsValidation } from "@feature/pronouns";

import { requiredStringValidation, stringValidation } from "@utils/yup/string";

export const getValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    fullName: requiredStringValidation(t),
    email: requiredStringValidation(t),
    phone: getPhoneValidationSchema(t),
    defaultLanguage: stringValidation(t),
    pronouns: pronounsValidation(t),
  });
