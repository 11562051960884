import { gql } from "@apollo/client";

const ADD_FOLDER_TO_COLLECTION = gql`
  mutation addFolderToCollection($folderToAdd: AddFolderToCollectionInputType!) {
    addFolderToCollection(folderToAdd: $folderToAdd) {
      name
      publicId
    }
  }
`;

export default ADD_FOLDER_TO_COLLECTION;
