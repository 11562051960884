/* eslint-disable no-control-regex */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ICollectionUser, IPartialUser } from "@graphql/collection/type";
import dayjs from "dayjs";
import EnglishTime from "dayjs/locale/en";
import SpanishTime from "dayjs/locale/es";
import FrenchTime from "dayjs/locale/fr";
import KoreanTime from "dayjs/locale/ko";
import RussianTime from "dayjs/locale/ru";
import ChineseTime from "dayjs/locale/zh";
import relativeTime from "dayjs/plugin/relativeTime";
import { RefObject } from "react";
import { ICommunity } from "types/community";
import { PersonalDetails, ProfileDetails } from "../store/onboarding/types";
import { isDocumentRtl } from "./checkDocumentDir";
import { formatDate } from "./date/helper";

dayjs.extend(relativeTime);

export const getSortedArray = <T>(arr: Array<T>, key: keyof T, isAsc: boolean) =>
  arr.sort((a, b) => {
    const stringA = a[key] as string;
    const stringB = b[key] as string;
    return (stringA.toUpperCase() < stringB.toUpperCase() ? -1 : 1) * (isAsc ? 1 : -1);
  });

export const sortByString = <T>(
  getStringProperty: (object: T) => string,
  isAsc: boolean
) => {
  return (objectA: T, objectB: T) => {
    const stringA = getStringProperty(objectA).toUpperCase();
    const stringB = getStringProperty(objectB).toUpperCase();
    return (stringA < stringB ? -1 : 1) * (isAsc ? 1 : -1);
  };
};

export const sortByNumber = <T>(
  getNumberProperty: (object: T) => number,
  isAsc: boolean
) => {
  return (objectA: T, objectB: T) => {
    const numberA = getNumberProperty(objectA);
    const numberB = getNumberProperty(objectB);
    return (numberA < numberB ? -1 : 1) * (isAsc ? 1 : -1);
  };
};

export const isObjectEmpty = (
  object: PersonalDetails | ProfileDetails | Record<string, unknown>
) => {
  return !Object.keys(object).length;
};

export const disableNext = (sliderPage: number, itemsCount: number, pageSize: number) => {
  const leftover = itemsCount % pageSize;
  // Since sliderPage is starting from 0, if leftover is not present
  // page counter will be left over with 1 bonus page
  return sliderPage >= (itemsCount - leftover) / pageSize - Number(!leftover);
};

export const formatDateForTableRow = (date: string) => {
  if (date === null) {
    return "Not onboarded";
  }

  return formatDate(date);
};

export const truncateStringIfTooLong = (text: string, maxLetters: number) => {
  return text.length < maxLetters ? text : text.substring(0, maxLetters).concat("...");
};

export const checkIfArrayIncludeString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array: Array<any> | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  text: any
) => array.includes(text);

export const urlRegex = new RegExp(
  /^((?:(https?|ftp):\/\/)(?:\S+(?::\S*)?@)?)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
);

export const validURL = (url: string) => {
  return !!urlRegex.test(url);
};

export const getDateInUtc = () => {
  const date = new Date();
  const dateInUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return new Date(dateInUtc);
};

export const determineTextLengthByElementWidth = (
  defaultWidth: number,
  elementRef?: HTMLDivElement | null
) => (elementRef ? Math.round((elementRef?.clientWidth - 100) / 10) : defaultWidth);

export const scrollToTheBottom = (element: React.RefObject<HTMLSpanElement>) => {
  element.current?.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

export const handleAcceptingRedirect = (
  clickedUrl: string,
  closeModal?: (modalState: boolean) => void
) => {
  const redirect = window.open(clickedUrl, "_blank");
  redirect?.focus();
  closeModal && closeModal(false);
};

export const convertTime = (time: number) => {
  if (isDocumentRtl()) {
    return dayjs(new Date(time)).format("Ahh:mm, YYYY MMM DD");
  }

  return dayjs(new Date(time)).format("DD MMM YYYY, hh:mmA");
};

export const countTimeAgoFromNowFromUTCToString = (time: number, language: string) => {
  switch (language) {
    case "es":
      dayjs.locale(SpanishTime);
      break;
    case "ko":
      dayjs.locale(KoreanTime);
      break;
    case "fr":
      dayjs.locale(FrenchTime);
      break;
    case "ru":
      dayjs.locale(RussianTime);
      break;
    case "zh":
      dayjs.locale(ChineseTime);
      break;
    default:
      dayjs.locale(EnglishTime);
  }

  return dayjs(new Date(time * 1000))
    .fromNow()
    .toString();
};

// this method compare if current time passes update check time for suggested articles by user activiy
export const compareIfCurrentTimePassesUpdateThreshold = (
  currentTime: Date,
  lastUpdatedTime: Date
) => {
  return new Date(currentTime).getTime() > new Date(lastUpdatedTime).getTime();
};

type toggleInputSizeArgs = {
  descriptionButtonRef: RefObject<HTMLDivElement>;
  inputNameRef: RefObject<HTMLInputElement>;
  descriptionField: boolean;
};

export const toggleInputSize = ({
  descriptionButtonRef,
  inputNameRef,
  descriptionField,
}: toggleInputSizeArgs) => {
  const defaultPadding = 10;
  const buttonRef = descriptionButtonRef?.current;
  const inputRef = inputNameRef?.current;

  if (!inputRef) return;

  if (buttonRef && !descriptionField)
    inputRef.style.paddingRight = buttonRef.clientWidth + 2 * defaultPadding + "px";
  if (!buttonRef && descriptionField) inputRef.style.paddingRight = defaultPadding + "px";
};

export const getCurrentPageRows = (
  tableData: Record<string, unknown>[],
  currentPage: number,
  pageSize: number
) => tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `${r},${g},${b}`;
};

/**
 * This methods replace regex needs to be in sync with Back-End regex
 */
export const parseFileName = (fileName: string) => fileName.replace(/[^a-zA-Z0-9]/g, "_");

/**
 * @param fileName Original file name
 * @returns Parsed file name in format where every special character is replaced with _ character without an extension
 * For example: File Name-example.png ---> File_Name_example.png
 */
export const fileNameReplaceRegex = (fileName: string) => {
  const fileExtensionRegexStr = ".[^/.]+$";
  const fileExtensionRegex = new RegExp(fileExtensionRegexStr);
  const fileExtensionMatch = fileExtensionRegex.exec(fileName);

  const fileNameWoExtension = fileName.slice(0, fileExtensionMatch!.index);

  const newFileNameWoExtension = parseFileName(fileNameWoExtension);
  const newFileName = newFileNameWoExtension.concat(fileExtensionMatch![0]);
  return newFileName;
};

export const scrolledToEnd = (element: HTMLElement) => {
  return element.scrollHeight > element.clientHeight;
};

export const openPageInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const urlToObject = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], "image.jpg", { type: blob.type });
  return file;
};

enum STATUS_COLORS {
  ACTIVE = "#14B25C",
  NOT_ACTIVE = "#EE5552",
  NOT_ONBOARDED = "#F5DC00",
}

// Based on user status and onboard process return adequate badge color
export const getUserBadgeColor = (
  userStatus: boolean,
  userIsOnboarded: boolean
): string => {
  if (userStatus && (!userIsOnboarded || userIsOnboarded === null)) {
    return STATUS_COLORS.NOT_ONBOARDED;
  }

  if (userStatus && userIsOnboarded) {
    return STATUS_COLORS.ACTIVE;
  }

  if (!userStatus) {
    return STATUS_COLORS.NOT_ACTIVE;
  }
  return "";
};

export const getUserStatusText = (
  userStatus: boolean,
  userIsOnboarded: boolean
): string => {
  if (userStatus && (!userIsOnboarded || userIsOnboarded === null)) {
    return "Not Onboarded";
  }

  if (userStatus && userIsOnboarded) {
    return "Active";
  }

  if (!userStatus) {
    return "Not Active";
  }

  return "";
};

/**
 *
 *
 * @template T extends { publicId: string }
 * @param {T[]} array
 * @param {string} id
 * @returns {boolean}
 */
export const checkIfUserExistsByPublicId = <T extends { publicId: string }>(
  array: T[],
  id: string
): boolean => {
  return !!array?.find((arrayItem) => arrayItem.publicId === id);
};

/**
 *
 *
 * @param {string} userPublicId
 * @param {IPartialUser} collectionCreator
 * @param {ICollectionUser[]} collectionFollowers
 * @param {ICollectionUser[]} collectionCollaborator
 * @param {string[]} translatedRoles
 * @returns {string} users role as string
 */
export const getUserRole = (
  userPublicId: string,
  collectionCreator: IPartialUser,
  collectionFollowers: ICollectionUser[],
  collectionCollaborator: ICollectionUser[],
  translatedRoles: string[]
): string => {
  if (userPublicId === collectionCreator?.publicId) {
    return translatedRoles[0];
  }

  if (checkIfUserExistsByPublicId(collectionFollowers, userPublicId)) {
    return translatedRoles[1];
  }

  if (checkIfUserExistsByPublicId(collectionCollaborator, userPublicId)) {
    return translatedRoles[2];
  }

  return "";
};

export const getUserTag = (email: string, community: ICommunity) => {
  switch (true) {
    case community.communityCreator.email === email:
      return "community.userRoles.owner";
    case community.admins.some((admin) => admin.email === email):
      return "community.userRoles.admin";
    case community.members.some((member) => member.email === email):
      return "community.userRoles.member";
  }
};
