import palette from "@scss/exports.module.scss";
import Loader from "react-loader-spinner";
import styles from "./Loading.module.scss";

type Size = "Large" | "Small";
interface ILoadingProps {
  borderless?: boolean;
  disableBoxShadow?: boolean;
  size?: Size;
  e2eTestId?: string;
}

const renderLoader = (size: Size) => {
  const width = size === "Large" ? 150 : 24;
  return <Loader type="Rings" color={palette.primaryBlue} width={width} height={width} />;
};

const Loading = ({
  borderless,
  disableBoxShadow,
  size = "Large",
  e2eTestId,
}: ILoadingProps) => {
  return borderless ? (
    <div role="progressbar" e2e-test-id={e2eTestId}>
      {renderLoader(size)}
    </div>
  ) : (
    <div
      className={`${styles.cardsLoadingContainer} ${
        disableBoxShadow ? styles.noBoxShadow : ""
      }`}
      role="progressbar"
      e2e-test-id={e2eTestId}
    >
      {renderLoader(size)}
    </div>
  );
};

export default Loading;
