import { gql } from "@apollo/client";

const GET_USER_INFO_COMMUNITIES = gql`
  query userInfo($email: String!, $communitySearch: SearchInputType!) {
    userInfo(email: $email) {
      publicId
      fullName
      email
      communities(where: { isHidden: "false" }, communitySearch: $communitySearch) {
        name
        sendbirdId
        communityImageUrl
        communityBannerUrl
        coverImageName
        imageDirectory
        communityType
        description
        joinRequests {
          user {
            email
            userLinkedInLink
            problemUserWouldLikeToSolve
            userSecretSuperPower
            userDefinedInterests
          }
        }
        communityCreator {
          fullName
          email
          userLinkedInLink
          problemUserWouldLikeToSolve
          userSecretSuperPower
          userDefinedInterests
        }
        members {
          email
          userLinkedInLink
          problemUserWouldLikeToSolve
          userSecretSuperPower
          userDefinedInterests
        }
        admins {
          email
          userLinkedInLink
          problemUserWouldLikeToSolve
          userSecretSuperPower
          userDefinedInterests
        }
        channels
      }
      communitiesTotalCount
    }
  }
`;

export default GET_USER_INFO_COMMUNITIES;
