import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { INotification } from "@graphql/notification/types";

import ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION from "@graphql/mutations/acceptOrDeclineCollectionCollaborationInvitation";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

// general part for notifications
import { GeneralNotificationPart } from "../GeneralNotificationPart/GeneralNotificationPart";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import { Buttons } from "../../Buttons/Buttons";

export const AcceptOrDeclineCollectionCollaborationInvitation = (
  notification: INotification
) => {
  const { t } = useTranslation();
  const notificationData = JSON.parse(notification.data);
  const [isAnswerInProgress, setIsAnswerInProgress] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [joinCollectionRequest] = useMutation(
    ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION,
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setIsAnswerInProgress(false);
        const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
        if (errorCode === "1008") {
          setIsModalOpen(true);
        } else {
          displayServerError();
        }
      },
    }
  );

  const handleJoinCollectionRequestWrapper = (isAcceptedInvitation: boolean) => {
    setIsAnswerInProgress(true);
    joinCollectionRequest({
      variables: {
        invitationResponse: {
          collectionPublicId: notificationData.CollectionPublicId,
          isAccepted: isAcceptedInvitation,
          inviterEmail: notificationData.UserEmail,
        },
      },
    });
  };

  // notifications left side
  return (
    <>
      <GeneralNotificationPart
        notificationData={notificationData}
        links={[
          {
            text: `${notificationData.ResourceName}`,
            path: `/portal/collection/${notificationData.CollectionPublicId}`,
          },
        ]}
        text={"requests.collection.invitationToBeCollectionCollaborator"}
      />
      <Buttons
        buttonSubmit={{
          disabled: isAnswerInProgress,
          onClick: () => handleJoinCollectionRequestWrapper(true),
        }}
        buttonDecline={{
          disabled: isAnswerInProgress,
          onClick: () => handleJoinCollectionRequestWrapper(false),
        }}
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        messageTitle={t(
          "collections.singleCollection.userNotAbleToAcceptInvitation.title"
        )}
        messageContent={t(
          "collections.singleCollection.userNotAbleToAcceptInvitation.content"
        )}
        closeModal={() => setIsModalOpen(false)}
        goBack={() => setIsModalOpen(false)}
      />
    </>
  );
};
