import { gql } from "@apollo/client";

const JOIN_CLOSED_COMMUNITY = gql`
  mutation JoinClosedCommunity($sendbirdId: String!) {
    joinClosedCommunity(sendbirdId: $sendbirdId) {
      name
    }
  }
`;

export default JOIN_CLOSED_COMMUNITY;
