/* eslint-disable react-hooks/exhaustive-deps */
import { Feedback } from "@components/DataDisplay/Feedback/Feedback";
import { TextTruncated as TextTruncatedUpdated } from "@components/utils/TextTruncated/TextTruncated";
import { STORAGE_IMAGE_TYPE_EVENT } from "@constants/constants";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { isBetween } from "@utils/date/helper";
import getStorageImage from "@utils/getStorageImage";
import { useTimeout } from "@utils/useTimeout";
import { MouseEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IEvent } from "types/event";
import withUser from "../../hoc/withUser";
import styles from "./EventCard.module.scss";

const getDelayForRemovingEventCard = (endDate: string) => {
  return new Date(endDate).getTime() - new Date().getTime();
};

export interface IEventCardProps {
  inUserDrawer?: boolean;
  startDate: string;
  endDate: string;
  userName: string;
  name: string;
  event: IEvent;
  updateEvents?: () => void;
}

const EventCard = ({
  inUserDrawer,
  name,
  userName,
  startDate,
  endDate,
  event,
  updateEvents,
}: IEventCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const cardRef = useRef<HTMLDivElement>(null);

  const [selectedUserEmail, setSelectedUserEmail] = useState("");

  useTimeout(() => {
    updateEvents?.();
  }, getDelayForRemovingEventCard(endDate));

  const openEventPage = () => {
    history.push(`/portal/event/${event.publicId}`);
  };

  const getEventTypeFromResponse = (param: string) => {
    switch (param) {
      case "Hive":
        return "public";
      case "Private":
        return "private";
      case "Public":
        return "openToPublic";
      default:
        return "public";
    }
  };

  const showEventType = () => t("events." + getEventTypeFromResponse(event.eventType!));

  const handleUserSelected = (mouseEvent: MouseEvent) => {
    mouseEvent.stopPropagation();
    setSelectedUserEmail(event.eventHost.email);
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  const isLive = isBetween(event);

  return (
    <>
      <div
        aria-label={`event-card-${name}`}
        onClick={openEventPage}
        className={`${styles.eventCard} ${inUserDrawer ? styles.inUserDrawer : ""}`}
        ref={cardRef}
      >
        <div className={styles.container}>
          {isLive && (
            <div className={styles.feedBackContainer}>
              <Feedback translationKey="eventDetail.liveButton" />
            </div>
          )}
          <div className={styles.imgWrapper}>
            <div
              style={{
                backgroundImage: `url(${getStorageImage({
                  image: event?.eventImageName,
                  directoryName: event?.imageDirectory,
                  type: STORAGE_IMAGE_TYPE_EVENT,
                })})`,
              }}
              className={styles.img}
            ></div>
          </div>
          <div className={styles.textBottom}>
            <div className={styles.titleContainer}>
              <TextTruncatedUpdated
                Tag="span"
                lines={2}
                text={name}
                className={styles.title}
              />
            </div>
            <span className={styles.dateAndTime}>{startDate}</span>
            <div className={styles.authorContainer}>
              <span className={styles.hostedBy}>
                {showEventType()} {t("events.hostedBy")}
              </span>
              <span className={styles.authorName} onClick={handleUserSelected}>
                {userName}
              </span>
            </div>
          </div>
        </div>
      </div>
      <UserDrawerWithUser
        email={selectedUserEmail}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </>
  );
};
export default EventCard;
