/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../store/RootState";

import basicStyles from "../BasicModal/BasicModal.module.scss";
import styles from "./AddToCollectionModal.module.scss";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Modal from "@components/Modal/Modal";
import Exit from "@images/exit.svg";

import {
  COMMUNITIES_MANAGED_BY_USER,
  GET_DEFAULT_COMMUNITY_COLLECTION_FOR_ADD_TO_COLLECTION_MODAL,
  GET_PRIVATE_COMMUNITY_COLLECTIONS,
  GET_PUBLIC_COMMUNITY_COLLECTIONS,
} from "@graphql/community/queries";

import { ADD_ARTICLE_TO_COLLECTION } from "@graphql/collection/mutation";
import { ADD_ARTICLE_TO_FOLDER } from "@graphql/folder/mutation";

import {
  CollectionsTreeNode,
  FolderNameForm,
  NodeIcon,
  useCollectionsTree,
} from "@components/CollectionsTree";
import CommunitiesList from "@components/CommunitiesList/CommunitiesList";
import Tree from "@components/Tree/Tree";
import {
  COMMUNITY_DEFAULT_COLLECTION_NAME,
  COMMUNITY_DEFAULT_COLLECTION_DEFAULT_FOLDER_NAME as DEFAULT_FOLDER_NAME,
  SORTING_A_Z,
  sortingCollectionsObj,
} from "@constants/constants";
import { GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES } from "@graphql/collection/queries";
import {
  CollectionType,
  ICollection,
  IUserCollectionResponse,
} from "@graphql/collection/type";
import { ICommunitiesManagedByUser } from "@graphql/community/types";
import {
  displayCustomNotSuccessNotification,
  displayNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { sortByString } from "@utils/helpers";
import { Tabs } from "antd";
import { TabsProps } from "antd/es/tabs";
import { ICommunity } from "types/community";

export interface IAddToCollectionModal {
  articleId: string;
  newCollectionId?: string;
  newCollectionType?: string;
  newCollectionCommunity?: ICommunity;
  redirectToAfterAddNewCollection?: string;
  isOpen: boolean;
  onClose: () => void;
  preSelectedOption?: string;
  showModalIfAborted?: boolean;
}

export interface IOptions {
  value: string;
  label: string;
}

const TABS = {
  personal: "Personal",
  community: "Community",
};

const AddToCollectionModal = ({
  isOpen,
  onClose,
  articleId,
  newCollectionId,
  newCollectionType,
  newCollectionCommunity,
  redirectToAfterAddNewCollection,
  showModalIfAborted,
}: IAddToCollectionModal) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useState(TABS.personal);
  const [selectedUserCollectionsNode, setSelectedUserCollectionsNode] =
    useState<CollectionsTreeNode>();
  const [selectedCommunityCollectionsNode, setSelectedCommunityCollectionsNode] =
    useState<CollectionsTreeNode>();
  const [selectedCommunity, setSelectedCommunity] = useState<ICommunity>();
  const [communityCollections, setCommunityCollections] = useState<ICollection[]>();
  const [isLoadingCommunityCollections, setIsLoadingCommunityCollections] =
    useState(false);
  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);

  const { data: userCommunitiesData } = useQuery<ICommunitiesManagedByUser>(
    COMMUNITIES_MANAGED_BY_USER,
    {
      fetchPolicy: "no-cache",
      variables: {
        userCommunitySearchOptions: {
          size: 100,
          skip: 0,
        },
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  // GET ALL USER COLLECTIONS
  const { data: userCollectionsData } = useQuery<IUserCollectionResponse>(
    GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES,
    {
      fetchPolicy: "no-cache",
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  const [getDefaultCommunityCollection] = useLazyQuery<{
    community: {
      defaultCollection: ICollection;
    };
  }>(GET_DEFAULT_COMMUNITY_COLLECTION_FOR_ADD_TO_COLLECTION_MODAL, {
    fetchPolicy: "no-cache",
  });

  const [getPublicCommunityCollections] = useLazyQuery<{
    publicCommunityCollections: { totalCount: number; collections: ICollection[] };
  }>(GET_PUBLIC_COMMUNITY_COLLECTIONS, {
    fetchPolicy: "no-cache",
  });
  const [getPrivateCommunityCollections] = useLazyQuery<{
    privateCommunityCollections: { totalCount: number; collections: ICollection[] };
  }>(GET_PRIVATE_COMMUNITY_COLLECTIONS, {
    fetchPolicy: "no-cache",
  });

  const [addArticleToCollection] = useMutation(ADD_ARTICLE_TO_COLLECTION);
  const [addArticleToFolder] = useMutation(ADD_ARTICLE_TO_FOLDER);

  const userCollectionsTreeInitialData: CollectionsTreeNode[] | undefined =
    useMemo(() => {
      if (userCollectionsData) {
        return [
          ...userCollectionsData.user.myCollections,
          ...userCollectionsData.user.collaboratingCollections,
        ]
          .sort(sortingCollectionsObj[SORTING_A_Z].sort)
          .map((collection) => ({
            key: collection.publicId,
            title: collection.name,
            type: "collection",
            isLeaf: false,
          }));
      }
      return undefined;
    }, [userCollectionsData, isOpen]);

  const communityCollectionsTreeInitialData: CollectionsTreeNode[] | undefined =
    useMemo(() => {
      if (communityCollections) {
        return communityCollections.map((collection) => ({
          key: collection.publicId,
          title: collection.name,
          type: "collection",
          isLeaf: false,
        }));
      }
      return undefined;
    }, [communityCollections, isOpen]);

  const handleDestinationSelected = (nodes: CollectionsTreeNode[] | undefined) => {
    const selectedNode = nodes?.pop();
    if (activeTabKey === TABS.personal) {
      setSelectedUserCollectionsNode(selectedNode);
    } else {
      setSelectedCommunityCollectionsNode(selectedNode);
    }
  };

  const userCollectionsTree = useCollectionsTree(
    userCollectionsTreeInitialData,
    handleDestinationSelected,
    t("collection.newFolder"),
    t("collection.noFolders"),
    false,
    true,
    t("collections.singleCollection.folderCreated")
  );

  const filterCommunityCollectionDefaultFolder = (
    node: CollectionsTreeNode,
    parent: CollectionsTreeNode
  ) => {
    return !(
      parent.type === "collection" &&
      parent.title === COMMUNITY_DEFAULT_COLLECTION_NAME &&
      node.type === "folder" &&
      node.title === DEFAULT_FOLDER_NAME
    );
  };

  const communityCollectionsTree = useCollectionsTree(
    communityCollectionsTreeInitialData,
    handleDestinationSelected,
    t("collection.newFolder"),
    t("collection.noFolders"),
    false,
    false,
    true,
    t("collections.singleCollection.folderCreated"),
    filterCommunityCollectionDefaultFolder
  );

  const handleClose = () => {
    setSelectedUserCollectionsNode(undefined);
    setSelectedCommunityCollectionsNode(undefined);
    setSelectedCommunity(undefined);
    setActiveTabKey(TABS.personal);
    if (userCollectionsTree.isCreatingNewFolder) {
      userCollectionsTree.cancelCreatingNewFoler();
    }
    if (communityCollectionsTree.isCreatingNewFolder) {
      communityCollectionsTree.cancelCreatingNewFoler();
    }
    onClose();
  };

  useEffect(() => {
    if (selectedCommunity) {
      getCommunityCollections(selectedCommunity.sendbirdId);
    }
  }, [selectedCommunity]);

  const getCommunityCollections = async (sendbirdId: string): Promise<ICollection[]> => {
    const options = {
      variables: {
        search: {
          sendbirdId,
        },
      },
    };

    try {
      setIsLoadingCommunityCollections(true);
      const responses = await Promise.all([
        getDefaultCommunityCollection({ variables: { sendbirdId } }),
        getPublicCommunityCollections(options),
        getPrivateCommunityCollections(options),
      ]);
      const collections = [
        ...(responses[0].data?.community.defaultCollection
          ? [responses[0].data?.community.defaultCollection]
          : []),
        ...(responses[1].data?.publicCommunityCollections.collections ?? []),
        ...(responses[2].data?.privateCommunityCollections.collections ?? []),
      ].filter((collection) => {
        return (
          collection.collectionCreator.email === currentUserEmail ||
          collection.collaborators?.some(
            (colaborator) => colaborator.email === currentUserEmail
          )
        );
      });
      setCommunityCollections(collections);
      setIsLoadingCommunityCollections(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayNotSuccessNotification(error);
    }
    return Promise.resolve([]);
  };

  const activeTabChangeHandler = (activeTabKey: string) => {
    setActiveTabKey(activeTabKey);
    if (activeTabKey === TABS.personal) {
      setSelectedCommunity(undefined);
    }
  };

  const handleCreateNew = () => {
    if (activeTabKey === TABS.personal && selectedUserCollectionsNode) {
      userCollectionsTree.createNewFolder(selectedUserCollectionsNode);
      return;
    }
    if (activeTabKey === TABS.community && selectedCommunityCollectionsNode) {
      communityCollectionsTree.createNewFolder(selectedCommunityCollectionsNode);
      return;
    }
    createNewCollection();
  };

  const createNewCollection = () => {
    let newLocation;

    if (activeTabKey === TABS.personal) {
      newLocation = {
        pathname: "/portal/collection/new",
        state: {
          redirect: redirectToAfterAddNewCollection,
          articleId,
          showModalIfAborted,
        },
      };
      history.push(newLocation);
    } else if (selectedCommunity) {
      newLocation = {
        pathname: "/portal/community-collection/new",
        state: {
          redirect: redirectToAfterAddNewCollection,
          articleId,
          showModalIfAborted,
          community: selectedCommunity,
        },
      };
      history.push(newLocation);
    }
  };

  const handleAddArticle = async () => {
    if (
      !(
        selectedUserCollectionsNode ||
        selectedCommunityCollectionsNode ||
        newCollectionId
      )
    ) {
      return;
    }

    const selectedTreeNode =
      activeTabKey === TABS.personal
        ? selectedUserCollectionsNode
        : selectedCommunityCollectionsNode;
    let id: string | number | undefined = newCollectionId;
    let destinationType = "collection";
    if (selectedTreeNode) {
      id = selectedTreeNode.key;
      destinationType = selectedTreeNode.type;
    }
    let mutation;
    let variables;

    if (destinationType === "collection") {
      mutation = addArticleToCollection;
      variables = {
        addArticleToCollection: {
          articleId,
          collectionPublicId: id,
        },
      };
    } else {
      mutation = addArticleToFolder;
      variables = {
        article: {
          articleId,
          folderPublicId: id,
        },
      };
    }

    try {
      const response = await mutation({ variables });
      let name;
      let translationKey;
      if (destinationType === "collection") {
        name = response.data.addArticleToCollection.name;
        translationKey = "singleArticle.collection.addedToCollectionToaster";
      } else {
        name = response.data.addArticleToFolder.name;
        translationKey = "singleArticle.collection.addedToFolderToaster";
      }
      displaySuccessNotification(
        t(translationKey, {
          name,
        })
      );
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      displayCustomNotSuccessNotification(errorCode);
    }
  };

  const handleCommunitySelected = (community: ICommunity) => {
    setSelectedCommunity(community);
  };

  useEffect(() => {
    if (newCollectionId && newCollectionType && userCollectionsData) {
      const newActiveTab =
        newCollectionType === CollectionType.USER_COLLECTION
          ? TABS.personal
          : TABS.community;
      let newCollection;

      if (newActiveTab === TABS.personal) {
        newCollection = [
          ...userCollectionsData.user.myCollections,
          ...userCollectionsData.user.collaboratingCollections,
        ].find((collection) => collection.publicId === newCollectionId);
      }
      if (newActiveTab === TABS.community) {
        if (communityCollections) {
          newCollection = communityCollections.find(
            (collection) => collection.publicId === newCollectionId
          );
        } else {
          setSelectedCommunity(newCollectionCommunity);
        }
      }

      setActiveTabKey(newActiveTab);

      if (newCollection) {
        const node = {
          key: newCollection?.publicId,
          title: newCollection?.name,
          type: "collection",
        } as CollectionsTreeNode;
        activeTabKey === TABS.personal
          ? setSelectedUserCollectionsNode(node)
          : setSelectedCommunityCollectionsNode(node);
      }
    }
  }, [
    newCollectionId,
    newCollectionType,
    newCollectionCommunity,
    communityCollections,
    userCollectionsData,
    userCommunitiesData,
  ]);

  useEffect(() => {
    if (communityCollectionsTreeInitialData) {
      communityCollectionsTree.setState(communityCollectionsTreeInitialData);
    }
  }, [communityCollectionsTreeInitialData]);

  const getSelectedKeysForPeronalTab = () =>
    selectedUserCollectionsNode ? [selectedUserCollectionsNode.key] : [];

  const getSelectedKeysForCommunitiesTab = () =>
    selectedCommunityCollectionsNode ? [selectedCommunityCollectionsNode.key] : [];

  const loadingSpinner = (
    <div className={styles.loadingWrapper}>
      <Loading borderless disableBoxShadow />
    </div>
  );

  const getTitle = (node: CollectionsTreeNode) => {
    const collectionsTree =
      activeTabKey === TABS.personal ? userCollectionsTree : communityCollectionsTree;
    const path = collectionsTree.getNodePath(node.key, true);
    const depth = path ? path.length - 1 : 0;
    const className = `collections-tree-node-title ${node.type}`;
    let content: ReactNode = node.title;
    if (node.type === "new-folder") {
      content = (
        <FolderNameForm
          initialValue={collectionsTree.newFolderTitle}
          onSubmit={(name: string) => {
            collectionsTree.newFolderSubmitHandler(name);
          }}
        />
      );
    }
    return (
      <span style={{ maxWidth: `${425 - 24 * depth}px` }} className={className}>
        {content}
      </span>
    );
  };

  const personalTabContent = userCollectionsTreeInitialData ? (
    <Tree<CollectionsTreeNode>
      treeData={userCollectionsTree.treeData}
      showIcon
      blockNode
      titleRender={getTitle}
      icon={NodeIcon}
      selectedKeys={getSelectedKeysForPeronalTab()}
      expandedKeys={userCollectionsTree.expandedKeys}
      onSelect={userCollectionsTree.selectHandler}
      onExpand={userCollectionsTree.expandHandler}
    />
  ) : (
    loadingSpinner
  );

  const communityTabContent = userCommunitiesData?.communitiesManagedByUser
    .communities ? (
    selectedCommunity ? (
      !isLoadingCommunityCollections ? (
        <Tree<CollectionsTreeNode>
          treeData={communityCollectionsTree.treeData}
          showIcon
          blockNode
          titleRender={getTitle}
          icon={NodeIcon}
          selectedKeys={getSelectedKeysForCommunitiesTab()}
          expandedKeys={communityCollectionsTree.expandedKeys}
          onSelect={communityCollectionsTree.selectHandler}
          onExpand={communityCollectionsTree.expandHandler}
        />
      ) : (
        loadingSpinner
      )
    ) : (
      <CommunitiesList
        communities={userCommunitiesData?.communitiesManagedByUser.communities.sort(
          sortByString(({ name }) => name, true)
        )}
        onSelect={handleCommunitySelected}
      />
    )
  ) : (
    loadingSpinner
  );

  const tabs: TabsProps["items"] = [
    {
      key: TABS.personal,
      label: t("singleArticle.collection.personalTabTitle"),
    },
    {
      key: TABS.community,
      label: t("singleArticle.collection.communityTabTitle"),
    },
  ];

  const createNewBtnTitle =
    (activeTabKey === TABS.personal && selectedUserCollectionsNode === undefined) ||
    (activeTabKey === TABS.community && selectedCommunityCollectionsNode === undefined)
      ? t("singleArticle.collection.actions.createNewCollection")
      : t("singleArticle.collection.actions.createNewFolder");

  const addButtonDisabled =
    !newCollectionId &&
    (activeTabKey === TABS.personal
      ? !selectedUserCollectionsNode
      : !selectedCommunityCollectionsNode);

  const createNewButtonDisabled =
    userCollectionsTree.isCreatingNewFolder ||
    communityCollectionsTree.isCreatingNewFolder ||
    (activeTabKey === TABS.community && !selectedCommunity) ||
    (activeTabKey === TABS.community &&
      selectedCommunity &&
      selectedCommunity.members.some((member) => member.email === currentUserEmail));

  const preventPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Modal
      onRequestClose={handleClose}
      isOpen={isOpen}
      modalType="primary"
      contentStyles={{ minHeight: 450 }}
      className={styles.modal}
    >
      <div className={styles.contentWrapper} onClick={preventPropagation}>
        <div className={styles.titleContainer} data-testid="modal-outer">
          <h4 className={styles.title}>{t("singleArticle.collection.title")}</h4>
          <img
            role="button"
            aria-label="close-modal"
            className={basicStyles.closeImage}
            onClick={handleClose}
            src={Exit}
            alt=""
          />
        </div>
        <div className={styles.tabsWrapper}>
          <Tabs items={tabs} activeKey={activeTabKey} onChange={activeTabChangeHandler} />
        </div>
        <div className={styles.tabsContentWrapper}>
          {activeTabKey === TABS.personal && personalTabContent}
          {activeTabKey === TABS.community && communityTabContent}
        </div>
        <div className={styles.buttons}>
          <Button
            aria-label="create-new"
            buttonType="link"
            title={createNewBtnTitle}
            wrapperClassNames={styles.newCollection}
            disabled={createNewButtonDisabled}
            onClick={handleCreateNew}
          />
          <Button
            aria-label="cancelBtn"
            buttonType="link"
            title={t("singleArticle.collection.actions.cancel")}
            className={styles.cancelLink}
            onClick={handleClose}
          />
          <Button
            aria-label="add-article"
            buttonType="primary"
            buttonSize="large"
            title={t("singleArticle.collection.actions.addToCollection")}
            disabled={addButtonDisabled}
            onClick={handleAddArticle}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddToCollectionModal;
