import { useTranslation } from "react-i18next";

import { CreateUpdateForm, ISector, ISectorUpdate } from "@feature/sector";

import { useRef } from "react";
import { EditField, IEditFieldRef } from "../../EditField/EditField";

interface ISectorField {
  sector: ISector;
  onSubmit: (values: ISectorUpdate, onSuccess: () => void) => void;
  isLoading: boolean;
}

export const SectorField = (props: ISectorField) => {
  const { t } = useTranslation();
  const editFieldRef = useRef<IEditFieldRef | null>(null);

  return (
    <EditField
      title={t("form.label.sector")}
      displayText={
        props.sector.customSectorName
          ? props.sector.customSectorName
          : t(`sector.${props.sector.name}`)
      }
      ref={editFieldRef}
    >
      <CreateUpdateForm
        {...props}
        onSubmit={(sector) => {
          props.onSubmit(sector, () => editFieldRef.current?.closeEditMode());
        }}
      />
    </EditField>
  );
};
