/* eslint-disable no-console */
import { useMemo } from "react";

import SendBird, { Emoji } from "sendbird";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";

import { Reaction } from "./Reaction/Reaction";

import { SendBirdMessageHelper } from "@customHooks/sendBird/SendBirdHelper";
import { IUseSendBirdGroupChannel } from "@customHooks/sendBird/useSendBirdGroupChannel/type";

import { IUser } from "types/user";

import styles from "./Reactions.module.scss";

interface IReactionsProps {
  sendBirdGroupChannel: IUseSendBirdGroupChannel;
  reactions?: Array<SendBird.Reaction>;
  toggleReaction: (emoji: Emoji) => Promise<void>;
  currentUser?: IUser;
}

export const Reactions = ({
  reactions,
  toggleReaction,
  sendBirdGroupChannel: {
    state: { groupChannel, allMembers },
  },
  currentUser,
}: IReactionsProps) => {
  const { emojiList } = useSendBirdContext();

  const reactionsWithEmoji = useMemo(() => {
    return SendBirdMessageHelper.mapReactionsWithExtendedData(
      emojiList,
      allMembers,
      reactions,
      groupChannel,
      currentUser
    );
  }, [currentUser, emojiList, groupChannel, reactions, allMembers]);

  console.log(groupChannel);
  console.log(reactions);
  console.log(currentUser);
  console.log(reactionsWithEmoji);

  const reactionsMemoized = useMemo(
    () =>
      reactionsWithEmoji.map((reaction) => (
        <Reaction
          key={reaction.emoji.key}
          reaction={reaction}
          onClick={() => toggleReaction(reaction.emoji)}
        />
      )),
    [reactionsWithEmoji, toggleReaction]
  );

  return <div className={styles.reactions}>{reactionsMemoized}</div>;
};
