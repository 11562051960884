import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import Button from "@components/Button/Button";

import { InputQuill } from "@components/formik/InputQuill/InputQuill";

import { FormField, FormFields, getValidationSchema } from "./validation";

interface ICreateUpdateForm {
  bio?: string;
  onSubmit?: (formData: string) => void;
  isLoading?: boolean;
}

export const CreateUpdateForm = ({ bio, onSubmit, isLoading }: ICreateUpdateForm) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        bio: bio || "",
      }}
      enableReinitialize
      onSubmit={(data) => onSubmit?.(data.bio)}
      validationSchema={getValidationSchema(t)}
    >
      {({ isValid, submitForm }) => {
        return (
          <>
            <InputQuill
              label=""
              {...FormFields[FormField.BIO]}
              placeholder={t(FormFields[FormField.BIO].placeholder)}
            />
            <div>
              <Button
                title={t("accountSettings.personalDetails.button.save")}
                buttonType="primary"
                disabled={!isValid || isLoading}
                type="button"
                onClick={submitForm}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};
