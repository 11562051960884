import { ClaimsService } from "@services/ClaimsService";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITab, ITabsProps } from "@components/navigation/Tabs";
import ExploreEvents from "../ExploreEvents/ExploreEvents";
import HostingEvents from "../MyEvents/HostingEvents/HostingEvents";
import PastEvents from "../MyEvents/PastEvents/PastEvents";
import UpcomingEvents from "../MyEvents/UpcomingEvents/UpcomingEvents";

import { EVENT, EVENT_ROUTES, MY_EVENT_SUB_SECTION } from "@utils/routes/event";

import { sortByNumber } from "@utils/helpers";
import { cloneDeep } from "lodash";

export const useGetTabs = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<ITab[]>([
    {
      id: "1",
      name: t("events.myEvents"),
      path: EVENT.MY_EVENTS,
      isExtraContentDisplayed: false,
      isRoot: true,
    },
    {
      id: "2",
      name: t("events.explore"),
      path: EVENT.EXPLORE,
      content: <ExploreEvents />,
      isExtraContentDisplayed: true,
      isRoot: true,
    },
  ]);

  const myEventsTabs = useMemo<Array<ITab>>(() => {
    const array = [
      {
        id: "1",
        name: t("events.upcoming"),
        path: MY_EVENT_SUB_SECTION.UPCOMING,
        content: <UpcomingEvents />,
        order: 0,
      },
      {
        id: "2",
        name: t("events.past"),
        path: "past",
        content: <PastEvents />,
        order: 2,
      },
    ];

    if (ClaimsService.hasCreateEventClaim()) {
      array.push({
        id: "3",
        name: t("events.hosting"),
        path: MY_EVENT_SUB_SECTION.HOSTING,
        content: <HostingEvents />,
        order: 1,
      });
    }

    return array.sort(sortByNumber(({ order }) => order || 0, true));
  }, [t]);

  useEffect(() => {
    const myEventsSubTab: ITabsProps = {
      tabs: myEventsTabs,
      routePrefix: EVENT_ROUTES.MY_EVENTS,
      size: "large",
      fullWidthTabBar: true,
    };

    setTabs((previousTabs) => {
      const previousTabsCopy = [...previousTabs];
      const myEventsTab = cloneDeep(previousTabs[0]);
      myEventsTab.subTab = myEventsSubTab;
      previousTabsCopy[0] = myEventsTab;

      return previousTabsCopy;
    });
  }, [myEventsTabs]);

  return { tabs };
};
