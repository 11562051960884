import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInputType!) {
    updateUser(user: $user) {
      fullName
      bio
      email
      userPhotoName
      imageDirectory
      defaultLanguage
      telephone
      isOnboarded
      canReceiveMessages
      country {
        name
      }
      organization {
        sector {
          name
        }
        name
        userRole
      }
      interests {
        name
      }
      countries {
        name
      }
    }
  }
`;

export default UPDATE_USER;
