import { useMutation } from "@apollo/client";
import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import Modal from "@components/Modal/Modal";
import FLAG_ARTICLE from "@graphql/mutations/flagArticle";
import removeIcon from "@images/cancelIcon.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FlagModal.module.scss";

import { FLAG_ARTICLE_INAPPROPRIATE, MAX_INPUT_LENGTH } from "@constants/constants";
import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

interface IFlagModalProps {
  open: boolean;
  close: () => void;
  articleId: string;
}
const FlagModal = ({ open, close, articleId }: IFlagModalProps) => {
  const articleURL = window.location.origin + `/portal/resources/article/${articleId}`;
  const [feedback, setFeedback] = useState<string>("");

  const { t } = useTranslation();
  const [addFlag] = useMutation(FLAG_ARTICLE);

  const handleSubmit = () => {
    addFlag({
      variables: {
        article: {
          articleId,
          articleFlag: FLAG_ARTICLE_INAPPROPRIATE,
          feedback,
        },
      },
    })
      .then((r) => {
        if (r.data) {
          close();
          displaySuccessNotification(t("singleArticle.feedbackModal.successMessage"));
        }
      })
      .catch((error) => displayServerError(error));
    setFeedback("");
  };

  const disableSubmit: boolean = !articleId || !feedback;

  return (
    <Modal isOpen={open} modalType="primary">
      <img src={removeIcon} className={styles.removeIcon} onClick={close} alt="" />
      <div className={styles.titleContainer}>
        <span>{t("singleArticle.feedbackModal.title")}</span>
      </div>
      <div className={styles.selectContainer}>
        {articleId && (
          <Input
            label={t("singleArticle.feedbackModal.urlInput")}
            name="url"
            disabled
            required
            value={articleURL}
          />
        )}
        <Input
          label={t("singleArticle.feedbackModal.feedbackInput")}
          placeholder={t("singleArticle.feedbackModal.feedbackInputPlaceholder")}
          name="feedbackContent"
          onChange={(e) => setFeedback(e.target.value)}
          required
          maxLength={MAX_INPUT_LENGTH}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          title={t("singleArticle.feedbackModal.buttons.cancel")}
          buttonType="link"
          onClick={close}
          buttonSize="large"
        />
        <Button
          title={t("singleArticle.feedbackModal.buttons.submit")}
          buttonType="primary"
          onClick={handleSubmit}
          disabled={disableSubmit}
          buttonSize="middle"
        />
      </div>
    </Modal>
  );
};

export default FlagModal;
