/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";

import { useLazyQuery } from "@apollo/client";

import Button from "@components/Button/Button";
import { Feedback } from "@components/DataDisplay/Feedback/Feedback";
import Loading from "@components/Loading/Loading";

import { GET_OPEN_EVENTS } from "@graphql/open-events/queries";

import { formatDate } from "@utils/eventsHelper";
import getStorageImage from "@utils/getStorageImage";
import { getDateInUtc } from "@utils/helpers";

import {
  INITIAL_PAGINATION,
  OPEN_PUBLIC_EVENTS_PAGINATION_SIZE,
  STORAGE_IMAGE_TYPE_EVENT,
} from "@constants/constants";

import { IOpenEvent } from "@graphql/open-events/type";

import { displayServerError } from "@services/NotificationService/NotifacitonService";

import { isBetween } from "@utils/date/helper";

import { PlusOutlined } from "@ant-design/icons";

import styles from "./OpenEventCards.module.scss";

const OpenEventCards = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [openEvents, setOpenEvents] = useState<Array<IOpenEvent>>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreEvents();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const [getOpenEvents, { loading }] = useLazyQuery(GET_OPEN_EVENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setOpenEvents([...openEvents, ...data.events.events]);
      setEventsCount(data.events.totalCount);
      setIsLoaded(true);
    },
    onError: (error) => displayServerError(error),
  });

  useEffect(() => {
    getOpenEvents({
      variables: {
        size: 10,
        skip: INITIAL_PAGINATION,
        from: getDateInUtc(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreEvents = () => {
    getOpenEvents({
      variables: {
        size: OPEN_PUBLIC_EVENTS_PAGINATION_SIZE,
        skip: openEvents.length,
        from: getDateInUtc(),
      },
    });
  };

  const eventCartRender = (event: IOpenEvent, index: number) => {
    const openEventPage = () => {
      history.push(`/event/${event.publicId}`);
    };

    const isLive = isBetween(event);

    if (index < 4) {
      return (
        <div
          key={index}
          aria-label={`event-card-${event.name}`}
          onClick={openEventPage}
          className={styles.eventCard}
        >
          <div className={styles.container}>
            {isLive && (
              <div className={styles.feedBackContainer}>
                <Feedback translationKey="eventDetail.liveButton" />
              </div>
            )}
            <div className={styles.imgWrapper}>
              <div
                style={{
                  backgroundImage: `url(${getStorageImage({
                    image: event?.eventImageName,
                    directoryName: event?.imageDirectory,
                    type: STORAGE_IMAGE_TYPE_EVENT,
                  })})`,
                }}
                className={styles.img}
              ></div>
            </div>
            <div className={styles.textBottom}>
              <span className={styles.title}>{event.name}</span>
              <span className={styles.dateAndTime}>
                {formatDate(event?.startDate, i18n.language)}
              </span>
              <div className={styles.authorContainer}>
                {t("community.upcomingEvents.hostedBy")}
                {event?.eventHost?.fullName}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.smallCardOuterWrapper} key={index} onClick={openEventPage}>
          <div className={styles.smallCardInnerWrapper}>
            <div
              className={styles.leftWrapper}
              style={{
                backgroundImage: `url(${getStorageImage({
                  image: event?.eventImageName,
                  directoryName: event?.imageDirectory,
                  type: STORAGE_IMAGE_TYPE_EVENT,
                })})`,
              }}
            >
              <div className={styles.dateWrapper}>
                <span className={styles.smallDate}>
                  {formatDate(event?.startDate, i18n.language, "MMM").toUpperCase()}
                </span>
                <span className={styles.bigDate}>
                  {formatDate(event?.startDate, i18n.language, "DD")}
                </span>
              </div>
            </div>
            <div className={styles.rightWrapper}>
              <div className={styles.name}>{event.name}</div>
              <div className={styles.additionalInfo}>
                <span className={styles.hostedBy}>
                  {t("community.upcomingEvents.hostedBy")} {event?.eventHost?.fullName}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {!isLoaded ? (
        <Loading disableBoxShadow />
      ) : (
        <div className={styles.mainWrapper}>
          <div className={styles.eventsTitleContainer}>
            <span className={styles.eventsTitle}>Upcoming public events</span>
          </div>
          {openEvents.length < 1 ? (
            <span className={styles.noUpcomingEvents}>
              {t("openPublicEventsPage.noEvents")}
            </span>
          ) : (
            <>
              <div className={styles.eventsCardContainer}>
                {openEvents.map(eventCartRender)}
              </div>

              {eventsCount > openEvents.length && (
                <Button
                  title={t("openEventCards.buttons.loadMoreEvents")}
                  wrapperClassNames={styles.loadMoreButton}
                  buttonIcon={<PlusOutlined />}
                  onClick={loadMoreEvents}
                  loading={loading}
                  disabled={loading}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OpenEventCards;
