/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DefaultOption } from "types/select";
import CollectionHeader from "../CollectionHeader/CollectionHeader";
import LinkCollectionToCommunityModal from "../LinkCollectionToCommunityModal/LinkCollectionToCommunityModal";
import styles from "./CollectionsContainer.module.scss";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Select from "@components/Select/Select";
import ShareModal from "@components/ShareModal/ShareModal";
import {
  SHARE_MODAL_TYPE_COLLECTION,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";
import { RootState } from "../../../store/store";

import { UNFOLLOW_COLLECTION } from "@graphql/collection/mutation";
import {
  COLLECTION_GET_ALL_USER_COLLECTIONS,
  GET_MY_COLLECTIONS,
  GET_SHARED_WITH_ME_COLLECTIONS,
  GET_USER_COMMUNITIES,
} from "@graphql/collection/queries";

import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";
import {
  ALL_COLLECTIONS,
  COLLECTION_CONTAINER,
  COLLECTION_PAGINATION_SIZE,
  COMMUNITY_PAGINATION_SIZE,
  CREATED_BY_ME,
  INITIAL_PAGINATION,
  SHARED_WITH_ME,
  SORTING_A_Z,
  SORTING_MOST_RECENT,
  SORTING_Z_A,
  sortingCollectionsObj,
} from "@constants/constants";

import CommunityCollections from "../CommunityCollections/CommunityCollections";

import { useSubscription } from "@customHooks/useSubscription";
import {
  ICollection,
  IUserAllCollectionsReponse,
  IUserCollectionResponse,
  IUserCommunitiesResponse,
} from "@graphql/collection/type";
import { Col, Row } from "antd";
import { Subscription } from "../../../customContext/subscriptions/enum";

import { PlusOutlined } from "@ant-design/icons";

import SortIcon from "@images/sortIcon.svg";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import { getMenuOptions } from "@utils/collectionsHelper";
import { ICommunity } from "types/community";
import { UserState } from "../../../store/user/types";

const CollectionsContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { email: userEmail } = useSelector<RootState, UserState>((state) => state.user);

  const [collectionType, setCollectionType] = useState(ALL_COLLECTIONS);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareCollectionUrl, setShareCollectionUrl] = useState<string>();

  const sortOptions = [
    // REMOVE FOR DEMO
    // {
    //   value: SORTING_MOST_ACTIVE,
    //   label: t("collections.sorting.mostActive"),
    // },
    {
      value: SORTING_MOST_RECENT,
      label: t("collections.sorting.mostRecent"),
    },
    { value: SORTING_A_Z, label: t("collections.sorting.alphabeticalAZ") },
    { value: SORTING_Z_A, label: t("collections.sorting.alphabeticalZA") },
  ];

  const [sortValue, setSortValue] = useState(sortOptions[0]);

  const [collections, setCollections] = useState<Array<ICollection>>([]);

  const [userCommunities, setUserCommunities] = useState<ICommunity[]>([]);
  const [userCommunitiesTotalCount, setUserCommunitiesTotalCount] = useState(0);

  const [allCollections, setAllCollections] = useState<Array<ICollection>>([]);
  const [allCollectionsCount, setAllCollectionsCount] = useState<number>(0);
  const [collectionToLinkId, setCollectionToLinkId] = useState<string>();
  const [targetCommunityId, setTargetCommunityId] = useState<string>();
  const [collectionName, setCollectionName] = useState<string>();

  const [isLinkCollectionToCommuniyModalOpen, setIsLinkCollectionToCommuniyModalOpen] =
    useState<boolean>(false);

  const handleCloseLinkCollectionToCommunityModal = () =>
    setIsLinkCollectionToCommuniyModalOpen(false);

  const handleSetTargetCommunityId = (id: string) => setTargetCommunityId(id);

  // GET USER COMMUNITIES
  const [getUserCommunities, { loading: userCommunitiesLoader }] =
    useLazyQuery<IUserCommunitiesResponse>(GET_USER_COMMUNITIES, {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        setUserCommunities([...userCommunities, ...res.userCommunities.communities]);
        setUserCommunitiesTotalCount(res.userCommunities.totalCount || 0);
      },
      onError: (error) => displayServerError(error),
    });

  // GET ALL USER COLLECTIONS
  const [getAllUserCollections, { loading: allUserCollectionsLoader, refetch }] =
    useLazyQuery<IUserAllCollectionsReponse>(COLLECTION_GET_ALL_USER_COLLECTIONS, {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        setAllCollections([...allCollections, ...res.allUserCollections.collections]);
        setAllCollectionsCount(res.allUserCollections.totalCount || 0);
      },
      onError: (error) => displayServerError(error),
    });

  // GET MY COLLECTIONS
  const [getMyCollections, { loading: myCollectionsLoader }] =
    useLazyQuery<IUserCollectionResponse>(GET_MY_COLLECTIONS, {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: IUserCollectionResponse) => {
        const collections = [...data.user.myCollections];

        const sortedCollections = collections.sort(
          sortingCollectionsObj[sortValue.value].sort
        );

        setCollections(sortedCollections);
      },
      onError: (error) => displayServerError(error),
    });

  // GET SHARED WITH ME
  const [getSharedWithMeCollections, { loading: sharedWithMeLoader }] =
    useLazyQuery<IUserCollectionResponse>(GET_SHARED_WITH_ME_COLLECTIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (data: IUserCollectionResponse) => {
        const followedCollections = [...data.user.followedCollections];
        const collaboratingCollections = [...data.user.collaboratingCollections];

        const collections = [...followedCollections, ...collaboratingCollections];
        const sortedCollections = collections.sort(
          sortingCollectionsObj[sortValue.value].sort
        );

        setCollections(sortedCollections);
      },
      onError: (error) => displayServerError(error),
    });

  const [unfollowCollection] = useMutation(UNFOLLOW_COLLECTION, {
    onCompleted: (data) => {
      if (!data?.unfollowCollection?.name) {
        const message = t("collections.singleCollection.unfollowCollection");
        displayNotSuccessNotification(message, message);
      }
      displaySuccessNotification(t("collections.singleCollection.successUnfollow"));
      setAllCollections((allCollections) =>
        allCollections.filter(
          (collection) => collection.publicId !== data.unfollowCollection.publicId
        )
      );
      setCollections((collections) =>
        collections.filter(
          (collection) => collection.publicId !== data.unfollowCollection.publicId
        )
      );
    },
    onError: (error) => displayServerError(error),
  });

  const [, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_COLLECTION,
  });

  const createNew = () => {
    const location = {
      pathname: "/portal/collection/new",
      state: { redirect: "/portal/collections/my-collections" },
    };
    history.push(location);
  };

  const handleCopyLink = () => {
    if (shareCollectionUrl) {
      navigator.clipboard.writeText(shareCollectionUrl);
      displaySuccessNotification(t("singleArticle.shareModal.copyLinkNotification"));
    }
  };

  const hadleLinkCollection = (collection: ICollection) => {
    setIsLinkCollectionToCommuniyModalOpen(true);
    setTargetCommunityId("");
    setCollectionToLinkId(collection.publicId);
    setCollectionName(collection.name);
  };

  const handleShareCollection = (collection: ICollection) => {
    setShareModalOpen(true);
    setShareCollectionUrl(
      `${window.location.origin}/portal/collection/${collection.publicId}`
    );
  };

  const handleUnfollowCollection = (collection: ICollection) => {
    unfollowCollection({
      variables: {
        collectionPublicId: collection.publicId,
      },
    });
  };

  const menuDots = (collection: ICollection) => {
    return getMenuOptions(collection, userEmail, {
      link: (collection) => hadleLinkCollection(collection),
      share: (collection) => handleShareCollection(collection),
      unfollow: (collection) => handleUnfollowCollection(collection),
    });
  };

  const handleGetAllUserCollections = (
    size: number,
    skip: number,
    sortingCriteria: string
  ) => {
    getAllUserCollections({
      variables: {
        allUserCollectionsSearchOptions: {
          size,
          skip,
          sortingCriteria,
        },
      },
    });
  };

  const handleGetUserCommunities = (
    size: number,
    skip: number,
    sortingCriteria: string
  ) => {
    getUserCommunities({
      variables: {
        userCommunitySearchOptions: {
          size,
          skip,
          sortingCriteria,
        },
      },
    });
  };
  useEffect(() => {
    handleGetAllUserCollections(
      COLLECTION_PAGINATION_SIZE,
      INITIAL_PAGINATION,
      sortValue.value
    );

    handleGetUserCommunities(
      COMMUNITY_PAGINATION_SIZE,
      INITIAL_PAGINATION,
      sortValue.value
    );
  }, []);

  const resetAndGetUserCommunities = (sortingCriteria: DefaultOption) => {
    setUserCommunities([]);
    handleGetUserCommunities(
      COMMUNITY_PAGINATION_SIZE,
      INITIAL_PAGINATION,
      sortingCriteria.value
    );
  };

  useEffect(() => {
    if (!sortValue) return;

    // PAGINATION FOR ALL COLLECTIONS
    if (activeButton === ALL_COLLECTIONS) {
      // RESET COLLECTIONS ON SORT CHANGE
      setAllCollections([]);
      handleGetAllUserCollections(
        COLLECTION_PAGINATION_SIZE,
        INITIAL_PAGINATION,
        sortValue.value
      );
    }
    // SINCE CREATED BY ME AND SHARED WITH USE OLD USER QUERY SORTING IS STILL DONE ON FE
    else {
      const sortedCollections = collections.sort(
        sortingCollectionsObj[sortValue.value].sort
      );
      setCollections([...sortedCollections]);
    }
  }, [sortValue]);

  useSubscription(Subscription.DELETED_COLLECTION, refetch);

  const [activeButton, setActiveButton] = useState(ALL_COLLECTIONS);

  const handleActiveButtonClick = (e: React.MouseEvent) => {
    const activeButton = e.currentTarget.id;
    setActiveButton(activeButton);
  };

  useEffect(() => {
    setAllCollections([]);

    switch (activeButton) {
      case ALL_COLLECTIONS:
        setCollectionType(ALL_COLLECTIONS);
        handleGetAllUserCollections(
          COLLECTION_PAGINATION_SIZE,
          INITIAL_PAGINATION,
          sortValue.value
        );
        break;
      case CREATED_BY_ME:
        setCollectionType(CREATED_BY_ME);
        getMyCollections();
        break;
      case SHARED_WITH_ME:
        setCollectionType(SHARED_WITH_ME);
        getSharedWithMeCollections();
        break;
      default:
        break;
    }
  }, [activeButton]);

  const handleLoadMoreCommunities = () => {
    userCommunities.length && setUserCommunities([...userCommunities]);

    handleGetUserCommunities(
      COMMUNITY_PAGINATION_SIZE,
      userCommunities.length,
      sortValue.value
    );
  };

  const handleLoadMoreAllUserCollections = () => {
    allCollections.length && setAllCollections([...allCollections]);

    handleGetAllUserCollections(
      COLLECTION_PAGINATION_SIZE,
      allCollections.length,
      sortValue.value
    );
  };

  const isLoading = myCollectionsLoader || sharedWithMeLoader;

  return (
    <div data-testid="collections-container" e2e-test-id="collections-container">
      <Row className={styles.myCollectionsContainer} gutter={[20, 0]}>
        <Col xs={24} sm={24} lg={8} xl={8} xxl={8}>
          <span
            className={styles.communityCollectionsTitle}
            e2e-test-id="community-collections-title"
          >
            {t("collections.communityCollections.title")}
          </span>

          <div
            className={styles.scrollableContainer}
            e2e-test-id="community-collections-cards"
          >
            <div className={styles.scrollableDiv}>
              {userCommunities && (
                <CommunityCollections
                  communities={userCommunities}
                  totalCount={userCommunitiesTotalCount}
                  loadMore={handleLoadMoreCommunities}
                  communitiesLoading={userCommunitiesLoader}
                  isEndlessScroll
                />
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={16} xl={16} xxl={16}>
          <CollectionHeader
            newCollection={
              <Button
                data-testid="new-collection-button"
                e2eTestId="new-collection-button"
                buttonSize="large"
                title={t("collections.buttons.newCollectionButton")}
                aria-label={"add-collection"}
                buttonType="primary"
                buttonIcon={<PlusOutlined className={styles.white} />}
                onClick={createNew}
              />
            }
            allCollections={
              <Button
                idElement={ALL_COLLECTIONS}
                e2eTestId="all-collections-filter"
                buttonSize="large"
                className={
                  activeButton === ALL_COLLECTIONS
                    ? styles.activeButton
                    : styles.notActive
                }
                title={t("collections.buttons.allCollections")}
                onClick={handleActiveButtonClick}
              />
            }
            createdByMe={
              <Button
                idElement={CREATED_BY_ME}
                e2eTestId="created-by-me-filter"
                buttonSize="large"
                className={
                  activeButton === CREATED_BY_ME ? styles.activeButton : styles.notActive
                }
                title={t("collections.buttons.createdByMe")}
                onClick={handleActiveButtonClick}
              />
            }
            sharedWithMe={
              <Button
                idElement={SHARED_WITH_ME}
                e2eTestId="shared-with-me-filter"
                buttonSize="large"
                className={
                  activeButton === SHARED_WITH_ME ? styles.activeButton : styles.notActive
                }
                title={t("collections.buttons.sharedWithMe")}
                onClick={handleActiveButtonClick}
              />
            }
            sort={
              <Select
                label=""
                placeholder=""
                name={"Sort"}
                defaultSelect={sortOptions[0]}
                options={sortOptions}
                showDropdown
                onChange={(option: DefaultOption) => {
                  setSortValue(option);
                  resetAndGetUserCommunities(option);
                }}
                selectWithIcon
                icon={SortIcon}
              />
            }
          />
          <div className={styles.scrollableContainer} e2e-test-id="my-collections-grid">
            <div className={styles.scrollableDiv}>
              <>
                {/* SEPARATE ALL COLLECTIONS SINCE OTHER TYPES DO NOT SUPPORT PAGINATION YET */}
                {/* ONCE IT IS SUPPORTED WE CAN USE ONE COLLECTION GRID COMPONENT AGAIN */}
                {activeButton === ALL_COLLECTIONS ? (
                  <>
                    <CollectionsGrid
                      collections={allCollections}
                      collectionsCount={allCollectionsCount}
                      collectionsLoading={allUserCollectionsLoader}
                      collectionsLoadingMore={allUserCollectionsLoader}
                      loadMore={handleLoadMoreAllUserCollections}
                      menuDots={menuDots}
                      type={collectionType}
                      createNew={createNew}
                      from={COLLECTION_CONTAINER}
                      isEndlessScroll
                    />
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <Loading disableBoxShadow />
                    ) : (
                      <CollectionsGrid
                        collections={collections}
                        collectionsCount={0}
                        collectionsLoading={isLoading}
                        collectionsLoadingMore={false}
                        loadMore={() => {
                          // FUTURE IMPLEMENTATION OF PAGINATION
                        }}
                        menuDots={menuDots}
                        type={collectionType}
                        createNew={createNew}
                        from={COLLECTION_CONTAINER}
                      />
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </Col>
      </Row>
      {isLinkCollectionToCommuniyModalOpen && (
        <LinkCollectionToCommunityModal
          isOpen={isLinkCollectionToCommuniyModalOpen}
          collectionId={collectionToLinkId}
          collectionName={collectionName}
          targetCommunityId={targetCommunityId}
          onClose={handleCloseLinkCollectionToCommunityModal}
          onSetTargetCommunityId={handleSetTargetCommunityId}
        />
      )}
      <ShareModal
        open={shareModalOpen}
        close={() => setShareModalOpen(false)}
        customUrl={shareCollectionUrl}
        copyLink={handleCopyLink}
        share={shareSuccessMessage}
      />
    </div>
  );
};

export default CollectionsContainer;
