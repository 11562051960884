import { ReactElement, useState } from "react";

import { useTranslation } from "react-i18next";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";

import { useHandleLinkClick } from "@customHooks/useHandleLinkClick";

import { handleAcceptingRedirect } from "@utils/helpers";

import { ReadMore } from "../ReadMore/ReadMore";

interface ILinkedTextProps {
  text: string;
  hasReadMore?: boolean;
  isReadMoreStyled?: boolean;
  children?: ReactElement;
  e2eTestId?: string;
}

export const LinkedText = ({
  text,
  hasReadMore,
  children,
  e2eTestId,
  isReadMoreStyled,
  ...props
}: ILinkedTextProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { externalUrl, handleLinkTextClick } = useHandleLinkClick({
    onExternalLinkClick: () => setIsModalOpen(true),
  });

  const renderText = () => {
    return (
      <>
        <div
          e2e-test-id={e2eTestId}
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleLinkTextClick}
          {...props}
        />
        {children}
      </>
    );
  };

  return (
    <>
      {hasReadMore ? (
        <ReadMore isStyled={isReadMoreStyled}>{renderText()}</ReadMore>
      ) : (
        renderText()
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        accept={() => handleAcceptingRedirect(externalUrl, setIsModalOpen)}
        url={externalUrl}
        closeModal={() => setIsModalOpen(false)}
        messageTitle={t("chat.group.urlModal.title")}
        messageContent={t("chat.group.urlModal.text")}
      />
    </>
  );
};
