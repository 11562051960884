import { gql } from "@apollo/client";

const UPLOAD_FILE_TO_COLLECTION = gql`
  mutation addFilesToCollection($addFilesToCollection: AddFilesToCollectionInputType!) {
    addFilesToCollection(addFilesToCollection: $addFilesToCollection) {
      files {
        name
      }
    }
  }
`;

export default UPLOAD_FILE_TO_COLLECTION;
