/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SEARCH_COLLECTIONS } from "@graphql/collection/queries";

import {
  COLLECTIONS_GRID_SIZE,
  SORTING_A_Z,
  SORTING_MOST_ACTIVE,
  SORTING_MOST_RECENT,
  SORTING_MOST_RELEVANT,
  SORTING_Z_A,
} from "@constants/constants";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Popover from "@components/Popover/Popover";
import stylesDropdown from "@components/SortDropdown/SortDropdown.module.scss";
import { ReactComponent as DropdownIndicator } from "@images/dropdownIndicator.svg";
import SearchMoreIcon from "@images/searchMore.svg";
import styles from "./SearchedCollections.module.scss";

import CollectionsGrid from "@components/CollectionsGrid/CollectionsGrid";
import { SortingEnum } from "types/sorting";

import { ICollection, ICollectionsResponse } from "@graphql/collection/type";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
interface ISearchedCollectionsProps {
  searchTerm: string;
}

type SearchType = {
  label: string;
  value: SortingEnum;
};

export const SearchedCollections = ({ searchTerm }: ISearchedCollectionsProps) => {
  const { t } = useTranslation();

  const menuItems: SearchType[] = [
    { value: SORTING_MOST_ACTIVE, label: t("collections.sorting.mostActive") },
    { value: SORTING_MOST_RECENT, label: t("collections.sorting.mostRecent") },
    {
      value: SORTING_MOST_RELEVANT,
      label: t("collections.sorting.mostRelevant"),
    },
    {
      value: SORTING_A_Z,
      label: t("collections.sorting.alphabeticalAZ"),
    },
    {
      value: SORTING_Z_A,
      label: t("collections.sorting.alphabeticalZA"),
    },
  ];

  const [menuState, setMenuState] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SearchType>(menuItems[0]);

  const [collections, setCollections] = useState<Array<ICollection>>([]);
  const [collectionsCount, setCollectionsCount] = useState<number>(4);
  const [loadingMore, setLoadingMore] = useState(false);

  const [getCollections, { loading: loadingInit }] = useLazyQuery<ICollectionsResponse>(
    SEARCH_COLLECTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ collections: collectionData }) => {
        if (!collectionData) return;
        setCollections([...collections, ...collectionData.collections]);
        setCollectionsCount(collectionData.totalCount);
        loadingMore && setLoadingMore(false);
      },
      onError: (error) => displayServerError(error),
    }
  );

  const [getCollectionsSorting, { loading: loadingSorted }] =
    useLazyQuery<ICollectionsResponse>(SEARCH_COLLECTIONS, {
      fetchPolicy: "no-cache",
      onCompleted: ({ collections: collectionData }) => {
        if (!collectionData) return;
        setCollections(collectionData.collections);
        setCollectionsCount(collectionData.totalCount);
        loadingMore && setLoadingMore(false);
      },
      onError: (error) => displayServerError(error),
    });

  const loadCollections = () => {
    !loadingInit && setLoadingMore(true);
    getCollections({
      variables: {
        collectionSearch: {
          size: COLLECTIONS_GRID_SIZE,
          skip: collections?.length,
          text: searchTerm,
          sortingCriteria: sortBy.value,
        },
      },
    });
  };

  useEffect(() => {
    loadCollections();
  }, []);

  useEffect(() => {
    getCollectionsSorting({
      variables: {
        collectionSearch: {
          size: COLLECTIONS_GRID_SIZE,
          skip: 0,
          text: searchTerm,
          sortingCriteria: sortBy.value,
        },
      },
    });
  }, [sortBy]);

  const menu = (
    <div className={stylesDropdown.menuItemsContainer}>
      {menuItems.map((menuItem) => {
        return (
          <div className={stylesDropdown.menuItemWrapper} key={menuItem.value}>
            <div
              className={stylesDropdown.menuItem}
              role="menuitem"
              onClick={() => {
                setSortBy(menuItem as SearchType);
                setMenuState(false);
              }}
            >
              {menuItem.label}
            </div>
          </div>
        );
      })}
    </div>
  );

  return loadingInit || loadingSorted ? (
    <Loading disableBoxShadow />
  ) : !collections.length ? (
    <div
      data-testid="no-results-found"
      aria-label="No Results Found"
      className={styles.noResultsContainer}
    >
      <div className={styles.imgWrapper}>
        <img src={SearchMoreIcon} className={styles.img} />
      </div>
      <span className={styles.noResultsText}>
        {t("collections.searchCollections.noSearchResult")}
      </span>
      <span>{t("collections.searchCollections.tryAgainSearch")}</span>
    </div>
  ) : (
    <div className={styles.girdOuter} data-testid="search-grid-container">
      {collectionsCount && (
        <p className={styles.subHeader}>
          {t("collections.searchCollections.resultsCount", {
            count: collectionsCount,
          })}
        </p>
      )}

      <div className={styles.resultsTopBar}>
        <h1 className={styles.header}>
          {t("collections.searchCollections.resultsText", {
            searchText: searchTerm,
          })}
        </h1>
        <div>
          <Popover
            menu={menu}
            placement="bottom"
            menuState={menuState}
            setMenuState={(state) => setMenuState(state)}
            childToWrap={
              <Button
                aria-label="popover"
                title={`${t("sortDropdown.title")} ${sortBy.label}`}
                wrapperClassNames={styles.buttonWrapper}
                buttonIcon={
                  <DropdownIndicator
                    style={{
                      marginRight: 5,
                    }}
                  />
                }
              />
            }
          />
        </div>
      </div>

      <CollectionsGrid
        collections={collections}
        loadMore={loadCollections}
        collectionsLoading={loadingInit || loadingSorted}
        collectionsCount={collectionsCount}
        collectionsLoadingMore={loadingMore}
      />
    </div>
  );
};
