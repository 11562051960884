import { LinkedText } from "@components/DataDisplay/LinkedText/LinkedText";
import Tabs from "@components/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import EventAttachmentTab from "./EventAttachmentTab/EventAttachmentTab";
import styles from "./EventDetailTabs.module.scss";

interface IEventDetailTabs {
  description: string;
  attachments: Array<{ name: string; size: number }>;
  attachmentsDirectory: string;
  externalUrl?: string;
}

const EventDetailTabs = ({
  description,
  attachments,
  attachmentsDirectory,
  externalUrl,
}: IEventDetailTabs) => {
  const { t } = useTranslation();

  const EVENT_DETAIL_TABS = [
    {
      name: t("eventDetail.eventDetailTabs.description"),
      content: (
        <LinkedText text={description}>
          {externalUrl ? (
            <div className={styles.externalUrl}>
              <a href={externalUrl} target="_blank" rel="noreferrer">
                {t("eventDetail.externalLinkText")}
              </a>
            </div>
          ) : (
            <></>
          )}
        </LinkedText>
      ),
    },
    {
      name: t("eventDetail.eventDetailTabs.attachments"),
      content: (
        <EventAttachmentTab
          attachments={attachments}
          attachmentsDirectory={attachmentsDirectory}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Tabs tabs={EVENT_DETAIL_TABS} size="large" fullWidthTabBar />
    </div>
  );
};

export default EventDetailTabs;
