import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";

import { INotification } from "@graphql/notification/types";

import ACCEPT_OR_DECLINE_INVITATION from "@graphql/mutations/acceptOrDeclineInvitation";

import { getCustomNotificationByCode } from "@services/NotificationService/NotificationHelper";

import { Buttons } from "../../Buttons/Buttons";
import { GeneralNotificationPart } from "../GeneralNotificationPart/GeneralNotificationPart";

export const MemberInvitedToCommunity = (notification: INotification) => {
  const { t } = useTranslation();
  const notificationData = JSON.parse(notification.data);
  const [acceptOrDeclineInvitation] = useMutation(ACCEPT_OR_DECLINE_INVITATION);
  const [isAnswerInProgress, setIsAnswerInProgress] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [isUserNotAllowedModalOpened, setIsUserNotAllowedModalOpened] =
    useState<boolean>(false);

  const acceptOrDeclineInvitationAction = (isAcceptedInvitation: boolean) => {
    setIsAnswerInProgress(true);
    acceptOrDeclineInvitation({
      variables: {
        invitationResponse: {
          sendbirdId: notificationData.SendbirdId,
          inviterEmail: notificationData.UserEmail,
          isAccepted: isAcceptedInvitation,
        },
      },
    }).catch((error) => {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      const msg = getCustomNotificationByCode(errorCode);
      setErrorCode(msg);
      setIsUserNotAllowedModalOpened(true);
    });
  };

  const closeCommunityAccessModal = () => {
    setIsUserNotAllowedModalOpened(false);
  };

  return (
    <>
      <GeneralNotificationPart
        notificationData={notificationData}
        links={[
          {
            text: `${notificationData.ResourceName}`,
            path: `/portal/community/community-page/${notificationData.SendbirdId}`,
          },
        ]}
        text={"requests.inviteAsMember"}
      />
      <Buttons
        buttonSubmit={{
          disabled: isAnswerInProgress,
          onClick: () => acceptOrDeclineInvitationAction(true),
        }}
        buttonDecline={{
          disabled: isAnswerInProgress,
          onClick: () => acceptOrDeclineInvitationAction(false),
        }}
      />
      <ConfirmationModal
        isOpen={isUserNotAllowedModalOpened}
        messageTitle={t("community.communityAccess.title")}
        messageContent={errorCode}
        closeModal={() => setIsUserNotAllowedModalOpened(false)}
        goBack={() => closeCommunityAccessModal()}
      />
    </>
  );
};
