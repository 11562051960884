import { gql } from "@apollo/client";

export const ADD_COLLECTION = gql`
  mutation addCollection($collection: CollectionInputType!) {
    addCollection(collection: $collection) {
      name
      publicId
    }
  }
`;

export const ADD_GLOBAL_CURATED_COLLECTION = gql`
  mutation addGlobalCuratedCollection($collection: CollectionInputType!) {
    addGlobalCuratedCollection(collection: $collection) {
      name
      publicId
    }
  }
`;

export const ADD_COMMUNITY_COLLECTION = gql`
  mutation addCommunityCollection($collection: AddCommunityCollectionInputType!) {
    addCommunityCollection(addCommunityCollectionRequest: $collection) {
      name
      publicId
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($collectionPublicId: ID!) {
    deleteCollection(collectionPublicId: $collectionPublicId) {
      publicId
    }
  }
`;

export const EDIT_COLLECTION = gql`
  mutation editCollection($editCollection: UpdateCollectionInputType!) {
    editCollection(editCollection: $editCollection) {
      name
      description
      isPublic
      interests {
        name
      }
    }
  }
`;

export const ADD_ARTICLE_TO_COLLECTION = gql`
  mutation AddArticleToCollection(
    $addArticleToCollection: AddArticleToCollectionInputType!
  ) {
    addArticleToCollection(addArticleToCollection: $addArticleToCollection) {
      publicId
      name
    }
  }
`;

export const FOLLOW_COLLECTION = gql`
  mutation followCollection($collectionPublicId: ID!) {
    followCollection(collectionPublicId: $collectionPublicId) {
      name
      publicId
    }
  }
`;

export const REMOVE_ARTICLE_FROM_COLLECTION = gql`
  mutation removeAnArticleFromCollection(
    $removeArticleFromCollection: RemoveArticleFromCollectionInputType!
  ) {
    removeAnArticleFromCollection(
      removeArticleFromCollection: $removeArticleFromCollection
    ) {
      publicId
    }
  }
`;

export const UNFOLLOW_COLLECTION = gql`
  mutation unfollowCollection($collectionPublicId: ID!) {
    unfollowCollection(collectionPublicId: $collectionPublicId) {
      name
      publicId
    }
  }
`;

export const ADD_FOLDER_TO_COLLECTION = gql`
  mutation AddFolderToCollection($folder: AddFolderToCollectionInputType) {
    addFolderToCollection(folderToAdd: $folder) {
      name
      publicId
    }
  }
`;

export const LINK_COLLECTION_TO_COMMUNITY = gql`
  mutation linkPublicCollectionToCommunity(
    $linkPublicCollectionToCommunity: LinkPublicCollectionToCommunityInputType!
  ) {
    linkPublicCollectionToCommunity(
      linkPublicCollectionToCommunity: $linkPublicCollectionToCommunity
    ) {
      name
    }
  }
`;

export const UNLINK_COLLECTION_FROM_COMMUNITY = gql`
  mutation UnlinkCollectionFromCommunity(
    $inputType: UnlinkPublicCollectionFromCommunityInputType!
  ) {
    unlinkPublicCollectionFromCommunity(unlinkPublicCollectionFromCommunity: $inputType)
  }
`;

export const MOVE_FILE_TO_COLLECTION = gql`
  mutation MoveFileToCollection(
    $moveFileToCollectionRequest: MoveFileToCollectionInputType!
  ) {
    moveFileToCollection(moveFileToCollectionRequest: $moveFileToCollectionRequest)
  }
`;

export const MOVE_FILE_TO_FOLDER = gql`
  mutation MoveFileToFolder($moveFileToFolderRequest: MoveFileToFolderInputType!) {
    moveFileToFolder(moveFileToFolderRequest: $moveFileToFolderRequest)
  }
`;

export const MOVE_LINK_TO_COLLECTION = gql`
  mutation MoveLinkToCollection(
    $moveLinkToCollectionRequest: MoveLinkToCollectionInputType!
  ) {
    moveLinkToCollection(moveLinkToCollectionRequest: $moveLinkToCollectionRequest)
  }
`;

export const MOVE_LINK_TO_FOLDER = gql`
  mutation MoveLinkToFolder($moveLinkToFolderRequest: MoveLinkToFolderInputType!) {
    moveLinkToFolder(moveLinkToFolderRequest: $moveLinkToFolderRequest)
  }
`;

export const MOVE_FOLDER_TO_COLLECTION = gql`
  mutation MoveFolderToCollection(
    $moveFolderToCollectionRequest: MoveFolderToCollectionInputType!
  ) {
    moveFolderToCollection(moveFolderToCollectionRequest: $moveFolderToCollectionRequest)
  }
`;

export const MOVE_FOLDER_TO_FOLDER = gql`
  mutation MoveFolderToFolder($moveFolderToFolderRequest: MoveFolderToFolderInputType!) {
    moveFolderToFolder(moveFolderToFolderRequest: $moveFolderToFolderRequest)
  }
`;
