// ---- COMMUNITY NOTIFICATIONS START ----
export const COMMUNITY_CREATED = "communityCreated";
export const DELETED_COMMUNITY = "communityDeleted";
export const JOIN_PRIVATE_COMMUNITY_ACCEPTED = "joinPrivateCommunityAccepted";
export const JOIN_PRIVATE_COMMUNITY_DECLINED = "joinPrivateCommunityDeclined";
export const JOINED_COMMUNITY = "joinedPublicCommunity";
export const ADDED_TO_COMMUNITY = "addedToCommunity";
export const REMOVED_FROM_COMMUNITY = "removedFromCommunity";
export const BANNED_FROM_COMMUNITY = "bannedFromCommunity";
export const MEMBER_IN_COMMUNITY_PROMOTED = "memberInCommunityPromoted";
export const ADMIN_IN_COMMUNITY_DEMOTED = "adminInCommunityDemoted";
export const UPDATED_COMMUNITY_CHANNELS = "updatedCommunityChannels";
export const USER_ACCEPTED_COMMUNITY_JOIN_INVITATION =
  "userAcceptedCommunityJoinInvitation";
export const USER_DECLINED_COMMUNITY_JOIN_INVITATION =
  "userDeclinedCommunityJoinInvitation";
// -- COMMUNITY UPDATE NOTIFICATIONS START --
export const COMMUNITY_NAME_UPDATED = "communityNameUpdated";
export const COMMUNITY_PRIVACY_UPDATED = "communityPrivacyUpdated";
// -- COMMUNITY UPDATE NOTIFICATIONS END ----
export const JOIN_PRIVATE_COMMUNITY_REQUESTED = "joinPrivateCommunityRequested";
export const CANCEL_JOIN_PRIVATE_COMMUNITY_REQUEST = "cancelJoinPrivateCommunityRequest";
export const MEMBER_INVITED_TO_COMMUNITY = "memberInvitedToCommunity";
export const ADMIN_INVITED_TO_COMMUNITY = "adminInvitedToCommunity";
export const USER_ADDED_AS_COMMUNITY_MEMBER_FOR_USER =
  "userAddedAsCommunityMemberForUser";
export const USER_ADDED_AS_COMMUNITY_MEMBER_FOR_COMMUNITY_CREATOR_OR_ADMIN =
  "userAddedAsCommunityMemberForCommunityCreatorOrAdmin";
export const COMMUNITY_CREATOR_CHANGED = "communityCreatorChanged";
// ---- COMMUNITY NOTIFICATIONS END ----

// ---- EVENT NOTIFICATIONS START ----
export const DELETED_EVENT = "eventDeleted";
export const EVENT_START_REMINDER = "eventStartingReminder";
export const EVENT_RECORDINGS_SAVED = "eventRecordingsSaved";

// -- EVENT UPDATE NOTIFICATIONS START --
export const EVENT_NAME_UPDATED = "eventNameUpdated";
export const EVENT_START_TIME_UPDATED = "eventStartTimeUpdated";
export const EVENT_END_TIME_UPDATED = "eventEndTimeUpdated";
export const EVENT_PRIVACY_UPDATED = "eventPrivacyUpdated";
export const EVENT_LINKED_TO_COMMUNITY = "eventLinkedToCommunity";
export const EVENT_ATTACHMENTS_UPDATED = "eventAttachmentsUpdated";
// -- EVENT UPDATE NOTIFICATIONS END --
// ---- EVENT NOTIFICATIONS END ----

// ---- COLLECTION NOTIFICATIONS START ----
export const DELETED_COLLECTION = "deletedCollection";
export const DELETED_ARTICLE_FROM_COLLECTION = "deletedArticleFromCollection";

// Accept & Decline Collection
export const USER_INVITED_AS_COLLECTION_COLLABORATOR =
  "userInvitedAsCollectionCollaborator";
export const USER_ACCEPTED_INVITATION_TO_BE_COLLECTION_COLLABORATOR =
  "userAcceptedInvitationToBeCollectionCollaborator";
export const USER_DECLINED_INVITATION_TO_BE_COLLECTION_COLLABORATOR =
  "userDeclinedInvitationToBeCollectionCollaborator";
export const CONTRIBUTOR_REMOVED_FROM_COLLECTION = "contributorRemovedFromCollection";
// Community Collection
export const USER_ADDED_AS_COMMUNITY_COLLECTION_COLLABORATOR =
  "userAddedAsCommunityCollectionCollaborator";
// ---- COLLECTION NOTIFICATIONS END ----

export const FEEDBACK_ADDED = "feedbackAdded";
