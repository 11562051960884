import { ActionTypes } from "../search/actions";
import {
  CLEAR_SEARCH_EVENT_ID,
  SET_PREVIOUS_PORTAL_URL,
  SET_SEARCH_EVENT_ID,
} from "../search/constants";
import { ISearchQuery } from "../search/types";
import { RootState } from "../store";

const initialState: ISearchQuery = {
  searchEventId: "",
  previousPortalUrl: "",
};

const searchQueryReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case SET_SEARCH_EVENT_ID:
      return {
        ...state,
        searchEventId: action.payload,
      };
    case CLEAR_SEARCH_EVENT_ID:
      return {
        ...state,
        searchEventId: action.payload,
      };
    case SET_PREVIOUS_PORTAL_URL:
      return {
        ...state,
        previousPortalUrl: action.payload,
      };
    default:
      return state;
  }
};

export const searchEventIdQuery = (state: RootState) => state.searchQuery.searchEventId;

export const previousPortalUrl = (state: RootState) =>
  state.searchQuery.previousPortalUrl;

export default searchQueryReducer;
