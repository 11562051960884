/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Table from "@components/Table/Table";
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_NUMBER_OF_ROWS,
  INITIAL_START_PAGE,
  STORAGE_IMAGE_TYPE_USER,
} from "@constants/constants";

import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import Avatar from "@images/avatar.svg";
import getStorageImage from "@utils/getStorageImage";
import { truncateStringIfTooLong } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { IUserImageResponse } from "types/user";
import SingleFeedback from "../SingleFeedback/SingleFeedback";
import styles from "./Feedbacks.module.scss";

import { GET_FEEDBACKS } from "@graphql/feedbacks/queries";
import { IFeedbacks } from "@graphql/feedbacks/types";
import UserDrawer from "@modules/UserDrawer/UserDrawer";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { formatDate } from "@utils/date/helper";
import withUser from "../../../hoc/withUser";

const FeedbackTable = () => {
  const PAGE_SIZE_OPTIONS = ["10"];

  const history = useHistory();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<Array<Record<string, unknown>>>();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_START_PAGE);
  const [totalNumberOfRows, setTotalNumberOfRows] =
    useState<number>(INITIAL_NUMBER_OF_ROWS);

  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();
  const [userImages, setUserImages] = useState<{ image: string; email: string }[]>();
  const [getUserImages] = useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
    fetchPolicy: "cache-and-network",
    onCompleted: async (res: IUserImageResponse) => {
      const userPhotosInfo = res.profileImages;
      setUserImages(
        userPhotosInfo.map((photo) => {
          return {
            image: getStorageImage({
              image: photo.userPhotoName,
              directoryName: photo.imageDirectory,
              type: STORAGE_IMAGE_TYPE_USER,
            }),
            email: photo.email,
          };
        })
      );
    },
  });

  const [getFeedbacks, { loading: feedbacksLoader }] = useLazyQuery<IFeedbacks>(
    GET_FEEDBACKS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (res) => {
        getUserImages({
          variables: {
            profileImagesInput: {
              userEmails: res.feedbacks.feedbacks.map(
                (feedback: { reporter: { email: string } }) => feedback.reporter.email
              ),
            },
          },
        });

        setTableData(res.feedbacks.feedbacks);
        setTotalNumberOfRows(res.feedbacks.totalCount);
      },
      onError: (error) => {
        displayNotSuccessNotification(error);
      },
    }
  );

  useEffect(() => {
    getFeedbacks({
      variables: {
        feedbackSearch: {
          size: DEFAULT_PAGE_SIZE,
          skip: 0,
          text: "",
        },
      },
    });
  }, []);

  const tableColumns = [
    {
      title: t("adminPanel.feedbacks.table.creationTime"),
      dataIndex: "creationTime",
      key: "creationTime",
      render: formatDate,
      width: 150,
    },
    {
      title: t("adminPanel.feedbacks.table.reportedBy"),
      dataIndex: "reporter",
      key: "reporter",
      render: (reporter: { fullName: string; email: string }) => {
        const userAvatar = userImages
          ? userImages?.find((image) => image.email === reporter.email)?.image
          : Avatar;
        return (
          <div
            className={styles.reporter}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedUserEmail(reporter.email);
            }}
          >
            <div
              style={{
                backgroundImage: `url(${userAvatar})`,
              }}
              className={styles.avatar}
            ></div>
            <div>
              <div>{reporter?.fullName}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: t("adminPanel.feedbacks.table.feedback"),
      dataIndex: "subject",
      key: "subject",
      render: (subject: string) => {
        return <div>{truncateStringIfTooLong(subject, 50)}</div>;
      },
    },
  ];

  const openFeedback = (record: Record<string, unknown>) => {
    history.push(`/portal/admin-panel/feedbacks/${record.publicId}`);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    getFeedbacks({
      variables: {
        feedbackSearch: {
          size: DEFAULT_PAGE_SIZE,
          skip: (page - 1) * pageSize,
          text: "",
        },
      },
    });

    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const UserDrawerWithUser = withUser(UserDrawer);

  return (
    <div className={styles.pageWrapper}>
      <h2 className={styles.title}>{t("adminPanel.feedbacks.title")}</h2>
      <Switch>
        <Route path="/portal/admin-panel/feedbacks/:id">
          <SingleFeedback />
        </Route>
        <Route path="/portal/admin-panel/feedbacks" exact>
          {tableData && (
            <Table
              onClick={openFeedback}
              columns={tableColumns}
              data={tableData}
              totalNumberOfRows={totalNumberOfRows}
              loadingData={feedbacksLoader}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPagesCount={Math.ceil(totalNumberOfRows / pageSize)}
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          )}
        </Route>
      </Switch>
      <UserDrawerWithUser
        email={selectedUserEmail ?? ""}
        closeInfoDrawer={() => setSelectedUserEmail("")}
        communityOptions={<></>}
      />
    </div>
  );
};

export default FeedbackTable;
