/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Button from "@components/Button/Button";
import EventCard from "@components/EventCard/EventCard";
import Loading from "@components/Loading/Loading";
import GET_USER_INFO_HOSTING_EVENTS from "@graphql/queries/getUserInfoHostingEvents";
import { displayNotSuccessNotification } from "@services/NotificationService/NotifacitonService";
import { formatDate } from "@utils/eventsHelper";
import { getDateInUtc } from "@utils/helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEvent } from "types/event";

import userDrawerStyles from "../UserDrawer.module.scss";

import styles from "./UserDrawerEvents.module.scss";

interface UserDrawerEventsProps {
  userEmail: string;
}

const UserDrawerEvents = ({ userEmail }: UserDrawerEventsProps) => {
  const { t } = useTranslation();
  const [userEvents, setUserEvents] = useState<IEvent[]>([]);
  const [userEventsTotalCount, setUserEventsTotalCount] = useState<number>(0);

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  const [getUserEvents, { loading: loadingEvents }] = useLazyQuery(
    GET_USER_INFO_HOSTING_EVENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setUserEvents([...userEvents, ...data.userInfo.hostingEvents]);
        setUserEventsTotalCount(data.userInfo.hostingEventsTotalCount);
      },
      onError: (error) => displayNotSuccessNotification(error),
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (loadingEvents) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loadingEvents) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMoreUserEvents();
      setShouldLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const loadMoreUserEvents = () => {
    getUserEvents({
      variables: {
        email: userEmail,
        hostingEventSearch: { size: 6, skip: userEvents?.length, from: getDateInUtc() },
      },
    });
  };

  useEffect(() => {
    if (!userEmail) return;

    getUserEvents({
      variables: {
        email: userEmail,
        hostingEventSearch: { size: 6, skip: 0, from: getDateInUtc() },
      },
    });
  }, [userEmail]);

  return (
    <>
      {loadingEvents && (
        <div className={userDrawerStyles.loader}>
          <Loading disableBoxShadow />
        </div>
      )}

      {!!userEvents.length && (
        <>
          {userEvents.map((event: IEvent) => (
            <EventCard
              inUserDrawer={true}
              startDate={formatDate(event?.startDate, t.language)}
              key={event.publicId}
              endDate={formatDate(event?.endDate, t.language)}
              name={event?.name}
              userName={event?.eventHost?.fullName}
              event={event}
            />
          ))}
        </>
      )}

      {!!userEvents.length && userEventsTotalCount > userEvents.length && (
        <Button
          data-testid="view-more-collections"
          aria-label="view-more-collections"
          title={t("collections.buttons.viewMore")}
          wrapperClassNames={styles.loadMoreButton}
          buttonIcon={<span className={styles.plusIcon}>+</span>}
          onClick={loadMoreUserEvents}
          loading={loadingEvents}
          disabled={loadingEvents}
          customRef={loadMoreElementRef}
        />
      )}
    </>
  );
};

export default UserDrawerEvents;
