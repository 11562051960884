export enum Subscription {
  JOIN_PRIVATE_COMMUNITY_REQUESTED = "joinPrivateCommunityRequested",
  CANCEL_JOIN_PRIVATE_COMMUNITY_REQUEST = "cancelJoinPrivateCommunityRequest",
  JOIN_PRIVATE_COMMUNITY_ACCEPTED = "joinPrivateCommunityAccepted",
  JOIN_PRIVATE_COMMUNITY_DECLINED = "joinPrivateCommunityDeclined",
  COMMUNITY_CREATED = "communityCreated",
  JOINED_COMMUNITY = "joinedPublicCommunity",
  ADDED_TO_COMMUNITY = "addedToCommunity",
  ADDED_TO_EXISTING_COMMUNITY = "memberInvitedToCommunity",
  REMOVED_FROM_COMMUNITY = "removedFromCommunity",
  BANNED_FROM_COMMUNITY = "bannedFromCommunity",
  MEMBER_IN_COMMUNITY_PROMOTED = "memberInCommunityPromoted",
  ADMIN_IN_COMMUNITY_DEMOTED = "adminInCommunityDemoted",
  UPDATED_COMMUNITY_CHANNELS = "updatedCommunityChannels",
  COMMUNITY_UPDATED = "communityUpdated",
  MEMBER_INVITED_TO_COMMUNITY = "memberInvitedToCommunity",
  ADMIN_INVITED_TO_COMMUNITY = "adminInvitedToCommunity",
  USER_ACCEPTED_COMMUNITY_JOIN_INVITATION = "userAcceptedCommunityJoinInvitation",
  USER_DECLINED_COMMUNITY_JOIN_INVITATION = "userDeclinedCommunityJoinInvitation",
  DELETED_COMMUNITY = "communityDeleted",
  EVENT_START_REMINDER = "eventStartingReminder",
  EVENT_RECORDINGS_SAVED = "eventRecordingsSaved",
  DELETED_COLLECTION = "deletedCollection",
  DELETED_ARTICLE_FROM_COLLECTION = "deletedArticleFromCollection",
  EVENT_UPDATED = "eventUpdated",
  DELETED_EVENT = "eventDeleted",
  // Accept & Decline Collection
  USER_INVITED_AS_COLLECTION_COLLABORATOR = "userInvitedAsCollectionCollaborator",
  USER_ACCEPTED_INVITATION_TO_BE_COLLECTION_COLLABORATOR = "userAcceptedInvitationToBeCollectionCollaborator",
  USER_DECLINED_INVITATION_TO_BE_COLLECTION_COLLABORATOR = "userDeclinedInvitationToBeCollectionCollaborator",
  CONTRIBUTOR_REMOVED_FROM_COLLECTION = "contributorRemovedFromCollection",
  // Community Collection
  USER_ADDED_AS_COMMUNITY_COLLECTION_COLLABORATOR = "userAddedAsCommunityCollectionCollaborator",
  // New Notification
  NEW_NOTIFICATION = "newNotification",
  FEEDBACK_ADDED = "feedbackAdded",
}
