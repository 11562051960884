import { useMutation } from "@apollo/client";

import { useTranslation } from "react-i18next";

import {
  displayCustomNotSuccessNotification,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import INVITE_USER from "./mutation";
import { IInviteUserRequest, IInviteUserResponse } from "./type";

interface IProps {
  onCompleted?: () => void;
  onError?: () => void;
}

export const useInviteUser = ({ onCompleted, onError }: IProps) => {
  const { t } = useTranslation();

  const [inviteUser, { loading: isInvitingUser }] = useMutation<
    IInviteUserResponse,
    IInviteUserRequest
  >(INVITE_USER, {
    onCompleted: ({ createUser }) => {
      displaySuccessNotification(
        t("adminPanel.users.inviteUserModal.userInvited", {
          user: createUser.fullName,
        })
      );

      onCompleted?.();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      const errorCode = error?.networkError?.result?.errors[0]?.extensions?.code;
      displayCustomNotSuccessNotification(errorCode);

      onError?.();
    },
  });

  return { inviteUser, isInvitingUser };
};
