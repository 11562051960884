import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { termsContent } from "@modules/TermsAndConditions/content";

import BackButton from "@components/BackButton/BackButton";
import Document from "@components/Document/Document";

import styles from "./TermsAndConditionsPage.module.scss";

interface ITermsAndConditionsPageProps {
  isBackButtonVisible?: boolean;
}

const TermsAndConditionsPage = ({
  isBackButtonVisible,
}: ITermsAndConditionsPageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <>
      <div className={styles.container}>
        {isBackButtonVisible && (
          <BackButton label={t("termsAndConditions.buttons.back")} />
        )}
        <Document document={termsContent} />
      </div>
    </>
  );
};

export default TermsAndConditionsPage;
