export enum Menu {
  DASHBOARD = "DASHBOARD",
  COMMUNITY = "COMMUNITY",
  EVENT = "EVENT",
  DRIVE = "DRIVE",
  SUPPORT = "SUPPORT",
}

export enum MenuType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}
