import { gql } from "@apollo/client";

export const GET_FEEDBACKS = gql`
  query feedbacks($feedbackSearch: FeedbackSearchInputType!) {
    feedbacks(feedbackSearch: $feedbackSearch) {
      totalCount
      feedbacks {
        creationTime
        subject
        publicId
        autoIncrementedId
        content
        reporter {
          fullName
          email
        }
      }
    }
  }
`;
