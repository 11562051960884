import { gql } from "@apollo/client";
import { COLLECTION_FRAGMENT } from "./fragments";

const COMMUNITY_COLLECTION_GET_COMMUNITY = gql`
  ${COLLECTION_FRAGMENT}
  query Community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      name
      sendbirdId
      communityType
      communityBannerUrl
      communityBannerName
      communityBannerDirectory
      defaultCollection {
        ...CollectionFields
        collectionType
      }
      interests {
        name
      }
      members {
        publicId
        fullName
        email
        organization {
          name
          userRole
        }
      }
      admins {
        publicId
        fullName
        email
        organization {
          name
          userRole
        }
      }
      communityCreator {
        publicId
        fullName
        email
        organization {
          name
          userRole
        }
      }
    }
  }
`;

const COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query allCommunityCollections(
    $communityCollectionSearch: CommunityCollectionSearchInputType!
  ) {
    allCommunityCollections(communityCollectionSearch: $communityCollectionSearch) {
      totalCount
      collections {
        collectionType
        ...CollectionFields
      }
    }
  }
`;

const COMMUNITY_COLLECTION_GET_PUBLIC_COMMUNITY_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query publicCommunityCollections(
    $communityCollectionSearch: CommunityCollectionSearchInputType!
  ) {
    publicCommunityCollections(communityCollectionSearch: $communityCollectionSearch) {
      totalCount
      collections {
        ...CollectionFields
      }
    }
  }
`;

const COMMUNITY_COLLECTION_GET_PRIVATE_COMMUNITY_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query privateCommunityCollections(
    $communityCollectionSearch: CommunityCollectionSearchInputType!
  ) {
    privateCommunityCollections(communityCollectionSearch: $communityCollectionSearch) {
      totalCount
      collections {
        ...CollectionFields
      }
    }
  }
`;

const GET_COMMUNITY_FOR_COMMUNITY_CHANEL_PAGE = gql`
  query Community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      name
      description
      sendbirdId
      communityType
      communityImageUrl
      communityBannerUrl
      communityBannerName
      communityEvents {
        publicId
        name
        eventImageName
        eventType
        imageDirectory
        startDate
        endDate
        eventHost {
          email
          fullName
        }
      }
      interests {
        name
      }
      members {
        publicId
        fullName
        canReceiveMessages
        pronouns
        bio
        interests {
          name
        }
        country {
          name
        }
        countries {
          name
        }
        globalInterestLocation
        email
        userDefinedInterests
        userSecretSuperPower
        problemUserWouldLikeToSolve
        userLinkedInLink
        organization {
          name
          userRole
        }
      }
      joinRequests {
        user {
          email
        }
      }
      admins {
        publicId
        canReceiveMessages
        pronouns
        fullName
        bio
        userDefinedInterests
        userSecretSuperPower
        problemUserWouldLikeToSolve
        userLinkedInLink
        interests {
          name
        }
        country {
          name
        }
        countries {
          name
        }
        globalInterestLocation
        email
        organization {
          name
          userRole
        }
      }
      communityCreator {
        publicId
        canReceiveMessages
        pronouns
        email
        bio
        userDefinedInterests
        userSecretSuperPower
        problemUserWouldLikeToSolve
        userLinkedInLink
        interests {
          name
        }
        country {
          name
        }
        countries {
          name
        }
        globalInterestLocation
        fullName
        email
        organization {
          name
          userRole
        }
      }
      goals
      rules
      name
      channels
      coverImageName
      imageDirectory
      communityBannerDirectory
      defaultCollection {
        name
        publicId
        description
        collaboratorsCount
        collectionType
        # articlesCount
        imageName
        imageDirectory
        updateTime
        collectionCreator {
          fullName
          email
        }
        isPublic
        isGlobalCurated
      }
      pinnedFiles {
        name
        isPinnable
        publicId
        directory
        originalName
      }
      communityLinks {
        title
        url
      }
    }
  }
`;

const GET_COMMUNITY_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE = gql`
  query communityPreview($sendbirdId: String!) {
    communityPreview(sendbirdId: $sendbirdId) {
      name
      sendbirdId
      communityImageUrl
      coverImageName
      imageDirectory
      communityType
      description
      goals
      rules
      communityBannerUrl
      communityBannerName
      communityBannerDirectory
      communityLinks {
        title
        url
      }
      communityEvents {
        publicId
        name
        eventImageName
        eventType
        imageDirectory
        startDate
        endDate
        eventHost {
          email
          fullName
        }
      }
      channels
      interests {
        name
      }

      defaultCollection {
        name
        publicId
        description
        isPublic
        isGlobalCurated
        imageName
        imageDirectory
        collaboratorsCount
        collectionCreator {
          fullName
          email
        }
      }
      allCommunityCollectionsCount
      pinnedFiles {
        name
        isPinnable
        publicId
        directory
      }
    }
  }
`;

const GET_PINNED_FILES_FOR_COMMUNITY_CHANEL_PAGE = gql`
  query Community($sendbirdId: String!) {
    community(sendbirdId: $sendbirdId) {
      pinnedFiles {
        name
        isPinnable
        publicId
        directory
        originalName
      }
    }
  }
`;

const GET_COMMUNITY_COLLECTIONS_FOR_COMMUNITY_CHANEL_PAGE = gql`
  query getAllCommunityCollections($search: CommunityCollectionSearchInputType!) {
    allCommunityCollections(communityCollectionSearch: $search) {
      totalCount
      collections {
        name
        publicId
        description
        collaboratorsCount
        collectionType
        # articlesCount
        imageName
        imageDirectory
        collectionType
        updateTime
        collectionCreator {
          fullName
          email
        }
        isPublic
        isGlobalCurated
      }
    }
  }
`;

const GET_COMMUNITY_COLLECTIONS_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE = gql`
  query allCommunityCollectionsPreview(
    $communityCollectionSearch: CommunityCollectionSearchInputType!
  ) {
    allCommunityCollectionsPreview(
      communityCollectionSearch: $communityCollectionSearch
    ) {
      totalCount
      collections {
        name
        publicId
        description
        isPublic
        isGlobalCurated
        imageName
        imageDirectory
        collaboratorsCount
        collectionCreator {
          fullName
          email
        }
      }
    }
  }
`;

const GET_ADDIBLE_USERS_TO_NEW_COMMUNITY_COLLECTION = gql`
  query addibleUsersToNewCommunityCollection(
    $addibleUsersAsCollaboratorsSearch: AddibleUsersToNewCommunityCollectionInputType!
  ) {
    addibleUsersToNewCommunityCollection(
      addibleUsersAsCollaboratorsSearch: $addibleUsersAsCollaboratorsSearch
    ) {
      publicId
      fullName
      email
      organization {
        name
        userRole
      }
    }
  }
`;

const GET_ADDIBLE_USERS_TO_EXISTING_COMMUNITY_COLLECTION = gql`
  query addibleUsersToExistingCommunityCollection(
    $addibleUsersAsCollaboratorsSearch: InvitableUsersToCollectionInputType!
  ) {
    addibleUsersToExistingCommunityCollection(
      addibleUsersAsCollaboratorsSearch: $addibleUsersAsCollaboratorsSearch
    ) {
      publicId
      fullName
      email
      organization {
        name
        userRole
      }
    }
  }
`;

export {
  COMMUNITY_COLLECTION_GET_ALL_COMMUNITY_COLLECTIONS,
  COMMUNITY_COLLECTION_GET_COMMUNITY,
  COMMUNITY_COLLECTION_GET_PRIVATE_COMMUNITY_COLLECTIONS,
  COMMUNITY_COLLECTION_GET_PUBLIC_COMMUNITY_COLLECTIONS,
  GET_ADDIBLE_USERS_TO_EXISTING_COMMUNITY_COLLECTION,
  GET_ADDIBLE_USERS_TO_NEW_COMMUNITY_COLLECTION,
  GET_COMMUNITY_COLLECTIONS_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_COLLECTIONS_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_FOR_COMMUNITY_CHANEL_PAGE,
  GET_COMMUNITY_PREVIEW_FOR_COMMUNITY_CHANEL_PAGE,
  GET_PINNED_FILES_FOR_COMMUNITY_CHANEL_PAGE,
};
