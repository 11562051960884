import { gql } from "@apollo/client";

const RENAME_FOLDER_IN_COLLECTION = gql`
  mutation renameFolder($folderRename: RenameFolderInputType!) {
    renameFolder(folderRename: $folderRename) {
      name
      publicId
    }
  }
`;

export default RENAME_FOLDER_IN_COLLECTION;
