export interface IEvent {
  name: string;
  startDate: Date;
  endDate: Date;
  hostingId: number;
  publicId: string;
  description: string;
  eventType?: string;
  eventHost: { fullName: string; __typeName: string; email: string };
  attachments: Array<{ name: string; __typeName: string; size: number }>;
  attachmentsDirectory: string;
  eventImageName?: string;
  imageDirectory?: string;
  externalUrl?: string;
  eventRecordingFileLinks?: Array<string>;
  eventRecordingsPassword?: string;
  attendeesTotalCount?: number;
  attendeesCountriesTotalCount?: number;
}
export interface IEventsResponse {
  events?: {
    totalCount: number;
    events: Array<IEvent>;
  };
}

export const EVENTS_PLACEHOLDER = {
  name: "placeholder",
  startDate: new Date(),
  endDate: new Date(),
  description: "",
  publicId: "3ff86dc1-f8ec-4823-a374-5727da873b59",
  hostingId: 0,
  eventHost: {
    fullName: "",
    email: "",
    __typeName: "",
  },
  attachments: [],
  attachmentsDirectory: "",
};
