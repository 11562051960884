import { GroupChannel } from "sendbird";

import CommunityMessage from "@components/CommunityMessage/CommunityMessage";

import { DIRECT } from "@customHooks/sendBird/constant";
import { ISendBirdMessageHelper } from "@customHooks/sendBird/useSendBirdMessage/type";

import StartAvatar from "@images/startAvatart.svg";

import { useTranslation } from "react-i18next";

interface IGroupDefaultMessageProps {
  selectedGroupChannel?: GroupChannel;
  sendBirdMessageHelper: ISendBirdMessageHelper;
}

export const GroupDefaultMessage = ({
  selectedGroupChannel,
  sendBirdMessageHelper,
}: IGroupDefaultMessageProps) => {
  const { t } = useTranslation();

  if (!selectedGroupChannel || selectedGroupChannel.customType === DIRECT) return <></>;

  return (
    <CommunityMessage
      messageDefault={{
        message: t("chat.group.createGroup.joinMessage", {
          name: selectedGroupChannel.name,
        }),
        name: selectedGroupChannel.name || "",
      }}
      image={StartAvatar}
      sendBirdMessageHelper={sendBirdMessageHelper}
      startMessage={true}
      description={selectedGroupChannel?.data || ""}
    />
  );
};
