import { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "@components/Button/Button";
import ShareModal from "@components/ShareModal/ShareModal";

import {
  SHARE_MODAL_TYPE_EVENT,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";

import { ReactComponent as ShareIcon } from "@images/shareIcon.svg";

import TooltipComponent from "@components/Tooltip/Tooltip";

import styles from "./ShareCTA.module.scss";

export const ShareCTA = () => {
  const { t } = useTranslation();
  const [copyLink, shareSuccessMessage] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_EVENT,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <TooltipComponent
        title={t("button.shareLink")}
        tooltipType="secondary"
        placement="bottomRight"
        childToWrap={
          <>
            <Button
              title=""
              buttonIcon={<ShareIcon />}
              buttonShape="circle"
              buttonSize="middle"
              wrapperClassNames={styles.shareButton}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        }
      />

      <ShareModal
        copyLink={copyLink}
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        share={shareSuccessMessage}
      />
    </>
  );
};
