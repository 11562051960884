import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import { useHistory } from "react-router";

import { setIsNotificationsPopUpVisible } from "../../../../store/notifications/actions";

import Loading from "@components/Loading/Loading";

import { NotificationCard } from "../NotificationCard/NotificationCard";

import { INotification } from "@graphql/notification/types";

import styles from "./Notifications.module.scss";

interface INotificationsPopover {
  notifications?: INotification[];
}

export const Notifications = ({ notifications }: INotificationsPopover) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    renderNotifications();
    getNotificationClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const renderNotifications = () => {
    if (typeof notifications === "undefined") return <Loading disableBoxShadow />;

    if (!notifications.length)
      return <h4 className={styles.text}>{t("requests.noNotificationPresent")}</h4>;

    return notifications.map((notification) => (
      <div
        aria-label="notification"
        key={notification.publicId}
        className={styles.notification}
      >
        <NotificationCard notification={notification} />
      </div>
    ));
  };

  const getNotificationClass = () => {
    if (typeof notifications === "undefined") return styles.notifications;

    if (!notifications.length) return `${styles.notifications} ${styles.noNotification}`;

    return styles.notifications;
  };

  return (
    <div className={getNotificationClass()}>
      <div className={styles.titleWrapper}>
        <h4 aria-label="heading" className={styles.heading}>
          {t("requests.notifications")}
        </h4>
        <p
          className={styles.action}
          onClick={() => {
            dispatch(setIsNotificationsPopUpVisible(false));
            history.push("/portal/notifications-page");
          }}
        >
          {t("requests.goToAllNotifications")}
        </p>
      </div>
      {renderNotifications()}
    </div>
  );
};
