import { gql } from "@apollo/client";

const ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION = gql`
  mutation acceptOrDeclineCollectionCollaborationInvitation(
    $invitationResponse: CollaborationInvitationResponseInputType!
  ) {
    acceptOrDeclineCollectionCollaborationInvitation(
      invitationResponse: $invitationResponse
    ) {
      name
      collectionPublicId
    }
  }
`;

export default ACCEPT_OR_DECLINE_COLLECTION_COLLABORATION_INVITATION;
