import { components, ControlProps } from "react-select";

export const Control = ({ children, ...props }: ControlProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = (props.selectProps as any)["data-icon"];

  return (
    <components.Control {...props}>
      {icon && <img src={icon} />}
      {children}
    </components.Control>
  );
};
