import CommunityBannerIcon1 from "../../images/CommunityBannerDefaultImages/banner_01.png";
import CommunityBannerIcon2 from "../../images/CommunityBannerDefaultImages/banner_02.png";
import CommunityBannerIcon3 from "../../images/CommunityBannerDefaultImages/banner_03.png";
import CommunityBannerIcon4 from "../../images/CommunityBannerDefaultImages/banner_04.png";
import CommunityBannerIcon5 from "../../images/CommunityBannerDefaultImages/banner_05.png";
import CommunityBannerIcon6 from "../../images/CommunityBannerDefaultImages/banner_06.png";
import CommunityBannerIcon7 from "../../images/CommunityBannerDefaultImages/banner_07.png";
import CommunityBannerIcon8 from "../../images/CommunityBannerDefaultImages/banner_08.png";
import CommunityBannerIcon9 from "../../images/CommunityBannerDefaultImages/banner_09.png";
import CommunityBannerIcon10 from "../../images/CommunityBannerDefaultImages/banner_10.png";
import CommunityBannerIcon11 from "../../images/CommunityBannerDefaultImages/banner_11.png";
import CommunityBannerIcon12 from "../../images/CommunityBannerDefaultImages/banner_12.png";
import CommunityBannerIcon13 from "../../images/CommunityBannerDefaultImages/banner_13.png";
import CommunityBannerIcon14 from "../../images/CommunityBannerDefaultImages/banner_14.png";
import CommunityBannerIcon15 from "../../images/CommunityBannerDefaultImages/banner_15.png";
import CommunityBannerIcon16 from "../../images/CommunityBannerDefaultImages/banner_16.png";
export interface IImageObject {
  name: string;
  label: string;
  image: string;
  removeIcon: boolean;
  checkButton: boolean;
  img?: File;
}

export const defaultImages = [
  {
    name: "DefaultCommunityImage1",
    label: "banner1",
    image: CommunityBannerIcon1,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage2",
    label: "banner2",
    image: CommunityBannerIcon2,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage3",
    label: "banner3",
    image: CommunityBannerIcon3,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage4",
    label: "banner4",
    image: CommunityBannerIcon4,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage5",
    label: "banner5",
    image: CommunityBannerIcon5,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage6",
    label: "banner6",
    image: CommunityBannerIcon6,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage7",
    label: "banner7",
    image: CommunityBannerIcon7,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage8",
    label: "banner8",
    image: CommunityBannerIcon8,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage9",
    label: "banner9",
    image: CommunityBannerIcon9,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage10",
    label: "banner10",
    image: CommunityBannerIcon10,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage11",
    label: "banner11",
    image: CommunityBannerIcon11,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage12",
    label: "banner12",
    image: CommunityBannerIcon12,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage13",
    label: "banner13",
    image: CommunityBannerIcon13,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage14",
    label: "banner14",
    image: CommunityBannerIcon14,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage15",
    label: "banner15",
    image: CommunityBannerIcon15,
    removeIcon: false,
    checkButton: true,
  },
  {
    name: "DefaultCommunityImage16",
    label: "banner16",
    image: CommunityBannerIcon16,
    removeIcon: false,
    checkButton: true,
  },
];

export const COMMUNITY_IMAGES_INITIAL_SIZE = 3;
