import { CloseOutlined } from "@ant-design/icons";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import DangerIcon from "@images/Danger.svg";
import { useTranslation } from "react-i18next";
import { IUser } from "types/user";
import styles from "./CommunityRemoveMemberModal.module.scss";
interface ICommunityRemoveMemberModal {
  isOpen: boolean;
  closeModal: () => void;
  handleRemoveMember: (member: IUser) => void;
  memberToBeRemoved: IUser | undefined;
}

const CommunityRemoveMemberModal = ({
  isOpen,
  closeModal,
  handleRemoveMember,
  memberToBeRemoved,
}: ICommunityRemoveMemberModal) => {
  const { t } = useTranslation();

  const handleRemove = () => {
    if (memberToBeRemoved) handleRemoveMember(memberToBeRemoved);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} className={styles.modalContainer}>
      <div className={styles.modalWrapper} aria-label="modal">
        <Button
          title=""
          buttonType="link"
          wrapperClassNames={styles.closeButton}
          onClick={closeModal}
          buttonIcon={<CloseOutlined className={styles.closeIcon} />}
        />
        <img src={DangerIcon} alt="" className={styles.videoImage} />
        {memberToBeRemoved?.fullName && (
          <h4 className={styles.header}>
            {t("community.administration.modal.title", {
              name: memberToBeRemoved.fullName,
            })}
          </h4>
        )}
        <p className={styles.text}>{t("community.administration.modal.text")}</p>
        <div className={styles.buttonContainer}>
          <Button
            wrapperClassNames={styles.confirmModalCancelBtnWrapper}
            title={t("community.administration.buttons.modal.cancel")}
            onClick={closeModal}
          />
          <Button
            wrapperClassNames={styles.confirmModalRemoveBtnWrapper}
            title={t("community.administration.buttons.modal.remove")}
            danger
            buttonType="primary"
            onClick={handleRemove}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CommunityRemoveMemberModal;
