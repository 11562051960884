import { AnyAction } from "redux";
import { RootState } from "./../store";
import {
  CLEAR_COMMUNITY_SEARCH_TERM,
  LEFT_COMMUNITY,
  SET_ADMINS,
  SET_ALL_MEMBERS,
  SET_COMMUNITY_MEMBERS,
  SET_COMMUNITY_SEARCH_TERM,
  SET_MEMBERS,
} from "./constants";
import { CommunityState } from "./types";

const initialState: CommunityState = {
  allMembers: [],
  members: [],
  admins: [],
  creator: undefined,
  leftCommunity: "",
};

const communityReducer = (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMMUNITY_MEMBERS: {
      const { admins, members, creator } = payload;
      return {
        ...state,
        members,
        admins,
        creator,
        allMembers: [creator && creator, ...admins, ...members],
      };
    }
    case SET_ALL_MEMBERS:
      return {
        ...state,
        allMembers: payload,
      };
    case SET_MEMBERS:
      return {
        ...state,
        members: payload,
      };
    case SET_ADMINS:
      return {
        ...state,
        admins: payload,
      };

    case LEFT_COMMUNITY:
      return {
        ...state,
        leftCommunity: payload,
      };

    case SET_COMMUNITY_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload,
      };

    case CLEAR_COMMUNITY_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload,
      };
    default:
      return state;
  }
};

export const leftCommunitySelector = (state: RootState) => state.community.leftCommunity;

export default communityReducer;
