import { Link } from "@components/navigation/Link/Link";

import { WHO_PAGE } from "@constants/constants";

import { PUBLIC_ROUTES } from "@feature/routes/components/PublicRoutes/constant";

import AwardLogo from "@images/award.png";
import Facebook from "@modules/LandingPage/icons/facebook.svg";
import Linkedin from "@modules/LandingPage/icons/linkedin.svg";
import X from "@modules/LandingPage/icons/x.svg";
import YouTube from "@modules/LandingPage/icons/youtube.svg";

import styles from "./Footer.module.scss";

const Footer = () => {
  const socialLinks = [
    {
      key: "Facebook",
      src: Facebook,
      url: "https://www.facebook.com/WHO/",
    },
    {
      key: "X",
      src: X,
      url: "https://twitter.com/WHO",
    },
    {
      key: "Linkedin",
      src: Linkedin,
      url: "https://au.linkedin.com/company/world-health-organization",
    },
    {
      key: "YouTube",
      src: YouTube,
      url: "https://www.youtube.com/channel/UC07-dOwgza1IguKA86jqxNA",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.awardLogo} src={AwardLogo} />
        <div className={styles.links}>
          <Link
            pathName={PUBLIC_ROUTES.TERMS_AND_CONDITIONS}
            translationKey="link.termsAndConditions"
          />
          <Link
            pathName={PUBLIC_ROUTES.CODE_OF_CONDUCT}
            translationKey="link.codeOfConduct"
          />
          <Link pathName={WHO_PAGE} translationKey="link.whoWebsite" isExternalUrl />
        </div>
        <div className={styles.socialLinksContainer}>
          {socialLinks.map((socialLink) => (
            <a href={socialLink.url} target="_blank" rel="noreferrer">
              <img className={styles.link} src={socialLink.src} alt={socialLink.key} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
