import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";

import { GroupChannel } from "sendbird";

import ADD_CHANNEL from "@graphql/mutations/addChannel";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import useCheckValidation, { ValidationOption } from "@utils/validation";
import { ICreateChannelRequest, ICreateChannelResponse } from "./type";

interface IUseCreateChannelProps {
  channels: Array<string>;
  setChannels: (value: Array<string>) => void;
  groupChannel?: GroupChannel;
  defaultChannel: string;
}

export const useCreateChannel = ({
  channels,
  setChannels,
  groupChannel,
  defaultChannel,
}: IUseCreateChannelProps) => {
  const { t } = useTranslation();
  const [addChannel, { loading }] = useMutation<
    ICreateChannelResponse,
    ICreateChannelRequest
  >(ADD_CHANNEL);

  const checkValidation = useCheckValidation();

  const createChannel = (name: string, resetChannelCreation: () => void) => {
    const [isInputValid] = checkValidation([ValidationOption.REQUIRED], name.trim());

    if (!isInputValid) {
      return resetChannelCreation();
    }

    if (name.toLowerCase() === defaultChannel.toLowerCase()) {
      const message = t("community.notifications.duplicateName");
      displayNotSuccessNotification(message, message);
      return resetChannelCreation();
    }

    const duplicateName = channels.find(
      (channel) => channel.toLowerCase() === name.toLowerCase()
    );

    if (duplicateName) {
      const message = t("community.notifications.duplicateName");
      displayNotSuccessNotification(message, message);
      return resetChannelCreation();
    }

    addChannel({
      variables: {
        channel: {
          name,
          communityId: groupChannel?.url,
        },
      },
      onCompleted: ({ addChannel }) => {
        displaySuccessNotification();
        setChannels(addChannel.channels);
        resetChannelCreation();
      },
      onError: displayServerError,
    });
  };

  return { createChannel, loading };
};
