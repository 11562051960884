import { CollectionType, ISingleCollection } from "@graphql/collection/type";
import { useTranslation } from "react-i18next";
import styles from "./CollaboratorsFollowers.module.scss";

interface CollaboratorsFollowersProps {
  collection?: Pick<ISingleCollection, "followers" | "collaborators" | "collectionType">;
  isCollectionInfoDisplayed?: boolean;
  isOnModal?: boolean;
}

const CollaboratorsFollowers = ({
  isOnModal = false,
  collection,
  isCollectionInfoDisplayed = true,
}: CollaboratorsFollowersProps) => {
  const { t } = useTranslation();

  const collaboratorsCount = 1 + (collection?.collaborators.length ?? 0);
  const followersCount = collection?.followers.length ?? 0;

  const collaboratorsInfo =
    collaboratorsCount === 1
      ? t("collections.singleCollaborator", {
          count: collaboratorsCount,
        })
      : t("collections.multipleCollaborators", {
          count: collaboratorsCount,
        });

  const followersInfo =
    followersCount === 1
      ? t("collections.singleFollower", {
          count: followersCount,
        })
      : t("collections.multipleFollowers", {
          count: followersCount,
        });

  const isCollectionTextDisplayed =
    collection?.collectionType !== CollectionType.COMMUNITY_COLLECTION &&
    isCollectionInfoDisplayed;

  return (
    <p className={isOnModal ? styles.textOnModal : styles.text}>
      <span>{collaboratorsInfo}</span>
      {isCollectionTextDisplayed && (
        <>
          <span className={styles.dotMargin}>•</span>
          <span>{followersInfo}</span>
        </>
      )}
    </p>
  );
};

export default CollaboratorsFollowers;
