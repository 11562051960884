/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import NoUpcomingEvents from "@components/NoUpcomingEvents/NoUpcomingEvents";
import { EVENTS_GRID_SIZE } from "@constants/constants";
import GET_HOSTING_AND_UPCOMING_EVENTS from "@graphql/queries/upcomingEvents";
import EventsGrid from "@modules/EventsGrid/EventsGrid";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { findFirstItemFromNextPage, simulatePagination } from "@utils/eventsHelper";
import { getDateInUtc } from "@utils/helpers";
import { useEffect, useState } from "react";
import { IEvent } from "types/event";
import { IUser } from "types/user";
import styles from "./UpcomingEvents.module.scss";

const UpcomingEvents = () => {
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [eventIdForScroll, setEventIdForScroll] = useState("");
  const [paginationLimiter, setPaginationLimiter] = useState<number>(EVENTS_GRID_SIZE);

  const [getHostingAndUpcomingEvents, { loading }] = useLazyQuery(
    GET_HOSTING_AND_UPCOMING_EVENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: { user: IUser }) => {
        setEvents(data.user.hostingAndAttendingUpcomingEvents!);
        setEventsCount(data.user.hostingAndAttendingUpcomingEvents!.length);
      },
      onError: (error) => displayServerError(error),
    }
  );

  const extendVisibleEvents = () => {
    setPaginationLimiter(paginationLimiter + EVENTS_GRID_SIZE);
  };

  useEffect(() => {
    if (!eventsCount || !paginationLimiter) return;
    setEventIdForScroll(findFirstItemFromNextPage(events, paginationLimiter));
  }, [paginationLimiter, eventsCount]);

  useEffect(() => {
    getHostingAndUpcomingEvents({
      variables: {
        from: getDateInUtc(),
      },
    });
  }, []);

  return loading ? (
    <Loading disableBoxShadow e2eTestId="upcoming-events-loader" />
  ) : !events.length ? (
    <div
      className={styles.noUpcomingEvents}
      e2e-test-id="no-upcoming-events-message-container"
    >
      <NoUpcomingEvents />
    </div>
  ) : (
    <div className={styles.eventContainer}>
      <div className={styles.upcomingEvents}>
        <EventsGrid
          events={simulatePagination(events, paginationLimiter)}
          loadMore={extendVisibleEvents}
          eventsLoading={loading}
          eventsCount={eventsCount}
          eventsLoadingMore={false}
          firstItemId={eventIdForScroll}
        />
      </div>
    </div>
  );
};

export default UpcomingEvents;
