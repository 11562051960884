import { gql } from "@apollo/client";

const REGISTER_TO_EVENT = gql`
  mutation RegisterToEvent($eventRegistrationInput: EventRegistrationInputType!) {
    registerToEvent(eventRegistrationInput: $eventRegistrationInput) {
      name
    }
  }
`;

export default REGISTER_TO_EVENT;
