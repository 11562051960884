import { useLazyQuery } from "@apollo/client";
import { USER_NOT_MEMBER } from "@constants/constants";
import GET_COMMUNITY from "@graphql/queries/community";
import GET_USER from "@graphql/queries/users";
import { displayServerError } from "@services/NotificationService/NotifacitonService";
import { GraphQLError } from "graphql";
import { useCallback, useEffect, useState } from "react";
import { ICommunityResponse } from "types/community";
import { ICommunityHelper } from "types/useCommunityHelperType";
import { IUser, IUserResponse } from "types/user";

export const useCommunityHelper = (): ICommunityHelper => {
  const [getCurrentUser, { data: currentUserData }] =
    useLazyQuery<IUserResponse>(GET_USER);
  const [getCommunity] = useLazyQuery<ICommunityResponse>(GET_COMMUNITY, {
    fetchPolicy: "no-cache",
    onCompleted: ({ community }) => {
      if (!community) return;

      setAdmins(community.admins);
      setRegularMembers(community.members);
      setCreator(community.communityCreator);
    },
    onError: (error) => {
      const userIsNotAMemberError = error?.graphQLErrors.filter(
        (graphQLError: GraphQLError) => graphQLError.extensions.code === USER_NOT_MEMBER
      );

      if (userIsNotAMemberError) {
        return;
      } else {
        displayServerError(error);
      }
    },
  });
  const [sendBirdId, setSendBirdId] = useState("");
  const [admins, setAdmins] = useState<IUser[]>();
  const [regularMembers, setRegularMembers] = useState<IUser[]>();
  const [creator, setCreator] = useState<IUser>();

  useEffect(() => {
    if (!sendBirdId) return;
    getCommunity({ variables: { sendbirdId: sendBirdId } });
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBirdId]);

  const isAdmin = useCallback(
    (email: string) => {
      if (!admins) return false;
      return admins.some((user) => user.email === email);
    },
    [admins]
  );

  const isMember = (email: string) => isRegularMember(email) || isAdmin(email);

  const isRegularMember = (email: string) => {
    if (!regularMembers) return false;
    return regularMembers.some((user) => user.email === email);
  };

  const isAbleToManageUser = useCallback(
    (email: string) => {
      if (!creator || !currentUserData) return false;
      const { user: currentUser } = currentUserData;

      const isUserManageable = () => creator.email !== email && !isAdmin(email);

      return (
        creator.email === currentUser.email ||
        (isAdmin(currentUser.email) && isUserManageable())
      );
    },
    [creator, currentUserData, isAdmin]
  );

  const removeRegularMember = (email: string) => {
    if (!regularMembers || !isRegularMember(email)) return false;
    setRegularMembers(
      regularMembers.filter((regularMember) => regularMember.email !== email)
    );
    return true;
  };

  const removeAdmin = (email: string) => {
    if (!admins || !isAdmin(email)) return false;
    setAdmins(admins.filter((admin) => admin.email !== email));
    return true;
  };

  const removeMember = (email: string) =>
    removeRegularMember(email) || removeAdmin(email);

  return {
    communityHelpers: { isAbleToManageUser, isMember },
    communityActions: {
      setSendBirdId,
      removeMember,
    },
    communityState: {
      regularMembers,
      admins,
      creator,
    },
  };
};
