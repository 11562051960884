/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { RootState } from "../../../store/RootState";

import AuthService from "@services/AuthService";
import {
  displayNotSuccessNotification,
  displayServerError,
} from "@services/NotificationService/NotifacitonService";

import sb from "../SendBird";

export const useSendBird = () => {
  const [sbToken, setSbToken] = useState<string>("");
  const [isSendbirdConnected, setIsSendbirdConnected] = useState(false);

  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  const userFullName = useSelector<RootState, string>((state) => state.user.fullName);

  useEffect(() => {
    const storedSbToken = AuthService.getStoredSendBirdToken();
    const expiresToken = AuthService.getSbTokenExpiresAt();

    if (storedSbToken && expiresToken && new Date(expiresToken) > new Date()) {
      setSbToken(storedSbToken);
      return;
    }

    if (userFullName) {
      AuthService.getSendBirdToken(userFullName)
        .then((response) => {
          setSbToken(response.data.token);
          AuthService.setSendBirdToken(response.data.token);
          AuthService.setSbTokenExpiresAt(response.data.expires_at);
        })
        .catch((error) => displayNotSuccessNotification(error));
    }
  }, [userFullName]);

  useEffect(() => {
    if (!sbToken) return;

    sb.connect(currentUserEmail, sbToken, function (user, error) {
      if (error) {
        displayServerError(error);
        return;
      }

      setIsSendbirdConnected(true);
    });

    return () => {
      sb.disconnect();
    };
  }, [sbToken]);

  return isSendbirdConnected ? sb : null;
};
