import { Popover as AntDPopover, PopoverProps } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import styles from "./Popover.module.scss";

interface IPopoverProps extends PopoverProps {
  placement: TooltipPlacement;
  childToWrap: React.ReactNode;
  menu: React.ReactNode;
  menuState: boolean;
  setMenuState?: React.Dispatch<React.SetStateAction<boolean>>;
  onOpenChange?: (isVisible: boolean) => void;
  trigger?: "hover" | "focus" | "click" | "contextMenu";
  className?: string;
}
const Popover = ({
  placement,
  trigger,
  childToWrap,
  menu,
  menuState,
  className,
  setMenuState,
  onOpenChange,
  ...props
}: IPopoverProps) => {
  return (
    <AntDPopover
      content={menu}
      placement={placement}
      trigger={trigger || "click"}
      zIndex={1101}
      overlayClassName={`${styles.avatarMenuOverlay} ${className}`}
      open={menuState}
      onOpenChange={(isVisible) => {
        setMenuState?.(isVisible);
        onOpenChange?.(isVisible);
      }}
      autoAdjustOverflow={true}
      {...props}
    >
      {childToWrap}
    </AntDPopover>
  );
};

export default Popover;
