import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import BackButton from "@components/BackButton/BackButton";
import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import PageHeading from "@components/PageHeading/PageHeading";

import BuildingProfile from "@modules/BuildingProfile/BuildingProfile";

import ErrorCircle from "@images/circleError.svg";

import UPDATE_USER from "@graphql/mutations/updateUser";
import GET_COUNTRIES from "@graphql/queries/countries";

import LocationService from "@services/LocationService";

import { clear } from "../../store/onboarding/actions";
import { OnboardingState } from "../../store/onboarding/types";
import { RootState } from "../../store/store";

import { GLOBAL_LOCATION } from "@constants/constants";
import UPDATE_USER_LOCATIONS from "@graphql/mutations/updateUserLocations";
import {
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";
import TourModalService from "@services/TourModalService";
import styles from "./InterestsAndSocialMedia.module.scss";

const InterestsAndSocialMedia = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const MAX_INPUT_LENGTH = 200;

  const userData = useSelector<RootState, OnboardingState>((state) => state.onboarding);
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const [updateUserLocationsOfInterest] = useMutation(UPDATE_USER_LOCATIONS);

  const [userDefinedInterests, setUserDefinedInterests] = useState<string | undefined>();
  const [userSecretSuperPower, setUserSecretSuperPower] = useState<string | undefined>();
  const [problemUserWouldLikeToSolve, setProblemUserWouldLikeToSolve] = useState<
    string | undefined
  >();
  const [userLinkedInLink, setUserLinkedInLink] = useState<string>("");

  const { data: countriesData } = useQuery(GET_COUNTRIES);

  const [isLinkedinValidUrl, setIsLinkedinValidUrl] = useState<boolean>(true);

  const linkedinRegex = new RegExp(
    /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!]))?/
  );

  useEffect(() => {
    if (userLinkedInLink !== "") {
      setIsLinkedinValidUrl(!!userLinkedInLink?.match(linkedinRegex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLinkedInLink]);

  const {
    personalDetails,
    profileDetails,
    interests: selectedInterests,
    locations,
  } = userData;

  const handleSubmitUserData = () => {
    const interests = selectedInterests.map(({ value }) => ({
      name: value,
    }));

    const { fullName, defaultLanguage, canReceiveMessages } = personalDetails;

    const { workLocation } = profileDetails;

    const workLocationValue = workLocation as { name: string; callingCode: string };

    const user = {
      fullName,
      defaultLanguage,
      canReceiveMessages,
      isOnboarded: true,
      workLocation: {
        name: workLocationValue.name,
        callingCode: workLocationValue.callingCode,
      },
      bio: profileDetails.bio,
      telephone: `${personalDetails.countryCode}${personalDetails.telephone}`,
      organization: {
        name: profileDetails.organizationName,
        sector: profileDetails.sector,
        userRole: profileDetails.organizationRole,
      },
      interests,
      userDefinedInterests,
      userSecretSuperPower,
      problemUserWouldLikeToSolve,
      userLinkedInLink,
    };

    updateUser({
      variables: {
        user,
      },
    })
      .then(() => {
        dispatch(clear());
        displaySuccessNotification();
        TourModalService.setIsOnboarded();
        history.push("/portal/resources");
      })
      .catch(() => displayServerError());
  };

  const handleSubmitCountires = () => {
    const isGlobalSelected = locations.some(
      (location) => location.name === GLOBAL_LOCATION
    );

    // Filter is added because we can't send undefined value and it needs to be removed,
    // but we are sending globalInterestLocation: {} because the backend accepts it like that
    const selectedCountries = LocationService.extractStatesFromSelectedLocations(
      locations,
      countriesData.countries
    ).filter(Boolean);

    const mutationToExecute = () => {
      if (!isGlobalSelected) {
        return updateUserLocationsOfInterest({
          variables: {
            user: {
              countries: selectedCountries,
            },
          },
        });
      } else {
        return updateUserLocationsOfInterest({
          variables: {
            user: {
              countries: selectedCountries,
              globalInterestLocation: {},
            },
          },
        });
      }
    };
    mutationToExecute();
  };

  const handleSubmit = () => {
    handleSubmitCountires();
    handleSubmitUserData();
  };

  if (loading) return <BuildingProfile />;

  return (
    <>
      <PageHeading
        heading={t("interestsAndSocialMedia.pageTitle")}
        subheading={t("interestsAndSocialMedia.subheading")}
      />
      <p className={styles.sectionTitle}>
        {t("accountSettings.interestsAndSocialMedia.label.interests")}
      </p>
      <Input
        maxLength={MAX_INPUT_LENGTH}
        placeholder={t("accountSettings.interestsAndSocialMedia.input.placeholder")}
        name="userDefinedInterests"
        label={t("accountSettings.interestsAndSocialMedia.input.userDefinedInterests")}
        labelClassNames={styles.inputLabel}
        value={userDefinedInterests}
        onChange={(e) => setUserDefinedInterests(e.target.value)}
      />
      <Input
        maxLength={MAX_INPUT_LENGTH}
        placeholder={t("accountSettings.interestsAndSocialMedia.input.placeholder")}
        name="userSecretSuperPower"
        label={t("accountSettings.interestsAndSocialMedia.input.userSecretSuperPower")}
        labelClassNames={styles.inputLabel}
        value={userSecretSuperPower}
        onChange={(e) => setUserSecretSuperPower(e.target.value)}
      />
      <Input
        maxLength={MAX_INPUT_LENGTH}
        placeholder={t("accountSettings.interestsAndSocialMedia.input.placeholder")}
        name="problemUserWouldLikeToSolve"
        label={t(
          "accountSettings.interestsAndSocialMedia.input.problemUserWouldLikeToSolve"
        )}
        labelClassNames={styles.inputLabel}
        value={problemUserWouldLikeToSolve}
        onChange={(e) => setProblemUserWouldLikeToSolve(e.target.value)}
      />
      <p className={styles.sectionTitle}>
        {t("accountSettings.interestsAndSocialMedia.label.socialMedia")}
      </p>
      <Input
        name="userLinkedInLink"
        placeholder="eg. https://linkedin.com/in/user-profile-page-123456"
        label={t("accountSettings.interestsAndSocialMedia.input.userLinkedInLink")}
        labelClassNames={styles.inputLabel}
        value={userLinkedInLink}
        onChange={(e) => setUserLinkedInLink(e.target.value)}
      />
      {!isLinkedinValidUrl && userLinkedInLink !== "" && (
        <div className={styles.errorContainer}>
          <img alt="" src={ErrorCircle} />
          <span className={styles.errorMessage}>
            {t("event.newEvent.error.externalUrlNotValid")}
          </span>
        </div>
      )}

      <div className={styles.buttonsWrapper}>
        <BackButton
          path="/onboarding/locations"
          label={t("interestsAndSocialMedia.heading")}
        />
        <Button
          aria-label="next-step"
          onClick={handleSubmit}
          title={t("button.next")}
          disabled={!isLinkedinValidUrl && userLinkedInLink !== ""}
          buttonType="primary"
        />
      </div>
    </>
  );
};

export default InterestsAndSocialMedia;
