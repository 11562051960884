import Button from "@components/Button/Button";

import { ReactComponent as Copy } from "@images/copyIconSecond.svg";

import {
  SHARE_MODAL_TYPE_EVENT,
  useShareModalHelpers,
} from "@utils/useShareModalHelpers";

import styles from "./EventImageCard.module.scss";

interface IEventImageCardProps {
  image: string;
  showCopyButton?: boolean;
}

const EventImageCard = ({ image, showCopyButton }: IEventImageCardProps) => {
  const [copyLink] = useShareModalHelpers({
    type: SHARE_MODAL_TYPE_EVENT,
  });

  return (
    <>
      <div className={styles.container}>
        <div style={{ backgroundImage: `url(${image})` }} className={styles.img}></div>
        {showCopyButton && (
          <Button
            aria-label={"copy"}
            buttonIcon={<Copy className={styles.copyImg} />}
            wrapperClassNames={styles.buttonCopy}
            title=""
            buttonSize="small"
            buttonShape="circle"
            onClick={copyLink}
          />
        )}
      </div>
    </>
  );
};

export default EventImageCard;
