/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../store/RootState";

import CommunityCollectionCard from "@components/CommunityCollectionCard/CommunityCollectionCard";
import styles from "./CommunityCollections.module.scss";

import { PlusOutlined } from "@ant-design/icons";
import Button from "@components/Button/Button";
import { COLLECTION } from "@constants/constants";
import { getUserTag } from "@utils/helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { ICommunity } from "types/community";
import { ICommunityCollectionCard } from "types/CommunityCollections";

interface CommunityCollectionProps {
  communities: ICommunity[] | undefined;
  totalCount: number;
  loadMore: () => void;
  communitiesLoading: boolean;
  isEndlessScroll?: boolean;
}

const CommunityCollections = ({
  communities,
  totalCount,
  loadMore,
  communitiesLoading,
  isEndlessScroll,
}: CommunityCollectionProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const observer = useRef<any>();
  const loadMoreElementRef = useCallback((node) => {
    if (communitiesLoading) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !communitiesLoading) {
        setShouldLoadMore(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      loadMore();
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);

  const currentUserEmail = useSelector<RootState, string>((state) => state.user.email);
  if (!communities) {
    return null;
  }
  const communityCollections: ICommunityCollectionCard[] = communities.map(
    (community) => ({
      id: community.sendbirdId,
      image: community.communityImageUrl,
      name: community.name,
      numberOfCollections: community.allCommunityCollectionsCount ?? 0,
      role: t(getUserTag(currentUserEmail, community)),
    })
  );

  return (
    <div className={styles.communityCollectionsContainer}>
      <div className={styles.communityCollectionCardContainer}>
        {communityCollections &&
          communityCollections?.map((collection) => (
            <span
              key={collection.id}
              onClick={() => {
                history.push(`/portal/community-collection/${collection.id}`);
              }}
            >
              <CommunityCollectionCard
                from={COLLECTION}
                image={collection.image}
                name={collection.name}
                role={collection.role}
                numberOfCollections={collection.numberOfCollections}
              />
            </span>
          ))}

        {!communityCollections?.length && (
          <div>{t("collections.communityCollections.noCommunityCollections")}</div>
        )}

        {totalCount > communities.length && (
          <Button
            data-testid="view-more-collections"
            aria-label="view-more-collections"
            title={t("collections.buttons.viewMore")}
            wrapperClassNames={styles.loadMoreButton}
            buttonIcon={<PlusOutlined />}
            onClick={loadMore}
            loading={communitiesLoading}
            disabled={communitiesLoading}
            customRef={isEndlessScroll ? loadMoreElementRef : null}
          />
        )}
      </div>
    </div>
  );
};

export default CommunityCollections;
