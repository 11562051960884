/* eslint-disable react-hooks/exhaustive-deps */
import Menu from "@components//Menu/Menu";
import mask from "@images/mask.svg";
import WhoCheckmark from "@images/whoCheckmark.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { IArticle } from "types/article";
import { ArticleOptions } from "./ArticleOptions/ArticleOptions";

import styles from "./ArticleCard.module.scss";

export interface IArticleCard {
  article: IArticle;
  megaphone?: boolean;
  trending?: boolean;
  menuOptions?: {
    label: string;
    action: () => void;
  }[];
  onClick?: (id: string) => void;
  currentLoadedPage?: number;
  initialPage?: string;
  "aria-label"?: string;
}

type LocationProps = {
  pathname: string;
  state: {
    currentPage?: number;
    redirect?: string;
    prevPath?: string;
  };
};

const ORGANISATION_DOMAIN = "www.who.int";

const ArticleCard = ({
  article,
  onClick,
  menuOptions,
  currentLoadedPage,
  initialPage,
  ...props
}: IArticleCard) => {
  const history = useHistory();
  const location = useLocation() as LocationProps;
  const { t } = useTranslation();
  const [timeAgo, setTimeAgo] = useState<string>("");
  const { id, image, published, title, domain } = article;

  const reduceArticleTime = () => {
    const nowDate = dayjs();
    const articleDate = dayjs(published);
    if (nowDate.diff(articleDate, "minutes") < 60) {
      setTimeAgo(`${nowDate.diff(articleDate, "minutes")} ${t("articleList.minutes")}`);
      return;
    }
    if (nowDate.diff(articleDate, "hours") < 24) {
      setTimeAgo(`${nowDate.diff(articleDate, "hours")} ${t("articleList.hours")}`);
      return;
    }
    if (nowDate.diff(articleDate, "days") < 31) {
      setTimeAgo(`${nowDate.diff(articleDate, "days")} ${t("articleList.days")}`);
      return;
    }
    if (nowDate.diff(articleDate, "months") < 12) {
      setTimeAgo(`${nowDate.diff(articleDate, "months")} ${t("articleList.months")}`);
      return;
    }
    if (nowDate.diff(articleDate, "year") >= 1) {
      setTimeAgo(t("articleList.years"));
    }
    return;
  };

  useEffect(() => {
    reduceArticleTime();
  }, [published]);

  const handleClick = () => {
    onClick && id && onClick(id);
    const newLocation: LocationProps = {
      pathname: `/portal/resources/article/${id}`,
      state: {
        ...location.state,
        currentPage: currentLoadedPage,
        redirect: initialPage,
      },
    };
    history.push(newLocation);
  };

  return (
    <div className={styles.card} aria-label={props["aria-label"]}>
      <div className={styles.outer} onClick={handleClick}>
        <div
          style={{ backgroundImage: `url(${image ? image : mask})` }}
          className={styles.articleImage}
          aria-label="image"
        >
          {menuOptions?.length ? (
            <Menu options={menuOptions} />
          ) : (
            <ArticleOptions {...article} />
          )}
        </div>
        <div className={styles.cardBottom}>
          <span className={styles.news} aria-label="news">
            {title}
          </span>
          <div className={styles.bottomText}>
            <span aria-label="timeAgo">{timeAgo}</span>
            <span className={styles.dot}>•</span>
            <span className={styles.organisation} aria-label="organisation">
              {domain}
            </span>
            {domain === ORGANISATION_DOMAIN && (
              <img src={WhoCheckmark} alt="" className={styles.img} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
