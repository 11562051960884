import {
  SET_USER_UNREAD_NOTIFICATIONS_COUNT,
  SET_IS_NOTIFICATIONS_POP_UP_VISIBLE,
} from "./constants";
// import { NotificationsState } from "./types";

// Actions
export const setUserUnreadNotificationsCount = (unreadNotificationsCount: number) => ({
  type: SET_USER_UNREAD_NOTIFICATIONS_COUNT,
  payload: unreadNotificationsCount,
});

export const setIsNotificationsPopUpVisible = (isNotificationsPopUpVisible: boolean) => ({
  type: SET_IS_NOTIFICATIONS_POP_UP_VISIBLE,
  payload: isNotificationsPopUpVisible,
});
