import {
  CLEAR_SEARCH_EVENT_ID,
  SET_PREVIOUS_PORTAL_URL,
  SET_SEARCH_EVENT_ID,
} from "./constants";

export const setSearchEventId = (searchId: string) => ({
  type: SET_SEARCH_EVENT_ID,
  payload: searchId,
});
export const clearSearchEventId = () => ({
  type: SET_SEARCH_EVENT_ID,
  payload: "",
});
export const setPreviousPortalUrl = (pathname: string) => ({
  type: SET_PREVIOUS_PORTAL_URL,
  payload: pathname,
});

interface ISetSearchEventId {
  type: typeof SET_SEARCH_EVENT_ID;
  payload: string;
}
interface IClearSearchEventId {
  type: typeof CLEAR_SEARCH_EVENT_ID;
  payload: string;
}
interface ISetPreviousPortalUrl {
  type: typeof SET_PREVIOUS_PORTAL_URL;
  payload: string;
}

export type ActionTypes = ISetSearchEventId | IClearSearchEventId | ISetPreviousPortalUrl;
