import { useTranslation } from "react-i18next";

import { Link as RouterLink } from "react-router-dom";

import styles from "./Link.module.scss";

interface ILinkProps {
  pathName: string;
  isExternalUrl?: boolean;
  translationKey: string;
}

export const Link = ({ pathName, translationKey, isExternalUrl }: ILinkProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        {!isExternalUrl && (
          <RouterLink to={pathName} className={styles.link}>
            <span>{t(translationKey)}</span>
          </RouterLink>
        )}
        {isExternalUrl && (
          <a href={pathName} target="_blank" rel="noreferrer" className={styles.link}>
            {t(translationKey)}
          </a>
        )}
      </div>
    </>
  );
};
