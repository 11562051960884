import { useMutation } from "@apollo/client";

import UPDATE_USER from "@graphql/mutations/updateUser";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

export const useUpdateUser = () => {
  const [updateUser] = useMutation(UPDATE_USER);

  const uploadedImage = (imageName: string, directoryName: string) => {
    updateUser({
      variables: {
        user: {
          userPhotoName: imageName,
          imageDirectory: directoryName,
        },
      },
    })
      .then((response) => {
        // Currently doesn't support validation for same strings, so Error notification will be shown if save is triggered on save string
        if (!response.data.updateUser) {
          displayNotSuccessNotification();
          return;
        }

        displaySuccessNotification();
      })
      .catch(() => {
        displayServerError();
      });
  };

  return { uploadedImage };
};
