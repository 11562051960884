import DefaultEventIcon1 from "../images/event-banner-1.svg";
import DefaultEventIcon2 from "../images/event-banner-2.svg";
import DefaultEventIcon3 from "../images/event-banner-3.svg";

import Avatar from "../images/avatar.svg";
import DefaultCollectionIcon1 from "../images/collections-baner-1.svg";
import DefaultCollectionIcon2 from "../images/collections-baner-2.svg";
import DefaultCollectionIcon3 from "../images/collections-baner-3.svg";

export const DEFAULT_EVENT_IMAGE_NAME_1 = "DefaultEventImage1";
export const DEFAULT_EVENT_IMAGE_NAME_2 = "DefaultEventImage2";
export const DEFAULT_EVENT_IMAGE_NAME_3 = "DefaultEventImage3";
export const DEFAULT_USER_IMAGE_NAME = "DefaultUserImage";

export const DEFAULT_COLLECTION_IMAGE_NAME_1 = "DefaultCollectionImage1";
export const DEFAULT_COLLECTION_IMAGE_NAME_2 = "DefaultCollectionImage2";
export const DEFAULT_COLLECTION_IMAGE_NAME_3 = "DefaultCollectionImage3";
interface IStorageImage {
  image?: string;
  directoryName?: string;
  type: "EVENT" | "COLLECTION" | "USER";
}

export const getStorageImageUrl = (image: string, directory: string) => {
  const container = process.env.REACT_APP_IMAGE_CONTAINER_NAME;
  const account = process.env.REACT_APP_BLOB_STORAGE_ACCOUNT;

  return `${account}/${container}/${directory}/${image}`;
};

const getStorageImage = ({ image, directoryName, type }: IStorageImage) => {
  const defaultImage =
    type === "EVENT"
      ? DefaultEventIcon1
      : type === "USER"
      ? Avatar
      : DefaultCollectionIcon1;

  switch (image) {
    case DEFAULT_EVENT_IMAGE_NAME_1:
      return DefaultEventIcon1;
    case DEFAULT_EVENT_IMAGE_NAME_2:
      return DefaultEventIcon2;
    case DEFAULT_EVENT_IMAGE_NAME_3:
      return DefaultEventIcon3;
    case DEFAULT_COLLECTION_IMAGE_NAME_1:
      return DefaultCollectionIcon1;
    case DEFAULT_COLLECTION_IMAGE_NAME_2:
      return DefaultCollectionIcon2;
    case DEFAULT_COLLECTION_IMAGE_NAME_3:
      return DefaultCollectionIcon3;
    default:
      return image && directoryName
        ? getStorageImageUrl(image, directoryName)
        : defaultImage;
  }
};

export default getStorageImage;
