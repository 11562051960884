import styles from "./Checkbox.module.scss";

interface ICheckboxProps {
  name: string;
  label: string;
  labelLeft?: string;
  checked: boolean;
  className?: string;
  wrapperClassName?: string;
  onChange?: () => void;
  "aria-label"?: string;
}
const Checkbox = (props: ICheckboxProps) => {
  return (
    <div
      aria-label={props["aria-label"]}
      className={props.wrapperClassName || styles.checkboxWrapper}
    >
      <label className={styles.checkboxLabel}>
        {props.labelLeft && <p className="labelLeft">{props.labelLeft}</p>}
        <input
          {...props}
          className={props.className || styles.checkboxInput}
          type="checkbox"
          checked={props.checked}
        />
        <span className={styles.checkmarkIcon} />
        {props.label}
      </label>
    </div>
  );
};
export default Checkbox;
