import { gql } from "@apollo/client";

const ADD_FEEDBACK = gql`
  mutation AddFeedback($feedback: FeedbackInputType!) {
    addFeedback(feedback: $feedback) {
      subject
      content
    }
  }
`;

export default ADD_FEEDBACK;
