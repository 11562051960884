import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import DetailField from "@components/DetailField/DetailField";

import { Language } from "@feature/language";
import { IPhoneData, getFullPhoneNumber } from "@feature/phone";

import { LANGUAGES } from "@constants/constants";
import UPDATE_USER from "@graphql/mutations/updateUser";
import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { useSendBirdContext } from "@customHooks/sendBird/context/context";
import { PronounsField } from "./PronounField/PronounField";

import { IUser } from "types/user";

import { setDocumentDir } from "@utils/setDocumentDir";
import { PhoneField } from "./PhoneField/PhoneField";
import styles from "./SettingsPersonalDetails.module.scss";

interface ISettingsPersonalDetailsProps {
  user: IUser;
  phoneData: IPhoneData;
  refetchData: () => void;
}

const PersonalDetails = ({
  user,
  phoneData,
  refetchData,
}: ISettingsPersonalDetailsProps) => {
  const { t, i18n } = useTranslation();
  const FULL_NAME = "fullName";
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const { sb } = useSendBirdContext();
  const TRANSLATED_LANGUAGES = LANGUAGES?.map(({ value }) => ({
    value,
    label: t(`select.language.${value}`),
  }));

  const { fullName, email, defaultLanguage } = user;

  const onSave = (name: string, value: string, onSuccess?: () => void) => {
    updateUser({
      variables: {
        user: {
          [name]: value,
        },
      },
      onCompleted: () => {
        onSuccess?.();
        // Currently doesn't support validation for same strings, so Error notification will be shown if save is triggered on save string
        if (name === FULL_NAME) {
          sb?.updateCurrentUserInfo(value, "", function (__, error) {
            if (error) {
              displayServerError(error);
              return;
            }
          });
        }

        refetchData();

        if (name === "defaultLanguage") {
          setDocumentDir(value as Language);
        }

        if (name !== "defaultLanguage") displaySuccessNotification();
      },
      onError: () => displayNotSuccessNotification(),
    });
  };

  return (
    <div className={styles.row} e2e-test-id="personal-details-container">
      <div className={styles.column}>
        <h4 className={styles.tabTitle} e2e-test-id="personal-details-title">
          {t("accountSettings.personalDetails.heading")}
        </h4>
        <PronounsField
          pronouns={user.pronouns}
          onSubmit={(value, onSuccess) => onSave("pronouns", value, onSuccess)}
          isLoading={loading}
        />
        <DetailField
          inputType="text"
          name="fullName"
          e2eTestId="full-name-section"
          label={t("accountSettings.personalDetails.label.fullName")}
          value={fullName}
          buttonName={t("accountSettings.personalDetails.button.edit")}
          onSave={(name, value) => {
            onSave(name, value);
          }}
          maxLength={70}
        />
        <DetailField
          inputType="text"
          name="email"
          e2eTestId="email-section"
          label={t("accountSettings.personalDetails.label.email")}
          value={email}
        />
        <PhoneField
          phoneData={phoneData}
          onSubmit={(data, onSuccess) =>
            onSave("telephone", getFullPhoneNumber(data), onSuccess)
          }
          isLoading={loading}
        />
        <DetailField
          inputType="select"
          placeholder={t(`select.language.${defaultLanguage}`)}
          options={TRANSLATED_LANGUAGES}
          name="defaultLanguage"
          e2eTestId="default-language-section"
          label={t("accountSettings.personalDetails.label.language")}
          value={t(`select.language.${defaultLanguage}`)}
          buttonName={t("accountSettings.personalDetails.button.edit")}
          onSave={(name, value) => {
            // quick fix for language because value prop is not consistent
            let languageValue: string;

            if (
              value === "Spanish" ||
              value === "$$$ Spanish" ||
              value === "Español" ||
              value === "스페인의" ||
              value === "Espagnol" ||
              value === "Испанский" ||
              value === "西班牙语"
            ) {
              languageValue = "Spanish";
            } else if (
              value === "Korean" ||
              value === "$$$ Korean" ||
              value === "Coreano" ||
              value === "한국인" ||
              value === "Coréen" ||
              value === "Корейский" ||
              value === "韩国人"
            ) {
              languageValue = "Korean";
            } else if (
              value === "French" ||
              value === "$$$ French" ||
              value === "Francés" ||
              value === "프랑스 국민" ||
              value === "Français" ||
              value === "Французский" ||
              value === "法语"
            ) {
              languageValue = "French";
            } else if (
              value === "Russian" ||
              value === "$$$ Russian" ||
              value === "Ruso" ||
              value === "러시아인" ||
              value === "Russe" ||
              value === "Русский" ||
              value === "俄语"
            ) {
              languageValue = "Russian";
            } else if (
              value === "Chinese" ||
              value === "$$$ Chinese" ||
              value === "Chino" ||
              value === "중국인" ||
              value === "Chinois" ||
              value === "Китайский" ||
              value === "中国人"
            ) {
              languageValue = "Chinese";
            } else {
              languageValue = "English";
            }
            const selectedLanguageValue = TRANSLATED_LANGUAGES?.find(
              (item) => item?.label === value
            );
            const selectedLanguageKey = LANGUAGES?.find(
              (item) => item?.value === languageValue
            );
            i18n.changeLanguage(selectedLanguageKey?.key);
            onSave(name, selectedLanguageValue?.value || "English");
          }}
          maxLength={70}
        />
      </div>

      {/*
      // #1078 What info is shared with others? Why do we collect your telephone number?
      <div className={styles.infoColumn}>
        <InfoCard
          image={InfoTab}
          title={t(
            "accountSettings.personalDetails.infoContainer.infoCard.title"
          )}
          subtitle={t(
            "accountSettings.personalDetails.infoContainer.infoCard.subtitle"
          )}
        />
        <InfoCard
          image={Bell}
          title={t(
            "accountSettings.personalDetails.infoContainer.bellCard.title"
          )}
          subtitle={t(
            "accountSettings.personalDetails.infoContainer.bellCard.subtitle"
          )}
        />
      </div> */}
    </div>
  );
};

export default PersonalDetails;
