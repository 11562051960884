import { gql } from "@apollo/client";
import { COLLECTION_FRAGMENT } from "./fragment";

const GET_ALL_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections {
    user {
      allCollections {
        name
        publicId
      }
    }
  }
`;

const GET_MY_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections {
    user {
      myCollections {
        ...CollectionFields
        collectionType
        collaborators {
          email
        }
        followers {
          email
        }
      }
    }
  }
`;

const GET_SHARED_WITH_ME_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections {
    user {
      followedCollections {
        followers {
          publicId
          email
        }
        collaborators {
          publicId
          email
        }
        ...CollectionFields
        collectionType
      }
      collaboratingCollections {
        followers {
          publicId
          email
        }
        collaborators {
          publicId
          email
        }
        ...CollectionFields
        collectionType
      }
    }
  }
`;

const GET_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections {
    user {
      myCollections {
        ...CollectionFields
        collectionCreator {
          fullName
          publicId
          organization {
            name
            userRole
          }
        }
        interests {
          name
        }
      }
    }
  }
`;

const GET_MY_COLLECTIONS_NAMES = gql`
  query collections {
    user {
      myCollections {
        publicId
        name
      }
    }
  }
`;

const GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES = gql`
  query collections {
    user {
      myCollections {
        publicId
        name
      }
      collaboratingCollections {
        publicId
        name
      }
    }
  }
`;

const SEARCH_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections($collectionSearch: CollectionSearchInputType!) {
    collections(collectionSearch: $collectionSearch) {
      totalCount
      collections {
        interests {
          name
        }
        collaborators {
          email
          publicId
        }
        followers {
          email
          publicId
        }
        ...CollectionFields
      }
    }
  }
`;
const EXPLORE_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query collections($collectionSearch: CollectionSearchInputType!) {
    collections(collectionSearch: $collectionSearch) {
      totalCount
      collections {
        interests {
          name
        }
        followers {
          email
          publicId
        }
        collaborators {
          email
          publicId
        }
        collectionType
        ...CollectionFields
      }
    }
  }
`;

const SEARCH_GLOBAL_CURATED_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query globalCuratedCollections(
    $globalCuratedCollectionSearch: CollectionSearchInputType!
  ) {
    globalCuratedCollections(
      globalCuratedCollectionSearch: $globalCuratedCollectionSearch
    ) {
      totalCount
      collections {
        interests {
          name
        }
        followers {
          email
          publicId
        }
        collaborators {
          email
          publicId
        }
        collectionType
        ...CollectionFields
      }
    }
  }
`;

const GET_RECOMMENDED_GLOBAL_CURATED_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  query recommendedGlobalCuratedCollections(
    $globalCuratedCollectionSearch: CollectionSearchInputType!
  ) {
    recommendedGlobalCuratedCollections(
      globalCuratedCollectionSearch: $globalCuratedCollectionSearch
    ) {
      totalCount
      collections {
        interests {
          name
        }
        followers {
          email
          publicId
        }
        collaborators {
          email
          publicId
        }
        collectionType
        ...CollectionFields
      }
    }
  }
`;

const GET_SINGLE_COLLECTION = gql`
  ${COLLECTION_FRAGMENT}
  query collection($publicId: Guid!) {
    collection(publicId: $publicId) {
      ...CollectionFields
      isEditable
      collectionCreator {
        fullName
        publicId
        email
        organization {
          name
          userRole
        }
      }
      collectionType
      canUserDeleteCollection
      collaborators {
        fullName
        publicId
        email
        organization {
          name
          userRole
        }
      }
      files {
        name
        publicId
        directory
        updateTime
        isPinnable
        canUserDeleteFile
        originalName
        interests {
          name
        }
      }
      folders {
        name
        publicId
        updateTime
        isEditable
        canUserDeleteFolder
      }
      links {
        name
        publicId
        updateTime
        url
        canUserDeleteLink
      }
      followers {
        fullName
        publicId
        email
        organization {
          name
          userRole
        }
      }
      interests {
        name
      }
      # articles {
      #   articleId
      # }
      # filteredArticles {
      #   articleId
      # }
      # filteredArticlesCount
      isGlobalCurated
    }
  }
`;

const GET_COLLECTION_CONTENT = gql`
  query GetCollection($publicId: Guid!) {
    collection(publicId: $publicId) {
      name
      publicId
      folders {
        name
        publicId
        updateTime
      }
      files {
        name
        publicId
      }
      links {
        name
        publicId
      }
      # articles {
      #   publicId
      #   articleId
      # }
    }
  }
`;

const GET_FOLDER_CONTENT = gql`
  query GetFolder($publicId: ID!) {
    folder(publicId: $publicId) {
      publicId
      name
      folders {
        name
        publicId
        updateTime
      }
      files {
        name
        publicId
      }
      links {
        name
        publicId
      }
      # articles {
      #   publicId
      #   articleId
      # }
    }
  }
`;

const GET_USER_COMMUNITIES = gql`
  query userCommunities($userCommunitySearchOptions: UserCommunitySearchInputType!) {
    userCommunities(userCommunitySearchOptions: $userCommunitySearchOptions) {
      totalCount
      communities {
        name
        sendbirdId
        communityImageUrl
        communityCreator {
          email
        }
        admins {
          email
        }
        members {
          email
        }
        allCommunityCollectionsCount
      }
    }
  }
`;

const COLLECTION_GET_ALL_USER_COLLECTIONS = gql`
  query allUserCollections(
    $allUserCollectionsSearchOptions: AllUserCollectionsSearchInputType!
  ) {
    allUserCollections(
      allUserCollectionsSearchOptions: $allUserCollectionsSearchOptions
    ) {
      totalCount
      collections {
        publicId
        name
        description
        isPublic
        isGlobalCurated
        imageName
        imageDirectory
        updateTime
        creationTime
        collaboratorsCount
        # articlesCount
        collectionType
        collectionCreator {
          fullName
          publicId
          email
        }
        collaborators {
          publicId
          email
        }
        followers {
          publicId
          email
        }
      }
    }
  }
`;

const GET_USER_INTERESTS_FOR_SLIDER = gql`
  query user {
    user {
      fullName
      interests {
        name
      }
    }
  }
`;

export {
  COLLECTION_GET_ALL_USER_COLLECTIONS,
  EXPLORE_COLLECTIONS,
  GET_ALL_COLLECTIONS,
  GET_COLLECTIONS,
  GET_COLLECTION_CONTENT,
  GET_FOLDER_CONTENT,
  GET_MY_AND_COLLABORATING_COLLECTIONS_NAMES,
  GET_MY_COLLECTIONS,
  GET_MY_COLLECTIONS_NAMES,
  GET_RECOMMENDED_GLOBAL_CURATED_COLLECTIONS,
  GET_SHARED_WITH_ME_COLLECTIONS,
  GET_SINGLE_COLLECTION,
  GET_USER_COMMUNITIES,
  GET_USER_INTERESTS_FOR_SLIDER,
  SEARCH_COLLECTIONS,
  SEARCH_GLOBAL_CURATED_COLLECTIONS,
};
