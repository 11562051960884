import { gql } from "@apollo/client";

const BAN_USER_FROM_COMMUNITY = gql`
  mutation banUserFromCommunity($banRequest: BanUserFromCommunityInputType!) {
    banUserFromCommunity(banRequest: $banRequest) {
      name
    }
  }
`;

export default BAN_USER_FROM_COMMUNITY;
