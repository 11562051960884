import {
  AccordionType,
  ContactType,
  FeatureBannerType,
  FooterType,
  HeaderType,
} from "./types";

import Logo from "@images/hive_logo.png";
import Facebook from "../icons/facebook-black.svg";
import Linkedin from "../icons/linkedin-black.svg";
import X from "../icons/x-black.svg";
import YouTube from "../icons/youtube-black.svg";
import ContactTablet from "../images/contact-tablet.png";
import Banner from "../images/featured-banner.svg";
import CommunitySpace from "../images/features-1.gif";
import Events from "../images/features-2.gif";
import Search from "../images/features-3.gif";
import FooterLogo from "../images/footer-logo.svg";
import GreenCircle from "../images/green-circle.svg";
import MobileLogo from "../images/mobile-logo.svg";
import OurMissionDesktop from "../images/our-mission-desktop.png";
import WelcomeHive from "../images/welcomeHive.svg";

interface Configuration {
  sectionOne: FeatureBannerType;
  sectionTwo: FeatureBannerType;
  sectionThree: AccordionType;
  sectionFour: ContactType;
  footer: FooterType;
  header: HeaderType;
  welcomeSection: FeatureBannerType;
}

export const configuration: Configuration = {
  sectionOne: {
    head: "Hive",
    title: "Epidemic and Pandemic Preparedness and Prevention",
    description: "powered by",
    image: {
      src: Banner,
      width: "1035",
      height: "640",
    },
  },
  sectionTwo: {
    title: "Our mission",
    tooltipText:
      "An infodemic is too much information including false or misleading information in digital and physical environments during a disease outbreak",
    description:
      "WHO wants to provide tools for community leaders and decision-makers to manage this infodemic by providing opportunities to find the right information at the right time, and share and collaborate.",
    image: {
      src: OurMissionDesktop,
      width: "1080",
      height: "730",
    },
  },
  sectionThree: {
    title: "Hive features",
    image: {
      src: GreenCircle,
      width: "475",
      height: "533",
    },
    items: [
      {
        id: "1",
        label: "Community space: Connect and collaborate",
        text: "Create and join communities where you can discuss ideas, share resources and information and connect with new people.",
        image: {
          src: CommunitySpace,
          width: "1588",
          height: "1480",
        },
      },
      {
        id: "3",
        label: "Events: Learn and share worldwide",
        text: "Host and attend events and meetings, where you can share your expertise; acquire knowledge from other professionals in your field and extend your networks with new connections.",
        image: {
          src: Events,
          width: "1814",
          height: "1707",
        },
      },
      {
        id: "2",
        label: "Search: The information you need when you need it",
        text: "Discover reliable information from trusted sources; obtain real-time updates based on your industry, location and interests; and  collate resources in your own curated collections.",
        image: {
          src: Search,
          width: "2364",
          height: "1488",
        },
      },
    ],
  },
  sectionFour: {
    title: "Register your interest",
    description: "",
    termsAndConditions: "I have read and agree to the Terms & conditions",
    submitText: "Register",
    image: {
      src: ContactTablet,
      width: "733.22",
      height: "543.55",
    },
  },
  header: {
    logoSrc: Logo,
    logoAlt: "Hive logo",
    logoMobileSrc: MobileLogo,
    logoMobileAlt: "Hive logo",
    menu: [
      {
        key: "events",
        secondaryHover: false,
        text: "Events",
        url: "/events",
      },
      {
        key: "login",
        secondaryHover: false,
        text: "Login",
        url: "/login",
      },
      {
        key: "sign-up",
        secondaryHover: true,
        text: "Register",
        url: "#signup",
      },
      {
        key: "about",
        secondaryHover: false,
        text: "About",
        url: "#about",
      },
      {
        key: "hive-features",
        secondaryHover: false,
        text: "Hive Features",
        url: "#hive-features",
      },
      {
        key: "hive-pulse",
        secondaryHover: false,
        text: "Hive Pulse",
        url: "#hive-pulse",
      },
    ],
  },
  footer: {
    logoSrc: FooterLogo,
    logoAlt: "Hive Logo",
    menu: [],
    socialLinks: [
      {
        key: "Facebook",
        src: Facebook,
        url: "https://www.facebook.com/WHO/",
      },
      {
        key: "X",
        src: X,
        url: "https://twitter.com/WHO",
      },
      {
        key: "Linkedin",
        src: Linkedin,
        url: "https://au.linkedin.com/company/world-health-organization",
      },
      {
        key: "YouTube",
        src: YouTube,
        url: "https://www.youtube.com/channel/UC07-dOwgza1IguKA86jqxNA",
      },
    ],
  },
  welcomeSection: {
    head: "",
    title: "Welcome to the Hive",
    description:
      "The World Health Organization's (WHO) Hive platform provides access to information; promotes knowledge sharing and collaboration; and fosters communities of practice in a safe space.",
    image: {
      src: WelcomeHive,
      width: "580",
      height: "580",
    },
  },
};
