import { useEffect, useRef } from "react";

import { LazyQueryExecFunction } from "@apollo/client";

interface IUseDebounce<Y, Z> {
  variables: Z;
  queryFn: LazyQueryExecFunction<Y, Z>;
}

export const useDebounce = <Y, Z>({ queryFn, variables }: IUseDebounce<Y, Z>) => {
  const abortController = useRef<AbortController>();

  useEffect(() => {
    const controller = new window.AbortController();
    abortController.current = controller;

    queryFn({
      variables,
      context: {
        fetchOptions: {
          signal: controller.signal,
        },
      },
    });

    return () => {
      abortController.current && abortController.current.abort();
    };
  }, [queryFn, variables]);
};
