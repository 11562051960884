import { useLazyQuery } from "@apollo/client";
import Loading from "@components/Loading/Loading";
import avatar from "@images/avatar.svg";
import { countTimeAgoFromNowFromUTCToString } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IComment } from "types/comment";
import ArticleCommentReactions from "../ArticleCommentReactions/ArticleCommentReactions";

import { STORAGE_IMAGE_TYPE_USER } from "@constants/constants";
import GET_PROFILE_IMAGES from "@graphql/queries/profileImages";
import getStorageImage from "@utils/getStorageImage";
import { IUserImageResponse } from "types/user";

import UserDrawer from "@modules/UserDrawer/UserDrawer";
import withUser from "../../hoc/withUser";

import styles from "./ArticleComment.module.scss";

interface IArticleComment {
  item: IComment;
}

const ArticleComment = ({ item }: IArticleComment) => {
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const { i18n } = useTranslation();
  const [userImage, setUserImage] = useState<string>();

  const handleUserSelected = () => {
    setSelectedUserEmail(item.commenter.email);
  };

  const [getUserImages, { loading: isUserImagesLoading }] =
    useLazyQuery<IUserImageResponse>(GET_PROFILE_IMAGES, {
      fetchPolicy: "cache-and-network",
      onCompleted: async (res) => {
        const userPhotosInfo = res.profileImages[0];
        setUserImage(
          getStorageImage({
            image: userPhotosInfo?.userPhotoName,
            directoryName: userPhotosInfo?.imageDirectory,
            type: STORAGE_IMAGE_TYPE_USER,
          })
        );
      },
    });

  useEffect(() => {
    if (item.commenter.email) {
      getUserImages({
        variables: {
          profileImagesInput: {
            userEmails: item.commenter.email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const UserDrawerWithUser = withUser(UserDrawer);
  return (
    <div>
      {isUserImagesLoading ? (
        <Loading />
      ) : (
        <div aria-label="articleComment">
          <div className={styles.commentHeader}>
            <div
              style={{ backgroundImage: `url(${userImage || avatar})` }}
              className={styles.avatar}
              onClick={handleUserSelected}
            ></div>
            <div className={styles.commentHeaderDescription}>
              <h3 onClick={handleUserSelected} className={styles.commentHeaderName}>
                {item.commenter.fullName}
              </h3>
              <p className={styles.commentHeaderTime}>
                {countTimeAgoFromNowFromUTCToString(item.creationTime, i18n.language)}
              </p>
            </div>
          </div>
          <p className={styles.commentContent}>{item.content}</p>
          <ArticleCommentReactions item={item} />
          <UserDrawerWithUser
            email={selectedUserEmail}
            communityOptions={<></>}
            closeInfoDrawer={() => setSelectedUserEmail("")}
          />
        </div>
      )}
    </div>
  );
};

export default ArticleComment;
