import { gql } from "@apollo/client";

const FLAG_ARTICLE = gql`
  mutation flagArticle($article: FlagArticleInputType!) {
    flagArticle(article: $article) {
      articleId
    }
  }
`;

export default FLAG_ARTICLE;
