import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Emoji } from "sendbird";

import Button from "@components/Button/Button";
import ChatWarningModal from "@components/ChatWarningModal/ChatWarningModal";

import { EmojiContainer } from "@customHooks/sendBird/components/EmojiContainer/EmojiContainer";
import {
  ISendBirdMessageHelper,
  MessageType,
} from "@customHooks/sendBird/useSendBirdMessage/type";

import { IUser } from "types/user";

import { ReactComponent as ReplyIcon } from "@images/reply.svg";
import { ReactComponent as TrashCanIcon } from "@images/trashCan.svg";

import styles from "./MessageOptions.module.scss";

interface IMessageOptionsProps {
  message: MessageType;
  sendBirdMessageHelper: ISendBirdMessageHelper;
  toggleReaction: (emoji: Emoji) => Promise<void>;
  currentUser?: IUser;
  selectParentMessage?: (messageId: number) => void;
  showReplyButton?: string;
}

export const MessageOptions = ({
  message,
  sendBirdMessageHelper: {
    messageActions: { deleteChannelMessage: deleteMessage },
    messageHelpers: { isChannelMessageDeletable },
  },
  toggleReaction,
  currentUser,
  selectParentMessage,
  showReplyButton,
}: IMessageOptionsProps) => {
  const { t } = useTranslation();
  const [isDeleteMessageModalOpen, setIsDeleteMessageModalOpen] = useState(false);
  const email = message.messageType !== "admin" ? message.sender?.userId : "";
  const isCurrentUserMessage = currentUser !== undefined && currentUser.email === email;

  const handleDeleteMessage = () => {
    deleteMessage(message.messageId);
    setIsDeleteMessageModalOpen(false);
  };

  const handleReplyToMessage = () => {
    selectParentMessage?.(message.messageId);
  };

  const renderOptions = () => {
    const isDeletable =
      currentUser && email && isChannelMessageDeletable(currentUser.email, email);

    return (
      <div
        aria-label="messageOptions"
        className={
          styles.options +
          (isCurrentUserMessage ? " " + styles.additionalClassForOwnMessages : "")
        }
      >
        <EmojiContainer className={styles.option} toggleReaction={toggleReaction} />
        {showReplyButton && !message.parentMessage && (
          <Button
            aria-label="replayMessageOption"
            title=""
            buttonShape="circle"
            buttonType="link"
            onClick={handleReplyToMessage}
            wrapperClassNames={styles.option}
            buttonIcon={<ReplyIcon />}
          />
        )}
        {isDeletable && (
          <Button
            aria-label="deleteMessageOption"
            title=""
            buttonShape="circle"
            buttonType="link"
            onClick={() => setIsDeleteMessageModalOpen(true)}
            wrapperClassNames={styles.option}
            buttonIcon={<TrashCanIcon />}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {/* due to the earlier implementation, the render option had to be prevented in this way if there is no reply or delete option */}
      {renderOptions()}
      <ChatWarningModal
        title={`${t("chat.group.deleteMessage.title")}`}
        subtitle={t("chat.group.deleteMessage.subtitle")}
        cancelText={t("chat.group.deleteMessage.cancelText")}
        submitText={t("chat.group.deleteMessage.submitText")}
        close={() => setIsDeleteMessageModalOpen(false)}
        submit={handleDeleteMessage}
        open={isDeleteMessageModalOpen}
      />
    </>
  );
};
